import React from 'react';
import PropTypes from 'prop-types';
import AccountDataPointPanel from './AccountDataPointPanel';
import { formatDecimalToFixedTwoPercent } from '../../../../shared/components/table/functions';

export default function PPCSalesPercentage({ data, loading, dateView }) {
  const grossSales = (myData) => {
    let total = 0;
    if (myData) {
      total = myData.reduce((sum, item) => {
        if (item.gross_sales && item.sales) {
          return sum + item.gross_sales;
        }
        return sum;
      }, 0);
    }
    return total;
  };
  const sales = (myData) => {
    let total = 0;
    if (myData) {
      total = myData.reduce((sum, item) => {
        if (item.sales && item.gross_sales) {
          return sum + item.sales;
        }
        return sum;
      }, 0);
    }
    return total;
  };
  return (
    <AccountDataPointPanel
      totalFunction={() => sales(data) / grossSales(data)}
      data={data}
      title="PPC Sales"
      subtitle="As percentage of gross sales"
      dayField="ppc_percent"
      formatFunction={item => formatDecimalToFixedTwoPercent({ value: item })}
      barColor="#fbb054"
      loading={loading}
      dateView={dateView}
    />
  );
}

PPCSalesPercentage.defaultProps = {
  data: null,
};

PPCSalesPercentage.propTypes = {
  loading: PropTypes.bool.isRequired,
  dateView: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(Array),
};
