import React from 'react';
import { Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import CloseIcon from 'mdi-react/CloseIcon';
import TableBuilder from '../../../../../shared/components/table/TableBuilder'

const TableModal = ({
  title, theme, showTableModal, changeShowTableModal, columnDefs, defaultColDef, apiEndPoint, setColumnsFromObject,
}) => (
  <Modal
    isOpen={showTableModal}
    toggle={changeShowTableModal}
    className={theme.className}
    style={{ maxWidth: '900px', width: '100%' }}
  >
    <button className="panel__btn" type="button" onClick={() => changeShowTableModal()}><CloseIcon /></button><h2>{title}</h2>
    <br />
    <br />
    <br />
    <div style={{ textAlign: 'left' }}>
      <TableBuilder
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        apiEndPoint={apiEndPoint}
        setColumnsFromObject={setColumnsFromObject}
      />
    </div>
  </Modal>
);

TableModal.propTypes = {
  title: PropTypes.string.isRequired,
  changeShowTableModal: PropTypes.func.isRequired,
  columnDefs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  defaultColDef: PropTypes.shape().isRequired,
  apiEndPoint: PropTypes.string.isRequired,
  showTableModal: PropTypes.bool,
  setColumnsFromObject: PropTypes.shape(),
};

TableModal.defaultProps = {
  showTableModal: false,
  setColumnsFromObject: {
    enabled: false,
    headerNameFunc: () => {},
  },
};

export default TableModal;
