import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { imageFieldColumn, imageCellRendererComponent } from './ImageCellRenderer';
import EditMasterCatalogModal from './EditMasterCatalogModal';
import config from '../../../../config';
import TableBuilder from '../../../../shared/components/table/TableBuilder';
import CardFreemium from '../../../../shared/components/widgets/CardFreemium';
import { doFetch } from '../../../../helpers';
import { getExportPrefix, currencyFormatter } from '../../../../shared/components/table/functions';

function ItemCatalogTable({ setAdStatusOnCount, setUsedBudget, setIsLoading }) {
  const selectedSeller = useSelector(store => store.sellerSelect);
  const user = useSelector(store => store.authentication.user);
  const theme = useSelector(store => store.theme);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedData, setSelectedData] = useState(0);
  const [agGrid, setAgGrid] = useState(null);

  const columnDefs = [
    imageFieldColumn,
    {
      field: 'item_id',
      headerName: 'Item ID',
      cellRenderer: (params) => {
        if (params && params.data && params.data.item_page_url) {
          return <a href={params.data.item_page_url} target="_blank" rel="noopener noreferrer">{params.data.item_id}</a>;
        }
        return params.data.item_id;
      },
    },
    {
      field: 'product_name',
      headerName: 'Product Name',
      headerTooltip: 'pull from lastest data',
    },
    {
      field: 'base_variant_identifier',
      headerName: 'BV Type',
      headerTooltip: 'pull from lastest data',
    },
    {
      field: 'ad_status',
      headerName: 'AD Status',
    },
    {
      field: 'brand',
      headerName: 'Brand',
    },
    {
      field: 'subcategory',
      headerName: 'Subcategory Name',
    },
    {
      field: 'category',
      headerName: 'Category Tree',
    },
    {
      field: 'average_rating',
      headerName: 'Average Rating',
      headerTooltip: 'pull from lastest data',
    },
    {
      field: 'review_count',
      headerName: 'Review Count',
      headerTooltip: 'pull from lastest data',
    },
    {
      field: 'available_inventory_units',
      headerName: 'Available Inventory Units',
      headerTooltip: 'pull from lastest data',
    },
    {
      field: 'price',
      headerName: 'Price',
      headerTooltip: 'pull from lastest data',
    },
    {
      field: 'availability_status',
      headerName: 'Availability Status',
      valueFormatter: (params) => {
        if (params.data && params.data.availability_status) {
          if (params.data.availability_status === '1') {
            return 'Availabile';
          }
          if (params.data.availability_status === '2') {
            return 'Unavailabile';
          }
        }
        return '';
      },
      headerTooltip: 'pull from lastest data',
    },
    {
      field: 'publish_status',
      headerName: 'Publish Status',
      valueFormatter: (params) => {
        if (params.data && params.data.publish_status) {
          if (params.data.publish_status === '1') {
            return 'Published';
          }
          if (params.data.publish_status === '2') {
            return 'Unpublished';
          }
        }
        return '';
      },
      headerTooltip: 'pull from lastest data',
    },
    {
      field: 'gtin',
      headerName: 'GTIN',
    },
    {
      field: 'upc',
      headerName: 'UPC',
    },
    {
      field: 'spend',
      headerName: 'MTD LL Spend',
      valueFormatter: (params) => {
        if (params.node?.footer) {
          if (params.node && params.node.aggData) {
            setUsedBudget(params.node.aggData?.spend);
          }
        }
        return currencyFormatter(params);
      },
      headerTooltip: 'Pull month-to-date data',
    },
    {
      field: 'sales',
      headerName: 'MTD LL Sales',
      valueFormatter: currencyFormatter,
      headerTooltip: 'Pull month-to-date data',
    },
    {
      field: 'orders',
      headerName: 'MTD LL Orders',
      valueFormatter: currencyFormatter,
      headerTooltip: 'Pull month-to-date data',
    },
    {
      field: '',
      headerName: 'MTD LL Ad ROAS',
      sortable: false,
      valueGetter: (params) => {
        if (params.data && params.data.spend > 0) {
          return (params.data.sales / params.data.spend).toFixed(1);
        }
        return '';
      },
      headerTooltip: 'Pull month-to-date data',
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: false,
    resizable: true,
    cellStyle: (params) => {
      if (params && params.value < 0.00) {
        return { color: 'red' };
      }
      return null;
    },
  };

  const frameworkComponents = {
    ...imageCellRendererComponent,
  };
  const tableConfig = {
    showDatePicker: false,
    showFooter: false,
    parentControlledLoad: true,
    apiEndPoint: `${config.serverUrl}/walmart/catalogs`,
    paginationPageSize: 5000,
    cacheBlockSize: 5000,
  };

  const datasource = {
    getRows: async (params) => {
      const {
        request, success, fail,
      } = params;
      const { startRow, sortModel } = request;
      const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
      const requestOptions = {
        method: 'GET',
        headers: headerWithAuth,
      };

      let sort = '';
      if (sortModel.length > 0) {
        sort = `${sortModel[0].colId} ${sortModel[0].sort}`;
      }

      const offset = startRow || 0;

      const accountId = selectedSeller.parentAccountId > 0 ? selectedSeller.parentAccountId : selectedSeller.value;

      const url = `${tableConfig.apiEndPoint}/${accountId}?limit=${tableConfig.paginationPageSize}&offset=${offset}&sort=${sort}&enhanced=true`;
      try {
        setIsLoading(true);
        const data = await doFetch(url, requestOptions);
        success({
          rowData: data.results,
          rowCount: data.total,
        });
        setAdStatusOnCount(data.ad_status_on_count);
        setUsedBudget(data.mtd_ad_spend);
        setIsLoading(false);
      } catch (error) {
        fail();
      }
    },
  };

  const parentAccess = (agGridPointer) => {
    setAgGrid(agGridPointer);
  }

  const forceRefresh = () => {
    agGrid.refreshData();
  }

  const changeShowEditModal = () => {
    setShowEditModal(!showEditModal);
  };

  const selectionChanged = (selectdData) => {
    setSelectedData(selectdData);
  };
  const exportPrefix = getExportPrefix('wm-catalog-op', selectedSeller, true);

  return (
    <Container className="todo-app">
      <Row>
        <Col md={12}>
          <CardFreemium
            title="Catalog Ad Status Controls"
            subhead="Please select all of the products you would like to advertise and assign an ad status of ON or OFF at any time."
            freemiumClass="performance"
            icon="picture"
          >
            <TableBuilder
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              apiEndPoint={tableConfig.apiEndPoint}
              showDatePicker={tableConfig.showDatePicker}
              showFooter={tableConfig.showFooter}
              frameworkComponents={frameworkComponents}
              rowHeight={75}
              pagination
              rowModelType="serverSide"
              datasource={datasource}
              paginationPageSize={tableConfig.paginationPageSize}
              cacheBlockSize={tableConfig.cacheBlockSize}
              serverSideInfiniteScroll
              parentControlledLoad={tableConfig.parentControlledLoad}
              exportPrefix={exportPrefix}
              parentAccess={parentAccess}
              parentSelectionChanged={selectionChanged}
              editSelectedRowsButton
              openEditSelected={changeShowEditModal}
            />
          </CardFreemium>
          <EditMasterCatalogModal
            changeShowEditModal={changeShowEditModal}
            showEditModal={showEditModal}
            theme={theme}
            selectedData={selectedData}
            forceRefresh={forceRefresh}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default ItemCatalogTable;
