import React from 'react';
import NotificationSystem from 'rc-notification';
import { BasicNotification } from '../Notification';
import config from '../../../config';


let notificationLU = null;
let notificationRU = null;
let notificationTC = null;

NotificationSystem.newInstance({}, n => { notificationLU = n });
NotificationSystem.newInstance({}, n => { notificationRU = n });
NotificationSystem.newInstance({}, n => { notificationTC = n });

const show = (color, title, msg) => {
  config.showNotification({
    notification: <BasicNotification
      color={color}
      title={title}
      message={msg}
    />,
    position: 'right-up',
    notificationLU,
    notificationRU,
    notificationTC,
  });
};

export default {
  show,
};
