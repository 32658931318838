import React from 'react';
import {
  Card, CardBody, Collapse,
} from 'reactstrap';
import PropTypes from 'prop-types';
import SimplePanel from './SimplePanel';
import LoadingSpinner from './LoadingSpinner';

// const amazonIcon = require('../../img/logo/amazonIcon.png');
// const dspIcon = require('../../img/logo/dspIcon.png');
const walmartIcon = require('../../img/logo/walmartIcon.png');

const CardFreemium = ({
  color, freemiumClass, icon, title, subhead, parentRefresh, children,
}) => (
  <Card
    className={`freemium${color ? ` freemium--${color}` : ''} freemium_top_border_${freemiumClass}`}
  >
    <CardBody className="freemium__body">
      {(parentRefresh) ? <LoadingSpinner active={parentRefresh} wrap /> : ''}
      <div style={{ whiteSpace: 'nowrap' }} className="freemium__div">
        <span className={`lnr lnr-${icon} freemium_${freemiumClass}-img`} />
        <span
          className={'freemium_platform-img'}
          style={{
            background: `url(${walmartIcon})`, backgroundPosition: 'left', backgroundRepeat: 'no-repeat', cursor: 'default',
          }}
        />
        <span className={'freemium_platform-text'}>{title}</span>
      </div>

      {subhead && (
        <div className="freemium__title">
          <h5 className="subhead">{subhead}</h5>
        </div>
      )}
      <Collapse isOpen={!parentRefresh}>
        <SimplePanel>
          {children}
        </SimplePanel>
      </Collapse>
    </CardBody>
  </Card>
);

CardFreemium.defaultProps = {
  color: '',
  title: '',
  subhead: '',
  icon: '',
  freemiumClass: '',
  parentRefresh: false,
  children: null,
};

CardFreemium.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  subhead: PropTypes.string,
  icon: PropTypes.string,
  freemiumClass: PropTypes.string,
  parentRefresh: PropTypes.bool,
  children: PropTypes.instanceOf(Object),
};

export default CardFreemium;
