import React, { PureComponent } from 'react';
import config from '../../../../config';
import TableBuilder from '../../../../shared/components/table/TableBuilder';
import { getExportPrefix } from '../../../../shared/components/table/functions';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class ActivityManagementTable extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        {
          field: 'activity_id',
          headerName: '#',
        },
        {
          field: 'message',
          headerName: 'Message',
          editable: true,
        },
        {
          field: 'activity_date',
          headerName: 'Activity Date',
          editable: true,
        },
        {
          field: 'user_id',
          headerName: 'User ID',
          editable: true,
        },
        {
          field: 'user_name',
          headerName: 'User Name',
          editable: true,
        },
        {
          field: 'user_email',
          headerName: 'User Email',
          editable: true,
        },
        {
          field: 'activity_stream',
          headerName: 'Activity Stream',
          editable: true,
        },
      ],
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
      },
      showDatePicker: false,
      showFooter: false,
    };
  }

  render() {
    const {
      columnDefs, defaultColDef, showDatePicker, showFooter,
    } = this.state;

    const apiEndPoint = `${apiUrl}/events/activity`;
    const exportPrefix = getExportPrefix('event-activity');

    return (
      <TableBuilder
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        apiEndPoint={apiEndPoint}
        showDatePicker={showDatePicker}
        showFooter={showFooter}
        exportPrefix={exportPrefix}
        confirmLogic
      />
    );
  }
}

export default ActivityManagementTable;
