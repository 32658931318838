/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import DeltaChange from '../../../../shared/components/widgets/DeltaChange';
import {
  currencyFormatter, formatDecimalToFixedTwoPercent,
} from '../../../../shared/components/table/functions';

const LbbBoxes = ({ actualRevenue, lostRevenueToLBB, initalLoad }) => (
  <Row>
    <DeltaChange
      content={{
        title: 'Actual Revenue',
        header: '',
        data: currencyFormatter({ value: actualRevenue }),
        delta: formatDecimalToFixedTwoPercent({ value: (actualRevenue / (parseInt(lostRevenueToLBB, 10) + parseInt(actualRevenue, 10))) }),
      }}
      loadingData={initalLoad}
    />
    <DeltaChange
      content={{
        title: 'Est. Lost Revenue to LBB',
        header: '',
        data: currencyFormatter({ value: lostRevenueToLBB }),
        delta: formatDecimalToFixedTwoPercent({ value: (lostRevenueToLBB / (parseInt(lostRevenueToLBB, 10) + parseInt(actualRevenue, 10))) }),
      }}
      loadingData={initalLoad}
    />
    <DeltaChange
      content={{
        title: 'Total Est. Revenue at zero LBB',
        header: '',
        data: currencyFormatter({ value: (parseInt(lostRevenueToLBB, 10) + parseInt(actualRevenue, 10)) }),
        delta: formatDecimalToFixedTwoPercent({ value: 1 }),
      }}
      loadingData={initalLoad}
    />
  </Row>
);

LbbBoxes.propTypes = {
  actualRevenue: PropTypes.number.isRequired,
  lostRevenueToLBB: PropTypes.number.isRequired,
  initalLoad: PropTypes.bool.isRequired,
};

export default LbbBoxes;
