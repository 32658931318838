import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Container, Row, Col,
} from 'reactstrap';
import moment from 'moment';
import PortfolioFilter, { ALL_PORTFOLIOS } from '../../../../../shared/components/widgets/PortfolioFilter';
import DataSetFilter, { DataSets } from '../../../../../shared/components/widgets/DataSetFilter';
import DataSourceFilter, { DataSources } from '../../../../../shared/components/widgets/DataSourceFilter';
import { MetricOptions } from '../../../../../shared/components/widgets/SalesMetricFilter';
import DateRangePickerPanel from '../../../../../shared/components/widgets/DateRangePickerPanel';
import Panel from '../../../../../shared/components/Panel';
import config from '../../../../../configWrapper';
import { useFetchReturnsArray as useFetch } from '../../../../../shared/components/hooks/useFetch';
import { CompetitionTable } from './CompetitionTable';
import { CompetitionMetricGraph } from './CompetitionMetricGraph';
import { getExportPrefix } from '../../../../../shared/components/table/functions';

function formatUrl(sellerSelect, startDate, endDate, dataSet, sourceOption, portfolio, metric) {
  const reportStart = startDate.format('YYYY-MM-DD');
  const reportEnd = endDate.format('YYYY-MM-DD');
  const url = `${config.serverUrl}/customers/bcc-top-competitors?sellerAccountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}&startDate=${reportStart}&endDate=${reportEnd}&accountType=${sellerSelect.type}&dataSet=${dataSet.value}&dataSource=${sourceOption.value}&portfolioFilter=${portfolio.value}&orderBy=${metric.value}`;
  return url;
}

export default function BccTopCompetitors() {
  const sellerSelect = useSelector(state => state.sellerSelect);
  const [sourceOption, setSourceOption] = useState(DataSources.ALL);
  const [dataSet, setDataSet] = useState(DataSets.ALL);
  const [portfolio, setPortfolio] = useState(ALL_PORTFOLIOS);
  const [metric, setMetric] = useState(MetricOptions.SALES);
  const [startDate, setStartDate] = useState(moment().subtract(34, 'days'));
  const [endDate, setEndDate] = useState(moment().subtract(4, 'days'));
  const url = useCallback(() => formatUrl(sellerSelect, startDate, endDate, dataSet, sourceOption, portfolio, metric), [sellerSelect, startDate, endDate, dataSet, sourceOption, portfolio, metric]);
  const [loading, results, error] = useFetch(url());

  function onDateChange(start, end) {
    setStartDate(moment(start));
    setEndDate(moment(end));
  }

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  const exportPrefix = getExportPrefix('bcc-top-competitors', sellerSelect);

  return (
    <Container className="dashboard">
      <Col md={12}>
        <Row>
          <DateRangePickerPanel
            reportStartDate={startDate.format('YYYY-MM-DD')}
            reportEndDate={endDate.format('YYYY-MM-DD')}
            onDateChange={onDateChange}
          />
        </Row>
      </Col>
      <Panel
        md={12}
        title="Filters"
        subhead=""
        parentRefresh={loading}
      >
        <Row>
          <Col lg={4}>
            <DataSetFilter
              initialValue={dataSet}
              onChange={value => setDataSet(value)}
              disable={loading}
            />
          </Col>
          <Col lg={4}>
            <PortfolioFilter
              onChange={value => setPortfolio(value)}
              disable={loading}
            />
          </Col>
          <Col lg={4}>
            <DataSourceFilter
              initialValue={sourceOption}
              onChange={option => setSourceOption(option)}
              disable={loading}
            />
          </Col>
        </Row>
      </Panel>
      <CompetitionMetricGraph
        title="Top 7 Metric Comparison"
        loading={loading}
        data={results}
        metric={metric}
        onChange={obj => setMetric(obj)}
      />
      <CompetitionTable
        title="Competitor Breakdown"
        subtitle={`${sourceOption.label} - ${startDate.format('MMMM DD, YYYY')} to ${endDate.format('MMMM DD, YYYY')}`}
        loading={loading}
        data={results}
        exportPrefix={exportPrefix}
        startDate={startDate}
        endDate={endDate}
      />
    </Container>
  );
}
