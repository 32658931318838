import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import SellerPpcProfitReport from './components/Page';
import { SellerSelect } from '../../../../../shared/prop-types/MainProps';
import SellerReportMessage from '../../../../../shared/components/widgets/SellerReportMessage';

const SellerPpcProfitReportPage = ({ sellerSelect }) => {
  if (sellerSelect.type === 1) {
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">PPC Profit Report</h3>
          </Col>
        </Row>
        <Row>
          <SellerPpcProfitReport />
        </Row>
      </Container>
    );
  }
  return (
    <SellerReportMessage />
  );
};

SellerPpcProfitReportPage.propTypes = {
  sellerSelect: SellerSelect.isRequired,
};

const mapStateToProps = (state) => {
  const { sellerSelect } = state;

  return {
    sellerSelect,
  };
};

export default connect(mapStateToProps)(SellerPpcProfitReportPage);
