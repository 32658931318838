import { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import config from '../../../configWrapper';
import { history } from '../../../helpers';

/** This work based on https://valentinog.com/blog/hooks */

const FETCH_START = 'START';
const FETCH_SUCCESS = 'SUCCESS';
const FETCH_FAILURE = 'FAILURE';
const FETCH_RESET = 'RESET';

const initialState = {
  loading: false,
  error: '',
  results: [],
};

function fetchReducer(state, action) {
  switch (action.type) {
    case FETCH_START:
      return { ...state, loading: true };
    case FETCH_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case FETCH_SUCCESS:
      return { ...state, loading: false, results: action.payload };
    case FETCH_RESET:
      return initialState;
    default:
      return state;
  }
}

export default function useFetch(url, options = { requestOptions: { method: 'GET' } }) {
  const user = useSelector(state => state.authentication.user);
  const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
  const { requestOptions } = options;
  const request = {
    ...requestOptions,
    headers: headerWithAuth,
  };
  // If the URL doesn't change, there's a chance we have POST data that did change, but we can't use the
  // options object directly because it's always a new object.
  // Stringify it and this is added to the useEffect watched array so if it changes we'll re-fetch.
  const optionsString = JSON.stringify(requestOptions);
  const [data, dispatch] = useReducer(fetchReducer, initialState);

  async function getData(theUrl, theOptions) {
    return fetch(theUrl, theOptions);
  }

  useEffect(() => {
    if (url && url !== '') {
      dispatch({ type: FETCH_START });
      getData(url, request)
        .then((response) => {
          if (response.status === 401) {
            history.push('/logout');
          }
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response.json();
        })
        .then((json) => {
          dispatch({ type: FETCH_SUCCESS, payload: json });
        })
        .catch((err) => {
          dispatch({ type: FETCH_FAILURE, payload: err.message });
        });
    }
    return (() => dispatch({ type: FETCH_RESET }));
  }, [url, optionsString]);

  return data;
}

export function useFetchReturnsArray(url, options = { requestOptions: { method: 'GET' } }) {
  const { loading, error, results } = useFetch(url, options);
  return [loading, results, error];
}
