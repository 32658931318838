const countryOptionsArray = [
  {
    label: 'AE',
    value: 'AE',
    developerId: '6601-3049-6200',
    devAccountId: 2,
    marketplaceId: 17,
    marketplaceCode: 'A2VIGQ35RCS4UG',
    marketplace: 'amazon.ae',
    marketplaceSellerCentralUrl: 'https://sellercentral.amazon.ae',
    marketplaceVendorCentralUrl: 'https://vendorcentral.amazon.me',
    region: 'eu',
  },
  {
    label: 'AU',
    value: 'AU',
    developerId: '7652-9311-7260',
    devAccountId: 3,
    marketplaceId: 5,
    marketplaceCode: 'A39IBJ37TRP1C6',
    marketplace: 'amazon.com.au',
    marketplaceSellerCentralUrl: 'https://sellercentral.amazon.com.au',
    marketplaceVendorCentralUrl: 'https://vendorcentral.amazon.com.au',
    region: 'fe',
  },
  {
    label: 'BE',
    value: 'BE',
    developerId: '6601-3049-6200',
    devAccountId: 2,
    marketplaceId: 24,
    marketplaceCode: 'AMEN7PMS3EDWL',
    marketplace: 'amazon.com.be',
    marketplaceSellerCentralUrl: 'https://sellercentral.amazon.com.be',
    marketplaceVendorCentralUrl: 'https://vendorcentral.amazon.com.be',
    region: 'eu',
  },
  {
    label: 'BR',
    value: 'BR',
    developerId: '7432-2529-1567',
    devAccountId: 1,
    marketplaceId: 6,
    marketplaceCode: 'A2Q3Y263D00KWC',
    marketplace: 'amazon.com.br',
    marketplaceSellerCentralUrl: 'https://sellercentral.amazon.com.br',
    marketplaceVendorCentralUrl: 'https://vendorcentral.amazon.com.br',
    region: 'na',
  },
  {
    label: 'CA',
    value: 'CA',
    developerId: '7432-2529-1567',
    devAccountId: 1,
    marketplaceId: 4,
    marketplaceCode: 'A2EUQ1WTGCTBG2',
    marketplace: 'amazon.ca',
    marketplaceSellerCentralUrl: 'https://sellercentral.amazon.ca',
    marketplaceVendorCentralUrl: 'https://vendorcentral.amazon.ca',
    region: 'na',
  },
  // {
  //   label: 'CN',
  //   value: 'CN',
  //   developerId: '',
  //   devAccountId: 3,
  //   marketplaceId: 7,
  //   marketplaceCode: 'AAHKV2X7AFYLW',
  //   marketplace: 'amazon.cn',
  //  marketplaceSellerCentralUrl: '',
  //  marketplaceVendorCentralUrl: '',
  //   region: 'fe',
  // },
  {
    label: 'DE',
    value: 'DE',
    developerId: '6601-3049-6200',
    devAccountId: 2,
    marketplaceId: 8,
    marketplaceCode: 'A1PA6795UKMFR9',
    marketplace: 'amazon.de',
    marketplaceSellerCentralUrl: 'https://sellercentral-europe.amazon.com',
    marketplaceVendorCentralUrl: 'https://vendorcentral.amazon.de',
    region: 'eu',
  },
  {
    label: 'EG',
    value: 'EG',
    developerId: '6601-3049-6200',
    devAccountId: 2,
    marketplaceId: 19,
    marketplaceCode: 'ARBP9OOSHTCHU',
    marketplace: 'amazon.eg',
    marketplaceSellerCentralUrl: 'https://sellercentral.amazon.eg',
    marketplaceVendorCentralUrl: 'https://vendorcentral.amazon.me',
    region: 'eu',
  },
  {
    label: 'ES',
    value: 'ES',
    developerId: '6601-3049-6200',
    devAccountId: 2,
    marketplaceId: 9,
    marketplaceCode: 'A1RKKUPIHCS9HS',
    marketplace: 'amazon.es',
    marketplaceSellerCentralUrl: 'https://sellercentral-europe.amazon.com',
    marketplaceVendorCentralUrl: 'https://vendorcentral.amazon.es',
    region: 'eu',
  },
  {
    label: 'FR',
    value: 'FR',
    developerId: '6601-3049-6200',
    devAccountId: 2,
    marketplaceId: 10,
    marketplaceCode: 'A13V1IB3VIYZZH',
    marketplace: 'amazon.fr',
    marketplaceSellerCentralUrl: 'https://sellercentral-europe.amazon.com',
    marketplaceVendorCentralUrl: 'https://vendorcentral.amazon.fr',
    region: 'eu',
  },
  {
    label: 'IN',
    value: 'IN',
    developerId: '6601-3049-6200',
    devAccountId: 2,
    marketplaceId: 12,
    marketplaceCode: 'A21TJRUUN4KGV',
    marketplace: 'amazon.in',
    marketplaceSellerCentralUrl: 'https://sellercentral.amazon.in',
    marketplaceVendorCentralUrl: 'https://www.vendorcentral.in',
    region: 'eu',
  },
  {
    label: 'IT',
    value: 'IT',
    developerId: '6601-3049-6200',
    devAccountId: 2,
    marketplaceId: 13,
    marketplaceCode: 'APJ6JRA9NG5V4',
    marketplace: 'amazon.it',
    marketplaceSellerCentralUrl: 'https://sellercentral-europe.amazon.com',
    marketplaceVendorCentralUrl: 'https://vendorcentral.amazon.it',
    region: 'eu',
  },
  {
    label: 'JP',
    value: 'JP',
    developerId: '0121-7713-7889',
    devAccountId: 3,
    marketplaceId: 14,
    marketplaceCode: 'A1VC38T7YXB528',
    marketplace: 'amazon.co.jp',
    marketplaceSellerCentralUrl: 'https://sellercentral.amazon.co.jp',
    marketplaceVendorCentralUrl: 'https://vendorcentral.amazon.co.jp',
    region: 'fe',
  },
  {
    label: 'MX',
    value: 'MX',
    developerId: '7432-2529-1567',
    devAccountId: 1,
    marketplaceId: 16,
    marketplaceCode: 'A1AM78C64UM0Y8',
    marketplace: 'amazon.com.mx',
    marketplaceSellerCentralUrl: 'https://sellercentral.amazon.com.mx',
    marketplaceVendorCentralUrl: 'https://vendorcentral.amazon.com.mx',
    region: 'na',
  },
  {
    label: 'NL',
    value: 'NL',
    developerId: '6601-3049-6200',
    devAccountId: 2,
    marketplaceId: 18,
    marketplaceCode: 'A1805IZSGTT6HS',
    marketplace: 'amazon.nl',
    marketplaceSellerCentralUrl: 'https://sellercentral.amazon.nl',
    marketplaceVendorCentralUrl: 'https://vendorcentral.amazon.nl',
    region: 'eu',
  },
  {
    label: 'PL',
    value: 'PL',
    developerId: '6601-3049-6200',
    devAccountId: 2,
    marketplaceId: 23,
    marketplaceCode: 'A1C3SOZRARQ6R3',
    marketplace: 'amazon.pl',
    marketplaceSellerCentralUrl: 'https://sellercentral.amazon.pl',
    marketplaceVendorCentralUrl: 'https://vendorcentral.amazon.pl',
    region: 'eu',
  },
  {
    label: 'SA',
    value: 'SA',
    developerId: '6601-3049-6200',
    devAccountId: 2,
    marketplaceId: 20,
    marketplaceCode: 'A17E79C6D8DWNP',
    marketplace: 'amazon.sa',
    marketplaceSellerCentralUrl: 'https://sellercentral.amazon.sa',
    marketplaceVendorCentralUrl: 'https://vendorcentral.amazon.me',
    region: 'eu',
  },
  {
    label: 'SE',
    value: 'SE',
    developerId: '6601-3049-6200',
    devAccountId: 2,
    marketplaceId: 21,
    marketplaceCode: 'A2NODRKZP88ZB9',
    marketplace: 'amazon.se',
    marketplaceSellerCentralUrl: 'https://sellercentral.amazon.se',
    marketplaceVendorCentralUrl: 'https://vendorcentral.amazon.se',
    region: 'eu',
  },
  {
    label: 'SG',
    value: 'SG',
    developerId: '0121-7713-7889',
    devAccountId: 3,
    marketplaceId: 22,
    marketplaceCode: 'A19VAU5U5O7RUS',
    marketplace: 'amazon.sg',
    marketplaceSellerCentralUrl: 'https://sellercentral.amazon.sg',
    marketplaceVendorCentralUrl: 'https://vendorcentral.amazon.com.sg',
    region: 'fe',
  },
  {
    label: 'TR',
    value: 'TR',
    developerId: '6601-3049-6200',
    devAccountId: 2,
    marketplaceId: 15,
    marketplaceCode: 'A33AVAJ2PDY3EV',
    marketplace: 'amazon.com.tr',
    marketplaceSellerCentralUrl: 'https://sellercentral.amazon.com.tr',
    marketplaceVendorCentralUrl: 'https://vendorcentral.amazon.com.tr',
    region: 'eu',
  },
  {
    label: 'UK',
    value: 'GB',
    developerId: '6601-3049-6200',
    devAccountId: 2,
    marketplaceId: 11,
    marketplaceCode: 'A1F83G8C2ARO7P',
    marketplace: 'amazon.co.uk',
    marketplaceSellerCentralUrl: 'https://sellercentral-europe.amazon.com',
    marketplaceVendorCentralUrl: 'https://vendorcentral.amazon.co.uk',
    region: 'eu',
  },
  {
    label: 'US',
    value: 'US',
    developerId: '7432-2529-1567',
    devAccountId: 1,
    marketplaceId: 3,
    marketplaceCode: 'ATVPDKIKX0DER',
    marketplace: 'amazon.com',
    marketplaceSellerCentralUrl: 'https://sellercentral.amazon.com',
    marketplaceVendorCentralUrl: 'https://vendorcentral.amazon.com',
    region: 'na',
  },
];

const countryOptionDefault = countryOptionsArray[(countryOptionsArray.length - 1)];

export {
  countryOptionsArray,
  countryOptionDefault,
};
