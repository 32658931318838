/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import {
  Container, Col, Row,
} from 'reactstrap';
import Select from 'react-select';
import { connect } from 'react-redux';
import moment from 'moment';
import Panel from '../../../shared/components/Panel';
import MetricLine from '../../../shared/components/widgets/MetricLine';
import SelectBoxDynamic from '../../../shared/components/widgets/SelectBoxDynamic';
import config from '../../../config';
import {
  formatNumberDefaultToZero, currencyFormatterWhole, formatDecimalToFixedTwoPercent, currencyFormatter, // formatFixedTwoPercent,
} from '../../../shared/components/table/functions';
import FullWidthFillGraph from '../../../shared/components/widgets/FullWidthFillGraph';
import DeltaChange from '../../../shared/components/widgets/DeltaChange';
import { SellerSelect, User, Theme } from '../../../shared/prop-types/MainProps';
// import AccountSummaryButtons from './components/AccountSummaryButtons';
import style, { DROPDOWN_COLORS } from '../../../shared/components/themeSupport';
import VendorReportMessage from '../../../shared/components/widgets/VendorReportMessage';
import { doFetch } from '../../../helpers';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class AccountSummaryPage extends PureComponent {
  static propTypes = {
    sellerSelect: SellerSelect.isRequired,
    user: User.isRequired,
    theme: Theme.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      graphData: [],
      loadingData: false,
      loadingDataMetrics: false,
      selectedOptions: [],
      startSelectedOption: {},
      endSelectedOption: {},
      // dataTotals: {},
      trendSelectedOptions: [{ label: 'Week Over Week', value: 'week-over-week-aviability', title: 'Week' }, { label: 'Month Over Month', value: 'month-over-month-aviability', title: 'Month' }],
      trendSelectedOption: { label: 'Week Over Week', value: 'week-over-week-aviability', title: 'Week' },
      lineListTop: ['spend', 'sales'],
      tickFormatter: number => number,
      bottomGraphDataKey: '',
      bottomGraphTitle: '',
      lineList: [
        {
          label: 'Sales',
          value: 'first_sales',
          pos: 'left',
          type: 'dollar',
          fill: 0,
        },
        {
          label: 'Sales',
          value: 'second_sales',
          pos: 'left',
          type: 'dollar',
          fill: 0.2,
        },
      ],
      leftGraphOptions: [
        {
          label: 'Impressions', value: 'impressions', pos: 'left', type: 'number',
        },
        {
          label: 'Clicks', value: 'clicks', pos: 'left', type: 'number',
        },
        {
          label: 'Spend', value: 'spend', pos: 'left', type: 'dollar',
        },
        {
          label: 'Sales', value: 'sales', pos: 'left', type: 'dollar',
        },
        {
          label: 'PACoS', value: 'pacos', pos: 'left', type: 'percent',
        },
        {
          label: 'CTR', value: 'ctr', pos: 'left', type: 'percent',
        },
        {
          label: 'CPC', value: 'cpc', pos: 'left', type: 'dollar',
        },
        {
          label: 'Units', value: 'units', pos: 'left', type: 'number',
        },
        {
          label: 'Orders', value: 'orders', pos: 'left', type: 'number',
        },
        {
          label: 'Shipped COGs', value: 'shipped_cogs', pos: 'left', type: 'number',
        },
        {
          label: 'Shipped Units', value: 'shipped_units', pos: 'left', type: 'number',
        },
        {
          label: 'Ordered Units', value: 'ordered_units', pos: 'left', type: 'number',
        },
        {
          label: 'Customer Returns', value: 'customer_returns', pos: 'left', type: 'number',
        },
        {
          label: 'Free Replacements', value: 'free_replacements', pos: 'left', type: 'number',
        },
        {
          label: 'Ordered Revenue', value: 'ordered_revenue', pos: 'left', type: 'dollar',
        },
      ],
      leftGraphOption: {
        label: 'Sales',
        value: 'sales',
        pos: 'left',
        type: 'dollar',
      },
    };
  }

  componentDidMount() {
    const { sellerSelect } = this.props;
    if (sellerSelect.type === 2) {
      this.getDropdownData();
    }
  }

  componentDidUpdate(prevState) {
    const { sellerSelect } = this.props;
    if (sellerSelect.value !== prevState.sellerSelect.value) {
      this.getDropdownData();
    }
  }

  handleStartChange = (value) => {
    const { leftGraphOption } = this.state;
    this.setState({
      startSelectedOption: value,
      loadingDataMetrics: true,
    }, () => {
      this.handleLeftChange(leftGraphOption);
      this.getData();
    });
  }

  handleEndChange = (value) => {
    const { leftGraphOption } = this.state;
    this.setState({
      endSelectedOption: value,
      loadingDataMetrics: true,
    }, () => {
      this.handleLeftChange(leftGraphOption);
      this.getData();
    });
  }

  handleTrendChange = (value) => {
    this.setState({
      trendSelectedOption: value,
      loadingDataMetrics: true,
    }, () => this.getDropdownData());
  }

  handleLeftChange = (selectedValue) => {
    const { startSelectedOption, endSelectedOption } = this.state;
    const firstLeftObj = Object.create(selectedValue);
    firstLeftObj.label = `${selectedValue.label} (${startSelectedOption.label})`;
    firstLeftObj.value = `first_${selectedValue.value}`;
    const secondLeftObj = Object.create(selectedValue);
    secondLeftObj.value = `second_${selectedValue.value}`;
    secondLeftObj.label = `${selectedValue.label} (${endSelectedOption.label})`;
    secondLeftObj.fill = 0.2;

    this.setState({
      leftGraphOption: selectedValue,
      lineList: [firstLeftObj, secondLeftObj],
    });
  }

  // buttonAction = (actionName) => {
  //   let graphItems = [];
  //   let tickFormatter = number => number;

  //   if (actionName === 'PPC_UNIT_PERCENT') {
  //     graphItems = ['units', 'shipped_units'];
  //     tickFormatter = percent => `${percent}%`;
  //   }
  //   if (actionName === 'DELIVERY') {
  //     graphItems = ['impressions', 'clicks'];
  //   }
  //   if (actionName === 'ROI') {
  //     graphItems = ['spend', 'sales'];
  //     tickFormatter = dollar => currencyFormatter({ value: dollar });
  //   }
  //   if (actionName === 'EFFICIENCY') {
  //     graphItems = ['cpc', 'spc'];
  //   }
  //   this.setState({
  //     lineListTop: graphItems,
  //     tickFormatter,
  //   });
  // }

  getData = () => {
    const { sellerSelect, user } = this.props;
    const {
      startSelectedOption, endSelectedOption, trendSelectedOption,
    } = this.state;
    const startAcctSummaryUrl = `${apiUrl}/vendors/account-summary?sellerAccountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}&startDate=${startSelectedOption.value.startDate}&endDate=${startSelectedOption.value.endDate}`;
    const endAcctSummaryUrl = `${apiUrl}/vendors/account-summary?sellerAccountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}&startDate=${endSelectedOption.value.startDate}&endDate=${endSelectedOption.value.endDate}`;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    this.setState({
      loadingData: true,
    });

    const promises = [];
    promises.push(doFetch(startAcctSummaryUrl, requestOptions));
    promises.push(doFetch(endAcctSummaryUrl, requestOptions));

    Promise.all(promises).then((responses) => {
      const startAcctSummary = responses[0];
      const endAcctSummary = responses[1];

      const formatDataPoint = function formatDataPoint(type, dataPoint) {
        const currencyWholeType = ['spend', 'sales'];
        if (currencyWholeType.includes(type)) {
          return currencyFormatterWhole({ value: dataPoint });
        }
        const currencyType = ['ppc_shipped_cogs', 'shipped_cogs', 'roas', 'cpc', 'rpc', 'Cost Per Mille (CPM)', 'Revenue Per Impression (RPI)', 'Cost per Acquisition (CPA)', 'total_roas', 'ordered_revenue'];
        if (currencyType.includes(type)) {
          return currencyFormatter({ value: dataPoint });
        }
        const decimalPercentType = ['pacos', 'cvr', 'ppc_unit_percent', 'total_acos'];
        if (decimalPercentType.includes(type)) {
          return formatDecimalToFixedTwoPercent({ value: dataPoint });
        }
        return formatNumberDefaultToZero({ value: dataPoint });
      };

      const getDelta = (type, rangeOneValue, rangeTwoValue, colorControl = 'normal') => {
        const delta = rangeTwoValue - rangeOneValue;
        const deltaPercent = (rangeTwoValue - rangeOneValue) / rangeOneValue;
        if (delta > 0) {
          if (colorControl === 'noColor') {
            return (<span>{formatDataPoint(type, delta)} {String.fromCharCode(8679)} +{formatDecimalToFixedTwoPercent({ value: deltaPercent })} </span>);
          }
          if (colorControl === 'reverse') {
            return (<span style={{ color: '#d04960' }}>{formatDataPoint(type, delta)} {String.fromCharCode(8679)} +{formatDecimalToFixedTwoPercent({ value: deltaPercent })} </span>);
          }
          return (<span style={{ color: '#03c03c' }}>{formatDataPoint(type, delta)} {String.fromCharCode(8679)} +{formatDecimalToFixedTwoPercent({ value: deltaPercent })} </span>);
        }
        if (delta < 0) {
          if (colorControl === 'noColor') {
            return (<span>{formatDataPoint(type, delta)} {String.fromCharCode(8681)} {formatDecimalToFixedTwoPercent({ value: deltaPercent })} </span>);
          }
          if (colorControl === 'reverse') {
            return (<span style={{ color: '#03c03c' }}>{formatDataPoint(type, delta)} {String.fromCharCode(8681)} {formatDecimalToFixedTwoPercent({ value: deltaPercent })} </span>);
          }
          return (<span style={{ color: '#d04960' }}>{formatDataPoint(type, delta)} {String.fromCharCode(8681)} {formatDecimalToFixedTwoPercent({ value: deltaPercent })} </span>);
        }
        return (<span>{formatDataPoint(type, delta)} {String.fromCharCode(8691)} - </span>);
      };

      const metricOrder = [
        { label: 'Shipped COGs', value: 'shipped_cogs', pos: 1 },
        {
          label: 'PPC Shipped COGS', value: 'ppc_shipped_cogs', pos: 2, colorControl: 'normal',
        },
        { label: 'PPC Sales', value: 'sales', pos: 3 },
        {
          label: 'PPC Spend', value: 'spend', pos: 4, colorControl: 'noColor',
        },
        {
          label: 'PACoS', value: 'pacos', pos: 5, colorControl: 'reverse',
        },
        { label: 'PPC RoAS', value: 'roas', pos: 6 },
        { label: 'Shipped Units', value: 'shipped_units', pos: 7 },
        { label: 'PPC Units', value: 'units', pos: 8 },
        { label: 'PPC Orders', value: 'orders', pos: 9 },
        {
          label: 'Cost Per Click (CPC)', value: 'cpc', pos: 10, colorControl: 'noColor',
        },
        { label: 'Revenue Per Click (RPC)', value: 'rpc', pos: 11 },
        { label: 'Conversion Rate (CVR)', value: 'cvr', pos: 12 },
        { label: 'PPC Unit %', value: 'ppc_unit_percent', pos: 13 },
        {
          label: 'PPC Unit %', value: 'ppc_unit_percent', pos: 14, colorControl: 'normal',
        },
        {
          label: 'Impressions', value: 'impressions', pos: 15, colorControl: 'normal',
        },
        {
          label: 'Clicks', value: 'clicks', pos: 16, colorControl: 'normal',
        },
        {
          label: 'Customer returns', value: 'customer_returns', pos: 17, colorControl: 'reverse',
        },
        {
          label: 'Free replacements', value: 'free_replacements', pos: 18, colorControl: 'reverse',
        },
        {
          label: 'Cost Per Mille (CPM)', value: 'Cost Per Mille (CPM)', pos: 19, colorControl: 'noColor',
        },
        {
          label: 'Revenue Per Impression (RPI)', value: 'Revenue Per Impression (RPI)', pos: 20, colorControl: 'noColor',
        },
        {
          label: 'Cost per Acquisition (CPA)', value: 'Cost per Acquisition (CPA)', pos: 21, colorControl: 'noColor',
        },
        {
          label: 'Ordered Units', value: 'ordered_units', pos: 22, colorControl: 'normal',
        },
        {
          label: 'Ordered Revenue', value: 'ordered_revenue', pos: 23, colorControl: 'normal',
        },
        {
          label: 'Total ACoS', value: 'total_acos', pos: 24, colorControl: 'reverse',
        },
        {
          label: 'Total RoAS', value: 'total_roas', pos: 25, colorControl: 'normal',
        },
        {
          label: 'Average Rank', value: 'avg_rank', pos: 26, colorControl: 'reverse',
        },
        {
          label: 'ASINs with rank of 100 or better', value: 'asin_under_100_rank', pos: 27, colorControl: 'normal',
        },
      ];

      const displayKey = (key) => {
        const foundRec = metricOrder.find(row => row.value === key);
        if (foundRec) {
          return foundRec.label;
        }
        const cleanKey = key.replace(/_/g, ' ');
        return cleanKey.charAt(0).toUpperCase() + cleanKey.slice(1);
      };

      const getPos = (key) => {
        const foundRec = metricOrder.find(row => row.value === key);
        if (foundRec) {
          return foundRec.pos;
        }
        return 100;
      };

      const getColor = (key) => {
        const foundRec = metricOrder.find(row => row.value === key);
        if (foundRec) {
          return (foundRec.colorControl || 'normal');
        }
        return 'normal';
      };

      const data = [];
      Object.keys(startAcctSummary.totals).forEach((key) => {
        data.push({
          pos: getPos(key), metric: displayKey(key), rangeOne: formatDataPoint(key, startAcctSummary.totals[key]), performance: getDelta(key, startAcctSummary.totals[key], endAcctSummary.totals[key], getColor(key)), rangeTwo: formatDataPoint(key, endAcctSummary.totals[key]),
        });
      });

      // Calculated Metrics
      let key = 'pacos';
      let rangeOneData = startAcctSummary.totals.spend / startAcctSummary.totals.sales;
      let rangeTwoData = endAcctSummary.totals.spend / endAcctSummary.totals.sales;
      data.push({
        pos: getPos(key), metric: displayKey(key), rangeOne: formatDataPoint(key, rangeOneData), performance: getDelta(key, rangeOneData, rangeTwoData, getColor(key)), rangeTwo: formatDataPoint(key, rangeTwoData),
      });

      key = 'cpc';
      rangeOneData = startAcctSummary.totals.spend / startAcctSummary.totals.clicks;
      rangeTwoData = endAcctSummary.totals.spend / endAcctSummary.totals.clicks;
      data.push({
        pos: getPos(key), metric: displayKey(key), rangeOne: formatDataPoint(key, rangeOneData), performance: getDelta(key, rangeOneData, rangeTwoData, getColor(key)), rangeTwo: formatDataPoint(key, rangeTwoData),
      });

      key = 'rpc';
      rangeOneData = startAcctSummary.totals.sales / startAcctSummary.totals.clicks;
      rangeTwoData = endAcctSummary.totals.sales / endAcctSummary.totals.clicks;
      data.push({
        pos: getPos(key), metric: displayKey(key), rangeOne: formatDataPoint(key, rangeOneData), performance: getDelta(key, rangeOneData, rangeTwoData, getColor(key)), rangeTwo: formatDataPoint(key, rangeTwoData),
      });

      key = 'Cost Per Mille (CPM)';
      rangeOneData = startAcctSummary.totals.spend / (startAcctSummary.totals.impressions / 1000);
      rangeTwoData = endAcctSummary.totals.spend / (endAcctSummary.totals.impressions / 1000);
      data.push({
        pos: getPos(key), metric: displayKey(key), rangeOne: formatDataPoint(key, rangeOneData), performance: getDelta(key, rangeOneData, rangeTwoData, getColor(key)), rangeTwo: formatDataPoint(key, rangeTwoData),
      });

      key = 'Revenue Per Impression (RPI)';
      rangeOneData = startAcctSummary.totals.sales / startAcctSummary.totals.impressions;
      rangeTwoData = endAcctSummary.totals.sales / endAcctSummary.totals.impressions;
      data.push({
        pos: getPos(key), metric: displayKey(key), rangeOne: formatDataPoint(key, rangeOneData), performance: getDelta(key, rangeOneData, rangeTwoData, getColor(key)), rangeTwo: formatDataPoint(key, rangeTwoData),
      });

      key = 'Cost per Acquisition (CPA)';
      rangeOneData = startAcctSummary.totals.spend / startAcctSummary.totals.orders;
      rangeTwoData = endAcctSummary.totals.spend / endAcctSummary.totals.orders;
      data.push({
        pos: getPos(key), metric: displayKey(key), rangeOne: formatDataPoint(key, rangeOneData), performance: getDelta(key, rangeOneData, rangeTwoData, getColor(key)), rangeTwo: formatDataPoint(key, rangeTwoData),
      });

      key = 'cvr';
      rangeOneData = startAcctSummary.totals.orders / startAcctSummary.totals.clicks;
      rangeTwoData = endAcctSummary.totals.orders / endAcctSummary.totals.clicks;
      data.push({
        pos: getPos(key), metric: displayKey(key), rangeOne: formatDataPoint(key, rangeOneData), performance: getDelta(key, rangeOneData, rangeTwoData, getColor(key)), rangeTwo: formatDataPoint(key, rangeTwoData),
      });

      key = 'roas';
      rangeOneData = startAcctSummary.totals.sales / startAcctSummary.totals.spend;
      rangeTwoData = endAcctSummary.totals.sales / endAcctSummary.totals.spend;
      data.push({
        pos: getPos(key), metric: displayKey(key), rangeOne: formatDataPoint(key, rangeOneData), performance: getDelta(key, rangeOneData, rangeTwoData, getColor(key)), rangeTwo: formatDataPoint(key, rangeTwoData),
      });

      key = 'ppc_unit_percent';
      rangeOneData = startAcctSummary.totals.units / startAcctSummary.totals.shipped_units;
      rangeTwoData = endAcctSummary.totals.units / endAcctSummary.totals.shipped_units;
      data.push({
        pos: getPos(key), metric: displayKey(key), rangeOne: formatDataPoint(key, rangeOneData), performance: getDelta(key, rangeOneData, rangeTwoData, getColor(key)), rangeTwo: formatDataPoint(key, rangeTwoData),
      });

      key = 'ppc_shipped_cogs';
      rangeOneData = (startAcctSummary.totals.shipped_cogs / startAcctSummary.totals.shipped_units) * startAcctSummary.totals.units;
      rangeTwoData = (endAcctSummary.totals.shipped_cogs / endAcctSummary.totals.shipped_units) * endAcctSummary.totals.units;
      data.push({
        pos: getPos(key), metric: displayKey(key), rangeOne: formatDataPoint(key, rangeOneData), performance: getDelta(key, rangeOneData, rangeTwoData, getColor(key)), rangeTwo: formatDataPoint(key, rangeTwoData),
      });

      key = 'total_roas';
      rangeOneData = (startAcctSummary.totals.ordered_revenue / startAcctSummary.totals.spend);
      rangeTwoData = (endAcctSummary.totals.ordered_revenue / endAcctSummary.totals.spend);
      data.push({
        pos: getPos(key), metric: displayKey(key), rangeOne: formatDataPoint(key, rangeOneData), performance: getDelta(key, rangeOneData, rangeTwoData, getColor(key)), rangeTwo: formatDataPoint(key, rangeTwoData),
      });

      key = 'total_acos';
      rangeOneData = (startAcctSummary.totals.spend / startAcctSummary.totals.ordered_revenue);
      rangeTwoData = (endAcctSummary.totals.spend / endAcctSummary.totals.ordered_revenue);
      data.push({
        pos: getPos(key), metric: displayKey(key), rangeOne: formatDataPoint(key, rangeOneData), performance: getDelta(key, rangeOneData, rangeTwoData, getColor(key)), rangeTwo: formatDataPoint(key, rangeTwoData),
      });

      const dataSorted = data.sort((a, b) => {
        const checkPos = (b.pos > a.pos) ? -1 : 0;
        return (b.pos < a.pos) ? 1 : checkPos;
      });

      const graphData = endAcctSummary.daily_data.sort((a, b) => {
        const checkPos = (b.report_date > a.report_date) ? -1 : 0;
        return (b.report_date < a.report_date) ? 1 : checkPos;
      });

      let bottomGraphDataKey = 'day_of_week';
      let bottomGraphTitle = 'Compare Week over Week';

      graphData.forEach((item) => {
        Object.keys(item).forEach((e) => {
          // eslint-disable-next-line
          item.day_of_week = moment(item.report_date).format('dddd');
          // eslint-disable-next-line
          item.day_of_month = moment(item.report_date).format('DD');
          // eslint-disable-next-line
          item[`second_${e}`] = item[e];
        });
        let startAcctSummaryRowAdd = startAcctSummary.daily_data.find(startAcctSummaryRow => moment(startAcctSummaryRow.report_date).format('dddd') === item.day_of_week);
        if (trendSelectedOption.title === 'Month') {
          startAcctSummaryRowAdd = startAcctSummary.daily_data.find(startAcctSummaryRow => moment(startAcctSummaryRow.report_date).format('DD') === item.day_of_month);
          bottomGraphDataKey = 'day_of_month';
          bottomGraphTitle = 'Compare Month over Month';
        }
        if (startAcctSummaryRowAdd) {
          Object.keys(startAcctSummaryRowAdd).forEach((e) => {
            // eslint-disable-next-line
            item[`first_${e}`] = startAcctSummaryRowAdd[e];
          });
        }
      });

      this.setState({
        loadingData: false,
        loadingDataMetrics: false,
        data: dataSorted,
        graphData,
        bottomGraphDataKey,
        bottomGraphTitle,
      });
    }).catch(() => {
      this.setState({
        loadingData: false,
        loadingDataMetrics: false,
      });
    });
  };

  getDropdownData = () => {
    // dateRange-over-dateRange-aviability
    const { trendSelectedOption } = this.state;
    const { sellerSelect, user } = this.props;
    const dateRangeOverDateRangeUrl = `${apiUrl}/big-query/${trendSelectedOption.value}?sellerAccountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}&datasetId=vendor_sales_diagnostic&tableType=_S_`;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    this.setState({
      loadingData: true,
      loadingDataMetrics: true,
    });

    fetch(dateRangeOverDateRangeUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        const selectedOptions = [];
        data.forEach((row) => {
          if (trendSelectedOption.title === 'Week') {
            selectedOptions.unshift({ label: `Week ${row.week}: ${moment(row.dates[0]).format('YYYY-MM-DD')} - ${moment(row.dates[6]).format('YYYY-MM-DD')}`, value: { startDate: row.dates[0], endDate: row.dates[6] } });
          } else {
            selectedOptions.unshift({ label: `${moment().month(row.month).format('MMMM')}: ${moment(row.dates[0]).format('YYYY-MM-DD')} - ${moment(row.dates[row.dates.length - 1]).format('YYYY-MM-DD')}`, value: { startDate: row.dates[0], endDate: row.dates[row.dates.length - 1] } });
          }
        });

        let compareOption = 0;
        if (selectedOptions.length > 1) {
          compareOption = 1;
        }

        if (data.length > 0) {
          this.setState({
            loadingData: false,
            selectedOptions,
            startSelectedOption: selectedOptions[compareOption],
            endSelectedOption: selectedOptions[0],
          }, () => {
            this.handleLeftChange({
              label: 'Sales',
              value: 'sales',
              pos: 'left',
              type: 'dollar',
            });
            this.getData();
          });
        } else {
          this.setState({
            loadingData: false,
            loadingDataMetrics: false,
            data: [],
            graphData: [],
            selectedOptions: [{ label: '... data not available', value: {} }],
            startSelectedOption: { label: '... data not available', value: {} },
            endSelectedOption: { label: '... data not available', value: {} },
          });
        }
      }).catch(() => {
        this.setState({
          loadingData: false,
        });
      });
  }

  render() {
    const {
      data, loadingData, loadingDataMetrics, reportStartDate, reportEndDate, trendSelectedOptions, trendSelectedOption,
      selectedOptions, startSelectedOption, endSelectedOption, graphData, leftGraphOption, leftGraphOptions,
      lineList, lineListTop, tickFormatter, bottomGraphDataKey, bottomGraphTitle,
    } = this.state;

    const { sellerSelect, theme } = this.props;
    const themeColors = style(theme, DROPDOWN_COLORS);

    let shippedCogsHighlight = 0;
    let shippedCogsChange = 0;
    let ppcSalesHighlight = 0;
    let ppcSalesChange = 0;
    let roasHighlight = 0;
    let roasChange = 0;
    let orderedRevenueHighlight = 0;
    let orderedRevenueChange = 0;
    let troasHighlight = 0;
    let troasChange = 0;
    let tacosHighlight = 0;
    let tacosChange = 0;
    data.forEach((item) => {
      if (item.metric === 'Shipped COGs') {
        shippedCogsHighlight = item.rangeTwo;
        shippedCogsChange = item.performance;
      }
      if (item.metric === 'PPC Sales') {
        ppcSalesHighlight = item.rangeTwo;
        ppcSalesChange = item.performance;
      }
      if (item.metric === 'PPC RoAS') {
        roasHighlight = item.rangeTwo;
        roasChange = item.performance;
      }
      if (item.metric === 'Ordered Revenue') {
        orderedRevenueHighlight = item.rangeTwo;
        orderedRevenueChange = item.performance;
      }
      if (item.metric === 'Total ACoS') {
        tacosHighlight = item.rangeTwo;
        tacosChange = item.performance;
      }
      if (item.metric === 'Total RoAS') {
        troasHighlight = item.rangeTwo;
        troasChange = item.performance;
      }
    });

    let leftTickFormatter = number => formatNumberDefaultToZero({ value: number });

    if (leftGraphOption.type === 'dollar') {
      leftTickFormatter = dollar => currencyFormatter({ value: dollar });
    }

    if (leftGraphOption.type === 'percent') {
      leftTickFormatter = percent => `${percent}%`;
    }

    if (sellerSelect.type === 2) {
      return (
        <Container className="dashboard">
          <Col md={12}>
            <Row>
              <DeltaChange
                content={{
                  title: 'Shipped COGs',
                  header: endSelectedOption.label,
                  data: shippedCogsHighlight,
                  delta: shippedCogsChange,
                }}
                loadingData={loadingData}
              />
              <DeltaChange
                content={{
                  title: 'PPC Sales',
                  header: endSelectedOption.label,
                  data: ppcSalesHighlight,
                  delta: ppcSalesChange,
                }}
                loadingData={loadingData}
              />
              <DeltaChange
                content={{
                  title: 'PPC RoAS',
                  header: endSelectedOption.label,
                  data: roasHighlight,
                  delta: roasChange,
                }}
                loadingData={loadingData}
              />
            </Row>
            <Row>
              <DeltaChange
                content={{
                  title: 'Ordered Revenue',
                  header: endSelectedOption.label,
                  data: orderedRevenueHighlight,
                  delta: orderedRevenueChange,
                }}
                loadingData={loadingData}
              />
              <DeltaChange
                content={{
                  title: 'Total ACoS',
                  header: endSelectedOption.label,
                  data: tacosHighlight,
                  delta: tacosChange,
                }}
                loadingData={loadingData}
              />
              <DeltaChange
                content={{
                  title: 'Total RoAS',
                  header: endSelectedOption.label,
                  data: troasHighlight,
                  delta: troasChange,
                }}
                loadingData={loadingData}
              />
            </Row>
          </Col>
          {/* <Col md={12}>
            <Row>
              <AccountSummaryButtons
                buttonAction={this.buttonAction}
                initalLoad={loadingData}
              />
            </Row>
          </Col> */}
          <Col md={12}>
            <Row>
              <FullWidthFillGraph
                data={graphData}
                dataType="report_date"
                title={endSelectedOption.label}
                initalLoad={loadingData}
                lineList={lineListTop}
                tickFormatter={tickFormatter}
                fillOpacity={0.2}
              />
            </Row>
          </Col>
          <Col md={12}>
            <Row>
              <SelectBoxDynamic
                pageTitle="Trend"
                loadingData={loadingData}
                mdWidth={4}
                selectOptions={trendSelectedOptions}
                selectedOption={trendSelectedOption}
                handleSelectChange={this.handleTrendChange}
                theme={theme}
              />
              <SelectBoxDynamic
                pageTitle={`Previous ${trendSelectedOption.title}`}
                loadingData={loadingData}
                mdWidth={4}
                selectOptions={selectedOptions}
                selectedOption={startSelectedOption}
                handleSelectChange={this.handleStartChange}
                theme={theme}
              />
              <SelectBoxDynamic
                pageTitle={`Latest ${trendSelectedOption.title}`}
                loadingData={loadingData}
                mdWidth={4}
                selectOptions={selectedOptions}
                selectedOption={endSelectedOption}
                handleSelectChange={this.handleEndChange}
                theme={theme}
              />
            </Row>
          </Col>
          <Col md={12}>
            <Row>
              <MetricLine
                data={data}
                content={{
                  title: 'Account Metrics',
                  metricHeader: 'Metric',
                  metricPerformanceHeader: 'Performance',
                  metricRangeOne: startSelectedOption.label,
                  metricRangeTwo: endSelectedOption.label,
                }}
                loadingData={loadingDataMetrics}
                reportStartDate={reportStartDate}
                reportEndDate={reportEndDate}
                lgWidth={12}
              />
            </Row>
          </Col>
          <Col md={12}>
            <Row>
              <Panel
                lg={12}
                md={12}
                title={bottomGraphTitle}
                subhead=""
                parentRefresh={loadingData}
              >
                <div className="topbar__dynamicDropdownWidth">
                  <Select
                    value={leftGraphOption}
                    onChange={this.handleLeftChange}
                    options={leftGraphOptions}
                    theme={selectTheme => ({
                      ...selectTheme,
                      colors: {
                        ...selectTheme.colors,
                        primary: `${themeColors.colorPrimary}`,
                        neutral0: `${themeColors.colorBackground}`,
                        neutral80: `${themeColors.colorText}`,
                      },
                    })}
                  />
                </div>
              </Panel>
            </Row>
          </Col>
          <Col md={12}>
            <Row>
              <FullWidthFillGraph
                data={graphData}
                dataKey={bottomGraphDataKey}
                title={bottomGraphTitle}
                initalLoad={loadingData}
                lineList={lineList}
                tickFormatter={leftTickFormatter}
                colorOptions={['#d04960', '#1de224']}
                useFill={false}
                strokeWidth={3}
              />
            </Row>
          </Col>
        </Container>
      );
    }
    return (
      <VendorReportMessage />
    );
  }
}

const mapStateToProps = (state) => {
  const { theme, sellerSelect } = state;
  const { user } = state.authentication;

  return {
    sellerSelect,
    theme,
    user,
  };
};

export default connect(mapStateToProps)(AccountSummaryPage);
