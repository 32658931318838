import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ManageCalendar from './components/ManageCalendar';
import Panel from '../../../shared/components/Panel';


const CalendarManager = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Calendar Event Manager</h3>
        <h3 className="page-subhead subhead">Manage your events.
        </h3>
      </Col>
    </Row>
    <Row>
      <Panel lg={12} title="Event Managemnt Notes">
        <div>
          <p>
            DateTime is in the format: YYYY-MM-DD HH24:MI <br />
            Event Priority can use a default (high, medium, normal, low) setting or custom color code with a hex value (e.g. #E4DDBD)
          </p>
        </div>
      </Panel>
    </Row>
    <Row>
      <ManageCalendar />
    </Row>
  </Container>
);

export default CalendarManager;
