import { useState, useEffect } from 'react';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { isLimitless, isAdRev, getPlatform } from '../domainSupport';
import config from '../../../configWrapper';

export default function useAgency() {
  const [agency, setAgency] = useState(null);
  const [matchAgency, setMatchAgency] = useState(null);
  const [onboardingAgency, setOnboardingAgency] = useState(null);
  const [searchParams, setSearchParams] = useState(null);
  const [onboardingId, setOnboardingId] = useState(null);
  const match = useRouteMatch({
    path: '/onboarding/:agencyCode',
    exact: false,
  });
  const location = useLocation();
  const platformObj = getPlatform(null);
  const isValidAgency = code => platformObj.agencies.map(node => node.name).includes(code);

  useEffect(() => {
    const params = (match && match.params ? match.params : '') || '';
    const agencyCode = params && isValidAgency(params.agencyCode) ? match.params.agencyCode : '';
    setMatchAgency(agencyCode);
  }, [match, setMatchAgency]);

  useEffect(() => {
    const { search } = location;
    if (search) {
      setSearchParams(search);
    }
  }, [location, setSearchParams]);

  useEffect(() => {
    const params = new URLSearchParams(searchParams);
    const id = params.get('state');
    if (id && id > 0) {
      setOnboardingId(id);
    }
  }, [searchParams, setOnboardingId]);

  useEffect(() => {
    // this is used when we have a return url and we need to deterime the agency with state parameter
    function getOnboardingAgency(id) {
      const onboardAgencyUrl = `${config.serverUrl}/onboarding/get-onboard-agency?state=${id}`;
      return fetch(onboardAgencyUrl, { headers: config.jsonHeader })
        .then((results) => {
          if (!results.ok) {
            throw results;
          }
          return results.json();
        })
        .then(data => data.agency || '')
        .catch(() => '');
    }
    if (onboardingId) {
      getOnboardingAgency(onboardingId).then((agencyCode) => {
        const theAgency = isValidAgency(agencyCode) ? agencyCode : '';
        setOnboardingAgency(theAgency);
      });
    }
  }, [onboardingId, setOnboardingAgency]);

  useEffect(() => {
    let finalAgency = '';
    if (matchAgency !== '' && isValidAgency(matchAgency)) {
      finalAgency = matchAgency;
    } else if (onboardingAgency !== '' && isValidAgency(onboardingAgency)) {
      finalAgency = onboardingAgency;
    } else if (isLimitless()) {
      finalAgency = 'limitless';
    } else if (isAdRev()) {
      finalAgency = 'adrev';
    } else {
      finalAgency = 'adjuggernaut';
    }
    setAgency(finalAgency);
  }, [matchAgency, onboardingAgency, setAgency]);

  return agency;
}
