import React from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import Calculator from './components/Calculator';
import Panel from '../../../../shared/components/Panel';

export default function CalculatorContent() {
  return (
    <Container className="dashboard">
      <Col xxl={4}>
        &nbsp;
      </Col>
      <Col xxl={4}>
        <Row>
          <Panel
            showButtons={false}
          >
            <Calculator />
          </Panel>
        </Row>
      </Col>
    </Container>
  );
}
