import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Col, Container, Row } from 'reactstrap';
import ReportFilters from './components/ReportFilters';
import ReportGraph from './components/ReportGraph';
import { serverUrl, jsonHeader } from '../../../../config';

const QueryReportsPage = () => {
  const sellerSelect = useSelector(state => state.sellerSelect);
  const user = useSelector(store => store.authentication.user);
  const [startDate, setStartDate] = useState(moment().subtract(7, 'days'));
  const [endDate, setEndDate] = useState(moment().subtract(1, 'days'));
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState({ label: 'AD Group', value: 'ad_group' });
  const [graphData, setGraphData] = useState(null);

  useEffect(() => {
    const queryReportUrl = `${serverUrl}/walmart/query/report/${sellerSelect.value}?startDate=${startDate.format('YYYYMMDD')}&endDate=${endDate.format('YYYYMMDD')}&accountType=${sellerSelect.type}&dataset=${report.value}`;

    const headerWithAuth = { ...jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    setLoading(true);
    setGraphData([]);

    fetch(queryReportUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        const graphDataSorted = data.sort((a, b) => {
          const checkPos = (b.date < a.date) ? -1 : 0;
          return (b.date > a.date) ? 1 : checkPos;
        });

        setLoading(false);
        setGraphData(graphDataSorted);
      }).catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [startDate, endDate, sellerSelect, user, report]);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Query Reports</h3>
        </Col>
      </Row>
      <Row>
        <ReportFilters startDate={startDate} endDate={endDate} dateChange={(start, end) => { setStartDate(moment(start)); setEndDate(moment(end)); }} currentReport={report} reportChange={(newReport) => { setReport(newReport); }} loading={loading} />
      </Row>
      <Row>
        <ReportGraph loading={loading} graphData={graphData} />
      </Row>
      {/* <Row>
        <ReportTable />
      </Row> */}
    </Container>
  );
}

export default QueryReportsPage;
