import React from 'react';
import {
  Card, CardBody, Col, ButtonToolbar, ButtonGroup,
} from 'reactstrap';
import { Link } from 'react-router-dom';

const EventLabels = () => (
  <Col md={12} lg={12} xl={3}>
    <Card className="card--not-full-height">
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">event labels</h5>
        </div>
        <p><span className="calendar-label calendar-label--high" /> High priority events</p>
        <p><span className="calendar-label calendar-label--medium" /> Medium priority events</p>
        <p><span className="calendar-label calendar-label--normal" /> Normal events</p>
        <p><span className="calendar-label calendar-label--low" /> Low priority events</p>
        <p><span className="calendar-label calendar-label--custom" /> Custom events (e.g. #E4DDBD)</p>
        {/* <ButtonToolbar>
          <ButtonGroup className="btn-group--justified">
            <Button color="primary" href="#">Create new event</Button>
          </ButtonGroup>
        </ButtonToolbar> */}
        <ButtonToolbar>
          <ButtonGroup className="btn-group--justified">
            <Link className="btn btn-outline-primary" to="/customer/calendar-manager">Manage events</Link>
          </ButtonGroup>
        </ButtonToolbar>
      </CardBody>
    </Card>
  </Col>
);

export default EventLabels;
