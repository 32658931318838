import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import config from '../../../config';
import { sellerSelectActions } from '../../../redux/actions';
import style, { DROPDOWN_COLORS } from '../../../shared/components/themeSupport';
import useFetch from '../../../shared/components/hooks/useFetch';
import { platform } from '../../../shared/components/domainSupport';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

function CustomerSellerSelect({
  options, selected, setSelected,
}) {
  const dispatch = useDispatch();
  const theme = useSelector(state => state.theme);
  const themeColors = style(theme, DROPDOWN_COLORS);

  useEffect(() => {
    if (selected && selected.value > 0) {
      dispatch(sellerSelectActions
        .sellerSelectChange(
          selected.value,
          selected.label,
          selected.type,
          selected.currencySymbol,
          selected.currencyCode,
          selected.parentAccountId,
          selected.mpName,
          selected.agency,
          selected.platform,
          selected.subscriptionId,
          selected.plan,
          selected.planStatus,
          selected.externalCustomerId,
          selected.externalAccountId,
          selected.customerId,
          selected.refreshKey,
        ));
    }
  }, [selected, dispatch]);

  return (
    <div className="topbar__customersellerselect">
      {selected && selected.value > 0 && (
        <Select
          value={selected}
          onChange={choice => setSelected(choice)}
          options={options}
          theme={(selectTheme) => {
            const updatedTheme = {
              ...selectTheme,
              colors: {
                ...selectTheme.colors,
                primary: `${themeColors.colorPrimary}`,
                neutral0: `${themeColors.colorBackground}`,
                neutral80: `${themeColors.colorText}`,
              },
            };
            return updatedTheme;
          }}
        />
      )}
    </div>
  );
}

CustomerSellerSelect.propTypes = {
  options: PropTypes.instanceOf(Array).isRequired,
  selected: PropTypes.instanceOf(Object).isRequired,
  setSelected: PropTypes.func.isRequired,
};

function formatOptions(data, user) {
  const sellerOptions = [];
  if (Array.isArray(data)) {
    data.forEach((seller) => {
      if (seller.platform === platform || seller.platform === platform.replace('adjuggernaut', 'limitless') || user.access === 'admin') { // "replace" Workaround as AdJuggernaut is running on Limitless.
        let label = seller.seller_account_name;
        if (user.access === 'admin') {
          let platfromCode = 'LL';
          const agencyInfo = JSON.parse(seller.agency);
          if (seller.platform === 'adrev') {
            platfromCode = 'AR';
            if (seller.plan_status === 'active') {
              platfromCode = 'AR+';
            } else if (seller.plan_status === 'activeCancelled') {
              platfromCode = 'AR-';
            }
          } else if (seller.platform === 'adjuggernaut' || (seller.platform === 'limitless' && agencyInfo.code !== 'limitless')) {
            platfromCode = `AJ-${agencyInfo.id}`;
          }
          label = `${seller.seller_account_name} ${seller.seller_account_id}:${seller.parent_account_id}:${seller.customer_id}, ${seller.marketplace_name}:${seller.marketplace_currency_symbol}, ${seller.seller_account_type_id} ~${platfromCode}`;
        } else if (user.access === 'agency') {
          const accountType = seller.seller_account_type_id === 1 ? '3p' : '1p';
          label = `${seller.seller_account_name} ${accountType}:${seller.marketplace_name}:${seller.marketplace_currency_symbol}`;
        }
        sellerOptions.push({
          value: seller.seller_account_id,
          label,
          type: seller.seller_account_type_id,
          currencySymbol: seller.marketplace_currency_symbol,
          currencyCode: seller.marketplace_currency_code,
          parentAccountId: seller.parent_account_id,
          mpName: seller.marketplace_name,
          agency: seller.agency,
          platform: seller.platform,
          subscriptionId: seller.subscription_id,
          plan: seller.plan,
          planStatus: seller.plan_status,
          externalCustomerId: seller.external_customer_id,
          externalAccountId: seller.external_account_id,
          customerId: seller.customer_id,
        });
      }
    });
  }
  return sellerOptions;
}

export default function CustomerSelector() {
  const user = useSelector(state => state.authentication.user);
  const sellerSelect = useSelector(state => state.sellerSelect);
  const { results } = useFetch(`${apiUrl}/accounts/active-by-name?refresh=${sellerSelect.refreshKey || 0}`);
  const options = useCallback(formatOptions(results, user), [results, user]);
  const [selected, setSelected] = useState(sellerSelect);

  useEffect(() => {
    if (!sellerSelect || Object.keys(sellerSelect).length === 0 || sellerSelect.value === 0) {
      if (Array.isArray(options) && options.length > 0) {
        setSelected(options[0]);
      }
    }
  }, [options, sellerSelect, setSelected]);

  return (
    <CustomerSellerSelect
      options={options}
      selected={selected}
      setSelected={setSelected}
    />
  );
}
