import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { AgChartsReact } from 'ag-charts-react';
import {
  Row, Col,
} from 'reactstrap';
import Panel from '../../../../../shared/components/Panel';
import SalesMetricFilter, { MetricOptions } from '../../../../../shared/components/widgets/SalesMetricFilter';
import { columnDefs } from './columns';

// eslint-disable-next-line import/prefer-default-export
export function CompetitionMetricGraph({
  title, loading, data, metric, onChange,
}) {
  const theme = useSelector(store => store.theme);
  const options = useMemo(() => ({
    data: data && data.slice(0, 7),
    theme: theme.className.endsWith('light') ? 'ag-default' : 'ag-default-dark',
    title: { text: metric.label },
    // height: 600,
    // width: '100%',
    autoSize: true,
    padding: {
      top: 40,
      bottom: 40,
    },
    legend: {
      position: 'right', // 'top', 'bottom', 'left',
    },
    series: [
      {
        type: 'pie',
        angleKey: metric.value,
        legendItemKey: 'competitor',
        tooltip: {
          renderer: (params) => {
            const column = columnDefs.filter(item => item.field === params.angleKey)[0];
            return `${params.datum.competitor} : ${column.valueFormatter({ value: params.angleValue })}`;
          },
        },
      },
    ],
  }), [title, data, metric, theme]);

  return (
    <Panel
      md={12}
      title={title}
      parentRefresh={loading}
    >
      <Row>
        <Col
          md={4}
        >
          <SalesMetricFilter
            onChange={obj => onChange(obj)}
            disable={loading}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          {data && data.length > 0 && (
            <div style={{ height: '400px' }}>
              <AgChartsReact options={options} />
            </div>
          )}
        </Col>
      </Row>
    </Panel>
  );
}

CompetitionMetricGraph.defaultProps = {
  loading: false,
  data: null,
  metric: MetricOptions.SALES,
  onChange: () => null,
};

CompetitionMetricGraph.propTypes = {
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  data: PropTypes.instanceOf(Array),
  metric: PropTypes.oneOf([...MetricOptions]),
  onChange: PropTypes.func,
};
