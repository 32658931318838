import React from 'react';
import { Container, Row } from 'reactstrap';
import LaunchResults from './components/LaunchResults';
import PhaseOverview from './components/PhaseOverview';

const items1 = [
  {
    product: 'SportDOG Brand YardTrainer Family Remote Trainers - Rechargeable, Waterproof Dog Training Collars with Shock and Tone - YT-100/100S Also Feature Vibrate',
    days: 6,
    rank: 2423,
    spend: '$78',
    ppcSales: '$733',
    totalSales: '$354',
    tacos: '0.22%',
    conversionRate: '6%',
    roas: '4.6%',
    img: `${process.env.PUBLIC_URL}/img/P1LR/01.32.B07DZC9CLJ.png`,
  },
  {
    product: 'SportDOG Brand YardTrainer Family Remote Trainers - Rechargeable, Waterproof Dog Training Collars with Shock and Tone - YT-100/100S Also Feature Vibrate',
    days: 6,
    rank: 1478,
    spend: '$206',
    ppcSales: '$1098',
    totalSales: '$700',
    tacos: '0.29%',
    conversionRate: '6%',
    roas: '3.4%',
    img: `${process.env.PUBLIC_URL}/img/P1LR/02.32.B00CQ8JRPS.png`,
  },
  {
    product: 'SportDOG Brand In-Ground Fence Add-A-Dog Collar - Additional, Replacement, or Extra Containment Collar - Waterproof with Tone/Vibration and Shock',
    days: 4,
    rank: 114,
    spend: '$118',
    ppcSales: '$1210',
    totalSales: '$3484',
    tacos: '0.03%',
    conversionRate: '13%',
    roas: '29.6%',
    img: `${process.env.PUBLIC_URL}/img/P1LR/03.32.B000FEQZXM.png`,
  },
  {
    product: 'SportDOG Brand NoBark Rechargeable Bark Control Collar - Programmable, Waterproof Bark Collar',
    days: 4,
    rank: 202,
    spend: '$293',
    ppcSales: '$2429',
    totalSales: '$2999',
    tacos: '0.09%',
    conversionRate: '11%',
    roas: '10.3%',
    img: `${process.env.PUBLIC_URL}/img/P1LR/04.32.B071L26LZ3.png`,
  },
  {
    product: 'SportDOG Brand SD-425 Adapter Accessory - Power Cord for FieldTrainer 425 Remote Trainer',
    days: 4,
    rank: 324,
    spend: '$94',
    ppcSales: '$758',
    totalSales: '$1161',
    tacos: '0.08%',
    conversionRate: '30%',
    roas: '12.4%',
    img: `${process.env.PUBLIC_URL}/img/P1LR/05.32.B00CQ2LE8W.png`,
  },
];

const items2 = [
  {
    product: 'SportDOG Brand YardTrainer Family Remote Trainers - Rechargeable, Waterproof Dog Training Collars with Shock and Tone - YT-100/100S Also Feature Vibrate',
    days: 5,
    rank: 246,
    spend: '$182',
    ppcSales: '$1709',
    totalSales: '$1927',
    tacos: '0.09%',
    conversionRate: '6%',
    roas: '10.6%',
    img: `${process.env.PUBLIC_URL}/img/P1LR/01.32.B07DZC9CLJ.png`,
  },
  {
    product: 'SportDOG Brand YardTrainer Family Remote Trainers - Rechargeable, Waterproof Dog Training Collars with Shock and Tone - YT-100/100S Also Feature Vibrate',
    days: 5,
    rank: 244,
    spend: '$296',
    ppcSales: '$1580',
    totalSales: '$1448',
    tacos: '0.2%',
    conversionRate: '6%',
    roas: '4.9%',
    img: `${process.env.PUBLIC_URL}/img/P1LR/02.32.B00CQ8JRPS.png`,
  },
  {
    product: 'SportDOG Brand In-Ground Fence Add-A-Dog Collar - Additional, Replacement, or Extra Containment Collar - Waterproof with Tone/Vibration and Shock',
    days: 3,
    rank: 22,
    spend: '$143',
    ppcSales: '$1474',
    totalSales: '$5170',
    tacos: '0.02%',
    conversionRate: '13%',
    roas: '36.2%',
    img: `${process.env.PUBLIC_URL}/img/P1LR/03.32.B000FEQZXM.png`,
  },
  {
    product: 'SportDOG Brand NoBark Rechargeable Bark Control Collar - Programmable, Waterproof Bark Collar',
    days: 3,
    rank: 32,
    spend: '$428',
    ppcSales: '$3547',
    totalSales: '$6393',
    tacos: '0.06%',
    conversionRate: '11%',
    roas: '15%',
    img: `${process.env.PUBLIC_URL}/img/P1LR/04.32.B071L26LZ3.png`,
  },
  {
    product: 'SportDOG Brand SD-425 Adapter Accessory - Power Cord for FieldTrainer 425 Remote Trainer',
    days: 3,
    rank: 57,
    spend: '$126',
    ppcSales: '$1018',
    totalSales: '$2098',
    tacos: '0.06%',
    conversionRate: '30%',
    roas: '16.7%',
    img: `${process.env.PUBLIC_URL}/img/P1LR/05.32.B00CQ2LE8W.png`,
  },
];

const items3 = [
  {
    product: 'SportDOG Brand YardTrainer Family Remote Trainers - Rechargeable, Waterproof Dog Training Collars with Shock and Tone - YT-100/100S Also Feature Vibrate',
    days: 6,
    rank: 190,
    spend: '$236',
    ppcSales: '$2219',
    totalSales: '$3249',
    tacos: '0.07%',
    conversionRate: '6%',
    roas: '13.8%',
    img: `${process.env.PUBLIC_URL}/img/P1LR/01.32.B07DZC9CLJ.png`,
  },
  {
    product: 'SportDOG Brand YardTrainer Family Remote Trainers - Rechargeable, Waterproof Dog Training Collars with Shock and Tone - YT-100/100S Also Feature Vibrate',
    days: 6,
    rank: 144,
    spend: '$502',
    ppcSales: '$2677',
    totalSales: '$4159',
    tacos: '0.12%',
    conversionRate: '6%',
    roas: '8.3%',
    img: `${process.env.PUBLIC_URL}/img/P1LR/02.32.B00CQ8JRPS.png`,
  },
  {
    product: 'SportDOG Brand In-Ground Fence Add-A-Dog Collar - Additional, Replacement, or Extra Containment Collar - Waterproof with Tone/Vibration and Shock',
    days: 4,
    rank: 15,
    spend: '$214',
    ppcSales: '$2200',
    totalSales: '$11516',
    tacos: '0.01%',
    conversionRate: '13%',
    roas: '53.9%',
    img: `${process.env.PUBLIC_URL}/img/P1LR/03.32.B000FEQZXM.png`,
  },
  {
    product: 'SportDOG Brand NoBark Rechargeable Bark Control Collar - Programmable, Waterproof Bark Collar',
    days: 4,
    rank: 24,
    spend: '$586',
    ppcSales: '$4858',
    totalSales: '$11995',
    tacos: '0.04%',
    conversionRate: '11%',
    roas: '20.5%',
    img: `${process.env.PUBLIC_URL}/img/P1LR/04.32.B071L26LZ3.png`,
  },
  {
    product: 'SportDOG Brand SD-425 Adapter Accessory - Power Cord for FieldTrainer 425 Remote Trainer',
    days: 4,
    rank: 47,
    spend: '$153',
    ppcSales: '$1242',
    totalSales: '$3119',
    tacos: '0.04%',
    conversionRate: '30%',
    roas: '20.4%',
    img: `${process.env.PUBLIC_URL}/img/P1LR/05.32.B00CQ2LE8W.png`,
  },
];

const P1Overview = {
  title: 'Phase One Results',
  left_header: 'PPC Sales',
  left_data: '6,228',
  middle_header: 'Total Sales',
  middle_data: '8,698',
  right_header: 'TACoS',
  right_data: '9.1',
};

const P2Overview = {
  title: 'Phase Two Results',
  left_header: 'PPC Sales',
  left_data: '9,328',
  middle_header: 'Total Sales',
  middle_data: '17,036',
  right_header: 'TACoS',
  right_data: '6.9',
};

const P3Overview = {
  title: 'Phase Three Results',
  left_header: 'PPC Sales',
  left_data: '13,196',
  middle_header: 'Total Sales',
  middle_data: '34,038',
  right_header: 'TACoS',
  right_data: '5.0',
};


const ResultsPage = () => (
  <Container className="dashboard">
    <Row>
      <PhaseOverview content={P1Overview} />
      <PhaseOverview content={P2Overview} />
      <PhaseOverview content={P3Overview} />
    </Row>
    <Row>
      <LaunchResults newOrder={items1} title="P1 Launch Results" />
    </Row>
    <Row>
      <LaunchResults newOrder={items2} title="P2 Launch Results" />
    </Row>
    <Row>
      <LaunchResults newOrder={items3} title="P3 Launch Results" />
    </Row>
  </Container>
);

export default ResultsPage;
