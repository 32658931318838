import React from 'react';
import PropTypes from 'prop-types';
import AccountDataPointPanel from './AccountDataPointPanel';
import { currencyFormatter } from '../../../../shared/functions';

export default function OrderedRevenue({
  data, account, loading, dateView,
}) {
  return (
    <AccountDataPointPanel
      data={data}
      title="Ordered Revenue"
      dayField="ordered_revenue"
      formatFunction={value => currencyFormatter({ value, account })}
      barColor="#c88ffa"
      loading={loading}
      dateView={dateView}
    />
  );
}

OrderedRevenue.defaultProps = {
  data: null,
};

OrderedRevenue.propTypes = {
  loading: PropTypes.bool.isRequired,
  dateView: PropTypes.instanceOf(Object).isRequired,
  account: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(Array),
};
