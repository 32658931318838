import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import SearchTrendsReport from './components/SearchTrendsReport';
import amazonDataProvider from './amazonDataProvider'

const AmazonSearchTrendsPage = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Amazon Search Trends</h3>
        <br />
        <span>Searches that contain an Item from the catalog in one or more of the three positions.</span>
      </Col>
    </Row>
    <Row>
      <br />
    </Row>
    <Row>
      <SearchTrendsReport dataProvider={amazonDataProvider} />
    </Row>
  </Container>
);

export default AmazonSearchTrendsPage;
