import React from 'react';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import { LoginHeader, setFavicon } from '../../../shared/components/domainSupport';

const ForgotPassword = () => (
  <div className="account">
    <div className="account__wrapper">
      <div className="account__card">
        {setFavicon()}
        <LoginHeader />
        <ForgotPasswordForm onSubmit />
      </div>
    </div>
  </div>
);

export default ForgotPassword;
