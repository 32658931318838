import React from 'react';
import { Alert } from 'reactstrap';
import { isPrimaryDomain } from '../domainSupport';
import useDashboardAlert from '../hooks/useDashboardAlert';

export default function DashboardAlert() {
  const {
    visible, alertHeading, alertText, alertType, showOnPrimaryDomainOnly, onDismiss,
  } = useDashboardAlert();

  if ((!showOnPrimaryDomainOnly || (showOnPrimaryDomainOnly && isPrimaryDomain())) && visible && alertText && alertType) {
    return (
      <div className="dashboard">
        <Alert color={alertType} isOpen={visible} toggle={onDismiss}>
          {alertHeading && (
            <h4 className="text-muted">{alertHeading}</h4>
          )}
          <p className="text-muted">
            {alertText}
          </p>
        </Alert>
      </div>
    );
  }
  return null;
}
