import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import SellerPpcReport from './components/SellerPpcReport';
import VendorPpcReport from './components/VendorPpcReport';

export default function PpcReportPage() {
  const sellerSelect = useSelector(state => state.sellerSelect);
  const title = (sellerSelect.type === 1) ? 'PPC Report' : 'PPC ASIN Report';
  const Report = (sellerSelect.type === 1) ? SellerPpcReport : VendorPpcReport;
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">{title}</h3>
        </Col>
      </Row>
      <Row>
        <Report />
      </Row>
    </Container>
  );
}
