

import React, { useState, useEffect } from 'react';
import {
  Row, Col,
} from 'reactstrap';
import { useRouteMatch } from 'react-router-dom';
import {
  meteredMonthlySpSdSpend, meteredMarketplaces, flatRate,
} from '../../config';
import {
  formatNumberTwoPlaces, formatNumberOnePlace, // formatDecimalToFixedTwoPercentDirect,
} from '../../../../../shared/components/table/functions';

const toolInfo = [
  {
    key: 'adrev',
    name: 'AdRev',
    tm: 0,
  },
  {
    key: 'perpetua',
    name: 'Perpetua',
    tm: 1.2,
  },
  {
    key: 'helium10',
    name: 'Helium 10',
    tm: 2.4,
  },
  {
    key: 'teikametrics',
    name: 'Teikametrics',
    tm: 1.9,
  },
  {
    key: 'adconsole',
    name: 'Ad Console',
    tm: 3,
  },

];

const productInfo = [
  {
    rs: 0,
    re: 20,
    tm: 1.7,
    tms: 51,
    tmsar: 7,
    base: 3.1,
  },
  {
    rs: 21,
    re: 50,
    tm: 2.2,
    tms: 92.4,
    tmsar: 3,
    base: 3.1,
  },
  {
    rs: 51,
    re: 200,
    tm: 2.9,
    tms: 174,
    tmsar: 1.45,
    base: 3.5,
  },
  {
    rs: 201,
    tm: 3.7,
    tms: 270.1,
    tmsar: 1.30,
    base: 5,
  },
];

const roasInfo = [
  {
    rs: 0,
    re: 4,
    tm: 1.1,
  },
  {
    rs: 4.01,
    re: 8,
    tm: 1.3,
  },
  {
    rs: 8.01,
    re: 12,
    tm: 1.7,
  },
  {
    rs: 12.01,
    tm: 2.1,
  },
];

const budgetInfo = [
  {
    rs: 1,
    re: 1000,
    tm: 1.1,
  },
  {
    rs: 1000.01,
    re: 3000,
    tm: 1.3,
  },
  {
    rs: 3000.01,
    re: 8000,
    tm: 1.6,
  },
  {
    rs: 8000.01,
    re: 20000,
    tm: 1.9,
  },
  {
    rs: 20000.01,
    tm: 2.3,
  },
];


const Calculator = () => {
  const match = useRouteMatch({
    path: '/calculator/tool/:toolKey',
    exact: false,
  });
  const params = (match && match.params ? match.params : '') || '';
  const toolKey = params ? params.toolKey : 'adrev';
  const customerFlatRate = flatRate[0].calcValue;

  const [customerSpend, setCustomerSpend] = useState(1000.00);
  // const [customerSpendTiers, setCustomerSpendTiers] = useState([]);
  const [spendCost, setSpendCost] = useState(0);

  // const [customerMarketplaces, setCustomerMarketplaces] = useState(1);
  // const [customerMarketplaceTiers, setCustomerMarketplaceTiers] = useState([]);
  const [marketplaceCost, setMarketplaceCost] = useState(0);

  const [customerRoas, setCustomerRoas] = useState(12);
  const [acmx, setAcmx] = useState(0);
  const [productCount, setProductCount] = useState(20);
  const [arTime, setArTime] = useState(0);
  const [toolName, setToolName] = useState('');

  const spendChange = (e) => {
    const updatedSpend = e.target.value;
    const calculatedPriceTiers = [];
    let totalSpendCost = 0;
    setCustomerSpend(updatedSpend);
    // Build price tiers
    meteredMonthlySpSdSpend.forEach(spendTier => {
      if (updatedSpend >= spendTier.minSpend) {
        const capSpend = updatedSpend > spendTier.maxSpend ? spendTier.maxSpend : updatedSpend;
        const tierSpend = capSpend - (spendTier.minSpend - 1);
        const tierSpendCost = tierSpend * spendTier.calcValue;
        calculatedPriceTiers.push({ tierName: spendTier.name, tierSpendMultiplier: spendTier.calcValue, tierSpendCost });
        totalSpendCost += tierSpendCost;
      }
    })
    // setCustomerSpendTiers(calculatedPriceTiers);
    setSpendCost(totalSpendCost);
  }

  const roasChange = (e) => {
    setCustomerRoas(e.target.value);
  }

  const productCountChange = (e) => {
    setProductCount(e.target.value);
  }

  const marketplaceChange = (e) => {
    const updatedMarketplaceCount = e.target.value;
    const calculatedMarketplaceTiers = [];
    let totalMarketplaceCost = 0;
    // setCustomerMarketplaces(updatedMarketplaceCount);
    // Build Marketplace tiers
    meteredMarketplaces.forEach(marketplaceTier => {
      if (updatedMarketplaceCount >= marketplaceTier.minMarketplaces) {
        const capMarketplace = updatedMarketplaceCount > marketplaceTier.maxMarketplaces ? marketplaceTier.maxMarketplaces : updatedMarketplaceCount;
        const tierMarketplace = capMarketplace - (marketplaceTier.minMarketplaces - 1);
        const tierMarketplaceCost = tierMarketplace * marketplaceTier.calcValue;
        calculatedMarketplaceTiers.push({ tierName: marketplaceTier.name, tierMarketplaceMultiplier: marketplaceTier.calcValue, tierMarketplaceCost });
        totalMarketplaceCost += tierMarketplaceCost;
      }
    })
    // setCustomerMarketplaceTiers(calculatedMarketplaceTiers);
    setMarketplaceCost(totalMarketplaceCost);
  }

  useEffect(() => {
    const tool = toolInfo.find(info => (info.key === toolKey));
    spendChange({ target: { value: 1000.00 } });
    roasChange({ target: { value: 12 } });
    productCountChange({ target: { value: 20 } });
    marketplaceChange({ target: { value: 1 } });
    setToolName(tool?.name || 'AdRev'); // Use whats passed in on the URL path.
  }, []);

  useEffect(() => {
    if (customerRoas && customerSpend && productCount) {
      const tool = toolInfo.find(info => (info.key === toolKey));
      const product = productInfo.find(info => (productCount >= info.rs && (productCount <= info.re || !info.re)));
      const roas = roasInfo.find(info => (customerRoas >= info.rs && (customerRoas <= info.re || !info.re)));
      const budget = budgetInfo.find(info => (customerSpend >= info.rs && (customerSpend <= info.re || !info.re)));
      setAcmx((roas?.tm || 0) + (budget?.tm || 0) + (product?.tm || 0) + (tool?.tm || 0));
      setArTime(Math.min(13, (product.base + (productCount / 100))));
    }
  }, [customerRoas, customerSpend, productCount]);


  const adrevFee = customerFlatRate + spendCost + marketplaceCost // + productCost + budgetGroupCost;
  const estimatedPpcRev = customerRoas > 0 ? customerSpend * customerRoas : 0;
  // const estimatedNetProfitAferFees = estimatedPpcRev - customerSpend - adrevFee;

  const colCss = {
    textAlign: 'center',
    margin: '0 auto',
  };

  const spanCss = {
    fontSize: '2em',
    whiteSpace: 'nowrap',
  }

  const spanColorCss = {
    ...spanCss,
    color: '#522eff',
  }


  return (
    <>
      <Row>
        <Col sm={12} md={4} lg={4} xl={4}>
          Target PPC Spend in USD:<br />
          <input type="text" id="customerSpendEstimate" className="input_round" style={{ width: '100%' }} value={customerSpend} onChange={spendChange} />
          <br />
          <br />
        </Col>
        <Col sm={12} md={4} lg={4} xl={4}>
          Target ROAS:<br />
          <input type="text" id="customerRoasEstimate" className="input_round" style={{ width: '100%' }} value={customerRoas} onChange={roasChange} />
          <br />
          <br />
        </Col>
        <Col sm={12} md={4} lg={4} xl={4}>
          Product Count:<br />
          <input type="text" id="proudctCountEstimate" className="input_round" style={{ width: '100%' }} value={productCount} onChange={productCountChange} />
          <br />
          <br />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={4} lg={4} xl={4} style={colCss}>
          <span style={spanCss}>User Time Spent:</span><br />
          <span style={spanColorCss}>{formatNumberOnePlace(arTime)} Minutes</span>
          <br />
          <br />
          <br />
        </Col>
        <Col sm={12} md={4} lg={4} xl={4} style={colCss}>
          <span style={spanCss}>AdRev PPC Sales:</span><br />
          <span style={spanColorCss}>${formatNumberTwoPlaces(estimatedPpcRev)}</span>
          <br />
          <br />
          <br />
        </Col>
        <Col sm={12} md={4} lg={4} xl={4} style={colCss}>
          <span style={spanCss}>AdRev Fee:</span><br />
          <span style={spanColorCss}>${formatNumberTwoPlaces(adrevFee)}</span>
          <br />
          <br />
          <br />
        </Col>
      </Row>
      {toolName?.toLowerCase() !== 'adrev' && (
        <Row>
          <Col sm={12} md={4} lg={4} xl={4} style={colCss}>
            <span style={spanCss}>AdRev vs {toolName}:</span><br />
            <span style={spanColorCss}>{formatNumberOnePlace(acmx)}X More Campaigns</span>
          </Col>
        </Row>
      )}
    </>
  )
};

export default Calculator;
