import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import AcctMgmtBudgetForecastReport from './components/AcctMgmtBudgetForecastReport';

const AcctMgmtBudgetForecastPage = ({ retailer }) => {
  const retailerConfig = retailer?.toLowerCase() === 'walmart' ? { retailerName: 'Walmart', retailerKey: 'walmart', retailerPrefix: 'wm' } : { retailerName: 'Amazon', retailerKey: 'amazon', retailerPrefix: 'amz' };
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Account Management {retailerConfig.retailerName} Budget Forecast</h3>
        </Col>
      </Row>
      <Row>
        <AcctMgmtBudgetForecastReport retailerConfig={retailerConfig} />
      </Row>
    </Container>
  );
}

export default AcctMgmtBudgetForecastPage;
