import React from 'react';
import PropTypes from 'prop-types';

export default function SimplePanel({ children }) {
  return (
    <div className="panel__content">
      {children}
    </div>
  );
}

SimplePanel.defaultProps = {
  children: null,
};

SimplePanel.propTypes = {
  children: PropTypes.instanceOf(Object),
};
