import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FilterSelect from './NewFilterSelect';

export const DataSetsMulti = {
  SP: {
    label: 'Sponsored Products',
    value: 'sp',
  },
  SB: {
    label: 'Sponsored Brands',
    value: 'sb',
  },
  SBV: {
    label: 'Sponsored Brands Video',
    value: 'sbv',
  },
  SD_CPC: {
    label: 'Sponsored Display - CPC',
    value: 'sd_cpc',
  },
  SD_VCPM: {
    label: 'Sponsored Display - vCPM',
    value: 'sd_vcpm',
  },
  * [Symbol.iterator]() {
    yield DataSetsMulti.SP;
    yield DataSetsMulti.SB;
    yield DataSetsMulti.SBV;
    yield DataSetsMulti.SD_CPC;
    yield DataSetsMulti.SD_VCPM;
  },
};

export const DataSets = {
  ALL: {
    label: 'ALL',
    value: '',
  },
  SP: {
    label: 'Sponsored Products',
    value: 'sp',
  },
  SB: {
    label: 'Sponsored Brands',
    value: 'sb',
  },
  SBV: {
    label: 'Sponsored Brands Video',
    value: 'sbv',
  },
  SD: {
    label: 'Sponsored Display',
    value: 'sd',
  },
  * [Symbol.iterator]() {
    yield DataSets.ALL;
    yield DataSets.SP;
    yield DataSets.SB;
    yield DataSets.SBV;
    yield DataSets.SD;
  },
};

export default function DataSetFilter({
  initialValue, onChange, disable, multi,
}) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (value) {
      onChange(value);
    }
  }, [value]);

  const dataSetOptions = multi ? DataSetsMulti : DataSets;

  return (
    <FilterSelect
      label="Data Set:"
      onChange={option => setValue(option)}
      name="dataOption"
      options={[...dataSetOptions]}
      selected={value}
      disable={disable}
      multi={multi}
    />
  );
}

DataSetFilter.defaultProps = {
  initialValue: DataSets.ALL,
  disable: false,
  multi: false,
};

DataSetFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialValue: PropTypes.oneOf([...DataSets]),
  disable: PropTypes.bool,
  multi: PropTypes.bool,
};
