import { sellerSelectConstants } from '../../constants';

export function sellerSelectChange(sellerId, sellerName, sellerType, currencySymbol, currencyCode, parentAccountId, mpName, agency, platform, subscriptionId, plan, planStatus, externalCustomerId, externalAccountId, customerId, refreshKey) {
  return {
    type: sellerSelectConstants.SELLER_SELECTED, sellerId, sellerName, sellerType, currencySymbol, currencyCode, parentAccountId, mpName, agency, platform, subscriptionId, plan, planStatus, externalCustomerId, externalAccountId, customerId, refreshKey,
  };
}

export function sellerSelectReset() {
  return {
    type: sellerSelectConstants.SELLER_RESET,
  };
}

// eslint-disable-next-line
export const sellerSelectActions = {
  sellerSelectChange,
  sellerSelectReset,
};
