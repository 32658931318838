import React from 'react';
import {
  Container, Row,
} from 'reactstrap';
import OneThreeBrandMarketShareMain from './components/OneThreeBrandMarketShareMain';

const OneThreeBrandMarketSharePage = () => (
  <Container className="dashboard">
    <Row>
      <OneThreeBrandMarketShareMain />
    </Row>
  </Container>
);

export default OneThreeBrandMarketSharePage;
