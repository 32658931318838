/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import Select from 'react-select';
import {
  Container, Col, Row, Card, CardBody,
} from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import DateRangePickerPanel from '../../../../../shared/components/widgets/DateRangePickerPanel';
import FullWidthBiaxialLineChart from '../../../../../shared/components/widgets/FullWidthBiaxialLineChart';
import LeftMiddleRightBox from '../../../../../shared/components/widgets/LeftMiddleRightBox';
import {
  formatNumberDefaultToZero, currencyFormatter, formatDecimalToFixedTwoPercent,
} from '../../../../../shared/components/table/functions';
import config from '../../../../../config';
import { SellerSelect, User, Theme } from '../../../../../shared/prop-types/MainProps';
import style, { DROPDOWN_COLORS } from '../../../../../shared/components/themeSupport';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class PpcSubcatRank extends PureComponent {
  static propTypes = {
    sellerSelect: SellerSelect.isRequired,
    user: User.isRequired,
    theme: Theme.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      initalLoad: false,
      reportStartDate: moment().subtract(11, 'days').format('YYYY-MM-DD'),
      reportEndDate: moment().subtract(4, 'days').format('YYYY-MM-DD'),
      data: {
        total: {
          impressions: 0,
          clicks: 0,
          sales: 0,
        },
      },
      lineList: [],
      leftGraphOptions: [
        {
          label: 'Impressions', value: 'impressions', pos: 'left', type: 'number',
        },
        {
          label: 'Clicks', value: 'clicks', pos: 'left', type: 'number',
        },
        {
          label: 'Spend', value: 'spend', pos: 'left', type: 'dollar',
        },
        {
          label: 'Sales', value: 'sales', pos: 'left', type: 'dollar',
        },
        {
          label: 'ACoS', value: 'acos', pos: 'left', type: 'percent',
        },
        {
          label: 'CTR', value: 'ctr', pos: 'left', type: 'percent',
        },
        {
          label: 'CPC', value: 'cpc', pos: 'left', type: 'percent',
        },
        {
          label: 'Units', value: 'units', pos: 'left', type: 'number',
        },
        {
          label: 'ACoS 14d', value: 'acos_14d', pos: 'left', type: 'percent',
        },
        {
          label: 'Orders', value: 'orders', pos: 'left', type: 'number',
        },
        {
          label: 'Number of Campaigns', value: 'number_of_campaigns', pos: 'left', type: 'number',
        },
        {
          label: 'Shipped COGs', value: 'shipped_cogs', pos: 'left', type: 'number',
        },
        {
          label: 'Shipped Units', value: 'shipped_units', pos: 'left', type: 'number',
        },
        {
          label: 'Ordered Units', value: 'ordered_units', pos: 'left', type: 'number',
        },
        {
          label: 'Customer Returns', value: 'customer_returns', pos: 'left', type: 'number',
        },
        {
          label: 'Free Replacements', value: 'free_replacements', pos: 'left', type: 'number',
        },
        {
          label: 'Rep OOS', value: 'rep_oos', pos: 'left', type: 'number',
        },
        {
          label: 'LBB', value: 'lbb', pos: 'left', type: 'number',
        },
      ],
      leftGraphOption: {
        label: 'Sales',
        value: 'sales',
        pos: 'left',
        type: 'dollar',
      },
      rightGraphOptions: [
        {
          label: 'Impressions', value: 'impressions', pos: 'right', type: 'number',
        },
        {
          label: 'Clicks', value: 'clicks', pos: 'right', type: 'number',
        },
        {
          label: 'Spend', value: 'spend', pos: 'right', type: 'dollar',
        },
        {
          label: 'Sales', value: 'sales', pos: 'right', type: 'dollar',
        },
        {
          label: 'ACoS', value: 'acos', pos: 'right', type: 'percent',
        },
        {
          label: 'CTR', value: 'ctr', pos: 'right', type: 'percent',
        },
        {
          label: 'CPC', value: 'cpc', pos: 'right', type: 'percent',
        },
        {
          label: 'Units', value: 'units', pos: 'right', type: 'number',
        },
        {
          label: 'ACoS 14d', value: 'acos_14d', pos: 'right', type: 'percent',
        },
        {
          label: 'Orders', value: 'orders', pos: 'right', type: 'number',
        },
        {
          label: 'Number of Campaigns', value: 'number_of_campaigns', pos: 'right', type: 'number',
        },
        {
          label: 'Shipped COGs', value: 'shipped_cogs', pos: 'right', type: 'number',
        },
        {
          label: 'Shipped Units', value: 'shipped_units', pos: 'right', type: 'number',
        },
        {
          label: 'Ordered Units', value: 'ordered_units', pos: 'right', type: 'number',
        },
        {
          label: 'Customer Returns', value: 'customer_returns', pos: 'right', type: 'number',
        },
        {
          label: 'Free Replacements', value: 'free_replacements', pos: 'right', type: 'number',
        },
        {
          label: 'Rep OOS', value: 'rep_oos', pos: 'right', type: 'number',
        },
        {
          label: 'LBB', value: 'lbb', pos: 'right', type: 'number',
        },
      ],
      rightGraphOption: {},
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevState) {
    const { sellerSelect } = this.props;
    if (sellerSelect.value !== prevState.sellerSelect.value) {
      this.getData();
    }
  }

  onDateChange = (startDate, endDate) => {
    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).format('YYYY-MM-DD');
    this.setState({
      reportStartDate: start,
      reportEndDate: end,
    }, this.getData);
  }

  handleLeftChange = (selectedValue) => {
    const { rightGraphOption } = this.state;
    this.setState({
      leftGraphOption: selectedValue,
      lineList: [selectedValue, rightGraphOption],
    });
  }

  handleRightChange = (selectedValue) => {
    const { leftGraphOption } = this.state;
    this.setState({
      rightGraphOption: selectedValue,
      lineList: [leftGraphOption, selectedValue],
    });
  }

  getData = () => {
    const { sellerSelect, user } = this.props;
    const {
      reportStartDate, reportEndDate, rightGraphOptions, rightGraphOption,
    } = this.state;
    const subcatRankUrl = `${apiUrl}/vendors/ppc-subcat-rank?sellerAccountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}&startDate=${reportStartDate}&endDate=${reportEndDate}`;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    this.setState({
      initalLoad: true,
    });

    fetch(subcatRankUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        if (data && data.total.subcategory_sales_rank_total > 0) {
          rightGraphOptions.unshift({
            label: 'Subcategory Sales Rank', value: 'subcategory_sales_rank', pos: 'right', type: 'number',
          });
        }
        const lineList = [
          {
            label: 'Sales', value: 'sales', pos: 'left', type: 'dollar',
          },
        ];


        let rightIndex = rightGraphOptions.indexOf(rightGraphOption);
        if (rightIndex < 0) {
          rightIndex = 0;
        }

        lineList.push(rightGraphOptions[rightIndex]);

        this.setState({
          initalLoad: false,
          data,
          rightGraphOptions,
          rightGraphOption: rightGraphOptions[rightIndex],
          lineList,
        });
      }).catch(() => {
        this.setState({
          initalLoad: false,
        });
      });
  };

  render() {
    const {
      reportStartDate, reportEndDate, initalLoad, data, lineList, leftGraphOptions, leftGraphOption, rightGraphOptions, rightGraphOption,
    } = this.state;

    const { sellerSelect, theme } = this.props;
    const themeColors = style(theme, DROPDOWN_COLORS);

    let leftTickFormatter = number => formatNumberDefaultToZero({ value: number });
    let rightTickFormatter = number => formatNumberDefaultToZero({ value: number });

    if (leftGraphOption.type === 'dollar') {
      leftTickFormatter = dollar => currencyFormatter({ value: dollar });
    }

    if (leftGraphOption.type === 'percent') {
      leftTickFormatter = percent => `${percent}%`;
    }

    if (rightGraphOption.type === 'dollar') {
      rightTickFormatter = dollar => currencyFormatter({ value: dollar });
    }

    if (rightGraphOption.type === 'percent') {
      rightTickFormatter = percent => `${percent}%`;
    }


    if (sellerSelect.type === 2) {
      return (
        <Container className="dashboard">
          <Col md={12}>
            <Row>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <h3 className="page-title">Left Y-Axis Graph Item</h3>
                    <div className="topbar__dynamicDropdownWidth">
                      <Select
                        value={leftGraphOption}
                        onChange={this.handleLeftChange}
                        options={leftGraphOptions}
                        theme={selectTheme => ({
                          ...selectTheme,
                          colors: {
                            ...selectTheme.colors,
                            primary: `${themeColors.colorPrimary}`,
                            neutral0: `${themeColors.colorBackground}`,
                            neutral80: `${themeColors.colorText}`,
                          },
                        })}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <h3 className="page-title">Right Y-Axis Graph Item</h3>
                    <div className="topbar__dynamicDropdownWidth">
                      <Select
                        value={rightGraphOption}
                        onChange={this.handleRightChange}
                        options={rightGraphOptions}
                        theme={selectTheme => ({
                          ...selectTheme,
                          colors: {
                            ...selectTheme.colors,
                            primary: `${themeColors.colorPrimary}`,
                            neutral0: `${themeColors.colorBackground}`,
                            neutral80: `${themeColors.colorText}`,
                          },
                        })}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={12}>
            <Row>
              <DateRangePickerPanel
                reportStartDate={reportStartDate}
                reportEndDate={reportEndDate}
                onDateChange={this.onDateChange}
              />
            </Row>
          </Col>
          <Col md={12}>
            <Row>
              <FullWidthBiaxialLineChart
                data={data.dateData}
                title="Compare Items"
                initalLoad={initalLoad}
                lineList={lineList}
                dataKey="report_date"
                leftTickFormatter={leftTickFormatter}
                rightTickFormatter={rightTickFormatter}
              />
            </Row>
          </Col>
          <Col md={12}>
            <Row>
              <LeftMiddleRightBox
                content={{
                  title: 'Return on Ad Spend (RoAS)',
                  left_header: '',
                  left_data: '',
                  middle_header: '',
                  middle_data: `${formatNumberDefaultToZero({ value: (data.total.sales / data.total.spend) })} : 1`,
                  right_header: '',
                  right_data: '',
                }}
                loadingData={initalLoad}
                lgWidth="4"
              />
              <LeftMiddleRightBox
                content={{
                  title: 'Conversion Rate (CVR)',
                  left_header: '',
                  left_data: '',
                  middle_header: '',
                  middle_data: formatDecimalToFixedTwoPercent({ value: (data.total.orders / data.total.clicks) }),
                  right_header: '',
                  right_data: '',
                }}
                loadingData={initalLoad}
                lgWidth="4"
              />
              <LeftMiddleRightBox
                content={{
                  title: 'Cost per Acquisition (CPA)',
                  left_header: '',
                  left_data: '',
                  middle_header: '',
                  middle_data: currencyFormatter({ value: (data.total.spend / data.total.orders) }),
                  right_header: '',
                  right_data: '',
                }}
                loadingData={initalLoad}
                lgWidth="4"
              />
            </Row>
          </Col>
          <Col md={12}>
            <Row>
              <LeftMiddleRightBox
                content={{
                  title: 'PPC Units',
                  left_header: '',
                  left_data: '',
                  middle_header: '',
                  middle_data: formatNumberDefaultToZero({ value: data.total.units }),
                  right_header: '',
                  right_data: '',
                }}
                loadingData={initalLoad}
                lgWidth="4"
              />
              <LeftMiddleRightBox
                content={{
                  title: 'Ordered Units',
                  left_header: '',
                  left_data: '',
                  middle_header: '',
                  middle_data: formatNumberDefaultToZero({ value: data.total.ordered_units }),
                  right_header: '',
                  right_data: '',
                }}
                loadingData={initalLoad}
                lgWidth="4"
              />
              <LeftMiddleRightBox
                content={{
                  title: 'PPC Unit %',
                  left_header: '',
                  left_data: '',
                  middle_header: '',
                  middle_data: formatDecimalToFixedTwoPercent({ value: (data.total.units / data.total.ordered_units) }),
                  right_header: '',
                  right_data: '',
                }}
                loadingData={initalLoad}
                lgWidth="4"
              />
            </Row>
          </Col>
          <Col md={12}>
            <Row>
              <LeftMiddleRightBox
                content={{
                  title: 'Total Impressions',
                  left_header: '',
                  left_data: '',
                  middle_header: '',
                  middle_data: formatNumberDefaultToZero({ value: data.total.impressions }),
                  right_header: '',
                  right_data: '',
                }}
                loadingData={initalLoad}
                lgWidth="4"
              />
              <LeftMiddleRightBox
                content={{
                  title: 'Total Clicks',
                  left_header: '',
                  left_data: '',
                  middle_header: '',
                  middle_data: formatNumberDefaultToZero({ value: data.total.clicks }),
                  right_header: '',
                  right_data: '',
                }}
                loadingData={initalLoad}
                lgWidth="4"
              />
              <LeftMiddleRightBox
                content={{
                  title: 'Click Through Rate (CTR)',
                  left_header: '',
                  left_data: '',
                  middle_header: '',
                  middle_data: formatDecimalToFixedTwoPercent({ value: data.total.ctr }),
                  right_header: '',
                  right_data: '',
                }}
                loadingData={initalLoad}
                lgWidth="4"
              />
            </Row>
          </Col>
          <Col md={12}>
            <Row>
              <LeftMiddleRightBox
                content={{
                  title: 'Total Spend',
                  left_header: '',
                  left_data: '',
                  middle_header: '',
                  middle_data: currencyFormatter({ value: data.total.spend }),
                  right_header: '',
                  right_data: '',
                }}
                loadingData={initalLoad}
                lgWidth="4"
              />
              <LeftMiddleRightBox
                content={{
                  title: 'Total Sales',
                  left_header: '',
                  left_data: '',
                  middle_header: '',
                  middle_data: currencyFormatter({ value: data.total.sales }),
                  right_header: '',
                  right_data: '',
                }}
                loadingData={initalLoad}
                lgWidth="4"
              />
              <LeftMiddleRightBox
                content={{
                  title: 'ACoS',
                  left_header: '',
                  left_data: '',
                  middle_header: '',
                  middle_data: formatDecimalToFixedTwoPercent({ value: data.total.acos }),
                  right_header: '',
                  right_data: '',
                }}
                loadingData={initalLoad}
                lgWidth="4"
              />
            </Row>
          </Col>
          <Col md={12}>
            <Row>
              <LeftMiddleRightBox
                content={{
                  title: 'Cost Per Click (CPC)',
                  left_header: '',
                  left_data: '',
                  middle_header: '',
                  middle_data: currencyFormatter({ value: data.total.cpc }),
                  right_header: '',
                  right_data: '',
                }}
                loadingData={initalLoad}
                lgWidth="4"
              />
              <LeftMiddleRightBox
                content={{
                  title: 'Revenue Per Click (RPC)',
                  left_header: '',
                  left_data: '',
                  middle_header: '',
                  middle_data: currencyFormatter({ value: (data.total.sales / data.total.clicks) }),
                  right_header: '',
                  right_data: '',
                }}
                loadingData={initalLoad}
                lgWidth="4"
              />
              <LeftMiddleRightBox
                content={{
                  title: 'Cost Per Millie (CPM)',
                  left_header: '',
                  left_data: '',
                  middle_header: '',
                  middle_data: currencyFormatter({ value: (data.total.spend / (data.total.impressions / 1000)) }),
                  right_header: '',
                  right_data: '',
                }}
                loadingData={initalLoad}
                lgWidth="4"
              />
            </Row>
          </Col>
          <Col md={12}>
            <Row>
              <LeftMiddleRightBox
                content={{
                  title: 'Replacements',
                  left_header: '',
                  left_data: '',
                  middle_header: '',
                  middle_data: formatNumberDefaultToZero({ value: data.total.free_replacements }),
                  right_header: '',
                  right_data: '',
                }}
                loadingData={initalLoad}
                lgWidth="4"
              />
              <LeftMiddleRightBox
                content={{
                  title: 'Returns',
                  left_header: '',
                  left_data: '',
                  middle_header: '',
                  middle_data: formatNumberDefaultToZero({ value: data.total.customer_returns }),
                  right_header: '',
                  right_data: '',
                }}
                loadingData={initalLoad}
                lgWidth="4"
              />
              <LeftMiddleRightBox
                content={{
                  title: 'Return Rate',
                  left_header: '',
                  left_data: '',
                  middle_header: '',
                  middle_data: formatDecimalToFixedTwoPercent({ value: (data.total.customer_returns / data.total.ordered_units) }),
                  right_header: '',
                  right_data: '',
                }}
                loadingData={initalLoad}
                lgWidth="4"
              />
            </Row>
          </Col>
        </Container>
      );
    }
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <p>This is a Vendor specific report. Please select a new report or select a Vendor account.</p>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { sellerSelect, theme } = state;
  const { user } = state.authentication;

  return {
    theme,
    sellerSelect,
    user,
  };
};

export default connect(mapStateToProps)(PpcSubcatRank);
