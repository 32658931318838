import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Form, Table, Input,
} from 'reactstrap';
import {
  getAccountSettingGroupsDbService as getDatabaseService,
  accountSettingGroupsUrl,
} from './services';
import {
  fieldStyle,
  useInputText,
  useDisplayError,
  getToggleArrayItemFieldFunction,
  getDeleteArrayItemFunction,
  getUpdateArrayItemFunction,
  getCreateArrayItemFunction,
} from '../../../../../helpers';
import TableHeaders from './TableHeaders';
import useFetch from '../../../../../shared/components/hooks/useFetch';
import Panel from '../../../../../shared/components/Panel';
import LoadingSpinner from '../../../../../shared/components/widgets/LoadingSpinner';
import DisplayTableError from '../../../../../shared/components/widgets/DisplayTableError';
import DisplaySettingGroupsTable from './DisplaySettingGroupsTable';

export default function SettingGroupsPanel() {
  const user = useSelector(state => state.authentication.user);
  const [data, setData] = useState([]);
  const { loading, results, error } = useFetch(accountSettingGroupsUrl());
  const { value: newKey, bind: bindKey, reset: resetKey } = useInputText('');
  const { value: newValue, bind: bindValue, reset: resetValue } = useInputText('');
  const { value: newPermissions, bind: bindPermissions, reset: resetPermissions } = useInputText('');
  const { displayError, setDisplayError, resetError } = useDisplayError();
  const toggleItemEdit = getToggleArrayItemFieldFunction('settingGroupId', 'editMode', setData, data);
  const createStateItem = getCreateArrayItemFunction(setData, data);
  const deleteStateItem = getDeleteArrayItemFunction('settingGroupId', setData, data);
  const updateStateItem = getUpdateArrayItemFunction('settingGroupId', setData, data);

  const doReset = () => {
    resetKey();
    resetValue();
    resetPermissions();
  };

  async function doSubmit(event) {
    event.preventDefault();
    try {
      const result = await getDatabaseService(user).create(newKey, newValue, newPermissions);
      await createStateItem({
        settingGroupId: result.insertId,
        settingGroupKey: newKey,
        settingGroupValue: newValue,
        settingGroupAccessControl: newPermissions,
        editMode: false,
      });
      doReset();
    } catch (createError) {
      setDisplayError({ id: 'CreateError', text: `Create failed. Setting Group with key '${newKey}' may already exist.` });
    }
  }

  async function doSave(event, updatedItem) {
    event.preventDefault();
    // eslint-disable-next-line no-alert
    if (window.confirm(`Are you sure you want to update setting group with key '${updatedItem.settingGroupKey}'?`)) {
      try {
        await getDatabaseService(user).update(updatedItem);
        await updateStateItem(updatedItem);
        doReset();
      } catch (updateError) {
        setDisplayError({ id: updatedItem.settingGroupId, text: `Update of item ${updatedItem.settingGroupId} failed.` });
      }
    }
  }

  async function doDelete(event, item) {
    event.preventDefault();
    // eslint-disable-next-line no-alert
    if (window.confirm(`Are you sure you want to delete setting group with key '${item.settingGroupKey}'?`)) {
      try {
        await getDatabaseService(user).delete(item);
        await deleteStateItem(item);
      } catch (deleteError) {
        setDisplayError({ id: item.settingGroupId, text: `Delete of item ${item.settingGroupId} failed.` });
      }
    }
  }

  useEffect(() => {
    if (results) {
      setData(results.map(it => (
        {
          ...it,
          editMode: false,
        }
      )));
    }
  }, [results, setData]);

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  return (
    <Panel lg={12} title="Manage Settings Groups">
      {loading && (
        <LoadingSpinner />
      )}
      <Form className="form" inline onSubmit={doSubmit}>
        <Table className="table table--bordered">
          <TableHeaders />
          <tbody>
            <DisplayTableError
              displayError={displayError}
              resetError={resetError}
              colSpan={5}
            />
            <tr>
              <td style={fieldStyle}>NEW</td>
              <td style={fieldStyle}><Input type="text" {...bindKey} /></td>
              <td style={fieldStyle}><Input type="text" {...bindValue} /></td>
              <td style={fieldStyle}><Input type="text" {...bindPermissions} /></td>
              <td style={fieldStyle}>
                <button type="submit" className="badge badge-success badge-btn">Create</button>
                <span>&nbsp;&nbsp;</span>
                <button type="button" onClick={doReset} className="badge badge-warning badge-btn">Reset</button>
              </td>
            </tr>
            <DisplaySettingGroupsTable
              data={data}
              toggleEdit={toggleItemEdit}
              doSave={(event, updatedItem) => doSave(event, updatedItem)}
              doDelete={(event, item) => doDelete(event, item)}
              displayError={displayError}
              resetError={() => resetError()}
            />
          </tbody>
        </Table>
      </Form>
    </Panel>
  );
}

SettingGroupsPanel.defaultProps = {
  loading: false,
};

SettingGroupsPanel.propTypes = {
  loading: PropTypes.bool,
};
