import moment from 'moment';
import store from '../../../containers/App/store';

function formatNumberTwoPlaces(number) {
  const value = number || 0;
  return (Math.floor(value * 100) / 100).toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

function formatNumberOnePlace(number) {
  const value = number || 0;
  return (Math.floor(value * 10) / 10).toFixed(1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

function formatNumberOnePlaceDefaultToZero(params) {
  if (params.value) {
    return formatNumberOnePlace(params.value);
  }
  return formatNumberOnePlace(0);
}

function formatNumberWhole(number) {
  const value = number || 0;
  // this puts commas into the number eg 1000 goes to 1,000,
  return Math.floor(value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

function formatNumberDefaultToZero(params) {
  if (params.value) {
    return formatNumberWhole(params.value);
  }
  return formatNumberWhole(0);
}

function formatNumberDefaultToDash(params) {
  if (params.value && typeof params.value === 'number') {
    return formatNumberWhole(params.value);
  }
  return '-';
}

function formatNumberDefaultToNull(params) {
  if (params.value && params.value > 0) {
    return formatNumberWhole(Math.ceil(params.value));
  }
  return '-';
}

function formatFixedFourPercent(params) {
  if (params.value) {
    return `${(Math.floor(params.value * 10000) / 10000).toFixed(4).toString()}%`;
  }
  return null;
}

function formatFixedTwoPercent(params) {
  if (params.value) {
    return `${((Math.floor(params.value * 100) / 100)).toFixed(2).toString()}%`;
  }
  return '0%';
}

function formatDecimalToFixedTwoPercentDirect(number) {
  if (number) {
    return `${((Math.floor(number * 1000000) / 10000)).toFixed(2).toString()}%`;
  }
  return '0%';
}

function formatDecimalToFixedTwoPercent(params) {
  return formatDecimalToFixedTwoPercentDirect(params.value);
}

function formatDecimalToFullPercent(params) {
  if (params.value) {
    return `${((Math.floor(params.value * 1000000) / 10000)).toFixed(0).toString()}%`;
  }
  return '0%';
}

function numberTwoPlaces(params) {
  // this puts commas into the number eg 1000 goes to 1,000,
  if (typeof params.value === 'number') {
    return formatNumberTwoPlaces(params.value);
  }
  return 0.00;
}

function currencyFormatter(params) {
  const value = params.value || 0;
  const state = store.getState();
  let useCurrencySymbol = (state.sellerSelect.currencySymbol || '$');
  if (params.data && params.data.marketplace_currency_symbol) {
    useCurrencySymbol = params.data.marketplace_currency_symbol;
  }
  if (value < 0) {
    return `-${useCurrencySymbol}${formatNumberTwoPlaces(Math.abs(value))}`;
  }
  return `${useCurrencySymbol}${formatNumberTwoPlaces(value)}`;
}

function currencyFormatterDirect(number) {
  const value = number || 0;
  const state = store.getState();
  const useCurrencySymbol = (state.sellerSelect.currencySymbol || '$');
  if (value < 0) {
    return `-${useCurrencySymbol}${formatNumberTwoPlaces(Math.abs(value))}`;
  }
  return `${useCurrencySymbol}${formatNumberTwoPlaces(value)}`;
}

function currencyFormatterWhole(params) {
  const value = params.value || 0;
  const state = store.getState();
  let useCurrencySymbol = (state.sellerSelect.currencySymbol || '$');
  if (params.data && params.data.marketplace_currency_symbol) {
    useCurrencySymbol = params.data.marketplace_currency_symbol;
  }
  if (params.value < 0) {
    return `-${useCurrencySymbol}${formatNumberWhole(Math.abs(value))}`;
  }
  return `${useCurrencySymbol}${formatNumberWhole(value)}`;
}

function currencyDisplay() {
  const state = store.getState();
  const useCurrencySymbol = (state.sellerSelect.currencySymbol || '$');
  return useCurrencySymbol;
}


function toDisplayDate(params) {
  if (params.value) {
    return moment.utc(params.value).format('YYYY-MM-DD');
  }
  return null;
}

function numberValueParser(params) {
  return Number(params.newValue);
}

function getExportPrefix(tableName, sellerSelect, useEncryptedValue = false) {
  if (sellerSelect) {
    if (useEncryptedValue) {
      return `${tableName}-${sellerSelect.externalAccountId}`;
    }
    return `${sellerSelect.value}-${tableName}`;
  }

  return `${tableName}`;
}

export {
  formatFixedFourPercent,
  formatFixedTwoPercent,
  formatDecimalToFixedTwoPercentDirect,
  formatDecimalToFixedTwoPercent,
  formatDecimalToFullPercent,
  currencyFormatter,
  currencyFormatterDirect,
  currencyFormatterWhole,
  toDisplayDate,
  formatNumberDefaultToZero,
  formatNumberDefaultToDash,
  formatNumberTwoPlaces,
  numberTwoPlaces,
  formatNumberDefaultToNull,
  numberValueParser,
  formatNumberWhole,
  currencyDisplay,
  formatNumberOnePlace,
  formatNumberOnePlaceDefaultToZero,
  getExportPrefix,
};
