/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import { currencyDisplay } from '../table/functions';
import Panel from '../Panel';

class FullWidthBiaxialLineChart extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line
    data: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    initalLoad: PropTypes.bool.isRequired,
    // eslint-disable-next-line
    lineList: PropTypes.array.isRequired,
    dataKey: PropTypes.string,
    leftTickFormatter: PropTypes.func,
    rightTickFormatter: PropTypes.func,
  }

  render() {
    const {
      data, initalLoad, title, lineList, dataKey, leftTickFormatter, rightTickFormatter,
    } = this.props;

    const colorOptions = ['#70bbfd', '#ffb153', '#f47b50', '#c88ffa', '#4ce1b6'];

    return (
      <Panel md={12} title={title} parentRefresh={initalLoad}>
        <ResponsiveContainer height={300} className="dashboard__area">
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5, right: 30, left: 20, bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={dataKey} />
            <YAxis yAxisId="left" tickFormatter={leftTickFormatter} />
            <YAxis yAxisId="right" orientation="right" tickFormatter={rightTickFormatter} />
            <Tooltip />
            <Legend />
            {lineList.map((value, index) => (
              <Line yAxisId={value.pos} type="monotone" dataKey={value.value} stroke={colorOptions[index]} activeDot={{ r: 8 }} />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </Panel>
    );
  }
}


FullWidthBiaxialLineChart.defaultProps = {
  dataKey: 'report_date',
  leftTickFormatter: dollar => `${currencyDisplay()}${dollar}`,
  rightTickFormatter: number => number,
};

const mapStateToProps = (state) => {
  const { sellerSelect } = state;
  const { user } = state.authentication;

  return {
    sellerSelect,
    user,
  };
};

export default connect(mapStateToProps)(FullWidthBiaxialLineChart);
