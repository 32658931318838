import React, { PureComponent } from 'react';
import {
  Container, Col, Row, Table,
} from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, // Line,
} from 'recharts';
import Panel from '../../../../../shared/components/Panel';
import DateRangePickerPanel from '../../../../../shared/components/widgets/DateRangePickerPanel';
import { SellerSelect, User, Theme } from '../../../../../shared/prop-types/MainProps';
import {
  formatNumberWhole, currencyFormatter, formatDecimalToFixedTwoPercent, formatNumberOnePlace,
} from '../../../../../shared/components/table/functions';
import style from '../../../../../shared/components/themeSupport';
import config from '../../../../../config';
import SalesMetricFilter, { MetricOptions } from '../../../../../shared/components/widgets/SalesMetricFilter';
import DateViewFilter, { DateViewOptions } from '../../../../../shared/components/widgets/DateViewFilter';
import PortfolioFilter, { ALL_PORTFOLIOS } from '../../../../../shared/components/widgets/PortfolioFilter';
import DataSetFilter, { DataSets } from '../../../../../shared/components/widgets/DataSetFilter';
import DataSourceFilter, { DataSources } from '../../../../../shared/components/widgets/DataSourceFilter';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class BccAccountPerformance extends PureComponent {
  static propTypes = {
    sellerSelect: SellerSelect.isRequired,
    user: User.isRequired,
    theme: Theme.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      reportStartDate: moment().subtract(34, 'days').format('YYYY-MM-DD'),
      reportEndDate: moment().subtract(4, 'days').format('YYYY-MM-DD'),
      initalLoad: false,
      bccAccountPerformance: [],
      bccAccountPerformanceHold: [],
      bccAccountDailyTotalsMap: new Map(),
      bccAccountTotals: {},
      metricOption: MetricOptions.SALES,
      dataOption: DataSets.ALL,
      dateViewOption: DateViewOptions.DAY,
      sourceOption: DataSources.ALL,
      portfolioOption: ALL_PORTFOLIOS,
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevState) {
    const { sellerSelect } = this.props;
    if (sellerSelect.value !== prevState.sellerSelect.value) {
      this.getData();
    }
  }

  onDateChange = (startDate, endDate) => {
    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).format('YYYY-MM-DD');
    this.setState({
      reportStartDate: start,
      reportEndDate: end,
    }, this.getData);
  }

  getPivotData = (data, pivotOn) => {
    const pivotData = data.reduce((list, row) => {
      const currentObj = list.get(row.searchDate);
      if (currentObj) {
        currentObj[row.brand_cat_comp] = row[pivotOn];
        list.set(row.searchDate, currentObj);
      } else {
        const newObj = {
          searchDate: row.searchDate,
          [row.brand_cat_comp]: row[pivotOn],
        };
        list.set(row.searchDate, newObj);
      }
      return list;
    }, new Map());

    const result = Array.from(pivotData.values());
    return result;
  }

  getDailyTotalsMap = (data) => {
    const dailyTotalsMap = data.reduce((list, row) => {
      if (row.brand_cat_comp !== '') { // Don't count rows that are not labeled (although they should be labeled!)
        const currentObj = list.get(row.searchDate);
        if (currentObj) {
          currentObj.impressions += row.impressions;
          currentObj.clicks += row.clicks;
          currentObj.orders += row.orders;
          currentObj.spend += row.spend;
          currentObj.sales += row.sales;
          currentObj.acos = (currentObj.sales > 0) ? (currentObj.spend / currentObj.sales) : 0;
          currentObj.roas = (currentObj.spend > 0) ? (currentObj.sales / currentObj.spend) : 0;
          currentObj.cvr = (currentObj.clicks > 0) ? (currentObj.orders / currentObj.clicks) : 0;
          currentObj.ctr = (currentObj.impressions > 0) ? (currentObj.clicks / currentObj.impressions) : 0;
          list.set(row.searchDate, currentObj);
        } else {
          const newObj = {
            searchDate: row.searchDate,
            impressions: row.impressions,
            clicks: row.clicks,
            orders: row.orders,
            spend: row.spend,
            sales: row.sales,
            acos: (row.sales > 0) ? (row.spend / row.sales) : 0,
            roas: (row.spend > 0) ? (row.sales / row.spend) : 0,
            cvr: (row.clicks > 0) ? (row.orders / row.clicks) : 0,
            ctr: (row.impressions > 0) ? (row.clicks / row.impressions) : 0,
          };
          list.set(row.searchDate, newObj);
        }
      }
      return list;
    }, new Map());

    return dailyTotalsMap;
  }

  getTotals = (data) => {
    const totalObj = {
      impressions: 0,
      clicks: 0,
      orders: 0,
      spend: 0,
      sales: 0,
      acos: 0,
      roas: 0,
      cvr: 0,
      ctr: 0,
    };

    const brandTotalObj = { ...totalObj };
    const competitorTotalObj = { ...totalObj };
    const categoryTotalObj = { ...totalObj };

    data.forEach((row) => {
      if (row.brand_cat_comp !== '') { // Don't count rows that are not labeled (although they should be labeled!)
        totalObj.impressions += row.impressions;
        totalObj.clicks += row.clicks;
        totalObj.orders += row.orders;
        totalObj.spend += row.spend;
        totalObj.sales += row.sales;
        totalObj.acos = (totalObj.sales > 0) ? (totalObj.spend / totalObj.sales) : 0;
        totalObj.roas = (totalObj.spend > 0) ? (totalObj.sales / totalObj.spend) : 0;
        totalObj.cvr = (totalObj.clicks > 0) ? (totalObj.orders / totalObj.clicks) : 0;
        totalObj.ctr = (totalObj.impressions > 0) ? (totalObj.clicks / totalObj.impressions) : 0;
        if (row.brand_cat_comp === 'Brand') {
          brandTotalObj.impressions += row.impressions;
          brandTotalObj.clicks += row.clicks;
          brandTotalObj.orders += row.orders;
          brandTotalObj.spend += row.spend;
          brandTotalObj.sales += row.sales;
          brandTotalObj.acos = (brandTotalObj.sales > 0) ? (brandTotalObj.spend / brandTotalObj.sales) : 0;
          brandTotalObj.roas = (brandTotalObj.spend > 0) ? (brandTotalObj.sales / brandTotalObj.spend) : 0;
          brandTotalObj.cvr = (brandTotalObj.clicks > 0) ? (brandTotalObj.orders / brandTotalObj.clicks) : 0;
          brandTotalObj.ctr = (brandTotalObj.impressions > 0) ? (brandTotalObj.clicks / brandTotalObj.impressions) : 0;
        }
        if (row.brand_cat_comp === 'Competitor') {
          competitorTotalObj.impressions += row.impressions;
          competitorTotalObj.clicks += row.clicks;
          competitorTotalObj.orders += row.orders;
          competitorTotalObj.spend += row.spend;
          competitorTotalObj.sales += row.sales;
          competitorTotalObj.acos = (competitorTotalObj.sales > 0) ? (competitorTotalObj.spend / competitorTotalObj.sales) : 0;
          competitorTotalObj.roas = (competitorTotalObj.spend > 0) ? (competitorTotalObj.sales / competitorTotalObj.spend) : 0;
          competitorTotalObj.cvr = (competitorTotalObj.clicks > 0) ? (competitorTotalObj.orders / competitorTotalObj.clicks) : 0;
          competitorTotalObj.ctr = (competitorTotalObj.impressions > 0) ? (competitorTotalObj.clicks / competitorTotalObj.impressions) : 0;
        }
        if (row.brand_cat_comp === 'Category') {
          categoryTotalObj.impressions += row.impressions;
          categoryTotalObj.clicks += row.clicks;
          categoryTotalObj.orders += row.orders;
          categoryTotalObj.spend += row.spend;
          categoryTotalObj.sales += row.sales;
          categoryTotalObj.acos = (categoryTotalObj.sales > 0) ? (categoryTotalObj.spend / categoryTotalObj.sales) : 0;
          categoryTotalObj.roas = (categoryTotalObj.spend > 0) ? (categoryTotalObj.sales / categoryTotalObj.spend) : 0;
          categoryTotalObj.cvr = (categoryTotalObj.clicks > 0) ? (categoryTotalObj.orders / categoryTotalObj.clicks) : 0;
          categoryTotalObj.ctr = (categoryTotalObj.impressions > 0) ? (categoryTotalObj.clicks / categoryTotalObj.impressions) : 0;
        }
      }
    });

    return {
      total: totalObj,
      brand: brandTotalObj,
      competitor: competitorTotalObj,
      category: categoryTotalObj,
    };
  }

  getData = () => {
    const { sellerSelect, user } = this.props;
    const {
      reportStartDate, reportEndDate, dateViewOption, dataOption, metricOption, sourceOption, portfolioOption,
    } = this.state;

    const bccAccountPerformanceUrl = `${apiUrl}/customers/bcc-account-performance?sellerAccountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}&startDate=${reportStartDate}&endDate=${reportEndDate}&accountType=${sellerSelect.type}&dataGroup=${dateViewOption.value}&dataSet=${dataOption.value}&dataSource=${sourceOption.value}&portfolioFilter=${portfolioOption.value}`;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    this.setState({
      initalLoad: true,
    });

    fetch(bccAccountPerformanceUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        const bccAccountPerformance = this.getPivotData(data, metricOption.value);
        const bccAccountDailyTotalsMap = this.getDailyTotalsMap(data);
        const bccAccountTotals = this.getTotals(data);

        this.setState({
          initalLoad: false,
          bccAccountPerformance,
          bccAccountPerformanceHold: data,
          bccAccountDailyTotalsMap,
          bccAccountTotals,
        });
      });
  };


  handleChange = (option, select) => {
    this.setState({
      [select.name]: option,
    }, () => {
      if (select.name !== 'metricOption') {
        const { initalLoad } = this.state;
        if (!initalLoad) {
          this.getData();
        }
      } else {
        const { bccAccountPerformanceHold, metricOption } = this.state;
        const bccAccountPerformance = this.getPivotData(bccAccountPerformanceHold, metricOption.value);
        this.setState({
          bccAccountPerformance,
        });
      }
    });
  }

  functionFormatter = (itemType) => {
    let tickFormatter = number => formatNumberWhole(number);

    if ([MetricOptions.ACOS.value, MetricOptions.CVR.value, MetricOptions.CTR.value].includes(itemType)) {
      tickFormatter = percent => formatDecimalToFixedTwoPercent({ value: percent });
    }
    if ([MetricOptions.SALES.value, MetricOptions.SPEND.value].includes(itemType)) {
      tickFormatter = dollar => currencyFormatter({ value: dollar });
    }
    if ([MetricOptions.ROAS.value].includes(itemType)) {
      tickFormatter = number => formatNumberOnePlace(number);
    }
    return tickFormatter;
  }

  render() {
    const {
      bccAccountPerformance, initalLoad, dataOption, reportStartDate, reportEndDate, bccAccountDailyTotalsMap,
      dateViewOption, metricOption, sourceOption, bccAccountTotals,
    } = this.state;

    const { theme } = this.props;

    const showLeftAxis = !([MetricOptions.ACOS.value, MetricOptions.CVR.value, MetricOptions.ROAS.value, MetricOptions.CTR.value].includes(metricOption.value));

    return (
      <Container className="dashboard">
        <Col md={12}>
          <Row>
            <DateRangePickerPanel
              reportStartDate={reportStartDate}
              reportEndDate={reportEndDate}
              onDateChange={this.onDateChange}
            />
          </Row>
        </Col>
        <Panel
          lg={12}
          md={12}
          title="Filters"
          subhead="NOTE: Filters and View only use data in the date range selected.  If your date range doesn't match your view, you could be seeing partial data on the ends of your view."
          parentRefresh={initalLoad}
        >
          <Row>
            <Col lg={2}>
              <SalesMetricFilter
                onChange={value => this.handleChange(value, { name: 'metricOption' })}
                initialValue={metricOption}
                disable={initalLoad}
              />
            </Col>
            <Col lg={2}>
              <DataSetFilter
                initialValue={dataOption}
                onChange={value => this.handleChange(value, { name: 'dataOption' })}
                disable={initalLoad}
              />
            </Col>
            <Col lg={3}>
              <PortfolioFilter
                onChange={value => this.handleChange(value, { name: 'portfolioOption' })}
                disable={initalLoad}
              />
            </Col>
            <Col lg={3}>
              <DataSourceFilter
                initialValue={sourceOption}
                onChange={value => this.handleChange(value, { name: 'sourceOption' })}
                disable={initalLoad}
              />
            </Col>
            <Col lg={2}>
              <DateViewFilter
                initialValue={dateViewOption}
                onChange={value => this.handleChange(value, { name: 'dateViewOption' })}
                disable={initalLoad}
              />
            </Col>
          </Row>
        </Panel>

        <Panel
          lg={12}
          md={12}
          title=""
          subhead=""
          parentRefresh={initalLoad}
        >
          <Row>
            <Col md={12} className="text-center">
              <h5>{metricOption.label}</h5>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ResponsiveContainer height={400} className="dashboard__area">
                <ComposedChart
                  width={600}
                  height={400}
                  data={bccAccountPerformance}
                  margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                  }}
                >
                  <CartesianGrid stroke={style(theme).colorText} />
                  <XAxis dataKey="searchDate" stroke={style(theme).colorText} />
                  {showLeftAxis && (
                    <YAxis yAxisId="left" tickFormatter={this.functionFormatter(metricOption.value)} stroke={style(theme).colorText} />
                  )}
                  {/* <YAxis yAxisId="right" orientation="right" tickFormatter={number => number} domain={[0, dataMax => (dataMax * 1.5)]} stroke={style(theme).colorText} /> */}
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="Brand" formatter={this.functionFormatter(metricOption.value)} stackId="a" yAxisId="left" barSize={20} fill="#03c03c" />
                  <Bar dataKey="Competitor" formatter={this.functionFormatter(metricOption.value)} stackId="a" yAxisId="left" barSize={20} fill="#36c9c9" />
                  <Bar dataKey="Category" formatter={this.functionFormatter(metricOption.value)} stackId="a" yAxisId="left" barSize={20} fill="#fbb054" />
                  {/* <Line type="monotone" dataKey="salesRankAvg" name="Sales Rank Avg" yAxisId="right" stroke="#FF9966" /> */}
                </ComposedChart>
              </ResponsiveContainer>
            </Col>
          </Row>
        </Panel>

        <Panel
          lg={12}
          md={12}
          title=""
          subhead=""
          parentRefresh={initalLoad}
        >
          <div style={{
            marginLeft: '100px',
          }}
          >
            {(bccAccountPerformance.length > 0) && (
              <Table responsive striped className="dashboard__table-orders" id="dataOverTime">
                <thead>
                  <tr>
                    <th className="sticky-th" />
                    {bccAccountPerformance.map(item => (
                      <th key={item.searchDate}>{item.searchDate}</th>
                    ))}
                    <th className="sticky-th-right">TOTAL</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="sticky-td-color" style={{ marginTop: '1px' }}>Brand</td>
                    {bccAccountPerformance.map(item => (
                      <td key={item.searchDate}>{this.functionFormatter(metricOption.value)(item.Brand)}</td>
                    ))}
                    <td>{this.functionFormatter(metricOption.value)(bccAccountTotals.brand[metricOption.value])}</td>
                  </tr>
                  <tr>
                    <td className="sticky-td">Competitor</td>
                    {bccAccountPerformance.map(item => (
                      <td key={item.searchDate}>{this.functionFormatter(metricOption.value)(item.Competitor)}</td>
                    ))}
                    <td>{this.functionFormatter(metricOption.value)(bccAccountTotals.competitor[metricOption.value])}</td>
                  </tr>
                  <tr>
                    <td className="sticky-td-color">Category</td>
                    {bccAccountPerformance.map(item => (
                      <td key={item.searchDate}>{this.functionFormatter(metricOption.value)(item.Category)}</td>
                    ))}
                    <td>{this.functionFormatter(metricOption.value)(bccAccountTotals.category[metricOption.value])}</td>
                  </tr>
                  <tr>
                    <td className="sticky-td">TOTAL</td>
                    {bccAccountPerformance.map(item => (
                      <td key={item.searchDate}>{this.functionFormatter(metricOption.value)(bccAccountDailyTotalsMap.get(item.searchDate)[metricOption.value])}</td>
                    ))}
                    <td>{this.functionFormatter(metricOption.value)(bccAccountTotals.total[metricOption.value])}</td>
                  </tr>
                </tbody>
              </Table>
            )}
          </div>
        </Panel>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { theme, sellerSelect } = state;
  const { user } = state.authentication;

  return {
    theme,
    sellerSelect,
    user,
  };
};

export default connect(mapStateToProps)(BccAccountPerformance);
