import PropTypes from 'prop-types';
import config from '../../../../../configWrapper';
import { doFetch } from '../../../../../helpers';

export const AccountSettingGroupShape = {
  settingGroupId: PropTypes.number,
  settingGroupKey: PropTypes.string,
  settingGroupValue: PropTypes.string,
  settingGroupAccessControl: PropTypes.string,
};

export function accountSettingGroupsUrl() {
  return `${config.serverUrl}/accounts/settingGroups`;
}

function deleteAccountSettingGroupUrl(id) {
  return `${config.serverUrl}/accounts/settingGroups/${id}`;
}

function createAccountSettingsGroup(props) {
  const {
    key, value, permissions, headerWithAuth,
  } = props;
  const options = {
    method: 'POST',
    headers: headerWithAuth,
    body: JSON.stringify({
      settingGroupKey: key,
      settingGroupValue: value,
      settingGroupAccessControl: permissions,
    }),
  };
  return doFetch(accountSettingGroupsUrl(), options);
}

function updateAccountSettingsGroup(props) {
  const {
    item, headerWithAuth,
  } = props;
  const options = {
    method: 'PUT',
    headers: headerWithAuth,
    body: JSON.stringify({
      ...item,
    }),
  };
  return doFetch(accountSettingGroupsUrl(), options);
}

function deleteAccountSettingsGroup(props) {
  const {
    item, headerWithAuth,
  } = props;
  const options = {
    method: 'DELETE',
    headers: headerWithAuth,
  };
  return doFetch(deleteAccountSettingGroupUrl(item.settingGroupId), options);
}


// eslint-disable-next-line import/prefer-default-export
export const getAccountSettingGroupsDbService = (user) => {
  const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
  return {
    create: (key, value, permissions) => createAccountSettingsGroup({
      key, value, permissions, headerWithAuth,
    }),
    update: item => updateAccountSettingsGroup({ item, headerWithAuth }),
    delete: item => deleteAccountSettingsGroup({ item, headerWithAuth }),
  };
};
