import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ItemCatalog from './components/ItemCatalog';

export default function CatalogIndexPage() {
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Item Catalog</h3>
        </Col>
      </Row>
      <Row>
        <ItemCatalog />
      </Row>
    </Container>
  );
}
