/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
} from 'recharts';
import PropTypes from 'prop-types';
import Panel from '../Panel';

const HorizontalTwoBar = ({ data, content, loadingData }) => (
  <Panel
    lg={6}
    xl={6}
    md={12}
    title={content.title}
    subhead={content.subtitle}
    panelClass="panel--narrow"
    parentRefresh={loadingData}
  >
    <div>
      <p style={{ width: '100%', textAlign: 'center' }}>Legend:<br />
        <span style={{ color: content.firstColor || '#fbb054' }}> {String.fromCharCode(8226)} {content.firstTitle}</span><br />
        <span style={{ color: content.secondColor || '#df7252' }}> {String.fromCharCode(8226)} {content.secondTitle}</span><br />
      </p>
    </div>
    <ResponsiveContainer height={300} className="dashboard__active-users-chart">
      <BarChart
        width={600}
        height={220}
        data={data}
        layout="vertical"
        barGap={0}
        barCategoryGap={0}
        stackOffset="expand"
        margin={{
          top: 0, right: 20, bottom: 0, left: 30,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" hide />
        <YAxis type="category" dataKey={content.dataKey} tickLine={false} verticalAnchor="start" />
        <Tooltip />
        <Bar dataKey={content.firstDataKey} fill={(content.firstColor || '#fbb054')} barSize={12} />
        <Bar dataKey={content.secondDataKey} fill={(content.secondColor || '#df7252')} barSize={12} />
      </BarChart>
    </ResponsiveContainer>
  </Panel>
);

HorizontalTwoBar.propTypes = {
  // eslint-disable-next-line
  data: PropTypes.array.isRequired,
  // eslint-disable-next-line
  content: PropTypes.object.isRequired,
  loadingData: PropTypes.bool.isRequired,
};

export default HorizontalTwoBar;
