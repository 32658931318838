import React, { useRef, useCallback } from 'react';
import { Col, Row } from 'reactstrap';
import { AgGridReact } from 'ag-grid-react';

export default function SearchTrendsTable(props) {
  const { tableTheme, tableColumns, tableData, exportFilename } = props;
  const gridRef = useRef();

  const onGridReady = useCallback(() => {
    if (gridRef && gridRef.current && gridRef.current.api && gridRef.current.api.autoSizeStrategy) {
      gridRef.current.api.autoSizeStrategy({
        type: 'fitCellContents',
      })
    }
  }, []);

  const onBtnExport = () => {
    gridRef.current.api.exportDataAsCsv({ fileName: exportFilename });
  };

  const defaultColumns = {
    sortable: true,
    filter: true,
    resizable: true,
  }

  return (
    <Col md={12}>
      <Row>
        <div style={{ verticalAlign: 'middle', spacing: '2px', width: '100%', display: 'table-cell', marginBottom: '25px' }}>
          <span style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '5px', paddingBottom: '0px' }}><button className="btn btn-outline-primary" style={{ width: '200px', marginBottom: ' 0px' }} onClick={onBtnExport} type="button">Export</button></span>
        </div>
      </Row>
      <Row>
        <div className={tableTheme} style={{ height: '75vh', width: '100%' }}>
          <AgGridReact
            ref={gridRef}
            columnDefs={tableColumns}
            defaultColDef={defaultColumns}
            rowData={tableData}
            onGridReady={onGridReady}
          />
        </div>
      </Row>
    </Col>
  );
}
