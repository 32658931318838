import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BigCalendar from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import moment from 'moment';
import { connect } from 'react-redux';
import config from '../../config';
import { updateCalendarActions } from '../../redux/actions/index';
import { SellerSelect } from '../prop-types/MainProps';

const localizer = BigCalendar.momentLocalizer(moment);

const DragAndDropCalendar = withDragAndDrop(BigCalendar);

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class CalendarComponent extends PureComponent {
  static propTypes = {
    small: PropTypes.bool,
    updateCalendar: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    sellerSelect: SellerSelect.isRequired,
  };

  static defaultProps = {
    small: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      events: [],
    };
  }

  componentDidMount() {
    this.refreshData();
  }

  componentDidUpdate(prevState) {
    const { updateCalendar, sellerSelect, dispatch } = this.props;
    if (updateCalendar.value === 'UPDATE_CALENDAR') {
      this.refreshData();
      dispatch(updateCalendarActions.updateCalanderDone('DONE'));
    }
    if (sellerSelect.value !== prevState.sellerSelect.value) {
      this.refreshData();
    }
  }

  refreshData = () => {
    // eslint-disable-next-line
    const { user, sellerSelect } = this.props;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const getUrl = `${apiUrl}/events/calendar/seller?sellerAccountId=${sellerSelect.value.toString()}&parentAccountId=${sellerSelect.parentAccountId}`;

    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };
    // Call via API (would like to update to " await ")
    fetch(getUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        const cleanData = data;
        Object.keys(cleanData).forEach((key) => {
          cleanData[key].start = new Date(cleanData[key].start.toString().replace('Z', ''));
          cleanData[key].end = new Date(cleanData[key].end.toString().replace('Z', ''));
        });
        this.setState({
          events: cleanData,
        });
      }).catch((error) => {
        console.error(error);
      });
  }

  moveEvent = ({ event, start, end }) => {
    const { events } = this.state;

    const idx = events.indexOf(event);
    const updatedEvent = { ...event, start, end };

    const nextEvents = [...events];
    nextEvents.splice(idx, 1, updatedEvent);

    this.setState({
      events: nextEvents,
    });
  };

  eventStyleGetter = (event) => {
    let color;

    switch (event.priority) {
      case 'high':
        color = '#C3485F';
        break;

      case 'medium':
        color = '#4ce1b6';
        break;

      case 'normal':
        color = '#E87B50';
        break;

      case 'low':
        color = '#808080';
        break;

      default:
        color = event.priority;
        break;
    }

    const style = {
      backgroundColor: color,
      border: 'none',
    };

    return {
      style,
    };
  };

  render() {
    const { small } = this.props;
    const { events } = this.state;

    return (
      <div className={`calendar${small ? ' calendar--small' : ''}`}>
        <DragAndDropCalendar
          localizer={localizer}
          events={events}
          views={['month', 'week', 'day']}
          popup
          step={60}
          timeslots={1}
          showMultiDayTimes
          onEventDrop={this.moveEvent}
          defaultDate={new Date()}
          eventPropGetter={(this.eventStyleGetter)}
          messages={{
            previous: <span className="lnr lnr-chevron-left" />,
            next: <span className="lnr lnr-chevron-right" />,
            today: <span className="lnr lnr-calendar-full" />,
          }}
        />
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  const { user } = state.authentication;
  const { updateCalendar, sellerSelect } = state;

  return {
    user,
    updateCalendar,
    sellerSelect,
  };
};

export default connect(mapStateToProps)(CalendarComponent);
