import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'react-scroll';
import classNames from 'classnames';
import { Row, Col, Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import CloseIcon from 'mdi-react/CloseIcon';
import moment from 'moment';

const ReactivateModal = ({
  toggleModal, showModal, nextBillDate, reactivatePlanFunction,
}) => {
  const theme = useSelector(store => store.theme);
  const modalClasses = classNames({
    'todo__add-modal': true,
  }, theme.className, 'ltr-support');

  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      className={modalClasses}
    >
      <button className="panel__btn" type="button" onClick={() => toggleModal(false)}><CloseIcon /></button><h2>Reactivate My Plan</h2>
      <br />
      <br />
      <Row>
        <Col md={12} lg={12} xl={12}>
          <div style={{ textAlign: 'left' }}>
            <p>Because you are Reactivating before the end of your subscription, we will pick up right where we left off:</p>
            <br />
            <ul>
              <li key="yesManage">We will continue to manager your Ads</li>
              <li key="yesAccess">You will continue to have access to your reports</li>
              <li key="yesAccess">Billing will resume next month</li>
            </ul>
          </div>
          <br />
          <p>Your subscription will renew on {moment(nextBillDate).format('YYYY-MM-DD')}, at which time you will be billed for last months addons and next months access.</p>
          <br />
          <br />
          <Button activeClass="active" className="btn btn-primary account__btn" type="submit" value="Reactivate Plan" to="cancelPlan" onClick={() => { reactivatePlanFunction('active'); toggleModal(false); }} spy smooth offset={50} duration={500} />
        </Col>
      </Row>
    </Modal>
  );
};

ReactivateModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default ReactivateModal;
