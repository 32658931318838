import { useFetchReturnsArray as useFetch } from './useFetch';
import config from '../../../configWrapper';

function formatUrl(customerId) {
  if (customerId) {
    const url = `${config.serverUrl}/customers/${customerId}`;
    return url;
  }
  return undefined;
}

export default function useCustomerLookup(customerId) {
  return useFetch(formatUrl(customerId));
}
