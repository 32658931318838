import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AtIcon from 'mdi-react/AtIcon';
import { userActions } from '../../../../redux/actions';
import LoadingSpinner from '../../../../shared/components/widgets/LoadingSpinner';

class ForgotPasswordForm extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    loading: PropTypes.bool,
  };

  constructor() {
    super();
    this.state = {
      showPassword: false,
      submitted: false,
      email: undefined,
    };

    this.showPassword = this.showPassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  showPassword(e) {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  }

  handleSubmit(e) {
    e.preventDefault();
    localStorage.removeItem('loginMessage'); // clear message

    this.setState({ submitted: true });
    const { email } = this.state;
    const { dispatch } = this.props;
    if (email) {
      dispatch(userActions.forgotPassword(email));
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  render() {
    const { loading } = this.props;
    const { submitted, email } = this.state;

    return (
      <form className="form" onSubmit={this.handleSubmit}>
        <div className="form__form-group form__form-group--forgot">
          <span className="form__form-group-label">Email</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AtIcon />
            </div>
            <Field
              name="email"
              component="input"
              type="email"
              placeholder="example@mail.com"
              onChange={this.handleChange}
            />
          </div>
          {submitted && !email && (
            <div className="form__form-group-error">Email is required</div>
          )}
        </div>
        <LoadingSpinner
          active={loading}
        />
        <div className="form__form-group">
          <button className="btn btn-primary account__btn" type="submit" disabled={loading}>Password Recovery</button>
          <Link className="btn btn-outline-primary account__btn" to="/login">Cancel</Link>
        </div>
      </form>
    );
  }
}

ForgotPasswordForm.defaultProps = {
  loading: false,
};

const forgotPasswordForm = reduxForm({
  form: 'account_forgot_password_form',
})(ForgotPasswordForm);

const mapStateToProps = (state) => {
  const { alert } = state;
  const { loading } = state.users;

  return {
    alert,
    loading,
  };
};

export default connect(mapStateToProps)(forgotPasswordForm);
