import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, Col, Nav, NavItem, NavLink,
} from 'reactstrap';
import classnames from 'classnames';

export default function InsightNavTabs({
  performanceCount, budgetCount, alertCount, connectionCount, activeTab, setActiveTab,
}) {
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  const totalCount = performanceCount + budgetCount + alertCount + connectionCount;

  return (
    <Col md={12} lg={12}>
      <Card>
        <div className="profile__card tabs tabs--bordered-bottom">
          <div className="tabs__wrap">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 'All' })}
                  onClick={() => {
                    toggle('All');
                  }}
                >
                  <span className={'sidebar__link-icon lnr lnr-eye'} /> All Insights <span style={{ paddingLeft: '30px' }}>{totalCount}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 'Performance' })}
                  onClick={() => {
                    toggle('Performance');
                  }}
                >
                  <span className={'lnr lnr-chart-bars insight_performance-icon'} /> Performance <span style={{ paddingLeft: '30px' }}>{performanceCount}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 'Budgets' })}
                  onClick={() => {
                    toggle('Budgets');
                  }}
                >
                  <span className={'sidebar__link-icon lnr lnr-diamond'} /> Budgets <span style={{ paddingLeft: '30px' }}>{budgetCount}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 'Alerts' })}
                  onClick={() => {
                    toggle('Alerts');
                  }}
                >
                  <span className={'lnr lnr-warning insight_alert-icon'} /> Alerts <span style={{ paddingLeft: '30px' }}>{alertCount}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 'Connections' })}
                  onClick={() => {
                    toggle('Connections');
                  }}
                >
                  <span className={'sidebar__link-icon lnr lnr-car'} /> Connections <span style={{ paddingLeft: '30px' }}>{connectionCount}</span>
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
      </Card>
    </Col>
  );
}

InsightNavTabs.defaultProps = {
  performanceCount: 0,
  budgetCount: 0,
  alertCount: 0,
  connectionCount: 0,
};

InsightNavTabs.propTypes = {
  // loading: PropTypes.bool.isRequired,
  performanceCount: PropTypes.number,
  budgetCount: PropTypes.number,
  alertCount: PropTypes.number,
  connectionCount: PropTypes.number,
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};
