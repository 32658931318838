import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import LaunchSettings from './components/LaunchSettings';

const LaunchSettingsPage = () => {
  const sellerSelect = useSelector(state => state.sellerSelect);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Launch Settings</h3>
        </Col>
      </Row>
      <Row>
        <LaunchSettings sellerSelect={sellerSelect} />
      </Row>
    </Container>
  );
}

export default LaunchSettingsPage;
