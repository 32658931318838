import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  fieldStyle,
} from '../../../../../helpers';
import {
  AccountSettingGroupShape,
} from './services';
import DisplayTableError, { DisplayErrorShape } from '../../../../../shared/components/widgets/DisplayTableError';
import DisplaySettingGroup from './DisplaySettingGroup';
import EditSettingGroup from './EditSettingGroup';

export default function DisplaySettingGroupsTable({
  data, toggleEdit, doSave, doDelete, displayError, resetError,
}) {
  return data.map(item => (
    <Fragment key={item.settingGroupId}>
      <DisplayTableError
        displayError={displayError}
        resetError={resetError}
        colSpan={5}
        checkErrorId={item.settingGroupId}
      />
      <tr>
        <td style={fieldStyle}>{ item.settingGroupId }</td>
        {!item.editMode && (
          <DisplaySettingGroup
            item={item}
            toggleEdit={toggleEdit}
            doDelete={doDelete}
          />
        )}
        {item.editMode && (
          <EditSettingGroup
            item={item}
            toggleEdit={toggleEdit}
            doSave={doSave}
          />
        )}
      </tr>
    </Fragment>
  ));
}

DisplaySettingGroupsTable.defaultProps = {
  displayError: undefined,
  resetError: data => data,
};

DisplaySettingGroupsTable.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.shape(AccountSettingGroupShape),
    PropTypes.arrayOf(PropTypes.shape(AccountSettingGroupShape)),
  ]).isRequired,
  toggleEdit: PropTypes.func.isRequired,
  doSave: PropTypes.func.isRequired,
  doDelete: PropTypes.func.isRequired,
  displayError: PropTypes.shape(DisplayErrorShape),
  resetError: PropTypes.func,
};
