import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import config from '../../../../config';
import TableBuilder from '../../../../shared/components/table/TableBuilder';
import {
  formatFixedTwoPercent, currencyFormatter,
} from '../../../../shared/components/table/functions';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class MarketShareTable extends PureComponent {
  static propTypes = {
    tableId: PropTypes.string.isRequired,
    sellerAccountId: PropTypes.number.isRequired,
    parentAccountId: PropTypes.number.isRequired,
    setPageData: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    const { tableId, sellerAccountId, parentAccountId } = this.props;

    this.state = {
      columnDefs: [
        {
          field: 'brand',
          headerName: 'Brand',
          chartDataType: 'category',
        },
        {
          field: 'revenue',
          headerName: 'Revenue',
          aggFunc: 'sum',
          chartDataType: 'series',
        },
        {
          field: 'AMZ',
          headerName: 'AMZ',
          aggFunc: 'sum',
          chartDataType: 'series',
        },
        {
          field: 'FBM',
          headerName: 'FBM',
          aggFunc: 'sum',
          chartDataType: 'series',
        },
        {
          field: 'FBA',
          headerName: 'FBA',
          aggFunc: 'sum',
          chartDataType: 'series',
        },
        {
          field: 'oneP',
          headerName: '1P',
          valueFormatter: currencyFormatter,
          aggFunc: 'sum',
        },
        {
          field: 'threeP',
          headerName: '3P',
          valueFormatter: currencyFormatter,
          aggFunc: 'sum',
        },
        {
          field: 'total',
          headerName: 'Total',
          valueFormatter: currencyFormatter,
          aggFunc: 'sum',
        },
        {
          field: 'percentOneP',
          headerName: '1P %',
          valueFormatter: formatFixedTwoPercent,
        },
        {
          field: 'percentThreeP',
          headerName: '3P %',
          aggFunc: 'sum',
          valueFormatter: formatFixedTwoPercent,
        },
        {
          field: 'percentTotal',
          headerName: 'Total %',
          valueFormatter: formatFixedTwoPercent,
          aggFunc: 'sum',
        },
      ],
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
      },
      showFooter: false,
      enableCharts: true,
      enableRangeSelection: true,
      apiQueryParams: `tableId=${tableId}&sellerAccountId=${sellerAccountId}&parentAccountId=${parentAccountId}`,
    };
  }

  static getDerivedStateFromProps(props) {
    if (props.tableId) {
      return {
        apiQueryParams: `tableId=${props.tableId}&sellerAccountId=${props.sellerAccountId}&parentAccountId=${props.parentAccountId}`,
      };
    }
    return null;
  }

  render() {
    const {
      columnDefs, defaultColDef, showFooter, enableCharts, enableRangeSelection, apiQueryParams,
    } = this.state;

    const { setPageData } = this.props;

    const apiEndPoint = `${apiUrl}/vendors/market-share`;

    return (
      <TableBuilder
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        apiEndPoint={apiEndPoint}
        apiQueryParams={apiQueryParams}
        showFooter={showFooter}
        enableCharts={enableCharts}
        enableRangeSelection={enableRangeSelection}
        parentSetData={setPageData}
      />
    );
  }
}

export default MarketShareTable;
