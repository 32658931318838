import React, { PureComponent } from 'react';
import {
  Container, Col, Row, Table,
} from 'reactstrap';
import Select from 'react-select';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, // Line,
} from 'recharts';
import Panel from '../../../../../shared/components/Panel';
import DateRangePickerPanel from '../../../../../shared/components/widgets/DateRangePickerPanel';
import { SellerSelect, User, Theme } from '../../../../../shared/prop-types/MainProps';
import {
  formatNumberWhole, currencyFormatter, formatDecimalToFixedTwoPercent, formatNumberOnePlace,
} from '../../../../../shared/components/table/functions';
import style, { DROPDOWN_COLORS } from '../../../../../shared/components/themeSupport';
import { history } from '../../../../../helpers';
import config from '../../../../../config';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class PpcOverTimePortfolios extends PureComponent {
  static propTypes = {
    sellerSelect: SellerSelect.isRequired,
    user: User.isRequired,
    theme: Theme.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      reportStartDate: moment().subtract(34, 'days').format('YYYY-MM-DD'),
      reportEndDate: moment().subtract(4, 'days').format('YYYY-MM-DD'),
      initalLoad: false,
      portfoliosData: [],
      metricOption: { label: 'Impressions', value: 'impressions' },
      metricOptions: [{ label: 'Impressions', value: 'impressions' }, { label: 'Clicks', value: 'clicks' }, { label: 'Orders', value: 'orders' }, { label: 'Spend', value: 'spend' }, { label: 'Sales', value: 'sales' }, { label: 'ACoS', value: 'acos' }, { label: 'ROAS', value: 'roas' }], // , { label: 'CVR', value: 'cvr' }
      dataOption: { label: 'ALL', value: '' },
      dataOptions: [{ label: 'ALL', value: '' }, { label: 'Sponsored Product', value: 'sp' }, { label: 'Sponsored Brands', value: 'sb' }],
      dateViewOption: { label: 'Day', value: 'day' },
      dateViewOptions: [{ label: 'Day', value: 'day' }, { label: 'Week', value: 'week' }, { label: 'Month', value: 'month' }],
      portfolioOption: {},
      portfolioOptions: [],
    };
  }

  componentDidMount() {
    this.getFilters();
  }

  componentDidUpdate(prevState) {
    const { sellerSelect } = this.props;
    if (sellerSelect.value !== prevState.sellerSelect.value) {
      this.getFilters();
    }
  }

  onDateChange = (startDate, endDate) => {
    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).format('YYYY-MM-DD');
    this.setState({
      reportStartDate: start,
      reportEndDate: end,
    }, this.getData);
  }

  getFilters = async () => {
    const { sellerSelect, user } = this.props;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    this.setState({
      initalLoad: true,
    });

    const protfolioMasterUrl = `${apiUrl}/customers/portfolios?sellerAccountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}`;

    const protfolioResponse = await fetch(protfolioMasterUrl, requestOptions);
    if (protfolioResponse.status === 401) {
      history.push('/logout');
    }
    const portfolioMap = new Map([['0000ALL', { label: 'ALL', value: '', sortName: '0000ALL' }]]);
    if (protfolioResponse.ok) {
      const data = await protfolioResponse.json();
      data.forEach((row) => {
        portfolioMap.set(row.portfolioId, { label: row.name, value: row.portfolioId, sortName: row.name });
      });
    }

    const portfolioOptions = Array.from(portfolioMap.values()).sort((a, b) => (a.sortName > b.sortName ? 1 : -1));

    this.setState({
      portfolioOption: portfolioOptions[0],
      portfolioOptions,
    }, () => {
      // Dropdowns are popluated, not get core data.
      this.getData();
    });
  }

  getData = () => {
    const { sellerSelect, user } = this.props;
    const {
      reportStartDate, reportEndDate, dateViewOption, dataOption, portfolioOption,
    } = this.state;

    const portfoliosDataUrl = `${apiUrl}/customers/portfolios-over-time?sellerAccountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}&startDate=${reportStartDate}&endDate=${reportEndDate}&accountType=${sellerSelect.type}&dataGroup=${dateViewOption.value}&dataSet=${dataOption.value}&portfolioFilter=${portfolioOption.value}`;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    this.setState({
      initalLoad: true,
    });

    fetch(portfoliosDataUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        this.setState({
          initalLoad: false,
          portfoliosData: data,
        });
      }).catch(() => {
        this.setState({
          initalLoad: false,
        });
      });
  };

  handleChange = (option, select) => {
    this.setState({
      [select.name]: option,
    }, () => {
      if (select.name !== 'metricOption') {
        this.getData();
      }
    });
  }

  graphFormatter = (itemType) => {
    let tickFormatter = number => formatNumberWhole(number);

    if (['acos', 'cvr'].includes(itemType)) {
      tickFormatter = percent => formatDecimalToFixedTwoPercent({ value: percent });
    }
    if (['sales', 'spend'].includes(itemType)) {
      tickFormatter = dollar => currencyFormatter({ value: dollar });
    }
    if (['roas'].includes(itemType)) {
      tickFormatter = number => formatNumberOnePlace(number);
    }
    return tickFormatter;
  }

  render() {
    const {
      portfoliosData, initalLoad, dataOption, dataOptions, reportStartDate, reportEndDate,
      dateViewOption, dateViewOptions, metricOption, metricOptions, portfolioOptions, portfolioOption,
    } = this.state;

    const { theme } = this.props;
    const themeColors = style(theme, DROPDOWN_COLORS);

    return (
      <Container className="dashboard">
        <Col md={12}>
          <Row>
            <DateRangePickerPanel
              reportStartDate={reportStartDate}
              reportEndDate={reportEndDate}
              onDateChange={this.onDateChange}
            />
          </Row>
        </Col>
        <Panel
          lg={12}
          md={12}
          title="Filters"
          subhead="NOTE: Filters and View only use data in the date range selected.  If your date range doesn't match your view, you could be seeing partial data on the ends of your view."
          parentRefresh={initalLoad}
        >
          <Row>
            <Col lg={3}>
              <p>Metric to graph:</p>
              <div className="topbar__dynamicDropdownWidth">
                <Select
                  value={metricOption}
                  name="metricOption"
                  onChange={this.handleChange}
                  options={metricOptions}
                  theme={selectTheme => ({
                    ...selectTheme,
                    colors: {
                      ...selectTheme.colors,
                      primary: `${themeColors.colorPrimary}`,
                      neutral0: `${themeColors.colorBackground}`,
                      neutral80: `${themeColors.colorText}`,
                    },
                  })}
                />
              </div>
            </Col>
            <Col lg={3}>
              <p>Portfolio Filter:</p>
              <div className="topbar__dynamicDropdownWidth">
                <Select
                  value={portfolioOption}
                  name="portfolioOption"
                  onChange={this.handleChange}
                  options={portfolioOptions}
                  theme={selectTheme => ({
                    ...selectTheme,
                    colors: {
                      ...selectTheme.colors,
                      primary: `${themeColors.colorPrimary}`,
                      neutral0: `${themeColors.colorBackground}`,
                      neutral80: `${themeColors.colorText}`,
                    },
                  })}
                />
              </div>
            </Col>
            <Col lg={3}>
              <p>Data Set:</p>
              <div className="topbar__dynamicDropdownWidth">
                <Select
                  value={dataOption}
                  name="dataOption"
                  onChange={this.handleChange}
                  options={dataOptions}
                  theme={selectTheme => ({
                    ...selectTheme,
                    colors: {
                      ...selectTheme.colors,
                      primary: `${themeColors.colorPrimary}`,
                      neutral0: `${themeColors.colorBackground}`,
                      neutral80: `${themeColors.colorText}`,
                    },
                  })}
                />
              </div>
            </Col>
            <Col lg={3}>
              <p>Date View:</p>
              <div className="topbar__dynamicDropdownWidth">
                <Select
                  value={dateViewOption}
                  name="dateViewOption"
                  onChange={this.handleChange}
                  options={dateViewOptions}
                  theme={selectTheme => ({
                    ...selectTheme,
                    colors: {
                      ...selectTheme.colors,
                      primary: `${themeColors.colorPrimary}`,
                      neutral0: `${themeColors.colorBackground}`,
                      neutral80: `${themeColors.colorText}`,
                    },
                  })}
                />
              </div>
            </Col>
          </Row>
        </Panel>

        <Panel
          lg={12}
          md={12}
          title=""
          subhead=""
          parentRefresh={initalLoad}
        >
          <Row>
            <Col md={12}>
              <ResponsiveContainer height={400} className="dashboard__area">
                <ComposedChart
                  width={600}
                  height={400}
                  data={portfoliosData}
                  margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                  }}
                >
                  <CartesianGrid stroke={style(theme).colorText} />
                  <XAxis dataKey="rankDate" stroke={style(theme).colorText} />
                  <YAxis yAxisId="left" tickFormatter={this.graphFormatter(metricOption.value)} stroke={style(theme).colorText} />
                  <YAxis yAxisId="right" orientation="right" tickFormatter={number => number} domain={[0, dataMax => (dataMax * 1.5)]} stroke={style(theme).colorText} />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey={metricOption.value} name={metricOption.label} formatter={this.graphFormatter(metricOption.value)} stackId="a" yAxisId="left" barSize={20} fill="#03c03c" />
                  {/* <Line type="monotone" dataKey="salesRankAvg" name="Sales Rank Avg" yAxisId="right" stroke="#FF9966" /> */}
                </ComposedChart>
              </ResponsiveContainer>
            </Col>
          </Row>
        </Panel>

        <Panel
          lg={12}
          md={12}
          title=""
          subhead=""
          parentRefresh={initalLoad}
        >
          <div style={{
            marginLeft: '100px',
          }}
          >
            <Table responsive striped className="dashboard__table-orders" id="dataOverTime">
              <thead>
                <tr>
                  <th className="sticky-th" />
                  {portfoliosData.map(item => (
                    <th>{item.rankDate}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {/* <tr>
                  <td>Average Sales Rank</td>
                  {portfoliosData.map(item => (
                    <td>{formatNumberWhole(item.salesRankAvg)}</td>
                  ))}
                </tr> */}
                <tr>
                  <td className="sticky-td-color" style={{ marginTop: '1px' }}>Impressions</td>
                  {portfoliosData.map(item => (
                    <td>{formatNumberWhole(item.impressions)}</td>
                  ))}
                </tr>
                <tr>
                  <td className="sticky-td">Clicks</td>
                  {portfoliosData.map(item => (
                    <td>{formatNumberWhole(item.clicks)}</td>
                  ))}
                </tr>
                <tr>
                  <td className="sticky-td-color">Orders</td>
                  {portfoliosData.map(item => (
                    <td>{formatNumberWhole(item.orders)}</td>
                  ))}
                </tr>
                <tr>
                  <td className="sticky-td">Spend</td>
                  {portfoliosData.map(item => (
                    <td>{currencyFormatter({ value: item.spend })}</td>
                  ))}
                </tr>
                <tr>
                  <td className="sticky-td-color">Sales</td>
                  {portfoliosData.map(item => (
                    <td>{currencyFormatter({ value: item.sales })}</td>
                  ))}
                </tr>
                <tr>
                  <td className="sticky-td">ACoS</td>
                  {portfoliosData.map(item => (
                    <td>{formatDecimalToFixedTwoPercent({ value: item.acos })}</td>
                  ))}
                </tr>
                <tr>
                  <td className="sticky-td-color">ROAS</td>
                  {portfoliosData.map(item => (
                    <td>{item.roas}</td>
                  ))}
                </tr>
                <tr>
                  <td className="sticky-td">CVR</td>
                  {portfoliosData.map(item => (
                    <td>{formatDecimalToFixedTwoPercent({ value: item.cvr })}</td>
                  ))}
                </tr>
              </tbody>
            </Table>
          </div>
        </Panel>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { theme, sellerSelect } = state;
  const { user } = state.authentication;

  return {
    theme,
    sellerSelect,
    user,
  };
};

export default connect(mapStateToProps)(PpcOverTimePortfolios);
