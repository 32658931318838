import { useFetchReturnsArray as useFetch } from './useFetch';
import config from '../../../configWrapper';

function formatUrl(account) {
  if (account && account.value) {
    const url = `${config.serverUrl}/accounts/api-status?accountId=${account.value}`;
    return url;
  }
  return undefined;
}

export default function useAccountApiStatus(account) {
  return useFetch(formatUrl(account));
}
