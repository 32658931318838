import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Row } from 'reactstrap';
import config from '../../../../config';
import TableBuilder from '../../../../shared/components/table/TableBuilder';
import { getExportPrefix } from '../../../../shared/components/table/functions';

export default function AmzProductOffImpTable() {
  const sellerSelect = useSelector(store => store.sellerSelect);
  const columnDefs = [
    { field: 'product_id', headerName: 'Product' },
    { field: 'product_type', headerName: 'Product Type' },
    { field: 'ad_type', headerName: 'AD Type' },
    { field: 'account_id', headerName: 'Account ID' },
    { field: 'sum_impressions', headerName: 'Impressions' },
    { field: 'sum_spend', headerName: 'Spend' },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };

  const showExportOptions = true;
  const apiEndPoint = `${config.serverUrl}/data-check/amz-product-off-imp`;

  const exportPrefix = getExportPrefix('amz-product-off-imp', sellerSelect);

  return (
    <Container>
      <Row>
        <TableBuilder
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          apiEndPoint={apiEndPoint}
          showDatePicker
          singleDatePicker
          defaultStartDays={1}
          showExportOptions={showExportOptions}
          exportPrefix={exportPrefix}
        />
      </Row>
    </Container>
  );
}
