/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Line, ComposedChart,
} from 'recharts';
import CardPanel from '../../../../../shared/components/widgets/CardPanel';
import style from '../../../../../shared/components/themeSupport';
import {
  currencyFormatter, formatDecimalToFixedTwoPercent, formatNumberWhole,
} from '../../../../../shared/components/table/functions';

function formatYAxis(value, seller) {
  const useCurrencySymbol = (seller.currencySymbol || '$');
  let displayValue = formatNumberWhole(value);
  let suffix = '';
  if (value >= 100000) {
    displayValue = value / 1000;
    suffix = 'K';
  }
  if (value >= 1000000) {
    displayValue = value / 1000000;
    suffix = 'M';
  }
  return `${useCurrencySymbol}${displayValue}${suffix}`;
}

export default function SalesGraph({ loading, data }) {
  const theme = useSelector(state => state.theme);
  const seller = useSelector(state => state.sellerSelect);
  const showDots = (fieldName) => {
    const count = (data ? data.reduce((countTotal, item) => {
      if (item[fieldName]) {
        return countTotal + 1;
      }
      return countTotal;
    }, 0) : 0);
    return (data && count < data.length);
  };

  return (
    <CardPanel
      md={12}
      title="PPC vs Organic Sales"
      parentRefresh={loading}
    >
      {!loading && data && (
        <ResponsiveContainer
          height={250}
          className="dashboard__area"
        >
          <ComposedChart data={data}>
            <CartesianGrid stroke={style(theme).colorText} />
            <XAxis dataKey="rankDate" stroke={style(theme).colorText} />
            <YAxis
              yAxisId="revenue"
              stroke={style(theme).colorText}
              tickFormatter={val => formatYAxis(val, seller)}
            />
            <YAxis
              yAxisId="ppcpercentage"
              orientation="right"
              stroke={style(theme).colorText}
              tickFormatter={val => formatDecimalToFixedTwoPercent({ value: val })}
            />
            <Tooltip />
            <Legend />
            <Area
              name="Organic Sales"
              type="monotone"
              dataKey="organic_revenue"
              fill={style(theme).areaGray}
              stroke={style(theme).lineGray}
              fillOpacity={0.2}
              stackId="rev"
              yAxisId="revenue"
              formatter={val => currencyFormatter({ value: val })}
              dot={showDots('organic_revenue')}
            />
            <Area
              name="PPC Sales"
              type="monotone"
              dataKey="sales"
              fill={style(theme).areaOrange}
              stroke={style(theme).lineOrange}
              fillOpacity={0.2}
              stackId="rev"
              yAxisId="revenue"
              formatter={val => currencyFormatter({ value: val })}
              dot={showDots('sales')}
            />
            <Line
              name="PPC Sales %"
              type="monotone"
              dataKey="ppc_sales_percent"
              yAxisId="ppcpercentage"
              stroke={style(theme).lineYellow}
              strokeWidth={2}
              formatter={val => formatDecimalToFixedTwoPercent({ value: val })}
            />
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </CardPanel>
  );
}

SalesGraph.defaultProps = {
  data: null,
};

SalesGraph.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.instanceOf(Array),
};
