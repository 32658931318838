import React from 'react';
import PropTypes from 'prop-types';
import {
  Table, Card, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import { Field } from 'redux-form';
import classnames from 'classnames';
import Panel from '../../../../../shared/components/Panel';
import renderSelectField from '../../../../../shared/components/form/Select';
import {
  typeOptions,
  statusOptions,
  editRoleOptions,
  viewRoleOptions,
} from './utils';

export default function GroupSettingsPanel({
  initialLoad, getData, dynamicValueField, onCreate, onReset, onDelete, onToggle, groups, activeTab, apiData, copyAccountSettings,
}) {
  return (
    <Panel lg={12} title="Settings By Group" getData={getData} parentRefresh={initialLoad}>
      <Card>
        <div className="profile__card tabs tabs--bordered-bottom">
          <div className="tabs__wrap">
            <Nav tabs>
              {groups && groups.map(group => (
                <NavItem key={group.value}>
                  <NavLink
                    className={classnames({ active: activeTab === group.label })}
                    onClick={() => {
                      onToggle(group.label);
                    }}
                  >
                    {group.label}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="Default" key="default">
                <p>Select tab above to start.</p>
              </TabPane>
              {groups && groups.map(group => (
                <TabPane tabId={group.label} key={group.value}>
                  <Table hover responsive className="table table--bordered">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Key</th>
                        <th>Value</th>
                        <th>Type</th>
                        <th>Status</th>
                        <th>Edit Role</th>
                        <th>View Role</th>
                        <th>Group</th>
                        <th>Update</th>
                        <th>Create</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {apiData && apiData.filter(line => (((line.setting_group || '').toLowerCase() === group.value) || (group.value === ''))).map(line => (
                        <tr key={line.account_setting_id}>
                          <td>{line.account_setting_id}</td>
                          <td>
                            <span className="form">
                              <Field
                                name={`${line.account_setting_id}Key`}
                                component="input"
                                className="form"
                              />
                            </span>
                          </td>
                          <td>
                            <span className="form">
                              {dynamicValueField(`${line.account_setting_id}Value`, line.account_setting_id)}
                            </span>
                          </td>
                          <td>
                            <Field
                              name={`${line.account_setting_id}Type`}
                              component={renderSelectField}
                              type="text"
                              options={typeOptions}
                            />
                          </td>
                          <td>
                            <Field
                              name={`${line.account_setting_id}Status`}
                              component={renderSelectField}
                              type="text"
                              options={statusOptions}
                            />
                          </td>
                          <td>
                            <Field
                              name={`${line.account_setting_id}EditRole`}
                              component={renderSelectField}
                              type="text"
                              options={editRoleOptions}
                            />
                          </td>
                          <td>
                            <Field
                              name={`${line.account_setting_id}ViewRole`}
                              component={renderSelectField}
                              type="text"
                              options={viewRoleOptions}
                            />
                          </td>
                          <td>
                            <Field
                              name={`${line.account_setting_id}Group`}
                              component={renderSelectField}
                              type="text"
                              options={groups}
                            />
                          </td>
                          <td>{line.last_updated_by} - {line.last_updated_ts}</td>
                          <td>{line.created_by} - {line.created_ts}</td>
                          <td>
                            <button type="button" className="badge badge-success badge-btn" onClick={() => onCreate(line.account_setting_id)}>Update</button>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <button type="button" className="badge badge-warning badge-btn" onClick={() => onReset(line.account_setting_id)}>Reset</button>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <button type="button" className="badge badge-danger badge-btn" onClick={() => onDelete(line.account_setting_id, line.setting_key)}>Delete</button>
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td><span>&nbsp;</span></td>
                      </tr>
                    </tbody>
                  </Table>
                </TabPane>
              ))}
            </TabContent>
            <p>Quick Actions:</p>
            <button type="button" className="btn btn-primary" onClick={() => copyAccountSettings()}>Copy Default System Account Settings</button>
          </div>
        </div>
      </Card>
    </Panel>
  );
}

GroupSettingsPanel.propTypes = {
  initialLoad: PropTypes.bool.isRequired,
  getData: PropTypes.func.isRequired,
  dynamicValueField: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  groups: PropTypes.instanceOf(Array).isRequired,
  activeTab: PropTypes.string.isRequired,
  apiData: PropTypes.instanceOf(Array).isRequired,
};
