import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import DatePicker from '../../../../../shared/components/widgets/DatePicker';
import CardPanel from '../../../../../shared/components/widgets/CardPanel';

const FilterMpOrdersPage = ({
  reportDate, dateChange,
}) => (
  <Container className="dashboard">
    <Col md={12}>
      <Row>
        <Col xs={12}>
          <CardPanel
            showButtons={false}
          >
            <Row>
              <Col xs={12} md={4}>
                <DatePicker
                  theDate={reportDate.toDate()}
                  onDateChange={dateChange}
                />
              </Col>
            </Row>
          </CardPanel>
        </Col>
      </Row>
    </Col>
  </Container>
);

export default FilterMpOrdersPage;

