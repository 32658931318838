import { useState, useEffect } from 'react';
import { getLocalStorage, setLocalStorage } from '../../storage';

export const AlertType = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  WARNING: 'warning',
  INFO: 'info',
  DANGER: 'danger',
  LIGHT: 'light',
  DARK: 'dark',
};

// Note: For now, this is just a static message. In the near future we
// can/should enhance this to retrieve the alert text from some backend
// process, so we can update it dynamically as necessary.
export default function useDashboardAlert() {
  const storedValue = getLocalStorage('showDashboardAlert');
  // Default to 'false' in local storage for now, helps guarantee we don't show alert yet.
  const [visible, setVisible] = useState((storedValue || 'false') === 'true');

  const onDismiss = () => setVisible(false);

  useEffect(() => {
    setLocalStorage('showDashboardAlert', visible.toString());
  }, [visible]);

  return {
    visible,
    alertHeading: 'Dashboard Maintenance Alert',
    alertText: `System maintenance will be performed starting Tuesday, 8 June 2021, 8:00 PM US CDT to
    Wednesday, 9 June 2021, 3:00 AM US CDT. The dashboard may be unavailable during this time period.`,
    alertType: AlertType.WARNING,
    showOnPrimaryDomainOnly: false,
    onDismiss,
  };
}
