import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import OrderPerformance from './components/OrderPerformance';

export default function OrderPerformanceIndex() {
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">DSP Order Performance</h3>
        </Col>
      </Row>
      <Row>
        <OrderPerformance />
      </Row>
    </Container>
  );
}
