import { useRouteMatch } from 'react-router-dom';

export default function useAcountTypeName() {
  const match = useRouteMatch({
    path: '/onboarding/ad-api/status/:accountTypeName',
    exact: false,
  });
  const params = (match && match.params ? match.params : '') || '';
  const accountTypeName = params ? params.accountTypeName : '';

  return accountTypeName;
}
