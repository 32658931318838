import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import PropTypes from 'prop-types';
import AuthorizeMpForm from './AuthorizeMpForm';
import { SiteName, getCorprateUrl, defaultAgencyCode as DEFAULT_AGENCY, platform as DEFAULT_PLATFORM, getPlatform } from '../../../../shared/components/domainSupport';
import useOnboardingEdata from '../../../../shared/components/hooks/useOnboardingEdata';

/*
- Agency Priority order:
    a. Use Agency in sellerSelect
    b. Use Agency in the URL
    c. Use default Agency
- Use Platform in sellerSelect to determine what the domain should be.
- Make sure MP-API is onboarding to the same platfrom as the AD-API
*/

const AuthorizeMpAPi = ({ agencyCode: agency, displayLink }) => {
  const selectedAccount = useSelector(store => store.sellerSelect);
  const [agencyCode, setAgencyCode] = useState(agency);
  const [platform, setPlatform] = useState(DEFAULT_PLATFORM);
  const { edata } = displayLink === 'yes' ? useOnboardingEdata(selectedAccount.value) : '';

  // This useEffect is really needed just to detect the seller changed. Since our input props
  // won't change in that case, we use this to force things to re-render.  Icky, but it works.
  useEffect(() => {
    if (selectedAccount && displayLink === 'yes' && selectedAccount.value !== 1) {
      const updatedAgency = JSON.parse(selectedAccount.agency);
      setAgencyCode(updatedAgency.code);
      setPlatform(selectedAccount.platform);
    }
  }, [selectedAccount, displayLink]);

  if (selectedAccount && selectedAccount.value === 0 && agency && agency !== agencyCode) { // Not logged in and the URL agency does not default agency, so use the URL agency.
    setAgencyCode(agency);
  }

  const quickReferenceLink = `${getPlatform(platform).dashboardUrl}/onboarding/walmart${agencyCode && platform !== agencyCode ? `/${agencyCode}` : ''}/mp-api?edata=${edata}`;

  // Add back in when AdJuggy goes full onboard:  || (selectedAccount.platform !== agencyCode && selectedAccount.platform !== 'adjuggernaut')
  if (selectedAccount && selectedAccount.value !== 0 && (selectedAccount.value === 1)) { // Can't run for Account ID 1 (System) and adjuggernaut is the only one that can have a different agency
    return (
      <Col md={12}>
        <Card>
          <Row>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">ERROR</h5>
              </div>
              <p>The account you are attempting to Authorize MP-API is not configured correctly.  Please contact your {SiteName()} account management team.</p>
            </CardBody>
          </Row>
        </Card>
      </Col>
    )
  }
  return (
    <Col md={12}>
      <Card>
        <Row>
          <Col md={5}>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">Add {SiteName()} as an Walmart Marketplace API Partner</h5>
              </div>
              <AuthorizeMpForm />
            </CardBody>
          </Col>
          <Col md={5}>
            <CardBody>
              <br />
              <p>PLEASE NOTE:<br /><br /></p>
              <blockquote><p>Walmart instuctions to generate a Marketplace API Key can be found <a href="https://sellerhelp.walmart.com/seller/s/guide?article=000011076&language=en_US" rel="noopener noreferrer" target="_blank">here</a></p></blockquote>
              <br />
              <blockquote><p>By Clicking &apos;Authorize&apos;, you agree to our <a href={`${getCorprateUrl()}/terms-of-use`} rel="noopener noreferrer" target="_blank">Terms &amp; Conditions</a></p></blockquote>
              <br />
              {displayLink === 'yes' && (
                <blockquote>
                  <p>This&nbsp;&nbsp;<a href={quickReferenceLink}>Quick Reference Link</a>&nbsp;&nbsp;can be used if you are not logged in</p>
                </blockquote>
              )}
            </CardBody>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

AuthorizeMpAPi.propTypes = {
  agencyCode: PropTypes.string,
  displayLink: PropTypes.string,
};

AuthorizeMpAPi.defaultProps = {
  agencyCode: DEFAULT_AGENCY,
  displayLink: 'yes',
};

export default AuthorizeMpAPi;
