import React, { PureComponent } from 'react';
import { Col, Row, Table } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import Select from 'react-select';
import config from '../../../../config';
import Panel from '../../../../shared/components/Panel';
import { User, Theme } from '../../../../shared/prop-types/MainProps';
import style, { DROPDOWN_COLORS } from '../../../../shared/components/themeSupport';

const advertisingReportList = [
  // SB
  'sb_ad_groups',
  'sb_campaigns',
  'sb_campaigns_placement',
  'sb_keywords',
  'sb_keywords_placement',
  'sb_search_terms',
  'sb_targets',
  // SBV
  'sbv_campaigns',
  'sbv_campaigns_placement',
  'sbv_keywords',
  'sbv_keywords_placement',
  'sbv_search_terms',
  'sbv_targets',
  // SD
  'sd_ad_groups',
  'sd_campaigns',
  'sd_product_ads',
  'sd_targets',
  // SP
  'sp_ad_groups',
  'sp_campaigns',
  'sp_campaigns_placement',
  'sp_keywords',
  'sp_other_asins',
  'sp_product_ads',
  'sp_search_terms',
  'sp_targets',
  'sp_targets_search_terms',
  // Daily
  'sp_daily_ad_groups',
  'sp_daily_campaigns',
  'sp_daily_keywords',
  'sp_daily_portfolios',
  'sp_daily_product_ads',
  'sp_daily_targets',
];

const dailyAdvertisingReportList = [
  // Daily SB
  // 'sb_daily_bid_recommendations_keywords',
  // 'sb_daily_bid_recommendations_targets',
  // Daily SP
  'sp_daily_ad_groups',
  // 'sp_daily_bid_recommendations_targets',
  'sp_daily_campaigns',
  'sp_daily_keywords',
  'sp_daily_portfolios',
  'sp_daily_product_ads',
  'sp_daily_targets',
];

const vendorSpecificReportList = [
  'vendor_forecast_and_inventory_planning',
  'vendor_inventory_health-_M_',
  'vendor_inventory_health-_S_',
  'vendor_sales_diagnostic-_R_M_SC_',
  'vendor_sales_diagnostic-_R_S_SC_',
  'vendor_sales_diagnostic-_R_M_OR_',
  'vendor_traffic_diagnostic',
  'vendor_traffic',
];

class PromotionEvents extends PureComponent {
  static propTypes = {
    user: User.isRequired,
    theme: Theme.isRequired,
    reportStartDate: PropTypes.string.isRequired,
    reportEndDate: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      accountData: [],
      initalLoad: false,
      reportData: {},
      filterAccountOption: { label: 'All Accounts', value: '' },
      filterAccountOptions: [{ label: 'All Accounts', value: '' }],
      filterOption: { label: 'All Datasets', value: '' },
      filterOptions: [{ label: 'All Datasets', value: '' }, { label: 'Advertising Datasets', value: 'advertising' }, { label: 'Daily Advertising Datasets', value: 'daily' }, { label: 'Vendor Central Datasets', value: 'vendor' }],
      filterReportOption: { label: 'All Reports', value: '' },
      filterReportOptions: [{ label: 'All Reports', value: '' }],
    };

    this.getColor = this.getColor.bind(this);
  }


  componentDidMount() {
    this.getDataInitalLoad();
  }

  getDataInitalLoad = () => {
    const { user } = this.props;

    const apiEndPoint = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

    const getUrl = `${apiEndPoint}/accounts/active`;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };
    this.setState({
      initalLoad: true,
    });
    fetch(getUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        const accountOptions = [{ label: 'All Accounts', value: '' }];
        data.forEach((item) => {
          accountOptions.push({
            label: `${item.seller_account_id}:${item.parent_account_id}:${item.customer_id} - ${item.seller_account_name} (${item.marketplace_name}, ${item.marketplace_currency_symbol}, ${item.seller_account_type_id})`,
            value: item.seller_account_id,
          });
        });
        this.setState({
          initalLoad: false,
          accountData: data,
          filterAccountOptions: accountOptions,
        });
        return data;
      }).catch(() => {
        this.setState({
          initalLoad: false,
        });
      });
  }

  getData = () => {
    const { user } = this.props;
    const { filterOption } = this.state;

    const apiEndPoint = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    let loopReportList = advertisingReportList.concat(dailyAdvertisingReportList, vendorSpecificReportList);
    if (filterOption.value === 'vendor') {
      loopReportList = vendorSpecificReportList;
    } else if (filterOption.value === 'advertising') {
      loopReportList = advertisingReportList;
    } else if (filterOption.value === 'daily') {
      loopReportList = dailyAdvertisingReportList;
    }

    this.setState({
      reportData: {},
    });

    const baseReportList = [];
    loopReportList.forEach((reportMix) => {
      const report = reportMix.split('-')[0];
      baseReportList.push(report);
    });

    [...new Set(baseReportList)].forEach((report) => {
      // get report Data
      const getDatasetUrl = `${apiEndPoint}/big-query/table-options-all?datasetId=${report}&cacheBuster=${moment()}`;

      const requestDatasetOptions = {
        method: 'GET',
        headers: headerWithAuth,
      };
      fetch(getDatasetUrl, requestDatasetOptions)
        .then((results) => {
          if (!results.ok) {
            throw Error(results.statusText);
          }
          return results.json();
        }).then((data) => {
          const { reportData } = this.state;
          delete reportData[report];
          const newReportData = Object.assign({ [report]: data }, reportData);
          this.setState({
            reportData: newReportData,
          });
        }).catch((error) => {
          console.error(error);
        });
    });
  };

  getColor = (input) => {
    let color;

    if (input && input.charAt(0) === '#') {
      return input;
    }

    switch (input) {
      case 'missing':
        color = '#C3485F';
        break;

      case 'good':
        color = '#4ce1b6';
        break;

      default:
        color = '#000000';
        break;
    }
    return color;
  }

  highlightDate = (sellerAccountId, parentAccountId, date, report, reportData) => {
    let returnValue = date;
    const reportType = report.split('-')[1];
    if (reportData) {
      reportData.forEach((day) => {
        if ((day.startsWith(sellerAccountId) || (day.startsWith(parentAccountId) && vendorSpecificReportList.includes(report))) && day.endsWith(date) && (!reportType || day.includes(reportType))) {
          returnValue = (<div style={{ color: '#00ff00' }}> {date} </div>);
        }
      });
    }
    return returnValue;
  }

  handleFilterChange = (filterOption) => {
    let loopReportList = advertisingReportList.concat(dailyAdvertisingReportList, vendorSpecificReportList);
    if (filterOption.value === 'vendor') {
      loopReportList = vendorSpecificReportList;
    } else if (filterOption.value === 'advertising') {
      loopReportList = advertisingReportList;
    } else if (filterOption.value === 'daily') {
      loopReportList = dailyAdvertisingReportList;
    }
    const filterReportOptions = [{ label: 'All Reports', value: '' }];
    loopReportList.forEach((report) => {
      filterReportOptions.push({ label: report, value: report });
    });

    this.setState({
      filterOption,
      filterReportOption: { label: 'All Reports', value: '' },
      filterReportOptions,
    });
  }

  handleFilterAccountChange = (filterAccountOption) => {
    this.setState({
      filterAccountOption,
    });
  }

  handleFilterReportChange = (filterReportOption) => {
    this.setState({
      filterReportOption,
    });
  }

  render() {
    const {
      accountData, initalLoad, reportData, filterOptions, filterOption, filterReportOption, filterReportOptions,
      filterAccountOption, filterAccountOptions,
    } = this.state;
    const { reportStartDate, reportEndDate, theme } = this.props;
    const dates = [];
    const themeColors = style(theme, DROPDOWN_COLORS);

    for (let m = moment.utc(reportStartDate); m.isSameOrBefore(reportEndDate); m.add(1, 'days')) {
      dates.push(m.format('YYYYMMDD'));
    }

    return (
      <Panel lg={12} title="BQ Data Check" parentRefresh={initalLoad}>
        <Row>
          <Col lg={1}>
            <p>Filter Report:</p>
          </Col>
          <Col lg={3}>
            <div className="topbar__dynamicDropdownWidth">
              <Select
                value={filterAccountOption}
                onChange={this.handleFilterAccountChange}
                options={filterAccountOptions}
                theme={selectTheme => ({
                  ...selectTheme,
                  colors: {
                    ...selectTheme.colors,
                    primary: `${themeColors.colorPrimary}`,
                    neutral0: `${themeColors.colorBackground}`,
                    neutral80: `${themeColors.colorText}`,
                  },
                })}
              />
            </div>
          </Col>
          <Col lg={3}>
            <div className="topbar__dynamicDropdownWidth">
              <Select
                value={filterOption}
                onChange={this.handleFilterChange}
                options={filterOptions}
                theme={selectTheme => ({
                  ...selectTheme,
                  colors: {
                    ...selectTheme.colors,
                    primary: `${themeColors.colorPrimary}`,
                    neutral0: `${themeColors.colorBackground}`,
                    neutral80: `${themeColors.colorText}`,
                  },
                })}
              />
            </div>
          </Col>
          <Col lg={3}>
            <div className="topbar__dynamicDropdownWidth">
              <Select
                value={filterReportOption}
                onChange={this.handleFilterReportChange}
                options={filterReportOptions}
                theme={selectTheme => ({
                  ...selectTheme,
                  colors: {
                    ...selectTheme.colors,
                    primary: `${themeColors.colorPrimary}`,
                    neutral0: `${themeColors.colorBackground}`,
                    neutral80: `${themeColors.colorText}`,
                  },
                })}
              />
            </div>
          </Col>
          <Col lg={2}>
            <div className="topbar__dynamicDropdownWidth">
              <button type="button" className="btn btn-primary account__btn" onClick={this.getData}>Run Check</button>
            </div>
          </Col>
        </Row>
        <br />
        <br />
        <Table responsive className="table--bordered">
          <thead>
            <tr>
              <th>Account</th>
              <th>Parent</th>
              <th>Type</th>
              <th>Report</th>
              {dates && dates.map(date => (
                <th>{date}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {accountData && accountData.map((line) => {
              let loopReportList = [];
              if (filterOption.value === 'advertising') {
                loopReportList = advertisingReportList;
              } else if (filterOption.value === 'vendor') {
                loopReportList = vendorSpecificReportList;
              } else if (filterOption.value === 'daily') {
                loopReportList = dailyAdvertisingReportList;
              }

              if (line.seller_account_type_id === 2 && filterOption.value !== 'advertising' && filterOption.value !== 'daily') {
                loopReportList = advertisingReportList.concat(dailyAdvertisingReportList, vendorSpecificReportList);
                if (filterOption.value === 'vendor') { // VENDOR ONLY
                  loopReportList = vendorSpecificReportList;
                }
              }

              if (filterOption.value === 'vendor' && line.seller_account_type_id === 1) {
                return '';
              }

              if (filterAccountOption.value !== '' && filterAccountOption.value !== line.seller_account_id) {
                return '';
              }

              const lineData = loopReportList.map((report) => {
                if (filterReportOption.value !== '' && filterReportOption.value !== report) {
                  return '';
                }

                return (
                  <tr>
                    <td>{`${line.seller_account_id} - ${line.seller_account_name}`}</td>
                    <td>{line.parent_account_id}</td>
                    <td>{line.dev_account_id}:{line.seller_account_type_id}:{line.marketplace_id}</td>
                    <td>{report}</td>
                    {dates.map(date => (
                      <td>{this.highlightDate(line.seller_account_id, line.parent_account_id, date, report, reportData[report.split('-')[0]])}</td>
                    ))}
                  </tr>
                );
              });
              return lineData;
            })}
          </tbody>
        </Table>
      </Panel>
    );
  }
}

const mapStateToProps = (state) => {
  const { theme } = state;
  const { user } = state.authentication;

  return {
    theme,
    user,
  };
};

export default connect(mapStateToProps)(PromotionEvents);
