import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Welcome from './components/Welcome';

const Home = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Welcome</h3>
      </Col>
    </Row>
    <Row>
      <Welcome />
    </Row>
  </Container>
);

export default Home;
