import React from 'react';
import PropTypes from 'prop-types';
import { AgCharts } from 'ag-charts-enterprise';
import 'ag-charts-community';
import { AgChartsReact } from 'ag-charts-react';
import config from '../../../config';
import LoadingSpinner from '../widgets/LoadingSpinner';

// Add when we get a license
AgCharts.setLicenseKey(config.agGridKey);

const ChartBuilder = ({
  parentRefresh,
  chartOptions,
  chartHeight,
}) => (
  <div style={{ height: chartHeight, width: '100%', padding: '20px' }}>
    {parentRefresh ? <LoadingSpinner active={parentRefresh} wrap /> : <AgChartsReact options={chartOptions} /> }
  </div>
);

ChartBuilder.propTypes = {
  parentRefresh: PropTypes.bool,
  chartOptions: PropTypes.shape().isRequired,
  chartHeight: PropTypes.string,
};

ChartBuilder.defaultProps = {
  chartHeight: '300px',
  parentRefresh: false,
};

export default ChartBuilder;
