import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from 'reactstrap';
import CardPanel from '../../../../../shared/components/widgets/CardPanel';
import { currencyFormatter, formatDecimalToFixedTwoPercent } from '../../../../../shared/components/table/functions';

export default function DataTable({ loading, data }) {
  return (
    <CardPanel
      md={12}
      title=""
      subhead=""
      parentRefresh={loading}
    >
      {!loading && data && (
        <div style={{
          marginLeft: '100px',
        }}
        >
          <Table responsive striped className="dashboard__table-orders" id="ppc-organic-perf-data">
            <thead>
              <tr>
                <th className="sticky-th" />
                {data.map(item => (
                  <th>{item.rankDate}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="sticky-td-color" style={{ marginTop: '1px' }}>PPC Spend</td>
                {data.map(item => (
                  <td>{currencyFormatter({ value: item.spend })}</td>
                ))}
              </tr>
              <tr>
                <td className="sticky-td" style={{ marginTop: '1px' }}>PPC Sales</td>
                {data.map(item => (
                  <td>{currencyFormatter({ value: item.sales })}</td>
                ))}
              </tr>
              <tr>
                <td className="sticky-td-color">Organic Sales</td>
                {data.map(item => (
                  <td>{currencyFormatter({ value: item.organic_revenue })}</td>
                ))}
              </tr>
              <tr>
                <td className="sticky-td" style={{ marginTop: '1px' }}>Total Sales</td>
                {data.map(item => (
                  <td>{currencyFormatter({ value: item.ordered_revenue })}</td>
                ))}
              </tr>
              <tr>
                <td className="sticky-td-color">PPC Sales %</td>
                {data.map(item => (
                  <td>{formatDecimalToFixedTwoPercent({ value: item.ppc_sales_percent })}</td>
                ))}
              </tr>
              <tr>
                <td className="sticky-td">PPC ACoS</td>
                {data.map(item => (
                  <td>{formatDecimalToFixedTwoPercent({ value: item.acos })}</td>
                ))}
              </tr>
              <tr>
                <td className="sticky-td-color">Total ACoS</td>
                {data.map(item => (
                  <td>{formatDecimalToFixedTwoPercent({ value: item.total_acos })}</td>
                ))}
              </tr>
              <tr>
                <td className="sticky-td" />
                {data.map(() => (
                  <td />
                ))}
              </tr>
              <tr>
                <td className="sticky-td-color">PPC Clicks</td>
                {data.map(item => (
                  <td>{item.clicks}</td>
                ))}
              </tr>
              <tr>
                <td className="sticky-td">Glance Views</td>
                {data.map(item => (
                  <td>{item.glance_views}</td>
                ))}
              </tr>
              <tr>
                <td className="sticky-td-color">PPC View %</td>
                {data.map(item => (
                  <td>{formatDecimalToFixedTwoPercent({ value: item.ppc_view_percent })}</td>
                ))}
              </tr>
              <tr>
                <td className="sticky-td" />
                {data.map(() => (
                  <td />
                ))}
              </tr>
              <tr>
                <td className="sticky-td-color">PPC ASINs</td>
                {data.map(item => (
                  <td>{item.ppc_asins}</td>
                ))}
              </tr>
              <tr>
                <td className="sticky-td">Organic ASINs</td>
                {data.map(item => (
                  <td>{item.organic_asins}</td>
                ))}
              </tr>
              <tr>
                <td className="sticky-td-color">Total ASINs</td>
                {data.map(item => (
                  <td>{item.total_asins}</td>
                ))}
              </tr>
              <tr>
                <td className="sticky-td">PPC Sales/ASIN</td>
                {data.map(item => (
                  <td>{currencyFormatter({ value: item.ppc_sales_asin })}</td>
                ))}
              </tr>
              <tr>
                <td className="sticky-td-color">Organic Sales/ASIN</td>
                {data.map(item => (
                  <td>{currencyFormatter({ value: item.organic_sales_asin })}</td>
                ))}
              </tr>
              <tr>
                <td className="sticky-td">Total Sales/ASIN</td>
                {data.map(item => (
                  <td>{currencyFormatter({ value: item.total_sales_asin })}</td>
                ))}
              </tr>
            </tbody>
          </Table>
        </div>
      )}
    </CardPanel>
  );
}

DataTable.defaultProps = {
  data: null,
};

DataTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.instanceOf(Array),
};
