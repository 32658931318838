import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import config from '../../../../config';
import TableBuilder from '../../../../shared/components/table/TableBuilder';
import {
  currencyFormatter, getExportPrefix, toDisplayDate,
} from '../../../../shared/components/table/functions';
import { imageFieldColumn, imageCellRendererComponent } from '../../../../shared/components/table/ImageCellRenderer';
import { doFetch } from '../../../../helpers';
import { SellerSelect, User } from '../../../../shared/prop-types/MainProps';
import { countryOptionsArray, countryOptionDefault } from '../../../Onboarding/amazon/components/OnboardingConfig';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;
class VendorCatalogTable extends PureComponent {
  static propTypes = {
    user: User.isRequired,
    sellerSelect: SellerSelect.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        imageFieldColumn,
        {
          field: 'asin',
          headerName: 'ASIN',
          cellRenderer: (params) => {
            if (params && params.data) {
              const getMarketPlace = (name) => {
                let result = countryOptionDefault.marketplace;
                countryOptionsArray.forEach((country) => {
                  if (name === country.value) {
                    result = country.marketplace;
                  }
                });
                return result;
              };
              const { sellerSelect } = this.props;
              const { mpName } = sellerSelect;
              const marketplace = getMarketPlace(mpName);
              const url = `https://${marketplace}/dp/${params.data.asin}`;
              return <a href={url} target="_blank" rel="noopener noreferrer">{params.data.asin}</a>;
            }
            return '';
          },
        },
        {
          field: 'sku',
          headerName: 'SKU',
        },
        { field: 'product_title', headerName: 'Product Title' },
        {
          field: 'list_price',
          headerName: 'List Price',
          valueFormatter: currencyFormatter,
        },
        {
          field: 'ad_status',
          headerName: 'Ad Status',
        },
        {
          field: 'reporting_visibility',
          headerName: 'Reporting Visibility',
        },
        {
          field: 'brand',
          headerName: 'Brand',
          headerTooltip: 'ASIN Brand used in reports',
          valueGetter: function defualtIfBlankOrNull(params) {
            if (params && params.data && (params.data.brand === '' || !params.data.brand)) {
              return params.data.default_brand;
            }
            return (params && params.data && params.data.brand) ? params.data.brand : '';
          },
          cellStyle: (params) => {
            if (params && params.data && (params.data.brand === '' || !params.data.brand)) {
              return { color: 'blue' };
            }
            return null;
          },
        },
        {
          field: 'category',
          headerName: 'Category',
          headerTooltip: 'ASIN Catagory used in reports',
          valueGetter: function defualtIfBlankOrNull(params) {
            if (params && params.data && (params.data.category === '' || !params.data.category)) {
              return params.data.default_mfg_category || params.data.default_src_category;
            }
            return (params && params.data && params.data.category) ? params.data.category : '';
          },
          cellStyle: (params) => {
            if (params && params.data && (params.data.category === '' || !params.data.category)) {
              return { color: 'blue' };
            }
            return null;
          },
        },
        {
          field: 'subcategory',
          headerName: 'Subcategory',
          headerTooltip: 'ASIN Subcategory used in reports',
          valueGetter: function defualtIfBlankOrNull(params) {
            if (params && params.data && (params.data.subcategory === '' || !params.data.subcategory)) {
              return params.data.default_mfg_subcategory || params.data.default_src_subcategory;
            }
            return (params && params.data && params.data.subcategory) ? params.data.subcategory : '';
          },
          cellStyle: (params) => {
            if (params && params.data && (params.data.subcategory === '' || !params.data.subcategory)) {
              return { color: 'blue' };
            }
            return null;
          },
        },
        {
          field: 'parent_asin',
          headerName: 'Parent ASIN',
          headerTooltip: 'Parent ASIN value used by in for all reporting',
          valueGetter: function defualtIfBlankOrNull(params) {
            if (params && params.data && (params.data.parent_asin === '' || !params.data.parent_asin)) {
              return params.data.default_parent_asin;
            }
            return (params && params.data && params.data.parent_asin) ? params.data.parent_asin : '';
          },
          cellStyle: (params) => {
            if (params && params.data && (params.data.parent_asin === '' || !params.data.parent_asin)) {
              return { color: 'blue' };
            }
            return null;
          },
        },
        {
          field: 'segment_one',
          headerName: 'Segment 1',
        },
        {
          field: 'segment_two',
          headerName: 'Segment 2',
        },
        {
          field: 'segment_three',
          headerName: 'Segment 3',
        },
        {
          field: 'segment_four',
          headerName: 'Segment 4',
        },
        {
          field: 'segment_five',
          headerName: 'Segment 5',
        },
        {
          field: 'release_date.value',
          headerName: 'Release Date',
          valueFormatter: toDisplayDate,
        },
        {
          field: 'size',
          headerName: 'Size',
          headerTooltip: 'ASIN Size used in reports',
        },
        {
          field: 'color',
          headerName: 'Color',
          headerTooltip: 'ASIN Color used in reports',
          valueGetter: function defualtIfBlankOrNull(params) {
            if (params && params.data && (params.data.color === '' || !params.data.color)) {
              return params.data.default_color;
            }
            return (params && params.data && params.data.color) ? params.data.color : '';
          },
          cellStyle: (params) => {
            if (params && params.data && (params.data.color === '' || !params.data.color)) {
              return { color: 'blue' };
            }
            return null;
          },
        },
        { field: 'budget_group', headerName: 'Budget Group' },
        { field: 'binding', headerName: 'Binding' },
        { field: 'model_style_number', headerName: 'Model / Style Number' },
        { field: 'ean', headerName: 'EAN' },
        { field: 'upc', headerName: 'UPC' },
      ],
      defaultColDef: {
        sortable: true,
        filter: false,
        resizable: true,
        cellStyle: (params) => {
          if (params && params.value < 0.00) {
            return { color: 'red' };
          }
          return null;
        },
      },
      frameworkComponents: {
        ...imageCellRendererComponent,
      },
      showDatePicker: false,
      showFooter: false,
      parentControlledLoad: true,
    };
  }

  render() {
    const {
      columnDefs, defaultColDef, showDatePicker, showFooter, pdOptions, frameworkComponents, parentControlledLoad,
    } = this.state;

    const { user, sellerSelect } = this.props;

    const apiEndPoint = `${apiUrl}/vendors/asin-catalog`;
    const paginationPageSize = 5000;
    const cacheBlockSize = paginationPageSize;
    const exportPrefix = getExportPrefix('amz-catalog', sellerSelect);

    const datasource = {
      getRows: async (params) => {
        const {
          request, success, fail,
        } = params;
        const { startRow, sortModel } = request;
        const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
        const requestOptions = {
          method: 'GET',
          headers: headerWithAuth,
        };

        let sort = '';
        if (sortModel.length > 0) {
          sort = `${sortModel[0].colId} ${sortModel[0].sort}`;
        }

        const offset = startRow || 0;

        const url = `${apiEndPoint}?sellerAccountId=${sellerSelect.value.toString()}&parentAccountId=${sellerSelect.parentAccountId}&currencyCode=${(sellerSelect.currencyCode || 'USD')}&accountType=${sellerSelect.type}&limit=${paginationPageSize}&offset=${offset}&sort=${sort}`;
        try {
          const data = await doFetch(url, requestOptions);
          success({
            rowData: data.results,
            rowCount: data.total,
          })
        } catch (error) {
          fail();
        }
      },
    };

    return (
      <TableBuilder
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        apiEndPoint={apiEndPoint}
        showDatePicker={showDatePicker}
        showFooter={showFooter}
        productDetailOptions={pdOptions}
        frameworkComponents={frameworkComponents}
        rowHeight={75}
        autoSizeAll
        pagination="true"
        rowModelType="serverSide"
        datasource={datasource}
        paginationPageSize={paginationPageSize}
        cacheBlockSize={cacheBlockSize}
        serverSideInfiniteScroll
        parentControlledLoad={parentControlledLoad}
        exportPrefix={exportPrefix}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state.authentication;
  const { sellerSelect } = state;

  return {
    user,
    sellerSelect,
  };
};

export default connect(mapStateToProps)(VendorCatalogTable);
