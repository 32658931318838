import { Buffer } from 'buffer';

export function setLocalStorage(key, value) {
  localStorage.setItem(key, Buffer.from(encodeURIComponent(value)).toString('base64'));
}

export function getLocalStorage(key) {
  const pulledValue = localStorage.getItem(key);
  let returnValue = pulledValue; // backwards compatiable
  if (pulledValue && !pulledValue.startsWith('true') && !pulledValue.startsWith('false') && !pulledValue.startsWith('{') && !pulledValue.startsWith('[') && !pulledValue.includes('@')) {
    returnValue = pulledValue ? decodeURIComponent(Buffer.from(pulledValue, 'base64').toString('utf-8')) : pulledValue;
  }
  return returnValue;
}
