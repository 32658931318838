import React, { PureComponent } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import FullWidthFillGraph from '../../../../shared/components/widgets/FullWidthFillGraph';
import BudgetTable from './BudgetTable';
import config from '../../../../config';
import { SellerSelect, User } from '../../../../shared/prop-types/MainProps';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class BudgetGraph extends PureComponent {
  static propTypes = {
    sellerSelect: SellerSelect.isRequired,
    user: User.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      graphData: [],
      graphLoad: false,
      checkedItem: ['spend', 'sales'],
      reportStartDate: moment().startOf('month').format('YYYYMMDD'),
      reportEndDate: moment().format('YYYYMMDD'),
      currentSales: 0,
      currentSpend: 0,
      currentById: { sales: {}, spend: {} },
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevState) {
    const { sellerSelect } = this.props;
    if (sellerSelect.value !== prevState.sellerSelect.value) {
      this.getData();
    }
  }

  getData = () => {
    const { sellerSelect, user } = this.props;
    const {
      reportStartDate, reportEndDate,
    } = this.state;
    const budgetUrl = `${apiUrl}/customers/ppc-sales-spend?sellerAccountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}&startDate=${reportStartDate}&endDate=${reportEndDate}&accountType=${sellerSelect.type}&allTotalsFlag=false`;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    this.setState({
      graphLoad: true,
    });

    fetch(budgetUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        const graphData = data.by_date.sort((a, b) => {
          const checkPos = (b.report_date > a.report_date) ? -1 : 0;
          return (b.report_date < a.report_date) ? 1 : checkPos;
        });

        const currentSales = data.totals.total_sales;
        const currentSpend = data.totals.total_spend;
        const currentById = data.by_id;

        this.setState({
          graphLoad: false,
          graphData,
          currentSales,
          currentSpend,
          currentById,
        });
      }).catch(() => {
        this.setState({
          graphLoad: false,
        });
      });
  };


  render() {
    const {
      graphData, graphLoad, checkedItem, currentSpend, currentSales, currentById,
    } = this.state;

    return (
      <Container className="dashboard">
        <Col md={12}>
          <Row>
            <FullWidthFillGraph
              data={graphData}
              dataType="asin"
              title="Budget Graph"
              initalLoad={graphLoad}
              lineList={checkedItem}
              colorOptions={['#70bbfd', '#ffb153', '#f47b50', '#c88ffa', '#4ce1b6', '#66CCFF', '#FF9966', '#FF6666', '#9999CC', '#33CCCC']}
            />
          </Row>
        </Col>
        <Col md={12}>
          <Row>
            <BudgetTable
              title="Budget Table"
              currentSpend={currentSpend}
              currentSales={currentSales}
              currentById={currentById}
            />
          </Row>
        </Col>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { sellerSelect } = state;
  const { user } = state.authentication;

  return {
    sellerSelect,
    user,
  };
};

export default connect(mapStateToProps)(BudgetGraph);
