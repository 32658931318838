import React, { PureComponent } from 'react';
import NotificationSystem from 'rc-notification';
import moment from 'moment';
import { connect } from 'react-redux';
import { BasicNotification } from '../../../../shared/components/Notification';
import config from '../../../../config';
import TableBuilder from '../../../../shared/components/table/TableBuilder';
import { SellerSelect, User } from '../../../../shared/prop-types/MainProps';
import { getExportPrefix } from '../../../../shared/components/table/functions'

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

let notificationLU = null;
let notificationRU = null;
let notificationTC = null;

class CalendarManagementTable extends PureComponent {
  static propTypes = {
    sellerSelect: SellerSelect.isRequired,
    user: User.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        {
          field: 'calendar_id',
          headerName: '#',
        },
        {
          field: 'title',
          headerName: 'Title',
          editable: true,
        },
        {
          field: 'all_day',
          headerName: 'All Day',
          editable: true,
        },
        {
          field: 'start',
          headerName: 'Start (GMT)',
          editable: true,
        },
        {
          field: 'end',
          headerName: 'End (GMT)',
          editable: true,
        },
        {
          field: 'priority',
          headerName: 'Priority',
          editable: true,
        },
        {
          field: 'owner',
          headerName: 'Owner',
          editable: true,
        },
        {
          field: 'seller_account_id',
          headerName: 'SAID',
          editable: true,
        },
      ],
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
      },
      showDatePicker: false,
      showFooter: false,
      showAddRowButton: true,
      showDeleteSelectedRowButton: true,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line
    NotificationSystem.newInstance({}, n => notificationLU = n);
    // eslint-disable-next-line
    NotificationSystem.newInstance({}, n => notificationRU = n);
    // eslint-disable-next-line
    NotificationSystem.newInstance({}, n => notificationTC = n);
  }

  componentWillUnmount() {
    if (notificationLU) {
      notificationLU.destroy();
    }
    if (notificationLU) {
      notificationRU.destroy();
    }
    if (notificationLU) {
      notificationTC.destroy();
    }
  }

  // eslint-disable-next-line
  show = (color, title, msg) => {
    return config.showNotification({
      notification: <BasicNotification
        color={color}
        title={title}
        message={msg}
      />,
      position: 'right-up',
      notificationLU,
      notificationRU,
      notificationTC,
    });
  };

  getNewRow = () => {
    const { user, sellerSelect } = this.props;
    const newData = {
      calendar_id: 0,
      title: '',
      all_day: 'true',
      start: moment.utc().format(),
      end: moment.utc().format(),
      priority: 'normal',
      owner: Number(user.id),
      seller_account_id: Number(sellerSelect.value),
    };
    return newData;
  }

  parentProcessChange = (rowNode, user, sellerSelect, apiEndPoint, childThis) => {
    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
    // Add logic to check input values, make sure they are present and correct.
    const requestOptions = {
      method: 'POST',
      headers: headerWithAuth,
      body: JSON.stringify({
        calendarId: rowNode.data.calendar_id,
        title: rowNode.data.title,
        allDay: rowNode.data.all_day,
        start: rowNode.data.start,
        end: rowNode.data.end,
        priority: rowNode.data.priority,
        owner: rowNode.data.owner?.toString(),
        sellerAccountId: rowNode.data.seller_account_id?.toString(),
      }),
    };

    // Call via API (would like to update to " await ")
    fetch(apiEndPoint, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        // success message
        if (rowNode.data.calendar_id === '0') {
          this.show('primary', 'Success', `Calendar entry ${data[0].calendar_id} has been created.`);
          childThis.refreshData();
        } else {
          this.show('primary', 'Success', `Calendar entry ${data[0].calendar_id} has been updated.`);
        }
      }).catch((error) => {
        this.show('danger', 'Error', `Problem adding/updating Calendar entry. \n Refrence: ${error}`);
      });
  }

  parentDeleteRows = (selectedRows, user, apiEndPoint, childThis) => {
    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
    // Add logic to check input values, make sure they are present and correct.
    const promises = [];

    selectedRows.forEach((selectedRow) => {
      const requestOptions = {
        method: 'DELETE',
        headers: headerWithAuth,
      };

      const fulDeleteUrl = `${apiEndPoint}/${selectedRow.calendar_id}/seller/${selectedRow.seller_account_id}`;

      // Call via API (would like to update to " await ")
      promises.push(fetch(fulDeleteUrl, requestOptions)
        .then((results) => {
          if (!results.ok) {
            throw Error(results.statusText);
          }
          return results.json();
        }).then(() => {
          // success message
          this.show('primary', 'Success', `Calendar entry ${selectedRow.calendar_id} has been deleted.`);
        }).catch((error) => {
          this.show('danger', 'Error', `Problem deleting Calendar entry. \n Refrence: ${error}`);
        }));
    });
    if (promises.length > 0) {
      Promise.all(promises).then(() => {
        // All good.
        childThis.refreshData();
      }, () => {
        childThis.refreshData();
      });
    }
  }

  render() {
    const {
      columnDefs, defaultColDef, showDatePicker, showFooter, showAddRowButton, showDeleteSelectedRowButton,
    } = this.state;

    const apiEndPoint = `${apiUrl}/events/calendar`;

    const exportPrefix = getExportPrefix('admin-calendar')

    return (
      <TableBuilder
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        apiEndPoint={apiEndPoint}
        showDatePicker={showDatePicker}
        showFooter={showFooter}
        parentProcessChange={this.parentProcessChange}
        parentDeleteRows={this.parentDeleteRows}
        showAddRowButton={showAddRowButton}
        showDeleteSelectedRowButton={showDeleteSelectedRowButton}
        getNewRow={this.getNewRow}
        exportPrefix={exportPrefix}
        confirmLogic
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { sellerSelect } = state;
  const { user } = state.authentication;

  return {
    sellerSelect,
    user,
  };
};

export default connect(mapStateToProps)(CalendarManagementTable);
