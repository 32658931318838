import PropTypes from 'prop-types';

const {
  string, shape, number, bool,
} = PropTypes;

// Shape of the agency, but it's in string form
// const agencyShape = shape({
//   id: number,
//   code: string,
//   name: string,
// });

export const SellerSelect = shape({
  value: number,
  label: string,
  type: number,
  currencySymbol: string,
  currencyCode: string,
  parentAccountId: number,
  mpName: string,
  agency: string, // Object in a string, needs to be Parsed if used.
  platform: string,
  subscriptionId: number,
  plan: string,
  planStatus: string,
  externalCustomerId: string,
  externalAccountId: string,
  customerId: number,
});

export const User = shape({
  id: number,
  email: string,
  access: string,
  access_control: string,
  first_name: string,
  last_name: string,
  folder: string,
  date_added: string,
  is_active: number,
  date_updated: string,
  username: string,
  verified: number,
  theme: string,
  jwtToken: string,
});

export const Theme = shape({
  value: string,
  label: string,
});

export const LoggedIn = bool;
