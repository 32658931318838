import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { Button, ButtonToolbar } from 'reactstrap';
import renderSelectField from '../../../../../shared/components/form/Select';
import config from '../../../../../config'

const renderFieldBudgetGroup = ({
  input, placeholder, type, isDisabled, meta: { error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} placeholder={placeholder} type={type} disabled={isDisabled} />
    {<span className="form__form-group-note">Alphanumeric, spaces, and hyphons. {error}</span>}
  </div>
);

const EditMasterCatalogModalForm = ({
  changeShowEditModal, selectedData, forceRefresh,
}) => {
  const selectedSeller = useSelector(store => store.sellerSelect);
  const user = useSelector(store => store.authentication.user);
  const formValues = useSelector(state => getFormValues('BulkManageCatalog')(state));
  const [showError, setShowError] = useState('');

  const statusOptions = [
    { label: 'ON', value: 'ON' },
    { label: 'OFF', value: 'OFF' },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    const { budgetGroup } = formValues;
    const reportingVisibility = budgetGroup !== '' ? formValues.reportingVisibility.value : 'OFF';
    const adStatus = budgetGroup !== '' ? formValues.adStatus.value : 'OFF';

    // Build update data, sp and sd are tied to ad_status
    const updateData = [
      {
        name: 'ad_status',
        value: adStatus,
      },
      {
        name: 'sp_status',
        value: adStatus,
      },
      {
        name: 'sd_status',
        value: adStatus,
      },
      {
        name: 'reporting_visibility',
        value: reportingVisibility,
      },
      {
        name: 'budget_group',
        value: budgetGroup,
      },
    ];

    // Build update IDs
    const updateMasterIds = [];
    const addMasterIds = [];
    if (selectedData && Array.isArray(selectedData)) {
      selectedData.forEach(selectedRow => {
        if (selectedRow.sku_master_id || selectedRow.asin_master_id) {
          if (selectedRow.catalog_type === 'seller') {
            updateMasterIds.push(selectedRow.sku_master_id);
          } else if (selectedRow.catalog_type === 'vendor') {
            updateMasterIds.push(selectedRow.asin_master_id);
          }
        } else {
          addMasterIds.push({
            accountId: selectedSeller.value, asin: selectedRow.asin, sku: selectedRow.sku, brand: selectedRow.brand, subcategory: selectedRow.subcategory, adStatus, reportingVisibility, budgetGroup, userId: user.id,
          });
        }
      });
    }

    const putData = {
      accountId: selectedSeller.value,
      accountTypeId: selectedSeller.type,
      updateData,
      updateMasterIds,
      addMasterIds,
    }

    const catalogsUrl = `${config.serverUrl}/catalogs`;
    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
    const requestOptions = {
      method: 'PUT',
      headers: headerWithAuth,
      body: JSON.stringify(putData),
    };

    fetch(catalogsUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then(() => {
        changeShowEditModal();
        forceRefresh();
        // window.location.reload();
      }).catch((error) => {
        console.error(error);
        setShowError('Failed to update rows.');
      });
  };

  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="form__form-group">
        <br />
      </div>
      <h3>{selectedData.length} items selected for update.</h3>
      <div className="form__form-group">
        <br />
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">AD Status:</span>
        <div className="form__form-group-field">
          <Field
            name="adStatus"
            component={renderSelectField}
            type="text"
            options={statusOptions}
            value={formValues?.adStatus}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Reporting Status:</span>
        <div className="form__form-group-field">
          <Field
            name="reportingVisibility"
            component={renderSelectField}
            type="text"
            options={statusOptions}
            value={formValues?.reportingVisibility}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label typography-message">Budget Group Name:</span>
        <div className="form__form-group-field priority">
          <Field
            name="budgetGroup"
            component={renderFieldBudgetGroup}
            type="text"
            value={formValues?.budgetGroup}
          />
        </div>
      </div>
      <div className="form__form-group">
        <br />
      </div>
      {showError && (
        <div className="form__form-group-error">&nbsp;&nbsp;&nbsp;Error: {showError}</div>
      )}
      <ButtonToolbar className="form__button-toolbar">
        <Button color="primary" type="submit">
          Update Selected Rows
        </Button>
        <Button type="button" onClick={changeShowEditModal}>Cancel</Button>
      </ButtonToolbar>
    </form>
  );
}

EditMasterCatalogModalForm.propTypes = {
  changeShowEditModal: PropTypes.func.isRequired,
};

const validate = (values) => {
  const error = {};
  // eslint-disable-next-line
  values.budgetGroup = values.budgetGroup.replace(/[^\-0-9A-Za-z ]/, '');
  if (values && values.budgetGroup.length > 255) {
    // eslint-disable-next-line
    values.budgetGroup = values.budgetGroup.substring(0, 255);
  }
  error.budgetGroup = `${values?.budgetGroup.length} of 255 characters`;
  return error;
};

export default reduxForm({
  form: 'BulkManageCatalog',
  validate,
  initialValues: {
    adStatus: { label: 'ON', value: 'ON' },
    reportingVisibility: { label: 'ON', value: 'ON' },
    budgetGroup: '',
  },
})(EditMasterCatalogModalForm);
