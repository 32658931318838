import React from 'react';
import PropTypes from 'prop-types';
import AccountDataPointPanel from './AccountDataPointPanel';
import { currencyFormatter } from '../../../../shared/functions';

export default function GrossSales({
  data, loading, dateView, title, subtitle, dayField, barColor, account,
}) {
  return (
    <AccountDataPointPanel
      data={data}
      title={title}
      subtitle={subtitle}
      dayField={dayField}
      formatFunction={value => currencyFormatter({ value, account })}
      barColor={barColor}
      loading={loading}
      dateView={dateView}
    />
  );
}

GrossSales.defaultProps = {
  data: null,
  title: 'Gross Sales',
  subtitle: 'Total for Date Range',
  dayField: 'gross_sales',
  barColor: '#f47b50',
};

GrossSales.propTypes = {
  loading: PropTypes.bool.isRequired,
  dateView: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(Array),
  account: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  dayField: PropTypes.string,
  barColor: PropTypes.string,
};
