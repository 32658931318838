import React from 'react';
import { useSelector } from 'react-redux';
import {
  Col, Container, Row,
} from 'reactstrap';
import PropTypes from 'prop-types';
import AuthorizeAdApi from './components/AuthorizeAdApi';
import { defaultAgencyCode as DEFAULT_AGENCY } from '../../../shared/components/domainSupport';

const AuthAdApiIndex = ({ agencyCode, apiType }) => {
  const sellerSelect = useSelector(state => state.sellerSelect);
  const localApiType = apiType || 'ad-api';
  const localAgencyCode = agencyCode || sellerSelect.agency;
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Authorize Advertising API</h3>
        </Col>
      </Row>
      <Row>
        <br />
      </Row>
      <Row>
        <AuthorizeAdApi agencyCode={localAgencyCode} apiType={localApiType} />
      </Row>
      <Row>
        <br />
      </Row>
    </Container>
  );
};

AuthAdApiIndex.propTypes = {
  agencyCode: PropTypes.string,
  apiType: PropTypes.string.isRequired,
};

AuthAdApiIndex.defaultProps = {
  agencyCode: DEFAULT_AGENCY,
};

export default AuthAdApiIndex;
