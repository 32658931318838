import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Picker from 'rsuite/DatePicker'
import lodash from 'lodash'

export default function DatePicker(props) {
  const {
    label, theDate, onDateChange,
  } = props;

  // eslint-disable-next-line
  const [pickerId, setPickerId] = useState(lodash.uniqueId('date-picker-'));
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }
  })

  const yesterday = moment().subtract(1, 'day');

  return (
    <>
      {label && (
        <p>{label}</p>
      )}
      <div id={pickerId} className="topbar__dynamicDropdownWidth">
        <Picker
          defaultValue={theDate || yesterday.toDate()}
          onChange={date => (date ? onDateChange(date, date) : null)}
          container={document.getElementById(pickerId)}
          oneTap
        />
      </div>
    </>
  )
}
