import React from 'react';
import { CardBody, Row, Col } from 'reactstrap';

export default function StreamsSettingsTable({ accountSettings, subscriptions, entityId, listEntities, activateSqsSubscriptions, setDataSetsCsv, submit, successRefresh }) {
  const actionQueue = [];
  const dataSets = [];
  accountSettings.forEach(sqsQueue => {
    if (sqsQueue.setting_value?.toUpperCase() === 'ON') {
      // Check to see if it is already ACTIVE or SUSPENDED
      const subKey = sqsQueue.setting_key.replace('SQS Stream - ', '').toLowerCase().replaceAll(' ', '-');
      let foundDataSet = false;
      subscriptions.forEach(sqsSub => {
        // terminal states: [ARCHIVED, FAILED_CONFIRMATION, SUSPENDED, FAILED_PROVISIONING]
        if (subKey === sqsSub.dataSetId && (sqsSub.status === 'ACTIVE' || sqsSub.status === 'PENDING_CONFIRMATION' || sqsSub.status === 'PROVISIONING')) {
          foundDataSet = true;
        }
      });
      if (!foundDataSet) {
        actionQueue.push(sqsQueue);
        dataSets.push(subKey.replaceAll('-', '_'));
      }
    }
  });
  setDataSetsCsv(dataSets.join(','));

  return (
    <CardBody>
      <Row>
        <Col md={2}>
          <h3>Configured Settings:</h3>
          <br />
          {accountSettings.length > 0 && (
            <div>
              {accountSettings.map(setting => (
                <div>
                  <h4>{setting.setting_value?.toUpperCase()} : {setting.setting_key}</h4>
                </div>
              ))}
            </div>
          )}
        </Col>
        <Col md={2}>
          <div>
            <h3>Entity ID: {entityId}</h3>
            <br />
            <br />
            In order to turn on Subscriptions, you need to configure account settings.<br />
            (1) Turn &quot;ON&quot; SQS Suscriptions in the SQS controls<br />
            (2) Set the Entity ID in Customer IDs section.<br />
            <br />
            If you have Actionable SQS items and a Entity ID the Activate SQS Subscriptions button will be enabled.
            <br />
            Here is a list of potential Entity IDs if you need it for reference.  Please confirm that the following are correct: Type, Currency.
            <br />
          </div>
        </Col>
        <Col md={2}>
          <h3>Actionable SQS Items:</h3>
          <br />
          {actionQueue.length > 0 && (
            <div>
              {actionQueue.map(action => (
                <div>
                  <h4>{action.setting_value?.toUpperCase()} : {action.setting_key}</h4>
                </div>
              ))}
            </div>
          )}
          <br />
          <br />
          <button type="button" className="btn btn-primary" disabled={entityId === '' || actionQueue.length === 0 || submit} onClick={() => activateSqsSubscriptions()}>Activate SQS Subscriptions</button>
        </Col>
        <Col md={6}>
          {entityId === '' && !submit && !successRefresh && (
            <div>
              <h3 style={{ color: 'red' }}>**** UPDATE only use Entity ID&apos;s that start with &apos;ENTITY&apos; *******</h3>
              <h3 style={{ color: 'red' }}>Confrim you are matching Type, Country, Currency, and Name.</h3>
              <h3 style={{ color: 'red' }}>Sellers Entity ID may be found outside this data.</h3>
              Here is a list of potential Entity IDs if you need it for reference.  Please confirm that the following are correct: Type, Country, Currency, and Name.
              <br />
              <b>AccountID: ProfileID-CountryCode-CurrencyCode-MarketpalceID-Type-Name</b>
              <br />
              {listEntities.length > 0 && (
                <div>
                  {listEntities.map(entity => (
                    <div>
                      {entity.accountInfo.id} : {entity.profileId}-{entity.countryCode}-{entity.currencyCode}-{entity.accountInfo.marketplaceStringId}-{entity.accountInfo.type}-{entity.accountInfo.name}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </Col>
      </Row>
    </CardBody>
  );
}
