import React from 'react';
import CalculatorContent from './Calculator/CalculatorContent';
import { isAdRev } from '../../../shared/components/domainSupport';

export default function CalculatorPage() {
  if (isAdRev()) {
    return (
      <CalculatorContent />
    );
  }
  return (<span>Page not available</span>);
}
