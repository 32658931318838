/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Container, Col, Row } from 'reactstrap';
import moment from 'moment';
import BqVendorData from './components/BqVendorData';
import DateRangePickerPanel from '../../../shared/components/widgets/DateRangePickerPanel';

class DataCheckPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      reportStartDate: moment().subtract(6, 'days').format('YYYY-MM-DD'),
      reportEndDate: moment().format('YYYY-MM-DD'),
    };
  }

  onDateChange = (startDate, endDate) => {
    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).format('YYYY-MM-DD');
    this.setState({
      reportStartDate: start,
      reportEndDate: end,
    });
  }

  render() {
    const {
      reportStartDate, reportEndDate,
    } = this.state;

    return (
      <Container className="dashboard">
        <Col md={12}>
          <Row>
            <DateRangePickerPanel
              reportStartDate={reportStartDate}
              reportEndDate={reportEndDate}
              onDateChange={this.onDateChange}
            />
          </Row>
        </Col>
        <Col md={12}>
          <Row>
            <BqVendorData reportStartDate={reportStartDate} reportEndDate={reportEndDate} />
          </Row>
        </Col>
      </Container>
    );
  }
}

export default DataCheckPage;
