import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';

export const DisplayErrorShape = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  text: PropTypes.string,
};

export default function DisplayTableError(props) {
  const {
    displayError, resetError, checkErrorId, colSpan,
  } = props;

  return (
    <>
      {displayError && displayError.id === checkErrorId && (
        <tr>
          <td colSpan={colSpan}><Alert color="danger" toggle={resetError}>{ displayError.text }</Alert></td>
        </tr>
      )}
    </>
  );
}

DisplayTableError.defaultProps = {
  colSpan: 12,
  checkErrorId: 'CreateError',
};

DisplayTableError.propTypes = {
  displayError: PropTypes.shape(DisplayErrorShape).isRequired,
  resetError: PropTypes.func.isRequired,
  checkErrorId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  colSpan: PropTypes.number,
};
