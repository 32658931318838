import React from 'react';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import Panel from '../../../shared/components/Panel';
import {
  SiteImage, SiteNameWithTM, isLimitless, isAdRev,
} from '../../../shared/components/domainSupport';

export function getCurrentDate(separator = '') {
  const newDate = new Date();
  const date = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();
  return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}`;
}

const Welcome = () => {
  const currentDate = getCurrentDate('-');

  if (isAdRev()) {
    return (
      <Col md={12}>
        <Panel showButtons={false}>
          <div className="card__title">
            <h5 className="bold-text">About Us</h5>
            <h5 className="subhead">Todays Date: {currentDate}</h5>
            <br />
            <br />
            <SiteImage />
          </div>
        </Panel>
        <Row>
          <Col md={6}>
            <Panel title="Meet AdRev" showButtons={false}>
              <p>
                <li>Built to bring a managed service model to any size of advertising effort adrev.tech (AdRev) represents a fundemental shift in how ads are managed on Amazon.</li>
              </p>
              <p>
                <li>Our Hybid AI™ ad-tech platform is the first of its kind, giving Sellers and Vendors the only tool they will ever need to manage ads. AdRev&apos;s team of experts tailor your ad strategy, manage planning and execution, launch campaigns, optimize targeting, and deliver viable targets that generate returns at or above your margin goals, maximizing the return on your ad spend.</li>
              </p>
              <p>
                <li>No more need to buy expensive keyword tools, no more unnecessary software add-ons, no more paying platform fees just to have to launch & manage your own ads.</li>
              </p>
              <br />
              <br />
              <br />
              <br />
            </Panel>
          </Col>
          <Col md={6}>
            <Panel title="Who is AdRev For?" showButtons={false}>
              <p>
                <li>AdRev empowers sellers and vendors in all Amazon marketplaces.</li>
              </p>
              <p>
                <li>It&apos;s for CMOs, marketers, founders, CEOs, and anyone else responsible for Amazon advertising who wants to reclaim valuable time spent in the weeds on Amazon and get more bandwidth to do the things that matter, like growing your business.</li>
              </p>
              <p>
                <li>Amazon grows in complexity each day, keeping up with changes and best practices requires hours of education. Understanding how to best achieve your goals on Amazon can be daunting and leave you full of doubt.</li>
              </p>
              <p>
                <li>It&apos;s time to give up managing campaigns and start seeing the forest again.</li>
              </p>
              <br />
              <br />
              <br />
              <br />
            </Panel>
          </Col>
          <Col md={6}>
            <Panel title="Why AdRev?" showButtons={false}>
              <p>
                <li>You have millions of potential customers out there, you just need to reach them.</li>
              </p>
              <p>
                <li>With AdRev, you have the control to set your margin goals and leave execution to experts. No more guessing if you bought the right software or if you are spending enough on the right keywords. The return will speak for itself.</li>
              </p>
              <p>
                <li>AdRev offers solutions for businesses of any size, from those in the early stages of development to those seeking a reliable and efficient solution for their advertising needs.</li>
              </p>
              <br />
              <br />
              <br />
              <br />
              <br />
            </Panel>
          </Col>
          <Col md={6}>
            <Panel title="Usage Agreements" showButtons={false}>
              <p>
                <li>Your use of this online service constitutes your agreement to these Terms of Use. If you do not agree with these Terms of Use, please do not use our online services. <SiteNameWithTM /> reserves the right to change, modify, add, or remove portions of these Terms of Use at any time.</li>
              </p>
              <p>
                <li>Our <SiteNameWithTM /> website is currently a work in progress and is provided to you &quot;AS IS&quot; and without warranties.</li>
              </p>
              <p>
                <li>This site has security measures in place to protect the loss, misuse and alteration of the information under our control. Any misuse will result in account removal.</li>
              </p>
              <p>
                Our full privicy policy can be found <a href="https://adrev.tech/privacy">here</a>.
              </p>
              <p>
                Terms and Conditions can be found <a href="https://adrev.tech/term">here</a>.
              </p>
              <br />
              <br />
            </Panel>
          </Col>
        </Row>
      </Col>
    );
  }

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">About Us</h5>
            <h5 className="subhead">Todays Date: {currentDate}</h5>
            <br />
            <br />
            <SiteImage />
          </div>
        </CardBody>
        <CardBody>
          <br />
          <div className="card__title">
            <h5 className="bold-text">What we offer:</h5>
          </div>
          <p>Fully Managed Amazon Advertising.</p>
          <p>We deliver the most advanced Fully Managed Amazon Advertising platform for Agencies, Brands & Sellers.</p>
          <p>The formula: +1 Billion Keywords + Expert Campaign Management + Powerful Algorithms & Proprietary Software + Customer Controlled Dashboard</p>
          <p>Welcome to Amazon’s Unfair Advantage.</p>
        </CardBody>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Usage Agreements:</h5>
          </div>
          <p>
            Your use of this online service constitutes your agreement to these Terms of Use. If you do not agree with these Terms of Use, please do not use our online services. <SiteNameWithTM /> reserves the right to change, modify, add, or remove portions of these Terms of Use at any time.
            Our <SiteNameWithTM /> website is currently a work in progress and is provided to you &quot;AS IS&quot; and without warranties.
            This site has security measures in place to protect the loss, misuse and alteration of the information under our control. Any misuse will result in account removal.
          </p>
          {isLimitless() && (
            <p>Our full privicy policy can be found <a href="https://limitless.tech/privacy/">here</a>.</p>
          )}
          {isLimitless() && (
            <p>Questions, Comments, Feedback: please email info@limitless.tech.</p>
          )}
        </CardBody>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Our Engineering Philosophy:</h5>
          </div>
          <p>
            We are a continuous deployment shop, it is our goal to get improvements and fixes to you as fast as we can. We use technology to our advantage and will always explore and implement new technology for our services when it is appropriate. We utilize the cloud and its ability to scale when needed. We backup our database daily and store all our source code. We believe in security and automation.
          </p>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Welcome;
