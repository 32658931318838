import React from 'react';
import classNames from 'classnames';
import { Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import { ThemeProps } from '../../../../../shared/prop-types/ReducerProps';
import EditMasterCatalogModalForm from './EditMasterCatalogModalForm';

const EditMasterCatalogModal = ({
  changeShowEditModal, showEditModal, theme, selectedData, forceRefresh,
}) => {
  const modalClasses = classNames({
    'todo__add-modal': true,
  }, theme.className, 'ltr-support');

  return (
    <Modal
      isOpen={showEditModal}
      toggle={changeShowEditModal}
      className={modalClasses}
    >
      <EditMasterCatalogModalForm
        changeShowEditModal={changeShowEditModal}
        selectedData={selectedData}
        forceRefresh={forceRefresh}
      />
    </Modal>
  );
};

EditMasterCatalogModal.propTypes = {
  changeShowEditModal: PropTypes.func.isRequired,
  showEditModal: PropTypes.bool.isRequired,
  theme: ThemeProps.isRequired,
};


export default EditMasterCatalogModal;
