import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Row, Col, Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import CloseIcon from 'mdi-react/CloseIcon';
import PriceTier from './PriceTier';
import {
  meteredMonthlySpSdSpend, meteredMarketplaces, flatRate, // meteredProducts, meteredBudgetGroups,
} from '../../config';

const PriceTierModal = ({
  toggleModal, showModal,
}) => {
  const theme = useSelector(store => store.theme);
  const modalClasses = classNames({
    'todo__add-modal': true,
  }, theme.className, 'ltr-support');

  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      className={modalClasses}
    >
      <button className="panel__btn" type="button" onClick={() => toggleModal(false)}><CloseIcon /></button><h2>Montly Costs</h2>
      <br />
      <br />
      <Row>
        <Col md={12} lg={12} xl={12}>
          <PriceTier title="Flat Rate" tierData={flatRate} />
        </Col>
        <Col md={12} lg={12} xl={12}>
          <PriceTier title="Monthly SP & SD Spend" tierData={meteredMonthlySpSdSpend} />
        </Col>
        {/* <Col md={12} lg={12} xl={12}>
          <PriceTier title="Cost per Product" tierData={meteredProducts} />
        </Col>
        <Col md={12} lg={12} xl={12}>
          <PriceTier title="Cost per Budget Group" tierData={meteredBudgetGroups} />
        </Col> */}
        <Col md={12} lg={12} xl={12}>
          <PriceTier title="Monthly Marketplace Costs" tierData={meteredMarketplaces} />
        </Col>
      </Row>
    </Modal>
  );
};

PriceTierModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};


export default PriceTierModal;
