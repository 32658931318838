/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import FullWidthFillGraph from '../../../../shared/components/widgets/FullWidthFillGraph';
import PieBreakdown from '../../../../shared/components/widgets/PieBreakdown';
import DateRangePickerPanel from '../../../../shared/components/widgets/DateRangePickerPanel';
import LeftMiddleRightBox from '../../../../shared/components/widgets/LeftMiddleRightBox';
import {
  formatNumberDefaultToZero, formatFixedTwoPercent, currencyFormatter, numberTwoPlaces, currencyDisplay,
} from '../../../../shared/components/table/functions';
import config from '../../../../config';
import { SellerSelect, User } from '../../../../shared/prop-types/MainProps';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class SponsoredBrandStatsMainPage extends PureComponent {
  static propTypes = {
    sellerSelect: SellerSelect.isRequired,
    user: User.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: {
        total: {
          sb_14_day_ntb_units: 0,
          sb_14_day_total_units: 0,
          sb_14_day_ntb_orders: 0,
          sb_14_day_total_orders: 0,
          sb_14_day_ntb_sales: 0,
          sb_14_day_total_sales: 0,
        },
      },
      pieData: [],
      graphData: [],
      lineList: [],
      initalLoad: false,
      // graphLoad: false,
      reportStartDate: moment().subtract(11, 'days').format('YYYY-MM-DD'),
      reportEndDate: moment().subtract(4, 'days').format('YYYY-MM-DD'),
      // showingAll: false,
    //   pivotOn: 'ASIN',
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevState) {
    const { sellerSelect } = this.props;
    if (sellerSelect.value !== prevState.sellerSelect.value) {
      this.getData();
    }
  }

  onDateChange = (startDate, endDate) => {
    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).format('YYYY-MM-DD');
    this.setState({
      reportStartDate: start,
      reportEndDate: end,
    }, this.getData);
  }

  // toggleDisplay = () => {
  //   this.setState(prevState => ({
  //     showingAll: !prevState.showingAll,
  //   }));
  // }

  // pivotData = (data, pivotType) => {
  //   if (pivotType === 'ASIN') {
  //     const newData = [];
  //     data.forEach((row) => {
  //       let pacos = 0.00;
  //       let tacos = 0.00;
  //       let roas = 0.00;
  //       let shippedSales = 0;
  //       if (row.spend && row.sales && row.sales !== 0) {
  //         pacos = (row.spend / row.sales) * 100;
  //       }
  //       if (
  //         row.spend && row.sourcing_shipped_units && row.list_price && (row.sourcing_shipped_units * row.list_price) !== 0
  //       ) {
  //         tacos = (row.spend / (row.sourcing_shipped_units * row.list_price)) * 100;
  //       }
  //       if (row.spend && row.spend !== 0) {
  //         roas = ((row.sourcing_shipped_units * row.list_price) / row.spend);
  //       }
  //       if (row.sourcing_shipped_units && row.list_price) {
  //         shippedSales = (row.sourcing_shipped_units * row.list_price);
  //       }

  //       newData.push({
  //         ...row,
  //         pacos,
  //         tacos,
  //         roas,
  //         shippedSales,
  //       });
  //     });

  //     return newData;
  //   }
  //   const pivotData = data.reduce((list, row) => {
  //     const driverColumn = row[pivotType.toLowerCase()];
  //     if (driverColumn !== undefined) {
  //       const currentObj = list.get(driverColumn);
  //       if (currentObj) {
  //         currentObj.impressions += row.impressions;
  //         currentObj.clicks += row.clicks;
  //         currentObj.spend += row.spend;
  //         currentObj.sales += row.sales;
  //         currentObj.units += row.units;
  //         currentObj.sourcing_shipped_units += (row.sourcing_shipped_units || 0);
  //         currentObj.shippedSales += ((row.sourcing_shipped_units || 0) * row.list_price);
  //         list.set(driverColumn, currentObj);
  //       } else {
  //         const newObj = {
  //           parent_asin: driverColumn,
  //           brand: driverColumn,
  //           segment_one: driverColumn,
  //           segment_two: driverColumn,
  //           segment_three: driverColumn,
  //           segment_four: driverColumn,
  //           segment_five: driverColumn,
  //           category: driverColumn,
  //           subcategory: driverColumn,
  //           impressions: row.impressions,
  //           clicks: row.clicks,
  //           spend: row.spend,
  //           sales: row.sales,
  //           units: row.units,
  //           sourcing_shipped_units: (row.sourcing_shipped_units || 0),
  //           shippedSales: ((row.sourcing_shipped_units || 0) * row.list_price),
  //         };
  //         list.set(driverColumn, newObj);
  //       }
  //     }
  //     return list;
  //   }, new Map());

  //   const newData = [];
  //   Array.from(pivotData.values()).forEach((row) => {
  //     let pacos = 0.00;
  //     let tacos = 0.00;
  //     let roas = 0.00;
  //     if (row.spend && row.sales && row.sales !== 0) {
  //       pacos = (row.spend / row.sales) * 100;
  //     }
  //     if (row.spend && row.shippedSales && row.shippedSales !== 0
  //     ) {
  //       tacos = (row.spend / row.shippedSales) * 100;
  //     }
  //     if (row.shippedSales && row.spend && row.spend !== 0) {
  //       roas = (row.shippedSales / row.spend);
  //     }

  //     newData.push({
  //       ...row,
  //       pacos,
  //       tacos,
  //       roas,
  //     });
  //   });

  //   return newData;
  // }

  getData = () => {
    const { sellerSelect, user } = this.props;
    const { reportStartDate, reportEndDate } = this.state;
    const keywordPlacementUrl = `${apiUrl}/customers/sb-keywords?sellerAccountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}&startDate=${reportStartDate}&endDate=${reportEndDate}`;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    this.setState({
      initalLoad: true,
      // graphLoad: true,
    });

    fetch(keywordPlacementUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        const COLORS = ['#03c03c', '#36c9c9', '#fbb054', '#df7252', '#d04960', '#e85097'];
        const pieData = [{
          name: 'NTB Sales',
          sales: data.total.sb_14_day_ntb_sales,
          fill: COLORS[0],
        }, {
          name: 'Non NTB Sales',
          sales: data.total.sb_14_day_total_sales - data.total.sb_14_day_ntb_sales,
          fill: COLORS[1],
        }];

        this.setState({
          initalLoad: false,
          data,
          graphData: data.dateData,
          pieData,
          lineList: ['sb_14_day_total_sales', 'sb_14_day_ntb_sales'],
        //   graphLoad: true,
        });
      }).catch(() => {
        this.setState({
          initalLoad: false,
        });
      });
  };

  render() {
    const {
      reportStartDate, reportEndDate, graphData, initalLoad, lineList, pieData, data,
    } = this.state;

    return (
      <Container className="dashboard">
        <Col md={12}>
          <Row>
            <LeftMiddleRightBox
              content={{
                title: 'Return on Ad Spend (RoAS)',
                left_header: '',
                left_data: '',
                middle_header: '',
                middle_data: `${formatNumberDefaultToZero({ value: (data.total.sb_14_day_total_sales / data.total.sb_spend) })} : 1`,
                right_header: '',
                right_data: '',
              }}
              loadingData={initalLoad}
              lgWidth="4"
            />
            <LeftMiddleRightBox
              content={{
                title: 'Conversion Rate (CVR)',
                left_header: '',
                left_data: '',
                middle_header: '',
                middle_data: formatFixedTwoPercent({ value: ((data.total.sb_14_day_total_orders / data.total.sb_clicks) * 100) }),
                right_header: '',
                right_data: '',
              }}
              loadingData={initalLoad}
              lgWidth="4"
            />
            <LeftMiddleRightBox
              content={{
                title: 'Cost per Acquisition (CPA)',
                left_header: '',
                left_data: '',
                middle_header: '',
                middle_data: currencyFormatter({ value: ((data.total.sb_spend / data.total.sb_14_day_total_orders)) }),
                right_header: '',
                right_data: '',
              }}
              loadingData={initalLoad}
              lgWidth="4"
            />
          </Row>
        </Col>
        <Col md={12}>
          <Row>
            <LeftMiddleRightBox
              content={{
                title: 'Spend',
                left_header: '',
                left_data: '',
                middle_header: '',
                middle_data: currencyFormatter({ value: data.total.sb_spend }),
                right_header: '',
                right_data: '',
              }}
              loadingData={initalLoad}
              lgWidth="4"
            />
            <LeftMiddleRightBox
              content={{
                title: 'Sales',
                left_header: '',
                left_data: '',
                middle_header: '',
                middle_data: currencyFormatter({ value: data.total.sb_14_day_total_sales }),
                right_header: '',
                right_data: '',
              }}
              loadingData={initalLoad}
              lgWidth="4"
            />
            <LeftMiddleRightBox
              content={{
                title: 'ACoS',
                left_header: '',
                left_data: '',
                middle_header: '',
                middle_data: formatFixedTwoPercent({ value: ((data.total.sb_spend / data.total.sb_14_day_total_sales) * 100) }),
                right_header: '',
                right_data: '',
              }}
              loadingData={initalLoad}
              lgWidth="4"
            />
          </Row>
        </Col>
        <Col md={12}>
          <Row>
            <LeftMiddleRightBox
              content={{
                title: 'Cost Per Click (CPC)',
                left_header: '',
                left_data: '',
                middle_header: '',
                middle_data: currencyFormatter({ value: (data.total.sb_spend / data.total.sb_clicks) }),
                right_header: '',
                right_data: '',
              }}
              loadingData={initalLoad}
              lgWidth="4"
            />
            <LeftMiddleRightBox
              content={{
                title: 'Revenue Per Click (RPC)',
                left_header: '',
                left_data: '',
                middle_header: '',
                middle_data: currencyFormatter({ value: (data.total.sb_14_day_total_sales / data.total.sb_clicks) }),
                right_header: '',
                right_data: '',
              }}
              loadingData={initalLoad}
              lgWidth="4"
            />
            <LeftMiddleRightBox
              content={{
                title: 'Cost Per Mille (CPM)',
                left_header: '',
                left_data: '',
                middle_header: '',
                middle_data: currencyFormatter({ value: ((data.total.sb_spend / (data.total.sb_impressions / 1000))) }),
                right_header: '',
                right_data: '',
              }}
              loadingData={initalLoad}
              lgWidth="4"
            />
          </Row>
        </Col>
        <Col md={12}>
          <Row>
            <LeftMiddleRightBox
              content={{
                title: 'Revenue Per Impression (RPI)',
                left_header: '',
                left_data: '',
                middle_header: '',
                middle_data: currencyFormatter({ value: (data.total.sb_14_day_total_sales / data.total.sb_impressions) }),
                right_header: '',
                right_data: '',
              }}
              loadingData={initalLoad}
              lgWidth="4"
            />
            <LeftMiddleRightBox
              content={{
                title: '7D Sales Trend',
                left_header: '',
                left_data: '',
                middle_header: '',
                middle_data: currencyFormatter({ value: ((data.total.sb_14_day_total_sales / graphData.length) * 7) }),
                right_header: '',
                right_data: '',
              }}
              loadingData={initalLoad}
              lgWidth="4"
            />
            <LeftMiddleRightBox
              content={{
                title: '7D NTB Sales Trend',
                left_header: '',
                left_data: '',
                middle_header: '',
                middle_data: currencyFormatter({ value: ((data.total.sb_14_day_ntb_sales / graphData.length) * 7) }),
                right_header: '',
                right_data: '',
              }}
              loadingData={initalLoad}
              lgWidth="4"
            />
          </Row>
        </Col>
        <Col md={12}>
          <Row>
            <DateRangePickerPanel
              reportStartDate={reportStartDate}
              reportEndDate={reportEndDate}
              onDateChange={this.onDateChange}
            />
          </Row>
        </Col>
        <Col md={12}>
          <Row>
            <FullWidthFillGraph
              data={graphData}
              title="NBT and Total SB"
              initalLoad={initalLoad}
              lineList={lineList}
              dataKey="sb_date.value"
            />
          </Row>
        </Col>
        <Col md={12}>
          <Row>
            <PieBreakdown
              data={pieData}
              content={{
                title: 'Total Sales',
                subtitle: '',
                outerDataKey: 'sales',
              }}
              loadingData={initalLoad}
            />
            <Col md={6}>
              <LeftMiddleRightBox
                content={{
                  title: 'Units',
                  left_header: 'NTB',
                  left_data: formatNumberDefaultToZero({ value: data.total.sb_14_day_ntb_units }),
                  middle_header: 'Total',
                  middle_data: formatNumberDefaultToZero({ value: data.total.sb_14_day_total_units }),
                  right_header: 'NTB%',
                  right_data: `${formatNumberDefaultToZero({ value: ((data.total.sb_14_day_ntb_units / data.total.sb_14_day_total_units) * 100) })}%`,
                }}
                loadingData={initalLoad}
                lgWidth="12"
              />
              <LeftMiddleRightBox
                content={{
                  title: 'Orders',
                  left_header: 'NTB',
                  left_data: formatNumberDefaultToZero({ value: data.total.sb_14_day_ntb_orders }),
                  middle_header: 'Total',
                  middle_data: formatNumberDefaultToZero({ value: data.total.sb_14_day_total_orders }),
                  right_header: 'NTB%',
                  right_data: `${formatNumberDefaultToZero({ value: ((data.total.sb_14_day_ntb_orders / data.total.sb_14_day_total_orders) * 100) })}%`,
                }}
                loadingData={initalLoad}
                lgWidth="12"
              />
              <LeftMiddleRightBox
                content={{
                  title: 'New To Brand (NTB) sales ratio',
                  left_header: 'NTB',
                  left_data: `${currencyDisplay()}${formatNumberDefaultToZero({ value: data.total.sb_14_day_ntb_sales })}`,
                  middle_header: 'Total',
                  middle_data: `${currencyDisplay()}${formatNumberDefaultToZero({ value: data.total.sb_14_day_total_sales })}`,
                  right_header: 'NTB%',
                  right_data: `${formatNumberDefaultToZero({ value: ((data.total.sb_14_day_ntb_sales / data.total.sb_14_day_total_sales) * 100) })}%`,
                }}
                loadingData={initalLoad}
                lgWidth="12"
              />
            </Col>
          </Row>
        </Col>
        <Col md={12}>
          <Row>
            <LeftMiddleRightBox
              content={{
                title: 'Detail Pages Views per Click (DPVpC)',
                left_header: 'Detail Page Views',
                left_data: formatNumberDefaultToZero({ value: data.total.sb_14_day_brand_total_detail_page_views }),
                middle_header: 'Clicks',
                middle_data: formatNumberDefaultToZero({ value: data.total.sb_clicks }),
                right_header: 'DPVpC',
                right_data: `${numberTwoPlaces({ value: (data.total.sb_14_day_brand_total_detail_page_views / data.total.sb_clicks) })}`,
              }}
              loadingData={initalLoad}
              lgWidth="6"
            />
            <LeftMiddleRightBox
              content={{
                title: 'Brand Awareness and Engagement (CTR)',
                left_header: 'Impressions',
                left_data: formatNumberDefaultToZero({ value: data.total.sb_impressions }),
                middle_header: 'Clicks',
                middle_data: formatNumberDefaultToZero({ value: data.total.sb_clicks }),
                right_header: 'CTR',
                right_data: `${formatFixedTwoPercent({ value: ((data.total.sb_clicks / data.total.sb_impressions) * 100) })}`,
              }}
              loadingData={initalLoad}
              lgWidth="6"
            />
          </Row>
        </Col>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { sellerSelect } = state;
  const { user } = state.authentication;

  return {
    sellerSelect,
    user,
  };
};

export default connect(mapStateToProps)(SponsoredBrandStatsMainPage);
