import {
  useState, useEffect,
} from 'react';
import {
  when,
} from '../../../../../shared/functions';
import useAccountApiStatus from '../../../../../shared/components/hooks/useAccountApiStatus';

const PerformanceType = {
  BEST: 'best',
  WORST: 'worst',
  * [Symbol.iterator]() {
    yield PerformanceType.BEST;
    yield PerformanceType.WORST;
  },
};

export default function useData(
  accountSelect,
) {
  const [apiStatusLoading, apiStatusResults, apiStatusError] = useAccountApiStatus(accountSelect);
  const loading = apiStatusLoading;
  const [results, setResults] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    setResults({
      ...apiStatusResults,
    });

    return (() => setResults({
      ...apiStatusResults,
    }));
  }, [apiStatusResults, setResults]);

  useEffect(() => {
    when(apiStatusError, () => {
      setError(apiStatusError);
    });
  }, [apiStatusError, setError]);

  return [loading, results, error];
}
