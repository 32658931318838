import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import StatusPage from './components/StatusPage';


const Status = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">System Status</h3>
      </Col>
    </Row>
    <Row>
      <br />
    </Row>
    <StatusPage />
    <Row>
      <br />
    </Row>
  </Container>
);

export default Status;
