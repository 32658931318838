/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import classNames from 'classnames';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import ChevronUpIcon from 'mdi-react/ChevronUpIcon';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import { FiCameraOff } from 'react-icons/fi';
import { NewOrderTableProps } from '../../../../../../shared/prop-types/TablesProps';
import Panel from '../../../../../../shared/components/Panel';
import {
  formatFixedTwoPercent, currencyFormatter, formatNumberDefaultToZero,
} from '../../../../../../shared/components/table/functions';

const BestsellersBars = ({ quantity }) => {
  const amountClass = classNames({
    'dashboard__table-bestsellers': true,
    'dashboard__table-bestsellers--high': quantity > 100,
    'dashboard__table-bestsellers--medium': quantity <= 100,
    'dashboard__table-bestsellers--low': quantity <= 20,
  });
  if (quantity > 150) {
    return (
      <div className={amountClass}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <span>{formatNumberDefaultToZero({ value: quantity })}</span>
      </div>
    );
  } if (quantity > 100) {
    return (
      <div className={amountClass}>
        <div />
        <div />
        <div />
        <div />
        <span>{formatNumberDefaultToZero({ value: quantity })}</span>
      </div>
    );
  } if (quantity > 50) {
    return (
      <div className={amountClass}>
        <div />
        <div />
        <div />
        <span>{formatNumberDefaultToZero({ value: quantity })}</span>
      </div>
    );
  } if (quantity > 20) {
    return (
      <div className={amountClass}>
        <div />
        <div />
        <span>{formatNumberDefaultToZero({ value: quantity })}</span>
      </div>
    );
  }
  return (
    <div className={amountClass}>
      <div />
      <span>{formatNumberDefaultToZero({ value: quantity })}</span>
    </div>
  );
};

BestsellersBars.propTypes = {
  quantity: PropTypes.number,
};

BestsellersBars.defaultProps = {
  quantity: 0,
};

const PpcBestsellers = ({
  newOrder, title, subhead, initalLoad, showingAll, toggleDisplay, pivotOn, handleCheckboxChange, checkedItem, graphLoad,
}) => {
  const iconStyle = { width: 40, height: 40 };
  return (
    <Panel
      lg={12}
      md={12}
      title={title}
      subhead={subhead}
      parentRefresh={initalLoad}
    >
      <Table responsive striped className="dashboard__table-orders" id="top10">
        <thead>
          <tr>
            <th>GRAPH</th>
            {(pivotOn === 'ASIN') && (
              <th>Image</th>
            )}
            {(pivotOn === 'ASIN') && (
              <th>ASIN</th>
            )}
            {!(pivotOn === 'ASIN') && (
              <th>{pivotOn.split('_').join(' ')}</th>
            )}
            {(pivotOn === 'ASIN') && (
              <th>SKU</th>
            )}
            {(pivotOn === 'ASIN') && (
              <th>Product Title</th>
            )}
            <th>Impressions</th>
            <th>Clicks</th>
            <th>PPC Spend</th>
            <th>PPC Sales</th>
            <th>PPC Units</th>
            <th>Shipped Units</th>
            <th>Shipped COGS</th>
            {/* <th>Shipped Sales</th> */}
            <th>PACoS</th>
            {/* <th>TACoS</th> */}
            <th>ROAS</th>
          </tr>
        </thead>
        <tbody>
          {newOrder.map((order, index) => (
            <tr key={index} style={index > 9 ? { display: (showingAll ? '' : 'none') } : {}}>
              <td>
                <input
                  id={order[pivotOn.toLowerCase()]}
                  // className="checkbox-btn__checkbox"
                  type="checkbox"
                  checked={checkedItem.has([order[pivotOn.toLowerCase()]].toString()) ? 'checked' : ''}
                  onChange={handleCheckboxChange}
                  disabled={graphLoad}
                />
              </td>
              {(pivotOn === 'ASIN') && order.smallImageUrl && (
                <td>
                  <LazyLoad>
                    <img src={order.smallImageUrl} alt={order.asin} />
                  </LazyLoad>
                </td>
              )}
              {(pivotOn === 'ASIN') && !order.smallImageUrl && (
                <td>
                  <FiCameraOff className="ag-no-image-icon" style={iconStyle} alt="Image not available" />
                </td>
              )}
              <td>{order[pivotOn.toLowerCase()]}</td>
              {(pivotOn === 'ASIN') && (
                <td>{order.sku}</td>
              )}
              {(pivotOn === 'ASIN') && (
                <td>{order.product_title}</td>
              )}
              <td>
                <BestsellersBars quantity={order.impressions} />
              </td>
              <td>
                <BestsellersBars quantity={order.clicks} />
              </td>
              <td className="dashboard__table-orders-total">{currencyFormatter({ value: order.spend })}</td>
              <td className="dashboard__table-orders-total">{currencyFormatter({ value: order.sales })}</td>
              <td>
                <BestsellersBars quantity={order.units} />
              </td>
              <td>
                <BestsellersBars quantity={order.sourcing_shipped_units} />
              </td>
              <td className="dashboard__table-orders-total">{currencyFormatter({ value: order.sourcing_shipped_cogs })}</td>
              {/* <td className="dashboard__table-orders-total">{currencyFormatter({ value: order.shippedSales })}</td> */}
              <td>{formatFixedTwoPercent({ value: order.pacos })}</td>
              {/* <td>{formatFixedTwoPercent({ value: order.tacos })}</td> */}
              <td>{currencyFormatter({ value: order.roas })}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <br />
      <Link to="/vendor/ppc-bestsellers" onClick={toggleDisplay} className="dashboard__table-orders-link">{ showingAll ? (<div><span>Show Less</span><ChevronUpIcon /></div>) : (<div><span>Show All</span><ChevronDownIcon /></div>)}</Link>
    </Panel>
  );
};

PpcBestsellers.propTypes = {
  newOrder: NewOrderTableProps.isRequired,
  title: PropTypes.string.isRequired,
  subhead: PropTypes.string.isRequired,
  initalLoad: PropTypes.bool.isRequired,
  showingAll: PropTypes.bool.isRequired,
  toggleDisplay: PropTypes.func.isRequired,
  pivotOn: PropTypes.string.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  // eslint-disable-next-line
  checkedItem: PropTypes.object.isRequired,
  graphLoad: PropTypes.bool.isRequired,
};

export default PpcBestsellers;
