/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import SelectBoxDynamic from '../../../../shared/components/widgets/SelectBoxDynamic';
import TableBuilder from '../../../../shared/components/table/TableBuilder';
import DeltaCompare from '../../../../shared/components/widgets/DeltaCompare';
import config from '../../../../config';
import {
  formatDecimalToFixedTwoPercent, formatFixedTwoPercent, formatNumberDefaultToZero, currencyFormatter, currencyFormatterWhole, getExportPrefix,
} from '../../../../shared/components/table/functions';
import { SellerSelect, User, Theme } from '../../../../shared/prop-types/MainProps';
import { imageFieldColumn, imageCellRendererComponent } from '../../../../shared/components/table/ImageCellRenderer';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class MonthOverMonthPpcReportPage extends PureComponent {
  static propTypes = {
    sellerSelect: SellerSelect.isRequired,
    user: User.isRequired,
    theme: Theme.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loadingData: false,
      loadingDataCompare: false,
      selectedOptions: [],
      startSelectedOption: {},
      endSelectedOption: {},
      dataTotals: {},
      trendSelectedOptions: [{ label: 'Week Over Week', value: 'week-over-week-aviability', title: 'Week' }, { label: 'Month Over Month', value: 'month-over-month-aviability', title: 'Month' }],
      trendSelectedOption: { label: 'Week Over Week', value: 'week-over-week-aviability', title: 'Week' },
      columnDefs: [
        imageFieldColumn,
        {
          field: 'asin',
          headerName: 'ASIN',
          chartDataType: 'category',
        },
        {
          field: 'sku',
          headerName: 'SKU',
          chartDataType: 'category',
        },
        {
          field: 'product_title',
          headerName: 'Product Title',
          chartDataType: 'category',
        },
        {
          headerName: 'Previous Week',
          children: [
            {
              field: 'first_spend',
              headerName: 'Spend',
              valueFormatter: currencyFormatter,
            },
            {
              field: 'first_sales',
              headerName: 'PPC Sales',
              valueFormatter: currencyFormatter,
            },
            {
              field: 'first_acos',
              headerName: 'PPC ACoS',
              valueFormatter: formatFixedTwoPercent,
            },
            {
              field: 'first_shipped_units',
              headerName: 'Shipped Units',
              valueFormatter: formatNumberDefaultToZero,
            },
          ],
        },
        {
          headerName: 'Latest Week',
          children: [
            {
              field: 'second_spend',
              headerName: 'Spend',
              valueFormatter: currencyFormatter,
            },
            {
              field: 'second_sales',
              headerName: 'PPC Sales',
              valueFormatter: currencyFormatter,
            },
            {
              field: 'second_acos',
              headerName: 'PPC ACoS',
              valueFormatter: formatFixedTwoPercent,
            },
            {
              field: 'second_shipped_units',
              headerName: 'Shipped Units',
              valueFormatter: formatNumberDefaultToZero,
            },
          ],
        },
        {
          headerName: 'Delta',
          children: [
            {
              field: 'delta_spend',
              headerName: 'Spend Diff',
              valueFormatter: formatDecimalToFixedTwoPercent,
            },
            {
              field: 'delta_sales',
              headerName: 'Sales Diff',
              valueFormatter: formatDecimalToFixedTwoPercent,
            },
            {
              field: 'delta_acos',
              headerName: 'ACoS Diff',
              valueFormatter: formatDecimalToFixedTwoPercent,
            },
            {
              field: 'delta_shipped_units',
              headerName: 'Shipped Units Diff',
              valueFormatter: formatDecimalToFixedTwoPercent,
            },
          ],
        },
      ],
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        cellStyle: (params) => {
          if (params.value < 0.00) {
            return { color: 'red' };
          }
          return null;
        },
      },
      frameworkComponents: {
        ...imageCellRendererComponent,
      },
      parentControlledLoad: true,
      apiEndPoint: `${apiUrl}/vendors/ppc-date-over-date-data`,
      apiQueryParams: null,
      pdOptions: {
        showButton: true,
        columnName: 'asin',
      },
    };
  }

  componentDidMount() {
    this.getDropdownData();
  }

  componentDidUpdate(prevState) {
    const { sellerSelect } = this.props;
    if (sellerSelect.value !== prevState.sellerSelect.value) {
      this.getDropdownData();
    }
  }

  handleStartChange = (value) => {
    this.setState({
      startSelectedOption: value,
      loadingDataCompare: true,
    }, () => this.refreshData());
  }

  handleEndChange = (value) => {
    this.setState({
      endSelectedOption: value,
      loadingDataCompare: true,
    }, () => this.refreshData());
  }

  handleTrendChange = (value) => {
    const { columnDefs } = this.state;
    const newColumnDefs = [...columnDefs];
    const firstHeaderName = {
      ...newColumnDefs[3],
      headerName: `First ${value.title}`,
    };
    newColumnDefs[3] = firstHeaderName;
    const secondHeaderName = {
      ...newColumnDefs[4],
      headerName: `Second ${value.title}`,
    };
    newColumnDefs[4] = secondHeaderName;
    this.setState({
      columnDefs: newColumnDefs,
      trendSelectedOption: value,
      loadingDataCompare: true,
    }, () => this.getDropdownData());
  }

  refreshData = () => {
    const {
      startSelectedOption, endSelectedOption,
    } = this.state;
    const { sellerSelect } = this.props;
    const apiQueryParams = `sellerAccountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}&firstStartDate=${startSelectedOption.value.startDate}&firstEndDate=${startSelectedOption.value.endDate}&secondStartDate=${endSelectedOption.value.startDate}&secondEndDate=${endSelectedOption.value.endDate}`;

    this.setState({
      apiQueryParams,
      dataTotals: {},
    });
  }

  parentSetData = (data) => {
    this.setState({
      dataTotals: data.totals,
      loadingDataCompare: false,
    });
  }

  getDropdownData = () => {
    // dateRange-over-dateRange-aviability
    const { trendSelectedOption } = this.state;
    const { sellerSelect, user } = this.props;
    const vendorAccountId = (sellerSelect.parentAccountId || '0') !== '0' ? sellerSelect.parentAccountId : sellerSelect.value;
    const dateRangeOverDateRangeUrl = `${apiUrl}/big-query/${trendSelectedOption.value}?sellerAccountId=${vendorAccountId}&parentAccountId=${sellerSelect.parentAccountId}&datasetId=vendor_sales_diagnostic&tableType=_S_`;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    this.setState({
      loadingData: true,
      loadingDataCompare: true,
      selectedOptions: [],
      startSelectedOption: {},
      endSelectedOption: {},
      dataTotals: {},
    });

    fetch(dateRangeOverDateRangeUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        const selectedOptions = [];
        if (data.length === 0) {
          selectedOptions.push({ label: '... data not available', value: {} });
        } else {
          data.forEach((row) => {
            if (trendSelectedOption.title === 'Week') {
              selectedOptions.unshift({ label: `Week ${row.week}: ${moment(row.dates[0]).format('YYYY-MM-DD')} - ${moment(row.dates[6]).format('YYYY-MM-DD')}`, value: { startDate: row.dates[0], endDate: row.dates[6] } });
            } else {
              selectedOptions.unshift({ label: `${moment().month(row.month).format('MMMM')}: ${moment(row.dates[0]).format('YYYY-MM-DD')} - ${moment(row.dates[row.dates.length - 1]).format('YYYY-MM-DD')}`, value: { startDate: row.dates[0], endDate: row.dates[row.dates.length - 1] } });
            }
          });
        }
        let compareOption = 0;
        if (selectedOptions.length > 1) {
          compareOption = 1;
        }

        this.setState({
          loadingData: false,
          selectedOptions,
          startSelectedOption: selectedOptions[compareOption],
          endSelectedOption: selectedOptions[0],
        }, () => this.refreshData());
      }).catch(() => {
        this.setState({
          loadingData: false,
        });
      });
  }

  render() {
    const {
      loadingData, selectedOptions, startSelectedOption, endSelectedOption, columnDefs, defaultColDef, apiEndPoint, apiQueryParams, parentControlledLoad, dataTotals, loadingDataCompare,
      trendSelectedOptions, trendSelectedOption, pdOptions, frameworkComponents,
    } = this.state;
    const { theme, sellerSelect } = this.props;

    const range = trendSelectedOption.title.toLowerCase();
    const exportPrefix = getExportPrefix(`amz-ppc-${range}-over-${range}`, sellerSelect);

    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">{`${trendSelectedOption.title} over ${trendSelectedOption.title} PPC Report`}</h3>
          </Col>
        </Row>
        <Row>
          <SelectBoxDynamic
            pageTitle="Trend"
            loadingData={loadingData}
            mdWidth={4}
            selectOptions={trendSelectedOptions}
            selectedOption={trendSelectedOption}
            handleSelectChange={this.handleTrendChange}
            theme={theme}
          />
          <SelectBoxDynamic
            pageTitle={`Previous ${trendSelectedOption.title}`}
            loadingData={loadingData}
            mdWidth={4}
            selectOptions={selectedOptions}
            selectedOption={startSelectedOption}
            handleSelectChange={this.handleStartChange}
            theme={theme}
          />
          <SelectBoxDynamic
            pageTitle={`Latest ${trendSelectedOption.title}`}
            loadingData={loadingData}
            mdWidth={4}
            selectOptions={selectedOptions}
            selectedOption={endSelectedOption}
            handleSelectChange={this.handleEndChange}
            theme={theme}
          />
        </Row>
        <Row>
          <DeltaCompare
            content={{
              title: 'PPC Spend',
              left_header: startSelectedOption.label,
              left_data: currencyFormatterWhole({ value: dataTotals.first_spend }),
              middle_header: 'Δ',
              middle_data: dataTotals.delta_spend,
              right_header: endSelectedOption.label,
              right_data: currencyFormatterWhole({ value: dataTotals.second_spend }),
            }}
            lgWidth="6"
            loadingData={loadingDataCompare}
          />
          <DeltaCompare
            content={{
              title: 'PPC Sales',
              left_header: startSelectedOption.label,
              left_data: currencyFormatterWhole({ value: dataTotals.first_sales }),
              middle_header: 'Δ',
              middle_data: dataTotals.delta_sales,
              right_header: endSelectedOption.label,
              right_data: currencyFormatterWhole({ value: dataTotals.second_sales }),
            }}
            lgWidth="6"
            loadingData={loadingDataCompare}
          />
          <DeltaCompare
            content={{
              title: 'PPC ACoS',
              left_header: startSelectedOption.label,
              left_data: formatDecimalToFixedTwoPercent({ value: dataTotals.first_acos }),
              middle_header: 'Δ',
              middle_data: Math.floor(dataTotals.second_acos * 100) - Math.floor(dataTotals.first_acos * 100),
              right_header: endSelectedOption.label,
              right_data: formatDecimalToFixedTwoPercent({ value: dataTotals.second_acos }),
            }}
            lgWidth="6"
            loadingData={loadingDataCompare}
          />
          <DeltaCompare
            content={{
              title: 'Total Shipped COGs',
              left_header: startSelectedOption.label,
              left_data: currencyFormatterWhole({ value: dataTotals.first_shipped_cogs }),
              middle_header: 'Δ',
              middle_data: dataTotals.delta_shipped_cogs,
              right_header: endSelectedOption.label,
              right_data: currencyFormatterWhole({ value: dataTotals.second_shipped_cogs }),
            }}
            lgWidth="6"
            loadingData={loadingDataCompare}
          />
        </Row>
        <Row>
          <Col md={12}>
            <Row>
              <TableBuilder
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                apiEndPoint={apiEndPoint}
                apiQueryParams={apiQueryParams}
                parentControlledLoad={parentControlledLoad}
                parentSetData={this.parentSetData}
                productDetailOptions={pdOptions}
                frameworkComponents={frameworkComponents}
                rowHeight={75}
                exportPrefix={exportPrefix}
                autoSizeAll
              />
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { theme, sellerSelect } = state;
  const { user } = state.authentication;

  return {
    theme,
    sellerSelect,
    user,
  };
};

export default connect(mapStateToProps)(MonthOverMonthPpcReportPage);
