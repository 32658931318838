import React from 'react';
import AccountStatusAll from './components/AccountStatusAll';
import Panel from '../../../../shared/components/Panel';

export default function AccountStatusAllPage() {
  return (
    <>
      <Panel lg={12} title="Pulling all &quot;ACTIVE&quot; accounts." showButtons={false}>
        <h3 className="page-subhead subhead">
          &nbsp;&nbsp;<span className="status__label-color status__label-color--green" />=Configured and successful test.
          &nbsp;&nbsp;<span className="status__label-color status__label-color--blue" />=Configured but not tested.
          &nbsp;&nbsp;<span className="status__label-color status__label-color--yellow" />=Configured with failed test.
          &nbsp;&nbsp;<span className="status__label-color status__label-color--gray" />=Prerequisite needed before configuration.
          &nbsp;&nbsp;<span className="status__label-color status__label-color--red" />=Setup/Update required.
        </h3>
      </Panel>
      <Panel lg={12} title="Account API Status" showButtons={false}>
        <AccountStatusAll />
      </Panel>
    </>
  );
}
