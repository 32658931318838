import React, { useState } from 'react';
import { Input } from 'reactstrap';
import { useSelector } from 'react-redux';
// import WebIcon from 'mdi-react/WebIcon';
import StoreIcon from 'mdi-react/StoreIcon';
import AtIcon from 'mdi-react/AtIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import Recaptcha from 'reaptcha';
import { serverUrl, jsonHeader } from '../../../../config';
import Alert from '../../../../shared/components/Alert';
import LoadingSpinner from '../../../../shared/components/widgets/LoadingSpinner';

const WalmartOnboardForm = ({ setProcessingRef, debug, adProgram }) => {
  const { loggedIn } = useSelector(store => store.authentication);
  const { loading } = useSelector(store => store.users);
  const user = useSelector(store => store.authentication.user);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState('');
  const [recaptchaResponse, setRecaptchaResponse] = useState('');
  const [recaptchaIsLoaded, setRecaptchaIsLoaded] = useState(false);
  const [advertiserId, setAdvertiserId] = useState(null);
  const [localAlert, setLocalAlert] = useState({});
  const [onboardSuccess, setOnboardSuccsss] = useState(false);

  const defaultForm = false;

  const submitWalmartOnboardForm = () => {
    setSubmitted(true);
    setProcessingRef({});
    setLocalAlert({});
    if (!recaptchaResponse || !firstName || !lastName || !email || !advertiserId) {
      setLocalAlert({ color: 'warning', message: 'Please fix the input issues below.' });
    } else {
      const headerWithAuth = { ...jsonHeader, authorization: `JWT ${user?.jwtToken}` };
      // Add logic to check input values, make sure they are present and correct.
      const onboardOptions = {
        method: 'POST',
        headers: headerWithAuth,
        body: JSON.stringify({
          firstName,
          lastName,
          email,
          advertiserId,
          recaptcha: recaptchaResponse,
          debug,
        }),
      };

      const onboardUrl = `${serverUrl}/walmart/onboard/freemium`;
      fetch(onboardUrl, onboardOptions)
        .then(async (results) => {
          if (!results.ok) {
            const text = await results.text();
            throw new Error(text);
          }
          return results.json();
        }).then((data) => {
          setProcessingRef(data);
          setOnboardSuccsss(true);
        }).catch((error) => {
          setProcessingRef({ errorMsg: error.toString() });
          setLocalAlert({ color: 'danger', message: 'We are having issues processing your request, please check your data and try again.' });
          setSubmitted(false);
        });
    }
  }

  return (
    <>
      {!submitted && (
        <>
          <div className="form__form-group">
            {localAlert && localAlert.message && (
              <>
                <Alert color={localAlert.color}>{localAlert.message}</Alert>
              </>
            )}
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">First Name</span>
            <div className="form__form-group-field">
              <div className="form__input-group-icon">
                <AccountOutlineIcon />
              </div>
              {!defaultForm && (
                <Input
                  autoFocus
                  name="firstName"
                  component="input"
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              )}
              {defaultForm && (
                <p>&nbsp;&nbsp;{firstName}</p>
              )}
            </div>
            {submitted && !firstName && (
              <div className="form__form-group-error">First name is required</div>
            )}
          </div>
          <br />
          <div className="form__form-group">
            <span className="form__form-group-label">Last Name</span>
            <div className="form__form-group-field">
              <div className="form__input-group-icon">
                <AccountOutlineIcon />
              </div>
              {!defaultForm && (
                <Input
                  name="lastName"
                  component="input"
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              )}
              {defaultForm && (
                <p>&nbsp;&nbsp;{lastName}</p>
              )}
            </div>
            {submitted && !lastName && (
              <div className="form__form-group-error">Last name is required</div>
            )}
          </div>
          <br />
          <div className="form__form-group">
            <span className="form__form-group-label">Email</span>
            <div className="form__form-group-field">
              <div className="form__input-group-icon">
                <AtIcon />
              </div>
              <Input
                name="email"
                component="input"
                type="email"
                placeholder="example@mail.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {submitted && !email && (
              <div className="form__form-group-error">Email is required</div>
            )}
          </div>
          <br />
          <div className="form__form-group">
            <span className="form__form-group-label">Walmart Advertiser ID</span>
            <div className="form__form-group-field">
              <div className="form__input-group-icon">
                <StoreIcon />
              </div>
              <Input
                name="advertiserId"
                component="input"
                type="text"
                value={advertiserId}
                onChange={(e) => setAdvertiserId(e.target.value)}
              />
            </div>
            {submitted && !advertiserId && (
              <div className="form__form-group-error">Advertiser ID is required</div>
            )}
          </div>
          {!loggedIn && (
            <div className="form__form-group">
              <Recaptcha sitekey="6LdYQuAbAAAAAD-jRnBlWEzdtu7poJfrLQ72h_mZ" onVerify={(response) => setRecaptchaResponse(response)} onload={() => setRecaptchaIsLoaded(true)} />
            </div>
          )}
          {!loggedIn && submitted && !recaptchaResponse && (
            <div className="form__form-group-error">Confirm you are not a robot</div>
          )}
          <LoadingSpinner
            active={loading}
          />
          <br />
          <br />
          <br />
          <div className="form__form-group">
            <button className="btn btn-primary account__btn" type="submit" disabled={recaptchaIsLoaded} onClick={() => submitWalmartOnboardForm()}>Submit and start Walmart{adProgram === 'FREE' ? ' Free' : ''} Ads</button>
          </div>
        </>
      )}
      {submitted && (
        <div className="form__form-group">
          {!onboardSuccess && (
            <div>
              <LoadingSpinner
                active={submitted}
              />
              <br />
              <br />
              <h1>Processing....</h1>
            </div>
          )}
          {onboardSuccess && (
            <div>
              <h1>Success</h1>
              <br />
              <h2>Watch for an email with your login information.  It can take up to 24 hours for data to start coming in.</h2>
            </div>
          )}
        </div>
      )}
    </>
  );
}


export default WalmartOnboardForm;
