import React from 'react';

export default function TableHeaders() {
  return (
    <thead>
      <tr>
        <th>#</th>
        <th>Setting Key</th>
        <th>Setting Value</th>
        <th>Access Control</th>
        <th>Actions</th>
      </tr>
    </thead>
  );
}
