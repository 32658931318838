import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import CustomerSellerSelect from './CustomerSellerSelect';
import { SellerSelect } from '../../../shared/prop-types/MainProps';
import { SiteImage } from '../../../shared/components/domainSupport';

class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
    sellerSelect: SellerSelect.isRequired,
  };

  render() {
    const {
      changeMobileSidebarVisibility, changeSidebarVisibility, sellerSelect,
    } = this.props;

    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />
            <SiteImage logoWidth="150" logoHeight="32" link="/" />
          </div>
          {sellerSelect.value === 274 && (
            <div className="topbar__center">
              <Link className="topbar__logo" to="/" />
            </div>
          )}
          <div className="topbar__right">
            <CustomerSellerSelect />
            <TopbarProfile />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { sellerSelect } = state;

  return {
    sellerSelect,
  };
};

export default connect(mapStateToProps)(Topbar);
