import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FilterSelect from './NewFilterSelect';

export const BccOptions = {
  ALL: {
    label: 'ALL',
    value: '',
  },
  BRAND: {
    label: 'Brand',
    value: 'Brand',
  },
  CATEGORY: {
    label: 'Category',
    value: 'Category',
  },
  COMPETITOR: {
    label: 'Competitor',
    value: 'Competitor',
  },
  * [Symbol.iterator]() {
    yield BccOptions.ALL;
    yield BccOptions.BRAND;
    yield BccOptions.CATEGORY;
    yield BccOptions.COMPETITOR;
  },
};

export default function BccFilter({
  initialValue, onChange, disable,
}) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (value) {
      onChange(value);
    }
  }, [value]);

  return (
    <FilterSelect
      label="BCC Filter:"
      onChange={option => setValue(option)}
      name="bccOption"
      options={[...BccOptions]}
      selected={value}
      disable={disable}
    />
  );
}

BccFilter.defaultProps = {
  initialValue: BccOptions.ALL,
  disable: false,
};

BccFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialValue: PropTypes.oneOf([...BccOptions]),
  disable: PropTypes.bool,
};
