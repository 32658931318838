import { useFetchReturnsArray as useFetch } from '../../../../shared/components/hooks/useFetch';
import config from '../../../../config';

function formatUrl(account, start, end, metric, limit) {
  if (account && account.value && start && end && metric) { // Default limit is 5
    const url = `${config.serverUrl}/walmart/query/item-top-performance-by-dates?accountId=${account.value}&parentAccountId=${account.parentAccountId}&startDate=${start.format('YYYYMMDD')}&endDate=${end.format('YYYYMMDD')}&metric=${metric}&limit=${limit}`;
    return url;
  }
  return undefined;
}

export default function useItemTopPerformanceByDates(account, start, end, metric, limit) {
  return useFetch(formatUrl(account, start, end, metric, limit));
}
