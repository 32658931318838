import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {
  Col, Card, CardBody,
} from 'reactstrap';
import { Theme } from '../../prop-types/MainProps';
import style, { DROPDOWN_COLORS } from '../themeSupport';

const SelectBoxDynamic = ({
  pageTitle, loadingData, mdWidth, selectOptions, selectedOption, handleSelectChange, theme,
}) => {
  const themeColors = style(theme, DROPDOWN_COLORS);

  return (
    <Col md={mdWidth}>
      <Card>
        <CardBody className="panel__body">
          {(loadingData) ? <div className="panel__refresh" /> : ''}
          <h3 className="page-title">{pageTitle}</h3>
          <div className="topbar__dynamicDropdownWidth">
            <Select
              value={selectedOption}
              onChange={handleSelectChange}
              options={selectOptions}
              theme={selectTheme => ({
                ...selectTheme,
                colors: {
                  ...selectTheme.colors,
                  primary: `${themeColors.colorPrimary}`,
                  neutral0: `${themeColors.colorBackground}`,
                  neutral80: `${themeColors.colorText}`,
                },
              })}
            />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

SelectBoxDynamic.defaultProps = {
  pageTitle: '',
  mdWidth: 3,
};

SelectBoxDynamic.propTypes = {
  pageTitle: PropTypes.string,
  loadingData: PropTypes.bool.isRequired,
  mdWidth: PropTypes.number,
  // eslint-disable-next-line
  selectOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line
  selectedOption: PropTypes.object.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  theme: Theme.isRequired,
};

export default SelectBoxDynamic;
