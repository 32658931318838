import config from './config';

const configObj = {
  isProdEnv: config.isProdEnv,
  agGridKey: config.agGridKey,
  jsonHeader: config.jsonHeader,
  gzipHeader: config.gzipHeader,
};

if (config.isProdEnv) {
  configObj.serverUrl = config.serverProdUrl;
  configObj.uploadUrl = config.uploadProdUrl;
} else {
  configObj.serverUrl = config.serverDevUrl;
  configObj.uploadUrl = config.uploadDevUrl;
}

export default configObj;
