/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import DateRangePickerPanel from '../../../../../shared/components/widgets/DateRangePickerPanel';
// import config from '../../../config';
import { SellerSelect } from '../../../../../shared/prop-types/MainProps';


// const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class PpcBestsellersPage extends PureComponent {
  static propTypes = {
    sellerSelect: SellerSelect.isRequired,
    // user: User.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      // initalLoad: false,
      // funnelLoad: false,
      reportStartDate: moment().subtract(11, 'days').format('YYYY-MM-DD'),
      reportEndDate: moment().subtract(4, 'days').format('YYYY-MM-DD'),
      // showingAll: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevState) {
    const { sellerSelect } = this.props;
    if (sellerSelect.value !== prevState.sellerSelect.value) {
      this.getData();
    }
  }

  onDateChange = (startDate, endDate) => {
    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).format('YYYY-MM-DD');
    this.setState({
      reportStartDate: start,
      reportEndDate: end,
    }, this.getData);
  }

  // toggleDisplay = () => {
  //   this.setState(prevState => ({
  //     showingAll: !prevState.showingAll,
  //   }));
  // }

  getData = () => {
    // const { sellerSelect, user } = this.props;
    // const { reportStartDate, reportEndDate, pivotOn } = this.state;
    // const bestsellersUrl = `${apiUrl}/vendors/ppc-stats?sellerAccountId=${sellerSelect.value}&startDate=${reportStartDate}&endDate=${reportEndDate}`;

    // const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    // const requestOptions = {
    //   method: 'GET',
    //   headers: headerWithAuth,
    // };

    // this.setState({
    //   initalLoad: true,
    //   funnelLoad: true,
    // });

    // fetch(bestsellersUrl, requestOptions)
    //   .then((results) => {
    //     if (!results.ok) {
    //       throw Error(results.statusText);
    //     }
    //     return results.json();
    //   }).then((data) => {
    //     const pivotedData = this.pivotData(data, pivotOn);

    //     const sortData = pivotedData.sort((a, b) => {
    //       const checkPos = (b.sales < a.sales) ? -1 : 0;
    //       return (b.sales > a.sales) ? 1 : checkPos;
    //     });

    //     this.setState({
    //       initalLoad: false,
    //       data: sortData,
    //     });

    //     let lineCount = 5;
    //     if (sortData.length < 5) {
    //       lineCount = sortData.length;
    //     }

    //     const lineList = [];
    //     for (let i = 0; i < lineCount; i += 1) {
    //       if (sortData[i][pivotOn.toLowerCase()]) {
    //         lineList.push(sortData[i][pivotOn.toLowerCase()]);
    //       } else {
    //         lineList.push('');
    //       }
    //     }
    //   }).catch(() => {
    //     this.setState({
    //       initalLoad: false,
    //     });
    //   });
  };

  render() {
    const {
      reportStartDate, reportEndDate,
    } = this.state;

    const { sellerSelect } = this.props;

    if (sellerSelect.type === 2) {
      return (
        <Container className="dashboard">
          <Col md={12}>
            <Row>
              <DateRangePickerPanel
                reportStartDate={reportStartDate}
                reportEndDate={reportEndDate}
                onDateChange={this.onDateChange}
              />
            </Row>
          </Col>
          <Col md={12}>
            <Row>
              Test 1
            </Row>
          </Col>
          <Col md={12}>
            <Row>
              Test 2
            </Row>
          </Col>
        </Container>
      );
    }
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <p>This is a Vendor specific report. Please select a new report or select a Vendor account.</p>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { sellerSelect } = state;
  // const { user } = state.authentication;

  return {
    sellerSelect,
    // user,
  };
};

export default connect(mapStateToProps)(PpcBestsellersPage);
