import React from 'react';
import PropTypes from 'prop-types';

export default function GraphicDataPointCircle({
  label, value, color, height, width, radius, dx, dy, stdDeviation, labelFontSize, valueFontSize,
}) {
  return (
    <svg height={height} width={width} xmlns="http://www.w3.org/2000/svg">
      <defs>
        <filter id="shadow" x="0" y="0" width="200%" height="200%">
          <feOffset result="offset" in="SourceAlpha" dx={dx} dy={dy} />
          <feGaussianBlur result="blur" in="offset" stdDeviation={stdDeviation} />
          <feBlend in="SourceGraphic" in2="blur" mode="normal" />
        </filter>
      </defs>
      <circle cx="50%" cy="50%" r={radius} fill={color} filter="url(#shadow)" />
      <text x="50%" y="40%" textAnchor="middle" fill="white" fontSize={`${labelFontSize}px`}>{label}</text>
      <text x="50%" y="70%" textAnchor="middle" fill="black" fontSize={`${valueFontSize}px`}>{value}</text>
      Sorry, your browser does not support inline SVG.
    </svg>
  );
}

GraphicDataPointCircle.defaultProps = {
  height: '90',
  width: '90',
  radius: '40',
  dx: '2',
  dy: '2',
  stdDeviation: '2.3',
  labelFontSize: '16',
  valueFontSize: '14',
};

GraphicDataPointCircle.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
  radius: PropTypes.string,
  dx: PropTypes.string,
  dy: PropTypes.string,
  stdDeviation: PropTypes.string,
  labelFontSize: PropTypes.string,
  valueFontSize: PropTypes.string,
};
