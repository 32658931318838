import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

export const fieldStyle = { verticalAlign: 'middle' };
export const checkStyle = { textAlign: 'center', verticalAlign: 'middle' };
export const headerStyle = { textAlign: 'center' };

export const mapBoolToDb = bool => (bool ? '1' : '0');
export const mapDbToBool = dbValue => (dbValue === 1);

export const ErrorShape = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  text: PropTypes.string.isRequired,
};

export function useInputText(initialValue) {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
    reset: () => setValue(initialValue),
    bind: {
      value,
      onChange: (event) => {
        event.preventDefault();
        setValue(event.target.value);
      },
    },
  };
}

export function useInputCheckbox(initialValue) {
  const [state, setState] = useState(initialValue);

  return {
    value: state,
    setValue: setState,
    reset: () => setState(initialValue),
    bind: {
      value: state,
      checked: state,
      onChange: event => setState(event.target.checked),
    },
  };
}

export function useInputSelect(initialValue) {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
    reset: () => setValue(initialValue),
    bind: {
      selected: value,
      onChange: (object) => {
        setValue(object);
      },
      disable: false,
    },
  };
}

export function getToggleArrayItemFieldFunction(keyField, toggleField, setData, data) {
  return function toggle(item) {
    const result = data.map(it => (
      it[keyField] === item[keyField]
        ? ({
          ...it,
          [toggleField]: !it[toggleField],
        })
        : it
    ));
    setData(result);
  };
}

export function getMoveArrayItemFunction(setData, data, mapFunction = item => item) {
  return function move(from, to) {
    const dataClone = JSON.parse(JSON.stringify(data));
    dataClone.splice(to, 0, dataClone.splice(from, 1)[0]);
    setData(dataClone.map((it, index) => mapFunction(it, index)));
  };
}

export function getCreateArrayItemFunction(setData, data) {
  return function createItem(item) {
    setData([...data, item]);
  };
}

export function getDeleteArrayItemFunction(keyField, setData, data, mapFunction = item => item) {
  return function deleteItem(item) {
    const filteredData = data.filter(it => (
      it[keyField] !== item[keyField]
    ));
    setData(filteredData.map((it, index) => mapFunction(it, index)));
  };
}

export function getUpdateArrayItemFunction(keyField, setData, data) {
  return function updateItem(updatedItem) {
    setData(data.map(it => (
      it[keyField] === updatedItem[keyField]
        ? updatedItem
        : it
    )));
  };
}

export function useDisplayError() {
  const [displayError, setDisplayError] = useState();
  const resetError = () => setDisplayError(undefined);
  const setWithTimeout = (value) => {
    setDisplayError(value);
    setTimeout(() => resetError(), 5000);
  };

  return {
    displayError,
    setDisplayError: setWithTimeout,
    resetError,
  };
}

export function usePrevious(value) {
  const ref = useRef([]);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
