import React, { useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import moment from 'moment';
import SalesComboGraph from './components/SalesCombo';
import PromotionEvents from './components/PromotionEvents';
import useSellerData from '../AccountDashboard/components/useSellerData';
import Sales from '../AccountDashboard/components/Sales';
import Spend from '../AccountDashboard/components/Spend';
import Impressions from '../AccountDashboard/components/Impressions';
import Clicks from '../AccountDashboard/components/Clicks';
import PPCSalesPercentage from '../AccountDashboard/components/PPCSalesPercentage';
import SellerReportMessage from '../../../shared/components/widgets/SellerReportMessage';
import { DateViewOptions } from '../../../shared/components/widgets/DateViewFilter';

export default function DashboardPage() {
  const sellerSelect = useSelector(store => store.sellerSelect);
  const [loading, results, error] = useSellerData(sellerSelect, moment().subtract(14, 'day'), moment().subtract(1, 'day'), DateViewOptions.DAY);
  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  if (sellerSelect.type === 1) {
    return (
      <Container className="dashboard">
        <Row>
          <Col md={6} lg={4}>
            <Sales
              title="Gross Sales"
              subtitle="Total"
              dayField="gross_sales"
              data={results && results.data}
              loading={loading}
              dateView={DateViewOptions.DAY}
              account={sellerSelect}
            />
          </Col>
          <Col md={6} lg={4}>
            <Sales
              title="PPC Sales"
              subtitle="Total"
              data={results && results.data}
              loading={loading}
              dateView={DateViewOptions.DAY}
              account={sellerSelect}
            />
          </Col>
          <Col md={6} lg={4}>
            <PPCSalesPercentage
              data={results && results.data}
              loading={loading}
              dateView={DateViewOptions.DAY}
            />
          </Col>
          <Col md={6} lg={4}>
            <Spend
              title="Spend"
              subtitle="Total"
              data={results && results.data}
              loading={loading}
              dateView={DateViewOptions.DAY}
              account={sellerSelect}
            />
          </Col>
          <Col md={6} lg={4}>
            <Impressions
              title="Impressions"
              subtitle="Total"
              data={results && results.data}
              loading={loading}
              dateView={DateViewOptions.DAY}
            />
          </Col>
          <Col md={6} lg={4}>
            <Clicks
              title="Clicks"
              subtitle="Total"
              data={results && results.data}
              loading={loading}
              dateView={DateViewOptions.DAY}
            />
          </Col>
        </Row>
        <Row>
          <SalesComboGraph
            loading={loading}
            data={results && results.data}
          />
        </Row>
        <Row>
          <PromotionEvents />
        </Row>
      </Container>
    );
  }
  return (
    <SellerReportMessage />
  );
}
