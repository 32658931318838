import React, { PureComponent } from 'react';
import config from '../../../../config';
import TableBuilder from '../../../../shared/components/table/TableBuilder';
import {
  currencyFormatter, toDisplayDate, formatNumberDefaultToZero, formatDecimalToFixedTwoPercent,
} from '../../../../shared/components/table/functions';
import { imageFieldColumn, imageCellRendererComponent } from '../../../../shared/components/table/ImageCellRenderer';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

function numberValueParser(params) {
  return Number(params.newValue);
}

class VendorCatalogTable extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        imageFieldColumn,
        { field: 'asin', headerName: 'ASIN' },
        { field: 'parent_asin', headerName: 'Parent ASIN' },
        { field: 'ean', headerName: 'EAN' },
        { field: 'isbn_13', headerName: 'ISBN-13' },
        { field: 'upc', headerName: 'UPC' },
        { field: 'product_title', headerName: 'Product Title' },
        { field: 'brand', headerName: 'Brand' },
        { field: 'subcategory', headerName: 'Subcategory' },
        { field: 'category', headerName: 'Category' },
        { field: 'apparel_size', headerName: 'Apparel Size' },
        { field: 'apparel_size_width', headerName: 'Apparel Size Width' },
        { field: 'author_artist', headerName: 'Author / Artist' },
        { field: 'binding', headerName: 'Binding' },
        { field: 'color', headerName: 'Color' },
        { field: 'model_style_number', headerName: 'Model / Style Number' },
        {
          field: 'release_date.value',
          headerName: 'Release Date',
          valueFormatter: toDisplayDate,
        },
        {
          field: 'shipped_cogs',
          headerName: 'Shipped COGS',
          valueFormatter: currencyFormatter,
        },
        {
          field: 'shipped_cogs_percent_of_total',
          headerName: 'Shipped COGS - % of Total',
          valueFormatter: formatDecimalToFixedTwoPercent,
        },
        {
          field: 'shipped_cogs_prior_period',
          headerName: 'Shipped COGS - Prior Period',
          valueFormatter: formatDecimalToFixedTwoPercent,
        },
        {
          field: 'shipped_cogs_last_year',
          headerName: 'Shipped COGS - Last Year',
          valueFormatter: formatDecimalToFixedTwoPercent,
        },
        {
          field: 'shipped_units',
          headerName: 'Shipped Units',
          valueFormatter: formatNumberDefaultToZero,
          valueParser: numberValueParser,
        },
        {
          field: 'shipped_units_percent_of_total',
          headerName: 'Shipped Units - % of Total',
          valueFormatter: formatDecimalToFixedTwoPercent,
        },
        {
          field: 'shipped_units_prior_period',
          headerName: 'Shipped Units - Prior Period',
          valueFormatter: formatDecimalToFixedTwoPercent,
        },
        {
          field: 'shipped_units_last_year',
          headerName: 'Shipped Units - Last Year',
          valueFormatter: formatDecimalToFixedTwoPercent,
        },
        {
          field: 'ordered_units',
          headerName: 'Ordered Units',
          valueFormatter: formatNumberDefaultToZero,
          valueParser: numberValueParser,
        },
        {
          field: 'ordered_units_percent_of_total',
          headerName: 'Ordered Units - % of Total',
          valueFormatter: formatDecimalToFixedTwoPercent,
        },
        {
          field: 'ordered_units_prior_period',
          headerName: 'Ordered Units - Prior Period',
          valueFormatter: formatDecimalToFixedTwoPercent,
        },
        {
          field: 'ordered_units_last_year',
          headerName: 'Ordered Units - Last Year',
          valueFormatter: formatDecimalToFixedTwoPercent,
        },
        {
          field: 'customer_returns',
          headerName: 'Customer Returns',
          valueFormatter: formatNumberDefaultToZero,
          valueParser: numberValueParser,
        },
        {
          field: 'free_replacements',
          headerName: 'Free Replacements',
          valueFormatter: formatNumberDefaultToZero,
          valueParser: numberValueParser,
        },
        {
          field: 'subcategory_sales_rank',
          headerName: 'Subcategory Sales Rank',
          valueFormatter: formatNumberDefaultToZero,
          valueParser: numberValueParser,
        },
        {
          field: 'subcategory_better_worse',
          headerName: 'Subcategory Better Worse',
          valueFormatter: formatDecimalToFixedTwoPercent,
        },
        {
          field: 'average_sales_price',
          headerName: 'Average Sales Price',
          valueFormatter: currencyFormatter,
        },
        {
          field: 'average_sales_price_prior_period',
          headerName: 'Average Sales Price Prior Period',
          valueFormatter: formatDecimalToFixedTwoPercent,
        },
        {
          field: 'change_in_glance_view_prior_period',
          headerName: 'Change in Glance View - Prior Period',
          valueFormatter: formatDecimalToFixedTwoPercent,
        },
        {
          field: 'change_in_glance_view_last_year',
          headerName: 'Change in GV Last Year',
          valueFormatter: formatDecimalToFixedTwoPercent,
        },
        {
          field: 'rep_oos',
          headerName: 'Rep OOS',
          valueFormatter: formatDecimalToFixedTwoPercent,
        },
        {
          field: 'rep_oos_percent_of_total',
          headerName: 'Rep OOS - % of Total',
          valueFormatter: formatDecimalToFixedTwoPercent,
        },
        {
          field: 'rep_oos_prior_period',
          headerName: 'Rep OOS - Prior Period',
          valueFormatter: formatDecimalToFixedTwoPercent,
        },
        {
          field: 'lbb',
          headerName: 'LBB (Price)',
          valueFormatter: formatDecimalToFixedTwoPercent,
        },
      ],
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        cellStyle: (params) => {
          if (params.value < 0.00) {
            return { color: 'red' };
          }
          return null;
        },
      },
      frameworkComponents: {
        ...imageCellRendererComponent,
      },
      showDatePicker: true,
      pdOptions: {
        showButton: true,
        columnName: 'asin',
      },
    };
  }

  render() {
    const {
      columnDefs, defaultColDef, onCellEditingStopped, showDatePicker, pdOptions, frameworkComponents,
    } = this.state;

    const apiEndPoint = `${apiUrl}/vendors/sales-manufacturing`;

    return (
      <TableBuilder
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        apiEndPoint={apiEndPoint}
        onCellEditingStopped={onCellEditingStopped}
        showDatePicker={showDatePicker}
        productDetailOptions={pdOptions}
        frameworkComponents={frameworkComponents}
        rowHeight={75}
        autoSizeAll
      />
    );
  }
}

export default VendorCatalogTable;
