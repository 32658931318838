import React, { PureComponent } from 'react';
import config from '../../../../../../config';
import TableBuilder from '../../../../../../shared/components/table/TableBuilder';
import {
  formatFixedTwoPercent, currencyFormatter,
} from '../../../../../../shared/components/table/functions';
import { SiteName } from '../../../../../../shared/components/domainSupport';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class ProfitsTable extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        {
          field: 'brand',
          headerName: 'Brand',
          valueGetter: function calcRoasValueGetter(params) {
            if (params.data && params.data.brand) {
              return params.data.brand.trim();
            }
            return '';
          },
        },
        {
          field: 'sku',
          headerName: 'SKU',
          rowGroup: true,
          enableRowGroup: true,
        },
        {
          field: 'segment_one',
          headerName: 'Segment 1',
          valueGetter: function calcRoasValueGetter(params) {
            if (params.data && params.data.segment_one) {
              return params.data.segment_one.trim();
            }
            return '';
          },
        },
        {
          field: 'segment_two',
          headerName: 'Segment 2',
          valueGetter: function calcRoasValueGetter(params) {
            if (params.data && params.data.segment_two) {
              return params.data.segment_two.trim();
            }
            return '';
          },
        },
        {
          field: 'segment_three',
          headerName: 'Segment 3',
          valueGetter: function calcRoasValueGetter(params) {
            if (params.data && params.data.segment_three) {
              return params.data.segment_three.trim();
            }
            return '';
          },
        },
        {
          field: 'segment_four',
          headerName: 'Segment 4',
          valueGetter: function calcRoasValueGetter(params) {
            if (params.data && params.data.segment_four) {
              return params.data.segment_four.trim();
            }
            return '';
          },
        },
        {
          field: 'segment_five',
          headerName: 'Segment 5',
          valueGetter: function calcRoasValueGetter(params) {
            if (params.data && params.data.segment_five) {
              return params.data.segment_five.trim();
            }
            return '';
          },
        },
        {
          field: 'sales',
          headerName: 'PPC Sales',
          valueFormatter: currencyFormatter,
          aggFunc: 'sum',
        },
        {
          field: 'units',
          headerName: 'PPC Units',
          aggFunc: 'sum',
        },
        {
          field: 'CALCAmazonFees',
          headerName: 'Amazon Fees',
          valueFormatter: currencyFormatter,
          valueGetter: function calcAmazonFeesValueGetter(params) {
            if (params && params.data && params.data.orderQuantity && params.data.orderQuantity !== 0) {
              return ((params.data.orderFees + params.data.orderTaxes + params.data.orderPromotions + params.data.adjustmentAmounts) / params.data.orderQuantity) * params.data.units;
            }
            return 0;
          },
          aggFunc: 'sum',
        },
        {
          field: 'CALCCogs',
          headerName: 'COGS',
          valueFormatter: currencyFormatter,
          valueGetter: function calcCogsValueGetter(params) {
            if (params && params.data && params.data.unit_cog && params.data.units) {
              return params.data.unit_cog * params.data.units * -1;
            }
            return 0;
          },
          aggFunc: 'sum',
        },
        {
          field: 'spend',
          headerName: 'PPC Spend',
          valueFormatter: currencyFormatter,
          valueGetter: 'data.spend * -1',
          aggFunc: 'sum',
        },
        {
          field: 'CALCaNineFee',
          headerName: `${SiteName()} Fee`,
          valueFormatter: currencyFormatter,
          valueGetter: function calcaNineFeeValueGetter(params) {
            if (params && params.data && params.data.billing_rate && params.data.sales) {
              return params.data.billing_rate * params.data.sales * -1;
            }
            return 0;
          },
          aggFunc: 'sum',
        },
        {
          field: 'billing_rate',
          headerName: `${SiteName()} %`,
        },
        {
          field: 'CALCTotalCost',
          headerName: 'Total Cost',
          valueFormatter: currencyFormatter,
          valueGetter: function calcTotalCostValueGetter(params) {
            const sales = (params.data.sales || 0);
            const orderFees = (params.data.orderFees || 0);
            const orderTaxes = (params.data.orderTaxes || 0);
            const orderPromotions = (params.data.orderPromotions || 0);
            const adjustmentAmounts = (params.data.adjustmentAmounts || 0);
            const orderQuantity = (params.data.orderQuantity || 0);
            const units = (params.data.units || 0);
            const spend = (params.data.spend || 0) * -1;
            const unitCog = (params.data.unit_cog || 0);
            const aNineFee = (params.data.billing_rate || 0) * sales * -1;

            if (orderQuantity !== 0) {
              return (((orderFees + orderTaxes + orderPromotions + adjustmentAmounts) / orderQuantity) * units) + spend + (unitCog * units * -1) + aNineFee;
            }
            return spend + (unitCog * units * -1) + aNineFee;
          },
          aggFunc: 'sum',
        },
        {
          field: 'CALCPpcProft',
          headerName: 'PPC Profit',
          valueFormatter: currencyFormatter,
          valueGetter: function calcPpcProfitValueGetter(params) {
            const sales = (params.data.sales || 0);
            const orderFees = (params.data.orderFees || 0);
            const orderTaxes = (params.data.orderTaxes || 0);
            const orderPromotions = (params.data.orderPromotions || 0);
            const adjustmentAmounts = (params.data.adjustmentAmounts || 0);
            const orderQuantity = (params.data.orderQuantity || 0);
            const units = (params.data.units || 0);
            const spend = (params.data.spend || 0) * -1;
            const unitCog = (params.data.unit_cog || 0);
            const aNineFee = (params.data.billing_rate || 0) * sales * -1;

            if (orderQuantity !== 0) {
              return sales + (((orderFees + orderTaxes + orderPromotions + adjustmentAmounts) / orderQuantity) * units) + spend + (unitCog * units * -1) + aNineFee;
            }
            return sales + spend + (unitCog * units * -1) + aNineFee + 0;
          },
          aggFunc: 'sum',
        },
        {
          field: 'CALCPpcProftPercent',
          headerName: 'PPC Profit %',
          valueFormatter: currencyFormatter,
          valueGetter: function calcPpcProfitPercentValueGetter(params) {
            const sales = (params.data.sales || 0);
            const orderFees = (params.data.orderFees || 0);
            const orderTaxes = (params.data.orderTaxes || 0);
            const orderPromotions = (params.data.orderPromotions || 0);
            const adjustmentAmounts = (params.data.adjustmentAmounts || 0);
            const orderQuantity = (params.data.orderQuantity || 0);
            const units = (params.data.units || 0);
            const spend = (params.data.spend || 0) * -1;
            const unitCog = (params.data.unit_cog || 0);
            const aNineFee = (params.data.billing_rate || 0) * sales * -1;

            if (sales !== 0 && orderQuantity !== 0) {
              return (sales + (((orderFees + orderTaxes + orderPromotions + adjustmentAmounts) / orderQuantity) * units) + spend + (unitCog * units * -1) + aNineFee) / sales;
            }
            if (sales !== 0) {
              return (sales + spend + (unitCog * units * -1) + aNineFee) / sales;
            }
            return 0;
          },
        },
        {
          field: 'CALCPacos',
          headerName: 'Total Spend %',
          valueFormatter: formatFixedTwoPercent,
          valueGetter: function calcRoasValueGetter(params) {
            if (params && params.data && params.data.spend && params.data.sales && params.data.sales !== 0) {
              return (params.data.spend * -1) / params.data.sales;
            }
            return 0;
          },
          headerTooltip: 'PPC ACoS',
        },
      ],
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        cellStyle: (params) => {
          if (params.value < 0.00) {
            return { color: 'red' };
          }
          return null;
        },
      },
      autoGroupColumnDef: {
        headerName: ' Breakdown ',
        cellRendererParams: {
          suppressCount: true,
        },
      },
      showDatePicker: true,
      showFooter: true,
      enableCharts: true,
      enableRangeSelection: true,
      sideBar: false,
      pivotMode: true,
      suppressAggFuncInHeader: true,
    };
  }

  render() {
    const {
      columnDefs, defaultColDef, showDatePicker, showFooter, enableCharts, enableRangeSelection, sideBar, pivotMode, suppressAggFuncInHeader, autoGroupColumnDef,
    } = this.state;

    const apiEndPoint = `${apiUrl}/sellers/ppc-profit-report`;

    const pivotButtons = [
      { buttonName: 'SKU', buttonAction: 'sku' },
      { buttonName: 'Brand', buttonAction: 'brand' },
      { buttonName: 'Segment 1', buttonAction: 'segment_one' },
      { buttonName: 'Segment 2', buttonAction: 'segment_two' },
      { buttonName: 'Segment 3', buttonAction: 'segment_three' },
      // { buttonName: 'Segment 4', buttonAction: 'segment_four' },
      // { buttonName: 'Segment 5', buttonAction: 'segment_five' },
    ];

    return (
      <TableBuilder
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        apiEndPoint={apiEndPoint}
        showDatePicker={showDatePicker}
        showFooter={showFooter}
        enableCharts={enableCharts}
        enableRangeSelection={enableRangeSelection}
        sideBar={sideBar}
        pivotMode={pivotMode}
        suppressAggFuncInHeader={suppressAggFuncInHeader}
        autoGroupColumnDef={autoGroupColumnDef}
        pivotButtons={pivotButtons}
      />
    );
  }
}

export default ProfitsTable;
