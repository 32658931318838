import {
  useState, useEffect,
} from 'react';
import config from '../../../../config';
import { useFetchReturnsArray as useFetch } from '../../../../shared/components/hooks/useFetch';
import { when } from '../../../../shared/functions';

function getCountUrl(account, path) {
  if (account && account.value) {
    const url = `${config.serverUrl}/${path}?accountId=${account.value}&parentAccountId=${account.parentAccountId}`;
    return url;
  }

  return undefined;
}

export default function useAlertData(
  account,
) {
  const [eligiblyLoading, eligiblyResults, eligiblyError] = useFetch(getCountUrl(account, 'insights/eligible-summary'));
  const [ineligiblyLoading, ineligiblyResults, ineligiblyError] = useFetch(getCountUrl(account, 'insights/ineligible-summary'));
  const loading = eligiblyLoading === 'true' && ineligiblyLoading === 'true'; // Check all the loadings here
  const [accountId, setAccountId] = useState(account.value);
  const [results, setResults] = useState({ alertCount: 0, alertCards: [] });
  const [error, setError] = useState('');

  // Elegibility Effects
  useEffect(() => {
    if (eligiblyResults?.eligibleCount > 0) {
      const alertCard = {
        title: 'Eligibilty Alert',
        countName: 'Products',
        countValue: eligiblyResults.eligibleCount,
        platform: 'Amazon',
        recommendation: 'Products that have shown up as Ineligible in the last three days, but are currently Eligible.',
        dtlsUrl: 'insights/eligibility-detail',
        accountType: eligiblyResults.accountType,
        productList: '\''.concat(eligiblyResults.eligibleData.map(row => row.productId).join('\',\''), '\''),
        firstDate: eligiblyResults.firstDate,
        lastDate: eligiblyResults.lastDate,
        dateList: eligiblyResults.dateList,
      }

      setResults({
        alertCount: results.alertCount + 1,
        alertCards: [
          ...results.alertCards,
          alertCard,
        ],
      });
    }
  }, [eligiblyResults, setResults]);

  useEffect(() => {
    when(eligiblyError, () => {
      setError(eligiblyError);
    });
  }, [eligiblyError, setError]);

  // Inelegibility Effects
  useEffect(() => {
    if (ineligiblyResults?.ineligibleCount > 0) {
      const alertCard = {
        title: 'Ineligibilty Alert',
        countName: 'Products',
        countValue: ineligiblyResults.ineligibleCount,
        platform: 'Amazon',
        recommendation: 'Products that have shown up as Eligible in the last seven days, but are currently Ineligible.',
        dtlsUrl: 'insights/eligibility-detail',
        accountType: ineligiblyResults.accountType,
        productList: '\''.concat(ineligiblyResults.ineligibleData.map(row => row.productId).join('\',\''), '\''),
        firstDate: ineligiblyResults.firstDate,
        lastDate: ineligiblyResults.lastDate,
        dateList: ineligiblyResults.dateList,
      }

      setResults({
        alertCount: results.alertCount + 1,
        alertCards: [
          ...results.alertCards,
          alertCard,
        ],
      });
    }
  }, [ineligiblyResults, setResults]);

  useEffect(() => {
    if (account.value !== accountId) {
      setResults({ alertCount: 0, alertCards: [] });
      setAccountId(account.value);
    }
  }, [account]);

  useEffect(() => {
    when(ineligiblyError, () => {
      setError(ineligiblyError);
    });
  }, [ineligiblyError, setError]);


  return [loading, results, error];
}
