import React from 'react';
// import { Progress } from 'reactstrap';
import PropTypes from 'prop-types';
import Panel from '../../../../shared/components/Panel';

const PhaseOverviewPage = ({ content }) => (
  <Panel md={12} lg={4} title={content.title}>
    <div className="dashboard__sales-report">
      <div className="progress-wrap progress-wrap--small">
        <p className="dashboard__sales-report-title">{content.left_header}</p>
        <p className="dashboard__sales-report-now">${content.left_data}</p>
      </div>
      <div className="progress-wrap progress-wrap--small progress-wrap--pink">
        <p className="dashboard__sales-report-title">{content.middle_header}</p>
        <p className="dashboard__sales-report-now">${content.middle_data}</p>
      </div>
      <div className="progress-wrap progress-wrap--small progress-wrap--pink">
        <p className="dashboard__sales-report-title">{content.right_header}</p>
        <p className="dashboard__sales-report-now">{content.right_data}%</p>
      </div>
    </div>
  </Panel>
);

PhaseOverviewPage.propTypes = {
  // eslint-disable-next-line
  content: PropTypes.object.isRequired,
};

export default PhaseOverviewPage;
