import {
  CHANGE_THEME_TO_DARK,
  CHANGE_THEME_TO_LIGHT,
  CHANGE_THEME_TO_LIMITLESS,
} from '../actions/themeActions';
import { getPlatform } from '../../shared/components/domainSupport';
import { getLocalStorage } from '../../shared/storage';

const user = JSON.parse(getLocalStorage('user'));
const { cssKey } = getPlatform();
let setTheme = `theme-${cssKey}light`;
if (user) {
  setTheme = `theme-${cssKey}${user.theme}`;
}
const initialState = {
  className: setTheme,
};

export default function theme(state = initialState, action) {
  switch (action.type) {
    case CHANGE_THEME_TO_DARK:
      return { className: `theme-${cssKey}dark` };
    case CHANGE_THEME_TO_LIGHT:
      return { className: `theme-${cssKey}light` };
    case CHANGE_THEME_TO_LIMITLESS:
      return { className: `theme-${cssKey}limitless` };
    default:
      return state;
  }
}
