import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TopbarProfile from './topbar/TopbarProfile';
import { SiteImageOnboarding } from '../../shared/components/domainSupport';

// eslint-disable-next-line
class LayoutOnboarding extends Component {
  static propTypes = {
    loggedIn: PropTypes.bool,
    logoImage: PropTypes.string,
  };

  static defaultProps = {
    loggedIn: false,
    logoImage: null,
  };

  render() {
    const { loggedIn, logoImage } = this.props;

    return (
      <div>
        <div className="topbar_onboarding">
          <div className="topbar__right">
            {loggedIn && (
              <TopbarProfile />
            )}
          </div>
          <SiteImageOnboarding logoImage={logoImage} logoHeight="100" logoWidth="300" link="/" />
        </div>
        <br /><br />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loggedIn } = state.authentication;
  return {
    loggedIn,
  };
};

export default connect(mapStateToProps)(LayoutOnboarding);
