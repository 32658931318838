import React, { PureComponent } from 'react';
import {
  Card, Col, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import classnames from 'classnames';
// import crypto from 'crypto';
import md5 from 'md5';
import { connect } from 'react-redux';
import ProfileActivities from './ProfileActivities';
import ProfileSettings from './ProfileSettings';
// import ProfileManageCalendar from './ProfileManageCalendar';
import { SiteName } from '../../../../shared/components/domainSupport';

class ProfileTabs extends PureComponent {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
    };
  }

  getTheme = (shortName) => {
    let longTheme = { value: 'light', label: 'Light Theme' };
    if (shortName && shortName === 'dark') {
      longTheme = { value: 'dark', label: 'Dark Theme' };
    } else if (shortName && shortName === 'limitless') {
      longTheme = { value: 'limitless', label: `${SiteName()} Theme` };
    }
    return longTheme;
  }

  toggle(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    // eslint-disable-next-line
    const { user } = this.props;
    const gravatarHash = md5(user.email);

    const initialValues = {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      access: user.access,
      theme: this.getTheme(user.theme),
      is_active: user.is_active,
      gravatarHash,
    };

    const { activeTab } = this.state;
    return (
      <Col md={12} lg={12} xl={8}>
        <Card>
          <div className="profile__card tabs tabs--bordered-bottom">
            <div className="tabs__wrap">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => {
                      this.toggle('1');
                    }}
                  >
                    Profile
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => {
                      this.toggle('2');
                    }}
                  >
                    Activity
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '3' })}
                    onClick={() => {
                      this.toggle('3');
                    }}
                  >
                    Manage Calendar
                  </NavLink>
                </NavItem> */}
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <ProfileSettings initialValues={initialValues} />
                </TabPane>
                <TabPane tabId="2">
                  <ProfileActivities />
                </TabPane>
                {/* <TabPane tabId="3">
                  <p>This is your Account calendar.  Your calendar will show data from all your accounts you have access to.</p>
                  <p>Note that you are adding events for the Account you have selected in the header.</p>
                  <p>Start and End dates, use the following format: YYYY-MM-DD HH24:MI</p>
                  <p>Priority can be predefined status (high, normal, medium, low) or you can just use a color code (#fcba01)</p>
                  <ProfileManageCalendar />
                </TabPane> */}
              </TabContent>
            </div>
          </div>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state.authentication;
  return {
    user,
  };
};

export default connect(mapStateToProps)(ProfileTabs);
