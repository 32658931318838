import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../Panel';
import {
  formatNumberDefaultToZero,
} from '../table/functions';

const getDelta = (inValue) => {
  if (inValue > 0) {
    return (<span style={{ color: '#03c03c' }}>{formatNumberDefaultToZero({ value: inValue })} {String.fromCharCode(8679)}</span>);
  }
  if (inValue < 0) {
    return (<span style={{ color: '#d04960' }}>{formatNumberDefaultToZero({ value: inValue })} {String.fromCharCode(8681)}</span>);
  }
  return (<span>{formatNumberDefaultToZero({ value: inValue })} {String.fromCharCode(8691)}</span>);
};


const DeltaCompare = ({ content, loadingData, lgWidth }) => (
  <Panel md={12} lg={lgWidth} title={content.title} parentRefresh={loadingData}>
    <div className="dashboard__delta-report">
      <div className="progress-wrap progress-wrap--small">
        <p className="dashboard__sales-report-title">{content.left_header}</p>
        <p className="dashboard__bypage-chart-number">{content.left_data}</p>
      </div>
      <div className="progress-wrap progress-wrap--small progress-wrap--pink">
        <p className="dashboard__delta_header">{content.middle_header}</p>
        <p className="dashboard__bypage-chart-number" style={{ whiteSpace: 'nowrap' }}>{getDelta(content.middle_data)}</p>
      </div>
      <div className="progress-wrap progress-wrap--small progress-wrap--pink">
        <p className="dashboard__sales-report-title">{content.right_header}</p>
        <p className="dashboard__bypage-chart-number">{content.right_data}</p>
      </div>
    </div>
  </Panel>
);

DeltaCompare.defaultProps = {
  lgWidth: 6,
};

DeltaCompare.propTypes = {
  // eslint-disable-next-line
  content: PropTypes.object.isRequired,
  loadingData: PropTypes.bool.isRequired,
  lgWidth: PropTypes.number,
};

export default DeltaCompare;
