import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import config from '../../../../config';
import TableBuilder from '../../../../shared/components/table/TableBuilder';
import {
  formatDecimalToFixedTwoPercent, currencyFormatter, formatNumberDefaultToZero,
} from '../../../../shared/components/table/functions';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class TopTenMarketShareTable extends PureComponent {
  static propTypes = {
    firstTableId: PropTypes.string.isRequired,
    secondTableId: PropTypes.string.isRequired,
    sellerAccountId: PropTypes.number.isRequired,
    parentAccountId: PropTypes.number.isRequired,
    setPageData: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    const {
      firstTableId, secondTableId, sellerAccountId, parentAccountId,
    } = this.props;

    this.state = {
      columnDefs: [
        {
          field: 'asin',
          headerName: 'ASIN',
          chartDataType: 'category',
        },
        {
          field: 'product_title',
          headerName: 'Product Name',
        },
        {
          field: 'brand',
          headerName: 'Brand',
          chartDataType: 'category',
        },
        {
          headerName: 'First Date',
          children: [
            {
              field: 'subcat_rank',
              headerName: 'AVG Sub Category Rank',
              valueFormatter: formatNumberDefaultToZero,
              chartDataType: 'series',
            },
            {
              field: 'cat_rank',
              headerName: 'AVG Category Rank',
              valueFormatter: formatNumberDefaultToZero,
              chartDataType: 'series',
            },
            {
              field: 'units',
              headerName: 'Units',
              chartDataType: 'series',
            },
            {
              field: 'revenue',
              headerName: 'Revenue',
              valueFormatter: currencyFormatter,
              chartDataType: 'series',
            },
          ],
        },
        {
          headerName: 'Second Date',
          children: [
            {
              field: 'second_subcat_rank',
              headerName: 'AVG Sub Category Rank',
              valueFormatter: formatNumberDefaultToZero,
              chartDataType: 'series',
            },
            {
              field: 'second_cat_rank',
              headerName: 'AVG Category Rank',
              valueFormatter: formatNumberDefaultToZero,
              chartDataType: 'series',
            },
            {
              field: 'second_units',
              headerName: 'Units',
              chartDataType: 'series',
            },
            {
              field: 'second_revenue',
              headerName: 'Revenue',
              valueFormatter: currencyFormatter,
              chartDataType: 'series',
            },
          ],
        },
        {
          headerName: 'Delta',
          children: [
            {
              field: 'delta_asin_count',
              headerName: 'Count of ASIN',
              valueFormatter: formatDecimalToFixedTwoPercent,
              chartDataType: 'series',
            },
            {
              field: 'delta_subcat_rank',
              headerName: 'AVG Sub Category Rank',
              valueFormatter: formatDecimalToFixedTwoPercent,
              chartDataType: 'series',
            },
            {
              field: 'delta_cat_rank',
              headerName: 'AVG Category Rank',
              valueFormatter: formatDecimalToFixedTwoPercent,
              chartDataType: 'series',
            },
            {
              field: 'delta_units',
              headerName: 'Units',
              valueFormatter: formatDecimalToFixedTwoPercent,
              chartDataType: 'series',
            },
            {
              field: 'delta_revenue',
              headerName: 'Revenue',
              valueFormatter: currencyFormatter,
              chartDataType: 'series',
            },
          ],
        },
      ],
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        cellStyle: (params) => {
          if (params.value < 0.00) {
            return { color: 'red' };
          }
          return null;
        },
      },
      showFooter: false,
      enableCharts: true,
      enableRangeSelection: true,
      apiQueryParams: `firstTableId=${firstTableId}&secondTableId=${secondTableId}&sellerAccountId=${sellerAccountId}&parentAccountId=${parentAccountId}`,
      pdOptions: {
        showButton: true,
        columnName: 'asin',
      },
    };
  }

  static getDerivedStateFromProps(props) {
    if (props.firstTableId && props.secondTableId) {
      return {
        apiQueryParams: `firstTableId=${props.firstTableId}&secondTableId=${props.secondTableId}&sellerAccountId=${props.sellerAccountId}&parentAccountId=${props.parentAccountId}`,
      };
    }
    return null;
  }

  render() {
    const {
      columnDefs, defaultColDef, showFooter, enableCharts, enableRangeSelection, apiQueryParams, pdOptions,
    } = this.state;

    const { setPageData } = this.props;

    const apiEndPoint = `${apiUrl}/vendors/market-share-asins`;

    return (
      <TableBuilder
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        apiEndPoint={apiEndPoint}
        apiQueryParams={apiQueryParams}
        showFooter={showFooter}
        enableCharts={enableCharts}
        enableRangeSelection={enableRangeSelection}
        parentSetData={setPageData}
        productDetailOptions={pdOptions}
      />
    );
  }
}

export default TopTenMarketShareTable;
