/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CloseIcon from 'mdi-react/CloseIcon';
import DateRangePicker from './DateRangePicker';

class DateRangePickerPanel extends PureComponent {
  static propTypes = {
    reportStartDate: PropTypes.string,
    reportEndDate: PropTypes.string,
    onDateChange: PropTypes.func.isRequired,
  };

  state = {
    open: false,
  };

  handleOpen = () => {
    this.setState(prevState => ({ open: !prevState.open }));
  };

  render() {
    const {
      reportStartDate, reportEndDate, onDateChange,
    } = this.props;

    const { open } = this.state;

    const customizerClass = classNames({
      customizer__wrap: true,
      'customizer__wrap--open': open,
    });

    return (
      <div className="customizer">
        <button className="customizer__btn" type="button" onClick={this.handleOpen}>
          <p>{reportStartDate} - {reportEndDate} <span className="lnr lnr-calendar-full" style={{ paddingLeft: '10px' }} /></p>
        </button>
        <div className={customizerClass}>
          <div className="customizer__title-wrap">
            <h5>Date Range</h5>
            <button className="customizer__close-btn" type="button" onClick={this.handleOpen}>
              <CloseIcon />
            </button>
          </div>
          <p className="customizer__caption">Select the date range for the graphs and data on this page.
          </p>
          <DateRangePicker
            startDate={moment(reportStartDate).toDate()}
            endDate={moment(reportEndDate).toDate()}
            onDateChange={onDateChange}
            className="theme-dark"
          />
        </div>
      </div>
    );
  }
}

DateRangePickerPanel.defaultProps = {
  reportStartDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
  reportEndDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
};

export default DateRangePickerPanel;
