/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import Panel from '../../../../shared/components/Panel';
import style from '../../../../shared/components/themeSupport';
import {
  currencyFormatter,
} from '../../../../shared/components/table/functions';

export default function SalesComboGraph({ loading, data }) {
  const theme = useSelector(state => state.theme);
  const showDots = (fieldName) => {
    const count = (data ? data.reduce((countTotal, item) => {
      if (item[fieldName]) {
        return countTotal + 1;
      }
      return countTotal;
    }, 0) : 0);
    return (data && count < data.length);
  };
  return (
    <Panel
      md={12}
      title="Sales Combo"
      subhead="Gross Sales recorded by order date"
      parentRefresh={loading}
    >
      <ResponsiveContainer height={250} className="dashboard__area">
        <AreaChart data={data} margin={{ top: 20, left: -15, bottom: 20 }}>
          <CartesianGrid stroke={style(theme).colorText} />
          <XAxis dataKey="rankDate" stroke={style(theme).colorText} />
          <YAxis stroke={style(theme).colorText} />
          <Tooltip />
          <Legend />
          <Area
            name="Gross Sales"
            type="monotone"
            dataKey="gross_sales"
            fill="#4ce1b6"
            stroke="#4ce1b6"
            fillOpacity={0.2}
            formatter={val => currencyFormatter({ value: val })}
            dot={showDots('gross_sales')}
          />
          <Area
            name="PPC Sales"
            type="monotone"
            dataKey="sales"
            fill="#70bbfd"
            stroke="#70bbfd"
            fillOpacity={0.2}
            formatter={val => currencyFormatter({ value: val })}
            dot={showDots('sales')}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Panel>
  );
}

SalesComboGraph.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
};
