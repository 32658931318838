import React from 'react';
import { Col, Container, Row, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import AuthorizeMpApi from './components/AuthorizeMpApi';
import { defaultAgencyCode as DEFAULT_AGENCY } from '../../../shared/components/domainSupport';

const AuthMpApiIndex = ({ agencyCode, displayLink }) => (
  <Container className="dashboard">
    <Row>
      <Col md={10}>
        <CardBody>
          <h3 className="page-title">Walmart Marketplace API</h3>
        </CardBody>
      </Col>
    </Row>
    <Row>
      <br />
    </Row>
    <Row>
      <AuthorizeMpApi agencyCode={agencyCode} displayLink={displayLink} />
    </Row>
    <Row>
      <br />
    </Row>
  </Container>
);

AuthMpApiIndex.propTypes = {
  agencyCode: PropTypes.string,
  displayLink: PropTypes.string,
};

AuthMpApiIndex.defaultProps = {
  agencyCode: DEFAULT_AGENCY,
  displayLink: 'yes',
};

export default AuthMpApiIndex;
