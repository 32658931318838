import { authHeader } from '../helpers';
import { SiteName, platform as PLATFORM } from '../shared/components/domainSupport';
import { setLocalStorage } from '../shared/storage';

const config = require('../config');

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

function logout() {
  // remove user, sellerSelectOption, showDashboardAlert from local storage to log user out
  localStorage.removeItem('user');
  localStorage.removeItem('sellerSelectOption');
  localStorage.removeItem('showDashboardAlert');
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        const dangerAlert = { type: 'alert-danger', message: response.statusText };
        setLocalStorage('loginMessage', JSON.stringify(dangerAlert));
        window.location.reload(true);
      }

      const error = (data && (data.message || data.statusMessage)) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

function login(email, password, recaptcha, rememberMe) {
  const requestOptions = {
    method: 'POST',
    headers: config.jsonHeader,
    body: JSON.stringify({
      email,
      password,
      recaptcha,
      domain: window.location.hostname,
    }),
  };

  return fetch(`${apiUrl}/users/login`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      setLocalStorage('user', JSON.stringify(user));
      if (rememberMe) {
        setLocalStorage('rememberMe', user.email);
      } else {
        localStorage.removeItem('rememberMe');
      }
      return user;
    });
}

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${apiUrl}/users`, requestOptions).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}

function register(user, recaptcha) {
  const requestOptions = {
    method: 'POST',
    headers: config.jsonHeader,
    body: JSON.stringify({ ...user, recaptcha }),
  };

  return fetch(`${apiUrl}/users/register`, requestOptions).then(handleResponse);
}

function updateProfile(id, jwtToken, updateUserData) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', authorization: `JWT ${jwtToken}` },
    body: JSON.stringify({
      id, firstName: updateUserData.first_name, lastName: updateUserData.last_name, email: updateUserData.email, theme: updateUserData.theme.value, updatePassword: updateUserData.updatePassword, confirmPassword: updateUserData.confirmPassword,
    }),
  };

  return fetch(`${apiUrl}/users/profile`, requestOptions).then(handleResponse);
}

function update(user) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };

  return fetch(`${apiUrl}/users/${user.id}`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
// eslint-disable-next-line
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(`${apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}

function forgotPassword(email) {
  const platform = PLATFORM;
  const requestOptions = {
    method: 'POST',
    headers: config.jsonHeader,
    body: JSON.stringify({ email, platform }),
  };

  return fetch(`${apiUrl}/users/forgot`, requestOptions)
    .then(handleResponse)
    .then(user => ({ user }));
}

function authorizeApi(customername, customeremail, recaptcha, countryObj, agencyCode, platform, accountType, accountId, apiType, isTest) {
  const requestOptions = {
    method: 'POST',
    headers: config.jsonHeader,
    body: JSON.stringify({
      customername,
      customeremail,
      recaptcha,
      countryObj,
      site: SiteName(),
      agencyCode,
      platform,
      apiType,
      accountType,
      accountId,
      isTest,
    }),
  };

  return fetch(`${apiUrl}/onboarding/authorize-api`, requestOptions)
    .then(handleResponse)
    .then(response => response);
}

function refreshLogin(user) {
  const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
  const requestOptions = {
    method: 'POST',
    headers: headerWithAuth,
    body: JSON.stringify({
      ...user,
    }),
  };

  return fetch(`${apiUrl}/users/refresh-login`, requestOptions)
    .then(handleResponse)
    .then((newUser) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      setLocalStorage('user', JSON.stringify(newUser));
      return newUser;
    });
}

// eslint-disable-next-line
export const userService = {
  login,
  logout,
  register,
  getAll,
  getById,
  update,
  delete: _delete,
  forgotPassword,
  updateProfile,
  authorizeApi,
  refreshLogin,
};
