import React, { useState } from 'react';
import { FiCameraOff } from 'react-icons/fi';

function ImageCellRenderer(params) {
  const { data } = params;
  const iconStyle = { width: 40, height: 40, color: 'var(--secondary)' };
  const [url] = useState(data && data.primary_image_url);
  const [product] = useState(data && data.item_id);
  const missingImage = <FiCameraOff className="ag-no-image-icon" style={iconStyle} alt="Image not available" />;
  const imageWidth = 75;
  const imageHeight = 75;

  return (
    <>
      {url && (
        <img onError={() => missingImage} src={url} width={imageWidth} height={imageHeight} title="Product Image" alt={product} loading="lazy" />
      )}
      {!url && data && (
        missingImage
      )}
      {!data && (
        null
      )}
    </>
  );
}

export const imageFieldColumn = {
  field: 'img',
  headerName: 'Image',
  minWidth: 90,
  maxWidth: 90,
  cellRenderer: 'imageCellRenderer',
  cellClass: 'ag-image-cell',
};

export const imageCellRendererComponent = {
  imageCellRenderer: ImageCellRenderer,
};
