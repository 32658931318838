import React from 'react';
import { Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import CloseIcon from 'mdi-react/CloseIcon';
import ChartBuilder from '../../../../../shared/components/chart/ChartBuilder';

const PieModal = ({
  title, theme, data, calloutLabelKey, sectorLabelKey, angleKey, showPieModal, changeShowPieModal,
}) => {
  const chartOptions = {
    data,
    series: [
      {
        type: 'pie',
        calloutLabelKey,
        sectorLabelKey,
        angleKey,
        calloutLabel: {
          offset: 10,
        },
      },
    ],
    title: {
      text: title,
    },
    // subtitle: {
    //   text: '2024',
    // },
  };

  return (
    <Modal
      isOpen={showPieModal}
      toggle={changeShowPieModal}
      className={theme.className}
      style={{ maxWidth: '900px', width: '100%' }}
    >
      <button className="panel__btn" type="button" onClick={() => changeShowPieModal()}><CloseIcon /></button><h2>{title}</h2>
      <br />
      <br />
      <br />
      <div style={{ textAlign: 'left' }}>
        <ChartBuilder chartOptions={chartOptions} />
      </div>
    </Modal>
  );
};

PieModal.propTypes = {
  title: PropTypes.string.isRequired,
  calloutLabelKey: PropTypes.string.isRequired,
  sectorLabelKey: PropTypes.string.isRequired,
  angleKey: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  changeShowPieModal: PropTypes.func.isRequired,
  showPieModal: PropTypes.bool,
};

PieModal.defaultProps = {
  showPieModal: false,
};

export default PieModal;
