/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import Panel from '../../../../shared/components/Panel';

class OosGraph extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line
    data: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    initalLoad: PropTypes.bool.isRequired,
    // eslint-disable-next-line
    lineList: PropTypes.array.isRequired,
  }

  render() {
    const {
      data, initalLoad, title, lineList,
    } = this.props;

    const colorOptions = ['#70bbfd', '#ffb153', '#f47b50', '#c88ffa', '#4ce1b6', '#66CCFF', '#FF9966', '#FF6666', '#9999CC', '#33CCCC'];
    const toPercent = precent => `${precent * 100}%`;

    return (
      <Panel md={12} title={title} parentRefresh={initalLoad}>
        <ResponsiveContainer height={250} className="dashboard__area">
          <AreaChart data={data} margin={{ top: 20, left: -15, bottom: 20 }}>
            <XAxis dataKey="report_date" tickLine={false} />
            <YAxis tickLine={false} tickFormatter={toPercent} />
            <Tooltip />
            <Legend />
            <CartesianGrid />
            {lineList.map((item, index) => (
              <Area
                name={item}
                type="monotone"
                dataKey={item}
                stroke={colorOptions[index]}
                fillOpacity={0}
              />
            ))}
          </AreaChart>
        </ResponsiveContainer>
      </Panel>
    );
  }
}

const mapStateToProps = (state) => {
  const { sellerSelect } = state;
  const { user } = state.authentication;

  return {
    sellerSelect,
    user,
  };
};

export default connect(mapStateToProps)(OosGraph);
