import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import BackgroundImage from '../../img/logo/adjuggernaut/logo_light.png';
import BackgroundImageDark from '../../img/logo/adjuggernaut/logo_dark.png';

export default function useLogoOnboarding(agencyCode) {
  const theme = useSelector(state => state.theme);
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    function getLogoLight() {
      let image = BackgroundImage;
      if (agencyCode !== '') {
        try {
          /* eslint-disable-next-line */
          image = require(`../../img/logo/${agencyCode}/logo_light.png`);
        } catch (error) {
          // return default
        }
      }
      return image;
    }

    function getLogoDark() {
      let image = BackgroundImageDark;
      if (agencyCode !== '') {
        try {
          /* eslint-disable-next-line */
          image = require(`../../img/logo/${agencyCode}/logo_dark.png`);
        } catch (error) {
          // return default
        }
      }
      return image;
    }

    if (agencyCode && theme && theme.className) {
      if (theme.className.endsWith('light')) {
        setLogo(getLogoLight());
      } else {
        setLogo(getLogoDark());
      }
    }
  }, [agencyCode, theme, setLogo]);

  return logo?.default;
}
