import React, { useState, useEffect } from 'react';
import { Col } from 'reactstrap';
import moment from 'moment';
import { useSelector } from 'react-redux';
// import { imageFieldColumn, imageCellRendererComponent } from './ImageCellRenderer';
import config from '../../../../config';
import CardFreemium from '../../../../shared/components/widgets/CardFreemium';
import Notify from '../../../../shared/components/widgets/Notify';
// import { doFetch } from '../../../../helpers';
// import { getExportPrefix, currencyFormatter } from '../../../../shared/components/table/functions';

function WmBudgetMgr({ adStatusOnCount, usedBudget, isLoading }) {
  const sellerSelect = useSelector(store => store.sellerSelect);
  const user = useSelector(store => store.authentication.user);
  const [dailySpend, setDailySpend] = useState(1);
  const [roasBrand, setRoasBrand] = useState(4);
  const [roasNonBrand, setRoasNonBrand] = useState(3);
  const [hasFreemiumRow, setHasFreemiumRow] = useState(false);
  const [budgetItems, setBudgetItems] = useState([]);
  const [budgetLoad, setBudgetLoad] = useState(false);
  const [monthlyBudgetCurrent, setMonthlyBudgetCurrent] = useState(0);
  const [monthlyBudgetCarryover, setMonthlyBudgetCarryover] = useState(0);
  const [monthlyBudgetSupplemental, setMonthlyBudgetSupplemental] = useState(0);
  const [monthlyPacos, setMonthlyPacos] = useState(0);
  const [monthlyTacos, setMonthlyTacos] = useState(0);

  useEffect(() => {
    if (!budgetLoad && dailySpend && roasBrand && roasNonBrand && adStatusOnCount) {
      const budgetItemCopy = [...budgetItems];
      const newBudgetItem = {
        name: 'Account Budget Group - Freemium',
        dailySpendPerItem: dailySpend,
        current: moment().daysInMonth() * adStatusOnCount * dailySpend,
        reallocation: 0,
        carryover: 0,
        supplemental: 0,
        pacos: 0,
        pacosBrand: roasBrand > 0 ? (1 / roasBrand) : 0,
        pacosNonBrand: roasNonBrand > 0 ? (1 / roasNonBrand) : 0,
        tacos: 0,
        includes: '',
        spendPriority: '',
        spendTactic: '',
        spendSpeed: '',
        portfolioIdsCsv: '',
        limitlessBudgetFlag: 0,
        itemOnCount: 0,
        budgetPerItem: 0,
      }

      const objIndex = budgetItemCopy.findIndex((budgetGroupRow => budgetGroupRow.name === 'Account Budget Group - Freemium'));
      if (objIndex >= 0) {
        // Update existing row
        budgetItemCopy[objIndex].dailySpendPerItem = newBudgetItem.dailySpendPerItem;
        budgetItemCopy[objIndex].current = newBudgetItem.current;
        budgetItemCopy[objIndex].pacosBrand = newBudgetItem.pacosBrand;
        budgetItemCopy[objIndex].pacosNonBrand = newBudgetItem.pacosNonBrand;
      } else {
        // Add new row
        budgetItemCopy.push({ ...newBudgetItem });
      }

      setBudgetItems(budgetItemCopy);
    }
  }, [dailySpend, adStatusOnCount, roasBrand, roasNonBrand]);

  const roasChange = (e) => {
    if (e.target.name === 'brandedRoas') {
      setRoasBrand(e.target.value)
    }
    if (e.target.name === 'nonBrandedRoas') {
      setRoasNonBrand(e.target.value)
    }
  }

  useEffect(() => {
    const ac = new AbortController();
    setBudgetLoad(true);
    setBudgetItems([{}]);
    setHasFreemiumRow(false);
    setDailySpend(1);
    setRoasBrand(4);
    setRoasBrand(3);

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    const budgetUrl = `${config.serverUrl}/accounts/walmart/budget?accountId=${sellerSelect.value}&platform=walmart`;

    fetch(budgetUrl, requestOptions, { signal: ac.signal })
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        if (!ac.signal.aborted) {
          // Find "Account Budget Group - Freemium"
          data.budgetGroupData.forEach(bgRow => {
            if (bgRow.name === 'Account Budget Group - Freemium') {
              setHasFreemiumRow(true);
              setDailySpend(bgRow.dailySpendPerItem || 1);
              if (bgRow.pacosBrand > 0) {
                setRoasBrand(1 / bgRow.pacosBrand);
              }
              if (bgRow.pacosNonBrand > 0) {
                setRoasNonBrand(1 / bgRow.pacosNonBrand);
              }
            }
          });

          setBudgetLoad(false);
          setBudgetItems(data.budgetGroupData);
          setMonthlyBudgetCurrent(data.monthlyBudgetCurrent || 0);
          setMonthlyBudgetCarryover(data.monthlyBudgetCarryover || 0);
          setMonthlyBudgetSupplemental(data.monthlyBudgetSupplemental || 0);
          setMonthlyPacos(data.monthlyPacos || 0);
          setMonthlyTacos(data.monthlyTacos || 0);
        }
      }).catch(() => {
        if (!ac.signal.aborted) {
          setBudgetLoad(false);
        }
      });
    return () => {
      ac.abort();
      setBudgetLoad(false);
      setBudgetItems([]);
      setMonthlyBudgetCurrent(0);
      setMonthlyBudgetCarryover(0);
      setMonthlyBudgetSupplemental(0);
      setMonthlyPacos(0);
      setMonthlyTacos(0);
    };
  }, [sellerSelect.value]);

  const submitBudget = () => {
    const budgetUrl = `${config.serverUrl}/accounts/budget`;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const requestOptions = {
      method: 'POST',
      headers: headerWithAuth,
      body: JSON.stringify({
        accountId: sellerSelect.value,
        monthlyBudgetCurrent,
        monthlyBudgetCarryover,
        monthlyBudgetSupplemental,
        monthlyPacos,
        monthlyTacos,
        currency: (sellerSelect.currencyCode || 'USD'),
        budgetItems,
        retailer: 'walmart',
      }),
    };

    setBudgetLoad(true);

    fetch(budgetUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then(() => {
        Notify.show('primary', 'Success', 'Walmart Budgets have been updated!');
        setBudgetLoad(false);
      }).catch(() => {
        Notify.show('danger', 'Error', 'We were unable to update your Walmart budgets.');
        setBudgetLoad(false);
      });
  };

  const monthlyBudget = moment().daysInMonth() * adStatusOnCount * dailySpend;

  return (
    <>
      <Col md={12} lg={6}>
        <CardFreemium
          title="Budget & Target Settings"
          subhead="Please choose the items you would like Limitless to advertise and turn them ON, then assign the Budget Group Setting below to finalize and complete the required inputs. Ads will be paused or enabled for any item based on status change selection and only for Limitless ads."
          freemiumClass="performance"
          icon="cog"
          parentRefresh={budgetLoad}
        >
          <br />
          <div>
            <div style={{ display: 'inline-block', width: '50%', padding: '5px' }}>
              <button type="button" className="btn btn-primary" onClick={() => submitBudget()}>Update Budget Settings</button>
            </div>
            <div style={{ display: 'inline-block', width: '50%', padding: '5px' }}>
              <span className="form__form-group-note">Not sure? <br /> Start with $1/day and ROAS targets of 2 - 4 on known top sellers or your entire catalog</span>
            </div>
          </div>
          <br />
          <div className="form__form-group-input-wrap">
            <div style={{ display: 'inline-block', width: '50%' }}>
              <h4> Budget Daily Spend/Item:</h4>
            </div>
            <div style={{ display: 'inline-block', width: '50%' }}>
              <input value={dailySpend} type="text" onChange={(e) => setDailySpend(e.target.value)} disabled={false} style={{ width: '50%', height: '30px', fontSize: '20px', paddingLeft: '20px' }} />
              <span style={{ position: 'absolute', display: 'inline-block', left: '50.5%', paddingTop: '5px', zIndex: '9' }}>$</span>
            </div>
            {<span className="form__form-group-note">Whole dollar amount greater than zero</span>}
          </div>
          <br />
          <br />
          <div className="form__form-group-input-wrap">
            <div style={{ display: 'inline-block', width: '50%' }}>
              <h4>Brand ROAS Target:</h4>
            </div>
            <div style={{ display: 'inline-block', width: '50%' }}>
              <select name="brandedRoas" onChange={(e) => roasChange(e)} className="select-options" style={{ width: '50%', height: '30px', fontSize: '20px' }}>
                <option value="1.0" selected={Math.trunc(roasBrand) === 2 ? 'selected' : ''}>1.0</option>
                <option value="2.0" selected={Math.trunc(roasBrand) === 2 ? 'selected' : ''}>2.0</option>
                <option value="3.0" selected={Math.trunc(roasBrand) === 3 ? 'selected' : ''}>3.0</option>
                <option value="4.0" selected={Math.trunc(roasBrand) === 4 ? 'selected' : ''}>4.0</option>
                <option value="5.0" selected={Math.trunc(roasBrand) === 5 ? 'selected' : ''}>5.0</option>
                <option value="6.0" selected={Math.trunc(roasBrand) === 6 ? 'selected' : ''}>6.0</option>
                <option value="7.0" selected={Math.trunc(roasBrand) === 7 ? 'selected' : ''}>7.0</option>
                <option value="8.0" selected={Math.trunc(roasBrand) === 8 ? 'selected' : ''}>8.0</option>
                <option value="9.0" selected={Math.trunc(roasBrand) === 9 ? 'selected' : ''}>9.0</option>
                <option value="10.0" selected={Math.trunc(roasBrand) === 10 ? 'selected' : ''}>10.0</option>
              </select>
            </div>
            {<span className="form__form-group-note">Number between 1 - 10</span>}
          </div>
          <br />
          <br />
          <div className="form__form-group-input-wrap">
            <div style={{ display: 'inline-block', width: '50%' }}>
              <h4>NonBrand ROAS Target:</h4>
            </div>
            <div style={{ display: 'inline-block', width: '50%' }}>
              <select name="nonBrandedRoas" onChange={(e) => roasChange(e)} className="select-options" style={{ width: '50%', height: '30px', fontSize: '20px' }}>
                <option value="1.0" selected={Math.trunc(roasNonBrand) === 2 ? 'selected' : ''}>1.0</option>
                <option value="2.0" selected={Math.trunc(roasNonBrand) === 2 ? 'selected' : ''}>2.0</option>
                <option value="3.0" selected={Math.trunc(roasNonBrand) === 3 ? 'selected' : ''}>3.0</option>
                <option value="4.0" selected={Math.trunc(roasNonBrand) === 4 ? 'selected' : ''}>4.0</option>
                <option value="5.0" selected={Math.trunc(roasNonBrand) === 5 ? 'selected' : ''}>5.0</option>
                <option value="6.0" selected={Math.trunc(roasNonBrand) === 6 ? 'selected' : ''}>6.0</option>
                <option value="7.0" selected={Math.trunc(roasNonBrand) === 7 ? 'selected' : ''}>7.0</option>
                <option value="8.0" selected={Math.trunc(roasNonBrand) === 8 ? 'selected' : ''}>8.0</option>
                <option value="9.0" selected={Math.trunc(roasNonBrand) === 9 ? 'selected' : ''}>9.0</option>
                <option value="10.0" selected={Math.trunc(roasNonBrand) === 10 ? 'selected' : ''}>10.0</option>
              </select>
            </div>
            {<span className="form__form-group-note">Number between 1 - 10</span>}
          </div>
        </CardFreemium>
      </Col>
      <Col md={12} lg={6}>
        <CardFreemium
          title="Budget & Item Target Summary"
          freemiumClass="performance"
          icon="bullhorn"
          parentRefresh={isLoading || budgetLoad}
        >
          <br />
          <div style={{ width: '100%' }}>
            <div style={{ display: 'inline-block', width: '60%' }}>
              <p>{(adStatusOnCount > 0 && (<span className="lnr lnr-checkmark-circle freemium__green-icon-text" />)) || (<span className="lnr lnr-circle-minus freemium__red-icon-text" />)}&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ verticalAlign: 'middle', fontSize: '2vmin' }}>Current Number of Items ON:</span></p>
            </div>
            <div style={{ display: 'inline-block', width: '40%' }}>
              <p><span style={{ verticalAlign: 'middle', fontSize: '2vmin' }}>{adStatusOnCount}</span></p>
            </div>
          </div>
          <br />
          <div style={{ width: '100%' }}>
            <div style={{ display: 'inline-block', width: '60%' }}>
              <p>{(monthlyBudget > 0 && (<span className="lnr lnr-checkmark-circle freemium__green-icon-text" />)) || (<span className="lnr lnr-circle-minus freemium__red-icon-text" />)}&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ verticalAlign: 'middle', fontSize: '2vmin' }}>Total Estimated Monthly Budget:</span></p>
            </div>
            <div style={{ display: 'inline-block', width: '40%' }}>
              <p><span style={{ verticalAlign: 'middle', fontSize: '2vmin' }}>${monthlyBudget}</span></p>
            </div>
          </div>
          <br />
          <div style={{ width: '100%' }}>
            <div style={{ display: 'inline-block', width: '60%' }}>
              <p>{(monthlyBudget > 0 && (<span className="lnr lnr-checkmark-circle freemium__green-icon-text" />)) || (<span className="lnr lnr-circle-minus freemium__red-icon-text" />)}&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ verticalAlign: 'middle', fontSize: '2vmin' }}>Current Daily Budget Consumption:</span></p>
            </div>
            <div style={{ display: 'inline-block', width: '40%' }}>
              <p><span style={{ verticalAlign: 'middle', fontSize: '2vmin' }}>{(Math.trunc(((usedBudget || 0) / monthlyBudget) * 100))}%</span></p>
            </div>
          </div>
          <br />
          <div style={{ width: '100%' }}>
            <div style={{ display: 'inline-block', width: '60%' }}>
              <p>{(hasFreemiumRow > 0 && (<span className="lnr lnr-checkmark-circle freemium__green-icon-text" />)) || (<span className="lnr lnr-circle-minus freemium__red-icon-text" />)}&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ verticalAlign: 'middle', fontSize: '2vmin' }}>Budget has been configured:</span></p>
            </div>
            <div style={{ display: 'inline-block', width: '40%' }}>
              <p><span style={{ verticalAlign: 'middle', fontSize: '2vmin' }}>Setup {(hasFreemiumRow && 'Complete') || 'Pending'}</span></p>
            </div>
          </div>
        </CardFreemium>
      </Col>
    </>
  )
}

export default WmBudgetMgr;
