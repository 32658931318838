import React from 'react';
import PlanEstimator from './Plan/components/PlanEstimator';
import { isAdRev } from '../../../shared/components/domainSupport';

export default function SubscriptionEstimate() {
  if (isAdRev()) {
    return (
      <PlanEstimator />
    );
  }
  return (<span>Page not available</span>);
}
