import {
  useState, useEffect,
} from 'react';
import { when } from '../../../../shared/functions';
import usePlacementsByDates from '../../Walmart/hooks/usePlacementsByDates';
import useItemTopPerformanceByDates from '../../Walmart/hooks/useItemTopPerformanceByDates';
import calcPpcTotalsByPlacement from './calcPpcTotalsByPlacement';

export default function useVendorData(accountSelect, startSelectionDate, endSelectionDate, dateView) {
  const [placementLoading, placementResults, placementError] = usePlacementsByDates(accountSelect, startSelectionDate, endSelectionDate, dateView);
  const [topItemOrdersLoading, topItemOrdersResults, topItemOrdersError] = useItemTopPerformanceByDates(accountSelect, startSelectionDate, endSelectionDate, 'orders', 5);
  const [topItemSalesLoading, topItemSalesResults, topItemSalesError] = useItemTopPerformanceByDates(accountSelect, startSelectionDate, endSelectionDate, 'sales', 5);
  const loading = placementLoading || topItemOrdersLoading || topItemSalesLoading;
  const [results, setResults] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const pieData = calcPpcTotalsByPlacement(placementResults);

    setResults({
      ...results,
      data: placementResults,
      pieData,
      topItemOrders: topItemOrdersResults,
      topItemSales: topItemSalesResults,
    });

    return (() => setResults({
      ...results,
      data: null,
      pieData: null,
      topItemOrders: null,
      topItemSales: null,
    }));
  }, [placementResults, topItemOrdersResults, topItemSalesResults, setResults]);

  useEffect(() => {
    when(placementError, () => {
      setError(placementError);
    });
  }, [placementError, setError]);

  useEffect(() => {
    when(topItemOrdersError, () => {
      setError(topItemOrdersError);
    });
  }, [topItemOrdersError, setError]);

  useEffect(() => {
    when(topItemSalesError, () => {
      setError(topItemSalesError);
    });
  }, [topItemSalesError, setError]);

  return [loading, results, error];
}
