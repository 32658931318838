import React from 'react';
import { Container, Row, Col } from 'reactstrap';

export default function VendorReportMessage() {
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <p>This is a Vendor specific report. Please select a new report or select a Vendor account.</p>
        </Col>
      </Row>
    </Container>
  );
}
