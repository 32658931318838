import React from 'react';
import LogInForm from './components/LogInForm';
import { LoginHeader, setFavicon } from '../../../shared/components/domainSupport';

const LogIn = () => (
  <div className="account">
    <div className="account__wrapper">
      <div className="account__card">
        {setFavicon()}
        <LoginHeader />
        <LogInForm onSubmit />
      </div>
    </div>
  </div>
);

export default LogIn;

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
