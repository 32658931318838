import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import ProfitReport from './components/ProfitReport';
import { SellerSelect } from '../../../shared/prop-types/MainProps';

const ProfitReportPage = ({ sellerSelect }) => {
  if (sellerSelect.type === 1) {
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">Profit Report</h3>
          </Col>
        </Row>
        <Row>
          <ProfitReport />
        </Row>
      </Container>
    );
  }
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <p>This is a Seller specific report. Please select a new report or select a Seller account.</p>
        </Col>
      </Row>
    </Container>
  );
};

ProfitReportPage.propTypes = {
  sellerSelect: SellerSelect.isRequired,
};

const mapStateToProps = (state) => {
  const { sellerSelect } = state;

  return {
    sellerSelect,
  };
};

export default connect(mapStateToProps)(ProfitReportPage);
