import React from 'react';
import { Container, Row } from 'reactstrap';
import config from '../../../../../config';
import TableBuilder from '../../../../../shared/components/table/TableBuilder';

export default function AcountStatusAll() {
  const colorChange = (params) => {
    if (params.value !== '') {
      const textColor = params.value === 'yellow' ? 'black' : 'white';
      return { color: textColor, backgroundColor: params.value, textAlign: 'center' };
    }
    return null;
  };

  const columnDefs = [
    { field: 'accountId', headerName: 'Account ID' },
    { field: 'name', headerName: 'Name' },
    { field: 'status', headerName: 'Status' },
    {
      headerName: 'Amazon',
      children: [
        { field: 'accountType', headerName: 'Account Type' },
        { field: 'region', headerName: 'Region' },
        { field: 'activeAmzAdApi', headerName: 'AMZ AD-API', cellStyle: (params) => colorChange(params) },
        { field: 'activeAmzSpApi', headerName: 'AMZ SP-API', cellStyle: (params) => colorChange(params) },
      ],
    },
    {
      headerName: 'Walmart',
      children: [
        { field: 'wmAdvertiserId', headerName: 'Advertiser ID' },
        { field: 'wmSellerId', headerName: 'Seller ID' },
        { field: 'activeWmAdApi', headerName: 'WM AD-API', cellStyle: (params) => colorChange(params) },
        { field: 'activeWmMpApi', headerName: 'WM MP-API', cellStyle: (params) => colorChange(params) },
        { field: 'activeWmSuApi', headerName: 'WM SU-API', cellStyle: (params) => colorChange(params) },
      ],
    },
    {
      headerName: 'Google',
      children: [
        { field: 'googleCustomerId', headerName: 'Google Customer ID' },
        { field: 'googleLimitlessManagerAccountId', headerName: 'Limitless Manager Account ID' },
      ],
    },

  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };

  const apiEndPoint = `${config.serverUrl}/accounts/api-status/all-active`;

  return (
    <Container>
      <Row>
        <TableBuilder
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          apiEndPoint={apiEndPoint}
          showDatePicker={false}
        />
      </Row>
    </Container>
  );
}
