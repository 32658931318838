import React, {
  useState, useEffect, useMemo, useRef, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { AgGridReact } from 'ag-grid-react';
import { ToggleWrapOuter, ToggleSlide } from './BudgetTableCss';
import Notify from '../../../../shared/components/widgets/Notify';
import config from '../../../../config';
import Panel from '../../../../shared/components/Panel';
// import Slider from '../../../../shared/components/range-slider/Slider';
import {
  currencyFormatter, formatDecimalToFixedTwoPercent, formatNumberOnePlaceDefaultToZero, formatNumberDefaultToZero, // formatDecimalToFixedTwoPercentDirect,
} from '../../../../shared/components/table/functions';
import { getPlatform } from '../../../../shared/components/domainSupport';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

export default function BudgetTable({
  allById, internalById, allByDate, internalByDate, retailer, title, allGraphLoad, internalGraphLoad, gridTheme, reportDays,
}) {
  const gridRef = useRef();
  const sellerSelect = useSelector(store => store.sellerSelect);
  const user = useSelector(store => store.authentication.user);
  const [combinedItems, setCombinedItems] = useState([]);
  const [budgetItems, setBudgetItems] = useState([]);
  const [panelLoad, setPanelLoad] = useState(false);
  const [monthlyBudgetCurrent, setMonthlyBudgetCurrent] = useState(0);
  const [monthlyBudgetCarryover, setMonthlyBudgetCarryover] = useState(0);
  const [monthlyBudgetSupplemental, setMonthlyBudgetSupplemental] = useState(0);
  const [monthlyPacos, setMonthlyPacos] = useState(0);
  const [monthlyTacos, setMonthlyTacos] = useState(0);
  const [hideNonEssentialBudget, setHideNonEssentialBudget] = useState(true);
  const [hideNonEssentialCostTargets] = useState(true);
  const [hideNonEssentialSpendControls, setHideNonEssentialSpendControls] = useState(false);
  const [hideNonEssentialMetrics, setHideNonEssentialMetrics] = useState(true);
  const [hideNonEssentialAdvancedMetrics, setHideNonEssentialAdvancedMetrics] = useState(false);
  const [hideNonEssentialSparklines, setHideNonEssentialSparklines] = useState(true);
  const [pacosBrandAvg, setPacosBrandAvg] = useState(true);
  const [pacosNonBrandAvg, setPacosNonBrandAvg] = useState(true);

  const platfromSpec = getPlatform();
  const unassignedText = '*UNASSIGNED*';

  const getData = () => {
    let retailerPath = '';
    if (retailer.toLowerCase() !== 'amazon') {
      retailerPath = `${retailer.toLowerCase()}/`;
    }
    const budgetUrl = `${apiUrl}/accounts/${retailerPath}budget?accountId=${sellerSelect.value}&platform=${platfromSpec.nameKey}`;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    setPanelLoad(true);
    setBudgetItems([]);

    fetch(budgetUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        setPanelLoad(false);
        setBudgetItems(data.budgetGroupData);
        setMonthlyBudgetCurrent(data.monthlyBudgetCurrent || 0);
        setMonthlyBudgetCarryover(data.monthlyBudgetCarryover || 0);
        setMonthlyBudgetSupplemental(data.monthlyBudgetSupplemental || 0);
        setMonthlyPacos(data.monthlyPacos || 0);
        setMonthlyTacos(data.monthlyTacos || 0);
      }).catch(() => {
        setPanelLoad(false);
      });
  };

  const submitBudget = () => {
    const budgetUrl = `${apiUrl}/accounts/budget`;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const requestOptions = {
      method: 'POST',
      headers: headerWithAuth,
      body: JSON.stringify({
        accountId: sellerSelect.value,
        monthlyBudgetCurrent,
        monthlyBudgetCarryover,
        monthlyBudgetSupplemental,
        monthlyPacos,
        monthlyTacos,
        currency: (sellerSelect.currencyCode || 'USD'),
        budgetItems,
        retailer,
      }),
    };

    setPanelLoad(true);

    fetch(budgetUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then(() => {
        Notify.show('primary', 'Success', `${retailer} Budgets have been updated!`);
        setPanelLoad(false);
      }).catch(() => {
        Notify.show('danger', 'Error', `We were unable to update your ${retailer} budgets.`);
        setPanelLoad(false);
      });
  };

  useEffect(() => {
    getData();
  }, [sellerSelect]);

  useEffect(() => {
    const newMoPacos = ((pacosNonBrandAvg / 100) + (pacosBrandAvg / 100)) / 2;
    setMonthlyPacos(newMoPacos || 0);
  }, [pacosBrandAvg, pacosNonBrandAvg]);

  useEffect(() => {
    if (allById && internalById && allByDate && internalByDate) {
      const newBudgetItems = [];
      const getName = (currentName) => {
        if (currentName === '') {
          return unassignedText;
        }
        return currentName;
      }
      budgetItems.forEach(budgetItemRow => {
        if (budgetItemRow.name || budgetItemRow.name === '') {
          const allSpendChange = [];
          const allSalesChange = [];
          const allAcosChange = [];
          const internalSpendChange = [];
          const internalSalesChange = [];
          const internalAcosChange = [];
          // Build Change Sparkline
          allByDate?.forEach(allDate => {
            allSpendChange.push(allDate[`spend_${budgetItemRow.name}`]);
            allSalesChange.push(allDate[`sales_${budgetItemRow.name}`]);
            allAcosChange.push((allDate[`spend_${budgetItemRow.name}`] && allDate[`sales_${budgetItemRow.name}`] && allDate[`sales_${budgetItemRow.name}`] > 0) ? (allDate[`spend_${budgetItemRow.name}`] / allDate[`sales_${budgetItemRow.name}`]) : null);
          });
          internalByDate?.forEach(internalDate => {
            internalSpendChange.push(internalDate[`spend_${budgetItemRow.name}`]);
            internalSalesChange.push(internalDate[`sales_${budgetItemRow.name}`]);
            internalAcosChange.push((internalDate[`spend_${budgetItemRow.name}`] && internalDate[`sales_${budgetItemRow.name}`] && internalDate[`sales_${budgetItemRow.name}`] > 0) ? (internalDate[`spend_${budgetItemRow.name}`] / internalDate[`sales_${budgetItemRow.name}`]) : null);
          });
          const allMetricRow = allById.group[budgetItemRow.name];
          const internalMetricRow = internalById.group[budgetItemRow.name];
          newBudgetItems.push({ ...budgetItemRow, name: getName(budgetItemRow.name), ...allMetricRow, ...internalMetricRow, allSpendChange, allSalesChange, allAcosChange, internalSpendChange, internalSalesChange, internalAcosChange });
        }
      });
      setCombinedItems(newBudgetItems);
    }
  }, [allById, internalById, allByDate, internalByDate, budgetItems]);

  const onGridReady = useCallback(() => {
    gridRef.current.api.sizeColumnsToFit({
      defaultMinWidth: 100,
    });
  }, []);

  const updateBudgetGroupDropdown = (selectedOption, e, itemName) => {
    const budgetItemsUpdates = [...budgetItems]; // clone array
    const { name: groupName } = e;

    const objIndex = budgetItemsUpdates.findIndex((obj => obj.name === groupName));

    budgetItemsUpdates[objIndex][itemName] = selectedOption.value;
    setBudgetItems(budgetItemsUpdates);
  }

  const updateBudgetGroup = (e) => {
    const budgetItemsUpdates = [...budgetItems]; // clone array
    const { name, value, dataset } = e.target;
    const { groupName } = dataset;
    const objIndex = budgetItemsUpdates.findIndex((obj => obj.name === groupName));
    const itemName = name;
    const regex = name === 'portfolioIdsCsv' ? /[^\-0-9.,]/g : /[^\-0-9.]/g;
    budgetItemsUpdates[objIndex][itemName] = ['pacos', 'tacos', 'pacosBrand', 'pacosNonBrand'].includes(name) ? (value.replace(regex, '') / 100) : value.replace(regex, '');
    setBudgetItems(budgetItemsUpdates);
  }

  const limitlessBudgetOptions = [
    { label: 'Strict Budget', value: 0 },
    { label: 'No Budget', value: 1 },
    { label: 'Flexible Budget', value: 2 },
  ];

  const includesOptions = [
    { label: 'All ADs', value: '' },
    { label: 'Limitless ADs', value: 'limitless' },
  ];

  const spendPriorityOptions = [
    { label: 'Most Brand', value: '-2' },
    { label: 'More Brand', value: '-1' },
    { label: 'Brand and NonBrand', value: '0' },
    { label: 'More NonBrand', value: '1' },
    { label: 'Most NonBrand', value: '2' },
  ];

  const spendTacticOptions = [
    { label: 'Max Performance', value: 'max_perf' },
    { label: 'Full Budget Spend', value: 'full_spend' },
  ];

  const spendSpeedOptions = [
    { label: 'Standard', value: 'speed_level_1' },
    { label: 'Fast', value: 'speed_level_2' },
    { label: 'Fastest', value: 'speed_level_3' },
  ];

  const findOption = (value, options) => {
    const foundObject = options.find(item => item.value === value);
    if (foundObject) {
      return foundObject;
    }
    return options[0];
  };

  function extractValues(mappings) {
    return mappings.map(map => map.value);
  }

  function lookupValue(mappings, valueKey) {
    return mappings.find(map => map.value === valueKey);
  }

  const aggFuncs = useMemo(() => {
    const aggFuncsReturn = {
      customAvg: params => {
        let count = 0;
        let total = 0;
        params.values.forEach(value => {
          if (value > 0) {
            count += 1;
            total += value;
          }
        });
        const returnValue = count > 0 ? (total / count) : 0;
        return returnValue.toFixed(1);
      },
    };
    return aggFuncsReturn;
  }, []);

  // AG Grid variables
  /* consider these as well (for reference)
    sortable: true,
    comparator: (valueA, valueB) => compareNumber(valueA, valueB),
    resizable: true,
  */
  const columnDefs = [
    {
      field: 'name',
      headerName: 'Budget Group Item',
      editable: false,
      valueFormatter: (params) => {
        if (params.node?.footer) {
          return 'TOTAL/AVG';
        }
        return params.value;
      },
    },
    {
      headerName: 'Monthly Budget',
      children: [
        {
          field: 'current',
          headerName: 'Group Current',
          // setMonthlyBudgetCurrent
          aggFunc: 'sum',
          editable: (params) => ![unassignedText].includes(params.data.name),
          valueFormatter: (params) => {
            if (params.node?.footer) {
              if (params.node && params.node.aggData) {
                setMonthlyBudgetCurrent((params.node.aggData?.current || 0) + (params.node.aggData?.reallocation || 0));
              }
            }
            return currencyFormatter(params);
          },
          valueGetter: (params) => parseFloat(params.data?.current || 0),
          valueSetter: params => {
            updateBudgetGroup({ target: { name: 'current', value: params.newValue.toString(), dataset: { groupName: params.data.name } } });
            return true;
          },
        },
        {
          field: 'reallocation',
          headerName: 'BG Reallocation (+/-)',
          aggFunc: 'sum',
          hide: !hideNonEssentialBudget,
          editable: (params) => ![unassignedText].includes(params.data.name),
          valueFormatter: currencyFormatter,
          valueGetter: (params) => parseFloat(params.data?.reallocation || 0),
          valueSetter: params => {
            updateBudgetGroup({ target: { name: 'reallocation', value: params.newValue.toString(), dataset: { groupName: params.data.name } } });
            return true;
          },
        },
        {
          field: 'carryover',
          headerName: 'BG Carryover (+)',
          aggFunc: 'sum',
          hide: true,
          valueFormatter: (params) => {
            if (params.node?.footer) {
              setMonthlyBudgetCarryover(params.value);
            }
            return currencyFormatter(params);
          },
          valueGetter: (params) => parseFloat(params.data?.carryover || 0),
          valueSetter: params => {
            updateBudgetGroup({ target: { name: 'carryover', value: params.newValue.toString(), dataset: { groupName: params.data.name } } });
            return true;
          },
        },
        {
          field: 'supplemental',
          headerName: 'BG Supplemental (+)',
          aggFunc: 'sum',
          hide: !hideNonEssentialBudget,
          editable: (params) => ![unassignedText].includes(params.data.name),
          valueFormatter: (params) => {
            if (params.node?.footer) {
              setMonthlyBudgetSupplemental(params.value);
            }
            return currencyFormatter(params);
          },
          valueGetter: (params) => parseFloat(params.data?.supplemental || 0),
          valueSetter: params => {
            updateBudgetGroup({ target: { name: 'supplemental', value: params.newValue.toString(), dataset: { groupName: params.data.name } } });
            return true;
          },
        },
      ],
    },
    {
      headerName: 'Cost Targets',
      children: [
        {
          field: 'pacos',
          headerName: 'BG PACoS Target',
          aggFunc: 'customAvg',
          editable: (params) => ![unassignedText].includes(params.data.name),
          valueFormatter: params => params.value.toString().concat('%'),
          valueGetter: params => (params.data?.pacos || 0) * 100,
          valueSetter: params => {
            updateBudgetGroup({ target: { name: 'pacos', value: params.newValue.toString(), dataset: { groupName: params.data.name } } });
            return true;
          },
        },
        {
          field: 'pacosBrand',
          headerName: 'BG Brand PACoS',
          aggFunc: 'customAvg',
          editable: (params) => ![unassignedText].includes(params.data.name),
          valueFormatter: (params) => {
            if (params.node?.footer) {
              setPacosBrandAvg(params.value);
            }
            return params.value.toString().concat('%');
          },
          valueGetter: params => (params.data?.pacosBrand || 0) * 100,
          valueSetter: params => {
            updateBudgetGroup({ target: { name: 'pacosBrand', value: params.newValue.toString(), dataset: { groupName: params.data.name } } });
            return true;
          },
        },
        {
          field: 'pacosNonBrand',
          headerName: 'BG NonBrand PACoS',
          aggFunc: 'customAvg',
          editable: (params) => ![unassignedText].includes(params.data.name),
          valueFormatter: (params) => {
            if (params.node?.footer) {
              setPacosNonBrandAvg(params.value);
            }
            return params.value.toString().concat('%');
          },
          valueGetter: params => (params.data?.pacosNonBrand || 0) * 100,
          valueSetter: params => {
            updateBudgetGroup({ target: { name: 'pacosNonBrand', value: params.newValue.toString(), dataset: { groupName: params.data.name } } });
            return true;
          },
        },
        {
          field: 'tacos',
          headerName: 'BG TACoS',
          aggFunc: 'customAvg',
          hide: hideNonEssentialCostTargets,
          editable: (params) => ![unassignedText].includes(params.data.name),
          valueFormatter: (params) => {
            if (params.node?.footer) {
              setMonthlyTacos((params.value / 100));
            }
            return params.value.toString().concat('%');
          },
          valueGetter: params => (params.data?.tacos || 0) * 100,
          valueSetter: params => {
            updateBudgetGroup({ target: { name: 'tacos', value: params.newValue.toString(), dataset: { groupName: params.data.name } } });
            return true;
          },
        },
      ],
    },
    {
      headerName: 'Spend Controls',
      children: [
        {
          field: 'portfolioIdsCsv',
          headerName: 'Portfolio ID',
          hide: !hideNonEssentialSpendControls,
          editable: (params) => !['Sponsored Brand', 'Sponsored Brand Video', 'Sponsored Television', unassignedText].includes(params.data.name),
          valueGetter: params => (params.data?.portfolioIdsCsv || '').replace(/[^\-0-9.,]/g, ''),
          valueSetter: params => {
            updateBudgetGroup({ target: { name: 'portfolioIdsCsv', value: params.newValue.toString(), dataset: { groupName: params.data.name } } });
            return true;
          },
        },
        {
          field: 'limitlessBudgetFlag',
          headerName: 'Budget Type',
          cellEditor: 'agSelectCellEditor',
          editable: (params) => !['Sponsored Brand', 'Sponsored Brand Video', 'Sponsored Television', unassignedText].includes(params.data.name),
          cellEditorParams: {
            values: extractValues(limitlessBudgetOptions), // 0, 1
            useFormatter: true,
          },
          valueFormatter: (params) => {
            if (params.node?.footer) {
              return '';
            }
            const dispalyObj = lookupValue(limitlessBudgetOptions, params.value || 0);
            return dispalyObj?.label || '';
          },
          valueGetter: params => {
            if (params.node?.footer) {
              return '';
            }
            return (params.data?.limitlessBudgetFlag || 0);
          },
          valueSetter: params => {
            updateBudgetGroupDropdown(findOption((params.newValue), limitlessBudgetOptions), { name: params.data.name }, 'limitlessBudgetFlag');
            return true;
          },
        },
        {
          field: 'includes',
          headerName: 'Includes Ads',
          hide: !hideNonEssentialSpendControls,
          editable: (params) => !['Sponsored Brand', 'Sponsored Brand Video', 'Sponsored Television', unassignedText].includes(params.data.name),
          cellEditor: 'agSelectCellEditor',
          cellEditorParams: {
            values: extractValues(includesOptions), // '', 'limitless'
            useFormatter: true,
          },
          valueFormatter: (params) => {
            if (params.node?.footer) {
              return '';
            }
            const dispalyObj = lookupValue(includesOptions, params.value);
            return dispalyObj?.label || '';
          },
          valueGetter: params => {
            if (params.node?.footer) {
              return '';
            }
            return (params.data?.includes || '');
          },
          valueSetter: params => {
            updateBudgetGroupDropdown(findOption((params.newValue), includesOptions), { name: params.data.name }, 'includes');
            return true;
          },
        },
        {
          field: 'spendPriority',
          headerName: 'Spend Priority',
          hide: !hideNonEssentialSpendControls,
          editable: (params) => !['Sponsored Brand', 'Sponsored Brand Video', 'Sponsored Television', unassignedText].includes(params.data.name),
          cellEditor: 'agSelectCellEditor',
          cellEditorParams: {
            values: extractValues(spendPriorityOptions),
            useFormatter: true,
          },
          valueFormatter: (params) => {
            if (params.node?.footer) {
              return '';
            }
            const dispalyObj = lookupValue(spendPriorityOptions, params.value || '0');
            return dispalyObj?.label || '';
          },
          valueGetter: params => {
            if (params.node?.footer) {
              return '';
            }
            return (params.data?.spendPriority || '0');
          },
          valueSetter: params => {
            updateBudgetGroupDropdown(findOption(params.newValue.toString(), spendPriorityOptions), { name: params.data.name }, 'spendPriority');
            return true;
          },
        },
        {
          field: 'spendTactic',
          headerName: 'Spend Tactic',
          hide: !hideNonEssentialSpendControls,
          editable: (params) => !['Sponsored Brand', 'Sponsored Brand Video', 'Sponsored Television', unassignedText].includes(params.data.name),
          cellEditor: 'agSelectCellEditor',
          cellEditorParams: {
            values: extractValues(spendTacticOptions),
            useFormatter: true,
          },
          valueFormatter: (params) => {
            if (params.node?.footer) {
              return '';
            }
            const dispalyObj = lookupValue(spendTacticOptions, params.value || 'max_perf');
            return dispalyObj?.label || '';
          },
          valueGetter: params => {
            if (params.node?.footer) {
              return '';
            }
            return (params.data?.spendTactic || 'max_perf');
          },
          valueSetter: params => {
            updateBudgetGroupDropdown(findOption((params.newValue), spendTacticOptions), { name: params.data.name }, 'spendTactic');
            return true;
          },
        },
        {
          field: 'spendSpeed',
          headerName: 'Spend Speed',
          hide: !hideNonEssentialSpendControls,
          editable: (params) => !['Sponsored Brand', 'Sponsored Brand Video', 'Sponsored Television', unassignedText].includes(params.data.name),
          cellEditor: 'agSelectCellEditor',
          cellEditorParams: {
            values: extractValues(spendSpeedOptions),
            useFormatter: true,
          },
          valueFormatter: (params) => {
            if (params.node?.footer) {
              return '';
            }
            const dispalyObj = lookupValue(spendSpeedOptions, params.value || 'speed_level_1');
            return dispalyObj?.label || '';
          },
          valueGetter: params => {
            if (params.node?.footer) {
              return '';
            }
            return (params.data?.spendSpeed || 'speed_level_1');
          },
          valueSetter: params => {
            updateBudgetGroupDropdown(findOption((params.newValue), spendSpeedOptions), { name: params.data.name }, 'spendSpeed');
            return true;
          },
        },
      ],
    },
    {
      headerName: 'Metrics',
      children: [
        {
          field: 'internal_spend',
          headerName: 'MTD Limitless PPC Spend',
          hide: !hideNonEssentialMetrics,
          aggFunc: 'sum',
          editable: false,
          valueFormatter: currencyFormatter,
        },
        {
          field: 'internal_sales',
          headerName: 'MTD Limitless PPC Sales',
          hide: !hideNonEssentialMetrics,
          aggFunc: 'sum',
          editable: false,
          valueFormatter: currencyFormatter,
        },
        {
          field: '',
          headerName: 'MTD Limitless PPC ACoS',
          hide: !hideNonEssentialMetrics,
          editable: false,
          aggFunc: (params) => {
            if (params && params.rowNode && params.rowNode.aggData && params.rowNode.aggData.internal_spend && params.rowNode.aggData.internal_sales && params.rowNode.aggData.internal_sales !== 0) {
              return params.rowNode.aggData.internal_spend / params.rowNode.aggData.internal_sales;
            }
            return 0;
          },
          valueFormatter: formatDecimalToFixedTwoPercent,
          valueGetter: function calcValueGetter(params) {
            if (params && params.data && params.data.internal_spend && params.data.internal_sales && params.data.internal_sales !== 0) {
              return params.data.internal_spend / params.data.internal_sales;
            }
            return 0;
          },
          headerTooltip: 'MTD Limitless PPC ACoS',
        },
        {
          field: '',
          headerName: 'MTD Limitless PPC ROAS',
          hide: !hideNonEssentialMetrics,
          editable: false,
          aggFunc: (params) => {
            if (params && params.rowNode && params.rowNode.aggData && params.rowNode.aggData.internal_spend && params.rowNode.aggData.internal_sales && params.rowNode.aggData.internal_spend !== 0) {
              return params.rowNode.aggData.internal_sales / params.rowNode.aggData.internal_spend;
            }
            return 0;
          },
          valueFormatter: formatNumberOnePlaceDefaultToZero,
          valueGetter: function calcValueGetter(params) {
            if (params && params.data && params.data.internal_spend && params.data.internal_sales && params.data.internal_spend !== 0) {
              return params.data.internal_sales / params.data.internal_spend;
            }
            return 0;
          },
          headerTooltip: 'MTD Limitless PPC ROAS',
        },
        {
          field: 'internal_orders',
          headerName: 'MTD Limitless Orders',
          hide: true,
          editable: false,
          aggFunc: 'sum',
          valueFormatter: formatNumberDefaultToZero,
        },
        {
          field: 'all_spend',
          headerName: 'MTD All PPC Spend',
          hide: !hideNonEssentialMetrics,
          editable: false,
          aggFunc: 'sum',
          valueFormatter: currencyFormatter,
        },
        {
          field: 'all_sales',
          headerName: 'MTD All PPC Sales',
          hide: !hideNonEssentialMetrics,
          editable: false,
          aggFunc: 'sum',
          valueFormatter: currencyFormatter,
        },
        {
          field: '',
          headerName: 'MTD All PPC ACoS',
          hide: !hideNonEssentialMetrics,
          editable: false,
          aggFunc: (params) => {
            if (params && params.rowNode && params.rowNode.aggData && params.rowNode.aggData.all_spend && params.rowNode.aggData.all_sales && params.rowNode.aggData.all_sales !== 0) {
              return params.rowNode.aggData.all_spend / params.rowNode.aggData.all_sales;
            }
            return 0;
          },
          valueFormatter: formatDecimalToFixedTwoPercent,
          valueGetter: function calcValueGetter(params) {
            if (params && params.data && params.data.all_spend && params.data.all_sales && params.data.all_sales !== 0) {
              return params.data.all_spend / params.data.all_sales;
            }
            return 0;
          },
          headerTooltip: 'MTD All PPC ACoS',
        },
        {
          field: '',
          headerName: 'MTD All PPC ROAS',
          hide: !hideNonEssentialMetrics,
          editable: false,
          aggFunc: (params) => {
            if (params && params.rowNode && params.rowNode.aggData && params.rowNode.aggData.all_spend && params.rowNode.aggData.all_sales && params.rowNode.aggData.all_spend !== 0) {
              return params.rowNode.aggData.all_sales / params.rowNode.aggData.all_spend;
            }
            return 0;
          },
          valueFormatter: formatNumberOnePlaceDefaultToZero,
          valueGetter: function calcValueGetter(params) {
            if (params && params.data && params.data.all_spend && params.data.all_sales && params.data.all_spend !== 0) {
              return params.data.all_sales / params.data.all_spend;
            }
            return 0;
          },
          headerTooltip: 'MTD All PPC ROAS',
        },
        {
          field: 'all_orders',
          headerName: 'MTD All Orders',
          hide: true,
          editable: false,
          aggFunc: 'sum',
          valueFormatter: formatNumberDefaultToZero,
        },
        {
          field: '',
          headerName: 'MTD Consumption Rate',
          hide: !hideNonEssentialMetrics,
          editable: false,
          valueFormatter: (params) => {
            if (params.node?.footer) {
              return '';
            }
            return formatDecimalToFixedTwoPercent(params);
          },
          valueGetter: function calcValueGetter(params) {
            if (params.node?.footer) {
              return '';
            }
            const totalBudget = (params.data.current || 0) + (params.data.reallocation || 0) + (params.data.carryover || 0) + (params.data.supplemental || 0);
            let calcBudget = totalBudget; // limitlessBudgetFlag === 0
            const daysInMonth = moment().daysInMonth();
            if (params.data.limitlessBudgetFlag === 1) {
              calcBudget = totalBudget * 10;
            }
            if (params.data.limitlessBudgetFlag === 2) {
              calcBudget = totalBudget * 1.25;
            }

            if (params && params.data && params.data.internal_spend && reportDays.internalReportDays !== 0 && calcBudget && calcBudget !== 0 && daysInMonth !== 0 && params.data.includes === 'limitless') {
              return (params.data.internal_spend / reportDays.internalReportDays) / (calcBudget / daysInMonth);
            }
            if (params && params.data && params.data.all_spend && reportDays.allReportDays !== 0 && calcBudget && calcBudget !== 0 && daysInMonth !== 0) {
              return (params.data.all_spend / reportDays.allReportDays) / (calcBudget / daysInMonth);
            }
            return 0;
          },
          headerTooltip: 'Allocation',
        },
        {
          field: 'itemOnCount',
          headerName: 'Items ON in BG',
          hide: !hideNonEssentialMetrics,
          editable: false,
          aggFunc: 'sum',
        },
        {
          field: 'calcBgDailyDollarPerItem',
          headerName: 'BG Daily $/Item',
          hide: !hideNonEssentialMetrics,
          editable: false,
          valueFormatter: (params) => {
            if (params.node?.footer) {
              return '';
            }
            return currencyFormatter(params);
          },
          valueGetter: function calcValueGetter(params) {
            const daysInMonth = moment().daysInMonth();
            if (params && params.data && params.data.itemOnCount && params.data.itemOnCount !== 0 && daysInMonth !== 0) {
              const totalBudget = (params.data.current || 0) + (params.data.reallocation || 0) + (params.data.carryover || 0) + (params.data.supplemental || 0);
              return (totalBudget / daysInMonth) / params.data.itemOnCount;
            }
            return 0;
          },
        },
        {
          field: 'calcLlMtdDailyDollarPerItem',
          headerName: 'LL MTD Daily $/Item',
          hide: !hideNonEssentialMetrics,
          editable: false,
          valueFormatter: (params) => {
            if (params.node?.footer) {
              return '';
            }
            return currencyFormatter(params);
          },
          valueGetter: function calcValueGetter(params) {
            if (params && params.data && params.data.internal_spend && params.data.itemOnCount && params.data.itemOnCount !== 0 && reportDays.internalReportDays !== 0) {
              return (params.data.internal_spend / reportDays.internalReportDays) / params.data.itemOnCount;
            }
            return 0;
          },
        },
        {
          field: 'calcAllMtdDailyDollarPerItem',
          headerName: 'All MTD Daily $/Item',
          hide: !hideNonEssentialMetrics,
          editable: false,
          valueFormatter: (params) => {
            if (params.node?.footer) {
              return '';
            }
            return currencyFormatter(params);
          },
          valueGetter: function calcValueGetter(params) {
            if (params && params.data && params.data.all_spend && params.data.itemOnCount && params.data.itemOnCount !== 0 && reportDays.internalReportDays !== 0) {
              return (params.data.all_spend / reportDays.internalReportDays) / params.data.itemOnCount;
            }
            return 0;
          },
        },
      ],
    },
    {
      headerName: 'Advanced Metrics',
      children: [
        {
          field: 'internal_advertised_sku_sales',
          headerName: 'MTD Limitless Advertised Sku Sales',
          hide: !hideNonEssentialAdvancedMetrics,
          aggFunc: 'sum',
          editable: false,
          valueFormatter: currencyFormatter,
        },
        {
          field: 'internal_brand_sales',
          headerName: 'MTD Limitless Brand Sales',
          hide: !hideNonEssentialAdvancedMetrics,
          aggFunc: 'sum',
          editable: false,
          valueFormatter: currencyFormatter,
        },
        {
          field: 'internal_direct_sales',
          headerName: 'MTD Limitless Direct Sales',
          hide: !hideNonEssentialAdvancedMetrics,
          aggFunc: 'sum',
          editable: false,
          valueFormatter: currencyFormatter,
        },
        {
          field: 'internal_related_sales',
          headerName: 'MTD Limitless Related Sales',
          hide: !hideNonEssentialAdvancedMetrics,
          aggFunc: 'sum',
          editable: false,
          valueFormatter: currencyFormatter,
        },
        {
          field: 'internal_ntb_orders',
          headerName: 'MTD Limitless NTB Orders',
          hide: true,
          editable: false,
          aggFunc: 'sum',
          valueFormatter: formatNumberDefaultToZero,
        },
        {
          field: 'internal_ntb_revenue',
          headerName: 'MTD Limitless NTB Revenue',
          hide: !hideNonEssentialAdvancedMetrics,
          aggFunc: 'sum',
          editable: false,
          valueFormatter: currencyFormatter,
        },
        {
          field: 'internal_other_sku_sales',
          headerName: 'MTD Limitless Other SKU Sales',
          hide: !hideNonEssentialAdvancedMetrics,
          aggFunc: 'sum',
          editable: false,
          valueFormatter: currencyFormatter,
        },
        {
          field: 'internal_in_store_orders',
          headerName: 'MTD Limitless In Store Orders',
          hide: true,
          editable: false,
          aggFunc: 'sum',
          valueFormatter: formatNumberDefaultToZero,
        },
        {
          field: 'internal_in_store_sales',
          headerName: 'MTD Limitless In Store Sales',
          hide: !hideNonEssentialAdvancedMetrics,
          aggFunc: 'sum',
          editable: false,
          valueFormatter: currencyFormatter,
        },
        {
          field: 'all_advertised_sku_sales',
          headerName: 'MTD ALL Advertised Sku Sales',
          hide: !hideNonEssentialAdvancedMetrics,
          aggFunc: 'sum',
          editable: false,
          valueFormatter: currencyFormatter,
        },
        {
          field: 'all_brand_sales',
          headerName: 'MTD ALL Brand Sales',
          hide: !hideNonEssentialAdvancedMetrics,
          aggFunc: 'sum',
          editable: false,
          valueFormatter: currencyFormatter,
        },
        {
          field: 'all_direct_sales',
          headerName: 'MTD ALL Direct Sales',
          hide: !hideNonEssentialAdvancedMetrics,
          aggFunc: 'sum',
          editable: false,
          valueFormatter: currencyFormatter,
        },
        {
          field: 'all_related_sales',
          headerName: 'MTD ALL Related Sales',
          hide: !hideNonEssentialAdvancedMetrics,
          aggFunc: 'sum',
          editable: false,
          valueFormatter: currencyFormatter,
        },
        {
          field: 'all_ntb_orders',
          headerName: 'MTD ALL NTB Orders',
          hide: true,
          editable: false,
          aggFunc: 'sum',
          valueFormatter: formatNumberDefaultToZero,
        },
        {
          field: 'all_ntb_revenue',
          headerName: 'MTD ALL NTB Revenue',
          hide: !hideNonEssentialAdvancedMetrics,
          aggFunc: 'sum',
          editable: false,
          valueFormatter: currencyFormatter,
        },
        {
          field: 'all_other_sku_sales',
          headerName: 'MTD ALL Other SKU Sales',
          hide: !hideNonEssentialAdvancedMetrics,
          aggFunc: 'sum',
          editable: false,
          valueFormatter: currencyFormatter,
        },
        {
          field: 'all_in_store_orders',
          headerName: 'MTD ALL In Store Orders',
          hide: true,
          editable: false,
          aggFunc: 'sum',
          valueFormatter: formatNumberDefaultToZero,
        },
        {
          field: 'all_in_store_sales',
          headerName: 'MTD ALL In Store Sales',
          hide: !hideNonEssentialAdvancedMetrics,
          aggFunc: 'sum',
          editable: false,
          valueFormatter: currencyFormatter,
        },
      ],
    },
    {
      headerName: 'Sparklines',
      children: [
        {
          field: 'internalSpendChange',
          headerName: 'Limitless Spend Change',
          hide: !hideNonEssentialSparklines,
          cellRenderer: 'agSparklineCellRenderer',
        },
        {
          field: 'internalSalesChange',
          headerName: 'Limitless Sales Change',
          hide: !hideNonEssentialSparklines,
          cellRenderer: 'agSparklineCellRenderer',
        },
        {
          field: 'internalAcosChange',
          headerName: 'Limitless ACoS Change',
          hide: !hideNonEssentialSparklines,
          cellRenderer: 'agSparklineCellRenderer',
        },
        {
          field: 'allSpendChange',
          headerName: 'All Spend Change',
          hide: !hideNonEssentialSparklines,
          cellRenderer: 'agSparklineCellRenderer',
        },
        {
          field: 'allSalesChange',
          headerName: 'All Sales Change',
          hide: !hideNonEssentialSparklines,
          cellRenderer: 'agSparklineCellRenderer',
        },
        {
          field: 'allAcosChange',
          headerName: 'All ACoS Change',
          hide: !hideNonEssentialSparklines,
          cellRenderer: 'agSparklineCellRenderer',
        },
      ],
    },
  ];

  const onBtnExport = () => {
    const fileName = `${sellerSelect.value}-${retailer}-BudgetGroups-${moment().format('YYYYMMDD')}.csv`;
    gridRef.current.api.exportDataAsCsv({ fileName });
  };

  const defaultColDef = {
    editable: true,
    sortable: true,
    filter: true,
    resizable: true,
  }

  return (
    <>
      <div style={{ height: '100%', width: '100%' }}>
        <Panel
          lg={12}
          md={12}
          title={`${title} Group Settings`}
          subhead="Used to manage budgets at a 'budget group' level"
          parentRefresh={panelLoad || allGraphLoad || internalGraphLoad}
          getData={() => getData()}
        >
          <div
            className={gridTheme}
            style={{ width: '100%' }}
          >
            <br />
            <div style={{ verticalAlign: 'middle', spacing: '2px', width: '100%', display: 'table-cell' }}>
              Show Additional Columns:
              <ToggleWrapOuter>
                <ToggleSlide
                  name="Budgets"
                  setFunction={() => setHideNonEssentialBudget(!hideNonEssentialBudget)}
                  currentState={hideNonEssentialBudget}
                  bgColor={platfromSpec.colorPrimary}
                />
                {/* <ToggleSlide
                  name="Cost Targets"
                  setFunction={() => setHideNonEssentialCostTargets(!hideNonEssentialCostTargets)}
                  currentState={hideNonEssentialCostTargets}
                  bgColor={platfromSpec.colorPrimary}
                /> */}
                <ToggleSlide
                  name="Spend Controls"
                  setFunction={() => setHideNonEssentialSpendControls(!hideNonEssentialSpendControls)}
                  currentState={hideNonEssentialSpendControls}
                  bgColor={platfromSpec.colorPrimary}
                />
                <ToggleSlide
                  name="Metrics"
                  setFunction={() => setHideNonEssentialMetrics(!hideNonEssentialMetrics)}
                  currentState={hideNonEssentialMetrics}
                  bgColor={platfromSpec.colorPrimary}
                />
                <ToggleSlide
                  name="Advanced Metrics"
                  setFunction={() => setHideNonEssentialAdvancedMetrics(!hideNonEssentialAdvancedMetrics)}
                  currentState={hideNonEssentialAdvancedMetrics}
                  bgColor={platfromSpec.colorPrimary}
                />
                <ToggleSlide
                  name="Sparklines"
                  setFunction={() => setHideNonEssentialSparklines(!hideNonEssentialSparklines)}
                  currentState={hideNonEssentialSparklines}
                  bgColor={platfromSpec.colorPrimary}
                />
              </ToggleWrapOuter>
            </div>
            <div style={{ verticalAlign: 'middle', spacing: '2px', width: '100%', display: 'table-cell' }}>
              <span style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '5px', paddingBottom: '0px' }}><button className="btn btn-outline-primary" style={{ width: '200px', marginBottom: ' 0px' }} onClick={onBtnExport} type="button">Export</button></span>
            </div>
            <br />
            <div className={gridTheme} style={{ height: '50vh', width: '100%' }}>
              <AgGridReact
                ref={gridRef}
                columnDefs={columnDefs}
                rowData={combinedItems}
                defaultColDef={defaultColDef}
                groupIncludeTotalFooter
                aggFuncs={aggFuncs}
                onGridReady={onGridReady}
                suppressScrollOnNewData
                enableRangeSelection
                rowSelection={'multiple'}
              />
            </div>
          </div>
          <br />
          <br />
          <div style={{ width: '500px' }}>
            <button type="button" className="btn btn-primary" onClick={() => submitBudget()}>Update {retailer} Budgets</button>
          </div>
        </Panel>
      </div>
    </>
  );
}

BudgetTable.propTypes = {
  gridTheme: PropTypes.string,
  internalGraphLoad: PropTypes.bool.isRequired,
  allGraphLoad: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  retailer: PropTypes.string.isRequired,
  internalById: PropTypes.shape().isRequired,
  allById: PropTypes.shape().isRequired,
};

BudgetTable.defaultProps = {
  gridTheme: 'ag-theme-balham',
};
