import { getLocalStorage } from '../shared/storage';

// eslint-disable-next-line
export function authHeader() {
  // return authorization header with jwt token
  const user = JSON.parse(getLocalStorage('user'));

  if (user && user.token) {
    return { Authorization: `Bearer ${user.token}` };
  }
  return {};
}
