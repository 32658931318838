/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';

export function BasicNotification({
  color, title, message,
}) {
  return (
    <div className={`notification notification--${color}`} style={{ maxHeight: 150, overflowY: 'auto' }}>
      <h5 className="notification__title bold-text">{title}</h5>
      <p className="notification__message">{message.split('\n').map(item => (<span key="notification">{item}<br /></span>))}</p>
    </div>
  );
}

BasicNotification.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
};

BasicNotification.defaultProps = {
  color: '',
  title: '',
};

export function ImageNotification({
  img, title, message,
}) {
  return (
    <div className="notification notification--image">
      <div className="notification__image">
        <img src={img} alt="" />
      </div>
      <h5 className="notification__title bold-text">{title}</h5>
      <p className="notification__message">{message.split('\n').map(item => (<span>{item}<br /></span>))}</p>
    </div>
  );
}

ImageNotification.propTypes = {
  img: PropTypes.string.isRequired,
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
};

ImageNotification.defaultProps = {
  title: '',
};

export function FullWideNotification({
  color, message,
}) {
  return (
    <div className={`notification notification--full-wide notification--${color}`}>
      <p className="notification__message">{message.split('\n').map(item => (<span>{item}<br /></span>))}</p>
    </div>
  );
}

FullWideNotification.propTypes = {
  color: PropTypes.string,
  message: PropTypes.string.isRequired,
};

FullWideNotification.defaultProps = {
  color: '',
};
