import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import AmzProductOffImpTable from './components/AmzProductOffImpTable';

const DataCheckAmzProductOffImpPage = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Data Check - Amazon Products</h3>
        <br />
        <span>Products with IMPRESSIONS on the DATE selected that currently have a ASIN configured OFF.</span>
      </Col>
    </Row>
    <Row>
      <br />
    </Row>
    <Row>
      <AmzProductOffImpTable />
    </Row>
  </Container>
);

export default DataCheckAmzProductOffImpPage;
