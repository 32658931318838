const costPerItemCurrencyOptions = [
  {
    currencyCode: 'AED',
    itemBudgetOptions: [
      { label: '10', value: 10 },
      { label: '15', value: 15 },
      { label: '20', value: 20 },
      { label: '25', value: 25 },
      { label: '50', value: 50 },
      { label: '100', value: 100 },
    ],
  },
  {
    currencyCode: 'AUD',
    itemBudgetOptions: [
      { label: '3', value: 3 },
      { label: '5', value: 5 },
      { label: '10', value: 10 },
      { label: '20', value: 20 },
      { label: '25', value: 25 },
      { label: '50', value: 50 },
    ],
  },
  {
    currencyCode: 'EUR',
    itemBudgetOptions: [
      { label: '3', value: 3 },
      { label: '5', value: 5 },
      { label: '10', value: 10 },
      { label: '20', value: 20 },
      { label: '25', value: 25 },
      { label: '50', value: 50 },
    ],
  },
  {
    currencyCode: 'BRL',
    itemBudgetOptions: [
      { label: '10', value: 10 },
      { label: '15', value: 15 },
      { label: '20', value: 20 },
      { label: '25', value: 25 },
      { label: '50', value: 50 },
      { label: '100', value: 100 },
    ],
  },
  {
    currencyCode: 'CAD',
    itemBudgetOptions: [
      { label: '3', value: 3 },
      { label: '5', value: 5 },
      { label: '10', value: 10 },
      { label: '15', value: 15 },
      { label: '25', value: 25 },
      { label: '30', value: 30 },
    ],
  },
  {
    currencyCode: 'CNY',
    itemBudgetOptions: [
      { label: '200', value: 200 },
      { label: '300', value: 300 },
      { label: '500', value: 500 },
      { label: '750', value: 750 },
      { label: '1000', value: 1000 },
      { label: '2000', value: 2000 },
    ],
  },
  {
    currencyCode: 'EUR',
    itemBudgetOptions: [
      { label: '3', value: 3 },
      { label: '5', value: 5 },
      { label: '10', value: 10 },
      { label: '20', value: 20 },
      { label: '25', value: 25 },
      { label: '50', value: 50 },
    ],
  },
  {
    currencyCode: 'EGP',
    itemBudgetOptions: [
      { label: '50', value: 50 },
      { label: '100', value: 100 },
      { label: '250', value: 250 },
      { label: '500', value: 500 },
      { label: '750', value: 750 },
      { label: '1000', value: 1000 },
    ],
  },
  {
    currencyCode: 'EUR',
    itemBudgetOptions: [
      { label: '3', value: 3 },
      { label: '5', value: 5 },
      { label: '10', value: 10 },
      { label: '20', value: 20 },
      { label: '25', value: 25 },
      { label: '50', value: 50 },
    ],
  },
  {
    currencyCode: 'EUR',
    itemBudgetOptions: [
      { label: '2', value: 2 },
      { label: '3', value: 3 },
      { label: '5', value: 5 },
      { label: '10', value: 10 },
      { label: '15', value: 15 },
      { label: '20', value: 20 },
    ],
  },
  {
    currencyCode: 'GBP',
    itemBudgetOptions: [
      { label: '2', value: 2 },
      { label: '3', value: 3 },
      { label: '5', value: 5 },
      { label: '10', value: 10 },
      { label: '15', value: 15 },
      { label: '20', value: 20 },
    ],
  },
  {
    currencyCode: 'INR',
    itemBudgetOptions: [
      { label: '200', value: 200 },
      { label: '300', value: 300 },
      { label: '500', value: 500 },
      { label: '750', value: 750 },
      { label: '1000', value: 1000 },
      { label: '2000', value: 2000 },
    ],
  },
  {
    currencyCode: 'EUR',
    itemBudgetOptions: [
      { label: '3', value: 3 },
      { label: '5', value: 5 },
      { label: '10', value: 10 },
      { label: '20', value: 20 },
      { label: '25', value: 25 },
      { label: '50', value: 50 },
    ],
  },
  {
    currencyCode: 'JPY',
    itemBudgetOptions: [
      { label: '200', value: 200 },
      { label: '300', value: 300 },
      { label: '500', value: 500 },
      { label: '1000', value: 1000 },
      { label: '1500', value: 1500 },
      { label: '3000', value: 3000 },
    ],
  },
  {
    currencyCode: 'MXN',
    itemBudgetOptions: [
      { label: '30', value: 30 },
      { label: '50', value: 50 },
      { label: '100', value: 100 },
      { label: '200', value: 200 },
      { label: '300', value: 300 },
      { label: '400', value: 400 },
    ],
  },
  {
    currencyCode: 'EUR',
    itemBudgetOptions: [
      { label: '2', value: 2 },
      { label: '3', value: 3 },
      { label: '5', value: 5 },
      { label: '10', value: 10 },
      { label: '15', value: 15 },
      { label: '20', value: 20 },
    ],
  },
  {
    currencyCode: 'PLN',
    itemBudgetOptions: [
      { label: '10', value: 10 },
      { label: '15', value: 15 },
      { label: '20', value: 20 },
      { label: '25', value: 25 },
      { label: '50', value: 50 },
      { label: '100', value: 100 },
    ],
  },
  {
    currencyCode: 'SAR',
    itemBudgetOptions: [
      { label: '5', value: 5 },
      { label: '10', value: 10 },
      { label: '15', value: 15 },
      { label: '25', value: 25 },
      { label: '50', value: 50 },
      { label: '100', value: 100 },
    ],
  },
  {
    currencyCode: 'SEK',
    itemBudgetOptions: [
      { label: '20', value: 20 },
      { label: '30', value: 30 },
      { label: '50', value: 50 },
      { label: '100', value: 100 },
      { label: '150', value: 150 },
      { label: '200', value: 200 },
    ],
  },
  {
    currencyCode: 'SGD',
    itemBudgetOptions: [
      { label: '2', value: 2 },
      { label: '3', value: 3 },
      { label: '5', value: 5 },
      { label: '10', value: 10 },
      { label: '15', value: 15 },
      { label: '20', value: 20 },
    ],
  },
  {
    currencyCode: 'TRY',
    itemBudgetOptions: [
      { label: '50', value: 50 },
      { label: '100', value: 100 },
      { label: '200', value: 200 },
      { label: '300', value: 300 },
      { label: '400', value: 400 },
      { label: '500', value: 500 },
    ],
  },
  {
    currencyCode: 'USD',
    itemBudgetOptions: [
      { label: '2', value: 2 },
      { label: '3', value: 3 },
      { label: '5', value: 5 },
      { label: '10', value: 10 },
      { label: '15', value: 15 },
      { label: '20', value: 20 },
    ],
  },
];

export default costPerItemCurrencyOptions;
