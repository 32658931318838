import PropTypes from 'prop-types';
import config from '../../../../../configWrapper';
import { doFetch } from '../../../../../helpers';

export const typeOptions = [
  { label: 'STRING', value: 'string' },
  { label: 'TEXTAREA', value: 'textarea' },
  { label: 'DATE', value: 'date' },
  { label: 'NUMBER', value: 'number' },
];

export const statusOptions = [
  { label: 'Active', value: true },
  { label: 'Inactive', value: false },
];

export const editRoleOptions = [
  { label: 'Admin Only', value: 'admin' },
  { label: 'Acct Mgr', value: 'actmgr' },
  { label: 'Customer', value: 'customer' },
  { label: 'Account', value: 'account' },
];

export const viewRoleOptions = [
  { label: 'Admin Only', value: 'admin' },
  { label: 'Acct Mgr', value: 'actmgr' },
  { label: 'Customer', value: 'customer' },
  { label: 'Account', value: 'account' },
];

function hasPermission(user, accessControl) {
  const permissionsJson = accessControl && JSON.parse(accessControl);
  if (!permissionsJson) {
    return true;
  }
  let access = false;
  const userAccess = JSON.parse(user.access_control);
  switch (permissionsJson.scope) {
    case 'admin':
      access = (user.access === 'admin');
      break;
    case 'user':
      Object.entries(userAccess).forEach(([key, value]) => {
        access = (!access && permissionsJson.roleList.includes(key)) ? value : access;
      });
      break;
    default:
      access = false;
  }
  return access;
}

export async function fetchGroupOptions(user) {
  try {
    const url = `${config.serverUrl}/accounts/settingGroups`;
    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };
    const results = await doFetch(url, requestOptions);
    return results
      .filter(result => hasPermission(user, result.settingGroupAccessControl))
      .map(item => ({ label: item.settingGroupValue, value: item.settingGroupKey }));
  } catch (error) {
    throw new Error(error);
  }
}

export const apiStatusOptions = [
  { label: 'on', value: '1' },
  { label: 'off', value: '0' },
];

export const acctStatusOptions = [
  { label: 'active', value: 'active' },
  { label: 'active hold', value: 'active hold' },
  { label: 'active data', value: 'active data' },
  { label: 'active terminated', value: 'active terminated' },
  { label: 'dead', value: 'dead' },
  { label: 'test', value: 'test' },
];

export const findOption = (value, options) => {
  const foundObject = options.find(item => item.value === value);
  if (foundObject) {
    return foundObject;
  }
  return options[0];
};

export const OptionShape = {
  label: PropTypes.string,
  value: PropTypes.string,
};
