import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import TreemapModal from './modals/TreemapModal';
import MultiLineSeriesModal from './modals/MultiLineSeriesModal';
import PieModal from './modals/PieModal';
// import Config from '../../../../config';
import CardInsight from '../../../../shared/components/widgets/CardInsight';

export default function InsightPerformanceCard({
  loading, title, cardData,
}) {
  // const sellerSelect = useSelector(state => state.sellerSelect);
  const theme = useSelector(state => state.theme);
  const [showTreemapModal, setShowTreemapModal] = useState(false);
  const [showMultiLineSeriesModal, setShowMultiLineSeriesModal] = useState(false);
  const [showPieModal, setShowPieModal] = useState(false);

  const changeShowPerformanceModal = (modalType) => {
    if (modalType === 'treemap') {
      setShowTreemapModal(!showTreemapModal);
    }
    if (modalType === 'line') {
      setShowMultiLineSeriesModal(!showMultiLineSeriesModal);
    }
    if (modalType === 'pie') {
      setShowPieModal(!showPieModal);
    }
  };

  return (
    <>
      <CardInsight
        title={title}
        parentRefresh={loading}
        insightClass="performance"
        icon="chart-bars"
        countName={cardData.countName}
        countValue={cardData.countValue}
      >
        <div>
          <div><b>Description:</b></div>
          <div>{cardData.recommendation}</div>
        </div>
        <br />
        <br />
        <div><button type="button" className="btn btn-primary badge-btn" onClick={() => changeShowPerformanceModal(cardData.modalType)}>View Details</button></div>
        <br />
        <div className={'insight_bottom-blank'}>
          &nbsp;
        </div>
        <br />
        <div>
          <span><b>Date Range:</b> {moment(cardData.firstDate).format('MM/DD/YYYY')} - {moment(cardData.lastDate).format('MM/DD/YYYY')}</span>
        </div>
      </CardInsight>
      <TreemapModal
        title={title}
        theme={theme}
        data={cardData.data}
        labelKey="subcategory"
        sizeKey="sum_sales_30d"
        sizeName="Sales"
        showTreemapModal={showTreemapModal}
        changeShowTreemapModal={() => changeShowPerformanceModal(cardData.modalType)}
      />
      <MultiLineSeriesModal
        title={title}
        theme={theme}
        data={cardData.data}
        labelKey="subcategory"
        sizeKey="sum_sales_30d"
        sizeName="Sales"
        showMultiLineSeriesModal={showMultiLineSeriesModal}
        changeShowMultiLineSeriesModal={() => changeShowPerformanceModal(cardData.modalType)}
        series={cardData.series}
      />
      <PieModal
        title={title}
        theme={theme}
        data={cardData.data}
        calloutLabelKey="subcategory"
        sectorLabelKey="sum_sales_30d"
        angleKey="sum_sales_30d"
        showPieModal={showPieModal}
        changeShowPieModal={() => changeShowPerformanceModal(cardData.modalType)}
      />
    </>
  );
}

// InsightAdCard.defaultProps = {
//   data: null,
// };

InsightPerformanceCard.propTypes = {
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};
