import React, { PureComponent } from 'react';
import NotificationSystem from 'rc-notification';
import config from '../../../../config';
import TableBuilder from '../../../../shared/components/table/TableBuilder';
import { BasicNotification } from '../../../../shared/components/Notification';
import { getExportPrefix } from '../../../../shared/components/table/functions';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

let notificationLU = null;
let notificationRU = null;
let notificationTC = null;

class UserTable extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        {
          field: 'id',
          headerName: '#',
        },
        {
          field: 'first_name',
          headerName: 'First Name',
          editable: true,
        },
        {
          field: 'last_name',
          headerName: 'Last Name',
          editable: true,
        },
        {
          field: 'email',
          headerName: 'Email',
          editable: true,
        },
        {
          field: 'access',
          headerName: 'Access',
          editable: true,
        },
        {
          field: 'access_control',
          headerName: 'Access Control',
          editable: true,
        },
        {
          field: 'password',
          headerName: 'Encrypted Password',
          editable: true,
        },
        {
          field: 'temporary_password',
          headerName: 'Temporary Password',
          editable: true,
        },
        {
          field: 'is_active',
          headerName: 'Status',
          editable: true,
        },
        {
          field: 'theme',
          headerName: 'Default Theme',
          editable: true,
        },
      ],
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
      },
      showDatePicker: false,
      showFooter: false,
      showAddRowButton: true,
      showDeleteSelectedRowButton: false,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line
    NotificationSystem.newInstance({}, n => notificationLU = n);
    // eslint-disable-next-line
    NotificationSystem.newInstance({}, n => notificationRU = n);
    // eslint-disable-next-line
    NotificationSystem.newInstance({}, n => notificationTC = n);
  }

  componentWillUnmount() {
    if (notificationLU) {
      notificationLU.destroy();
    }
    if (notificationRU) {
      notificationRU.destroy();
    }
    if (notificationTC) {
      notificationTC.destroy();
    }
  }

  // eslint-disable-next-line
  show = (color, title, msg) => {
    return config.showNotification({
      notification: <BasicNotification
        color={color}
        title={title}
        message={msg}
      />,
      position: 'right-up',
      notificationLU,
      notificationRU,
      notificationTC,
    });
  };

  getNewRow = () => {
    const newData = {
      id: 0,
      first_name: '',
      last_name: '',
      email: '',
      access: 'none',
      access_control: '',
      password: '',
      is_active: 1,
      username: '',
      verified: 0,
      theme: 'light',
    };
    return newData;
  }

  parentProcessChange = (rowNode, user, sellerSelect, apiEndPoint, childThis) => {
    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
    // Add logic to check input values, make sure they are present and correct.
    const requestOptions = {
      method: 'POST',
      headers: headerWithAuth,
      body: JSON.stringify({
        id: rowNode.data.id,
        first_name: rowNode.data.first_name,
        last_name: rowNode.data.last_name,
        email: rowNode.data.email,
        access: rowNode.data.access,
        access_control: rowNode.data.access_control,
        password: rowNode.data.password,
        temporary_password: rowNode.data.temporary_password,
        is_active: rowNode.data.is_active,
        username: rowNode.data.username,
        verified: rowNode.data.verified,
        theme: rowNode.data.theme,
      }),
    };

    if ((rowNode.data.id || rowNode.data.id === 0) && rowNode.data.first_name && rowNode.data.last_name && rowNode.data.email
      && rowNode.data.access && rowNode.data.access_control && rowNode.data.password && rowNode.data.is_active
      && rowNode.data.theme) {
      // Call via API (would like to update to " await ")
      fetch(apiEndPoint, requestOptions)
        .then((results) => {
          if (!results.ok) {
            throw results;
          }
          return results.json();
        }).then((data) => {
          // success message
          if (rowNode.data.id === 0) {
            this.show('primary', 'Success', `Uer entry ${data[0].id} has been created.`);
          } else {
            this.show('primary', 'Success', `User entry ${data[0].id} has been updated.`);
          }
          childThis.refreshData();
        }).catch((error) => {
          error.json().then((data) => {
            this.show('danger', 'Error', `Problem adding/updating User entry. \n Refrence: ${JSON.stringify(data)}`);
          }).catch(() => {
            this.show('danger', 'Error', `Problem adding/updating User entry. \n Refrence: ${error}`);
          });
        });
    } else {
      this.show('danger', 'Error', 'Did not process, all fields must be filled out.');
    }
  }

  parentDeleteRows = (selectedRows, user, apiEndPoint, childThis) => {
    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
    // Add logic to check input values, make sure they are present and correct.
    const promises = [];

    selectedRows.forEach((selectedRow) => {
      const requestOptions = {
        method: 'DELETE',
        headers: headerWithAuth,
      };

      const fullDeleteUrl = `${apiEndPoint}/${selectedRow.id}`;

      if (selectedRow.id !== '0') {
        // Call via API (would like to update to " await ")
        promises.push(fetch(fullDeleteUrl, requestOptions)
          .then((results) => {
            if (!results.ok) {
              throw Error(results.statusText);
            }
            return results.json();
          }).then(() => {
            // success message
            this.show('primary', 'Success', `User entry ${selectedRow.id} has been deleted.`);
          }).catch((error) => {
            this.show('danger', 'Error', `Problem deleting User entry. \n Refrence: ${error}`);
          }));
      }
    });
    if (promises.length > 0) {
      Promise.all(promises).then(() => {
        // All good.
        childThis.refreshData();
      }, () => {
        childThis.refreshData();
      });
    }
  }


  render() {
    const {
      columnDefs, defaultColDef, showDatePicker, showFooter, showDeleteSelectedRowButton, showAddRowButton,
    } = this.state;

    const apiEndPoint = `${apiUrl}/users`;
    const exportPrefix = getExportPrefix('users');

    return (
      <TableBuilder
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        apiEndPoint={apiEndPoint}
        showDatePicker={showDatePicker}
        showAddRowButton={showAddRowButton}
        showDeleteSelectedRowButton={showDeleteSelectedRowButton}
        showFooter={showFooter}
        parentProcessChange={this.parentProcessChange}
        parentDeleteRows={this.parentDeleteRows}
        getNewRow={this.getNewRow}
        exportPrefix={exportPrefix}
        confirmLogic
      />
    );
  }
}

export default UserTable;
