import React, { PureComponent } from 'react';
import config from '../../../../config';
import TableBuilder from '../../../../shared/components/table/TableBuilder';
import {
  currencyFormatter, formatNumberDefaultToZero, formatDecimalToFixedTwoPercent,
} from '../../../../shared/components/table/functions';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

function numberValueParser(params) {
  return Number(params.newValue);
}

class VendorSaleSummaryTable extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        {
          field: 'asin',
          headerName: 'ASIN',
          rowGroup: true,
          enableRowGroup: true,
        },
        { field: 'parent_asin', headerName: 'Parent ASIN' },
        { field: 'product_title', headerName: 'Product Title' },
        { field: 'brand', headerName: 'Brand' },
        { field: 'subcategory', headerName: 'Subcategory' },
        {
          field: 'category',
          headerName: 'Category',
        },
        {
          field: 'list_price', // Pull from Vendor Catalog
          headerName: 'List Price',
          valueFormatter: currencyFormatter,
        },
        {
          field: 'lbb',
          headerName: 'LBB (Price)',
          valueFormatter: formatDecimalToFixedTwoPercent,
        },
        {
          field: 'sourcing_shipped_units',
          headerName: '1P SRC',
          valueFormatter: formatNumberDefaultToZero,
          valueParser: numberValueParser,
          aggFunc: 'sum',
        },
        {
          field: 'shipped_units',
          headerName: '1P MFG',
          valueFormatter: formatNumberDefaultToZero,
          valueParser: numberValueParser,
          aggFunc: 'sum',
        },
        {
          field: 'CALC3P',
          headerName: '3P',
          valueGetter: function calc3PValueGetter(params) {
            if (params && params.data && params.data.shipped_units && (1 - params.data.lbb) !== 0 && Math.ceil(params.data.shipped_units / (1 - params.data.lbb)) > 0) {
              return Math.ceil(params.data.shipped_units / (1 - params.data.lbb)) - params.data.shipped_units;
            }
            return 0;
          },
          valueFormatter: formatNumberDefaultToZero,
          aggFunc: 'sum',
        },
        {
          field: 'CALCTotal',
          headerName: 'Total',
          valueGetter: function calcTotalValueGetter(params) {
            if (params && params.data && params.data.shipped_units && (1 - params.data.lbb) !== 0) {
              return Math.ceil(params.data.shipped_units / (1 - params.data.lbb));
            }
            return 0;
          },
          valueFormatter: formatNumberDefaultToZero,
          aggFunc: 'sum',
        },
        {
          field: 'CALC1PSrc',
          headerName: '1P SRC %',
          valueGetter: function calc1PSrcValueGetter(params) {
            if (params && params.data && params.data.sourcing_shipped_units && Math.ceil(params.data.shipped_units / (1 - params.data.lbb)) !== 0) {
              return (params.data.sourcing_shipped_units / Math.ceil(params.data.shipped_units / (1 - params.data.lbb)));
            }
            return 0;
          },
          valueFormatter: formatDecimalToFixedTwoPercent,
        },
        {
          field: 'CALC1PMfg',
          headerName: '1P MFG %',
          valueGetter: function calc1PMfgValueGetter(params) {
            if (params && params.data && params.data.shipped_units && Math.ceil(params.data.shipped_units / (1 - params.data.lbb)) !== 0) {
              return (params.data.shipped_units / Math.ceil(params.data.shipped_units / (1 - params.data.lbb)));
            }
            return 0;
          },
          valueFormatter: formatDecimalToFixedTwoPercent,
        },
        {
          field: 'CALC3PPercent',
          headerName: '3P %',
          valueGetter: function calc3PPercentValueGetter(params) {
            if (params && params.data && params.data.shipped_units && Math.ceil(params.data.shipped_units / (1 - params.data.lbb)) !== 0 && (1 - params.data.lbb) !== 0) {
              return (Math.ceil(params.data.shipped_units / (1 - params.data.lbb)) - params.data.shipped_units) / Math.ceil(params.data.shipped_units / (1 - params.data.lbb));
            }
            return 0;
          },
          valueFormatter: formatDecimalToFixedTwoPercent,
        },
        {
          field: 'CALC1PMfgSales',
          headerName: '1P $',
          valueGetter: function calc1PDollarValueGetter(params) {
            if (params && params.data && params.data.shipped_units && params.data.list_price) {
              return (params.data.shipped_units * params.data.list_price);
            }
            return 0;
          },
          valueFormatter: currencyFormatter,
          aggFunc: 'sum',
        },
        {
          field: 'CALC3PSales',
          headerName: '3P $',
          valueGetter: function calc3PDollarValueGetter(params) {
            if (params && params.data && params.data.shipped_units && Math.ceil(params.data.shipped_units / (1 - params.data.lbb)) !== 0 && (1 - params.data.lbb) !== 0) {
              return (Math.ceil(params.data.shipped_units / (1 - params.data.lbb)) - params.data.shipped_units) * params.data.list_price;
            }
            return 0;
          },
          valueFormatter: currencyFormatter,
          aggFunc: 'sum',
        },
        {
          field: 'CALCTotalSales',
          headerName: 'Total $',
          valueGetter: function calcTotalDollarValueGetter(params) {
            if (params && params.data && params.data.shipped_units && Math.ceil(params.data.shipped_units / (1 - params.data.lbb)) !== 0 && (1 - params.data.lbb) !== 0) {
              return Math.ceil(params.data.shipped_units / (1 - params.data.lbb)) * params.data.list_price;
            }
            return 0;
          },
          valueFormatter: currencyFormatter,
          aggFunc: 'sum',
        },
      ],
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        cellStyle: (params) => {
          if (params.value < 0.00) {
            return { color: 'red' };
          }
          return null;
        },
      },
      showDatePicker: true,
      showFooter: true,
      enableCharts: true,
      enableRangeSelection: true,
      sideBar: true,
      pivotMode: true,
      suppressAggFuncInHeader: true,
    };
  }

  render() {
    const {
      columnDefs, defaultColDef, onCellEditingStopped, showDatePicker, showFooter, enableCharts, enableRangeSelection,
      sideBar, pivotMode, suppressAggFuncInHeader,
    } = this.state;

    const apiEndPoint = `${apiUrl}/vendors/sales-share`;

    return (
      <TableBuilder
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        apiEndPoint={apiEndPoint}
        onCellEditingStopped={onCellEditingStopped}
        showDatePicker={showDatePicker}
        showFooter={showFooter}
        enableCharts={enableCharts}
        enableRangeSelection={enableRangeSelection}
        sideBar={sideBar}
        pivotMode={pivotMode}
        suppressAggFuncInHeader={suppressAggFuncInHeader}
      />
    );
  }
}

export default VendorSaleSummaryTable;
