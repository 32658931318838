import {
  useState, useEffect,
} from 'react';
import {
  withArray, when, arrayToMap,
} from '../../../../shared/functions';
import calcPpcTotalsByCampaignType from './calcPpcTotalsByCampaignType';
import useAccountOverTime from '../../../../shared/components/hooks/useAccountOverTime';
import useVendorSalesManufacturingOverTime from '../../../../shared/components/hooks/useVendorSalesManufacturingOverTime';
import useTopAsins from '../../../../shared/components/hooks/useTopAsins';
import useRankForDaysEnding from '../../../../shared/components/hooks/useRankForDaysEnding';
import useVendorSalesOrdersOverTime from '../../../../shared/components/hooks/useVendorSalesOrdersOverTime';

export default function useVendorData(accountSelect, startSelectionDate, endSelectionDate, dateView, brand) {
  const dataSet = { value: '' };
  const [ppcLoading, ppcResults, ppcError] = useAccountOverTime(accountSelect, startSelectionDate, endSelectionDate, dateView, dataSet, brand);
  const [manufacturingLoading, manufacturingResults, manufacturingError] = useVendorSalesManufacturingOverTime(accountSelect, startSelectionDate, endSelectionDate, dateView, brand);
  const [ordersLoading, ordersResults, ordersError] = useVendorSalesOrdersOverTime(accountSelect, startSelectionDate, endSelectionDate, dateView, brand);
  const [rankLoading, rankResults, rankError] = useRankForDaysEnding(accountSelect, 30, endSelectionDate, brand);
  const [topAsinsAcosLoading, topAsinsAcosResults, topAsinsAcosError] = useTopAsins(accountSelect, startSelectionDate, endSelectionDate, 'acos', brand);
  const [topAsinsRankLoading, topAsinsRankResults, topAsinsRankError] = useTopAsins(accountSelect, startSelectionDate, endSelectionDate, 'rank', brand);
  const loading = ppcLoading || manufacturingLoading || ordersLoading || topAsinsAcosLoading || topAsinsRankLoading || rankLoading;
  const [results, setResults] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const data = withArray(ppcResults, () => {
      const manufacturingMap = arrayToMap(manufacturingResults, 'report_date');
      const ordersMap = arrayToMap(ordersResults, 'report_date');
      return ppcResults.map((result) => {
        const temp = result;
        const dayManufacturing = manufacturingMap.get(result.rankDate);
        when(dayManufacturing, () => {
          temp.ordered_units = dayManufacturing.ordered_units;
          temp.average_sales_price = dayManufacturing.average_sales_price;
          temp.ordered_revenue = dayManufacturing.ordered_revenue;
          temp.tacos = result.spend / result.ordered_revenue;
          temp.subcategory_sales_rank = dayManufacturing.subcategory_sales_rank;
          temp.shipped_cogs = dayManufacturing.shipped_cogs;
        });
        const dayOrders = ordersMap.get(result.rankDate);
        when(dayOrders, () => {
          temp.ordered_units = dayOrders.ordered_units;
          temp.ordered_revenue = dayOrders.ordered_revenue;
          temp.tacos = result.spend / dayOrders.ordered_revenue;
        });
        return temp;
      });
    }, () => []);

    const pieData = calcPpcTotalsByCampaignType(data);

    setResults({
      ...results,
      data,
      pieData,
      topAsinsAcos: topAsinsAcosResults,
      topAsinsRank: topAsinsRankResults,
      rankData: rankResults,
    });

    return (() => setResults({
      ...results,
      data: null,
      pieData: null,
      topAsinsAcos: null,
      topAsinsRank: null,
      rankData: null,
    }));
  }, [ppcResults, manufacturingResults, topAsinsAcosResults, topAsinsRankResults, rankResults, setResults]);

  useEffect(() => {
    when(ppcError, () => {
      setError(ppcError);
    });
  }, [ppcError, setError]);

  useEffect(() => {
    when(manufacturingError, () => {
      setError(manufacturingError);
    });
  }, [manufacturingError, setError]);

  useEffect(() => {
    when(ordersError, () => {
      setError(ordersError);
    });
  }, [ordersError, setError]);

  useEffect(() => {
    when(topAsinsAcosError, () => {
      setError(topAsinsAcosError);
    });
  }, [topAsinsAcosError, setError]);

  useEffect(() => {
    when(topAsinsRankError, () => {
      setError(topAsinsRankError);
    });
  }, [topAsinsRankError, setError]);

  useEffect(() => {
    when(rankError, () => {
      setError(rankError);
    });
  }, [rankError, setError]);

  return [loading, results, error];
}
