import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import PropTypes from 'prop-types';
import AuthorizeForm from './AuthorizeForm';
import {
  SiteName, getCorprateUrl, defaultAgencyCode as DEFAULT_AGENCY, platform as DEFAULT_PLATFORM, getPlatform,
} from '../../../../shared/components/domainSupport';
import useOnboardingEdata from '../../../../shared/components/hooks/useOnboardingEdata';

/*
- Agency Priority order:
    a. Use Agency in sellerSelect
    b. Use Agency in the URL
    c. Use default Agency
- Use Platform in sellerSelect to determine what the domain should be.
- Make sure SP-API is onboarding to the same platfrom as the AD-API
*/

const AuththorizeSpAPi = ({ agencyCode: agency, displayLink, apiType }) => {
  const selectedAccount = useSelector(store => store.sellerSelect);
  const [agencyCode, setAgencyCode] = useState(agency);
  const [platform, setPlatform] = useState(DEFAULT_PLATFORM);
  const { edata } = displayLink === 'yes' ? useOnboardingEdata(selectedAccount.value) : '';

  // This useEffect is really needed just to detect the seller changed. Since our input props
  // won't change in that case, we use this to force things to re-render.  Icky, but it works.
  useEffect(() => {
    if (selectedAccount && displayLink === 'yes' && selectedAccount.value !== 1) {
      const updatedAgency = JSON.parse(selectedAccount.agency);
      setAgencyCode(updatedAgency.code);
      setPlatform(selectedAccount.platform);
    }
  }, [selectedAccount, displayLink]);

  if (selectedAccount && selectedAccount.value === 0 && agency && agency !== agencyCode) { // Not logged in and the URL agency does not default agency, so use the URL agency.
    setAgencyCode(agency);
  }

  if (!platform) {
    setPlatform(DEFAULT_PLATFORM);
  }

  const quickReferenceLink = `${getPlatform(platform).dashboardUrl}/onboarding${agencyCode && platform !== agencyCode ? `/${agencyCode}` : ''}/sp-api/start?edata=${edata}`;

  // Add back in when AdJuggy goes full onboard:  || (selectedAccount.platform !== agencyCode && selectedAccount.platform !== 'adjuggernaut')
  if (selectedAccount && selectedAccount.value !== 0 && (selectedAccount.value === 1)) { // Can't run for Account ID 1 (System) and adjuggernaut is the only one that can have a different agency
    return (
      <Col md={12}>
        <Card>
          <Row>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">ERROR</h5>
              </div>
              <p>The account you are attempting to Authorize SP-API is not configured correctly.  Please contact your {SiteName()} account management team.</p>
            </CardBody>
          </Row>
        </Card>
      </Col>
    )
  }
  return (
    <Col md={12}>
      <Card>
        <Row>
          <Col md={5}>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">Authorizing access for {SiteName()}</h5>
              </div>
              <AuthorizeForm agencyCode={agencyCode} apiType={apiType} />
            </CardBody>
          </Col>
          <Col md={5}>
            <CardBody>
              <br />
              <p>PLEASE NOTE:<br /><br /></p>
              <blockquote><p>By Clicking &apos;Authorize&apos;, you agree to our <a href={`${getCorprateUrl()}/terms-of-use`} target="blank">Terms &amp; Conditions</a></p></blockquote>
              <br />
              <blockquote><p>After you click &apos;Authorize&apos;, you will be directed to Amazon to complete the authorization.  When you are finished, Amazon will redirect back to {SiteName()} where you will recieve a success message.</p></blockquote>
              <br />
              {displayLink === 'yes' && (
                <blockquote>
                  <p>This&nbsp;&nbsp;<a href={quickReferenceLink}>Quick Reference Link</a>&nbsp;&nbsp;can be used if you are not logged in.</p>
                </blockquote>
              )}
            </CardBody>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

AuththorizeSpAPi.propTypes = {
  agencyCode: PropTypes.string,
  displayLink: PropTypes.string,
  apiType: PropTypes.string.isRequired,
};

AuththorizeSpAPi.defaultProps = {
  agencyCode: DEFAULT_AGENCY,
  displayLink: 'yes',
};

export default AuththorizeSpAPi;
