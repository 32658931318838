import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import ProductMetricsView from './components/ProductMetricsView';
import MetricSelector from './components/MetricSelector';
import Panel from '../../../shared/components/Panel';

export default function ProductMetricsPage() {
  const [metric, setMetric] = useState({
    label: 'Impressions',
    key: 'impressions',
    value: 'impressions_1d',
  });

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Account Manager Product Metrics</h3>
          <h3 className="page-subhead subhead">Product level information</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Panel
            lg={12}
            md={12}
            title="Select the Metric you would like to view"
            subhead="Red cells represent blank data or 0 values.  Green is shaded based on the percent of that days sum and is to be used for general refrernece."
          >
            <MetricSelector
              setValue={setMetric}
              value={metric}
            />
          </Panel>
        </Col>
      </Row>
      <Row>
        <ProductMetricsView metric={metric} />
      </Row>
    </Container>
  );
}
