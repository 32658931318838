import { updateCalendarConstants } from '../../constants';

const initialState = {};

export default function updateCalendar(state = initialState, action) {
  switch (action.type) {
    case updateCalendarConstants.UPDATE_CALENDAR:
      return { value: action.currentState };
    case updateCalendarConstants.UPDATE_CALENDAR_DONE:
      return {};
    default:
      return state;
  }
}
