import React from 'react';
import { Table } from 'reactstrap';

const PriceTier = ({ title, tierData }) => (
  <>
    <h3>{title}</h3>
    <br />
    <Table responsive striped>
      <thead>
        <tr>
          <th>Tier</th>
          <th>Rate/Fee</th>
        </tr>
      </thead>
      <tbody>
        {tierData.map((teir) => (
          <tr key={teir.name}>
            <td>{teir.name}</td>
            <td>{teir.value}</td>
          </tr>
        ))}
      </tbody>
    </Table>
    <br />
    <br />
  </>
);

export default PriceTier;
