/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  PieChart, Pie, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import PropTypes from 'prop-types';
import Panel from '../Panel';
import {
  formatNumberDefaultToZero,
} from '../table/functions';

const renderLegend = ({ payload }) => (
  <ul className="dashboard__chart-legend">
    {
      payload.map((entry, index) => {
        if (index <= 7) { // Only get one set of legend values from both piecharts
          return (
            <li key={`item-${index}`}><span style={{ backgroundColor: entry.color }} />{entry.value}</li>
          );
        }
        return null;
      })
    }
  </ul>
);

renderLegend.propTypes = {
  payload: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string,
    vslue: PropTypes.string,
  })).isRequired,
};

const PieBreakdown = ({
  data, content, loadingData,
}) => (
  <Panel
    lg={6}
    md={12}
    title={content.title}
    subhead={content.subtitle}
    parentRefresh={loadingData}
  >
    <div className="dashboard__bypage-chart">
      <ResponsiveContainer className="dashboard__chart-pie dashboard__chart-pie--commerce" width="100%" height={360}>
        <PieChart className="dashboard__chart-pie-container">
          <Tooltip formatter={value => formatNumberDefaultToZero({ value })} />
          {/* <Pie data={data} dataKey={contentsearchVolumeKey} cy={110} outerRadius={60} /> */}
          <Pie
            data={data}
            dataKey={content.outerDataKey}
            cy={170}
            innerRadius={110}
            outerRadius={160}
            label={labelData => formatNumberDefaultToZero({ value: labelData.payload.payload[content.outerDataKey] })}
          />
          <Legend layout="vertical" verticalAlign="middle" align="left" content={renderLegend} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  </Panel>
);

PieBreakdown.propTypes = {
  // eslint-disable-next-line
  data: PropTypes.array.isRequired,
  // eslint-disable-next-line
  content: PropTypes.object.isRequired,
  loadingData: PropTypes.bool.isRequired,
};

export default PieBreakdown;
