import React, { useState } from 'react';
import { Col, Container, Row, CardBody } from 'reactstrap';
import { Buffer } from 'buffer';
import WalmartOnboardForm from './components/WalmartOnboardForm';
import { getCorprateUrl } from '../../../shared/components/domainSupport';

const WalmartOnboardStepTwo = ({ adProgram }) => {
  const [processingRef, setProcessingRef] = useState({});
  const urlParams = new URLSearchParams(window.location.search);
  const debug = urlParams.get('debug');
  return (
    <Container className="dashboard">
      <Row>
        <Col xl={10}>
          <CardBody>
            <h2>Setting Up Walmart{adProgram === 'FREE' ? ' Free' : ''} Ads: Step 2 of 2</h2>
          </CardBody>
        </Col>
      </Row>
      <Row>
        <br />
      </Row>
      <Row>
        <Col xl={5}>
          <CardBody>
            <div className="account__wrapper">
              <h2>SECOND STEP:</h2>
              <br />
              <br />
              <WalmartOnboardForm setProcessingRef={setProcessingRef} debug={debug} adProgram={adProgram} />
            </div>
          </CardBody>
        </Col>
        <Col xl={5}>
          <CardBody>
            <br />
            <br />
            <p>PLEASE NOTE:<br /><br /></p>
            <blockquote><p>By Clicking &apos;Submit&apos;, you agree to our <a href={`${getCorprateUrl()}/terms-of-use`} target="blank">Terms &amp; Conditions</a></p></blockquote>
            <br />
            <blockquote><p>Your name and email address will be use to create an account in our system.</p></blockquote>
            <br />
            <blockquote><p>The Advertiser ID referenced in the pervious step will be used to link your Walmart account with your new user.</p></blockquote>
            <br />
            <br />
            {debug === 'true' && (
              <p>******** DEBUG MODE ********</p>
            )}
            <br />
            {processingRef && JSON.stringify(processingRef) !== JSON.stringify({}) && (
              <div style={{ width: '100%', wordWrap: 'break-word' }}>
                <p>Reference Code: {Buffer.from(JSON.stringify(processingRef)).toString('base64')}</p>
              </div>
            )}
          </CardBody>
        </Col>
      </Row>
      <Row>
        <br />
      </Row>
    </Container>
  );
}

export default WalmartOnboardStepTwo;
