import {
  formatNumberDefaultToZero, formatNumberOnePlaceDefaultToZero, currencyFormatter, formatDecimalToFixedTwoPercent,
} from '../../../../../shared/components/table/functions';

export const columnDefs = [
  {
    field: 'competitor',
    headerName: 'Competitor',
  },
  {
    field: 'impressions',
    headerName: 'Impressions',
    valueFormatter: formatNumberDefaultToZero,
  },
  {
    field: 'clicks',
    headerName: 'Clicks',
    valueFormatter: formatNumberDefaultToZero,
  },
  {
    field: 'ctr',
    headerName: 'CTR',
    valueFormatter: formatDecimalToFixedTwoPercent,
  },
  {
    field: 'spend',
    headerName: 'Spend',
    valueFormatter: currencyFormatter,
  },
  {
    field: 'sales',
    headerName: 'Sales',
    valueFormatter: currencyFormatter,
  },
  {
    field: 'acos',
    headerName: 'AcOS',
    valueFormatter: formatDecimalToFixedTwoPercent,
  },
  {
    field: 'roas',
    headerName: 'ROAS',
    valueFormatter: formatNumberOnePlaceDefaultToZero,
  },
  {
    field: 'orders',
    headerName: 'Orders',
    valueFormatter: formatNumberDefaultToZero,
  },
  {
    field: 'cvr',
    headerName: 'CVR',
    valueFormatter: formatDecimalToFixedTwoPercent,
  },
];

export const defaultColumnDefs = {
  sortable: true,
  filter: true,
  resizable: true,
};
