import React, { PureComponent } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import {
  Container, Row, Col, Card, CardBody,
} from 'reactstrap';
import TopTenBrandMarketShare from './TopTenBrandMarketShare';
import config from '../../../../config';
import { history } from '../../../../helpers';
import HorizontalTwoBar from '../../../../shared/components/widgets/HorizontalTwoBar';
import PieBreakdown from '../../../../shared/components/widgets/PieBreakdown';
import { SellerSelect, User, Theme } from '../../../../shared/prop-types/MainProps';
import style, { DROPDOWN_COLORS } from '../../../../shared/components/themeSupport';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class TopTenBrandMarketShareMain extends PureComponent {
  static propTypes = {
    sellerSelect: SellerSelect.isRequired,
    user: User.isRequired,
    theme: Theme.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      loadingData: false,
      loadingDataWidgets: false,
      // marketShareOptionsJson: {},
      multi: false,
      marketplaceSelectOptions: [],
      marketplaceSelectedOption: undefined,
      typeSelectOptions: [],
      typeSelectedOption: undefined,
      nodeSelectOptions: [],
      nodeSelectedOption: undefined,
      dateSelectOptions: [],
      dateSelectedOption: undefined,
      dateSecondSelectedOption: undefined,
      // catData: [],
      // subcatData: [],
      asinCountData: [],
      pieData: [],
      // unitData: [],
      // revenueData: [],
      noData: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevState) {
    const { sellerSelect } = this.props;
    if (sellerSelect.value !== prevState.sellerSelect.value) {
      this.getData();
    }
  }

  getData = () => {
    const { user, sellerSelect } = this.props;
    // const { nodeSelectedOption, typeSelectedOption } = this.state;
    const headerWithAuth = { authorization: `JWT ${user.jwtToken}` };

    // Clear previous settings
    this.setState({
      marketplaceSelectOptions: [],
      marketplaceSelectedOption: undefined,
      typeSelectOptions: [],
      typeSelectedOption: undefined,
      nodeSelectOptions: [],
      nodeSelectedOption: undefined,
      dateSelectOptions: [],
      dateSelectedOption: undefined,
      dateSecondSelectedOption: undefined,
      loadingData: true,
      loadingDataWidgets: true,
    });

    fetch(`${apiUrl}/vendors/market-share-options?sellerAccountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}`, { headers: headerWithAuth })
      .then((resultsMarketShareOptions) => {
        if (resultsMarketShareOptions.status === 401) {
          history.push('/logout');
        }
        return resultsMarketShareOptions.json();
      })
      .then((data) => {
        if (Object.keys(data).length === 0 && data.constructor === Object) {
          this.setState({
            // catData: [],
            // subcatData: [],
            asinCountData: [],
            pieData: [],
            // unitData: [],
            // revenueData: [],
            marketplaceSelectedOption: {},
            typeSelectedOption: {},
            nodeSelectedOption: {},
            dateSelectedOption: {},
            dateSecondSelectedOption: {},
            loadingData: false,
            loadingDataWidgets: false,
            noData: true,
          });
        }
        const marketShareOptionsJsonArr = data.marketplaces;

        if (marketShareOptionsJsonArr && marketShareOptionsJsonArr.length > 0) {
          const marketplaceSelectOptions = marketShareOptionsJsonArr;
          const [marketplaceSelectedOption] = marketShareOptionsJsonArr;

          if (marketplaceSelectedOption.types && marketplaceSelectedOption.types.length > 0) {
            const typeSelectOptions = marketplaceSelectedOption.types;
            const [typeSelectedOption] = marketplaceSelectedOption.types;

            if (typeSelectedOption.nodes && typeSelectedOption.nodes.length > 0) {
              const nodeSelectOptions = typeSelectedOption.nodes;
              const [nodeSelectedOption] = typeSelectedOption.nodes;

              if (nodeSelectedOption.dates && nodeSelectedOption.dates.length > 0) {
                const dateSelectOptions = nodeSelectedOption.dates;
                const [dateSelectedOption] = nodeSelectedOption.dates;
                const dateSecondSelectedOption = nodeSelectedOption.dates[nodeSelectedOption.dates.length - 1];

                this.setState({
                  marketplaceSelectOptions,
                  marketplaceSelectedOption,
                  typeSelectOptions,
                  typeSelectedOption,
                  nodeSelectOptions,
                  nodeSelectedOption,
                  dateSelectOptions,
                  dateSelectedOption,
                  dateSecondSelectedOption,
                  loadingData: false,
                  noData: false,
                });
              }
            }
          }
        }
      }).catch((error) => {
        console.error(error);
        this.setState({
          loadingData: false,
        });
      });
  }

  handleMarketplaceChange = (selectedValue) => {
    const { marketplaceSelectedOption } = this.state;
    let typeSelectOptions = [];
    let typeSelectedOption = {};
    let nodeSelectOptions = [];
    let nodeSelectedOption = {};
    let dateSelectOptions = [];
    let dateSelectedOption = {};
    let dateSecondSelectedOption = {};
    let loadingData = false;
    if (marketplaceSelectedOption !== selectedValue) {
      loadingData = true;
    }


    if (selectedValue.types && selectedValue.types.length > 0) {
      typeSelectOptions = selectedValue.types;
      [typeSelectedOption] = selectedValue.types;

      if (typeSelectedOption.nodes && typeSelectedOption.nodes.length > 0) {
        nodeSelectOptions = typeSelectedOption.nodes;
        [nodeSelectedOption] = typeSelectedOption.nodes;

        if (nodeSelectedOption.dates && nodeSelectedOption.dates.length > 0) {
          dateSelectOptions = nodeSelectedOption.dates;
          [dateSelectedOption] = nodeSelectedOption.dates;
          dateSecondSelectedOption = nodeSelectedOption.dates[nodeSelectedOption.dates.length - 1];
        }
      }
    }
    this.setState({
      marketplaceSelectedOption: selectedValue,
      typeSelectOptions,
      typeSelectedOption,
      nodeSelectOptions,
      nodeSelectedOption,
      dateSelectOptions,
      dateSelectedOption,
      dateSecondSelectedOption,
      loadingData,
      loadingDataWidgets: loadingData,
    });
  }

  handleTypeChange = (selectedValue) => {
    const { typeSelectedOption } = this.state;
    let nodeSelectOptions = [];
    let nodeSelectedOption = {};
    let dateSelectOptions = [];
    let dateSelectedOption = {};
    let dateSecondSelectedOption = {};
    let loadingData = false;
    if (typeSelectedOption !== selectedValue) {
      loadingData = true;
    }

    if (selectedValue.nodes && selectedValue.nodes.length > 0) {
      nodeSelectOptions = selectedValue.nodes;
      [nodeSelectedOption] = selectedValue.nodes;

      if (nodeSelectedOption.dates && nodeSelectedOption.dates.length > 0) {
        dateSelectOptions = nodeSelectedOption.dates;
        [dateSelectedOption] = nodeSelectedOption.dates;
        dateSecondSelectedOption = nodeSelectedOption.dates[nodeSelectedOption.dates.length - 1];
      }
    }
    this.setState({
      typeSelectedOption: selectedValue,
      nodeSelectOptions,
      nodeSelectedOption,
      dateSelectOptions,
      dateSelectedOption,
      dateSecondSelectedOption,
      loadingData,
      loadingDataWidgets: loadingData,
    });
  }

  handleNodeChange = (selectedValue) => {
    const { nodeSelectedOption } = this.state;
    let dateSelectOptions = [];
    let dateSelectedOption = {};
    let dateSecondSelectedOption = {};
    let loadingData = false;
    if (nodeSelectedOption !== selectedValue) {
      loadingData = true;
    }

    if (selectedValue.dates && selectedValue.dates.length > 0) {
      dateSelectOptions = selectedValue.dates;
      [dateSelectedOption] = selectedValue.dates;
      dateSecondSelectedOption = selectedValue.dates[selectedValue.dates.length - 1];
    }
    this.setState({
      nodeSelectedOption: selectedValue,
      dateSelectOptions,
      dateSelectedOption,
      dateSecondSelectedOption,
      loadingData,
      loadingDataWidgets: loadingData,
    });
  }

  handleDateChange = (selectedValue) => {
    const { dateSelectedOption } = this.state;
    if (dateSelectedOption !== selectedValue) {
      this.setState({
        dateSelectedOption: selectedValue,
        loadingData: true,
        loadingDataWidgets: true,
      });
    }
  }

  handleSecondDateChange = (selectedValue) => {
    const { dateSecondSelectedOption } = this.state;
    if (dateSecondSelectedOption !== selectedValue) {
      this.setState({
        dateSecondSelectedOption: selectedValue,
        loadingData: true,
        loadingDataWidgets: true,
      });
    }
  }

  setData = (inData) => {
    const { noData } = this.state;
    // const sortCatData = Array.prototype.slice.call(inData).sort((a, b) => {
    //   const checkPos = (a.second_cat_rank < b.second_cat_rank) ? -1 : 0;
    //   return (a.second_cat_rank > b.second_cat_rank) ? 1 : checkPos;
    // }).filter(c => c.second_cat_rank > 0);

    // const sortSubcatData = Array.prototype.slice.call(inData).sort((a, b) => {
    //   const checkPos = (a.second_subcat_rank < b.second_subcat_rank) ? -1 : 0;
    //   return (a.second_subcat_rank > b.second_subcat_rank) ? 1 : checkPos;
    // }).filter(c => c.second_cat_rank > 0);

    const sortAsinCountData = Array.prototype.slice.call(inData).sort((a, b) => {
      const checkPos = (a.second_asin_count > b.second_asin_count) ? -1 : 0;
      return (a.second_asin_count < b.second_asin_count) ? 1 : checkPos;
    });

    const sortRevenueData = Array.prototype.slice.call(inData).sort((a, b) => {
      const checkPos = (a.second_revenue > b.second_revenue) ? -1 : 0;
      return (a.second_revenue < b.second_revenue) ? 1 : checkPos;
    });

    // const sortUnitData = Array.prototype.slice.call(inData).sort((a, b) => {
    //   const checkPos = (a.second_units > b.second_units) ? -1 : 0;
    //   return (a.second_units < b.second_units) ? 1 : checkPos;
    // });

    const COLORS = ['#03c03c', '#36c9c9', '#fbb054', '#df7252', '#d04960', '#C0C0C0'];
    const outPieData = [];
    // let remainingRevenue = 0;
    sortRevenueData.forEach((dataItem, index) => {
      if (index < 5) {
        outPieData.push({
          name: dataItem.brand,
          second_revenue: dataItem.second_revenue,
          fill: COLORS[index],
        });
      } // else {
      //   remainingRevenue += (dataItem.second_revenue || 0);
      // }
    });

    if (inData) {
      inData.forEach((row) => {
        outPieData.forEach((pieItem) => {
          // eslint-disable-next-line
          pieItem.total += row[pieItem.name];
        });
      });
    }


    if (!noData) {
      this.setState({
        // catData: sortCatData,
        // subcatData: sortSubcatData,
        asinCountData: sortAsinCountData,
        // unitData: sortUnitData,
        pieData: outPieData,
        // revenueData: sortRevenueData,
        loadingData: false,
        loadingDataWidgets: false,
      });
    }
  }

  render() {
    const {
      nodeSelectedOption, nodeSelectOptions, typeSelectedOption, typeSelectOptions, dateSelectedOption, multi, dateSelectOptions, loadingData,
      marketplaceSelectedOption, marketplaceSelectOptions, dateSecondSelectedOption, loadingDataWidgets, noData, asinCountData, pieData,
      // catData, unitData, revenueData,
    } = this.state;
    const { theme, sellerSelect } = this.props;
    const themeColors = style(theme, DROPDOWN_COLORS);

    return (
      <Container className="dashboard">
        <Row>
          <Col md={3}>
            <Card>
              <CardBody className="panel__body">
                {(loadingData) ? <div className="panel__refresh" /> : ''}
                <h3 className="page-title">Marketplace</h3>
                <div className="topbar__dynamicDropdownWidth">
                  <Select
                    value={marketplaceSelectedOption}
                    onChange={this.handleMarketplaceChange}
                    options={marketplaceSelectOptions}
                    theme={selectTheme => ({
                      ...selectTheme,
                      colors: {
                        ...selectTheme.colors,
                        primary: `${themeColors.colorPrimary}`,
                        neutral0: `${themeColors.colorBackground}`,
                        neutral80: `${themeColors.colorText}`,
                        neutral10: `${themeColors.colorPrimary}`,
                      },
                    })}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={2}>
            <Card>
              <CardBody className="panel__body">
                {(loadingData) ? <div className="panel__refresh" /> : ''}
                <h3 className="page-title">Type</h3>
                <div className="topbar__dynamicDropdownWidth">
                  <Select
                    value={typeSelectedOption}
                    onChange={this.handleTypeChange}
                    options={typeSelectOptions}
                    theme={selectTheme => ({
                      ...selectTheme,
                      colors: {
                        ...selectTheme.colors,
                        primary: `${themeColors.colorPrimary}`,
                        neutral0: `${themeColors.colorBackground}`,
                        neutral80: `${themeColors.colorText}`,
                        neutral10: `${themeColors.colorPrimary}`,
                      },
                    })}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={5}>
            <Card>
              <CardBody className="panel__body">
                {(loadingData) ? <div className="panel__refresh" /> : ''}
                <h3 className="page-title">Available Reports</h3>
                <div className="topbar__dynamicDropdownWidth">
                  <Select
                    value={nodeSelectedOption}
                    onChange={this.handleNodeChange}
                    options={nodeSelectOptions}
                    theme={selectTheme => ({
                      ...selectTheme,
                      colors: {
                        ...selectTheme.colors,
                        primary: `${themeColors.colorPrimary}`,
                        neutral0: `${themeColors.colorBackground}`,
                        neutral80: `${themeColors.colorText}`,
                        neutral10: `${themeColors.colorPrimary}`,
                      },
                    })}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={2}>
            <Card>
              <CardBody className="panel__body">
                {(loadingData) ? <div className="panel__refresh" /> : ''}
                <h3 className="page-title">Report Dates</h3>
                <div className="topbar__dynamicDropdownWidth">
                  <p>Date 1:
                    <Select
                      value={dateSelectedOption}
                      onChange={this.handleDateChange}
                      options={dateSelectOptions}
                      theme={selectTheme => ({
                        ...selectTheme,
                        colors: {
                          ...selectTheme.colors,
                          primary: `${themeColors.colorPrimary}`,
                          neutral0: `${themeColors.colorBackground}`,
                          neutral80: `${themeColors.colorText}`,
                          neutral10: `${themeColors.colorPrimary}`,
                        },
                      })}
                      isMulti={multi}
                    />
                  </p>
                  <p>Date 2:
                    <Select
                      value={dateSecondSelectedOption}
                      onChange={this.handleSecondDateChange}
                      options={dateSelectOptions}
                      theme={selectTheme => ({
                        ...selectTheme,
                        colors: {
                          ...selectTheme.colors,
                          primary: `${themeColors.colorPrimary}`,
                          neutral0: `${themeColors.colorBackground}`,
                          neutral80: `${themeColors.colorText}`,
                          neutral10: `${themeColors.colorPrimary}`,
                        },
                      })}
                      isMulti={multi}
                    />
                  </p>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <PieBreakdown
            data={pieData}
            content={{
              title: 'Revenue',
              subtitle: 'Second Date Top 5 Brands',
              outerDataKey: 'second_revenue',
            }}
            loadingData={loadingDataWidgets}
          />
          <HorizontalTwoBar
            data={asinCountData.slice(0, 9)}
            content={{
              title: 'D1 ASIN Count vs D2 ASIN Count',
              subtitle: 'by Brand',
              dataKey: 'brand',
              firstDataKey: 'asin_count',
              secondDataKey: 'second_asin_count',
              firstTitle: 'D1 ASIN Count',
              secondTitle: 'D2 ASIN Count',
              data: asinCountData.slice(0, 9),
            }}
            loadingData={loadingDataWidgets}
          />
        </Row>
        {!noData && marketplaceSelectedOption && typeSelectedOption && nodeSelectedOption && dateSelectedOption && dateSecondSelectedOption && (
          <Row>
            <TopTenBrandMarketShare
              firstTableId={`${marketplaceSelectedOption.value}_${typeSelectedOption.value}_${nodeSelectedOption.value}_${dateSelectedOption.value}`}
              secondTableId={`${marketplaceSelectedOption.value}_${typeSelectedOption.value}_${nodeSelectedOption.value}_${dateSecondSelectedOption.value}`}
              sellerAccountId={sellerSelect.value}
              parentAccountId={sellerSelect.parentAccountId}
              setPageData={this.setData}
            />
          </Row>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { theme, sellerSelect } = state;
  const { user } = state.authentication;

  return {
    theme,
    user,
    sellerSelect,
  };
};

export default connect(mapStateToProps)(TopTenBrandMarketShareMain);
