/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import Lbb from './components/Lbb';
import LbbBoxes from './components/LbbBoxes';
import LbbGraph from './components/LbbGraph';
// import LbbButtons from './components/LbbButtons';
import DateRangePickerPanel from '../../../shared/components/widgets/DateRangePickerPanel';
import config from '../../../config';
import { SellerSelect, User } from '../../../shared/prop-types/MainProps';


const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class LbbPage extends PureComponent {
  static propTypes = {
    sellerSelect: SellerSelect.isRequired,
    user: User.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      graphData: [],
      initalLoad: false,
      graphLoad: false,
      reportStartDate: moment().subtract(11, 'days').format('YYYY-MM-DD'),
      reportEndDate: moment().subtract(4, 'days').format('YYYY-MM-DD'),
      showingAll: false,
      pivotOn: 'ASIN',
      checkedItem: new Set(),
      totalRevenue: 0,
      totalLostRevenue: 0,
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevState) {
    const { sellerSelect } = this.props;
    if (sellerSelect.value !== prevState.sellerSelect.value) {
      this.getData();
    }
  }

  onDateChange = (startDate, endDate) => {
    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).format('YYYY-MM-DD');
    this.setState({
      reportStartDate: start,
      reportEndDate: end,
    }, this.getData);
  }

  toggleDisplay = () => {
    this.setState(prevState => ({
      showingAll: !prevState.showingAll,
    }));
  }

  // setPivot = (pivotOn) => {
  //   this.setState({
  //     pivotOn,
  //   }, () => {
  //     this.getData();
  //   });
  // }

  handleCheckboxChange = (event) => {
    const { checkedItem } = this.state;
    const updateSet = new Set(checkedItem);
    if (updateSet.has(event.target.id)) {
      updateSet.delete(event.target.id);
    } else {
      updateSet.add(event.target.id);
    }

    this.setState({
      checkedItem: updateSet,
      graphLoad: true,
    }, () => {
      this.getGraphData();
    });
  }

  pivotData = (data, pivotType) => {
    if (pivotType === 'ASIN') {
      const newData = [];
      data.forEach((row) => {
        let orderedSales = 0;
        let lostSales = 0;
        if (row.ordered_units > 0) {
          if (row.ordered_units && row.list_price) {
            orderedSales = (row.ordered_units * row.list_price);
          }
          if (row.lbb > 0 && row.lbb <= 0.95) {
            lostSales = ((row.ordered_units * row.list_price) / (1 - row.lbb)) - (row.ordered_units * row.list_price);
          } else if (row.lbb > 0.95) { // if greater than 95% treat order_units as 1
            lostSales += row.list_price;
          }
        }

        newData.push({
          ...row,
          orderedSales,
          lostSales,
        });
      });

      return newData;
    }
    const pivotData = data.reduce((list, row) => {
      const driverColumn = row[pivotType.toLowerCase()];
      if (driverColumn !== undefined) {
        const currentObj = list.get(driverColumn);
        if (currentObj) {
          if (row.ordered_units > 0) {
            currentObj.orderedSales += (row.orderedSales || 0) + (row.ordered_units * row.list_price);
            if (row.lbb > 0 && row.lbb > 0.95) {
              currentObj.lostSales += ((row.lostSales || 0) + ((row.ordered_units * row.list_price) / (1 - row.lbb)) - (row.ordered_units * row.list_price));
            } else if (row.lbb > 0.95) { // if greater than 95% treat order_units as 1
              currentObj.lostSales += row.list_price;
            }
            list.set(driverColumn, currentObj);
          }
        } else {
          const newObj = {
            parent_asin: driverColumn,
            brand: driverColumn,
            segment_one: driverColumn,
            segment_two: driverColumn,
            segment_three: driverColumn,
            segment_four: driverColumn,
            segment_five: driverColumn,
            category: driverColumn,
            subcategory: driverColumn,
          };
          if (row.ordered_units > 0) {
            newObj.orderedSales = (row.ordered_units * row.list_price);
            if (row.lbb > 0 && row.lbb > 0.95) {
              newObj.lostSales = ((row.ordered_units * row.list_price) / (1 - row.lbb)) - (row.ordered_units * row.list_price);
            } else if (row.lbb > 0.95) { // if greater than 95% treat order_units as 1
              newObj.lostSales += row.list_price;
            } else {
              newObj.lostSales = 0;
            }
          }
          list.set(driverColumn, newObj);
        }
      }
      return list;
    }, new Map());

    return pivotData.values();
  }

  getGraphData = () => {
    const { sellerSelect, user } = this.props;
    const {
      reportStartDate, reportEndDate, pivotOn, checkedItem,
    } = this.state;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    // Use same requestOptions as above
    const salesMfgGraphUrl = `${apiUrl}/vendors/lost-sales-graph?sellerAccountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}&startDate=${reportStartDate}&endDate=${reportEndDate}&columnType=${pivotOn}&itemList=${Array.from(checkedItem).join(',')}`;
    fetch(salesMfgGraphUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((graphData) => {
        const sortData = graphData.sort((a, b) => {
          const checkPos = (b.report_date > a.report_date) ? -1 : 0;
          return (b.report_date < a.report_date) ? 1 : checkPos;
        });

        this.setState({
          graphLoad: false,
          graphData: sortData,
        });
      }).catch(() => {
        this.setState({
          graphLoad: false,
        });
      });
  }

  getData = () => {
    const { sellerSelect, user } = this.props;
    const {
      reportStartDate, reportEndDate, pivotOn, checkedItem,
    } = this.state;
    const mfgUrl = `${apiUrl}/vendors/enhanced-manufacturing?sellerAccountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}&startDate=${reportStartDate}&endDate=${reportEndDate}`;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    this.setState({
      initalLoad: true,
      graphLoad: true,
    });

    fetch(mfgUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        const pivotedData = this.pivotData(data, pivotOn);

        const sortData = pivotedData.sort((a, b) => {
          const checkPos = (b.lostSales < a.lostSales) ? -1 : 0;
          return (b.lostSales > a.lostSales) ? 1 : checkPos;
        });

        let totalRevenue = 0;
        let totalLostRevenue = 0;

        sortData.forEach((item) => {
          totalRevenue += item.orderedSales || 0;
          totalLostRevenue += item.lostSales || 0;
        });

        this.setState({
          initalLoad: false,
          data: sortData,
          totalRevenue,
          totalLostRevenue,
        });

        let lineCount = 5;
        if (sortData.length < 5) {
          lineCount = sortData.length;
        }

        for (let i = 0; i < lineCount; i += 1) {
          if (sortData[i][pivotOn.toLowerCase()]) {
            checkedItem.add(sortData[i][pivotOn.toLowerCase()]);
          }
        }

        this.setState({
          checkedItem,
        });

        this.getGraphData();
      }).catch(() => {
        this.setState({
          initalLoad: false,
        });
      });
  };

  render() {
    const {
      data, graphData, initalLoad, graphLoad, reportStartDate, reportEndDate, showingAll, pivotOn, checkedItem, totalRevenue, totalLostRevenue,
    } = this.state;

    const { sellerSelect } = this.props;

    if (sellerSelect.type === 2) {
      return (
        <Container className="dashboard">
          <Col md={12}>
            <Row>
              <DateRangePickerPanel
                reportStartDate={reportStartDate}
                reportEndDate={reportEndDate}
                onDateChange={this.onDateChange}
              />
            </Row>
          </Col>
          <Col md={12}>
            <Row>
              <LbbGraph
                data={graphData}
                dataType="report_date"
                title="Sales ordered and sales lost by ASIN"
                initalLoad={graphLoad}
                lineList={Array.from(checkedItem)}
              />
            </Row>
          </Col>
          {/* <Col md={12}>
            <Row>
              <LbbButtons
                setPivot={this.setPivot}
                accountType={sellerSelect.type}
              />
            </Row>
          </Col> */}
          <Col md={12}>
            <LbbBoxes actualRevenue={totalRevenue} lostRevenueToLBB={totalLostRevenue} initalLoad={initalLoad} />
          </Col>
          <Col md={12}>
            <Row>
              <Lbb
                newOrder={data}
                title="LBB"
                subhead="Top 10 LBB"
                initalLoad={initalLoad}
                reportStartDate={reportStartDate}
                reportEndDate={reportEndDate}
                showingAll={showingAll}
                toggleDisplay={this.toggleDisplay}
                pivotOn={pivotOn}
                handleCheckboxChange={this.handleCheckboxChange}
                checkedItem={checkedItem}
                graphLoad={graphLoad}
              />
            </Row>
          </Col>
        </Container>
      );
    }
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <p>This is a Vendor specific report. Please select a new report or select a Vendor account.</p>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { sellerSelect } = state;
  const { user } = state.authentication;

  return {
    sellerSelect,
    user,
  };
};

export default connect(mapStateToProps)(LbbPage);
