import React, { PureComponent } from 'react';
import config from '../../../../config';
import TableBuilder from '../../../../shared/components/table/TableBuilder';
import {
  currencyFormatter, getExportPrefix, toDisplayDate,
} from '../../../../shared/components/table/functions';
import { imageFieldColumn, imageCellRendererComponent } from '../../../../shared/components/table/ImageCellRenderer';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class SkuTable extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        imageFieldColumn,
        { field: 'sku', headerName: 'SKU' },
        { field: 'asin', headerName: 'ASIN' },
        { field: 'product_title', headerName: 'Title' },
        {
          field: 'list_price',
          headerName: 'Price',
          valueFormatter: currencyFormatter,
        },
        {
          field: 'open_date',
          headerName: 'Open Date',
          valueFormatter: toDisplayDate,
        },
        {
          field: 'unit_cog',
          headerName: 'Unit COG',
          valueFormatter: currencyFormatter,
        },
        {
          field: 'brand',
          headerName: 'Brand',
        },
        {
          field: 'ad_status',
          headerName: 'Ad Status',
          cellEditor: 'agSelectCellEditor',
          cellEditorParams: {
            values: ['on', 'off'],
          },
        },
        {
          field: 'reporting_visibility',
          headerName: 'Reporting Visibility',
          cellEditor: 'agSelectCellEditor',
          cellEditorParams: {
            values: ['on', 'off'],
          },
        },
        {
          field: 'segment_one',
          headerName: 'Segment 1',
        },
        {
          field: 'segment_two',
          headerName: 'Segment 2',
        },
        {
          field: 'segment_three',
          headerName: 'Segment 3',
        },
        {
          field: 'segment_four',
          headerName: 'Segment 4',
        },
        {
          field: 'segment_five',
          headerName: 'Segment 5',
        },
        {
          field: 'size',
          headerName: 'Size',
        },
        {
          field: 'color',
          headerName: 'Color',
        },
        {
          field: 'budget_group',
          headerName: 'Budget Group',
        },
      ],
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        cellStyle: (params) => {
          if (params.value < 0.00) {
            return { color: 'red' };
          }
          return null;
        },
      },
      frameworkComponents: {
        ...imageCellRendererComponent,
      },
      showDatePicker: false,
      showFooter: false,
    };
  }

  render() {
    const {
      columnDefs, defaultColDef, onCellEditingStopped, showDatePicker, showFooter, frameworkComponents,
    } = this.state;
    const { sellerSelect } = this.props;

    const apiEndPoint = `${apiUrl}/sellers/sku-catalog-deprecated`;
    const exportPrefix = getExportPrefix('amz-catalog', sellerSelect)

    return (
      <TableBuilder
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        apiEndPoint={apiEndPoint}
        onCellEditingStopped={onCellEditingStopped}
        showDatePicker={showDatePicker}
        showFooter={showFooter}
        frameworkComponents={frameworkComponents}
        rowHeight={75}
        exportPrefix={exportPrefix}
        autoSizeAll
      />
    );
  }
}

export default SkuTable;
