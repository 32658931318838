import React from 'react';
import {
  Container, Row,
} from 'reactstrap';
import TopTenAsinMarketShareMain from './components/TopTenAsinMarketShareMain';

const TopTenAsinMarketSharePage = () => (
  <Container className="dashboard">
    <Row>
      <TopTenAsinMarketShareMain />
    </Row>
  </Container>
);

export default TopTenAsinMarketSharePage;
