import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

/* eslint react/prop-types: 0 */
// eslint-disable-next-line
const AccountControlRoute = ({ loggedIn, user, component: Component, ...rest }) => {
  const accessControl = JSON.parse(user.access_control);

  return (
    <Route
      {...rest}
      render={props => (
        (loggedIn && (user.access === 'admin' || accessControl.accountControlRole))
          ? <Component {...props} />
          : <Redirect to={{ pathname: '/logout', state: { from: props.location } }} />
      )}
    />
  );
};

const mapStateToProps = (state) => {
  const { loggedIn, user } = state.authentication;
  return {
    loggedIn,
    user,
  };
};

export default connect(mapStateToProps)(AccountControlRoute);
