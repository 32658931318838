import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const SubscriptionRoute = ({
  component: Component, ...rest
}) => {
  const sellerSelect = useSelector(state => state.sellerSelect);
  const loggedIn = useSelector(state => state.authentication.loggedIn);
  const platform = sellerSelect.platform || 'limitless';

  return (
    <Route
      {...rest}
      render={props => (
        (loggedIn && platform === 'adrev')
          ? <Component {...props} {...rest} />
          : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )}
    />
  );
};

export default SubscriptionRoute;
