import React, { PureComponent } from 'react';
import {
  Col, Row,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import config from '../../../config';
import { User } from '../../../shared/prop-types/MainProps';
import { userActions } from '../../../redux/actions';

const pjson = require('../../../../package.json');

const serverUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;
const uploadUrl = config.isProdEnv ? config.uploadProdUrl : config.uploadDevUrl;

class statusPage extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    user: User.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      getServer: { color: 'BLUE', status: 'Loading...' },
      postServer: { color: 'BLUE', status: 'Loading...' },
      upload: { color: 'BLUE', status: 'Loading...' },
      postBody: '',
      decryptValue: '',
      encryptValue: '',
      decodedValue: '',
      encodedValue: '',
    };
  }

  componentDidMount() {
    this.getServerStatus();
    this.postServerStatus();
    this.getUploadStatus();
  }

  getServerStatus = () => {
    const requestOptions = {
      method: 'GET',
    };

    // Call via API (would like to update to " await ")
    fetch(serverUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        let getServer = { color: 'YELLOW', status: 'ISSUES' };
        if (data.status === 'UP') {
          getServer = { color: 'GREEN', status: 'UP' };
        }

        this.setState({
          getServer,
        });
      }).catch(() => {
        this.setState({
          getServer: { color: 'RED', status: 'DOWN' },
        });
      });
  };

  postServerStatus = () => {
    const requestOptions = {
      method: 'POST',
      headers: config.jsonHeader,
      body: JSON.stringify({
        testOne: 'ONE',
        testTwo: 2,
        testThree: true,
      }),
    };

    // Call via API (would like to update to " await ")
    fetch(serverUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        let postServer = { color: 'YELLOW', status: 'ISSUES' };
        if (data.status === 'UP') {
          postServer = { color: 'GREEN', status: 'UP' };
        }

        this.setState({
          postServer,
          postBody: JSON.stringify(data.body),
        });
      }).catch(() => {
        this.setState({
          postServer: { color: 'RED', status: 'DOWN' },
        });
      });
  };

  getUploadStatus = () => {
    const requestOptions = {
      method: 'GET',
    };

    // Call via API (would like to update to " await ")
    fetch(uploadUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        let upload = { color: 'YELLOW', status: 'ISSUES' };
        if (data.status === 'UP') {
          upload = { color: 'GREEN', status: 'UP' };
        }

        this.setState({
          upload,
        });
      }).catch(() => {
        this.setState({
          upload: { color: 'RED', status: 'DOWN' },
        });
      });
  };

  handleClick = (retry) => {
    if (retry === 'getServerStatus') {
      this.getServerStatus();
    }
    if (retry === 'postServerStatus') {
      this.postServerStatus();
    }
    if (retry === 'getUploadStatus') {
      this.getUploadStatus();
    }
  };

  handleRefreshLogin = () => {
    const { dispatch, user } = this.props;
    dispatch(userActions.refreshLogin(user));
  };

  saveCryptoChange = (e) => {
    if (e.target.id === 'decrypted') {
      this.setState({
        decryptValue: e.target.value,
      });
    } else if (e.target.id === 'encrypted') {
      this.setState({
        encryptValue: e.target.value,
      });
    }
  };

  saveBase64Change = (e) => {
    if (e.target.id === 'decoded') {
      this.setState({
        decodedValue: e.target.value,
      });
    } else if (e.target.id === 'encoded') {
      this.setState({
        encodedValue: e.target.value,
      });
    }
  };

  handleCrypto = (direction) => {
    const { user } = this.props;
    const { decryptValue, encryptValue } = this.state;
    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    let inputValue = '';

    if (direction === 'decrypt') {
      inputValue = encryptValue;
    } else if (direction === 'encrypt') {
      inputValue = decryptValue;
    }

    const cryptoUrl = `${serverUrl}/accounts/two-way-crypto?value=${inputValue}&direction=${direction}`;

    // Call via API (would like to update to " await ")
    fetch(cryptoUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        if (direction === 'decrypt') {
          this.setState({
            decryptValue: data.returnValue,
          });
        } else if (direction === 'encrypt') {
          this.setState({
            encryptValue: data.returnValue,
          });
        }
      }).catch((error) => {
        if (direction === 'decrypt') {
          this.setState({
            decryptValue: error,
          });
        } else if (direction === 'encrypt') {
          this.setState({
            encryptValue: error,
          });
        }
      });
  };

  handleBase64 = (direction) => {
    const { decodedValue, encodedValue } = this.state;

    if (direction === 'decode') {
      this.setState({
        decodedValue: atob(encodedValue),
      });
    } else if (direction === 'encode') {
      this.setState({
        encodedValue: btoa(decodedValue),
      });
    }
  };

  render() {
    const { user } = this.props;
    const {
      getServer, postServer, postBody, upload, decryptValue, encryptValue, encodedValue, decodedValue,
    } = this.state;

    const asText = {
      background: 'none',
      border: 'none',
      margin: '0',
      padding: '0',
      cursor: 'pointer',
    };

    const urlParams = new URLSearchParams(window.location.search);
    const paramRefresh = urlParams.get('refresh');

    return (
      <Row>
        <Col md={3}>
          <div className="account__wrapper">
            <div className="account__card">
              <h1 style={{ textAlign: 'center' }}>
                Internal Systems<b />
              </h1>
              <br />
              <h4>WEBSITE (SPA): <span style={{ color: 'GREEN' }}>UP</span></h4>
              <br />
              <h4 style={{ whiteSpace: 'nowrap' }}>SERVER (POST) via CORS:  <span style={{ color: postServer.color }}>{postServer.status}</span><br />(<button style={asText} type="button" onClick={this.handleClick.bind(this, 'getServerStatus')}>retry</button>)</h4>
              <div
                id="serverPostResponse"
                style={{
                  border: '1px', borderColor: 'gray', width: '100%', height: '75px', borderStyle: 'solid', overflowY: 'auto',
                }}
              >
                <p>{postBody}</p>
              </div>
              <br />
              <h4 style={{ whiteSpace: 'nowrap' }}>SERVER (GET) via CORS:  <span style={{ color: getServer.color }}>{getServer.status}</span><br />(<button style={asText} type="button" onClick={this.handleClick.bind(this, 'postServerStatus')}>retry</button>)</h4>
              <iframe width="100%" height="75" title="Server" src={serverUrl} />
              <br /><br />
              <h4 style={{ whiteSpace: 'nowrap' }}>UPLOAD via CORS: <span style={{ color: upload.color }}>{upload.status}</span><br />(<button style={asText} type="button" onClick={this.handleClick.bind(this, 'getUploadStatus')}>retry</button>)</h4>
              <iframe width="100%" height="75" title="Upload" src={uploadUrl} />
            </div>
          </div>
        </Col>
        <Col md={3}>
          <div className="account__wrapper">
            <div className="account__card">
              <h1 style={{ textAlign: 'center' }}>
                Status Key<b />
              </h1>
              <br />
              <h4><span style={{ color: 'BLUE' }}>BLUE: Loading</span></h4><br />
              <h4><span style={{ color: 'GREEN' }}>GREEN: UP</span> Good</h4><br />
              <h4><span style={{ color: 'YELLOW' }}>YELLOW: ISSUES</span> The URL is responding, but there is an issue</h4><br />
              <h4><span style={{ color: 'RED' }}>RED: DOWN</span> The URL is not accessable via CORS.  If content comes back in the box the server is up.</h4>
              <br />
              <br />
            </div>
          </div>
        </Col>
        <Col md={3}>
          <div className="account__wrapper">
            <div className="account__card">
              <h1 style={{ textAlign: 'center' }}>
                System Version<br /><br />
                {config.isProdEnv ? pjson.version : 'vDevelopment'}
              </h1>
              <br />
              <br />
              <br />
              <br />
              <br />
              <h4>
                Links to External Systems:<b />
              </h4>
              <br />
              <ul>
                <li><a href="https://health.aws.amazon.com/health/status" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', fontSize: '20px' }}>AWS System Status</a></li>
                <br />
                <li><a href="https://status.ads.amazon.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', fontSize: '20px' }}>Amazon ADs Status</a></li>
                <br />
                <li><a href="https://status.cloud.google.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', fontSize: '20px' }}>Google System Status</a></li>
                <br />
                <li><a href="https://developer.walmart.com/apiStatus" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', fontSize: '20px' }}>Walmart MarketPlace API Status</a></li>
              </ul>
              <br />
            </div>
          </div>
        </Col>
        <Col md={3}>
          {user && user.access === 'admin' && (
            <>
              <div className="account__wrapper">
                <div className="account__card">
                  <h1 style={{ textAlign: 'center' }}>
                    Encrypt/Decrypt<b />
                  </h1>
                  {user && paramRefresh === 'on' && (
                    <div>
                      <br />
                      <br />
                      <p>{user.email}</p>
                      <p>{user.access_control}</p>
                      <p><button type="button" className="btn btn-primary account__btn" onClick={this.handleRefreshLogin}>Refresh Login</button></p>
                    </div>
                  )}
                  <div>
                    <br />
                    <br />
                    <p>
                      Decrypted Value: <input type="text" id="decrypted" style={{ width: '100%' }} value={decryptValue} onChange={this.saveCryptoChange} />
                      <br />
                      <br />
                      Encrypted Value: <input type="text" id="encrypted" style={{ width: '100%' }} value={encryptValue} onChange={this.saveCryptoChange} />
                      <br />
                      <br />
                      <br />
                      <button type="button" className="btn btn-primary account__btn" onClick={this.handleCrypto.bind(this, 'encrypt')}>Encrypt</button>
                      <button type="button" className="btn btn-primary account__btn" onClick={this.handleCrypto.bind(this, 'decrypt')}>Decrypt</button>
                    </p>
                  </div>
                  <br />
                </div>
              </div>
              <hr />
              <div className="account__wrapper">
                <div className="account__card">
                  <h1 style={{ textAlign: 'center' }}>
                    Encode/Decode<b />
                  </h1>
                  {user && paramRefresh === 'on' && (
                    <div>
                      <br />
                      <br />
                      <p>{user.email}</p>
                      <p>{user.access_control}</p>
                      <p><button type="button" className="btn btn-primary account__btn" onClick={this.handleRefreshLogin}>Refresh Login</button></p>
                    </div>
                  )}
                  <div>
                    <br />
                    <br />
                    <p>
                      Decoded Value: <input type="text" id="decoded" style={{ width: '100%' }} value={decodedValue} onChange={this.saveBase64Change} />
                      <br />
                      <br />
                      Encoded Value: <input type="text" id="encoded" style={{ width: '100%' }} value={encodedValue} onChange={this.saveBase64Change} />
                      <br />
                      <br />
                      <br />
                      <button type="button" className="btn btn-primary account__btn" onClick={this.handleBase64.bind(this, 'encode')}>Encode</button>
                      <button type="button" className="btn btn-primary account__btn" onClick={this.handleBase64.bind(this, 'decode')}>Decode</button>
                    </p>
                  </div>
                  <br />
                </div>
              </div>
            </>
          )}
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state.authentication;

  return {
    user,
  };
};

export default connect(mapStateToProps)(statusPage);
