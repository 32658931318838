import React from 'react';
import PropTypes from 'prop-types';
import AccountDataPointPanel from './AccountDataPointPanel';
import { formatDecimalToFixedTwoPercent } from '../../../../shared/components/table/functions';

export default function TotalAcos({
  data, loading, dateView, account,
}) {
  const revenue = (myData, myAccount) => {
    let total = 0;
    if (myData) {
      total = myData.reduce((sum, item) => {
        if (myAccount?.type === 2) {
          if (item.ordered_revenue) {
            return sum + item.ordered_revenue;
          }
        } else if (myAccount?.type === 1) {
          if (item.gross_sales) {
            return sum + item.gross_sales;
          }
        }
        return sum;
      }, 0);
    }
    return total;
  };

  const spend = (myData) => {
    let total = 0;
    if (myData) {
      total = myData.reduce((sum, item) => {
        if (item.spend) {
          return sum + item.spend;
        }
        return sum;
      }, 0);
    }
    return total;
  };

  return (
    <AccountDataPointPanel
      totalFunction={() => spend(data) / revenue(data, account)}
      data={data}
      title="Total ACoS"
      dayField="tacos"
      formatFunction={item => formatDecimalToFixedTwoPercent({ value: item })}
      barColor="#fbb054"
      loading={loading}
      dateView={dateView}
    />
  );
}

TotalAcos.defaultProps = {
  data: null,
};

TotalAcos.propTypes = {
  loading: PropTypes.bool.isRequired,
  dateView: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(Array),
  account: PropTypes.instanceOf(Object).isRequired,
};
