import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import config from '../../../../config';
import Panel from '../../../../shared/components/Panel';

export default function ContactCustomerView(props) {
  const customeMessageDefault = <>&#123;&#123;customMessage&#125;&#125;</>;
  const user = useSelector(state => state.authentication.user);
  const [generalUpdateCustomMessage, setGeneralUpdateCustomMessage] = useState(customeMessageDefault);
  const [generalUpdateSubject, setGeneralUpdateSubject] = useState('AdRev Update');
  const [generalUpdateLoading, setGeneralUpdateLoading] = useState(false);
  const [generalUpdateStatus, setGeneralUpdateStatus] = useState(false);
  const [trialSetupIssuesCustomMessage, setTrialSetupIssuesCustomMessage] = useState(customeMessageDefault);
  const [trialSetupIssuesLoading, setTrialSetupIssuesLoading] = useState(false);
  const [trialSetupIssuesStatus, setTrialSetupIssuesStatus] = useState(false);
  const [trialStartedCustomMessage, setTrialStartedCustomMessage] = useState(customeMessageDefault);
  const [trialStartedLoading, seTtrialStartedlaoding] = useState(false);
  const [trialStartedStatus, seTtrialStartedStatus] = useState(false);

  const {
    loading, results, error, platform,
  } = props;

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  const cleanGeneralUpdateCustomMessage = () => {
    setGeneralUpdateCustomMessage(customeMessageDefault || '');
    setGeneralUpdateStatus('');
    setGeneralUpdateSubject('AdRev Update');
  }

  const cleanTrialSetupIssuesCustomMessage = () => {
    setTrialSetupIssuesCustomMessage(customeMessageDefault || '');
    setTrialSetupIssuesStatus('');
  }

  const cleanTrialStartedCustomMessage = () => {
    setTrialStartedCustomMessage(customeMessageDefault || '');
    seTtrialStartedStatus('');
  }


  const updateGeneralUpdateSubject = (e) => {
    setGeneralUpdateSubject(e.target.value);
  }

  const handleChange = (e) => {
    const { value, name } = e.target;
    let setFunction;
    if (name === 'generalUpdateCustomMessage') {
      setFunction = setGeneralUpdateCustomMessage;
    }
    if (name === 'trialSetupIssuesCustomMessage') {
      setFunction = setTrialSetupIssuesCustomMessage;
    }
    if (name === 'trialStartedCustomMessage') {
      setFunction = setTrialStartedCustomMessage;
    }
    if (value && setFunction) {
      setFunction(value);
    } else {
      setFunction();
    }
  }

  const sendEmail = (emailType, toEmail) => {
    let url;
    let requestOptions;
    if (emailType === 'generalUpdate') {
      setGeneralUpdateLoading(true);
      url = `${config.serverUrl}/customers/email/contact`;
      requestOptions = {
        method: 'POST',
        body: JSON.stringify({
          from: 'AdRev Support <support@adrev.tech>',
          reply_to: 'support@adrev.tech',
          subject: generalUpdateSubject,
          to: toEmail,
          template: 'adrev-update',
          tag: 'update',
          substitutions: {
            customMessage: generalUpdateCustomMessage.replaceAll('\n', '<br />'),
          },
          platform: 'adrev',
        }),
      }
    }
    if (emailType === 'trialSetupIssues') {
      setTrialSetupIssuesLoading(true);
      url = `${config.serverUrl}/customers/email/contact`;
      requestOptions = {
        method: 'POST',
        body: JSON.stringify({
          from: 'AdRev Support <support@adrev.tech>',
          reply_to: 'support@adrev.tech',
          subject: 'AdRev Trial Setup Issues',
          to: toEmail,
          template: 'adrev-trial-setup-issues',
          tag: 'trial-setup-issues',
          substitutions: {
            customMessage: trialSetupIssuesCustomMessage.replaceAll('\n', '<br />'),
          },
          platform: 'adrev',
        }),
      }
    }
    if (emailType === 'trialStarted') {
      setTrialSetupIssuesLoading(true);
      url = `${config.serverUrl}/customers/email/contact`;
      requestOptions = {
        method: 'POST',
        body: JSON.stringify({
          from: 'AdRev Support <support@adrev.tech>',
          reply_to: 'support@adrev.tech',
          subject: 'AdRev Trial Start',
          to: toEmail,
          template: 'adrev-trial-start',
          tag: 'trial-start',
          substitutions: {
            customMessage: trialStartedCustomMessage.replaceAll('\n', '<br />'),
          },
          platform: 'adrev',
        }),
      }
    }
    if (url && requestOptions) {
      const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
      const fullRrequest = {
        ...requestOptions,
        headers: headerWithAuth,
      };
      try {
        fetch(url, fullRrequest)
          .then((requestResults) => {
            if (!requestResults.ok) {
              throw Error(requestResults.statusText);
            }
            return requestResults.json();
          }).then((response) => {
            if (emailType === 'generalUpdate') {
              setGeneralUpdateStatus(`Success: ${JSON.stringify(response)}`);
            }
            if (emailType === 'trialSetupIssues') {
              setTrialSetupIssuesStatus(`Success: ${JSON.stringify(response)}`);
            }
            if (emailType === 'trialStarted') {
              seTtrialStartedStatus(`Success: ${JSON.stringify(response)}`);
            }
          }).catch((err) => {
            console.error(`${emailType} Error: ${err}`);
          });
      } catch (catchError) {
        console.error(`Catch Error: ${catchError}`);
      }
    } else {
      console.error(`${emailType} Error: missing url or requestOptions`);
    }
    setGeneralUpdateLoading(false);
    setTrialSetupIssuesLoading(false);
    seTtrialStartedlaoding(false);
  }

  const { customer_email: customerEmail } = results;

  if (platform === 'adrev' && user.access === 'admin') {
    return (
      <Container className="dashboard">
        <Panel
          lg={12}
          md={12}
          title="General Update"
          subhead="Email Account User"
          defaultOpen={false}
          label={generalUpdateStatus}
          parentRefresh={loading || generalUpdateLoading}
          getData={cleanGeneralUpdateCustomMessage}
        >
          <Row>
            <Col md={6} lg={6}>
              {results && (
                <p>Customer Email: {customerEmail}</p>
              )}
              <p>From Email: AdRev Support &lt;suppor&#64;adrev.tech&gt;</p>
              <p>Subject: <input type="text" value={generalUpdateSubject} onChange={updateGeneralUpdateSubject} /></p>
              <p>Custom Message:
                <textarea
                  name="generalUpdateCustomMessage"
                  rows="10"
                  style={{
                    width: '100%',
                  }}
                  onChange={handleChange}
                  value={generalUpdateCustomMessage?.toString() === customeMessageDefault?.toString() ? '' : generalUpdateCustomMessage}
                />
              </p>
              <br />
              <span style={{ textAlign: 'center' }}>
                <div className="progress-wrap progress-wrap--small progress-wrap--pink" style={{ width: '100%' }}>
                  <button
                    type="button"
                    className="btn btn-primary account__btn"
                    name="generalUpdateCustomMessageButton"
                    onClick={(e) => {
                      e.preventDefault();
                      sendEmail('generalUpdate', customerEmail);
                    }}
                  >
                    Send
                  </button>
                </div>
              </span>
              <h4>Common Phrases:</h4>
              <p>Your trial settings have been reviewed and approved, we will send you an email when your trial begins.</p>
              <br />
              <h5>SUBJECT: AdRev Free Trial Action Required</h5>
              <p style={{ marginLeft: '20px' }}>
                AdRev requires a few more steps before we can start your Trial.
                <br />
                Look for an email from support@adrev.tech with the subject &apos;Welcome to AdRev&apos;.  This email will include your login and password, which will give you access to our dashboard where you can complete the Trial Setting to get your trial launched.
              </p>
            </Col>
            <Col md={6} lg={6}>
              <h3>Template:</h3>
              <div style={{
                padding: '10px', backgroundColor: '#FFFFFF', color: '#000000', borderStyle: 'solid', borderColor: '#000000',
              }}
              >
                <img src="https://dashboard.adrev.tech/img/email/adrev-for-email.png" width="50%" alt="AdRev" />
                <br />
                <br />
                <p style={{ whiteSpace: 'pre-wrap' }}>{generalUpdateCustomMessage}</p>
                <p>
                  Thank you,<br />
                  The AdRev Team<br />
                  <br />
                </p>
              </div>
            </Col>
          </Row>
        </Panel>
        <Panel
          lg={12}
          md={12}
          title="Trial Setup Issues"
          subhead="Email Account User"
          defaultOpen={false}
          label={trialSetupIssuesStatus}
          parentRefresh={loading || trialSetupIssuesLoading}
          getData={cleanTrialSetupIssuesCustomMessage}
        >
          <Row>
            <Col md={6} lg={6}>
              {results && (
                <p>Customer Email: {customerEmail}</p>
              )}
              <p>From Email: AdRev Support &lt;suppor&#64;adrev.tech&gt;</p>
              <p>Subject: AdRev Trial Setup Issues</p>
              <p>Custom Message:
                <textarea
                  name="trialSetupIssuesCustomMessage"
                  rows="10"
                  style={{
                    width: '100%',
                  }}
                  onChange={handleChange}
                  value={trialSetupIssuesCustomMessage?.toString() === customeMessageDefault?.toString() ? '' : trialSetupIssuesCustomMessage}
                />
              </p>
              <br />
              <span style={{ textAlign: 'center' }}>
                <div className="progress-wrap progress-wrap--small progress-wrap--pink" style={{ width: '100%' }}>
                  <button
                    type="button"
                    className="btn btn-primary account__btn"
                    name="trialSetupIssuesCustomMessageButton"
                    onClick={(e) => {
                      e.preventDefault();
                      sendEmail('trialSetupIssues', customerEmail);
                    }}
                  >
                    Send
                  </button>
                </div>
              </span>
              <h4>Common Phrases:</h4>
              <p>Missing SKUs: please add comma separated list of SKUs you wish to use in a trial.  This list should contain at a minimum the number of SKUs you wish to use the trial and at most 100 SKUs.</p>
              <p>Missing ASINs: please add comma separated list of ASINs you wish to use in a trial.  This list should contain at a minimum the number of ASINs you wish to use the trial and at most 100 ASINs.</p>
            </Col>
            <Col md={6} lg={6}>
              <h3>Template:</h3>
              <div style={{
                padding: '10px', backgroundColor: '#FFFFFF', color: '#000000', borderStyle: 'solid', borderColor: '#000000',
              }}
              >
                <img src="https://dashboard.adrev.tech/img/email/adrev-for-email.png" width="50%" alt="AdRev" />
                <br />
                <br />
                <p>Thanks for your interest in starting a new trial with AdRev!</p>
                <p>We have found issue(s) with the information supplied to start your trial and require settings to be updated in order to launch your trial. We have unlocked your Trial Settings, please login and correct any issues.</p>
                <p>Issue Details: <br />
                  <span style={{ whiteSpace: 'pre-wrap' }}>{trialSetupIssuesCustomMessage}</span>
                </p>
                <p>Once updated settings are saved, your account will be reviewed again prior to AdRev trial launch.</p>
                <p>
                  Thank you,<br />
                  The AdRev Team<br />
                  <br />
                </p>
              </div>
            </Col>
          </Row>
        </Panel>
        <Panel
          lg={12}
          md={12}
          title="Trial Started"
          subhead="Email Account User"
          defaultOpen={false}
          label={trialStartedStatus}
          parentRefresh={loading || trialStartedLoading}
          getData={cleanTrialStartedCustomMessage}
        >
          <Row>
            <Col md={6} lg={6}>
              {results && (
                <p>Customer Email: {customerEmail}</p>
              )}
              <p>From Email: AdRev Support &lt;suppor&#64;adrev.tech&gt;</p>
              <p>Subject: AdRev Trial Start</p>
              <p>Custom Message:
                <textarea
                  name="trialStartedCustomMessage"
                  rows="10"
                  style={{
                    width: '100%',
                  }}
                  onChange={handleChange}
                  value={trialStartedCustomMessage?.toString() === customeMessageDefault?.toString() ? '' : trialStartedCustomMessage}
                />
              </p>
              <br />
              <span style={{ textAlign: 'center' }}>
                <div className="progress-wrap progress-wrap--small progress-wrap--pink" style={{ width: '100%' }}>
                  <button
                    type="button"
                    className="btn btn-primary account__btn"
                    name="trialStartedCustomMessageButton"
                    onClick={(e) => {
                      e.preventDefault();
                      sendEmail('trialStarted', customerEmail);
                    }}
                  >
                    Send
                  </button>
                </div>
              </span>
              <h4>Common Phrases:</h4>
              <p>The AdRev team has launched campaigns and will continue to do so during your trial.</p>
            </Col>
            <Col md={6} lg={6}>
              <h3>Template:</h3>
              <div style={{
                padding: '10px', backgroundColor: '#FFFFFF', color: '#000000', borderStyle: 'solid', borderColor: '#000000',
              }}
              >
                <img src="https://dashboard.adrev.tech/img/email/adrev-for-email.png" width="50%" alt="AdRev" />
                <br />
                <br />
                <p>Your 7 day free trial has officially started!</p>
                <p style={{ whiteSpace: 'pre-wrap' }}>{trialStartedCustomMessage}</p>
                <p>
                  Thank you,<br />
                  The AdRev Team<br />
                  <br />
                </p>
              </div>
            </Col>
          </Row>
        </Panel>
      </Container>
    );
  }
  return (
    <Container className="dashboard">
      <p>This page is for Admin users and AdRev only at this time.</p>
    </Container>
  );
}
