import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Container, Col, Row } from 'reactstrap';
import moment from 'moment';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { AgGridReact } from 'ag-grid-react';
import MetricFilter, { MetricOptions } from './MetricFilter';
import config from '../../../../../config';
import ChartBuilder from '../../../../../shared/components/chart/ChartBuilder';
import CardPanel from '../../../../../shared/components/widgets/CardPanel';
import DateRangePicker from '../../../../../shared/components/widgets/DateRangePicker';

export default function OrderPerformanceLayout() {
  const gridRef = useRef();
  const sellerSelect = useSelector(store => store.sellerSelect);
  const user = useSelector(store => store.authentication.user);
  const theme = useSelector(store => store.theme);
  const [graphData, setGraphData] = useState([{}]);
  const [apiLoad, setApiLoad] = useState(false);
  const [startDate, setStartDate] = useState(moment().subtract(11, 'days')); // moment('20231005', 'YYYYMMDD')
  const [endDate, setEndDate] = useState(moment().subtract(4, 'days'));
  const [colDef, setColDef] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [metricOption, setMetricOption] = useState(MetricOptions.IMPRESSIONS);

  const [series, setSeries] = useState([]);
  const [apiData, setApiData] = useState([]);
  // const [reportStartDate] = useState(moment('20231005', 'YYYYMMDD').format('YYYYMMDD')); // moment().subtract(6, 'months').startOf('month').format('YYYYMMDD')
  // const [reportEndDate] = useState(moment('20231010', 'YYYYMMDD').format('YYYYMMDD')); // moment().format('YYYYMMDD')

  useEffect(() => {
    setApiLoad(true);
    setGraphData([{}]);
    setApiData([{}]);

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    const performnceUrl = `${config.serverUrl}/dsp/order-performance/${sellerSelect.value}?startDate=${startDate.format('YYYYMMDD')}&endDate=${endDate.format('YYYYMMDD')}`;
    fetch(performnceUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        setApiData(data);
      }).catch(() => {
        setApiLoad(false);
      });
  }, [sellerSelect.value, startDate, endDate]);


  useEffect(() => {
    if (apiData && metricOption.value) {
      setApiLoad(true);
      const dayOrdersMap = new Map();
      const newSeries = new Map();
      const orderNameMap = new Map();
      const newColDef = [
        {
          field: 'orderName',
          headerName: 'Order Name',
        },
      ];

      for (let m = moment.utc(startDate); m.isSameOrBefore(endDate); m.add(1, 'days')) {
        dayOrdersMap.set(m.format('MMM Do YYYY'), { report_date: m.format('MMM Do YYYY'), data: {} });
        newColDef.push(
          {
            field: `"${m.format('MMM Do YYYY').toString()}"`,
            headerName: m.format('MMM Do YYYY').toString(),
            valueFormatter: metricOption.formatter,
          },
        )
      }
      apiData.forEach(day => {
        const formattedDay = moment(day.report_date, 'YYYYMMDD').format('MMM Do YYYY');
        // Set Table Data
        const orderNameRec = orderNameMap.get(day.order_id);
        if (orderNameRec) {
          orderNameRec[`"${formattedDay}"`] = day[metricOption.value];
          orderNameMap.set(day.order_id, orderNameRec);
        } else {
          const newObj = {
            orderName: day.order_name,
            [`"${formattedDay}"`]: day[metricOption.value],
          }
          orderNameMap.set(day.order_id, newObj);
        }

        // Set Graph Data
        const dayOrders = dayOrdersMap.get(formattedDay);
        if (dayOrders) {
          Object.assign(dayOrders.data, { [day.order_id]: day });
          newSeries.set(day.order_id, {
            type: 'bar',
            xKey: 'report_date',
            yKey: `data['${day.order_id}'].${metricOption.value}`,
            yName: day.order_name,
            stackGroup: 'DAY',
            orderId: day.order_id.toString(),
            tooltip: {
              renderer: ({ datum, xKey }) => ({ title: datum[xKey], content: metricOption.formatter({ value: datum.data[day.order_id][metricOption.value] }) }),
            },
          });
          dayOrdersMap.set(formattedDay, dayOrders);
        }
      })

      // Table Data
      setColDef(newColDef);
      const newTableData = Array.from(orderNameMap.values());
      setTableData(newTableData);

      // Graph Data
      setSeries(Array.from(newSeries.values()));
      const newData = Array.from(dayOrdersMap.values());
      setApiLoad(false);
      setGraphData(newData);
    }
  }, [apiData, metricOption.value]);

  function dateChange(start, end) {
    setStartDate(moment(start));
    setEndDate(moment(end));
  }

  const chartOptions = {
    theme: theme.className.endsWith('light') ? 'ag-default' : 'ag-default-dark',
    data: graphData,
    series,
    title: {
      text: 'DSP Order Performance',
    },
    // subtitle: {
    //   text: '2024',
    // },
  };

  // Table Settings
  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };

  const onGridReady = useCallback(() => {
    gridRef.current.api.sizeColumnsToFit({
      defaultMinWidth: 100,
    });
  }, []);

  let useTheme = 'ag-theme-balham';
  if (!theme.className.endsWith('light')) {
    useTheme = 'ag-theme-balham-dark';
  }

  const onBtnExport = () => {
    const fileName = `${sellerSelect.value}-DSP-OrderPerformance-${metricOption.value}-${moment().format('YYYYMMDD')}.csv`;
    gridRef.current.api.exportDataAsCsv({ fileName });
  };

  return (
    <Container className="dashboard">
      <Col md={12}>
        <CardPanel
          showButtons={false}
          parentRefresh={apiLoad}
        >
          <Row>
            <Col lg={3}>
              <p>Dates:</p>
              <DateRangePicker
                startDate={startDate.toDate()}
                endDate={endDate.toDate()}
                onDateChange={dateChange}
              />
            </Col>
            <Col lg={4}>
              <MetricFilter
                accountId={sellerSelect.value}
                value={metricOption}
                setValue={setMetricOption}
                disable={apiLoad}
              />
            </Col>
          </Row>
        </CardPanel>
        <Row>
          <CardPanel
            showButtons={false}
            parentRefresh={apiLoad}
          >
            <ChartBuilder chartOptions={chartOptions} />
          </CardPanel>
        </Row>
      </Col>
      <CardPanel
        lg={12}
        md={12}
        title=""
        subhead=""
        showButtons={false}
        parentRefresh={apiLoad}
      >
        <Row>
          <Col md={12}>
            <Row>
              <div style={{ verticalAlign: 'middle', spacing: '2px', width: '100%', display: 'table-cell' }}>
                <span style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '5px', paddingBottom: '0px' }}><button className="btn btn-outline-primary" style={{ width: '200px', marginBottom: ' 0px' }} onClick={onBtnExport} type="button">Export</button></span>
              </div>
              <br />
              <br />
              <br />
            </Row>
            <Row>
              <div className={useTheme} style={{ height: '50vh', width: '100%' }}>
                <AgGridReact
                  ref={gridRef}
                  columnDefs={colDef}
                  rowData={tableData}
                  defaultColDef={defaultColDef}
                  // groupIncludeTotalFooter
                  // aggFuncs={aggFuncs}
                  onGridReady={onGridReady}
                  // suppressScrollOnNewData
                />
              </div>
            </Row>
          </Col>
        </Row>
      </CardPanel>
    </Container>
  );
}
