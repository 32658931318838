import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import moment from 'moment';
import useData from './useData';
import { PageContextProvider, usePageContext } from './Context';
import { when } from '../../../../../shared/functions';
import CardPanel from '../../../../../shared/components/widgets/CardPanel';
import DateRangePicker from '../../../../../shared/components/widgets/DateRangePicker';
import DateViewFilter from '../../../../../shared/components/widgets/DateViewFilter';
import SalesGraph from './SalesGraph';
import ACoSGraph from './ACoSGraph';
import GlancesVsSalesPercentage from './GlancesVsSalesPercentage';
import GlancesVsPPCClicks from './GlancesVsPPCClicks';
import DataTable from './DataTable';

function PpcAndOrganicPerformanceLayout() {
  const sellerSelect = useSelector(store => store.sellerSelect);
  const { state, actions } = usePageContext();
  const {
    startSelectionDate, endSelectionDate, dateView,
  } = state;
  const {
    setDates, setDateView,
  } = actions;
  const [loading, results, error] = useData(sellerSelect, startSelectionDate, endSelectionDate, dateView);

  useEffect(() => {
    when(error, () => {
      console.error(error);
    });
  }, [error]);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">PPC and Organic Performance</h3>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <SalesGraph
            data={results && results.data}
            loading={loading}
          />
        </Col>
        <Col md={6}>
          <ACoSGraph
            data={results && results.data}
            loading={loading}
          />
        </Col>
        <Col md={6}>
          <GlancesVsSalesPercentage
            data={results && results.data}
            loading={loading}
          />
        </Col>
        <Col md={6}>
          <GlancesVsPPCClicks
            data={results && results.data}
            loading={loading}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <CardPanel
            showButtons={false}
          >
            <Row>
              <Col xs={12} md={10}>
                <DateRangePicker
                  startDate={startSelectionDate.toDate()}
                  endDate={endSelectionDate.toDate()}
                  onDateChange={(start, end) => setDates(moment(start), moment(end))}
                />
              </Col>
              <Col xs={12} md={2}>
                <DateViewFilter initialValue={dateView} onChange={view => setDateView(view)} />
              </Col>
            </Row>
          </CardPanel>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <DataTable
            loading={loading}
            data={results && results.data}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default function PpcAndOrganicPerformanceWrapper() {
  return (
    <PageContextProvider>
      <PpcAndOrganicPerformanceLayout />
    </PageContextProvider>
  );
}
