import useFetch from './useFetch';

export const DEFAULT_ALL_FILTER_VALUE = { label: 'ALL', value: '', sortName: '!ALL' };

export default function useFilterData(url, fields) {
  const { results, error } = useFetch(url);
  const filterMap = new Map();

  if (error) {
    filterMap.set('!ERR', {
      label: '...An error occurred retrieving filter values. Try refreshing the page.',
      value: 'ERROR',
      sortName: 'ERROR',
    });
  }

  if (results) {
    filterMap.set('!ALL', DEFAULT_ALL_FILTER_VALUE);
    let data = results;
    if (!Array.isArray(data)) {
      data = [data];
    }
    data.forEach((row) => {
      if (row[fields.id]) {
        const obj = { label: row[fields.name], value: row[fields.id], sortName: row[fields.name] };
        Object.keys(row).forEach((key) => {
          obj[key] = row[key];
        });
        filterMap.set(row[fields.id], obj);
      }
    });
  }

  const filterOptions = Array.from(filterMap.values()).sort((a, b) => (a.sortName > b.sortName ? 1 : -1));

  return {
    data: filterOptions,
  };
}
