/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Line, ComposedChart,
} from 'recharts';
import CardPanel from '../../../../../shared/components/widgets/CardPanel';
import style from '../../../../../shared/components/themeSupport';
import {
  formatDecimalToFixedTwoPercent,
} from '../../../../../shared/components/table/functions';

export default function ACoSGraph({ loading, data }) {
  const theme = useSelector(state => state.theme);

  return (
    <CardPanel
      md={12}
      title="PPC vs Total ACoS"
      parentRefresh={loading}
    >
      <>
        {!loading && data && (
          <ResponsiveContainer height={250} className="dashboard__area">
            <ComposedChart data={data}>
              <CartesianGrid stroke={style(theme).colorText} />
              <XAxis dataKey="rankDate" stroke={style(theme).colorText} />
              <YAxis
                stroke={style(theme).colorText}
                tickFormatter={val => formatDecimalToFixedTwoPercent({ value: val })}
              />
              <Tooltip />
              <Legend />
              <Line
                name="PPC ACoS"
                type="monotone"
                dataKey="acos"
                stroke={style(theme).lineOrange}
                strokeWidth={2}
                formatter={val => formatDecimalToFixedTwoPercent({ value: val })}
              />
              <Line
                name="Total ACoS"
                type="monotone"
                dataKey="total_acos"
                stroke={style(theme).lineGray}
                strokeWidth={2}
                formatter={val => formatDecimalToFixedTwoPercent({ value: val })}
              />
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </>
    </CardPanel>
  );
}

ACoSGraph.defaultProps = {
  data: null,
};

ACoSGraph.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.instanceOf(Array),
};
