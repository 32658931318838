import React from 'react';
import {
  Container, Row,
} from 'reactstrap';
import TopTenBrandMarketShareMain from './components/TopTenBrandMarketShareMain';

const TopTenBrandMarketSharePage = () => (
  <Container className="dashboard">
    <Row>
      <TopTenBrandMarketShareMain />
    </Row>
  </Container>
);

export default TopTenBrandMarketSharePage;
