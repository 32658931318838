import React, { useState } from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import Panel from '../../../shared/components/widgets/CardPanelSlim';
import { subscriptionFaq } from './config';

export default function FaqPage() {
  const [openAll, setOpenAll] = useState(null);

  return (
    <Container className="dashboard">
      <Row>
        <Col>
          <button type="button" className="btn btn-primary" onClick={() => setOpenAll(true)}>Open All</button>
          <button type="button" className="btn btn-primary" onClick={() => setOpenAll(false)}>Close All</button>
        </Col>
      </Row>
      {subscriptionFaq && subscriptionFaq.map(faq => (
        <Row key={faq.title}>
          <Col md={12}>
            <Panel
              title={faq.title}
              defaultOpen={openAll}
              setDefaultOpen={setOpenAll}
            >
              <Row>
                <Col>
                  <p>{faq.description}</p>
                </Col>
              </Row>
            </Panel>
          </Col>
        </Row>
      ))}
    </Container>
  );
}
