import React from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { AgGridReact } from 'ag-grid-react';
import { CardBody } from 'reactstrap';

export default function StreamsTable({ subscriptions, gridRef, gridTheme, archiveSqsSubscriptions }) {
  const columnDefs = [
    { field: 'createdDate', headerName: 'Created Date' },
    { field: 'updatedDate', headerName: 'Updated Date' },
    { field: 'dataSetId', headerName: 'Data Set Id' },
    { field: 'destinationArn', headerName: 'Destination ARN' },
    { field: 'notes', headerName: 'Notes' },
    { field: 'status', headerName: 'Status' },
    { field: 'subscriptionId', headerName: 'Subscription ID' },
    { field: 'archiveButton',
      headerName: 'Archive Subscription',
      cellRenderer: params => {
        // terminal states: [ARCHIVED, FAILED_CONFIRMATION, SUSPENDED, FAILED_PROVISIONING]
        if (['ARCHIVED', 'FAILED_CONFIRMATION', 'SUSPENDED', 'FAILED_PROVISIONING'].includes(params.data.status)) {
          return 'terminal status'
        }
        return (
          <button type="button" onClick={() => archiveSqsSubscriptions(params.data.subscriptionId)}>Archive Subscription</button>
        );
      },
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };

  return (
    <CardBody>
      <div className={gridTheme} style={{ height: '50vh', width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          columnDefs={columnDefs}
          rowData={subscriptions}
          defaultColDef={defaultColDef}
        />
      </div>
    </CardBody>
  );
}
