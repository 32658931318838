import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Col, Row } from 'reactstrap';
import moment from 'moment';
import WmCatalogMgr from './WmCatalogMgr';
import WmBudgetMgr from './WmBudgetMgr';
import config from '../../../../config';
import ChartBuilder from '../../../../shared/components/chart/ChartBuilder';
import CardPanel from '../../../../shared/components/widgets/CardPanel';

export default function WalmartOnePageLayout() {
  const sellerSelect = useSelector(store => store.sellerSelect);
  const user = useSelector(store => store.authentication.user);
  const theme = useSelector(store => store.theme);
  const [wmInternalGraphData, setWmInternalGraphData] = useState([{}]);
  const [wmInternalGraphLoad, setWmInternalGraphLoad] = useState(false);
  const [usedBudget, setUsedBudget] = useState(0);
  const [adStatusOnCount, setAdStatusOnCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [series] = useState([
    {
      type: 'line',
      xKey: 'report_date',
      yKey: 'spend',
      yName: 'Limitless Ad Spend',
    },
    {
      type: 'line',
      xKey: 'report_date',
      yKey: 'sales',
      yName: 'Limitless Ad Sales',
    },
  ]);
  const [reportStartDate] = useState(moment().startOf('month').format('YYYYMMDD'));
  const [reportEndDate] = useState(moment().format('YYYYMMDD'));

  useEffect(() => {
    setWmInternalGraphLoad(true);
    setWmInternalGraphData([{}]);

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    const internalStatsUrl = `${config.serverUrl}/walmart/query/ad-items-by-dates-breakdown?accountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}&startDate=${reportStartDate}&endDate=${reportEndDate}&internal=true`;
    fetch(internalStatsUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        const wmGraphDataSorted = data.by_date.sort((a, b) => {
          const checkPos = (b.report_date > a.report_date) ? -1 : 0;
          return (b.report_date < a.report_date) ? 1 : checkPos;
        });

        setWmInternalGraphLoad(false);
        setWmInternalGraphData(wmGraphDataSorted);
      }).catch(() => {
        setWmInternalGraphLoad(false);
      });
  }, [sellerSelect.value]);

  const chartOptions = {
    theme: theme.className.endsWith('light') ? 'ag-default' : 'ag-default-dark',
    data: wmInternalGraphData,
    series,
    title: {
      text: 'Walmart Ads Performance (MTD)',
    },
    // subtitle: {
    //   text: '2024',
    // },
  };

  return (
    <Container className="dashboard">
      <Col md={12}>
        <Row>
          <CardPanel
            showButtons={false}
            parentRefresh={wmInternalGraphLoad}
          >
            <ChartBuilder chartOptions={chartOptions} />
          </CardPanel>
        </Row>
        <Row>
          <WmBudgetMgr adStatusOnCount={adStatusOnCount} usedBudget={usedBudget} isLoading={isLoading} />
        </Row>
        <Row>
          <WmCatalogMgr setAdStatusOnCount={setAdStatusOnCount} setUsedBudget={setUsedBudget} setIsLoading={setIsLoading} />
        </Row>
        <Row>
          <CardPanel
            showButtons={false}
          >
            <h4>Make sure performance needs are met when changing ROAS targets or increasing daily spend per item. You are responsible for monitoring all ad spend  and results are not guaranteed. If our free ad solution is not meeting desired objectives then pause all campaigns and remove Limitless as a partner. You are spending money based on the Walmart Advertising account billing settings you have chosen. LIMITLESS ONLY RUNS AND MODIFIES ONLY ADS CREATED BY LIMITLESS.</h4>
          </CardPanel>
        </Row>
      </Col>
    </Container>
  );
}
