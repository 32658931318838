/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import { currencyDisplay, formatNumberTwoPlaces } from '../table/functions';
import Panel from '../Panel';

class FullWidthFillGraph extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line
    data: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    initalLoad: PropTypes.bool.isRequired,
    // eslint-disable-next-line
    lineList: PropTypes.array.isRequired,
    dataKey: PropTypes.string,
    tickFormatter: PropTypes.func,
    colorOptions: PropTypes.arrayOf(PropTypes.string),
    fillOpacity: PropTypes.number,
    useFill: PropTypes.bool,
    strokeWidth: PropTypes.number,
  }

  render() {
    const {
      data, initalLoad, title, lineList, dataKey, tickFormatter, colorOptions, fillOpacity, useFill, strokeWidth,
    } = this.props;

    let fillValue = null;
    let fillOpacityValue = 0;

    return (
      <Panel md={12} title={title} parentRefresh={initalLoad}>
        <ResponsiveContainer height={250} className="dashboard__area">
          <AreaChart data={data} margin={{ top: 20, left: 15, bottom: 20 }}>
            <XAxis dataKey={dataKey} tickLine={false} />
            <YAxis tickLine={false} tickFormatter={tickFormatter} />
            <Tooltip formatter={tickFormatter} />
            <Legend />
            <CartesianGrid />
            {lineList.map((value, index) => {
              if (useFill) {
                fillValue = colorOptions[index];
                fillOpacityValue = value.fill || fillOpacity;
              }
              return (
                <Area
                  key={(value.label || value)}
                  name={(value.label || value)}
                  type="monotone"
                  dataKey={(value.value || value)}
                  stroke={colorOptions[index]}
                  fill={fillValue}
                  fillOpacity={fillOpacityValue}
                  strokeWidth={strokeWidth}
                />
              );
            })}
          </AreaChart>
        </ResponsiveContainer>
      </Panel>
    );
  }
}


FullWidthFillGraph.defaultProps = {
  dataKey: 'report_date',
  tickFormatter: dollar => `${currencyDisplay()}${formatNumberTwoPlaces(dollar)}`,
  colorOptions: ['#70bbfd', '#ffb153', '#f47b50', '#c88ffa', '#4ce1b6'],
  fillOpacity: 0,
  useFill: true,
  strokeWidth: 1,
};

const mapStateToProps = (state) => {
  const { sellerSelect } = state;
  const { user } = state.authentication;

  return {
    sellerSelect,
    user,
  };
};

export default connect(mapStateToProps)(FullWidthFillGraph);
