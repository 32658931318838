import { combineReducers, createStore, applyMiddleware } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import {
  sidebarReducer, themeReducer, authentication, registration, users, alert, sellerSelect, updateCalendar,
} from '../../redux/reducers/index';
import todoReducer from '../AccountManagment/Todo/redux/reducer';

const loggerMiddleware = createLogger();

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  sidebar: sidebarReducer,
  authentication,
  registration,
  users,
  alert,
  sellerSelect,
  updateCalendar,
  todo: todoReducer,
});

const store = createStore(
  reducer,
  applyMiddleware(
    thunkMiddleware,
    loggerMiddleware,
  ),
);

export default store;
