import React, { PureComponent } from 'react';
import { Alert, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';
import ThumbUpOutlineIcon from 'mdi-react/ThumbUpOutlineIcon';
import CommentAlertOutlineIcon from 'mdi-react/CommentAlertOutlineIcon';
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';

export default class AlertComponent extends PureComponent {
  static propTypes = {
    color: PropTypes.string,
    icon: PropTypes.bool,
    showButton: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.element.isRequired,
  };

  static defaultProps = {
    color: '',
    icon: false,
    showButton: false,
    className: '',
  };

  constructor() {
    super();

    this.state = {
      visible: true,
    };
  }

  onShow = () => {
    this.setState({ visible: true });
  };

  onDismiss = () => {
    this.setState({ visible: false });
  };

  render() {
    const { visible } = this.state;

    const {
      color, className, icon, showButton, children,
    } = this.props;
    let Icon;

    const AlertCloseButtonStyle = styled.button`
      color: #FFFFFF;
      opacity: 1;
      font-weight: 100;
      left: 20px;
      font-size: 14px;
      line-height: 14px;
      position: absolute;
      top: calc(50% - 7px);
      text-shadow: none;
      background-color: transparent;
      border: 0;
      padding: 0;
    
      &:focus, &:active, &:active:focus {
        outline: none;
      }
    `;

    const AlertIcon = styled.div`
      padding-left: 10px;
      padding-top: 8px;
      padding-bottom: 12px;
      float: left;
      width: 5%;
    
      svg {
        fill: #FFFFFF;
        margin: auto 0;
      }
    `;

    const AlertContent = styled.div`
      text-align: left;
      width: 95%;
      padding-right: 40px;
      padding-left: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
    `;

    switch (color) {
      case 'info':
        Icon = <InformationOutlineIcon />;
        break;
      case 'success':
        Icon = <ThumbUpOutlineIcon />;
        break;
      case 'warning':
        Icon = <CommentAlertOutlineIcon />;
        break;
      case 'danger':
        Icon = <CloseCircleOutlineIcon />;
        break;
      default:
        break;
    }

    if (visible) {
      return (
        <Alert color={color} className={className} isOpen={visible}>
          {icon && <AlertIcon>{Icon}</AlertIcon>}
          {showButton && <AlertCloseButtonStyle type="button" onClick={this.onDismiss}><span className="lnr lnr-cross" /></AlertCloseButtonStyle>}
          <AlertContent>{children}</AlertContent>
        </Alert>
      );
    }

    return <Button onClick={this.onShow}>Show Alert</Button>;
  }
}
