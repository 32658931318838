import config from '../../../config';

export const stripeKey = config.isProdEnv ? 'pk_live_51MgDC7KCbOxHqO2t0c1oz5L1FVNywSbNKHBraL83kr8fHepYQyb8cTGtXsYDTlceWSzYr3Ly37WGwznRmXTYX8AU00O5xXQtwC' : 'pk_test_51MgDC7KCbOxHqO2tFRanzJ9BvN0V4ftfT1Wc7Q5RTwm8emPJNUtJzPMfe0DwM4AKjTHVGnjJqfjCmd0fHmah29tO00Fxt7HtDp';

export function getPlanStatus(planStatus) {
  const localPlanStatus = (planStatus || 'new').toUpperCase();
  const planStatusArrary = [
    {
      planStatus: 'DEFAULT',
      cleanPlanStatus: 'NONE',
      planBillingTitle: 'Billing',
      planBillingMessage: 'Add a plan and start today!',
      showBillingDate: false,
    },
    {
      planStatus: 'ACTIVE',
      cleanPlanStatus: 'ACTIVE',
      planBillingTitle: 'Next Billing Date*',
      planBillingMessage: '* Bill will include last months tiered addon charges and a base payment of $99.00 for for the upcoming Month',
      showBillingDate: true,
    },
    {
      planStatus: 'NEW',
      cleanPlanStatus: 'TRIAL',
      planBillingTitle: 'Billing',
      planBillingMessage: 'Add a plan and start today!',
      showBillingDate: false,
    },
    {
      planStatus: 'PENDING',
      cleanPlanStatus: 'PENDING ACTIVE',
      planBillingTitle: 'Next Billing Date*',
      planBillingMessage: '* Bill will include last months tiered addon charges and a base payment of $99.00 for for the upcoming Month',
      showBillingDate: true,
    },
    {
      planStatus: 'ACTIVECANCELLED',
      cleanPlanStatus: 'PENDING CANCEL',
      planBillingTitle: 'Last Billing Date*',
      planBillingMessage: '* Bill will include last months tiered addon charges',
      showBillingDate: true,
    },
    {
      planStatus: 'CANCELLED',
      cleanPlanStatus: 'CANCELLED',
      planBillingTitle: 'Billing',
      planBillingMessage: 'Add a plan and start today!',
      showBillingDate: false,
    },
  ];

  const foundObj = planStatusArrary.find((searchObj) => searchObj.planStatus === localPlanStatus);
  const returnObj = foundObj || planStatusArrary[0];

  return returnObj;
}

export const planConfig = {
  basePlan: 'CANCEL',
  tagLine: '',
  products: [
    {
      name: 'TRIAL',
      tagLine: 'Try us for free',
      costAmount: '$0.00 USD',
      costDetails: 'for one week',
      bullets: [
        'Up to 20 products',
        'One budget group',
        'Trial monthly spend',
        'One Marketplace',
        'No Reports',
        'Fully Managed Advertising by AdRev',
        '0-Click Campaign Creation',
        '0-Click Managment and Optimization',
        '0-Click Keyword & Target Expansion',
        'Trial Budget & PACoS Selection',
        'Trial Product AD Selection',
      ],
    },
    {
      name: 'CANCEL',
      tagLine: 'Cancel Your Subscription',
      costAmount: '',
      costDetails: '',
      bullets: [
        'We will no longer manage Ads',
        'Access to reports will be removed',
        'Come back any time',
      ],
    },
    {
      name: 'FLEX',
      tagLine: 'Get Started!',
      costAmount: '$99.00 USD',
      costDetails: '/ month',
      bullets: [
        'Up to 50 products',
        'One budget group',
        'Up to $5,000 USD in monthly spend',
        'One Marketplace',
        'Limited Reports',
        'Fully Managed Advertising by AdRev',
        '0-Click Campaign Creation (SP & SD)',
        '0-Click Managment and Optimization',
        '0-Click Keyword & Target Expansion',
        'Full Budget & PACoS Control',
        'ON/OFF Product AD Control',
      ],
    },
  ],
};

export const flatRate = [
  {
    name: 'Monthly Cost', value: '$99.00 USD', calcValue: 99, minFlatRate: 0, maxFlatRate: 250,
  },
];

export const meteredMarketplaces = [
  {
    name: '0-1', value: '$0.00 USD', calcValue: 0, minMarketplaces: 0, maxMarketplaces: 1,
  },
  {
    name: '2+', value: '$50.00 USD', calcValue: 50, minMarketplaces: 2,
  },
];

export const meteredProducts = [
  {
    name: '0-250', value: '$0.00 USD', calcValue: 0, minProducts: 0, maxProducts: 250,
  },
  {
    name: '251+', value: '$0.01 USD', calcValue: 0.01, minProducts: 251,
  },
];

export const meteredBudgetGroups = [
  {
    name: '0-1', value: '$0.00 USD', calcValue: 0, minBudgetGroups: 0, maxBudgetGroups: 250,
  },
  {
    name: '2-4', value: '$0.10 USD', calcValue: 0.1, minBudgetGroups: 2, maxBudgetGroups: 4,
  },
  {
    name: '5-10', value: '$0.15 USD', calcValue: 0.15, minBudgetGroups: 5, maxBudgetGroups: 10,
  },
  {
    name: '11+', value: '$0.20 USD', calcValue: 0.2, minBudgetGroups: 11,
  },
];

export const meteredMonthlySpSdSpend = [
  {
    name: '0-1,000', value: '0.0% of Spend', calcValue: 0, minSpend: 0, maxSpend: 1000,
  },
  {
    name: '1,001+', value: '2.5% of Spend', calcValue: 0.025, minSpend: 1001,
  },
];

export const subscriptionFaq = [
  {
    title: 'What do I need to do to get set up?',
    description: 'All you need to do is have an Amazon Ads account set up.',
  },
  {
    title: 'Why do I need to Authorize Amazons AD-API and SP-API?',
    description: 'Authorizing both of Amazons APIs allows us to work directly with Amazon on your behalf.  We are able to create and monitor ADs and get data for our dashboards.',
  },
  {
    title: 'How do I add additinal Marketplaces?',
    description: 'Select your name in the upper right corner of the page, then select "Add Marketplaces" from the dropdown.  This will take you into the onboarding flow.  Make sure to use the email address you are currently using for access to the Dashboard and answer and follow the quesitons in the onboarding flow.',
  },
  {
    title: 'Why do I need Budget Groups?',
    description: 'Budget Groups are used to focus your spend.  You can add Budget Groups to Items in your catalog and set your Budget targets, and then we do the rest.',
  },
  {
    title: 'How does Billing work?',
    description: 'Charged on the 4th each month.  Your monthly bill will be comprised two main buckets.  A flat fee will be a charge for the site access and functionality for the current month and a usage costs from addons from the last month.  Visit My Plan page and use the Plan Estimator to get a estimate on what your usages will cost you.',
  },
  {
    title: 'How much does AdRev cost?',
    description: 'After the free trial period, we have a simple no contract billing model that fits the needs of every size of business.',
  },
  {
    title: 'How do I add Budget Groups?',
    description: 'Budget Groups can be added in the catalog page.  Select the products you would like to add to a budget group and click the Edit Selected Rows button.  You can turn products on/off and set a Budget Group name.',
  },
  {
    title: 'I just added a Marketplace, why is data missing?',
    description: 'Make sure you have authorized both Amazon APIs and it may take a day or two to pull down inital data from Amazon.',
  },
];
