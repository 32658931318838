import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Col, Container, Row } from 'reactstrap';
import ReportFilters from './components/ReportFilters';
import ReportChart from './components/ReportChart';
import ReportTable from './components/ReportTable';
import { serverUrl, jsonHeader } from '../../../../config';

const defaultChartData = [
  { name: 'Alabama', code: 'AL', orders: 0 },
  { name: 'Arizona', code: 'AZ', orders: 0 },
  { name: 'Arkansas', code: 'AR', orders: 0 },
  { name: 'California', code: 'CA', orders: 0 },
  { name: 'Colorado', code: 'CO', orders: 0 },
  { name: 'Connecticut', code: 'CT', orders: 0 },
  { name: 'Delaware', code: 'DE', orders: 0 },
  { name: 'District of Columbia', code: 'DC', orders: 0 },
  { name: 'Florida', code: 'FL', orders: 0 },
  { name: 'Georgia', code: 'GA', orders: 0 },
  { name: 'Idaho', code: 'ID', orders: 0 },
  { name: 'Illinois', code: 'IL', orders: 0 },
  { name: 'Indiana', code: 'IN', orders: 0 },
  { name: 'Iowa', code: 'IA', orders: 0 },
  { name: 'Kansas', code: 'KS', orders: 0 },
  { name: 'Kentucky', code: 'KY', orders: 0 },
  { name: 'Louisiana', code: 'LA', orders: 0 },
  { name: 'Maine', code: 'ME', orders: 0 },
  { name: 'Maryland', code: 'MD', orders: 0 },
  { name: 'Massachusetts', code: 'MA', orders: 0 },
  { name: 'Michigan', code: 'MI', orders: 0 },
  { name: 'Minnesota', code: 'MN', orders: 0 },
  { name: 'Mississippi', code: 'MS', orders: 0 },
  { name: 'Missouri', code: 'MO', orders: 0 },
  { name: 'Montana', code: 'MT', orders: 0 },
  { name: 'Nebraska', code: 'NE', orders: 0 },
  { name: 'Nevada', code: 'NV', orders: 0 },
  { name: 'New Hampshire', code: 'NH', orders: 0 },
  { name: 'New Jersey', code: 'NJ', orders: 0 },
  { name: 'New Mexico', code: 'NM', orders: 0 },
  { name: 'New York', code: 'NY', orders: 0 },
  { name: 'North Carolina', code: 'NC', orders: 0 },
  { name: 'North Dakota', code: 'ND', orders: 0 },
  { name: 'Ohio', code: 'OH', orders: 0 },
  { name: 'Oklahoma', code: 'OK', orders: 0 },
  { name: 'Oregon', code: 'OR', orders: 0 },
  { name: 'Pennsylvania', code: 'PA', orders: 0 },
  { name: 'Rhode Island', code: 'RI', orders: 0 },
  { name: 'South Carolina', code: 'SC', orders: 0 },
  { name: 'South Dakota', code: 'SD', orders: 0 },
  { name: 'Tennessee', code: 'TN', orders: 0 },
  { name: 'Texas', code: 'TX', orders: 0 },
  { name: 'Utah', code: 'UT', orders: 0 },
  { name: 'Vermont', code: 'VT', orders: 0 },
  { name: 'Virginia', code: 'VA', orders: 0 },
  { name: 'Washington', code: 'WA', orders: 0 },
  { name: 'West Virginia', code: 'WV', orders: 0 },
  { name: 'Wisconsin', code: 'WI', orders: 0 },
  { name: 'Wyoming', code: 'WY', orders: 0 },
  { name: 'Alaska', code: 'AK', orders: 0 },
  { name: 'Hawaii', code: 'HI', orders: 0 },
];

const QueryReportsPage = () => {
  const sellerSelect = useSelector(state => state.sellerSelect);
  const user = useSelector(store => store.authentication.user);
  const [reportDate, setReportDate] = useState(moment().subtract(1, 'days'));
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [chartData, setChartData] = useState(defaultChartData);
  const [chartRange, setChartRange] = useState([0, 100]);


  useEffect(() => {
    const queryMpOrdersUrl = `${serverUrl}/walmart/mp/orders?accountId=${sellerSelect.value}&date=${reportDate.format('YYYYMMDD')}`;

    const headerWithAuth = { ...jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    setLoading(true);
    setChartData(defaultChartData);
    setTableData([]);

    fetch(queryMpOrdersUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        const newChartData = JSON.parse(JSON.stringify(defaultChartData));
        let maxOrders = 0;
        data.forEach(order => {
          const newPos = newChartData.findIndex(state => (state.code === order.shipping_info.state));
          newChartData[newPos].orders += 1;
          if (newChartData[newPos].orders > maxOrders) {
            maxOrders = newChartData[newPos].orders;
          }
        });
        setChartRange([0, maxOrders])
        setChartData(newChartData);
        setTableData(data);
        setLoading(false);
      }).catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [reportDate, sellerSelect, user]);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Marketplace Orders</h3>
        </Col>
      </Row>
      <Row>
        <ReportFilters reportDate={reportDate} dateChange={(newReportDate) => { setReportDate(moment(newReportDate)); }} loading={loading} />
      </Row>
      <Row>
        <ReportChart loading={loading} data={chartData} chartRange={chartRange} />
      </Row>
      <Row>
        <ReportTable data={tableData} />
      </Row>
    </Container>
  );
}

export default QueryReportsPage;
