import React from 'react';
import PropTypes from 'prop-types';
import {
  PieChart, Pie, ResponsiveContainer, Tooltip, Legend, Cell,
} from 'recharts';
import { currencyFormatter, percentFormatter } from '../../../../shared/functions';
import CardPanel from '../../../../shared/components/widgets/CardPanel';

const renderLegend = ({ payload, account }) => {
  if (!payload) {
    return null;
  }

  return (
    <ul className="dashboard__chart-horizontal_legend">
      {payload.map(entry => (
        <li
          key={`item-${entry.name}`}
        >
          <span
            style={{ backgroundColor: entry.color }}
          />
          {entry.displayName}: {currencyFormatter({ value: entry.sales, account })}
        </li>
      ))}
    </ul>
  );
};

renderLegend.propTypes = {
  payload: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    sales: PropTypes.number,
    spend: PropTypes.number,
    displayName: PropTypes.string,
    color: PropTypes.string,
  })).isRequired,
  // eslint-disable-next-line
  account: PropTypes.object.isRequired,
};

export default function SalesGraph({
  title, subhead, loading, data, account,
}) {
  let total = 0;
  if ((data || '').length > 0) {
    data.forEach((item) => {
      total += item.sales;
    });
  }
  return (
    <CardPanel
      title={title}
      subhead={subhead}
      parentRefresh={loading}
    >
      {!loading && data && (
        <ResponsiveContainer
          className="dashboard__chart-pie dashboard__chart-pie--commerce"
        >
          <PieChart
            className="dashboard__chart-pie-container"
          >
            <Pie
              data={data}
              dataKey="sales"
              nameKey="displayName"
            >
              {data.map(entry => (
                <Cell
                  key={`cell-${entry.name}`}
                  fill={entry.color}
                />
              ))}
            </Pie>
            <Tooltip
              formatter={value => percentFormatter((total > 0) ? (value / total) : 0)}
            />
            <Legend
              payload={data}
              account={account}
              content={renderLegend}
            />
          </PieChart>
        </ResponsiveContainer>
      )}
    </CardPanel>
  );
}

SalesGraph.defaultProps = {
  data: null,
  loading: false,
};

SalesGraph.propTypes = {
  title: PropTypes.string.isRequired,
  subhead: PropTypes.string.isRequired,
  account: PropTypes.instanceOf(Object).isRequired,
  loading: PropTypes.bool,
  data: PropTypes.instanceOf(Array),
};
