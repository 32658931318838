import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import config from '../../../../config';
import TableBuilder from '../../../../shared/components/table/TableBuilder';
import { getExportPrefix } from '../../../../shared/components/table/functions';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class MarketShareTable extends PureComponent {
  static propTypes = {
    asin: PropTypes.string.isRequired,
    marketplace: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props);

    const {
      asin, marketplace,
    } = this.props;

    this.state = {
      columnDefs: [
        {
          field: 'asin',
          headerName: 'ASIN',
          // chartDataType: 'category',
          enableRowGroup: true,
        },
        {
          field: 'report_date',
          headerName: 'Date',
          enablePivot: true,
          pivot: true,
        },
        {
          field: 'rstp',
          headerName: 'Search Term-Phrase-Target',
          enableRowGroup: true,
        },
        {
          field: 'rstp_score',
          headerName: 'Score',
        },
        {
          field: 'rstp_search_volume',
          headerName: 'Search Volume',
        },
        {
          field: 'rstp_noof_competing_products',
          headerName: 'noOf Competing Products',
        },
        {
          field: 'rstp_giveaways_per_week',
          headerName: 'Giveaways/Week',
        },
        {
          field: 'rstp_noof_sp_asins',
          headerName: 'noOf SP ASINs',
        },
        {
          field: 'rstp_amazon_recommended',
          headerName: 'Amazon Recommended',
        },
        {
          field: 'rstp_sponsored',
          headerName: 'Sponsored',
        },
        {
          field: 'rstp_organic',
          headerName: 'Organic',
        },
        {
          field: 'rstp_amazon_recommended_rank',
          headerName: 'Amazon Recommended Rank',
        },
        {
          field: 'rstp_serp_sponsored_rank',
          headerName: 'SERP Sponsored Rank',
        },
        {
          field: 'rstp_serp_organic_rank',
          headerName: 'Organic Rank',
          aggFunc: 'avg',
        },
      ],
      defaultColDef: {
        sortable: true,
        // filter: true,
        resizable: true,
      },
      showDatePicker: true,
      showFooter: false,
      // enableCharts: true,
      // enableRangeSelection: true,
      sideBar: true,
      apiQueryParams: `asin=${asin}&marketplace=${marketplace}`,
    };
  }

  static getDerivedStateFromProps(props) {
    if (props.asin && props.marketplace) {
      return {
        apiQueryParams: `asin=${props.asin}&marketplace=${props.marketplace}`,
      };
    }
    return null;
  }

  render() {
    const {
      columnDefs, defaultColDef, showFooter, enableCharts, enableRangeSelection, apiQueryParams, showDatePicker, sideBar,
    } = this.state;
    const { sellerSelect } = this.props;

    const apiEndPoint = `${apiUrl}/jtsr/asin`;
    const exportPrefix = getExportPrefix('jtsr-asin', sellerSelect);

    return (
      <TableBuilder
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        apiEndPoint={apiEndPoint}
        apiQueryParams={apiQueryParams}
        showFooter={showFooter}
        enableCharts={enableCharts}
        enableRangeSelection={enableRangeSelection}
        showDatePicker={showDatePicker}
        sideBar={sideBar}
        exportPrefix={exportPrefix}
      />
    );
  }
}

export default MarketShareTable;
