import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import PpcDataReport from './components/Page';
import { SellerSelect } from '../../../../shared/prop-types/MainProps';

const PpcDataReportPage = ({ sellerSelect }) => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">PPC Data Report</h3>
      </Col>
    </Row>
    <Row>
      <PpcDataReport accountType={sellerSelect.type} />
    </Row>
  </Container>
);

PpcDataReportPage.propTypes = {
  sellerSelect: SellerSelect.isRequired,
};

const mapStateToProps = (state) => {
  const { sellerSelect } = state;

  return {
    sellerSelect,
  };
};

export default connect(mapStateToProps)(PpcDataReportPage);
