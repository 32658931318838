import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import NotificationSystem from 'rc-notification';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { BasicNotification } from '../../../../../shared/components/Notification';
import { getCorprateUrl } from '../../../../../shared/components/domainSupport';
import config from '../../../../../config';
import Panel from '../../../../../shared/components/Panel';
import {
  currencyDisplay,
} from '../../../../../shared/components/table/functions';
import { SellerSelect, User } from '../../../../../shared/prop-types/MainProps';
import costPerItemCurrencyOptions from './CostPerItemConfig';
import LoadingSpinner from '../../../../../shared/components/widgets/LoadingSpinner';

let notificationLU = null;
let notificationRU = null;
let notificationTC = null;

class QuickStartItems extends PureComponent {
  static propTypes = {
    sellerSelect: SellerSelect.isRequired,
    user: User.isRequired,
    setShowIntro: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const { sellerSelect } = this.props;
    const dynamicItemBudgetOptions = costPerItemCurrencyOptions.find(obj => obj.currencyCode === sellerSelect.currencyCode)?.itemBudgetOptions;

    this.state = {
      acceptTerms: 'FALSE',
      itemBudget: 0.0,
      numberOfItems: 0,
      pacos: 0.0,
      skipItems: '',
      useItems: '',
      useItemsDisplay: [],
      searchQuery: '',
      searchResults: [],
      searching: false,
      lockData: false,
      numberOfItemsDefault: { label: '10', value: 10 },
      numberOfItemsOptions: [
        { label: '5', value: 5 },
        { label: '10', value: 10 },
      ],
      itemBudgetDefault: dynamicItemBudgetOptions ? dynamicItemBudgetOptions[3] : {},
      itemBudgetOptions: dynamicItemBudgetOptions,
      pacosDefault: { label: '20', value: 0.2 },
      pacosOptions: [
        { label: '5', value: 0.5 },
        { label: '10', value: 0.1 },
        { label: '15', value: 0.15 },
        { label: '20', value: 0.2 },
        { label: '25', value: 0.25 },
        { label: '30', value: 0.3 },
        { label: '40', value: 0.4 },
        { label: '50', value: 0.5 },
        { label: '75', value: 0.75 },
      ],
      trialSettings: [],
      formErrors: false,
    };
  }

  componentDidMount() {
    this.getData();
    // eslint-disable-next-line
    NotificationSystem.newInstance({}, n => notificationLU = n);
    // eslint-disable-next-line
    NotificationSystem.newInstance({}, n => notificationRU = n);
    // eslint-disable-next-line
    NotificationSystem.newInstance({}, n => notificationTC = n);
  }

  componentDidUpdate(prevState) {
    const { sellerSelect } = this.props;
    if (sellerSelect.value !== prevState.sellerSelect.value) {
      this.getData();
    }
  }

  componentWillUnmount() {
    if (notificationLU) {
      notificationLU.destroy();
    }
    if (notificationRU) {
      notificationRU.destroy();
    }
    if (notificationTC) {
      notificationTC.destroy();
    }
  }

  // eslint-disable-next-line
  show = (color, title, msg) => {
    return config.showNotification({
      notification: <BasicNotification
        color={color}
        title={title}
        message={msg}
      />,
      position: 'right-up',
      notificationLU,
      notificationRU,
      notificationTC,
    });
  };

  getData = () => {
    const { numberOfItemsOptions, pacosOptions } = this.state;
    const { sellerSelect, user, setShowIntro } = this.props;
    if (sellerSelect.value) { // Need an account id to call the API
      const trialSettingsUrl = `${config.serverUrl}/onboarding/trial-settings?accountId=${sellerSelect.value}`;

      const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
      const requestOptions = {
        method: 'GET',
        headers: headerWithAuth,
      };

      const dynamicItemBudgetOptions = costPerItemCurrencyOptions.find(obj => obj.currencyCode === sellerSelect.currencyCode)?.itemBudgetOptions;

      this.setState({
        panelLoad: true,
        acceptTerms: 'FALSE',
        itemBudget: 0.0,
        numberOfItems: 0,
        pacos: 0.0,
        skipItems: '',
        useItems: '',
        useItemsDisplay: [],
        searchQuery: '',
        searchResults: [],
        lockData: false,
        itemBudgetDefault: dynamicItemBudgetOptions ? dynamicItemBudgetOptions[3] : {},
        itemBudgetOptions: dynamicItemBudgetOptions,
        trialSettings: [],
      });

      fetch(trialSettingsUrl, requestOptions)
        .then((results) => {
          if (!results.ok) {
            throw Error(results.statusText);
          }
          return results.json();
        }).then((data) => {
          const acceptTerms = data.find(row => row.setting_key === 'acceptTerms') || {};
          const itemBudget = data.find(row => row.setting_key === 'itemBudget') || {};
          const numberOfItems = data.find(row => row.setting_key === 'numberOfItems') || {};
          const pacos = data.find(row => row.setting_key === 'pacos') || {};
          const skipItems = data.find(row => row.setting_key === 'skipItems') || [];
          const useItems = data.find(row => row.setting_key === 'useItems') || [];
          const lockData = data.find(row => row.setting_key === 'lockData') || 'FALSE';

          const numberOfItemsDefault = numberOfItemsOptions.find(item => item.value.toString() === numberOfItems.setting_value?.toString());
          const itemBudgetDefault = dynamicItemBudgetOptions.find(item => item.value.toString() === itemBudget.setting_value?.toString());
          const pacosDefault = pacosOptions.find(item => item.value.toString() === pacos.setting_value?.toString());

          setShowIntro(lockData.setting_value === 'TRUE');

          this.setState({
            panelLoad: false,
            acceptTerms: acceptTerms.setting_value,
            itemBudget: itemBudget.setting_value || dynamicItemBudgetOptions[3].value,
            numberOfItems: numberOfItems.setting_value || 10,
            pacos: pacos.setting_value || 0.2,
            skipItems: skipItems.setting_value || '',
            useItems: useItems.setting_value?.split(',').join('\n') || '',
            useItemsDisplay: useItems.setting_value?.split(',') || [],
            lockData: lockData.setting_value === 'TRUE',
            numberOfItemsDefault: numberOfItemsDefault || { label: '10', value: 10 },
            itemBudgetDefault: itemBudgetDefault || dynamicItemBudgetOptions[3],
            pacosDefault: pacosDefault || { label: '20', value: 0.2 },
            trialSettings: data,
          });
        }).catch((error) => {
          console.error(error);
          this.setState({
            panelLoad: false,
          });
        });
    }
  };


  removeItem = (asin, sku) => {
    const { useItems, useItemsDisplay } = this.state;
    const { sellerSelect } = this.props;

    const useItemsArray = useItems.split(',');
    const updateItemsDisplay = [...useItemsDisplay];
    if (sellerSelect.type === 1) { // Seller
      useItemsArray.splice(useItemsArray.findIndex(obj => obj === sku), 1);
      updateItemsDisplay.splice(updateItemsDisplay.findIndex(obj => obj.id === sku), 1);
    } else if (sellerSelect.type === 2) { // Vendor
      useItemsArray.splice(useItemsArray.findIndex(obj => obj === asin), 1);
      updateItemsDisplay.splice(updateItemsDisplay.findIndex(obj => obj.id === asin), 1);
    }
    this.setState({
      useItems: useItemsArray.join(','),
      useItemsDisplay: updateItemsDisplay,
    });
  }

  addItem = (asin, sku, price, title) => {
    const { useItems, useItemsDisplay } = this.state;
    const { sellerSelect } = this.props;

    const useItemsArray = useItems.split(',');
    let updatedUseItems = useItems;
    const updateItemsDisplay = [...useItemsDisplay];
    if (sellerSelect.type === 1) { // Seller
      if (!useItemsArray.includes(sku)) {
        updatedUseItems = useItems === '' ? useItems.concat(sku) : useItems.concat(',', sku);
        updateItemsDisplay.push({ id: sku, html: <tr><td><button type="button" onClick={() => this.removeItem(asin, sku)} className="badge badge-warning badge-btn" style={{ color: '#FFFFFF', background: '#6146FB' }}><span className="lnr lnr-cross" style={{ fontWeight: 'bold' }} /> Remove</button></td><td>{asin}</td><td>{sku}</td><td>{price}</td><td>{title}</td></tr> });
      }
    } else if (sellerSelect.type === 2) { // Vendor
      if (!useItemsArray.includes(asin)) {
        updatedUseItems = useItems === '' ? useItems.concat(asin) : useItems.concat(',', asin);
        updateItemsDisplay.push({ id: sku, html: <tr><td><button type="button" onClick={() => this.removeItem(asin, sku)} className="badge badge-warning badge-btn" style={{ color: '#FFFFFF', background: '#6146FB' }}><span className="lnr lnr-cross" style={{ fontWeight: 'bold' }} /> Remove</button></td><td>{asin}</td><td>{sku}</td><td>{price}</td><td>{title}</td></tr> });
      }
    }
    this.setState({
      useItems: updatedUseItems,
      useItemsDisplay: updateItemsDisplay,
    });
  }

  searchProducts = () => {
    const { searchQuery } = this.state;
    const { sellerSelect, user } = this.props;
    if (sellerSelect.value) { // Need an account id to call the API
      const searchUrl = `${config.serverUrl}/customers/sp-product-metadata-search`;

      const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
      const requestOptions = {
        method: 'POST',
        headers: headerWithAuth,
        body: JSON.stringify({
          accountId: sellerSelect.value,
          searchStr: searchQuery,
        }),
      };

      this.setState({
        searchResults: [],
        searching: true,
      });

      fetch(searchUrl, requestOptions)
        .then((results) => {
          if (!results.ok) {
            throw Error(results.statusText);
          }
          return results.json();
        }).then((itemData) => {
          const rows = [];
          // eslint-disable-next-line
          for (const item of itemData.productMetadataList) {
            if (item.eligibilityStatus === 'ELIGIBLE' && item.availability.startsWith('IN_STOCK')) {
              rows.push(<tr><td><button type="button" onClick={() => this.addItem(item.asin, item.sku, item?.priceToPay?.amount || '', item.title)} className="badge badge-primary badge-btn" style={{ color: '#FFFFFF', background: '#008000' }}><span className="lnr lnr-chevron-down" style={{ fontWeight: 'bold' }} /> Add to Trial</button></td><td>{item.asin}</td><td>{item.sku}</td><td>{item?.priceToPay?.amount || ''}</td><td>{item.title}</td></tr>);
            }
          }
          if (itemData.productMetadataList.length === 0) {
            rows.push('No data found');
          }
          this.setState({
            searchResults: rows,
            searching: false,
          });
        }).catch((error) => {
          console.error(error);
          this.setState({
            searching: false,
          });
        });
    }
  };

  submitSettings = () => {
    const {
      acceptTerms, useItems, skipItems, numberOfItems, itemBudget, pacos, trialSettings,
    } = this.state;
    const { sellerSelect, user } = this.props;
    const trialSettingsUrl = `${config.serverUrl}/onboarding/trial-settings?accountId=${sellerSelect.value}`;

    const settingsArr = [
      { key: 'acceptTerms', type: 'string', value: acceptTerms },
      { key: 'itemBudget', type: 'number', value: itemBudget },
      { key: 'numberOfItems', type: 'number', value: numberOfItems },
      { key: 'pacos', type: 'number', value: pacos },
      { key: 'skipItems', type: 'string', value: skipItems },
      { key: 'useItems', type: 'string', value: useItems },
      { key: 'lockData', type: 'string', value: 'TRUE' },
    ];

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const trialSettingsNew = [];

    // eslint-disable-next-line
    for (const setting of settingsArr) {
      const existingSetting = trialSettings.find(item => item.setting_key === setting.key);
      let settingValue = setting.value?.toString();
      if (setting.key === 'useItems') { // Needs to be cleaned
        if (sellerSelect.type === 2) {
          // eslint-disable-next-line
          settingValue = setting.value?.toString().replace(/ /g, '').replace(/\r/g, '').split('\n').join(',');
        } else {
          settingValue = setting.value?.toString().replace(/\r/g, '').split('\n').join(',');
        }
      }
      if (existingSetting) {
        const newSetting = {
          accountSettingId: existingSetting.account_setting_id,
          accountId: existingSetting.account_id,
          settingKey: existingSetting.setting_key,
          settingValue,
          settingType: existingSetting.setting_type,
          settingStatus: existingSetting.setting_status,
          editRole: existingSetting.edit_role,
          viewRole: existingSetting.view_role,
          settingGroup: existingSetting.setting_group,
          lastUpdatedBy: existingSetting.last_updated_by,
          createdBy: existingSetting.created_by,
        }
        trialSettingsNew.push(newSetting);
      } else {
        const newSetting = {
          accountSettingId: '0',
          accountId: sellerSelect.value,
          settingKey: setting.key,
          settingValue,
          settingType: setting.type,
          settingStatus: 1,
          editRole: 'customer',
          viewRole: 'customer',
          settingGroup: 'customerinput',
          lastUpdatedBy: user.id,
          createdBy: user.id,
        }
        trialSettingsNew.push(newSetting);
      }
    }

    const requestOptions = {
      method: 'POST',
      headers: headerWithAuth,
      body: JSON.stringify({
        accountId: sellerSelect.value,
        trialSettings: trialSettingsNew,
        currency: sellerSelect.currencyCode,
      }),
    };

    this.setState({
      panelLoad: true,
    });

    const useItemsCount = useItems !== '' ? (useItems.split(',').length + useItems.split('\n').length - 1) : 0;

    if (useItemsCount > 0 && useItemsCount < 101) {
      if (acceptTerms === 'TRUE') {
        fetch(trialSettingsUrl, requestOptions)
          .then((results) => {
            if (!results.ok) {
              throw Error(results.statusText);
            }
            return results.json();
          }).then((data) => {
            const errorList = [];
            data.forEach(item => {
              if (item.status === 'error') {
                errorList.push(item.field);
              }
            });
            if (errorList.length > 0) {
              this.show('warning', 'Warning', `We are having issues updating your Trial Settings. Reference: ${errorList.join(',')}`);
            } else {
              this.show('primary', 'Success', 'Trial Settgins have been updated!');
              this.setState({
                lockData: true,
              });
            }
            this.setState({
              panelLoad: false,
            });
          }).catch(() => {
            this.show('danger', 'Error', 'We were unable to update your Trial Settings.');
            this.setState({
              panelLoad: false,
            });
          });
      } else {
        this.show('warning', 'Warning', 'Missing required fields.');
        this.setState({
          panelLoad: false,
          formErrors: true,
        });
      }
    } else {
      this.show('warning', 'Warning', `Please insert 1-100 ${sellerSelect.type === 2 ? 'ASINs' : 'SKUs'}.`);
      this.setState({
        panelLoad: false,
        formErrors: true,
      });
    }
  };


  updateDropdown = (selectedOption, e) => {
    const { lockData } = this.state;
    const { name } = e;
    if (!lockData) {
      this.setState({
        [name]: selectedOption.value,
        [name.concat('Default')]: selectedOption,
      });
    }
  }

  updateSetting = (e) => {
    const { lockData } = this.state;
    let newValue = '';
    const { acceptTerms } = this.state;
    const { value, name } = e.target;
    switch (name) {
      case 'acceptTerms':
        newValue = (acceptTerms === 'FALSE') ? 'TRUE' : 'FALSE';
        break;
      default:
        newValue = value;
    }

    if (!lockData) {
      this.setState({
        [name]: newValue,
      });
    }
  }

  render() {
    const {
      acceptTerms, useItems, useItemsDisplay, searchQuery, searchResults, searching, skipItems, numberOfItemsDefault, numberOfItemsOptions, itemBudgetDefault, itemBudgetOptions, pacosDefault, pacosOptions, panelLoad, formErrors, lockData,
    } = this.state;
    const { sellerSelect } = this.props;

    const flexCenter = {
      display: 'flex',
      alignItems: 'center',
      marginTop: '60px',
    };

    return (
      <Panel
        lg={12}
        md={12}
        title={lockData ? 'Trial Settings - LOCKED' : 'Trial Settings'}
        subhead="Update to start your Trial."
        parentRefresh={panelLoad}
        getData={this.getData}
        showButtons={false}
      >
        <div className="dashboard__sales-report" id="itemBudget">
          <div className="progress-wrap progress-wrap--small" style={{ width: '100%' }}>
            <p className="dashboard__sales-report-title">Set the Daily Product Budget per {sellerSelect.type === 2 ? 'ASIN' : 'SKU'} in {currencyDisplay()}</p>
            <Select
              name="itemBudget"
              className="react-select"
              classNamePrefix="react-select"
              onChange={this.updateDropdown}
              options={itemBudgetOptions}
              value={itemBudgetDefault}
              clearable={false}
            />
          </div>
        </div>
        <br />
        <br />
        <div className="dashboard__sales-report" id="pacos">
          <div className="progress-wrap progress-wrap--small" style={{ width: '100%' }}>
            <p className="dashboard__sales-report-title">Set the PPC ACoS % <span id="pacosHint">(Ad Spend / Ad Sales)</span></p>
            <Select
              name="pacos"
              className="react-select"
              classNamePrefix="react-select"
              onChange={this.updateDropdown}
              options={pacosOptions}
              value={pacosDefault}
              clearable={false}
            />
          </div>
        </div>
        <br />
        <br />
        <div id="productArea">
          <div className="dashboard__sales-report" id="numberOfItems">
            <div className="progress-wrap progress-wrap--small" style={{ width: '100%' }}>
              <p className="dashboard__sales-report-title">Set the maximum number of product AdRev can consider for your trial.</p>
              <Select
                name="numberOfItems"
                className="react-select"
                classNamePrefix="react-select"
                onChange={this.updateDropdown}
                options={numberOfItemsOptions}
                value={numberOfItemsDefault}
                clearable={false}
              />
            </div>
          </div>
          {!lockData && (
            <>
              <br />
              <br />
              <div className="dashboard__sales-report" id="searchQuery">
                <div className="progress-wrap progress-wrap--small">
                  <p className="dashboard__sales-report-title">Search for available and eligibile products in your Amazon Catalog using ASIN, SKU, Title or Keywords.
                  </p>
                  <input
                    type="text"
                    id="searchQuery"
                    name="searchQuery"
                    rows="=0"
                    style={{
                      width: '80%', height: '40px', marginBottom: '20px', marginRight: '30px', verticalAlign: 'middle',
                    }}
                    onChange={this.updateSetting}
                    value={searchQuery}
                    className="form__theme"
                  /><button type="button" className="btn btn-primary badge__btn" onClick={this.searchProducts}>Search</button>
                </div>
              </div>
              <br />
              <br />
              <div className="dashboard__sales-report" id="searchResults">
                <div className="progress-wrap progress-wrap--small">
                  <p className="dashboard__sales-report-title">Search Results: click &quot;add to trial&quot; for each product you would like be considered for trial.</p>
                  <div
                    className="form__theme"
                    style={{
                      overflowX: 'auto', overflowY: 'auto', width: '100%', height: '150px', whiteSpace: 'nowrap',
                    }}
                  >
                    {searching && (
                      <div className="load__icon-wrap" style={flexCenter}>
                        <LoadingSpinner active={searching} />
                      </div>
                    )}
                    {!searching && searchResults.length > 0 && (
                      <table style={{ borderSpacing: '10px', borderCollapse: 'separate' }}>
                        <tbody>
                          <tr><th>ACTION</th><th>ASIN</th><th>SKU</th><th>Price</th><th>Title</th></tr>
                          {searchResults}
                        </tbody>
                      </table>
                    )}
                  </div>
                  <div className="form__form-group-note">Count: {searchResults ? searchResults.length : '0'} of 300</div>
                </div>
              </div>
              <br />
              <br />
              <div className="dashboard__sales-report" id="useItemsDisplay">
                <div className="progress-wrap progress-wrap--small">
                  <p className="dashboard__sales-report-title">Current Products selection, AdRev recommends at least {numberOfItemsDefault.value} products. Up to 100 products can be added for trial consideration.</p>
                  <div
                    className="form__theme"
                    style={{
                      overflowX: 'auto', overflowY: 'auto', width: '100%', height: '150px', whiteSpace: 'nowrap',
                    }}
                  >
                    {!searching && useItemsDisplay.length > 0 && (
                      <table style={{ borderSpacing: '10px', borderCollapse: 'separate' }}>
                        <tbody>
                          <tr><th>ACTION</th><th>ASIN</th><th>SKU</th><th>Price</th><th>Title</th></tr>
                          {useItemsDisplay.map(obj => obj.html)}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {lockData && (
          <>
            <br />
            <br />
            <div className="dashboard__sales-report">
              <div className="progress-wrap progress-wrap--small">
                <p className="dashboard__sales-report-title">Input {sellerSelect.type === 2 ? 'ASINs' : 'SKUs'}:
                  {(useItems.length === 0 || useItems.length > 100) && formErrors && (
                    <span style={{ color: 'red' }}> * {sellerSelect.type === 2 ? 'ASINs' : 'SKUs'} count needs to be between 1 and {numberOfItemsDefault.value}.</span>
                  )}
                </p>
                <textarea id="useItems" name="useItems" className="form__theme" rows="4" style={{ width: '100%' }} value={useItems} />
                <div className="form__form-group-note">Count: {useItems !== '' ? (useItems.split(',').length + useItems.split('\n').length - 1) : '0'} of 100</div>
              </div>
            </div>
          </>
        )}
        {1 === 2 && ( // Save for later
          <>
            <br />
            <br />
            <div className="dashboard__sales-report">
              <div className="progress-wrap progress-wrap--small">
                <p className="dashboard__sales-report-title">Skip {sellerSelect.type === 2 ? 'ASINs' : 'SKUs'}</p>
                <textarea id="skipItems" name="skipItems" rows="4" style={{ width: '100%' }} onChange={this.updateSetting} value={skipItems} />
              </div>
            </div>
          </>
        )}
        <br />
        <br />
        <div className="dashboard__sales-report" id="readAndConfirm">
          <div className="progress-wrap progress-wrap--small">
            <p className="dashboard__sales-report-title">Accept Terms:
              {acceptTerms === 'FALSE' && formErrors && (
                <span style={{ color: 'red' }}> * Required </span>
              )}
            </p>
            <p>
              <input
                id="acceptTerms"
                name="acceptTerms"
                type="checkbox"
                checked={(acceptTerms === 'TRUE') ? 'checked' : ''}
                onChange={this.updateSetting}
              /><span> By continuing, you agree to the <a href={`${getCorprateUrl()}/terms-of-use`}>Terms of Service</a> and acknowledge our <a href={`${getCorprateUrl()}/privacy`}>Privacy Policy</a>.</span>
            </p>
          </div>
        </div>
        <br />
        {!lockData && (
          <span style={{ textAlign: 'center' }} id="submit">
            <p>Once you hit submit, your data will lock in while we setup the trial.</p>
            <div className="progress-wrap progress-wrap--small progress-wrap--pink" style={{ width: '100%' }}>
              <button type="button" className="btn btn-primary account__btn" onClick={this.submitSettings}>Submit Settings</button>
            </div>
          </span>
        )}
        {lockData && (
          <>
            <br />
            <h2 style={{ textAlign: 'center' }}>Data is locked as we review and setup your trial. <br /><br />  Watch your email for further info on your trial.</h2>
          </>
        )}
      </Panel>
    );
  }
}

const mapStateToProps = (state) => {
  const { sellerSelect } = state;
  const { user } = state.authentication;

  return {
    sellerSelect,
    user,
  };
};

export default connect(mapStateToProps)(QuickStartItems);
