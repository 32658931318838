import React, { useState, useEffect } from 'react';
import {
  Badge, Card, CardBody, Collapse,
} from 'reactstrap';
import PropTypes from 'prop-types';
import KeyboardArrowLeftIcon from 'mdi-react/KeyboardArrowLeftIcon';
import KeyboardArrowDownIcon from 'mdi-react/KeyboardArrowDownIcon';
import LoadingSpinner from './LoadingSpinner';

const CardPanel = ({
  divider, color, panelClass, parentRefresh, icon, label, title, subhead, getData, children, showButtons, showArrowButton, defaultOpen, showNameButton, setDefaultOpen,
}) => {
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (refresh) {
      if (getData) {
        getData();
      }
      setRefresh(false);
    }
  }, [refresh, getData]);

  useEffect(() => {
    if (defaultOpen !== null) {
      setOpen(defaultOpen);
    }
    if (defaultOpen !== open) {
      setDefaultOpen(null);
    }
  }, [defaultOpen, open]);

  const openCloseButtonIcon = open ? (<KeyboardArrowDownIcon />) : (<KeyboardArrowLeftIcon />);

  return (
    <Card
      className={`panel${color ? ` panel--${color}` : ''}
      ${divider ? ' panel--divider' : ''}${(open) || !parentRefresh ? '' : ' panel--collapse'} ${panelClass}`}
    >
      <CardBody>
        {(refresh || parentRefresh) ? <LoadingSpinner active={refresh || parentRefresh} wrap /> : ''}
        {showButtons && (
          <div className="panel__btns">
            {showArrowButton && (
              <button className="panel__btn" type="button" onClick={() => setOpen(!open)}>{openCloseButtonIcon}</button>
            )}
          </div>
        )}
        {showNameButton && (
          <div className={open ? 'panel__title' : ''} role="button" tabIndex="0" onClick={() => setOpen(!open)} onKeyDown={() => setOpen(!open)}>
            <h5 className="bold-text">
              {icon ? <span className={`panel__icon lnr lnr-${icon}`} /> : ''}
              {title}
              <Badge className="panel__label">{label}</Badge>
            </h5>
            <h5 className="subhead">{subhead}</h5>
          </div>
        )}
        {!showNameButton && (
          <div className="panel__title">
            <h5 className="bold-text">
              {icon ? <span className={`panel__icon lnr lnr-${icon}`} /> : ''}
              {title}
              <Badge className="panel__label">{label}</Badge>
            </h5>
            <h5 className="subhead">{subhead}</h5>
          </div>
        )}
        <Collapse isOpen={(open) && !parentRefresh}>
          <div className="panel__content">
            {children}
          </div>
        </Collapse>
      </CardBody>
    </Card>
  );
};

CardPanel.defaultProps = {
  divider: false,
  color: '',
  title: '',
  subhead: '',
  label: '',
  icon: '',
  panelClass: '',
  parentRefresh: false,
  getData: null,
  showButtons: true,
  showArrowButton: true,
  children: null,
  defaultOpen: false,
  showNameButton: true,
};

CardPanel.propTypes = {
  divider: PropTypes.bool,
  color: PropTypes.string,
  title: PropTypes.string,
  subhead: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  panelClass: PropTypes.string,
  parentRefresh: PropTypes.bool,
  getData: PropTypes.func,
  showButtons: PropTypes.bool,
  showArrowButton: PropTypes.bool,
  children: PropTypes.instanceOf(Object),
  defaultOpen: PropTypes.bool,
  showNameButton: PropTypes.bool,
};

export default CardPanel;
