import React from 'react';
import { Container, Row } from 'reactstrap';
import TableBuilder from '../../../../../shared/components/table/TableBuilder';
import CardPanel from '../../../../../shared/components/widgets/CardPanel';

function MpOrdersTable({ data }) {
  const columnDefs = [
    {
      field: 'customer_order_id',
      headerName: 'Customer Order ID',
    },
    {
      field: 'purchase_order_id',
      headerName: 'Purchase Order ID',
    },
    {
      field: 'order_type',
      headerName: 'Order Type',
    },
    {
      field: 'original_customer_order_id',
      headerName: 'Orig Customer Order ID',
    },
    {
      field: 'order_date',
      headerName: 'Order Date',
    },
    {
      field: 'buyer_id',
      headerName: 'Buyer ID',
    },
    {
      field: 'mart',
      headerName: 'Mart',
    },
    {
      field: 'is_guest',
      headerName: 'Is Guest',
    },
    {
      field: 'shipping_info.estimated_delivery_date',
      headerName: 'Estimated Delivery Date',
    },
    {
      field: 'shipping_info.estimated_ship_date',
      headerName: 'Estimated Ship Date',
    },
    {
      field: 'shipping_info.method_code',
      headerName: 'Method Code',
    },
    {
      field: 'shipping_info.country',
      headerName: 'Country',
    },
    {
      field: 'shipping_info.state',
      headerName: 'State',
    },
    {
      field: 'shipping_info.postal_code',
      headerName: 'Postal Code',
    },
    {
      field: 'shipping_info.address_type',
      headerName: 'Address Type',
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: false,
    resizable: true,
    cellStyle: (params) => {
      if (params && params.value < 0.00) {
        return { color: 'red' };
      }
      return null;
    },
  };

  return (
    <Container className="todo-app">
      <Row>
        <CardPanel
          autorenewButton={false}
          closeButton={false}
        >
          {data && data.length > 0 && (
            <TableBuilder
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowData={data}
              parentControlledLoad
            />
          )}
        </CardPanel>
      </Row>
    </Container>
  );
}

export default MpOrdersTable;
