import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Col, Row } from 'reactstrap';
import moment from 'moment';
import CardPanel from '../../../../shared/components/widgets/CardPanel';
import DateRangePicker from '../../../../shared/components/widgets/DateRangePicker';
import SearchTrendsChart from './SearchTrendsChart';
import SearchTrendsTable from './SearchTrendsTable';
import { getExportPrefix } from '../../../../shared/components/table/functions';

export default function SearchTrendsReport(props) {
  const { dataProvider } = props;

  const theme = useSelector(store => store.theme);
  const sellerSelect = useSelector(store => store.sellerSelect);
  const user = useSelector(store => store.authentication.user);

  const [startDate, setStartDate] = useState(moment().subtract(9, 'days'));
  const [endDate, setEndDate] = useState(moment().subtract(2, 'days'));
  const [chartLoad, setChartLoad] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [tableLoad, setTableLoad] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableExportFilename, setTableExportFilename] = useState();

  function dateChange(start, end) {
    setStartDate(moment(start))
    setEndDate(moment(end))
  }

  const chartTheme = theme.className.endsWith('light') ? 'ag-default' : 'ag-default-dark';
  const tableTheme = theme.className.endsWith('light') ? 'ag-theme-balham' : 'ag-theme-balham-dark';

  useEffect(() => {
    setChartLoad(true);
    setChartData([]);
    setTableLoad(true);
    setTableData([]);
    setTableExportFilename();
  }, [sellerSelect.value, startDate, endDate]);

  useEffect(() => {
    if (chartLoad) {
      dataProvider.getChartData(user, sellerSelect.value, startDate, endDate)
        .then((results) => {
          if (!results.ok) {
            throw Error(results.statusText);
          }
          return results.json();
        }).then((data) => {
          setChartData(data);
        }).finally(() => {
          setChartLoad(false);
        })
    }
  }, [chartLoad]);

  useEffect(() => {
    if (tableLoad) {
      dataProvider.getTableData(user, sellerSelect.value, startDate, endDate)
        .then((results) => {
          if (!results.ok) {
            throw Error(results.statusText);
          }
          return results.json();
        }).then((data) => {
          setTableData(data);
          const prefix = getExportPrefix('wm-search-terms', sellerSelect);
          setTableExportFilename(`${prefix}-${startDate.format('YYYYMMDD')}-${endDate.format('YYYYMMDD')}.csv`)
        }).finally(() => {
          setTableLoad(false);
        })
    }
  }, [tableLoad]);

  return (
    <Container className="dashboard">
      <Col md={12}>
        <Row>
          <CardPanel showButtons={false}>
            <Row>
              <Col lg={3}>
                <p>Dates:</p>
                <DateRangePicker
                  startDate={startDate.toDate()}
                  endDate={endDate.toDate()}
                  onDateChange={dateChange}
                />
              </Col>
            </Row>
          </CardPanel>
        </Row>
        <Row>
          <CardPanel showButtons={false}>
            <p>
              Daily count of Top 3 Advertiser search terms positions over time. Searches that contain an item from the catalog in one or more of the three positions.
            </p>
            <SearchTrendsChart parentRefresh={chartLoad} chartHeight="400px" chartTheme={chartTheme} chartData={chartData} />
          </CardPanel>
        </Row>
        <Row>
          <CardPanel parentRefresh={tableLoad} showButtons={false}>
            <Col md={12}>
              <Row>
                <SearchTrendsTable tableTheme={tableTheme} tableColumns={dataProvider.getTableColumns()} tableData={tableData} exportFilename={tableExportFilename} />
              </Row>
              <Row>
                <div>
                  <p>Total Row Count: {tableData.length}</p>
                </div>
              </Row>
            </Col>
          </CardPanel>
        </Row>
      </Col>
    </Container>
  );
}
