import React, { PureComponent } from 'react';
import config from '../../../../config';
import TableBuilder from '../../../../shared/components/table/TableBuilder';
import { getExportPrefix } from '../../../../shared/components/table/functions';
// import {
//   formatFixedTwoPercent, currencyFormatter,
// } from '../../../../shared/components/table/functions';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class ProfitsTable extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        // { field: 'adGroupName', headerName: 'Ad Group Name' },
        // { field: 'campaignName', headerName: 'Campaign Name' },
        { field: 'sku', headerName: 'SKU' },
        { field: 'asin', headerName: 'ASIN' },
        { field: 'impressions', headerName: 'Impressions' },
        { field: 'clicks', headerName: 'Clicks' },
        { field: 'spend', headerName: 'Spend' },
        { field: 'sales', headerName: 'Sales' },
        { field: 'acos', headerName: 'ACoS' },
        { field: 'ctr', headerName: 'CTR' },
        { field: 'cpc', headerName: 'CPC' },
        { field: 'orders', headerName: 'Orders' },
        { field: 'sales', headerName: 'Sales' },
        { field: 'acos_7d', headerName: 'ACoS 7d' },
        { field: 'acos_14d', headerName: 'ACoS 14d' },
      ],
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        cellStyle: (params) => {
          if (params.value < 0.00) {
            return { color: 'red' };
          }
          return null;
        },
      },
      showDatePicker: true,
      pdOptions: {
        showButton: true,
        columnName: 'asin',
      },
    };
  }

  // TODO: Clean up for specific report now that this is defined by report.
  // eslint-disable-next-line
  getGroupRowAgg(params) {
    const result = {
      orderCharges: 0,
      orderPromotions: 0,
      orderFees: 0,
      orderTaxes: 0,
      orderQuantity: 0,
      refundCharges: 0,
      refundPromotions: 0,
      refundFees: 0,
      refundTaxes: 0,
      refundQuantity: 0,
      adjustmentAmounts: 0,
      amazonPayout: 0,
      CALCunitCog: 0,
      unit_cog: 0,
      CALCtotalCog: 0,
      adsCost: 0,
      sales: 0,
      spend: 0,
      acos: 0,
      clicks: 0,
      impressions: 0,
      ctr: 0,
      price: 0,
      quantity: 0,
      pendingQuantity: 0,
      totalQuantity: 0,
      unit_inbound: 0,
      bacos: 0,
      sumBacos: 0,
      nonZeroBacos: 0,
      CALCgrossProfit: 0,
      CALCamazonPayout: 0,
      sourcing_shipped_cogs: 0,
      sourcing_shipped_units: 0,
      shipped_units: 0,
      ordered_units: 0,
      lbbCount: 0,
      lbbSum: 0,
    };
    params.nodes.forEach((node) => {
      const data = node.group ? node.aggData : node.data;
      if (typeof data.orderCharges === 'number') {
        result.orderCharges += data.orderCharges;
      }
      if (typeof data.orderFees === 'number') {
        result.orderFees += data.orderFees;
      }
      if (typeof data.orderPromotions === 'number') {
        result.orderPromotions += data.orderPromotions;
      }
      if (typeof data.orderTaxes === 'number') {
        result.orderTaxes += data.orderTaxes;
      }
      if (typeof data.orderQuantity === 'number') {
        result.orderQuantity += data.orderQuantity;
      }
      if (typeof data.refundCharges === 'number') {
        result.refundCharges += data.refundCharges;
      }
      if (typeof data.refundPromotions === 'number') {
        result.refundPromotions += data.refundPromotions;
      }
      if (typeof data.refundFees === 'number') {
        result.refundFees += data.refundFees;
      }
      if (typeof data.refundTaxes === 'number') {
        result.refundTaxes += data.refundTaxes;
      }
      if (typeof data.refundQuantity === 'number') {
        result.refundQuantity += data.refundQuantity;
      }
      if (typeof data.adjustmentAmounts === 'number') {
        result.adjustmentAmounts += data.adjustmentAmounts;
      }
      if (typeof data.amazonPayout === 'number') {
        result.amazonPayout += data.amazonPayout;
      }
      if (typeof data.unit_cog === 'number' && typeof data.orderQuantity === 'number' && typeof data.refundQuantity === 'number' && (data.orderQuantity - data.refundQuantity) >= 0) {
        result.CALCunitCog += data.unit_cog;
      }
      if (typeof data.adsCost === 'number') {
        result.adsCost += data.adsCost;
      }
      if (typeof data.sales === 'number') {
        result.sales += data.sales;
      }
      if (typeof data.spend === 'number') {
        result.spend += data.spend;
      }
      if (typeof data.clicks === 'number') {
        result.clicks += data.clicks;
      }
      if (typeof data.impressions === 'number') {
        result.impressions += data.impressions;
      }
      if (typeof data.price === 'number') {
        result.price += data.price;
      }
      if (typeof data.quantity === 'number') {
        result.quantity += data.quantity;
      }
      if (typeof data.pendingQuantity === 'number') {
        result.pendingQuantity += data.pendingQuantity;
      }
      if (typeof data.totalQuantity === 'number') {
        result.totalQuantity += data.totalQuantity;
      }
      if (typeof data.unit_inbound === 'number') {
        result.unit_inbound += data.unit_inbound;
      }
      if (typeof data.bacos === 'number') {
        result.sumBacos += data.bacos;
        if (data.bacos !== 0) {
          result.nonZeroBacos += 1;
        }
      }
      if (typeof data.amazonPayout === 'number' && typeof data.adjustmentAmounts === 'number' && typeof data.orderQuantity === 'number' && typeof data.refundQuantity === 'number' && typeof data.adsCost === 'number' && typeof data.orderTaxes === 'number') {
        if ((data.orderQuantity - data.refundQuantity) > 0) {
          const unitCog = (data.unit_cog !== undefined) ? data.unit_cog : 0;
          result.CALCgrossProfit += data.amazonPayout + data.adjustmentAmounts + ((data.orderQuantity - data.refundQuantity) * unitCog * -1) + data.adsCost + (data.orderTaxes * -1);
        } else {
          result.CALCgrossProfit += data.amazonPayout + data.adjustmentAmounts + data.adsCost + (data.orderTaxes * -1);
        }
      }
      if (typeof data.orderQuantity === 'number' && typeof data.refundQuantity === 'number' && typeof data.unit_cog === 'number' && (data.orderQuantity - data.refundQuantity) >= 0) {
        result.CALCtotalCog += (data.orderQuantity - data.refundQuantity) * data.unit_cog * -1;
      }
      if (typeof data.sourcing_shipped_cogs === 'number') {
        result.sourcing_shipped_cogs += data.sourcing_shipped_cogs;
      }
      if (typeof data.sourcing_shipped_units === 'number') {
        result.sourcing_shipped_units += data.sourcing_shipped_units;
      }
      if (typeof data.shipped_units === 'number') {
        result.shipped_units += data.shipped_units;
      }
      if (typeof data.ordered_units === 'number') {
        result.ordered_units += data.ordered_units;
      }
      if (typeof data.lbb === 'number') {
        result.lbbCount += 1;
        result.lbbSum += data.lbb;
      }
    });

    if (result.orderCharges !== 0) {
      result.CALCprofitPerc = (result.CALCgrossProfit / result.orderCharges);
      result.CALCTACoS = (Math.floor(((result.adsCost * -1) / result.orderCharges) * 10000) / 10000);
    } else {
      result.CALCprofitPerc = 0;
      result.CALCTACoS = 0;
    }

    if (result.lbbCount !== 0) {
      result.lbb = result.lbbSum / result.lbbCount;
    }

    if (result.nonZeroBacos !== 0) {
      result.bacos = result.sumBacos / result.nonZeroBacos;
    }

    if (result.sourcing_shipped_cogs !== 0) {
      result.acos = result.spend / result.sales;
      result.CALCVendorPacos = result.acos;
    }
    result.ctr = result.clicks / result.impressions;
    result.CALCorderTaxes = result.orderTaxes * -1;
    result.CALCorderFees = result.orderFees + result.orderTaxes;
    result.CALCrefundFees = result.refundFees + result.refundTaxes;
    result.CALCamazonPayout = result.amazonPayout + result.adjustmentAmounts;
    result.CALCNetACoS = result.bacos - result.CALCTACoS;

    return result;
  }

  render() {
    const {
      columnDefs, defaultColDef, showDatePicker, pdOptions,
    } = this.state;
    const { sellerSelect } = this.props;

    const apiEndPoint = `${apiUrl}/customers/product-ads`;
    const exportPrefix = getExportPrefix('product-ads', sellerSelect);

    return (
      <TableBuilder
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        apiEndPoint={apiEndPoint}
        showDatePicker={showDatePicker}
        getGroupRowAgg={this.getGroupRowAgg}
        productDetailOptions={pdOptions}
        exportPrefix={exportPrefix}
      />
    );
  }
}

export default ProfitsTable;
