import React, { PureComponent } from 'react';
import config from '../../../../config';
import TableBuilder from '../../../../shared/components/table/TableBuilder';
// import {
//   formatDecimalToFixedTwoPercent, currencyFormatter,
// } from '../../../../shared/components/table/functions';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

function eligibilityStatusItem(data, itemType) {
  let returnValue = '';
  if (data && data.eligibilityStatusList) {
    const errorData = data.eligibilityStatusList;
    if (errorData) {
      if (errorData.length > 0) {
        errorData.forEach((item) => {
          if (item.name === itemType) {
            returnValue = `${item.level} - ${item.message}`;
          }
        });
      }
    }
  }
  return returnValue;
}

class ProductEligibilityTable extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        { field: 'productDetails.asin', headerName: 'ASIN' },
        { field: 'productDetails.sku', headerName: 'SKU' },
        { field: 'overallStatus', headerName: 'Overall Status' },
        {
          field: 'eligibilityStatusList',
          headerName: 'NOT_IN_BUYBOX',
          valueGetter: params => eligibilityStatusItem(params.data, 'NOT_IN_BUYBOX'),
        },
        {
          field: 'eligibilityStatusList',
          headerName: 'OUT_OF_STOCK',
          valueGetter: params => eligibilityStatusItem(params.data, 'OUT_OF_STOCK'),
        },
        {
          field: 'eligibilityStatusList',
          headerName: 'LISTING_SUPRESSED',
          valueGetter: params => eligibilityStatusItem(params.data, 'LISTING_SUPRESSED'),
        },
        {
          field: 'eligibilityStatusList',
          headerName: 'MISSING_IMAGE',
          valueGetter: params => eligibilityStatusItem(params.data, 'MISSING_IMAGE'),
        },
        {
          field: 'eligibilityStatusList',
          headerName: 'MISSING_TITLE',
          valueGetter: params => eligibilityStatusItem(params.data, 'MISSING_TITLE'),
        },
        {
          field: 'eligibilityStatusList',
          headerName: 'INELIGIBLE_CONDITION',
          valueGetter: params => eligibilityStatusItem(params.data, 'INELIGIBLE_CONDITION'),
        },
        {
          field: 'eligibilityStatusList',
          headerName: 'INELIGIBLE_OFFER',
          valueGetter: params => eligibilityStatusItem(params.data, 'INELIGIBLE_OFFER'),
        },
        {
          field: 'eligibilityStatusList',
          headerName: 'INELIGIBLE_PRODUCT_COST',
          valueGetter: params => eligibilityStatusItem(params.data, 'INELIGIBLE_PRODUCT_COST'),
        },
        {
          field: 'eligibilityStatusList',
          headerName: 'ADULT_PRODUCT',
          valueGetter: params => eligibilityStatusItem(params.data, 'ADULT_PRODUCT'),
        },
        {
          field: 'eligibilityStatusList',
          headerName: 'CLOSED_CATEGORY',
          valueGetter: params => eligibilityStatusItem(params.data, 'CLOSED_CATEGORY'),
        },
        {
          field: 'eligibilityStatusList',
          headerName: 'RESTRICTED_CATEGORY',
          valueGetter: params => eligibilityStatusItem(params.data, 'RESTRICTED_CATEGORY'),
        },
        {
          field: 'eligibilityStatusList',
          headerName: 'VARIATION_PARENT',
          valueGetter: params => eligibilityStatusItem(params.data, 'VARIATION_PARENT'),
        },
      ],
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        cellStyle: (params) => {
          if (params.value < 0.00) {
            return { color: 'red' };
          }
          return null;
        },
      },
      showFooter: true,
      showDatePicker: false,
    };
  }

  render() {
    const {
      columnDefs, defaultColDef, showFooter, showDatePicker,
    } = this.state;

    const apiEndPoint = `${apiUrl}/customers/product-eligibility`;

    return (
      <TableBuilder
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        apiEndPoint={apiEndPoint}
        showFooter={showFooter}
        showDatePicker={showDatePicker}
      />
    );
  }
}

export default ProductEligibilityTable;
