import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import Select from 'react-select';
import {
  fetchTodoListData,
  editTodoElement,
  deleteTodoElement, addTodoElement,
} from './redux/actions';
import { ThemeProps } from '../../../shared/prop-types/ReducerProps';
import ItemEditModal from './components/form/ItemEditModal';
import TodoListWrapper from './components/TodoList';
import TodoSidebar from './components/TodoSidebar';
import todoCard from './types';
import { SellerSelect, User } from '../../../shared/prop-types/MainProps';

const DividerLine = ({ title }) => (
  <Col md={12}>
    <div className="todo-app__divider">
      <div className="todo-app__divider-line" />
      <p className="todo-app__divider-title">{title}</p>
      <div className="todo-app__divider-line" />
    </div>
  </Col>
);

const todoViewOptions = [
  { value: 'accountId', label: 'Account ID' },
  { value: 'assignedToEmail', label: 'Assigned to me' },
  { value: 'submittedByEmail', label: 'Submitted by me' },
];


DividerLine.propTypes = {
  title: PropTypes.string.isRequired,
};

const Todo = ({
  theme, sellerSelect, user,
  todoElements,
  addTodoElementAction, editTodoElementAction, fetchTodoListDataAction, deleteTodoElementAction,
  isFetching,
}) => {
  const [incompleteTodoElements, setIncompleteTodoElements] = useState(null);
  const [completedTodoElements, setCompletedTodoElements] = useState(null);
  const [archivedTodoElements, setArchivedTodoElements] = useState(null);
  const [currentEditItem, setCurrentEditItem] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [filterByPriority, setFilterByPriority] = useState('');
  const [prevTodoElements, setPrevTodoElements] = useState(null);
  const [prevSellerSelect, setPrevSellerSelect] = useState(null);
  const [prevTodoView, setPrevTodoView] = useState({ value: 'accountId', label: 'Account ID' });
  const [todoView, setTodoView] = useState({ value: 'accountId', label: 'Account ID' });

  useEffect(() => {
    let updateChangeOccured = false;
    if (prevSellerSelect === null || prevSellerSelect !== sellerSelect) {
      updateChangeOccured = true;
    }

    if (prevTodoView !== todoView) {
      setPrevTodoView(todoView);
      updateChangeOccured = true;
    }

    if ((JSON.stringify(todoElements) !== JSON.stringify(prevTodoElements)) || updateChangeOccured) {
      if ((todoElements.length === 0 && prevTodoElements === null) || updateChangeOccured) {
        setPrevSellerSelect(sellerSelect);
        if (todoView.value === 'accountId') {
          fetchTodoListDataAction({ accountId: sellerSelect.value });
        } else if (todoView.value === 'assignedToEmail') {
          fetchTodoListDataAction({ assignedToEmail: user.user.email });
        } else if (todoView.value === 'submittedByEmail') {
          fetchTodoListDataAction({ submittedByEmail: user.user.email });
        }
      }
      const filteredData = [...todoElements];
      let activeTodoElements = filteredData.filter(item => !item.data.isArchived);

      if (filterByPriority !== '') {
        activeTodoElements = activeTodoElements.filter(item => item.data.priority === filterByPriority);
      }
      setIncompleteTodoElements(activeTodoElements.filter(item => !item.data.isCompleted));
      setCompletedTodoElements(activeTodoElements.filter(item => item.data.isCompleted));
      setArchivedTodoElements(filteredData.filter(item => item.data.isArchived));
      setPrevTodoElements([...todoElements]);
    }
  }, [prevTodoElements, fetchTodoListDataAction, todoElements, filterByPriority, sellerSelect, todoView]);

  const handleTodoViewChange = (option) => {
    setTodoView(option);
  };

  const changeShowEditModal = (data) => {
    setShowEditModal(!showEditModal);
    setCurrentEditItem(data);
  };

  const filteringByPriority = (priority) => {
    let filteredByPriorityTodoElements = [];
    if (priority === '') {
      filteredByPriorityTodoElements = [...todoElements];
    } else {
      filteredByPriorityTodoElements = todoElements.filter(item => item.data.priority === priority);
    }
    setFilterByPriority(priority);
    setIncompleteTodoElements(filteredByPriorityTodoElements.filter(item => !item.data.isCompleted));
    setCompletedTodoElements(filteredByPriorityTodoElements.filter(item => item.data.isCompleted));
  };

  return (
    <Container className="todo-app">
      <Row>
        <Col md={12}>
          <div style={{ width: '100px', height: '40px', float: 'left' }}>
            <h3 className="page-title">Todo List</h3>
          </div>
          <div style={{ marginLeft: '100px', height: '40px', width: '200px' }}>
            <Select
              className="react-select"
              classNamePrefix="react-select"
              onChange={handleTodoViewChange}
              options={todoViewOptions}
              defaultValue={todoView}
              clearable={false}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={9} xl={10}>
          <Fragment>
            <DividerLine title="Active" />
            <TodoListWrapper
              todoElements={incompleteTodoElements}
              changeShowEditModal={changeShowEditModal}
              editTodoElementAction={editTodoElementAction}
              isFetching={isFetching}
            />
            <DividerLine title="Done" />
            <TodoListWrapper
              isCompleted
              todoElements={completedTodoElements}
              changeShowEditModal={changeShowEditModal}
              editTodoElementAction={editTodoElementAction}
              isFetching={isFetching}
            />
            <div>
              <DividerLine title="Archived" />
              <TodoListWrapper
                isArchived
                todoElements={archivedTodoElements}
                editTodoElementAction={editTodoElementAction}
                deleteTodoElementAction={deleteTodoElementAction}
                isFetching={isFetching}
              />
            </div>
          </Fragment>
        </Col>
        <Col md={3} xl={2}>
          <TodoSidebar
            changeShowEditModal={changeShowEditModal}
            filterByPriority={filteringByPriority}
          />
        </Col>
        <ItemEditModal
          theme={theme}
          showEditModal={showEditModal}
          currentEditItem={currentEditItem && currentEditItem.data}
          changeShowEditModal={changeShowEditModal}
          todoElements={todoElements}
          addTodoElementAction={addTodoElementAction}
          editTodoElementAction={editTodoElementAction}
        />
      </Row>
    </Container>
  );
};

Todo.propTypes = {
  theme: ThemeProps.isRequired,
  sellerSelect: SellerSelect.isRequired,
  user: User.isRequired,
  todoElements: PropTypes.arrayOf(todoCard).isRequired,
  fetchTodoListDataAction: PropTypes.func.isRequired,
  editTodoElementAction: PropTypes.func.isRequired,
  addTodoElementAction: PropTypes.func.isRequired,
  deleteTodoElementAction: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const todoElements = state.todo && state.todo.data && state.todo.data.elements
    && state.todo.data.elements.length > 0 ? [...state.todo.data.elements] : [];
  return ({
    todoElements,
    isFetching: state.todo && state.todo.isFetching,
    theme: state.theme,
    sellerSelect: state.sellerSelect,
    user: state.authentication,
  });
};

const mapDispatchToProps = {
  fetchTodoListDataAction: fetchTodoListData,
  editTodoElementAction: editTodoElement,
  addTodoElementAction: addTodoElement,
  deleteTodoElementAction: deleteTodoElement,
};

export default connect(mapStateToProps, mapDispatchToProps)(Todo);
