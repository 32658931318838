import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import PropTypes from 'prop-types';
import AuthAdApiStatus from './components/AuthorizeAdApiStatus';

const AuthAdApiStatusIndex = ({ accountTypeName }) => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Authorizing Advertising API Status</h3>
      </Col>
    </Row>
    <Row>
      <br />
    </Row>
    <Row>
      <AuthAdApiStatus accountTypeName={accountTypeName} />
    </Row>
    <Row>
      <br />
    </Row>
  </Container>
);

AuthAdApiStatusIndex.defaultProps = {
  accountTypeName: 'seller',
};

AuthAdApiStatusIndex.propTypes = {
  accountTypeName: PropTypes.string,
};

export default AuthAdApiStatusIndex;
