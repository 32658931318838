import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import AuthSpApiStatus from './components/AuthSpApiStatus';

const AuthSpApiStatusIndex = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Authorizing Selling Partner API Status</h3>
      </Col>
    </Row>
    <Row>
      <br />
    </Row>
    <Row>
      <AuthSpApiStatus />
    </Row>
    <Row>
      <br />
    </Row>
  </Container>
);

export default AuthSpApiStatusIndex;
