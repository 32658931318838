import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Picker from 'rsuite/DateRangePicker'
import lodash from 'lodash'

export default function DateRangePicker(props) {
  const {
    label, startDate, endDate, onDateChange, loading,
  } = props;


  const today = moment();
  const oneWeekAgo = moment().subtract(1, 'week');
  const oneMonthAgo = moment().subtract(1, 'month');

  // eslint-disable-next-line
  const [pickerId, setPickerId] = useState(lodash.uniqueId('date-range-picker-'));
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }
  })

  const predefinedRanges = [
    {
      label: 'Today',
      value: [today.toDate(), today.toDate()],
      placement: 'left',
    },
    {
      label: 'Last 7 days',
      value: [oneWeekAgo.toDate(), today.toDate()],
      placement: 'left',
    },
    {
      label: 'Last 30 days',
      value: [moment().subtract(30, 'days').toDate(), moment().subtract(1, 'days').toDate()],
      placement: 'left',
    },
    {
      label: 'This week',
      value: [moment().startOf('week').toDate(), today.toDate()],
      placement: 'left',
    },
    {
      label: 'Last week',
      value: [oneWeekAgo.startOf('week').toDate(), oneWeekAgo.endOf('week').toDate()],
      placement: 'left',
    },
    {
      label: 'This month',
      value: [moment().startOf('month').toDate(), today.toDate()],
      placement: 'left',
    },
    {
      label: 'Last month',
      value: [oneMonthAgo.startOf('month').toDate(), oneMonthAgo.endOf('month').toDate()],
      placement: 'left',
    },
  ]

  return (
    <>
      {label && (
        <p>{label}</p>
      )}
      <div id={pickerId} className="topbar__dynamicDropdownWidth">
        <Picker
          defaultValue={[startDate, endDate]}
          ranges={predefinedRanges}
          onChange={dates => { onDateChange(dates[0], dates[1]) }}
          container={document.getElementById(pickerId)}
          loading={loading}
        />
      </div>
    </>
  )
}
