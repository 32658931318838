import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import FullWidthFillGraph from '../../../../../shared/components/widgets/FullWidthFillGraph';
import {
  currencyDisplay, formatNumberTwoPlaces, formatNumberDefaultToZero, formatDecimalToFixedTwoPercent,
} from '../../../../../shared/components/table/functions';

const ReportGraph = ({ loading, graphData }) => (
  <Container className="dashboard">
    <Col md={12}>
      <Row>
        <FullWidthFillGraph
          data={graphData}
          title="Spend"
          initalLoad={loading}
          tickFormatter={dollar => `${currencyDisplay()}${formatNumberTwoPlaces(dollar)}`}
          lineList={['Spend', 'Sales']}
          colorOptions={['#70bbfd', '#c88ffa']}
        />
        <FullWidthFillGraph
          data={graphData}
          title="Orders and Units"
          initalLoad={loading}
          tickFormatter={number => formatNumberDefaultToZero({ value: number })}
          lineList={['Orders', 'Units']}
          colorOptions={['#f47b50', '#66CCFF']}
        />
        <FullWidthFillGraph
          data={graphData}
          title="PPC ACoS"
          initalLoad={loading}
          tickFormatter={number => formatDecimalToFixedTwoPercent({ value: number })}
          lineList={['PPC_ACoS']}
          colorOptions={['#4ce1b6']}
        />
      </Row>
    </Col>
  </Container>
);


export default ReportGraph;
