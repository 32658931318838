import React, { useEffect, useState } from 'react';
import { FiCameraOff } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useAccountCountry } from '../hooks/useMarketPlace';

function getDefaultUrl(asin) {
  return `https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=${asin}&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL75_`;
}

function getImageUrl(region, marketplace, asin) {
  if (marketplace !== 'AE') {
    return `https://ws-${region}.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=${marketplace}&ASIN=${asin}&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL75_`;
  }
  return `https://ws-${region}.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=${asin}&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL75_`;
}

function getFallbackUrl(region, asin) {
  if (region !== 'na') {
    return `https://ws-${region}.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=${asin}&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL75_`;
  }
  return getDefaultUrl(asin);
}

function ImageCellRenderer(params) {
  const { data } = params;
  const sellerSelect = useSelector(state => state.sellerSelect);
  const country = useAccountCountry(sellerSelect);
  const iconStyle = { width: 40, height: 40, color: 'var(--secondary)' };
  const [useFallback, setUseFallback] = useState(false);
  const [useDefault, setUseDefault] = useState(false);
  const [foundImage, setFoundImage] = useState(false);
  const [product] = useState(data && (data.asin || data.asin1 || data.productId));
  const [url, setUrl] = useState(data && getImageUrl(country.region, country.value, product));
  const imageWidth = 75;
  const imageHeight = 75;

  function setFallbackUrl(region, asin) {
    setUseFallback(true);
    setUrl(getFallbackUrl(region, asin));
  }

  function setDefaultUrl(asin) {
    setUseDefault(true);
    setUrl(getDefaultUrl(asin));
  }

  useEffect(() => {
    if (url) {
      setFoundImage(true);
    } else {
      setFoundImage(false);
    }
  }, [url, setFoundImage]);

  return (
    <>
      {foundImage && !useFallback && !useDefault && (
        <img onError={() => setFallbackUrl(country.region, product)} src={url} width={imageWidth} height={imageHeight} title="Product Image" alt={product} loading="lazy" />
      )}
      {foundImage && useFallback && (
        <img onError={() => setDefaultUrl(product)} src={url} width={imageWidth} height={imageHeight} title="Product Image" alt={product} loading="lazy" />
      )}
      {foundImage && useDefault && (
        <img onError={() => setUrl(undefined)} src={url} width={imageWidth} height={imageHeight} title="Product Image" alt={product} loading="lazy" />
      )}
      {!foundImage && data && (
        <FiCameraOff className="ag-no-image-icon" style={iconStyle} alt="Image not available" />
      )}
      {!data && (
        null
      )}
    </>
  );
}

export const imageFieldColumn = {
  field: 'img',
  headerName: 'Image',
  minWidth: 90,
  maxWidth: 90,
  cellRenderer: 'imageCellRenderer',
  cellClass: 'ag-image-cell',
};

export const imageCellRendererComponent = {
  imageCellRenderer: ImageCellRenderer,
};
