import React, { PureComponent } from 'react';
import NotificationSystem from 'rc-notification';
import { BasicNotification } from '../../../../shared/components/Notification';
import config from '../../../../config';
import TableBuilder from '../../../../shared/components/table/TableBuilder';
import { getExportPrefix } from '../../../../shared/components/table/functions';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

let notificationLU = null;
let notificationRU = null;
let notificationTC = null;

export default class UserTable extends PureComponent {
  constructor() {
    super();
    this.state = {
      columnDefs: [
        {
          field: 'seller_account_id',
          headerName: 'Seller ID',
        },
        {
          field: 'customer_id',
          headerName: 'Customer ID',
        },
        {
          field: 'dev_account_id',
          headerName: 'Dev Account ID',
          valueFormatter: (params) => {
            switch (params.value) {
              case 1:
                return '1 - Americas';
              case 2:
                return '2 - Europe';
              case 3:
                return '3 - Far East';
              default:
                return `${params.value} - Unknown`;
            }
          },
        },
        {
          field: 'seller_account_type_id',
          headerName: 'Seller Type',
          valueFormatter: (params) => {
            switch (params.value) {
              case 1:
                return '1 - Seller';
              case 2:
                return '2 - Vendor';
              default:
                return `${params.value} - Unknown`;
            }
          },
        },
        {
          field: 'marketplace_id',
          headerName: 'Marketplace ID',
          valueFormatter: (params) => {
            switch (params.value) {
              case 3:
                return '3 - US';
              case 4:
                return '4 - CA';
              case 5:
                return '5 - AU';
              case 6:
                return '6 - BR';
              case 7:
                return '7 - CN';
              case 8:
                return '8 - DE';
              case 9:
                return '9 - ES';
              case 10:
                return '10 - FR';
              case 11:
                return '11 - GB';
              case 12:
                return '12 - IN';
              case 13:
                return '13 - IT';
              case 14:
                return '14 - JP,';
              case 15:
                return '15 - TR';
              case 16:
                return '16 - MX';
              case 17:
                return '17 - AE';
              case 18:
                return '18 - NL';
              case 19:
                return '19 - EG';
              case 20:
                return '20 - SA';
              case 21:
                return '21 - SE';
              case 22:
                return '22 - SG';
              default:
                return `${params.value} - Unknown`;
            }
          },
        },
        {
          field: 'seller_account_name',
          headerName: 'Seller Name',
        },
        {
          field: 'seller_account_description',
          headerName: 'Seller Desc',
        },
        {
          field: 'seller_account_source_id',
          headerName: 'Seller Source ID',
        },
        {
          field: 'seller_account_source_token',
          headerName: 'Seller Source Token',
        },
        {
          field: 'seller_account_refresh_token',
          headerName: 'Seller Refresh Token',
        },
        {
          field: 'seller_account_profile_id',
          headerName: 'Seller Profile ID',
        },
        {
          field: 'seller_account_sandbox_profile_id',
          headerName: 'Seller Sandbox Profile ID',
        },
        {
          field: 'selling_partner_refresh_token',
          headerName: 'Selling Partner Refresh Token',
        },
        {
          field: 'selling_partner_id',
          headerName: 'Selling Partner ID',
        },
        {
          field: 'seller_account_date_added',
          headerName: 'Seller Date Added ',
        },
        {
          field: 'seller_account_added_by',
          headerName: 'Seller Added By',
        },
        {
          field: 'seller_account_date_last_update',
          headerName: 'Seller Date Last Updated',
        },
        {
          field: 'seller_account_last_updated_by',
          headerName: 'Seller Last Updated By',
        },
        {
          field: 'amz_ad_api_status',
          headerName: 'Ad API Status',
          editable: true,
          cellRenderer: (params) => {
            if (params && params.data && (params.data.amz_ad_api_status === 1 || params.data.amz_ad_api_status === 'on')) {
              return 'on';
            }
            return 'off';
          },
          valueParser: (params) => {
            if (params && params.data && (params.data.amz_ad_api_status === 1 || params.data.amz_ad_api_status === 'on')) {
              return 'on';
            }
            return 'off';
          },
          cellEditor: 'agRichSelectCellEditor',
          cellEditorPopup: true,
          cellEditorParams: {
            values: [1, 0],
            formatValue: (value) => {
              if (value === 1) {
                return 'on';
              }
              return 'off'
            },
          },
        },
        {
          field: 'amz_mws_api_status',
          headerName: 'MWS API Status',
          editable: true,
          cellRenderer: (params) => {
            if (params && params.data && (params.data.amz_mws_api_status === 1 || params.data.amz_mws_api_status === 'on')) {
              return 'on';
            }
            return 'off';
          },
          valueParser: (params) => {
            if (params && params.data && (params.data.amz_ad_api_status === 1 || params.data.amz_ad_api_status === 'on')) {
              return 'on';
            }
            return 'off';
          },
          cellEditor: 'agRichSelectCellEditor',
          cellEditorPopup: true,
          cellEditorParams: {
            values: [1, 0],
            formatValue: (value) => {
              if (value === 1) {
                return 'on';
              }
              return 'off'
            },
          },
        },
        {
          field: 'amz_sp_api_status',
          headerName: 'SP API Status',
          editable: true,
          cellRenderer: (params) => {
            if (params && params.data && (params.data.amz_sp_api_status === 1 || params.data.amz_sp_api_status === 'on')) {
              return 'on';
            }
            return 'off';
          },
          valueParser: (params) => {
            if (params && params.data && (params.data.amz_ad_api_status === 1 || params.data.amz_ad_api_status === 'on')) {
              return 'on';
            }
            return 'off';
          },
          cellEditor: 'agRichSelectCellEditor',
          cellEditorPopup: true,
          cellEditorParams: {
            values: [1, 0],
            formatValue: (value) => {
              if (value === 1) {
                return 'on';
              }
              return 'off'
            },
          },
        },
        {
          field: 'status',
          headerName: 'Status',
          editable: true,
          cellEditor: 'agRichSelectCellEditor',
          cellEditorPopup: true,
          cellEditorParams: {
            values: ['active', 'active hold', 'active data', 'active terminated', 'dead', 'test'],
          },
        },
        {
          field: 'agency',
          headerName: 'Agency',
          valueFormatter: (params) => {
            // strip off time to keep it clean
            const agencyJson = JSON.parse(params.value || 0);
            return agencyJson.name;
          },
        },
      ],
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
      },
      showDatePicker: false,
      rowDeselection: true,
      enableRangeSelection: false,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line
    NotificationSystem.newInstance({}, n => notificationLU = n);
    // eslint-disable-next-line
    NotificationSystem.newInstance({}, n => notificationRU = n);
    // eslint-disable-next-line
    NotificationSystem.newInstance({}, n => notificationTC = n);
  }

  componentWillUnmount() {
    if (notificationLU) {
      notificationLU.destroy();
    }
    if (notificationLU) {
      notificationRU.destroy();
    }
    if (notificationLU) {
      notificationTC.destroy();
    }
  }

  // eslint-disable-next-line
  show = (color, title, msg) => {
    return config.showNotification({
      notification: <BasicNotification
        color={color}
        title={title}
        message={msg}
      />,
      position: 'right-up',
      notificationLU,
      notificationRU,
      notificationTC,
    });
  };

  parentProcessChange = (rowNode, user) => {
    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
    if (rowNode.data.amz_ad_api_status != null && rowNode.data.amz_mws_api_status != null && rowNode.data.amz_sp_api_status != null && rowNode.data.status && rowNode.data.seller_account_id) {
      const requestOptions = {
        method: 'POST',
        headers: headerWithAuth,
        body: JSON.stringify({
          amzAdApiStatus: rowNode.data.amz_ad_api_status.toString(),
          amzMwsApiStatus: rowNode.data.amz_mws_api_status.toString(),
          amzSpApiStatus: rowNode.data.amz_sp_api_status.toString(),
          status: rowNode.data.status.toString(),
          accountId: rowNode.data.seller_account_id.toString(),
        }),
      };
      // Call via API (would like to update to " await ")
      fetch(`${apiUrl}/accounts/status`, requestOptions)
        .then((results) => {
          if (!results.ok) {
            throw Error(results.statusText);
          }
          return results.json();
        }).then((data) => {
          // success message
          // eslint-disable-next-line
          this.show('primary', 'Success', `Account ${data[0].seller_account_id} Updated Fee and Status.`);
        }).catch(() => {
          this.show('danger', 'Error', `Account ${rowNode.data.seller_account_id.toString()} was not saved.`);
        });
    } else {
      this.show('danger', 'Error', `Account ${(rowNode.data.seller_account_id || '').toString()} was not saved.  Status setting is missing`);
    }
  }

  render() {
    const {
      columnDefs, defaultColDef, showDatePicker, rowDeselection, enableRangeSelection,
    } = this.state;

    const apiEndPoint = `${apiUrl}/accounts`;
    const exportPrefix = getExportPrefix('customer-accounts')

    return (
      <TableBuilder
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        apiEndPoint={apiEndPoint}
        showDatePicker={showDatePicker}
        rowDeselection={rowDeselection}
        enableRangeSelection={enableRangeSelection}
        exportPrefix={exportPrefix}
        parentProcessChange={this.parentProcessChange}
        confirmLogic
      />
    );
  }
}
