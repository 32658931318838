import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import PropTypes from 'prop-types';
import style, { DROPDOWN_COLORS } from '../themeSupport';

export const DEFAULT_ALL_FILTER_VALUE = { label: 'ALL', value: '', sortName: '!ALL' };

/*
 * NOTE: This Implementation of FilterSelect is intended to replace the current FilterSelect.  There are a number of advantages to this
 * one including being simpler and no longer having fetch logic embedded herein. This is presentational only.  There are intended to be
 * several specific users of this -- of which currently only PortfolioFilter and DateViewFilter are implemented and being used.
 * Other specific filter users (AsinFilter, BrandFilter, CategoryFilter) are implemented and awaiting further movement that requires
 * manual data configuration.
 */

export default function FilterSelect({
  onChange, label, name, options, selected, disable, multi,
}) {
  const { theme } = useSelector(state => state);
  const themeColors = style(theme, DROPDOWN_COLORS);

  return (
    <Fragment>
      <p>{label}</p>
      <div className="topbar__dynamicDropdownWidth">
        <Select
          value={selected}
          name={name}
          onChange={onChange}
          options={options}
          theme={selectTheme => ({
            ...selectTheme,
            colors: {
              ...selectTheme.colors,
              primary: `${themeColors.colorPrimary}`,
              neutral0: `${themeColors.colorBackground}`,
              neutral80: `${themeColors.colorText}`,
            },
          })}
          isDisabled={disable}
          isMulti={multi}
          styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
        />
      </div>
    </Fragment>
  );
}

FilterSelect.defaultProps = {
  disable: false,
  multi: false,
};

const optionShape = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  sortName: PropTypes.string,
};

FilterSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape(optionShape)).isRequired,
  selected: PropTypes.shape(optionShape).isRequired,
  disable: PropTypes.bool,
  multi: PropTypes.bool,
};
