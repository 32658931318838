import React from 'react';
import {
  Badge, Card, CardBody, Collapse,
} from 'reactstrap';
import PropTypes from 'prop-types';
import SimplePanel from './SimplePanel';

const amazonIcon = require('../../img/logo/amazonIcon.png');
// const dspIcon = require('../../../shared/img/logo/dspIcon.png');
// const walmartIcon = require('../../../shared/img/logo/walmartIcon.png');

const CardInsight = ({
  color, insightClass, icon, label, title, subhead, countName, countValue, children,
}) => (
  <Card
    className={`insight${color ? ` insight--${color}` : ''} insight_top_border_${insightClass}`}
  >
    <CardBody className="insight__body">
      <div>
        <span className={`lnr lnr-${icon} insight_${insightClass}-img`} />
        <span
          className={'insight_platform-img'}
          style={{
            background: `url(${amazonIcon})`, backgroundPosition: 'left', backgroundRepeat: 'no-repeat', cursor: 'default',
          }}
        />
      </div>
      <div className="insight__card_header-right">
        <h4>{countName} <span className={`insight__count-${insightClass}`}>{countValue}</span></h4>
      </div>

      { title && (
        <div className="insight__title">
          <h5 className="bold-text">
            {icon ? <span className={`insight__icon lnr lnr-${icon}`} /> : ''}
            {title}
            <Badge className="insight__label">{label}</Badge>
          </h5>
          <h5 className="subhead">{subhead}</h5>
        </div>
      )}
      <Collapse isOpen>
        <SimplePanel>
          {children}
        </SimplePanel>
      </Collapse>
    </CardBody>
  </Card>
);

CardInsight.defaultProps = {
  color: '',
  title: '',
  subhead: '',
  countName: '',
  countValue: 0,
  label: '',
  icon: '',
  insightClass: '',
  children: null,
};

CardInsight.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  subhead: PropTypes.string,
  countName: PropTypes.string,
  countValue: PropTypes.number,
  label: PropTypes.string,
  icon: PropTypes.string,
  insightClass: PropTypes.string,
  children: PropTypes.instanceOf(Object),
};

export default CardInsight;
