import React, { PureComponent } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Container, Row, Col, Card, CardBody,
} from 'reactstrap';
import JtsrAsin from './JtsrAsin';
import style, { DROPDOWN_COLORS } from '../../../../shared/components/themeSupport';

// import config from '../../../../config';
// import { history } from '../../../../helpers';

// const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class JtsrAsinMain extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line
    theme: PropTypes.object.isRequired,
    // eslint-disable-next-line
    user: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      marketplaceSelectedOption: { label: 'amazon.com', value: 'amazon.com' },
      marketplaceSelectOptions: [{ label: 'amazon.com', value: 'amazon.com' }],
      asinSelectedOption: { label: 'B001F0IJ9S', value: 'B001F0IJ9S' },
      asinSelectOptions: [{ label: 'B001F0IJ9S', value: 'B001F0IJ9S' }, { label: 'B001F0IDYY', value: 'B001F0IDYY' }, { label: 'B071L26LZ3', value: 'B071L26LZ3' }, { label: 'B07DYXVTQL', value: 'B07DYXVTQL' }],
    };
  }

  // componentDidMount() {
  //   const { user } = this.props;
  //   const { selectedOption } = this.state;
  //   const headerWithAuth = { authorization: `JWT ${user.jwtToken}` };

  //   fetch(`${apiUrl}/vendors/market-share-options`, { headers: headerWithAuth })
  //     .then((resultsJtsrAsinOptions) => {
  //       if (resultsJtsrAsinOptions.status === 401) {
  //         history.push('/logout');
  //       }
  //       return resultsJtsrAsinOptions.json();
  //     })
  //     .then((data) => {
  //       const selectOptions = [];
  //       const marketShareOptionsJson = data;

  //       marketShareOptionsJson.forEach((option) => {
  //         const market = option.substr(0, option.lastIndexOf('_'));
  //         const pullDate = option.substr(option.lastIndexOf('_') + 1);
  //         selectOptions.push({
  //           value: option,
  //           label: `${market.replace(/_/g, ' ')} - ${pullDate}`,
  //         });
  //       });

  //       this.setState({
  //         selectOptions,
  //       });

  //       if (selectOptions.length > 0 && (selectedOption === undefined || selectedOption === {})) {
  //         this.setState({
  //           selectedOption: selectOptions[0],
  //         });
  //       }
  //     }).catch((error) => {
  //       console.error(error);
  //     });
  // }

  handleMarketplaceChange = (selectedValue) => {
    this.setState({
      marketplaceSelectedOption: selectedValue,
    });
  }

  handleAsinChange = (selectedValue) => {
    this.setState({
      asinSelectedOption: selectedValue,
    });
  }


  render() {
    const {
      marketplaceSelectedOption, marketplaceSelectOptions, asinSelectedOption, asinSelectOptions,
    } = this.state;
    const { sellerSelect, theme } = this.props;
    const themeColors = style(theme, DROPDOWN_COLORS);

    return (
      <Container className="dashboard">
        <h3 className="page-title">JTSR</h3>
        <Row>
          <Col md={6}>
            <Card>
              <CardBody>
                <h3 className="page-title">ASIN</h3>
                <div className="topbar__customersellerselect">
                  <Select
                    value={asinSelectedOption}
                    onChange={this.handleAsinChange}
                    options={asinSelectOptions}
                    theme={selectTheme => ({
                      ...selectTheme,
                      colors: {
                        ...selectTheme.colors,
                        primary: `${themeColors.colorPrimary}`,
                        neutral0: `${themeColors.colorBackground}`,
                        neutral80: `${themeColors.colorText}`,
                      },
                    })}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <CardBody>
                <h3 className="page-title">Marketplace</h3>
                <div className="topbar__customersellerselect">
                  <Select
                    value={marketplaceSelectedOption}
                    onChange={this.handleMarketplaceChange}
                    options={marketplaceSelectOptions}
                    theme={selectTheme => ({
                      ...selectTheme,
                      colors: {
                        ...selectTheme.colors,
                        primary: `${themeColors.colorPrimary}`,
                        neutral0: `${themeColors.colorBackground}`,
                        neutral80: `${themeColors.colorText}`,
                      },
                    })}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {asinSelectedOption && marketplaceSelectedOption && (
          <Row>
            <JtsrAsin asin={asinSelectedOption.value} marketplace={marketplaceSelectedOption.value} sellerSelect={sellerSelect} />
          </Row>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { theme, sellerSelect } = state;
  const { user } = state.authentication;

  return {
    theme,
    user,
    sellerSelect,
  };
};

export default connect(mapStateToProps)(JtsrAsinMain);
