import React from 'react';
import { Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import CloseIcon from 'mdi-react/CloseIcon';
import ChartBuilder from '../../../../../shared/components/chart/ChartBuilder';



const TreemapModal = ({
  title, theme, data, labelKey, sizeKey, sizeName, showTreemapModal, changeShowTreemapModal,
}) => {
  const chartOptions = {
    data,
    series: [
      {
        type: 'treemap',
        labelKey,
        // secondaryLabelKey: 'sum_spend_30d',
        sizeKey,
        sizeName,
      },
    ],
    title: {
      text: title,
    },
    // subtitle: {
    //   text: '2024',
    // },
  };

  return (
    <Modal
      isOpen={showTreemapModal}
      toggle={changeShowTreemapModal}
      className={theme.className}
      style={{ maxWidth: '900px', width: '100%' }}
    >
      <button className="panel__btn" type="button" onClick={() => changeShowTreemapModal()}><CloseIcon /></button><h2>{title}</h2>
      <br />
      <br />
      <br />
      <div style={{ textAlign: 'left' }}>
        <ChartBuilder chartOptions={chartOptions} />
      </div>
    </Modal>
  );
};

TreemapModal.propTypes = {
  title: PropTypes.string.isRequired,
  labelKey: PropTypes.string.isRequired,
  sizeKey: PropTypes.string.isRequired,
  sizeName: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  changeShowTreemapModal: PropTypes.func.isRequired,
  showTreemapModal: PropTypes.bool,
};

TreemapModal.defaultProps = {
  showTreemapModal: false,
};

export default TreemapModal;
