import React from 'react';
import {
  Card, CardBody, Col,
} from 'reactstrap';
import md5 from 'md5';
import { connect } from 'react-redux';

const createGravatarHash = userEmail => md5(userEmail);

// eslint-disable-next-line
const ProfileMain = ({ user }) => (
  <Col md={12} lg={12} xl={12}>
    <Card>
      <CardBody className="profile__card">
        <div className="profile__information">
          <div className="profile__avatar">
            <img src={`https://www.gravatar.com/avatar/${createGravatarHash(user.email)}`} alt="avatar" />
          </div>
          <div className="profile__data">
            <p className="profile__name">{user.first_name} {user.last_name}</p>
            <p className="profile__work">Access Level: {user.access}</p>
            <p className="profile__contact">{user.email}</p>
          </div>
        </div>
      </CardBody>
    </Card>
  </Col>
);

const mapStateToProps = (state) => {
  const { user } = state.authentication;
  return {
    user,
  };
};

export default connect(mapStateToProps)(ProfileMain);
