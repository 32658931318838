import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import PpcOverTimeCategoriesPlus from './components/Page';

const PpcOverTimeCategoriesPlusPage = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">PPC Over Time Categories With Portfolios</h3>
      </Col>
    </Row>
    <Row>
      <PpcOverTimeCategoriesPlus />
    </Row>
  </Container>
);

export default PpcOverTimeCategoriesPlusPage;
