import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import NotificationSystem from 'rc-notification';
import { BasicNotification } from '../../../../shared/components/Notification';
import config from '../../../../config';
import Panel from '../../../../shared/components/Panel';
import {
  currencyFormatter, currencyDisplay, formatDecimalToFixedTwoPercent,
} from '../../../../shared/components/table/functions';
import { SellerSelect, User } from '../../../../shared/prop-types/MainProps';
import { getPlatform } from '../../../../shared/components/domainSupport';


let notificationLU = null;
let notificationRU = null;
let notificationTC = null;

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class BudgetTable extends PureComponent {
  static propTypes = {
    sellerSelect: SellerSelect.isRequired,
    user: User.isRequired,
    currentSpend: PropTypes.number.isRequired,
    currentSales: PropTypes.number.isRequired,
    // eslint-disable-next-line
    currentById: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      budgetItems: [],
      panelLoad: false,
      monthlyBudget: 0,
      monthlyPacos: 0,
      monthlyPacosMin: 0,
      monthlyPacosMax: 0,
      totalOnCount: 0,
    };
  }

  componentDidMount() {
    this.getData();
    // eslint-disable-next-line
    NotificationSystem.newInstance({}, n => notificationLU = n);
    // eslint-disable-next-line
    NotificationSystem.newInstance({}, n => notificationRU = n);
    // eslint-disable-next-line
    NotificationSystem.newInstance({}, n => notificationTC = n);
  }

  componentDidUpdate(prevState) {
    const { sellerSelect } = this.props;
    if (sellerSelect.value !== prevState.sellerSelect.value) {
      this.getData();
    }
  }

  componentWillUnmount() {
    if (notificationLU) {
      notificationLU.destroy();
    }
    if (notificationRU) {
      notificationRU.destroy();
    }
    if (notificationTC) {
      notificationTC.destroy();
    }
  }

  // eslint-disable-next-line
  show = (color, title, msg) => {
    return config.showNotification({
      notification: <BasicNotification
        color={color}
        title={title}
        message={msg}
      />,
      position: 'right-up',
      notificationLU,
      notificationRU,
      notificationTC,
    });
  };

  getData = () => {
    const { sellerSelect, user } = this.props;
    const budgetUrl = `${apiUrl}/accounts/budget?accountId=${sellerSelect.value}&platform=${getPlatform().nameKey}&acosAsPercent=true`;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    this.setState({
      panelLoad: true,
      budgetItems: [],
    });

    fetch(budgetUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        let minPacos = 0;
        let maxPacos = 0;

        if (data && data.budgetGroupData) {
          data.budgetGroupData.forEach(budgetGroup => {
            if (budgetGroup.pacos > maxPacos) {
              maxPacos = budgetGroup.pacos;
            }
            if ((budgetGroup.pacos < minPacos && budgetGroup.pacos > 0) || minPacos === 0) {
              minPacos = budgetGroup.pacos;
            }
          });
        }

        this.setState({
          panelLoad: false,
          budgetItems: data.budgetGroupData,
          monthlyBudget: data.monthlyBudgetCurrent,
          monthlyPacos: data.monthlyPacos,
          monthlyPacosMin: minPacos,
          monthlyPacosMax: maxPacos,
          totalOnCount: data.totalOnCount,
        });
      }).catch(() => {
        this.setState({
          panelLoad: false,
        });
      });
  };

  updateBudgetGroup = (e) => {
    const { budgetItems } = this.state;
    const budgetItemsUpdates = [...budgetItems]; // clone array
    const { name, value, dataset } = e.target;

    const { groupName } = dataset;
    const objIndex = budgetItemsUpdates.findIndex((obj => obj.name === groupName));
    const itemName = name;
    const regex = name === 'portfolioIdsCsv' ? /[^\-0-9.,]/g : /[^\-0-9.]/g;
    budgetItemsUpdates[objIndex][itemName] = value.replace(regex, '');
    budgetItemsUpdates[objIndex].budgetPerItem = budgetItemsUpdates[objIndex].itemOnCount > 0 ? (budgetItemsUpdates[objIndex].current / budgetItemsUpdates[objIndex].itemOnCount) : 0;
    // recalc totals
    let newTotal = 0;
    let newMinPacos = 0;
    let newMaxPacos = 0;
    budgetItemsUpdates.forEach((data) => {
      if (data.pacos > newMaxPacos) {
        newMaxPacos = data.pacos;
      }
      if ((data.pacos < newMinPacos && data.pacos > 0) || (newMinPacos === 0)) {
        newMinPacos = data.pacos;
      }
      newTotal += parseFloat(data[itemName]) || 0;
    });

    this.setState({
      budgetItems: budgetItemsUpdates,
    }, () => {
      if (name === 'current') {
        this.setState({
          monthlyBudget: newTotal,
        });
      }
      if (name === 'pacos') {
        this.setState({
          monthlyPacos: newMaxPacos,
          monthlyPacosMin: newMinPacos,
          monthlyPacosMax: newMaxPacos,
        });
      }
    });
  }

  getGroupValue = (groupName, dataType) => {
    const { budgetItems } = this.state;
    const filteredObj = budgetItems.filter((obj => obj.name === groupName));
    if (filteredObj[0][dataType] || filteredObj[0][dataType] === 0) {
      return filteredObj[0][dataType].toString();
    }
    return '';
  };

  submitBudget = () => {
    const { monthlyBudget, monthlyPacos, budgetItems } = this.state;
    const { sellerSelect, user } = this.props;
    const budgetUrl = `${apiUrl}/accounts/budget-simple`;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const requestOptions = {
      method: 'POST',
      headers: headerWithAuth,
      body: JSON.stringify({
        accountId: sellerSelect.value,
        monthlyBudget,
        monthlyPacos,
        currency: (sellerSelect.currencyCode || 'USD'),
        budgetAssignments: budgetItems,
      }),
    };

    // check ranges
    let dataCheck = true;
    budgetItems.forEach((data) => {
      if ((data.pacos < 5 || data.pacos > 80) && data.itemOnCount > 0) {
        dataCheck = false;
      }
    });

    if (dataCheck) {
      this.setState({
        panelLoad: true,
      });

      fetch(budgetUrl, requestOptions)
        .then((results) => {
          if (!results.ok) {
            throw Error(results.statusText);
          }
          return results.json();
        }).then(() => {
          this.show('primary', 'Success', 'Budgets have been updated!');
          this.setState({
            panelLoad: false,
          });
        }).catch(() => {
          this.show('danger', 'Error', 'We were unable to update your budget.');
          this.setState({
            panelLoad: false,
          });
        });
    } else {
      this.show('danger', 'Error', 'PACoS range for Budget Group with ON Items needs to be between 5% and 80%.  All PACoS values must be set, budget can be set as needed');
    }
  };

  render() {
    const {
      budgetItems, panelLoad, monthlyBudget, title, subhead, totalOnCount, monthlyPacosMin, monthlyPacosMax,
    } = this.state;

    const {
      currentSpend, currentSales, currentById,
    } = this.props;

    return (
      <Panel
        lg={12}
        md={12}
        title={title}
        subhead={subhead}
        parentRefresh={panelLoad}
        getData={this.getData}
      >
        <br />
        <br />
        <Table responsive striped className="dashboard__table-orders" id="top10">
          <thead>
            <tr>
              <th>Budget Group</th>
              <th>Estimated Monthly Budget</th>
              <th>Estimated Monthly PACoS</th>
              <th># of Items ON</th>
              <th>Budget Per Item</th>
              <th>MTD PPC Spend</th>
              <th>MTD PPC Sales</th>
              <th>MTD PPC ACoS</th>
            </tr>
          </thead>
          <tbody>
            {budgetItems.map(budgetItem => (
              <tr key={budgetItem.name}>
                <td>{budgetItem.name}</td>
                <td>{currencyDisplay()} <input type="text" name="current" data-group-name={budgetItem.name} onChange={this.updateBudgetGroup} value={this.getGroupValue(budgetItem.name, 'current')} style={{ width: '100px', color: '#000000' }} /></td>
                <td><input type="text" name="pacos" data-group-name={budgetItem.name} onChange={this.updateBudgetGroup} value={this.getGroupValue(budgetItem.name, 'pacos')} style={{ width: '100px', color: '#000000' }} /> %</td>
                <td>{budgetItem.itemOnCount}</td>
                <td>{currencyFormatter({ value: budgetItem.budgetPerItem })}</td>
                <td>{currencyFormatter({ value: (currentById.spend[(budgetItem.name || '')] || 0) })}</td>
                <td>{currencyFormatter({ value: (currentById.sales[(budgetItem.name || '')] || 0) })}</td>
                <td>{formatDecimalToFixedTwoPercent({ value: ((currentById.sales[(budgetItem.name || '')] || 0) > 0 ? ((currentById.spend[(budgetItem.name || '')] || 0) / (currentById.sales[(budgetItem.name || '')])) : 0) })}</td>
              </tr>
            ))}
            <tr key="TOTALS">
              <td>TOTALS</td>
              <td>{currencyFormatter({ value: monthlyBudget })}</td>
              <td>RANGE: {monthlyPacosMin}% - {monthlyPacosMax}%</td>
              <td>{totalOnCount}</td>
              <td>{currencyFormatter({ value: (totalOnCount > 0 ? (monthlyBudget / totalOnCount) : 0) })}</td>
              <td>{currencyFormatter({ value: currentSpend })}</td>
              <td>{currencyFormatter({ value: currentSales })}</td>
              <td>{formatDecimalToFixedTwoPercent({ value: ((currentSales || 0) > 0 ? ((currentSpend || 0) / (currentSales)) : 0) })}</td>
            </tr>
          </tbody>
        </Table>
        <br />
        <br />
        <div className="progress-wrap progress-wrap--small progress-wrap--pink">
          <button type="button" className="btn btn-primary account__btn" onClick={this.submitBudget}>Update Budgets</button>
        </div>
      </Panel>
    );
  }
}

const mapStateToProps = (state) => {
  const { sellerSelect } = state;
  const { user } = state.authentication;

  return {
    sellerSelect,
    user,
  };
};

export default connect(mapStateToProps)(BudgetTable);
