import React, { PureComponent } from 'react';
import config from '../../../../config';
import TableBuilder from '../../../../shared/components/table/TableBuilder';
import {
  currencyFormatter, formatNumberDefaultToZero, formatDecimalToFixedTwoPercent, numberValueParser,
} from '../../../../shared/components/table/functions';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class VendorSaleSegmentShareTable extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        {
          field: 'manufacturing_asin',
          headerName: 'ASIN',
          enableRowGroup: true,
        },
        {
          field: 'manufacturing_parent_asin',
          headerName: 'Parent ASIN',
          hide: true,
        },
        {
          field: 'manufacturing_brand',
          headerName: 'Brand',
          hide: true,
        },
        {
          field: 'manufacturing_subcategory',
          headerName: 'Subcategory',
          hide: true,
        },
        {
          field: 'sku',
          headerName: 'SKU',
          enableRowGroup: true,
        },
        {
          field: 'product_title',
          headerName: 'Product Title',
          enableRowGroup: true,
        },
        {
          field: 'manufacturing_category',
          headerName: 'Category',
          hide: true,
        },
        {
          field: 'list_price', // Pull from Vendor Catalog
          headerName: 'List Price',
          valueFormatter: currencyFormatter,
          hide: true,
        },
        {
          field: 'segment_one',
          headerName: 'Segment 1',
          hide: true,
        },
        {
          field: 'segment_two',
          headerName: 'Segment 2',
          hide: true,
        },
        {
          field: 'segment_three',
          headerName: 'Segment 3',
          hide: true,
        },
        {
          field: 'segment_four',
          headerName: 'Segment 4',
          hide: true,
        },
        {
          field: 'segment_five',
          headerName: 'Segment 5',
          hide: true,
        },
        {
          field: 'lbb',
          headerName: 'LBB (Price)',
          valueFormatter: formatDecimalToFixedTwoPercent,
          hide: true,
        },
        {
          field: 'sourcing_shipped_units',
          headerName: '1P SRC',
          valueFormatter: formatNumberDefaultToZero,
          valueParser: numberValueParser,
          aggFunc: 'sum',
        },
        {
          field: 'manufacturing_shipped_units',
          headerName: '1P MFG',
          valueFormatter: formatNumberDefaultToZero,
          valueParser: numberValueParser,
          aggFunc: 'sum',
        },
        {
          field: 'CALC3P',
          headerName: '3P',
          valueGetter: function calc3PValueGetter(params) {
            if (params
              && params.data
              && params.data.manufacturing_shipped_units
              && params.data.lbb
              && (1 - params.data.lbb) !== 0 && (params.data.manufacturing_shipped_units / (1 - params.data.lbb)) > 0) {
              return (params.data.manufacturing_shipped_units / (1 - params.data.lbb)) - params.data.manufacturing_shipped_units;
            }
            return 0;
          },
          valueFormatter: formatNumberDefaultToZero,
          aggFunc: 'sum',
        },
        {
          field: 'CALCTotal',
          headerName: 'Total',
          valueGetter: function calcTotalValueGetter(params) {
            if (params
              && params.data
              && params.data.manufacturing_shipped_units
              && params.data.lbb
              && (1 - params.data.lbb) !== 0) {
              return (params.data.manufacturing_shipped_units / (1 - params.data.lbb));
            }
            return 0;
          },
          valueFormatter: formatNumberDefaultToZero,
          aggFunc: 'sum',
        },
        {
          field: 'CALC1PSrc',
          headerName: '1P SRC %',
          valueGetter: function calc1PSrcValueGetter(params) {
            if (params
              && params.data
              && params.data.sourcing_shipped_units
              && params.data.manufacturing_shipped_units
              && params.data.lbb
              && (params.data.manufacturing_shipped_units / (1 - params.data.lbb)) !== 0) {
              return (params.data.sourcing_shipped_units / (params.data.manufacturing_shipped_units / (1 - params.data.lbb)));
            }
            return 0;
          },
          valueFormatter: formatDecimalToFixedTwoPercent,
          hide: true,
        },
        {
          field: 'CALC1PMfg',
          headerName: '1P MFG %',
          valueGetter: function calc1PMfgValueGetter(params) {
            if (params
              && params.data
              && params.data.manufacturing_shipped_units
              && params.lbb
              && (params.data.manufacturing_shipped_units / (1 - params.data.lbb)) !== 0) {
              return (params.data.manufacturing_shipped_units / (params.data.manufacturing_shipped_units / (1 - params.data.lbb)));
            }
            return 0;
          },
          valueFormatter: formatDecimalToFixedTwoPercent,
          hide: true,
        },
        {
          field: 'CALC3PPercent',
          headerName: '3P %',
          valueGetter: function calc3PPercentValueGetter(params) {
            if (params
              && params.data
              && params.data.manufacturing_shipped_units
              && params.data.lbb
              && (params.data.manufacturing_shipped_units / (1 - params.data.lbb)) !== 0 && (1 - params.data.lbb) !== 0) {
              return (params.data.manufacturing_shipped_units / (1 - params.data.lbb) - params.data.manufacturing_shipped_units) / (params.data.manufacturing_shipped_units / (1 - params.data.lbb));
            }
            return 0;
          },
          valueFormatter: formatDecimalToFixedTwoPercent,
          hide: true,
        },
        {
          field: 'CALC1PMfgSales',
          headerName: '1P $',
          valueGetter: function calc1PDollarValueGetter(params) {
            if (params
              && params.data
              && params.data.manufacturing_shipped_units
              && params.data.list_price) {
              return (params.data.manufacturing_shipped_units * params.data.list_price);
            }
            return 0;
          },
          valueFormatter: currencyFormatter,
          aggFunc: 'sum',
        },
        {
          field: 'CALC3PSales',
          headerName: '3P $',
          valueGetter: function calc3PDollarValueGetter(params) {
            if (params
                && params.data
                && params.data.manufacturing_shipped_units
                && params.data.lbb
                && (params.data.manufacturing_shipped_units / (1 - params.data.lbb)) !== 0 && (1 - params.data.lbb) !== 0) {
              return (params.data.manufacturing_shipped_units / (1 - params.data.lbb) - params.data.manufacturing_shipped_units) * params.data.list_price;
            }
            return 0;
          },
          valueFormatter: currencyFormatter,
          aggFunc: 'sum',
        },
        {
          field: 'CALCTotalSales',
          headerName: 'Total $',
          valueGetter: function calcTotalDollarValueGetter(params) {
            if (params
              && params.data
              && params.data.manufacturing_shipped_units
              && params.data.lbb
              && Math.ceil(params.data.manufacturing_shipped_units / (1 - params.data.lbb)) !== 0 && (1 - params.data.lbb) !== 0) {
              return Math.ceil(params.data.manufacturing_shipped_units / (1 - params.data.lbb)) * params.data.list_price;
            }
            return 0;
          },
          valueFormatter: currencyFormatter,
          aggFunc: 'sum',
        },
      ],
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        cellStyle: (params) => {
          if (params.value < 0.00) {
            return { color: 'red' };
          }
          return null;
        },
      },
      autoGroupColumnDef: {
        headerName: ' Breakdown ',
        cellRendererParams: {
          suppressCount: true,
        },
      },
      showDatePicker: true,
      showFooter: true,
      enableCharts: true,
      enableRangeSelection: true,
      sideBar: false,
      pivotMode: false,
      suppressAggFuncInHeader: true,
      pdOptions: {
        showButton: true,
        columnName: 'asin',
      },
    };
  }

  render() {
    const {
      columnDefs, defaultColDef, onCellEditingStopped, showDatePicker, showFooter, enableCharts, enableRangeSelection, autoGroupColumnDef,
      sideBar, pivotMode, suppressAggFuncInHeader, pdOptions,
    } = this.state;

    const apiEndPoint = `${apiUrl}/vendors/sales-share`;
    const pivotButtons = [
      { buttonName: 'ASIN', buttonAction: '' },
      { buttonName: 'Parent ASIN', buttonAction: 'manufacturing_parent_asin' },
      { buttonName: 'Brand', buttonAction: 'manufacturing_brand' },
      { buttonName: 'Category', buttonAction: 'manufacturing_category' },
      { buttonName: 'Subcategory', buttonAction: 'manufacturing_subcategory' },
      { buttonName: 'Segment 1', buttonAction: 'segment_one' },
      { buttonName: 'Segment 2', buttonAction: 'segment_two' },
      { buttonName: 'Segment 3', buttonAction: 'segment_three' },
      { buttonName: 'Segment 4', buttonAction: 'segment_four' },
      { buttonName: 'Segment 5', buttonAction: 'segment_five' },
    ];

    return (
      <TableBuilder
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        apiEndPoint={apiEndPoint}
        onCellEditingStopped={onCellEditingStopped}
        showDatePicker={showDatePicker}
        showFooter={showFooter}
        enableCharts={enableCharts}
        enableRangeSelection={enableRangeSelection}
        sideBar={sideBar}
        pivotMode={pivotMode}
        suppressAggFuncInHeader={suppressAggFuncInHeader}
        autoGroupColumnDef={autoGroupColumnDef}
        pivotButtons={pivotButtons}
        defaultStartDays={11}
        defaultEndDays={5}
        productDetailOptions={pdOptions}
      />
    );
  }
}

export default VendorSaleSegmentShareTable;
