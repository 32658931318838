import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import DateRangePicker from '../../../../../shared/components/widgets/DateRangePicker';
import CardPanel from '../../../../../shared/components/widgets/CardPanel';
import FilterSelect from '../../../../../shared/components/widgets/NewFilterSelect';

const FilterReportsPage = ({
  startDate, endDate, dateChange, currentReport, reportChange, loading,
}) => {
  const reportOptions = [
    { label: 'AD Group', value: 'ad_group' },
    { label: 'AD Item', value: 'ad_item' },
    { label: 'Brand', value: 'brand' },
    { label: 'Category', value: 'category' },
    { label: 'Keyword', value: 'keyword' },
    { label: 'Page Type', value: 'page_type' },
    { label: 'Placement', value: 'placement' },
    { label: 'Platform', value: 'platform' },
    { label: 'Video Campaigns', value: 'video_campaigns' },
    { label: 'Video Keywords', value: 'video_keywords' },
  ];

  return (
    <Container className="dashboard">
      <Col md={12}>
        <Row>
          <Col xs={12}>
            <CardPanel
              showButtons={false}
            >
              <Row>
                <Col xs={12} md={4}>
                  <DateRangePicker
                    startDate={startDate.toDate()}
                    endDate={endDate.toDate()}
                    onDateChange={dateChange}
                  />
                </Col>
                <Col xs={12} md={3}>
                  <FilterSelect
                    onChange={reportChange}
                    label="Report Filter:"
                    name="reports"
                    options={reportOptions}
                    selected={currentReport}
                    disable={loading}
                  />
                </Col>
              </Row>
            </CardPanel>
          </Col>
        </Row>
      </Col>
    </Container>
  );
}

export default FilterReportsPage;

