import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import AccountsTable from './components/AccountsTable';

const AccountTable = () => (
  <Container>
    <Row>
      <Col md={2}>
        <h3 className="page-title">Accounts:</h3>
        <h3 className="page-subhead subhead">Current Account List</h3>
      </Col>
      <Col md={3}>
        <p>
          <u>Dev Account ID</u><br />&nbsp;&nbsp;&nbsp; 1 = Americas, 2 = Europe, 3 = Japan, 4 = Australia<br />
          <u>Seller Type</u><br />&nbsp;&nbsp;&nbsp;  1 = Seller, 2 = Vendor, 3 = DSP/Agency<br />
          <br />
        </p>
      </Col>
      <Col md={7}>
        <p>
          <u>Marketplace ID</u><br />&nbsp;&nbsp;&nbsp;
          3 = US,
          4 = CA,
          5 = AU,
          6 = BR,
          7 = CN,
          8 = DE,
          9 = ES,
          10 = FR,
          11 = GB,
          12 = IN,
          13 = IT,
          14 = JP,
          15 = TR,
          16 = MX,
          17 = AE,
          18 = NL,
          19 = EG,
          20 = SA,
          21 = SE,
          22 = SG,
          23 = PL,
          24 = BE<br />
          <u>Agency Codes</u><br />&nbsp;&nbsp;&nbsp; 0 = Limitless, 1 = AdJuggernaut, 2 = Cities Market Studios, 3 = Hawke Media, 4 = CartAgent, 5 = AdRev
          <br />
        </p>
      </Col>
    </Row>
    <Row>
      <AccountsTable />
    </Row>
  </Container>
);

export default AccountTable;
