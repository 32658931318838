

import React, { useState, useEffect } from 'react';
import { Table } from 'reactstrap';
import {
  meteredMonthlySpSdSpend, meteredMarketplaces, flatRate, // meteredProducts, meteredBudgetGroups,
} from '../../config';
import CardPanel from '../../../../../shared/components/widgets/CardPanel';
import {
  formatNumberTwoPlaces, formatDecimalToFixedTwoPercentDirect, formatNumberOnePlace, // currencyFormatter, formatNumberDefaultToZero, formatDecimalToFixedTwoPercent, numberTwoPlaces,
} from '../../../../../shared/components/table/functions';

const PlanEstimator = () => {
  const customerFlatRate = flatRate[0].calcValue;

  const [customerSpend, setCustomerSpend] = useState(1000.00);
  const [customerSpendTiers, setCustomerSpendTiers] = useState([]);
  const [spendCost, setSpendCost] = useState(0);

  // const [customerProducts, setCustomerProducts] = useState(10);
  // const [customerProductTiers, setCustomerProductTiers] = useState([]);
  // const [productCost, setProductCost] = useState(0);

  // const [customerBudgetGroups, setCustomerBudgetGroups] = useState(1);
  // const [customerBudgetGroupTiers, setCustomerBudgetGroupTiers] = useState([]);
  // const [budgetGroupCost, setBudgetGroupCost] = useState(0);

  const [customerMarketplaces, setCustomerMarketplaces] = useState(1);
  const [customerMarketplaceTiers, setCustomerMarketplaceTiers] = useState([]);
  const [marketplaceCost, setMarketplaceCost] = useState(0);

  const [customerPacos, setCustomerPacos] = useState(5);

  const spendChange = (e) => {
    const updatedSpend = e.target.value;
    const calculatedPriceTiers = [];
    let totalSpendCost = 0;
    setCustomerSpend(updatedSpend);
    // Build price tiers
    meteredMonthlySpSdSpend.forEach(spendTier => {
      if (updatedSpend >= spendTier.minSpend) {
        const capSpend = updatedSpend > spendTier.maxSpend ? spendTier.maxSpend : updatedSpend;
        const tierSpend = capSpend - (spendTier.minSpend - 1);
        const tierSpendCost = tierSpend * spendTier.calcValue;
        calculatedPriceTiers.push({ tierName: spendTier.name, tierSpendMultiplier: spendTier.calcValue, tierSpendCost });
        totalSpendCost += tierSpendCost;
      }
    })
    setCustomerSpendTiers(calculatedPriceTiers);
    setSpendCost(totalSpendCost);
  }

  const pacosChange = (e) => {
    setCustomerPacos(e.target.value);
  }

  // const productChange = (e) => {
  //   const updatedProductCount = e.target.value;
  //   const calculatedProductTiers = [];
  //   let totalProductCost = 0;
  //   setCustomerProducts(updatedProductCount);
  //   // Build product tiers
  //   meteredProducts.forEach(productTier => {
  //     if (updatedProductCount >= productTier.minProducts) {
  //       const capProduct = updatedProductCount > productTier.maxProducts ? productTier.maxProducts : updatedProductCount;
  //       const tierProduct = capProduct - (productTier.minProducts - 1);
  //       const tierProductCost = tierProduct * productTier.calcValue;
  //       calculatedProductTiers.push({ tierName: productTier.name, tierProductMultiplier: productTier.calcValue, tierProductCost });
  //       totalProductCost += tierProductCost;
  //     }
  //   })
  //   setCustomerProductTiers(calculatedProductTiers);
  //   setProductCost(totalProductCost);
  // }

  // const budgetGroupChange = (e) => {
  //   const updatedBudgetGroupCount = e.target.value;
  //   const calculatedBudgetGroupTiers = [];
  //   let totalBudgetGroupCost = 0;
  //   setCustomerBudgetGroups(updatedBudgetGroupCount);
  //   // Build BudgetGroup tiers
  //   meteredBudgetGroups.forEach(budgetGroupTier => {
  //     if (updatedBudgetGroupCount >= budgetGroupTier.minBudgetGroups) {
  //       const capBudgetGroup = updatedBudgetGroupCount > budgetGroupTier.maxBudgetGroups ? budgetGroupTier.maxBudgetGroups : updatedBudgetGroupCount;
  //       const tierBudgetGroup = capBudgetGroup - (budgetGroupTier.minBudgetGroups - 1);
  //       const tierBudgetGroupCost = tierBudgetGroup * budgetGroupTier.calcValue;
  //       calculatedBudgetGroupTiers.push({ tierName: budgetGroupTier.name, tierBudgetGroupMultiplier: budgetGroupTier.calcValue, tierBudgetGroupCost });
  //       totalBudgetGroupCost += tierBudgetGroupCost;
  //     }
  //   })
  //   setCustomerBudgetGroupTiers(calculatedBudgetGroupTiers);
  //   setBudgetGroupCost(totalBudgetGroupCost);
  // }

  const marketplaceChange = (e) => {
    const updatedMarketplaceCount = e.target.value;
    const calculatedMarketplaceTiers = [];
    let totalMarketplaceCost = 0;
    setCustomerMarketplaces(updatedMarketplaceCount);
    // Build Marketplace tiers
    meteredMarketplaces.forEach(marketplaceTier => {
      if (updatedMarketplaceCount >= marketplaceTier.minMarketplaces) {
        const capMarketplace = updatedMarketplaceCount > marketplaceTier.maxMarketplaces ? marketplaceTier.maxMarketplaces : updatedMarketplaceCount;
        const tierMarketplace = capMarketplace - (marketplaceTier.minMarketplaces - 1);
        const tierMarketplaceCost = tierMarketplace * marketplaceTier.calcValue;
        calculatedMarketplaceTiers.push({ tierName: marketplaceTier.name, tierMarketplaceMultiplier: marketplaceTier.calcValue, tierMarketplaceCost });
        totalMarketplaceCost += tierMarketplaceCost;
      }
    })
    setCustomerMarketplaceTiers(calculatedMarketplaceTiers);
    setMarketplaceCost(totalMarketplaceCost);
  }

  useEffect(() => {
    spendChange({ target: { value: 1000.00 } });
    pacosChange({ target: { value: 5 } });
    // productChange({ target: { value: 10 } });
    // budgetGroupChange({ target: { value: 1 } });
    marketplaceChange({ target: { value: 1 } });
  }, []);

  const adrevFee = customerFlatRate + spendCost + marketplaceCost // + productCost + budgetGroupCost;
  const estimatedPpcRev = customerPacos > 0 ? customerSpend / (customerPacos / 100) : 0;
  const estimatedNetProfitAferFees = estimatedPpcRev - customerSpend - adrevFee;

  return (
    <CardPanel
      showButtons={false}
    >
      <Table responsive id="summary">
        <tbody>
          <tr>
            <td style={{ textAlign: 'center', fontSize: '24px' }}>AdRev PPC Sales</td>
            <td style={{ textAlign: 'center', fontSize: '24px' }}>Net Profit</td>
            <td style={{ textAlign: 'center', fontSize: '24px' }}>User Time Spent</td>
          </tr>
          <tr>
            <td style={{ textAlign: 'center', fontSize: '24px' }}>${formatNumberTwoPlaces(estimatedPpcRev)}</td>
            <td style={{ textAlign: 'center', fontSize: '24px' }}>${formatNumberTwoPlaces(estimatedNetProfitAferFees)}</td>
            <td style={{ textAlign: 'center', fontSize: '24px' }}>0 Minutes</td>
          </tr>
          <tr>
            <td colSpan="3">&nbsp;</td>
          </tr>
          <tr>
            <td style={{ textAlign: 'center', fontSize: '24px', width: '33%' }}>AdRev Fee</td>
            <td style={{ textAlign: 'center', fontSize: '24px' }}>PPC Spend</td>
            <td style={{ textAlign: 'center', fontSize: '24px' }}>Total PPC Spend</td>
          </tr>
          <tr>
            <td style={{ textAlign: 'center', fontSize: '24px', width: '33%' }}>${formatNumberTwoPlaces(adrevFee)}</td>
            <td style={{ textAlign: 'center', fontSize: '24px' }}>${formatNumberTwoPlaces(customerSpend)}</td>
            <td style={{ textAlign: 'center', fontSize: '24px' }}>${formatNumberTwoPlaces(adrevFee + customerSpend)}</td>
          </tr>
          <tr>
            <td colSpan="3">&nbsp;</td>
          </tr>
          <tr>
            <td style={{ textAlign: 'center', fontSize: '24px' }}>PPC ROAS</td>
            <td style={{ textAlign: 'center', fontSize: '24px' }}>PPC ACoS</td>
            <td style={{ textAlign: 'center', fontSize: '24px' }}># of Markets</td>

          </tr>
          <tr>
            <td style={{ textAlign: 'center', fontSize: '24px' }}>{formatNumberOnePlace((1 / customerPacos) * 100)}</td>
            <td style={{ textAlign: 'center', fontSize: '24px' }}>{customerPacos}%</td>
            <td style={{ textAlign: 'center', fontSize: '24px' }}>{customerMarketplaces}</td>
          </tr>
        </tbody>
      </Table>
      <br />
      <br />
      <br />
      <br />
      <br />
      <h3>Estimate Inputs</h3>
      <Table responsive id="estimateInput">
        <tbody>
          <tr>
            <td>Expected Spend in USD:</td>
            <td>Expected PACoS:</td>
            {/* <td>Expected Products:</td>
            <td>Expected Budget Groups:</td> */}
            <td>Expected Marketplaces:</td>
          </tr>
          <tr>
            <td><input type="text" id="customerSpendEstimate" className="input_round" style={{ width: '100%' }} value={customerSpend} onChange={spendChange} /></td>
            <td><input type="text" id="customerPacosEstimate" className="input_round" style={{ width: '100%' }} value={customerPacos} onChange={pacosChange} /></td>
            {/* <td><input type="text" id="customerProductEstimate" className="input_round" style={{ width: '100%' }} value={customerProducts} onChange={productChange} /></td>
            <td><input type="text" id="customerBudgetGroupEstimate" className="input_round" style={{ width: '100%' }} value={customerBudgetGroups} onChange={budgetGroupChange} /></td> */}
            <td><input type="text" id="customerMarketplaceEstimate" className="input_round" style={{ width: '100%' }} value={customerMarketplaces} onChange={marketplaceChange} /></td>
          </tr>
        </tbody>
      </Table>
      <br />
      <br />
      <h3>Estimated Performance</h3>
      <Table responsive striped className="dashboard__table-orders" id="estimtedPerformance">
        <tbody>
          <tr>
            <td>Target PACoS</td><td>{customerPacos}%</td><td>&nbsp;</td>
          </tr>
          <tr>
            <td>Estimated PPC Revenue<br /><sub>[(PPC AD Spend)/(Target ACoS)]</sub></td><td>${formatNumberTwoPlaces(estimatedPpcRev)}</td><td>&nbsp;</td>
          </tr>
          <tr>
            <td>Less PPC AD Spend</td><td>$({formatNumberTwoPlaces(customerSpend)})</td><td>{formatDecimalToFixedTwoPercentDirect(estimatedPpcRev > 0 ? customerSpend / estimatedPpcRev : 0)}</td>
          </tr>
          <tr>
            <td>Less AdRev Fee</td><td>$({formatNumberTwoPlaces(adrevFee)})</td><td>{formatDecimalToFixedTwoPercentDirect(estimatedPpcRev > 0 ? adrevFee / estimatedPpcRev : 0)}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th style={{ borderTop: 'thin solid' }}>Net Profit after Fees</th><th style={{ borderTop: 'thin solid' }}>${formatNumberTwoPlaces(estimatedNetProfitAferFees)}</th><th style={{ borderTop: 'thin solid' }}>{formatDecimalToFixedTwoPercentDirect(estimatedPpcRev > 0 ? estimatedNetProfitAferFees / estimatedPpcRev : 0)}</th>
          </tr>
        </tfoot>
      </Table>
      <br />
      <br />
      <h3>Estimated Calculations (auto updates with input above)</h3>
      <Table responsive striped className="dashboard__table-orders" id="estimateLineItems">
        <thead>
          <tr>
            <th>Item</th>
            <th>Calculation</th>
            <th>Estimated Amount</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Base Price</td>
            <td>Flat Fee of ${customerFlatRate} USD a month</td>
            <td>${customerFlatRate} USD</td>
            <td>Cost will be billed at the begining of the cycle and will cover a months worth of service</td>
          </tr>
          <tr>
            <td>% of PPC Spend</td>
            <td>
              {customerSpendTiers.map((tier) => (
                <div key={tier.tierName}>{tier.tierName} @ {tier.tierSpendMultiplier} each for ${formatNumberTwoPlaces(tier.tierSpendCost)} USD</div>
              ))}
            </td>
            <td>${formatNumberTwoPlaces(spendCost)} USD</td>
            <td>Cost is calculated based on last months spend and is billed at the end of the cycle</td>
          </tr>
          {/* <tr>
            <td># of Products</td>
            <td>
              {customerProductTiers.map((tier) => (
                <div key={tier.tierName}>{tier.tierName} @ {tier.tierProductMultiplier} each for ${formatNumberTwoPlaces(tier.tierProductCost)} USD</div>
              ))}
            </td>
            <td>${formatNumberTwoPlaces(productCost)} USD</td>
            <td>Cost is calculated based on the number of products that have ever been in a budget group</td>
          </tr>
          <tr>
            <td># of Budget Groups</td>
            <td>
              {customerBudgetGroupTiers.map((tier) => (
                <div key={tier.tierName}>{tier.tierName} @ {tier.tierBudgetGroupMultiplier} each for ${formatNumberTwoPlaces(tier.tierBudgetGroupCost)} USD</div>
              ))}
            </td>
            <td>${formatNumberTwoPlaces(budgetGroupCost)} USD</td>
            <td>Budget Groups are buckets that you can assign products and spend allowing you to control your ADs with our fully managed solution</td>
          </tr> */}
          <tr>
            <td># of Marketplaces</td>
            <td>
              {customerMarketplaceTiers.map((tier) => (
                <div key={tier.tierName}>{tier.tierName} @ {tier.tierMarketplaceMultiplier} each for ${formatNumberTwoPlaces(tier.tierMarketplaceCost)} USD</div>
              ))}
            </td>
            <td>${formatNumberTwoPlaces(marketplaceCost)} USD</td>
            <td>Number of marketplaces you onboard and link</td>
          </tr>
        </tbody>
      </Table>
    </CardPanel>
  )
};

export default PlanEstimator;
