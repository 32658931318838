import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from 'reactstrap';
import { Field } from 'redux-form';
import Panel from '../../../../../shared/components/Panel';
import renderSelectField from '../../../../../shared/components/form/Select';
import {
  OptionShape,
  typeOptions,
  statusOptions,
  editRoleOptions,
  viewRoleOptions,
} from './utils';

export default function AddSettingPanel({
  initialLoad, getData, dynamicValueField, groups, onCreate, onReset,
}) {
  return (
    <Panel lg={12} title="Add New Setting" getData={getData} parentRefresh={initialLoad}>
      <Table responsive className="table--bordered">
        <thead>
          <tr>
            <th>#</th>
            <th>Key</th>
            <th>Value</th>
            <th>Type</th>
            <th>Status</th>
            <th>Edit Role</th>
            <th>View Role</th>
            <th>Group</th>
            <th>Update</th>
            <th>Create</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>NEW</td>
            <td>
              <span className="form">
                <Field
                  name="newKey"
                  component="input"
                  className="form"
                />
              </span>
            </td>
            <td>
              <span className="form">
                {dynamicValueField('newValue', 'new')}
              </span>
            </td>
            <td>
              <Field
                name="newType"
                component={renderSelectField}
                type="text"
                options={typeOptions}
              />
            </td>
            <td>
              <Field
                name="newStatus"
                component={renderSelectField}
                type="text"
                options={statusOptions}
              />
            </td>
            <td>
              <Field
                name="newEditRole"
                component={renderSelectField}
                type="text"
                options={editRoleOptions}
              />
            </td>
            <td>
              <Field
                name="newViewRole"
                component={renderSelectField}
                type="text"
                options={viewRoleOptions}
              />
            </td>
            <td>
              <Field
                name="newGroup"
                component={renderSelectField}
                type="text"
                options={groups}
              />
            </td>
            <td>auto</td>
            <td>auto</td>
            <td>
              <button type="button" className="badge badge-success badge-btn" onClick={() => onCreate(0)}>Create</button>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <button type="button" className="badge badge-warning badge-btn" onClick={() => onReset(0)}>Reset</button>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
        </tbody>
      </Table>
    </Panel>
  );
}

AddSettingPanel.propTypes = {
  initialLoad: PropTypes.bool.isRequired,
  getData: PropTypes.func.isRequired,
  dynamicValueField: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(PropTypes.shape(OptionShape)).isRequired,
  onCreate: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};
