import React, { useState, useEffect, Fragment } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { Theme, User } from '../../prop-types/MainProps';
import config from '../../../config';
import { history } from '../../../helpers';
import style, { DROPDOWN_COLORS } from '../themeSupport';

export const DEFAULT_ALL_FILTER_VALUE = { label: 'ALL', value: '', sortName: '! !ALL' };

const FilterSelect = ({
  handleSelectChange, user, theme, label, name, filterUrl, filterFields, options, selectedOption, disable,
}) => {
  const themeColors = style(theme, DROPDOWN_COLORS);
  const [selected, setSelected] = useState(selectedOption);
  const [displayOptions, setDisplayOptions] = useState(options);

  useEffect(() => {
    async function loadFilterData(url) {
      if (url !== '') {
        const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

        const requestOptions = {
          method: 'GET',
          headers: headerWithAuth,
        };

        await fetch(url, requestOptions)
          .then((response) => {
            if (response.status === 401) {
              history.push('/logout');
            }
            return response.json();
          })
          .then((data) => {
            // We had 000ALL for sorting, but  we found a case where client had data of just '0' -- so now we use '!' to ensure our ALL value sorts first.
            const filterMap = new Map([['! !ALL', DEFAULT_ALL_FILTER_VALUE]]);
            data.forEach((row) => {
              if (row[filterFields.id]) {
                filterMap.set(row[filterFields.id], { label: row[filterFields.name], value: row[filterFields.id], sortName: row[filterFields.name] });
              }
            });
            const filterOptions = Array.from(filterMap.values()).sort((a, b) => (a.sortName > b.sortName ? 1 : -1));
            setDisplayOptions(filterOptions);
            setSelected(filterOptions[0]);
          })
          .catch((err) => {
            console.error(`Error fetching data for filter: ${err}`);
            // If we got an error, just show the default ALL
            const filterMap = new Map([['! !ALL', DEFAULT_ALL_FILTER_VALUE]]);
            const filterOptions = Array.from(filterMap.values()).sort((a, b) => (a.sortName > b.sortName ? 1 : -1));
            setDisplayOptions(filterOptions);
            setSelected(filterOptions[0]);
          });
      }
    }
    loadFilterData(filterUrl);
  }, [filterUrl]);

  useEffect(() => {
    if (selected) {
      handleSelectChange(selected, { name });
    }
  }, [selected]);

  function handleChange(e) {
    setSelected(e);
  }

  return (
    <Fragment>
      <p>{label}</p>
      <div className="topbar__dynamicDropdownWidth">
        <Select
          value={selected}
          name={name}
          onChange={handleChange}
          options={displayOptions}
          theme={selectTheme => ({
            ...selectTheme,
            colors: {
              ...selectTheme.colors,
              primary: `${themeColors.colorPrimary}`,
              neutral0: `${themeColors.colorBackground}`,
              neutral80: `${themeColors.colorText}`,
            },
          })}
          isDisabled={disable}
        />
      </div>
    </Fragment>
  );
};

FilterSelect.defaultProps = {
  filterUrl: '',
  filterFields: {},
  options: [],
  selectedOption: {},
  disable: false,
};

const optionShape = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  sortName: PropTypes.string,
};

const filterStructure = {
  id: PropTypes.string,
  name: PropTypes.string,
};

FilterSelect.propTypes = {
  handleSelectChange: PropTypes.func.isRequired,
  user: User.isRequired,
  theme: Theme.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  filterUrl: PropTypes.string,
  filterFields: PropTypes.shape(filterStructure),
  options: PropTypes.arrayOf(PropTypes.shape(optionShape)),
  selectedOption: PropTypes.shape(optionShape),
  disable: PropTypes.bool,
};

export default FilterSelect;
