import React, { PureComponent } from 'react';
import NotificationSystem from 'rc-notification';
import moment from 'moment';
import { BasicNotification } from '../../../../shared/components/Notification';
import config from '../../../../config';
import TableBuilder from '../../../../shared/components/table/TableBuilder';
import {
  currencyFormatter, toDisplayDate, formatDecimalToFixedTwoPercent, formatNumberDefaultToZero, getExportPrefix,
} from '../../../../shared/components/table/functions';


const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

function numberValueParser(params) {
  return Number(params.newValue);
}

let notificationLU = null;
let notificationRU = null;
let notificationTC = null;

class LaunchSettingsTable extends PureComponent {
  constructor(props) {
    super(props);

    this.parentProcessChange = this.parentProcessChange.bind(this);

    this.state = {
      columnDefs: [
        {
          headerName: 'Product Details',
          children: [
            { field: 'launch_id', headerName: 'Launch ID' },
            { field: 'product_name', headerName: 'Product Name' },
            { field: 'type', headerName: 'Type' },
            { field: 'parent_sku', headerName: 'Parent SKU' },
            { field: 'child_sku', headerName: 'Child SKU' },
            { field: 'upc', headerName: 'UPC' },
            { field: 'category', headerName: 'Category' },
            { field: 'sub_category', headerName: 'Sub Category' },
            { field: 'brand', headerName: 'Brand' },
            // { field: 'new_test_sku', headerName: 'New Test SKU' },
            // { field: 'new_sku', headerName: 'New SKU' },
            // { field: 'new_variation_sku', headerName: 'New Variation SKU' },
            // { field: 'existing_sku', headerName: 'Existing SKU' },
            // { field: 'bundle_product', headerName: 'Bundle Product' },
            {
              field: 'listing_live_date',
              headerName: 'Listing Live Date',
              valueFormatter: toDisplayDate,
            },
            {
              field: 'shipment_date',
              headerName: 'Shipment Date',
              valueFormatter: toDisplayDate,
            },
            {
              field: 'estimated_launch_date',
              headerName: 'Estimated Launch Date',
              valueFormatter: toDisplayDate,
            },
            // { field: 'segment_1', headerName: 'Segment 1' },
            // { field: 'segment_2', headerName: 'Segment 2' },
            // { field: 'segment_3', headerName: 'Segment 3' },
            { field: 'other_promotions', headerName: 'Other Promotions' },
            { field: 'coupon', headerName: 'Coupon' },
          ],
        },
        {
          headerName: 'Phase 1',
          children: [
            {
              field: 'p1_budget_cap',
              headerName: 'Budget Cap',
              editable: true,
              valueFormatter: currencyFormatter,
            },
            {
              field: 'p1_no_of_units',
              headerName: 'No. of Units',
              valueFormatter: formatNumberDefaultToZero,
              valueParser: numberValueParser,
            },
            {
              field: 'p1_days',
              headerName: 'Days',
              valueFormatter: formatNumberDefaultToZero,
              valueParser: numberValueParser,
            },
            {
              field: 'p1_bacos',
              headerName: 'BACOS',
              valueFormatter: formatDecimalToFixedTwoPercent,
            },
          ],
        },
        {
          headerName: 'Phase 2',
          children: [
            {
              field: 'p2_budget_cap',
              headerName: 'Budget Cap',
              valueFormatter: currencyFormatter,
            },
            {
              field: 'p2_no_of_units',
              headerName: 'No. of Units',
              valueFormatter: formatNumberDefaultToZero,
              valueParser: numberValueParser,
            },
            {
              field: 'p2_days',
              headerName: 'Days',
              valueFormatter: formatNumberDefaultToZero,
              valueParser: numberValueParser,
            },
            {
              field: 'p2_bacos',
              headerName: 'BACOS',
              valueFormatter: formatDecimalToFixedTwoPercent,
            },
          ],
        },
        {
          headerName: 'Phase 3',
          children: [
            {
              field: 'p3_budget_cap',
              headerName: 'Budget Cap',
              valueFormatter: currencyFormatter,
            },
            {
              field: 'p3_no_of_units',
              headerName: 'No. of Units',
              valueFormatter: formatNumberDefaultToZero,
              valueParser: numberValueParser,
            },
            {
              field: 'p3_days',
              headerName: 'Days',
              valueFormatter: formatNumberDefaultToZero,
              valueParser: numberValueParser,
            },
            {
              field: 'p3_bacos',
              headerName: 'BACOS',
              valueFormatter: formatDecimalToFixedTwoPercent,
            },
          ],
        },
      ],
      defaultColDef: {
        editable: true,
        sortable: true,
        filter: true,
        resizable: true,
        cellStyle: (params) => {
          if (params.value < 0.00) {
            return { color: 'red' };
          }
          return null;
        },
      },
      showDatePicker: false,
      showImportButton: true,
      showFooter: false,
      showAddRowButton: true,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line
    NotificationSystem.newInstance({}, n => notificationLU = n);
    // eslint-disable-next-line
    NotificationSystem.newInstance({}, n => notificationRU = n);
    // eslint-disable-next-line
    NotificationSystem.newInstance({}, n => notificationTC = n);
  }

  componentWillUnmount() {
    if (notificationLU) {
      notificationLU.destroy();
    }
    if (notificationLU) {
      notificationRU.destroy();
    }
    if (notificationLU) {
      notificationTC.destroy();
    }
  }

  // eslint-disable-next-line
  show = (color, title, msg) => {
    return config.showNotification({
      notification: <BasicNotification
        color={color}
        title={title}
        message={msg}
      />,
      position: 'right-up',
      notificationLU,
      notificationRU,
      notificationTC,
    });
  };

  getNewRow = () => {
    const newData = {
      launch_id: 'PL_New',
      product_name: '',
      type: '',
      parent_sku: '',
      child_sku: '',
      upc: '',
      category: '',
      sub_category: '',
      new_test_sku: '',
      new_sku: '',
      new_variation_sku: '',
      existing_sku: '',
      bundle_product: '',
      listing_live_date: moment.utc().startOf('day').format('YYYY-MM-DD'),
      shipment_date: moment.utc().startOf('day').format('YYYY-MM-DD'),
      estimated_launch_date: moment.utc().startOf('day').format('YYYY-MM-DD'),
      brand: '',
      segment_1: '',
      segment_2: '',
      segment_3: '',
      other_promotions: '',
      coupon: '',
      p1_budget_cap: '',
      p1_no_of_units: '',
      p1_days: '',
      p1_bacos: '',
      p2_budget_cap: '',
      p2_no_of_units: '',
      p2_days: '',
      p2_bacos: '',
      p3_budget_cap: '',
      p3_no_of_units: '',
      p3_days: '',
      p3_bacos: '',
    };
    return newData;
  }

  parentProcessChange = (rowNode, user, sellerSelect, apiEndPoint) => {
    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
    const requestOptions = {
      method: 'POST',
      headers: headerWithAuth,
      body: JSON.stringify({
        sku: rowNode.data.sku,
        sellerAccountId: sellerSelect.value.toString(),
      }),
    };
    // Call via API (would like to update to " await ")
    fetch(apiEndPoint, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        // success message
        // eslint-disable-next-line
        this.show('primary', 'Success', `${data[0].launch_id} Added.`);
      }).catch(() => {
        this.show('danger', 'Error', `${rowNode.data.launch_id} was not saved.`);
      });
  }

  render() {
    const {
      columnDefs, defaultColDef, onCellEditingStopped, showDatePicker, showImportButton, showFooter, showAddRowButton,
    } = this.state;

    const apiEndPoint = `${apiUrl}/events/launch/settings`;
    const { sellerSelect } = this.props;
    const exportPrefix = getExportPrefix('launch-settings', sellerSelect);

    return (
      <TableBuilder
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        apiEndPoint={apiEndPoint}
        onCellEditingStopped={onCellEditingStopped}
        showDatePicker={showDatePicker}
        showImportButton={showImportButton}
        showFooter={showFooter}
        parentProcessChange={this.parentProcessChange}
        showAddRowButton={showAddRowButton}
        getNewRow={this.getNewRow}
        importMessage="Import a file like the one exported with all the fields below."
        exportPrefix={exportPrefix}
        confirmLogic
      />
    );
  }
}

export default LaunchSettingsTable;
