import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import useFetch from '../../../../shared/components/hooks/useFetch';
import config from '../../../../configWrapper';
import style, { DROPDOWN_COLORS } from '../../../../shared/components/themeSupport';

function AccountSelect({
  accounts, selected, label, onChange,
}) {
  const theme = useSelector(store => store.theme);
  const themeColors = style(theme, DROPDOWN_COLORS);

  return (
    <>
      <p>{ label }</p>
      <div className="topbar__dynamicDropdownWidth">
        <Select
          value={selected}
          onChange={choice => onChange(choice)}
          options={accounts}
          theme={selectTheme => ({
            ...selectTheme,
            colors: {
              ...selectTheme.colors,
              primary: `${themeColors.colorPrimary}`,
              neutral0: `${themeColors.colorBackground}`,
              neutral80: `${themeColors.colorText}`,
            },
          })}
        />
      </div>
    </>
  );
}

AccountSelect.propTypes = {
  accounts: PropTypes.instanceOf(Array).isRequired,
  selected: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function AccountSelectProvider({
  label, accountSelect, onChange,
}) {
  const [accounts, setAccounts] = useState([]);
  const selectedSeller = useSelector(store => store.sellerSelect);
  const { results, error } = useFetch(`${config.serverUrl}/accounts/active-by-name`);

  useEffect(() => {
    if (error) {
      console.error(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (results && results.length > 0) {
      const accountOptions = [];
      const accountsJson = results;

      if (Array.isArray(accountsJson)) {
        accountsJson.forEach((account) => {
          if (account.customer_id === selectedSeller.customerId) {
            accountOptions.push({
              value: account.seller_account_id,
              label: account.seller_account_name,
              type: account.seller_account_type_id,
              currencySymbol: account.marketplace_currency_symbol,
              currencyCode: account.marketplace_currency_code,
              parentAccountId: account.parent_account_id,
              mpName: account.marketplace_name,
              agency: account.agency,
              platform: account.platform,
              subscriptionId: account.subscription_id,
              plan: account.plan,
              planStatus: account.plan_status,
              externalCustomerId: account.external_customer_id,
              externalAccountId: account.external_account_id,
              customerId: account.customer_id,
            });
          }
        });
      }
      setAccounts(accountOptions);
    }
  }, [results, selectedSeller, setAccounts]);

  return (
    <AccountSelect
      label={label}
      onChange={onChange}
      accounts={accounts}
      selected={accountSelect}
    />
  );
}

AccountSelectProvider.defaultProps = {
  label: '',
};

AccountSelectProvider.propTypes = {
  onChange: PropTypes.func.isRequired,
  accountSelect: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string,
};
