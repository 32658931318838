import {
  formatNumberTwoPlaces, formatDecimalToFixedTwoPercent,
} from './components/table/functions';

export function when(expression = false, callback = () => {}, otherwise = () => {}) {
  if (expression) {
    return callback();
  }
  return otherwise();
}

export function withArray(arr = [], callback = () => [], orElse = () => undefined) {
  return when((Array.isArray(arr) && arr.length > 0), callback, orElse);
}

export function currencyFormatter({ value, account }) {
  const useCurrencySymbol = (account.currencySymbol || '$');
  if (value < 0) {
    return `-${useCurrencySymbol}${formatNumberTwoPlaces(Math.abs(value))}`;
  }
  return `${useCurrencySymbol}${formatNumberTwoPlaces(value || 0)}`;
}

export function percentFormatter(value) {
  if (value > 0) {
    return formatDecimalToFixedTwoPercent({ value });
  }
  return '0%';
}

export function arrayToMap(array, mapKeyField) {
  const theMap = withArray(array, () => {
    const map = new Map();
    array.forEach(item => map.set(item[mapKeyField], item));
    return map;
  }, () => new Map());

  return theMap;
}
