import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import StoreOutlineIcon from 'mdi-react/StoreOutlineIcon';
import StoreIcon from 'mdi-react/StoreIcon';
import FormDropdownIcon from 'mdi-react/FormDropdownIcon';
import IdentifierIcon from 'mdi-react/IdentifierIcon';
import CheckCircleOutlineIcon from 'mdi-react/CheckCircleOutlineIcon';
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';
import Recaptcha from 'reaptcha';
import { Buffer } from 'buffer';
import { Input } from 'reactstrap';
import Alert from '../../../../shared/components/Alert';
import { serverUrl, jsonHeader, isProdEnv } from '../../../../config';
import { platform as PLATFORM } from '../../../../shared/components/domainSupport';
import LoadingSpinner from '../../../../shared/components/widgets/LoadingSpinner';
import { history } from '../../../../helpers';

const AuthorizeAdForm = () => {
  const selectedAccount = useSelector(store => store.sellerSelect);
  const { loggedIn, user } = useSelector(store => store.authentication);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recaptchaResponse, setRecaptchaResponse] = useState('');
  const [recaptchaIsLoaded, setRecaptchaIsLoaded] = useState(false);
  const [platform, setPlatform] = useState(PLATFORM);
  const [accountId, setAccountId] = useState(selectedAccount.value);
  const [localAlert, setLocalAlert] = useState({});
  const [advertiserId, setAdvertiserId] = useState('');
  const [advertiserName, setAdvertiserName] = useState('');
  const [advertiserType, setAdvertiserType] = useState('');
  const [sellerId, setSellerId] = useState('');
  const [sellerName, setSellerName] = useState('');
  const [onboardSuccess, setOnboardSuccsss] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const adApiParams = urlParams.get('edata');
  // const adminParam = urlParams.get('admin');
  const isTestParam = urlParams.get('isTest')?.toLowerCase();


  if (loggedIn && window.location.pathname.startsWith('/onboarding/walmart/ad-api')) {
    history.push('/customer/onboarding/walmart/ad-api');
  } else if (adApiParams) {
    try {
      const adApiParamsDecoded = decodeURIComponent(adApiParams);
      const adApiParamsString = Buffer.from(adApiParamsDecoded, 'base64').toString('utf-8');
      const adApiParamsJson = JSON.parse(adApiParamsString);
      useEffect(() => {
        setAccountId(adApiParamsJson.accountId);
        setPlatform(adApiParamsJson.platform);
      }, []);
    } catch (err) {
      return (
        <div className="form__form-group">
          <Alert className="INFO">You are required to be logged in or have a starting link to use the Walmart Advertising API authorization process.</Alert>
          <br />
          Error Reference: Bad Link ({err})
        </div>
      );
    }
  } else if (!loggedIn) {
    return (
      <div className="form__form-group">
        <div style={{ textAlign: 'center', width: '100%' }}><CloseCircleOutlineIcon className="dashboard__x-icon" /></div>
        <br />
        <h3> You are required to be logged in or have a starting link to use the Walmart Advertising API onboarding process.</h3>
      </div>
    );
  }

  // Run on load and see if Advertising API has already been configured and/or if it is ready to be confiugred.  Requires seller id from AD onboard.
  useEffect(() => {
    setLocalAlert({});
    setAdvertiserId('');
    setAdvertiserName('');
    setAdvertiserType('');
    setSellerId('');
    setSellerName('');
    setLoading(true);
    setOnboardSuccsss(false);
    if (platform === 'adjuggernaut') {
      setPlatform('limitless'); // Temp workaround until all the APIs are deployed to be multi-platform.  AdRev is not live, so it's OK to onboard as-is.
    }

    const headerWithAuth = { ...jsonHeader, authorization: `JWT ${user?.jwtToken}` };
    // Add logic to check input values, make sure they are present and correct.
    const onboardOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    const onboardUrl = `${serverUrl}/walmart/onboard/ad-api?accountId=${selectedAccount.value || accountId}`;
    fetch(onboardUrl, onboardOptions)
      .then(async (results) => {
        if (!results.ok) {
          const text = await results.text();
          throw new Error(text);
        }
        return results.json();
      }).then((data) => {
        if (!isProdEnv) {
          console.info(data);
        }
        if (data.walmartAdvertiserId && data.walmartAdvertiserId !== '') {
          setOnboardSuccsss(true);
        }
        setLoading(false);
      }).catch((error) => {
        try {
          const serverMsg = JSON.parse(JSON.parse(error.message).error).error;
          setLocalAlert({ color: 'danger', message: serverMsg });
        } catch {
          setLocalAlert({ color: 'danger', message: 'Unbale to check for exsiting configuration.' });
        }
        setLoading(false);
      });
  }, [selectedAccount.value, accountId]);

  const submitWalmartOnboardAdApiForm = () => {
    setSubmitted(true);
    setLocalAlert({});
    if (platform === 'adjuggernaut') {
      setPlatform('limitless'); // Temp workaround until all the APIs are deployed to be multi-platform.  AdRev is not live, so it's OK to onboard as-is.
    }
    if (!recaptchaResponse && !loggedIn) {
      setLocalAlert({ color: 'warning', message: 'Please fix the input issues below.' });
      setSubmitted(false);
    } else {
      const headerWithAuth = { ...jsonHeader, authorization: `JWT ${user?.jwtToken}` };
      // Add logic to check input values, make sure they are present and correct.
      const onboardOptions = {
        method: 'POST',
        headers: headerWithAuth,
        body: JSON.stringify({
          accountId,
          advertiserId,
          advertiserName,
          advertiserType,
          sellerId,
          sellerName,
          platform,
          recaptcha: recaptchaResponse,
        }),
      };

      const onboardUrl = `${serverUrl}/walmart/onboard/ad-api`;
      fetch(onboardUrl, onboardOptions)
        .then(async (results) => {
          if (!results.ok) {
            const text = await results.text();
            throw new Error(text);
          }
          return results.json();
        }).then((data) => {
          if (!isProdEnv) {
            console.info(data);
          }
          setOnboardSuccsss(true);
          setSubmitted(false);
        }).catch((error) => {
          try {
            const serverMsg = JSON.parse(JSON.parse(error.message).error).error;
            setLocalAlert({ color: 'danger', message: serverMsg });
          } catch {
            console.error('unable to parse');
            setLocalAlert({ color: 'danger', message: 'We are having issues processing your request, please check your data and try again.' });
          }
          setSubmitted(false);
        });
    }
  }

  return (
    <>
      {!onboardSuccess && (
        <>
          <div className="form__form-group">
            {localAlert && localAlert.message && (
              <>
                <Alert color={localAlert.color}>{localAlert.message}</Alert>
              </>
            )}
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Advertiser ID</span>
            <div className="form__form-group-field">
              <div className="form__input-group-icon">
                <IdentifierIcon />
              </div>
              <Input
                autoFocus
                name="advertiserId"
                component="input"
                type="text"
                value={advertiserId}
                onChange={(e) => setAdvertiserId(e.target.value)}
              />
            </div>
            {localAlert.message && (!advertiserId || advertiserId === '') && (
              <div className="form__form-group-error">Advertiser ID is required</div>
            )}
          </div>
          <br />
          <div className="form__form-group">
            <span className="form__form-group-label">Advertiser Name</span>
            <div className="form__form-group-field">
              <div className="form__input-group-icon">
                <StoreIcon />
              </div>
              <Input
                autoFocus
                name="advertiserName"
                component="input"
                type="text"
                value={advertiserName}
                onChange={(e) => setAdvertiserName(e.target.value)}
              />
            </div>
            {localAlert.message && (!advertiserName || advertiserName === '') && (
              <div className="form__form-group-error">Advertiser Name is required</div>
            )}
          </div>
          <br />
          <div className="form__form-group">
            <span className="form__form-group-label">Advertiser Type (1p/3p)</span>
            <div className="form__form-group-field">
              <div className="form__input-group-icon">
                <FormDropdownIcon />
              </div>
              <Input
                autoFocus
                name="advertiserType"
                component="input"
                type="select"
                value={advertiserType}
                onChange={(e) => setAdvertiserType(e.target.value)}
              >
                <option value="">Please select...</option>
                <option value="1p">1p - Supplier (Vendor)</option>
                <option value="3p">3p - Marketplace (Seller)</option>
              </Input>
            </div>
            {localAlert.message && (!advertiserType || advertiserType === '') && (
              <div className="form__form-group-error">Advertiser Type is required</div>
            )}
          </div>
          <br />
          {advertiserType === '3p' && (
            <>
              <div className="form__form-group">
                <span className="form__form-group-label">Seller ID</span>
                <div className="form__form-group-field">
                  <div className="form__input-group-icon">
                    <IdentifierIcon />
                  </div>
                  <Input
                    autoFocus
                    name="sellerId"
                    component="input"
                    type="text"
                    value={sellerId}
                    onChange={(e) => setSellerId(e.target.value)}
                  />
                </div>
                {localAlert.message && (!sellerId || sellerId === '') && advertiserType === '3p' && (
                  <div className="form__form-group-error">Seller ID is required for Marketplace Advertisers</div>
                )}
              </div>
              <br />
              <div className="form__form-group">
                <span className="form__form-group-label">Seller Name</span>
                <div className="form__form-group-field">
                  <div className="form__input-group-icon">
                    <StoreOutlineIcon />
                  </div>
                  <Input
                    autoFocus
                    name="sellerName"
                    component="input"
                    type="text"
                    value={sellerName}
                    onChange={(e) => setSellerName(e.target.value)}
                  />
                </div>
                {localAlert.message && (!sellerName || sellerName === '') && advertiserType === '3p' && (
                  <div className="form__form-group-error">Seller Name is required for Marketplace Advertisers</div>
                )}
              </div>
              <br />
            </>
          )}
          {!loggedIn && (
            <div className="form__form-group">
              <Recaptcha sitekey="6LdYQuAbAAAAAD-jRnBlWEzdtu7poJfrLQ72h_mZ" onVerify={(response) => setRecaptchaResponse(response)} onload={() => setRecaptchaIsLoaded(true)} />
            </div>
          )}
          <div style={{ textAlign: 'center', paddingBottom: '20px' }}>
            <LoadingSpinner
              active={submitted}
            />
          </div>
          {isTestParam === 'true' && (
            <div className="form__form-group">
              <p>************************************************************ YOU ARE IN TEST MODE ************************************************************</p>
            </div>
          )}
          {!submitted && !loading && (
            <div className="form__form-group">
              <button className="btn btn-primary account__btn" type="submit" disabled={recaptchaIsLoaded} onClick={() => submitWalmartOnboardAdApiForm()}>Authorize</button>
            </div>
          )}
        </>
      )}
      {loading && (
        <div style={{ textAlign: 'center', paddingBottom: '20px' }}>
          <LoadingSpinner active={loading} />
        </div>
      )}
      {onboardSuccess && (
        <div className="form__form-group">
          <div style={{ textAlign: 'center', width: '100%' }}><CheckCircleOutlineIcon className="dashboard__check-icon" /></div>
          <br />
          <h3> Congratulations, your Advertising credentials have been confirmed and assigned to your account.</h3>
        </div>
      )}
    </>
  );
}

export default AuthorizeAdForm;
