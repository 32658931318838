import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import config from '../../../../config';
import TableBuilder from '../../../../shared/components/table/TableBuilder';
import {
  formatDecimalToFixedTwoPercent, formatNumberDefaultToDash, getExportPrefix,
} from '../../../../shared/components/table/functions';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class JtsrSearchTermRank extends PureComponent {
  static propTypes = {
    setPageData: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        {
          field: 'rstp',
          headerName: 'Search Term Phrases',
        },
        {
          headerName: 'First Date',
          children: [
            {
              headerName: 'Search Volume',
              valueGetter: (params) => {
                if (params.data.dateData[1]) {
                  return params.data.dateData[1].rstp_search_volume;
                }
                return '-';
              },
              aggFunc: 'sum',
            },
            {
              headerName: 'Rank',
              valueGetter: (params) => {
                if (params.data.dateData[1]) {
                  return params.data.dateData[1].rstp_serp_organic_rank;
                }
                return '-';
              },
              valueFormatter: formatNumberDefaultToDash,
              aggFunc: 'avg',
            },
          ],
        },
        {
          headerName: 'Second Date',
          children: [
            {
              headerName: 'Search Volume',
              valueGetter: params => params.data.dateData[0].rstp_search_volume,
              aggFunc: 'sum',
            },
            {
              headerName: 'Rank',
              valueGetter: params => params.data.dateData[0].rstp_serp_organic_rank,
              valueFormatter: formatNumberDefaultToDash,
              aggFunc: 'avg',
            },
          ],
        },
        {
          headerName: 'Delta',
          children: [
            {
              field: 'calcData.rstp_serp_organic_delta',
              headerName: 'Rank Change Diff',
              aggFunc: 'sum',
            },
            {
              field: 'calcData.rstp_serp_organic_percent',
              headerName: '% Rank Change',
              valueFormatter: formatDecimalToFixedTwoPercent,
            },
          ],
        },
      ],
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        cellStyle: (params) => {
          if (params.value < 0.00) {
            return { color: 'red' };
          }
          return null;
        },
      },
      showDatePicker: false,
      showFooter: false,
      enableCharts: true,
      apiQueryParams: null,
    };
  }

  static getDerivedStateFromProps(props) {
    if (props.asin && props.marketplace && props.sellerSelect.value) {
      return {
        apiQueryParams: `asin=${props.asin}&marketplace=${props.marketplace}&selectedDates=${props.selectedDates}&sellerAccountId=${props.sellerSelect.value}&parentAccountId=${props.sellerSelect.parentAccountId}`,
      };
    }
    return null;
  }

  render() {
    const {
      columnDefs, defaultColDef, showFooter, enableCharts, enableRangeSelection, apiQueryParams, showDatePicker, sideBar, pivotMode, autoGroupColumnDef, suppressAggFuncInHeader,
    } = this.state;

    const { sellerSelect, setPageData } = this.props;

    const apiEndPoint = `${apiUrl}/jtsr/search-term-pivot`;
    const exportPrefix = getExportPrefix('jtsr-search-term', sellerSelect);

    return (
      <TableBuilder
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        apiEndPoint={apiEndPoint}
        apiQueryParams={apiQueryParams}
        showFooter={showFooter}
        enableCharts={enableCharts}
        enableRangeSelection={enableRangeSelection}
        showDatePicker={showDatePicker}
        sideBar={sideBar}
        pivotMode={pivotMode}
        autoGroupColumnDef={autoGroupColumnDef}
        suppressAggFuncInHeader={suppressAggFuncInHeader}
        parentSetData={setPageData}
        exportPrefix={exportPrefix}
      />
    );
  }
}

export default JtsrSearchTermRank;
