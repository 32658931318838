import React, { PureComponent } from 'react';
import config from '../../../../config';
import TableBuilder from '../../../../shared/components/table/TableBuilder';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

export default class BasicAccountTable extends PureComponent {
  constructor() {
    super();
    this.state = {
      columnDefs: [
        {
          field: 'seller_account_id',
          headerName: 'Seller ID',
        },
        {
          field: 'customer_id',
          headerName: 'Customer ID',
        },
        {
          field: 'seller_account_name',
          headerName: 'Seller Name',
        },
        {
          field: 'seller_account_description',
          headerName: 'Seller Desc',
        },
        {
          field: 'seller_account_type_id',
          headerName: 'Seller Type',
        },
        {
          field: 'marketplace_id',
          headerName: 'Marketplace ID',
        },
        {
          field: 'status',
          headerName: 'Status',
        },
      ],
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
      },
      showDatePicker: false,
      showCornerButtons: false,
    };
  }

  render() {
    const {
      columnDefs, defaultColDef, showDatePicker, showCornerButtons,
    } = this.state;

    const apiEndPoint = `${apiUrl}/accounts`;

    return (
      <TableBuilder
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        apiEndPoint={apiEndPoint}
        showDatePicker={showDatePicker}
        tableHeight="150px"
        showCornerButtons={showCornerButtons}
      />
    );
  }
}
