import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import AccountView from './components/AccountView';

class AccountViewPage extends PureComponent {
  render() {
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Account Manager View</h3>
            <h3 className="page-subhead subhead">All Accounts</h3>
          </Col>
        </Row>
        <Row>
          <AccountView />
        </Row>
      </Container>
    );
  }
}

export default AccountViewPage;
