import PropTypes from 'prop-types';
import { withArray } from '../../../../shared/functions';

const calcTotal = (myData, dataset, field) => {
  let total = 0;
  withArray(myData, () => {
    total = myData.reduce((sum, item) => {
      if (item.datasets[dataset][field]) {
        return sum + item.datasets[dataset][field];
      }
      return sum;
    }, 0);
  });
  return total;
};

export default function calcPpcTotalsByCampaignType(data) {
  return withArray(data, () => {
    const result = [];
    const spData = {
      name: 'sp',
      sales: calcTotal(data, 'sp', 'sales'),
      spend: calcTotal(data, 'sp', 'spend'),
      displayName: 'Sponsored Products',
      color: '#d04960',
    };
    result.push(spData);
    const sbData = {
      name: 'sb',
      sales: calcTotal(data, 'sb', 'sales'),
      spend: calcTotal(data, 'sb', 'spend'),
      displayName: 'Sponsored Brands',
      color: '#03c03c',
    };
    result.push(sbData);
    const sbvData = {
      name: 'sbv',
      sales: calcTotal(data, 'sbv', 'sales'),
      spend: calcTotal(data, 'sbv', 'spend'),
      displayName: 'Sponsored Brands Video',
      color: '#36c9c9',
    };
    result.push(sbvData);
    const sdData = {
      name: 'sd',
      sales: calcTotal(data, 'sd_cpc', 'sales') + calcTotal(data, 'sd_vcpm', 'sales'),
      spend: calcTotal(data, 'sd_cpc', 'spend') + calcTotal(data, 'sd_vcpm', 'spend'),
      displayName: 'Sponsored Display',
      color: '#fbb054',
    };
    result.push(sdData);
    return result;
  }, () => []);
}

calcPpcTotalsByCampaignType.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
};
