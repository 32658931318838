/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import PpcBestsellers from './components/Page';
import PpcBestsellersGraph from '../../../../../shared/components/widgets/FullWidthFillGraph';
import PpcBestsellersButtons from '../../components/PpcBestsellersButtons';
import PpcBestsellersTotals from '../../components/PpcBestsellersTotals';
import DateRangePickerPanel from '../../../../../shared/components/widgets/DateRangePickerPanel';
import config from '../../../../../config';
import { SellerSelect, User } from '../../../../../shared/prop-types/MainProps';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class PpcBestsellersPage extends PureComponent {
  static propTypes = {
    sellerSelect: SellerSelect.isRequired,
    user: User.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      graphData: [],
      initalLoad: false,
      graphLoad: false,
      reportStartDate: moment().subtract(11, 'days').format('YYYY-MM-DD'),
      reportEndDate: moment().subtract(4, 'days').format('YYYY-MM-DD'),
      showingAll: false,
      pivotOn: 'ASIN',
      checkedItem: new Set(),
      totalPpcSpend: 0,
      totalPpcSales: 0,
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevState) {
    const { sellerSelect } = this.props;
    if (sellerSelect.value !== prevState.sellerSelect.value) {
      this.getData();
    }
  }

  handleCheckboxChange = (event) => {
    const { checkedItem } = this.state;
    const updateSet = new Set(checkedItem);
    if (updateSet.has(event.target.id)) {
      updateSet.delete(event.target.id);
    } else {
      updateSet.add(event.target.id);
    }

    this.setState({
      checkedItem: updateSet,
      graphLoad: true,
    }, () => {
      this.getGraphData();
    });
  }

  onDateChange = (startDate, endDate) => {
    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).format('YYYY-MM-DD');
    this.setState({
      reportStartDate: start,
      reportEndDate: end,
    }, this.getData);
  }

  toggleDisplay = () => {
    this.setState(prevState => ({
      showingAll: !prevState.showingAll,
    }));
  }

  setPivot = (pivotOn) => {
    this.setState({
      pivotOn,
      checkedItem: new Set(),
    }, () => {
      this.getData();
    });
  }

  pivotData = (data, pivotType) => {
    if (pivotType === 'ASIN') {
      const newData = [];
      data.forEach((row) => {
        let pacos = 0.00;
        let tacos = 0.00;
        let roas = 0.00;
        let shippedSales = 0;
        if (row.spend && row.sales && row.sales !== 0) {
          pacos = (row.spend / row.sales) * 100;
        }
        if (
          row.spend && row.sourcing_shipped_units && row.list_price && (row.sourcing_shipped_units * row.list_price) !== 0
        ) {
          tacos = (row.spend / (row.sourcing_shipped_units * row.list_price)) * 100;
        }
        if (row.spend && row.spend !== 0) {
          roas = (row.sales / row.spend);
        }
        if (row.sourcing_shipped_units && row.list_price) {
          shippedSales = (row.sourcing_shipped_units * row.list_price);
        }

        newData.push({
          ...row,
          pacos,
          tacos,
          roas,
          shippedSales,
        });
      });

      return newData;
    }
    const pivotData = data.reduce((list, row) => {
      const driverColumn = row[pivotType.toLowerCase()];
      if (driverColumn !== undefined) {
        const currentObj = list.get(driverColumn);
        if (currentObj) {
          currentObj.impressions += row.impressions;
          currentObj.clicks += row.clicks;
          currentObj.spend += row.spend;
          currentObj.sales += row.sales;
          currentObj.units += row.units;
          currentObj.sourcing_shipped_units += (row.sourcing_shipped_units || 0);
          currentObj.sourcing_shipped_cogs += (row.sourcing_shipped_cogs || 0);
          currentObj.shippedSales += ((row.sourcing_shipped_units || 0) * row.list_price);
          list.set(driverColumn, currentObj);
        } else {
          const newObj = {
            parent_asin: driverColumn,
            brand: driverColumn,
            segment_one: driverColumn,
            segment_two: driverColumn,
            segment_three: driverColumn,
            segment_four: driverColumn,
            segment_five: driverColumn,
            category: driverColumn,
            subcategory: driverColumn,
            impressions: row.impressions,
            clicks: row.clicks,
            spend: row.spend,
            sales: row.sales,
            units: row.units,
            sourcing_shipped_units: (row.sourcing_shipped_units || 0),
            sourcing_shipped_cogs: (row.sourcing_shipped_cogs || 0),
            shippedSales: ((row.sourcing_shipped_units || 0) * row.list_price),
          };
          list.set(driverColumn, newObj);
        }
      }
      return list;
    }, new Map());

    const newData = [];
    Array.from(pivotData.values()).forEach((row) => {
      let pacos = 0.00;
      let tacos = 0.00;
      let roas = 0.00;
      if (row.spend && row.sales && row.sales !== 0) {
        pacos = (row.spend / row.sales) * 100;
      }
      if (row.spend && row.shippedSales && row.shippedSales !== 0
      ) {
        tacos = (row.spend / row.shippedSales) * 100;
      }
      if (row.shippedSales && row.spend && row.spend !== 0) {
        roas = (row.sales / row.spend);
      }

      newData.push({
        ...row,
        pacos,
        tacos,
        roas,
      });
    });

    return newData;
  }

  getGraphData = () => {
    const { sellerSelect, user } = this.props;
    const {
      reportStartDate, reportEndDate, pivotOn, checkedItem,
    } = this.state;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    // Use same requestOptions as above
    const bestsellersGraphUrl = `${apiUrl}/vendors/ppc-bestsellers-graph?sellerAccountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}&startDate=${reportStartDate}&endDate=${reportEndDate}&columnType=${pivotOn}&currencyCode=${(sellerSelect.currencyCode || 'USD')}&itemList=${Array.from(checkedItem).join(',')}`;
    fetch(bestsellersGraphUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((graphData) => {
        this.setState({
          graphLoad: false,
          graphData,
        });
      }).catch(() => {
        this.setState({
          graphLoad: false,
        });
      });
  }

  getData = () => {
    const { sellerSelect, user } = this.props;
    const {
      reportStartDate, reportEndDate, pivotOn, checkedItem,
    } = this.state;
    const bestsellersUrl = `${apiUrl}/vendors/ppc-bestsellers?sellerAccountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}&startDate=${reportStartDate}&endDate=${reportEndDate}&currencyCode=${(sellerSelect.currencyCode || 'USD')}`;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    this.setState({
      initalLoad: true,
      graphLoad: true,
    });

    fetch(bestsellersUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        const pivotedData = this.pivotData(data, pivotOn);

        const sortData = pivotedData.sort((a, b) => {
          const checkPos = (b.sales < a.sales) ? -1 : 0;
          return (b.sales > a.sales) ? 1 : checkPos;
        });

        let totalPpcSpend = 0;
        let totalPpcSales = 0;
        sortData.forEach((item) => {
          totalPpcSpend += item.spend;
          totalPpcSales += item.sales;
        });

        this.setState({
          initalLoad: false,
          data: sortData,
          totalPpcSpend,
          totalPpcSales,
        });

        let lineCount = 5;
        if (sortData.length < 5) {
          lineCount = sortData.length;
        }

        for (let i = 0; i < lineCount; i += 1) {
          if (sortData[i][pivotOn.toLowerCase()]) {
            checkedItem.add(sortData[i][pivotOn.toLowerCase()]);
          }
        }

        this.setState({
          checkedItem,
        });

        this.getGraphData();
      }).catch(() => {
        this.setState({
          initalLoad: false,
        });
      });
  };

  render() {
    const {
      data, graphData, initalLoad, graphLoad, reportStartDate, reportEndDate, showingAll, pivotOn, checkedItem, totalPpcSpend, totalPpcSales,
    } = this.state;

    const { sellerSelect } = this.props;

    if (sellerSelect.type === 2) {
      return (
        <Container className="dashboard">
          <Col md={12}>
            <Row>
              <DateRangePickerPanel
                reportStartDate={reportStartDate}
                reportEndDate={reportEndDate}
                onDateChange={this.onDateChange}
              />
            </Row>
          </Col>
          <Col md={12}>
            <Row>
              <PpcBestsellersGraph
                data={graphData}
                dataType="asin"
                title="Top 5 PPC Bestsellers"
                initalLoad={graphLoad}
                lineList={Array.from(checkedItem)}
                colorOptions={['#70bbfd', '#ffb153', '#f47b50', '#c88ffa', '#4ce1b6', '#66CCFF', '#FF9966', '#FF6666', '#9999CC', '#33CCCC']}
              />
            </Row>
          </Col>
          <Col md={12}>
            <Row>
              <PpcBestsellersButtons
                setPivot={this.setPivot}
                accountType={sellerSelect.type}
              />
            </Row>
          </Col>
          <Col md={12}>
            <PpcBestsellersTotals
              totalPpcSpend={totalPpcSpend}
              totalPpcSales={totalPpcSales}
              initalLoad={initalLoad}
            />
          </Col>
          <Col md={12}>
            <Row>
              <PpcBestsellers
                newOrder={data}
                title="PPC Bestsellers"
                subhead="Top 10 Bestseller - Advertised Products"
                initalLoad={initalLoad}
                reportStartDate={reportStartDate}
                reportEndDate={reportEndDate}
                showingAll={showingAll}
                toggleDisplay={this.toggleDisplay}
                pivotOn={pivotOn}
                handleCheckboxChange={this.handleCheckboxChange}
                checkedItem={checkedItem}
                graphLoad={graphLoad}
              />
            </Row>
          </Col>
        </Container>
      );
    }
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <p>This is a Vendor specific report. Please select a new report or select a Vendor account.</p>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { sellerSelect } = state;
  const { user } = state.authentication;

  return {
    sellerSelect,
    user,
  };
};

export default connect(mapStateToProps)(PpcBestsellersPage);
