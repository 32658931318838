import config from '../../../config';

function getChartData(user, accountId, startDate, endDate) {
  const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
  const requestOptions = {
    method: 'GET',
    headers: headerWithAuth,
  };

  const startParam = startDate.format('YYYY-MM-DD');
  const endParam = endDate.format('YYYY-MM-DD');
  const url = `${config.serverUrl}/accounts/amazon-search-trend-ranks?accountId=${accountId}&startDate=${startParam}&endDate=${endParam}`;

  return fetch(url, requestOptions);
}

function getTableData(user, accountId, startDate, endDate) {
  const headers = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
  const requestOptions = {
    method: 'GET',
    headers,
  };

  const startParam = startDate.format('YYYY-MM-DD');
  const endParam = endDate.format('YYYY-MM-DD');
  const url = `${config.serverUrl}/accounts/amazon-search-trends?accountId=${accountId}&startDate=${startParam}&endDate=${endParam}`;

  return fetch(url, requestOptions);
}

const tableColumns = [
  {
    field: 'reportDate',
    headerName: 'Date',
  },
  {
    field: 'searchTerm',
    headerName: 'Search Term',
  },
  {
    field: 'position',
    headerName: 'Position',
  },
  {
    field: 'departmentName',
    headerName: 'Department Name',
  },
  {
    field: 'searchFrequencyRank',
    headerName: 'Search Frequency Rank',
  },
  {
    field: 'clickedAsin1',
    headerName: 'ASIN 1',
  },
  {
    field: 'clickedItemName1',
    headerName: 'Item Name 1',
  },
  {
    field: 'clickShareRank1',
    headerName: 'Click Share Rank 1',
  },
  {
    field: 'clickShare1',
    headerName: 'Click Share 1',
  },
  {
    field: 'conversionShare1',
    headerName: 'Conversion Share 1',
  },
  {
    field: 'clickedAsin2',
    headerName: 'ASIN 2',
  },
  {
    field: 'clickedItemName2',
    headerName: 'Item Name 2',
  },
  {
    field: 'clickShareRank1',
    headerName: 'Click Share Rank 2',
  },
  {
    field: 'clickShare2',
    headerName: 'Click Share 2',
  },
  {
    field: 'conversionShare2',
    headerName: 'Conversion Share 2',
  },
  {
    field: 'clickedAsin3',
    headerName: 'ASIN 3',
  },
  {
    field: 'clickedItemName3',
    headerName: 'Item Name 3',
  },
  {
    field: 'clickShareRank3',
    headerName: 'Click Share Rank 3',
  },
  {
    field: 'clickShare3',
    headerName: 'Click Share 3',
  },
  {
    field: 'conversionShare3',
    headerName: 'Conversion Share 3',
  },
];

function getTableColumns() {
  return tableColumns;
}

export default {
  getChartData,
  getTableColumns,
  getTableData,
};
