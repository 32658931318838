import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import useFetch from '../../../../shared/components/hooks/useFetch';
import config from '../../../../configWrapper';
import style, { DROPDOWN_COLORS } from '../../../../shared/components/themeSupport';

function BrandSelect({
  brands, brandSelected, label, onChange, loading,
}) {
  const theme = useSelector(store => store.theme);
  const themeColors = style(theme, DROPDOWN_COLORS);

  return (
    <>
      <p>{ label }</p>
      <div className="topbar__dynamicDropdownWidth">
        <Select
          value={brandSelected}
          onChange={choice => onChange(choice)}
          options={[...brands]}
          isDisabled={loading ? 'disabled' : ''}
          theme={selectTheme => ({
            ...selectTheme,
            colors: {
              ...selectTheme.colors,
              primary: `${themeColors.colorPrimary}`,
              neutral0: `${themeColors.colorBackground}`,
              neutral80: `${themeColors.colorText}`,
            },
          })}
        />
      </div>
    </>
  );
}

BrandSelect.propTypes = {
  brands: PropTypes.instanceOf(Array).isRequired,
  brandSelected: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function BrandSelectProvider({
  label, onChange, brandSelected, accountId, loading,
}) {
  const [brands, setBrands] = useState([{ value: '', label: '** ALL BRANDS **' }]);
  const { results, error } = useFetch(`${config.serverUrl}/catalogs/brands?accountId=${accountId}`);

  useEffect(() => {
    if (error) {
      console.error(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (results && results.length > 0) {
      const brandOptions = [{ value: '', label: '** ALL BRANDS **' }];
      const brandsJson = results;

      if (Array.isArray(brandsJson)) {
        brandsJson.forEach((brand) => {
          brandOptions.push({
            value: brand.lowercase_brand,
            label: brand.brand,
          });
        });
      }
      setBrands(brandOptions);
    }
  }, [results]);

  return (
    <BrandSelect
      label={label}
      onChange={onChange}
      loading={loading}
      brands={brands}
      brandSelected={brandSelected}
    />
  );
}

BrandSelectProvider.defaultProps = {
  label: '',
  accountId: '0',
  brandSelected: { value: '', label: '** ALL BRANDS **' },
};

BrandSelectProvider.propTypes = {
  onChange: PropTypes.func.isRequired,
  brandSelected: PropTypes.instanceOf(Object),
  label: PropTypes.string,
  accountId: PropTypes.string,
};
