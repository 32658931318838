import React from 'react';
import PlanManagment from './Plan/Managment';

export default function SubscriptionPlan({
  clientSecret, setClientSecret, selectedPlan, setSelectedPlan, selectedSubscriptionId, setSelectedSubscriptionId,
}) {
  return (
    <PlanManagment
      selectedPlan={selectedPlan}
      setSelectedPlan={setSelectedPlan}
      clientSecret={clientSecret}
      setClientSecret={setClientSecret}
      selectedSubscriptionId={selectedSubscriptionId}
      setSelectedSubscriptionId={setSelectedSubscriptionId}
    />
  );
}
