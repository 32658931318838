import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FilterSelect from './NewFilterSelect';

export const DataSources = {
  ALL: {
    label: 'ASINs and Search Terms',
    value: '',
  },
  ASINS: {
    label: 'ASINs',
    value: 'targets',
  },
  SEARCH_TERMS: {
    label: 'Search Terms',
    value: 'searchTerms',
  },
  * [Symbol.iterator]() {
    yield DataSources.ALL;
    yield DataSources.ASINS;
    yield DataSources.SEARCH_TERMS;
  },
};

export default function DataSourceFilter({
  initialValue, onChange, disable,
}) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (value) {
      onChange(value);
    }
  }, [value]);

  return (
    <FilterSelect
      label="Data Source:"
      onChange={option => setValue(option)}
      name="sourceOption"
      options={[...DataSources]}
      selected={value}
      disable={disable}
    />
  );
}

DataSourceFilter.defaultProps = {
  initialValue: DataSources.ALL,
  disable: false,
};

DataSourceFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialValue: PropTypes.oneOf([...DataSources]),
  disable: PropTypes.bool,
};
