/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Line, ComposedChart,
} from 'recharts';
import CardPanel from '../../../../../shared/components/widgets/CardPanel';
import style from '../../../../../shared/components/themeSupport';
import { formatNumberWhole } from '../../../../../shared/components/table/functions';

export default function GlancesVsPPCClicks({ loading, data }) {
  const theme = useSelector(state => state.theme);

  return (
    <CardPanel
      md={12}
      title="Glance Views VS PPC Clicks"
      parentRefresh={loading}
    >
      {!loading && data && (
        <ResponsiveContainer height={250} className="dashboard__area">
          <ComposedChart data={data}>
            <CartesianGrid stroke={style(theme).colorText} />
            <XAxis dataKey="rankDate" stroke={style(theme).colorText} />
            <YAxis
              stroke={style(theme).colorText}
              tickFormatter={val => formatNumberWhole(val)}
            />
            <Tooltip />
            <Legend />
            <Line
              name="Glance Views"
              type="monotone"
              dataKey="glance_views"
              stroke={style(theme).lineYellow}
              strokeWidth={2}
              formatter={val => formatNumberWhole(val)}
            />
            <Line
              name="PPC Clicks"
              type="monotone"
              dataKey="clicks"
              stroke={style(theme).lineOrange}
              strokeWidth={2}
              formatter={val => formatNumberWhole(val)}
            />
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </CardPanel>
  );
}

GlancesVsPPCClicks.defaultProps = {
  data: null,
};

GlancesVsPPCClicks.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.instanceOf(Array),
};
