import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import VendorSalesShare from './components/VendorSalesShare';

const VendorSalesSharePage = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Vendor Sales Share</h3>
      </Col>
    </Row>
    <Row>
      <VendorSalesShare />
    </Row>
  </Container>
);

export default VendorSalesSharePage;
