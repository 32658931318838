import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import ProfitReport from './components/ProductAdsReport';

const ProductAdsPage = () => {
  const sellerSelect = useSelector(store => store.sellerSelect);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Product Ads</h3>
        </Col>
      </Row>
      <Row>
        <ProfitReport sellerSelect={sellerSelect} />
      </Row>
    </Container>
  );
}

export default ProductAdsPage;
