import axios from 'axios';
import { useSelector } from 'react-redux';
import config from '../../../config';

export const defaultParams = () => {
  const user = useSelector(store => store.authentication.user);
  return {
    headers: { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` },
  };
};

export default axios;
