import { userConstants } from '../../constants';
import { userService } from '../../services';
import { alertActions } from './alertActions';
import { sellerSelectActions } from './sellerSelectActions';
import { history } from '../../helpers';
import { changeThemeToLight, changeThemeToDark, changeThemeToLimitless } from './themeActions';

function login(email, password, recaptcha, rememberMe) {
  function request(user) { return { type: userConstants.LOGIN_REQUEST, user }; }
  function success(user) { return { type: userConstants.LOGIN_SUCCESS, user }; }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ email }));

    userService.login(email, password, recaptcha, rememberMe)
      .then(
        (user) => {
          dispatch(success(user));
          if (user.theme === 'limitless') {
            dispatch(changeThemeToLimitless());
          } else if (user.theme === 'dark') {
            dispatch(changeThemeToDark());
          } else if (user.theme === 'light') {
            dispatch(changeThemeToLight());
          }
          dispatch(alertActions.clear());
          history.push('/');
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        },
      );
  };
}

function refreshLogin(currentUser) {
  function request(user) { return { type: userConstants.REFRESH_LOGIN_REQUEST, user }; }
  function success(user) { return { type: userConstants.REFRESH_LOGIN_SUCCESS, user }; }
  function failure(error) { return { type: userConstants.REFRESH_LOGIN_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ currentUser }));

    userService.refreshLogin(currentUser)
      .then(
        (newUser) => {
          dispatch(success(newUser));
          if (newUser.theme === 'limitless') {
            dispatch(changeThemeToLimitless());
          } else if (newUser.theme === 'dark') {
            dispatch(changeThemeToDark());
          } else if (newUser.theme === 'light') {
            dispatch(changeThemeToLight());
          }
          dispatch(alertActions.clear());
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        },
      );
  };
}

function logout() {
  userService.logout();
  return (dispatch) => {
    dispatch(sellerSelectActions.sellerSelectReset());
    return { type: userConstants.LOGOUT };
  };
}

function register(user, recaptcha) {
  // eslint-disable-next-line
  function request(user) { return { type: userConstants.REGISTER_REQUEST, user }; }
  // eslint-disable-next-line
  function success(user) { return { type: userConstants.REGISTER_SUCCESS, user }; }
  // eslint-disable-next-line
  function failure(error) { return { type: userConstants.REGISTER_FAILURE, error }; }


  return (dispatch) => {
    dispatch(request(user));

    userService.register(user, recaptcha)
      .then(
      // eslint-disable-next-line
      (user) => {
          dispatch(success());
          history.push('/login');
          dispatch(alertActions.success('Registration successful'));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        },
      );
  };
}

function forgotPassword(email) {
  function request() { return { type: userConstants.FORGOTPASSWORD_REQUEST }; }
  function success(user) { return { type: userConstants.FORGOTPASSWORD_SUCCESS, user }; }
  function failure(error) { return { type: userConstants.FORGOTPASSWORD_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    userService.forgotPassword(email)
      .then(
        (user) => {
          history.push('/login');
          dispatch(success(user));
          dispatch(alertActions.success('Check your email for your temporary password.'));
        },
        error => dispatch(failure(error.toString())),
      );
  };
}

function getAll() {
  function request() { return { type: userConstants.GETALL_REQUEST }; }
  function success(users) { return { type: userConstants.GETALL_SUCCESS, users }; }
  function failure(error) { return { type: userConstants.GETALL_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    userService.getAll()
      .then(
        users => dispatch(success(users)),
        error => dispatch(failure(error.toString())),
      );
  };
}

// prefixed function name with underscore because delete is a reserved word in javascript
// eslint-disable-next-line
function _delete(id) {
  // eslint-disable-next-line
  function request(id) { return { type: userConstants.DELETE_REQUEST, id }; }
  // eslint-disable-next-line
  function success(id) { return { type: userConstants.DELETE_SUCCESS, id }; }
  // eslint-disable-next-line
  function failure(id, error) { return { type: userConstants.DELETE_FAILURE, id, error }; }

  return (dispatch) => {
    dispatch(request(id));

    userService.delete(id)
      .then(
        // eslint-disable-next-line
        user => dispatch(success(id)),
        error => dispatch(failure(id, error.toString())),
      );
  };
}

function updateProfile(id, jwtToken, updateUserData) {
  // eslint-disable-next-line
  function request(user) { return { type: userConstants.PROFILE_UPDATE_REQUEST, user }; }
  // eslint-disable-next-line
  function success(user) { return { type: userConstants.PROFILE_UPDATE_SUCCESS, user }; }
  // eslint-disable-next-line
  function failure(error) { return { type: userConstants.PROFILE_UPDATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ email: updateUserData.email }));

    userService.updateProfile(id, jwtToken, updateUserData)
      .then(
        (user) => {
          dispatch(success(user));
          const userObject = user[0];
          if (userObject.theme === 'limitless') {
            dispatch(changeThemeToLimitless());
          } else if (userObject.theme === 'dark') {
            dispatch(changeThemeToDark());
          } else if (userObject.theme === 'light') {
            dispatch(changeThemeToLight());
          }
          logout();
          history.push('/login');
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        },
      );
  };
}

function authorizeApi(name, email, recaptcha, countryObj, agencyCode, platform, accountType, accountId, apiType, isTest) {
  // eslint-disable-next-line
  function request(email) { return { type: userConstants.AUTHORIZE_API_REQUEST, email }; }
  // eslint-disable-next-line
  function success(email) { return { type: userConstants.AUTHORIZE_API_SUCCESS, email }; }
  // eslint-disable-next-line
  function failure(error) { return { type: userConstants.AUTHORIZE_API_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ email }));

    userService.authorizeApi(name, email, recaptcha, countryObj, agencyCode, platform, accountType, accountId, apiType, isTest)
      .then(
        (response) => {
          // add logic to make sure we received a 200 response code.
          window.location.href = response.redirectUrl;
          // window.open(response.redirectUrl, '_self');
          dispatch(success(response));
          dispatch(alertActions.success(`Use this URL if you are not automatically redirected: \n ${response.redirectUrl}`));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        },
      );
  };
}

// eslint-disable-next-line
export const userActions = {
  login,
  logout,
  register,
  getAll,
  delete: _delete,
  forgotPassword,
  updateProfile,
  refreshLogin,
  authorizeApi,
};
