import {
  useState, useEffect,
} from 'react';
import config from '../../../../config';
import { useFetchReturnsArray as useFetch } from '../../../../shared/components/hooks/useFetch';
import { when } from '../../../../shared/functions';

function getCountUrl(account, path) {
  if (account && account.value) {
    const url = `${config.serverUrl}/${path}?accountId=${account.value}&parentAccountId=${account.parentAccountId}`;
    return url;
  }

  return undefined;
}

export default function usePerformanceData(
  account,
) {
  const [subcatPerformanceLoading, subcatPerformanceResults, subcatPerformanceError] = useFetch(getCountUrl(account, 'insights/subcat-performance-data'));
  const [subcatPerformanceDateLoading, subcatPerformanceDateResults, subcatPerformanceDateError] = useFetch(getCountUrl(account, 'insights/subcat-performance-by-date'));
  const loading = subcatPerformanceLoading === 'true' && subcatPerformanceDateLoading === 'true'; // Check all the loadings here
  const [accountId, setAccountId] = useState(account.value);
  const [results, setResults] = useState({ performanceCount: 0, performanceCards: [] });
  const [error, setError] = useState('');

  // Subcat Performance Data Effects
  useEffect(() => {
    if (subcatPerformanceResults?.subcatCount > 0) {
      const data = [
        {
          title: 'Sales',
          children: subcatPerformanceResults.subcatPerfData.filter(row => row.sum_sales_30d > 0),
        },
      ]

      const performanceCards = [];

      const treemapPerformanceCard = {
        title: 'Subcategory Performance Treemap',
        countName: 'Subcatgory Count',
        countValue: subcatPerformanceResults.subcatCount,
        platform: 'Amazon',
        recommendation: 'Subcatogry sales performance over the last 30 days.',
        data,
        lastDate: subcatPerformanceResults.rollupDate,
        firstDate: subcatPerformanceResults.metadataDate,
        modalType: 'treemap',
      }
      performanceCards.push(treemapPerformanceCard);

      const piePerformanceCard = {
        title: 'Subcategory Performance Pie',
        countName: 'Subcatgory Count',
        countValue: subcatPerformanceResults.subcatCount,
        platform: 'Amazon',
        recommendation: 'Subcatogry sales performance over the last 30 days.',
        data: subcatPerformanceResults.subcatPerfData.filter(row => row.sum_sales_30d > 0),
        lastDate: subcatPerformanceResults.rollupDate,
        firstDate: subcatPerformanceResults.metadataDate,
        modalType: 'pie',
      }
      performanceCards.push(piePerformanceCard);

      setResults({
        performanceCount: results.performanceCount + performanceCards.length,
        performanceCards: [
          ...results.performanceCards,
          ...performanceCards,
        ],
      });
    }
  }, [subcatPerformanceResults, setResults]);

  useEffect(() => {
    when(subcatPerformanceError, () => {
      setError(subcatPerformanceError);
    });
  }, [subcatPerformanceError, setError]);

  // Subcat Performance by Date Effects
  useEffect(() => {
    if (subcatPerformanceDateResults?.subcatDateCount > 0) {
      const performanceCard = {
        title: 'Subcategory Performance By Date',
        countName: 'Subcatgory Date Count',
        countValue: subcatPerformanceDateResults.subcatDateCount,
        platform: 'Amazon',
        recommendation: 'Subcatogry sales performance over the last 30 days.',
        data: subcatPerformanceDateResults.subcatDateData,
        lastDate: subcatPerformanceDateResults.startDate,
        firstDate: subcatPerformanceDateResults.endDate,
        modalType: 'line',
        series: subcatPerformanceDateResults.series,
      }

      setResults({
        performanceCount: results.performanceCount + 1,
        performanceCards: [
          ...results.performanceCards,
          performanceCard,
        ],
      });
    }
  }, [subcatPerformanceDateResults, setResults]);

  useEffect(() => {
    when(subcatPerformanceDateError, () => {
      setError(subcatPerformanceDateError);
    });
  }, [subcatPerformanceDateError, setError]);

  // Account Change Effect
  useEffect(() => {
    if (account.value !== accountId) {
      setResults({ performanceCount: 0, performanceCards: [] });
      setAccountId(account.value);
    }
  }, [account]);

  return [loading, results, error];
}
