import { useEffect, useState } from 'react';
import { useFetchReturnsArray as useFetch } from './useFetch';
import config from '../../../configWrapper';
import useAccountMarketplace, { formatProductLink } from './useMarketPlace';
import { withArray } from '../../functions';
import { formatDecimalToFixedTwoPercent, numberTwoPlaces } from '../table/functions';

function formatValue(value, type) {
  if (type === 'rank') {
    return numberTwoPlaces({ value });
  }
  return formatDecimalToFixedTwoPercent({ value });
}

function formatUrl(account, start, end, type, brand) {
  if (account && account.value && start && end && type) {
    if (type === 'acos') {
      return `${config.serverUrl}/dashboard/top-asins?accountId=${account.value}&accountType=${account.type}&startDate=${start.format('YYYYMMDD')}&endDate=${end.format('YYYYMMDD')}&type=${type}&limit=${4}&brandFilter=${brand.value || ''}`;
    }
    if (type === 'rank') {
      return `${config.serverUrl}/dashboard/top-asins-rank?accountId=${account.value}&accountType=${account.type}&startDate=${start.format('YYYYMMDD')}&endDate=${end.format('YYYYMMDD')}&type=${type}&limit=${4}&brandFilter=${brand.value || ''}`;
    }
  }
  return undefined;
}

function truncateValue(value, length) {
  let result = value;
  if (length && value && value.length > length) {
    result = `${value.substring(0, length - 4)}...`;
  }
  return result;
}

export default function useTopAsins(account, start, end, type, brand = {}) {
  const marketplace = useAccountMarketplace(account);
  const [loading, results, error] = useFetch(formatUrl(account, start, end, type, brand));
  const [value, setValue] = useState(null);

  useEffect(() => {
    const output = withArray(results, () => {
      results.forEach((item) => {
        item.displayTitle = truncateValue(item.product_title, 54); // eslint-disable-line no-param-reassign
        item.productLink = formatProductLink(item.asin, marketplace); // eslint-disable-line no-param-reassign
        item.displayValue = formatValue(item[type], type); // eslint-disable-line no-param-reassign
      });
      return results;
    }, () => []);
    setValue(output);
  }, [results, marketplace, setValue]);

  return [loading, value, error];
}
