import React from 'react';
import { Link } from 'react-router-dom';
import RegisterForm from './components/RegisterForm';
import { LoginHeader, setFavicon } from '../../../shared/components/domainSupport';

const Register = () => (
  <div className="account">
    <div className="account__wrapper">
      <div className="account__card">
        {setFavicon()}
        <LoginHeader />
        <RegisterForm onSubmit />
        <div className="account__have-account">
          <p>Already have an account? <Link to="/login">Login</Link></p>
        </div>
      </div>
    </div>
  </div>
);

export default Register;
