import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import SellerAsinBrandPerformanceReport from './components/SellerAsinBrandPerformanceReport';

const SellerAsinBrandPerformanceReportPage = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Seller Asin Brand Performance Report</h3>
      </Col>
    </Row>
    <Row>
      <SellerAsinBrandPerformanceReport />
    </Row>
  </Container>
);

export default SellerAsinBrandPerformanceReportPage;
