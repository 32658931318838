import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import VendorSalesSegmentShare from './components/VendorSalesSegmentShare';

const VendorSalesSegmentSharePage = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">ASIN Market Share</h3>
      </Col>
    </Row>
    <Row>
      <VendorSalesSegmentShare />
    </Row>
  </Container>
);

export default VendorSalesSegmentSharePage;
