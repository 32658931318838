import React, { Fragment } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { BrowserRouter, Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import HttpsRedirect from 'react-https-redirect';
import 'rsuite/dist/rsuite.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import RouterMain from './Router';
import store from './store';
import ScrollToTop from './ScrollToTop';
import { config as i18nextConfig } from '../../translations';
import LoadingSpinner from '../../shared/components/widgets/LoadingSpinner';

// Temp as we test out history
import { history } from '../../helpers';

i18next.init(i18nextConfig);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loaded: false,
    };
  }

  componentDidMount() {
    window.addEventListener('load', () => {
      this.setState({ loading: false });
      setTimeout(() => this.setState({ loaded: true }), 500);
    });
  }

  render() {
    const { loaded, loading } = this.state;
    return (
      <Provider store={store}>
        <HttpsRedirect>
          <BrowserRouter>
            <I18nextProvider i18n={i18next}>
              <ScrollToTop>
                <Fragment>
                  {!loaded
                  && (
                    <div className={`load${loading ? '' : ' loaded'}`}>
                      <LoadingSpinner
                        active={loading}
                        wrap
                      />
                    </div>
                  )
                  }
                  <div>
                    <Router history={history}>
                      <RouterMain />
                    </Router>
                  </div>
                </Fragment>
              </ScrollToTop>
            </I18nextProvider>
          </BrowserRouter>
        </HttpsRedirect>
      </Provider>
    );
  }
}

export default hot(module)(App);
