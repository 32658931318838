import React, { useMemo } from 'react';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import config from '../../../configWrapper';

export default function ClientSidebarContent({ scope, menuDataFromServer }) {
  function generateKey() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }

  function isRenderedForEnvironment(isValidProduction, isProductionEnvironment) {
    return (isValidProduction || (isValidProduction === isProductionEnvironment));
  }

  function renderViewItem(viewItem) {
    if (viewItem.enabled && viewItem.scope === scope && isRenderedForEnvironment(viewItem.production, config.isProdEnv)) {
      return (
        <SidebarLink key={generateKey()} title={viewItem.title} route={viewItem.route} icon={viewItem.icon} betaLink={viewItem.isBeta} />
      );
    }
    return null;
  }

  function renderFeatureItem(featureItem) {
    if (featureItem.enabled && featureItem.scope === scope && isRenderedForEnvironment(featureItem.production, config.isProdEnv)) {
      return (
        <SidebarCategory key={generateKey()} title={featureItem.title} scope={featureItem.scope} icon={featureItem.icon} isNew={featureItem.isNew}>
          {featureItem.content && Object.values(featureItem.content).map(view => renderViewItem(view))}
        </SidebarCategory>
      );
    }
    return null;
  }

  function renderMenu(menuData) {
    return Object.values(menuData || {}).map(menuItem => (menuItem?.type === 'feature' ? renderFeatureItem(menuItem) : renderViewItem(menuItem)));
  }

  // useMemo to only re-render the menu when the data we receive changes (according to React)... fixes sub-menus closing issue
  return useMemo(() => renderMenu(menuDataFromServer), [menuDataFromServer]);
}
