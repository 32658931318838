import React from 'react';
import {
  Container,
} from 'reactstrap';

function AccessDeinedPage() {
  return (
    <Container className="dashboard" />
  );
}

export default function AccessDeinedDashboard() {
  return (
    <AccessDeinedPage />
  );
}
