import React, { useState, useEffect } from 'react';
import {
  Badge, Card, CardBody, Collapse,
} from 'reactstrap';
import PropTypes from 'prop-types';
import CloseIcon from 'mdi-react/CloseIcon';
import MinusIcon from 'mdi-react/MinusIcon';
import AutorenewIcon from 'mdi-react/AutorenewIcon';
import SimplePanel from './SimplePanel';
import LoadingSpinner from './LoadingSpinner';

const CardPanel = ({
  divider, color, panelClass, parentRefresh, icon, label, title, subhead, getData, children, showButtons, minusButton, autorenewButton, closeButton,
}) => {
  const [visible, setVisible] = useState(true);
  const [open, setOpen] = useState(true);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (refresh) {
      if (getData) {
        getData();
      }
      setRefresh(false);
    }
  }, [refresh, setRefresh, getData]);

  if (visible) {
    return (
      <Card
        className={`panel${color ? ` panel--${color}` : ''}
        ${divider ? ' panel--divider' : ''}${open || !parentRefresh ? '' : ' panel--collapse'} ${panelClass}`}
      >
        <CardBody className="panel__body">
          {(refresh || parentRefresh) ? <LoadingSpinner active={refresh || parentRefresh} wrap /> : ''}
          { showButtons && (
            <div className="panel__btns">
              {minusButton && (
                <button className="panel__btn" type="button" onClick={() => setOpen(!open)}><MinusIcon /></button>
              )}
              {autorenewButton && (
                <button className="panel__btn" type="button" onClick={() => setRefresh(true)}><AutorenewIcon /></button>
              )}
              {closeButton && (
                <button className="panel__btn" type="button" onClick={() => setVisible(!visible)}><CloseIcon /></button>
              )}
            </div>
          )}
          { title && (
            <div className="panel__title">
              <h5 className="bold-text">
                {icon ? <span className={`panel__icon lnr lnr-${icon}`} /> : ''}
                {title}
                <Badge className="panel__label">{label}</Badge>
              </h5>
              <h5 className="subhead">{subhead}</h5>
            </div>
          )}
          <Collapse isOpen={open && !parentRefresh}>
            <SimplePanel>
              {children}
            </SimplePanel>
          </Collapse>
        </CardBody>
      </Card>
    );
  }

  return null;
};

CardPanel.defaultProps = {
  divider: false,
  color: '',
  title: '',
  subhead: '',
  label: '',
  icon: '',
  panelClass: '',
  parentRefresh: false,
  getData: null,
  showButtons: true,
  minusButton: true,
  autorenewButton: true,
  closeButton: false,
  children: null,
};

CardPanel.propTypes = {
  divider: PropTypes.bool,
  color: PropTypes.string,
  title: PropTypes.string,
  subhead: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  panelClass: PropTypes.string,
  parentRefresh: PropTypes.bool,
  getData: PropTypes.func,
  showButtons: PropTypes.bool,
  minusButton: PropTypes.bool,
  autorenewButton: PropTypes.bool,
  closeButton: PropTypes.bool,
  children: PropTypes.instanceOf(Object),
};

export default CardPanel;
