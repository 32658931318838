import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import ContactCenterView from './components/ContactCenterView';
import useCustomerLookup from '../../../shared/components/hooks/useCustomerLookup';

export default function ContactCenterPage() {
  const sellerSelect = useSelector(store => store.sellerSelect);
  const [loading, results, error] = useCustomerLookup(sellerSelect.customerId);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Account Manager Contact Center</h3>
          <h3 className="page-subhead subhead">Customer Contact</h3>
        </Col>
      </Row>
      <Row>
        <ContactCenterView loading={loading} results={results} error={error} platform={sellerSelect.platform} />
      </Row>
    </Container>
  );
}
