import { useFetchReturnsArray as useFetch } from './useFetch';
import config from '../../../configWrapper';

function formatUrl(account, start, end, brand) {
  if (account && account.value && start && end) {
    const url = `${config.serverUrl}/customers/product-metadata-ranks-in-range?accountId=${account.value}&parentAccountId=${account.parentAccountId}&startDate=${start.format('YYYYMMDD')}&endDate=${end.format('YYYYMMDD')}&brandFilter=${brand.value || ''}`;
    return url;
  }
  return undefined;
}

export default function useProductMetadataRankInRange(account, start, end, brand = {}) {
  return useFetch(formatUrl(account, start, end, brand));
}
