import useFetch from '../../../shared/components/hooks/useFetch';
import config from '../../../configWrapper';

function formatUrl(seller) {
  if (seller && seller.value && seller.type) {
    return `${config.serverUrl}/menu/client-menu-data?accountId=${seller.value}&type=${seller.type}&platform=${seller.platform}&plan=${seller.plan || ''}&planStatus=${seller.planStatus || ''}&menuVersion=2`;
  }
  return undefined;
}

export default function useClientMenuData(seller) {
  const fetchData = useFetch(formatUrl(seller));
  return fetchData.results;
}
