import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import classNames from 'classnames';
import { connect } from 'react-redux';
import config from '../../config';
import { SellerSelect, User } from '../prop-types/MainProps';
import LoadingSpinner from './widgets/LoadingSpinner';

const uploadDomain = config.isProdEnv ? config.uploadProdUrl : config.uploadDevUrl;

class ModalComponent extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    color: PropTypes.string.isRequired,
    colored: PropTypes.bool,
    header: PropTypes.bool,
    btn: PropTypes.string.isRequired,
    sellerSelect: SellerSelect.isRequired,
    user: User.isRequired,
    refreshData: PropTypes.func.isRequired,
    uploadPath: PropTypes.string.isRequired,
  };

  static defaultProps = {
    title: '',
    message: '',
    colored: false,
    header: false,
  };

  constructor() {
    super();
    this.state = {
      modal: false,
      uploadFile: null,
      processing: false,
      lastUploadResults: '',
    };

    this.toggle = this.toggle.bind(this);
    this.processFile = this.processFile.bind(this);
    this.completeUpload = this.completeUpload.bind(this);
  }

  toggle() {
    this.setState(prevState => ({ modal: !prevState.modal }));
  }

  handleChange(uploadFile) {
    this.setState({ uploadFile });
  }

  completeUpload() {
    const { refreshData } = this.props;
    this.setState({ processing: false });
    this.setState({ lastUploadResults: '' });
    refreshData();
    this.toggle();
  }

  processFile(e) {
    e.preventDefault();
    const { uploadFile } = this.state;
    const { user, sellerSelect, uploadPath } = this.props;
    const data = new FormData();
    data.append('sellerAccountId', sellerSelect.value.toString());
    data.append('accountId', sellerSelect.value.toString());
    this.setState({ processing: true });
    data.append('file', uploadFile);

    const headerWithAuth = { authorization: `JWT ${user.jwtToken}` };
    const requestOptions = {
      method: 'POST',
      headers: headerWithAuth,
      body: data,
    };

    fetch(`${uploadDomain}/${uploadPath}`, requestOptions)
      .then(results => results.json())
      .then((returnData) => {
        let errorItemsMsg = '';
        if (returnData.error && returnData.error > 0) {
          const itemList = returnData.errorAsin || returnData.errorSku;
          if (itemList) {
            errorItemsMsg = `List of Error items: \n ${itemList.toString().replace(/,/g, '\n')}`;
          } else {
            errorItemsMsg = 'Error occured before items were procesed.';
          }
        }
        this.setState({ processing: false });
        this.setState({ lastUploadResults: `Success: ${returnData.success} - Error: ${returnData.error} \n ${errorItemsMsg}` });
      }).catch(() => {
        this.setState({ processing: false });
        this.setState({ lastUploadResults: 'Larger files may take longer to upload and will continue procesing asynchronously.' });
      });
  }

  render() {
    const {
      color, btn, title, message, colored, header,
    } = this.props;
    const { modal, processing, lastUploadResults } = this.state;
    let Icon;

    switch (color) {
      case 'primary':
        Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
        break;
      case 'success':
        Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
        break;
      case 'warning':
        Icon = <span className="lnr lnr-flag modal__title-icon" />;
        break;
      case 'danger':
        Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
        break;
      default:
        break;
    }
    const modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });

    // const fileRef = 'fileUpload';

    return (
      <div>
        <Button color={color} onClick={this.toggle}>{btn}</Button>
        <Modal
          isOpen={modal}
          toggle={this.toggle}
          backdrop={false}
          className={`modal-dialog--${color} ${modalClass}`}
        >
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
            {header ? '' : Icon}
            <h4 className="bold-text  modal__title">{title}</h4>
          </div>
          <form>
            <div className="modal__body">
              {message}
              <br />
              <br />
              {!processing && lastUploadResults === '' && (
                <input type="file" accept=".csv" onChange={e => this.handleChange(e.target.files[0])} />
              )}
            </div>
            {!processing && lastUploadResults === '' && (
              <ButtonToolbar className="modal__footer">
                <Button onClick={this.toggle}>Cancel</Button>{' '}
                <Button outline={colored} color={color} onClick={this.processFile}>Ok</Button>
              </ButtonToolbar>
            )}
            {processing && (
              <div>
                <br />
                <LoadingSpinner
                  active={processing}
                />
                <p>Upload in process.  <br /> Please Wait for Upload to complete</p>
              </div>
            )}
            {lastUploadResults !== '' && (
              <div className="modal__body">
                <h4 className="bold-text  modal__title">Last Upload Results:</h4>
                <br />
                <div style={{ height: 75, overflowY: 'auto' }}>
                  {lastUploadResults.split('\n').map(item => (<span>{item}<br /></span>))}
                </div>
                <ButtonToolbar className="modal__footer">
                  <Button onClick={this.completeUpload}>Done</Button>{' '}
                </ButtonToolbar>
              </div>
            )}
          </form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { sellerSelect } = state;
  const { user } = state.authentication;

  return {
    sellerSelect,
    user,
  };
};

export default connect(mapStateToProps)(ModalComponent);
