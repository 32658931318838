import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, Container, Row,
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Panel from '../../../shared/components/Panel';
import { User } from '../../../shared/prop-types/MainProps';
import Modal from '../../../shared/components/Modal';
import config from '../../../config';

const uploadUrl = config.isProdEnv ? config.uploadProdUrl : config.uploadDevUrl;
class FileLoaderPage extends PureComponent {
  static propTypes = {
    user: User.isRequired,
    importMessage: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      processMsg: '',
      submitted: false,
    };
  }


  processFiles = () => {
    // Lock the submit button
    this.setState({
      submitted: true,
    });

    // Do all the processing
    const { user } = this.props;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const requestOptions = {
      method: 'POST',
      headers: headerWithAuth,
    };
    // Use same requestOptions as above
    const batchProcesUrl = `${uploadUrl}/batch-searchTerms`;
    fetch(batchProcesUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((output) => {
        this.setState({
          processMsg: `Process Output: ${JSON.stringify(output)}`,
        });
      }).catch((error) => {
        console.error(error);
        this.setState({
          processMsg: 'Batch Processing Error',
        });
      });
  }

  resetProcess = () => {
    this.setState({
      submitted: false,
      processMsg: '',
    });
  }

  render() {
    const { processMsg, submitted } = this.state;
    const {
      user, importMessage,
    } = this.props;

    const accessControl = JSON.parse(user.access_control);

    // let timestampCheck = '';

    if (!accessControl.batchLoadRole) {
      return (
        <Container>
          <Row>
            <Col md={12}>
              <h3 className="page-title">You do not have access to the page</h3>
              <h3 className="page-subhead subhead">If you should have access to this page, please contact your admin.</h3>
            </Col>
          </Row>
        </Container>
      );
    }

    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">File Loader</h3>
            <h3 className="page-subhead subhead">Status and Load links</h3>
          </Col>
        </Row>
        <Row>
          <Panel
            lg={12}
            md={12}
            title="File Information"
            subhead="Blow is a list of files you can upload with some details."
          >
            <Card>
              <CardBody className="profile__card">
                <p className="profile__current-tasks-title">Amazon Brand Analytics - Top Million Search Terms</p>
                <Row>
                  <Col md={6}>
                    <div style={{ paddingLeft: '40px', paddingTop: '5px' }}>
                      <p><span>NOTES:</span></p>
                      <p>File Type: CSV</p>
                      <p>Database: BigQuery</p>
                      <p>Dataset ID: azba_search_terms</p>
                      <p>Format: azba_search_terms-daily_top_million_{'{YYYYMMDD}'}.csv</p>
                      <p>Example: azba_search_terms-daily_top_million_20191020.csv</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <p><span>Load Individual File (&lt; 32 MB): </span></p>
                    <Modal
                      color="primary"
                      title="Amazon Brand Analytics - Top Million Search Terms"
                      btn="Amazon Brand Analytics - Top Million Search Terms"
                      message={importMessage}
                      refreshData={() => {}}
                      uploadPath="upload-searchTerms"
                    />
                    <p>----------------------------------------------------------------</p>
                    <p><span>Load Files in GCS - search-term-data folder: </span></p>
                    <p>
                      <Row>
                        <Col md={6}>
                          <button type="button" className="btn btn-primary account__btn" onClick={this.processFiles} disabled={submitted}>Process Batch Top Million Search Terms</button>
                        </Col>
                        <Col md={6}>
                          <button type="button" className="btn btn-secondary account__btn" onClick={this.resetProcess} disabled={!submitted}>Reset</button>
                        </Col>
                      </Row>
                    </p>
                    <p>{processMsg}</p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody className="profile__card">
                <p className="profile__current-tasks-title">Vendor Catalog</p>
                <Row>
                  <Col md={6}>
                    <div style={{ paddingLeft: '40px', paddingTop: '5px' }}>
                      <p><span>NOTES:</span></p>
                      <p>File Type: CSV</p>
                      <p>Database: MySQL</p>
                      <p>Table: vendor_catalog</p>
                      <p>Format: {'{SID}-[src|mfg]-catalog-{YYYYMMDD}'}.csv</p>
                      <p>Example: 452-src-catalog-20191020.csv</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <p><span>Load: </span></p>
                    <Modal
                      color="primary"
                      title="Vendor Catalog"
                      btn="Vendor Catalog"
                      message={importMessage}
                      refreshData={() => {}}
                      uploadPath="upload-vendorCatalog"
                    />
                    <p><span>SPECIAL NOTE 1:</span> This process happens off-line and will take about 5 minutes for every 1,000 records.  Please look at slack logs for when process is completed.</p>
                    <p><span>SPECIAL NOTE 2:</span> Do not upload SRC and MFG for the same client at the same time.  Make sure processing is done for one before uploading the other.</p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody className="profile__card">
                <p className="profile__current-tasks-title">Seller Catalog</p>
                <Row>
                  <Col md={6}>
                    <div style={{ paddingLeft: '40px', paddingTop: '5px' }}>
                      <p><span>NOTES:</span></p>
                      <p>This will fast track the following processes:</p>
                      <p>STEP 1 (EST: 30 minutes) - Pulling SP-API Merchanct listing report, requires SP-API account connection.</p>
                      <p>STEP 2 (EST: 30 minutes) - Pull Product Metadata from merchant listing report.</p>
                      <p>STEP 3 (EST: 30 minutes) - Sync Seller Catalog with metadata if Subcategory and Brand are not null and Eligibility Status is &apos;ELIGIBLE&apos;</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <p><span>Load: </span></p>
                    {/* <p><button type="button" onClick={this.pullMerchantListing()}>Pull Merchant Listing Report</button></p>
                    <p><button type="button" onClick={this.pullProductMetadata()}>Pull Product Metadata</button></p>
                    <p><button type="button" onClick={this.syncSellerCatalogMetadata()}>Sync Seller Catalog</button></p> */}
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody className="profile__card">
                <p className="profile__current-tasks-title">APK ASIN</p>
                <Row>
                  <Col md={6}>
                    <div style={{ paddingLeft: '40px', paddingTop: '5px' }}>
                      <p><span>NOTES:</span></p>
                      <p>File Type: CSV</p>
                      <p>Database: BigQuery</p>
                      <p>Dataset ID: raw_data</p>
                      <p>Table ID: apk_asin</p>
                      <p>Format: {'.{YYYY_MM_DD}.{_US}'}.csv</p>
                      <p>Example: afkldjadlkfj_2019_10_20_alkjdfkljd_US.csv</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <p><span>Load: </span></p>
                    <Modal
                      color="primary"
                      title="APK ASIN"
                      btn="APK ASIN"
                      message={importMessage}
                      refreshData={() => {}}
                      uploadPath="upload-apkAsin"
                    />
                    <p>*NOTE: file name must include a date int the format YYYY_MM_DD and the marketplace at the end of the file name _US.csv</p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Panel>
        </Row>
      </Container>
    );
  }
}

// Set default props
FileLoaderPage.defaultProps = {
  importMessage: '',
};

const mapStateToProps = (state) => {
  const { user } = state.authentication;
  return {
    user,
  };
};

export default connect(mapStateToProps)(FileLoaderPage);
