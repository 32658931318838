import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { DateViewOptions } from '../../../../shared/components/widgets/DateViewFilter';

const initialState = {
  startSelectionDate: moment().subtract(34, 'days'),
  endSelectionDate: moment().subtract(4, 'days'),
  accountSelect: {},
  dateView: DateViewOptions.DAY,
  isVendor: false,
  brand: { value: '', label: '** ALL BRANDS **' },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setDates':
      return {
        ...state,
        startSelectionDate: action.payload.start,
        endSelectionDate: action.payload.end,
      };
    case 'setAccount':
      return {
        ...state,
        accountSelect: action.payload,
        isVendor: (action.payload.type === 2),
        brand: { value: '', label: '** ALL BRANDS **' },
      };
    case 'setDateView':
      return {
        ...state,
        dateView: action.payload,
      };
    case 'setPerformanceSelect':
      return {
        ...state,
        performanceSelect: action.payload,
      };
    case 'setBrandFilter':
      return {
        ...state,
        brand: action.payload,
      };
    default:
      throw new Error();
  }
};

const setSelectionDates = (startDate, endDate) => ({ type: 'setDates', payload: { start: startDate, end: endDate } });
const setAccountSelect = account => ({ type: 'setAccount', payload: account });
const setDateView = dateView => ({ type: 'setDateView', payload: dateView });
const setPerformanceSelect = value => ({ type: 'setPerformanceSelect', payload: value });
const setBrandFilter = value => ({ type: 'setBrandFilter', payload: value });

const actions = dispatch => ({
  setDates: (startDate, endDate) => dispatch(setSelectionDates(startDate, endDate)),
  setAccount: account => dispatch(setAccountSelect(account)),
  setDateView: dateView => dispatch(setDateView(dateView)),
  setPerformanceSelect: value => dispatch(setPerformanceSelect(value)),
  setBrandFilter: value => dispatch(setBrandFilter(value)),
});

const DashboardContext = React.createContext(initialState);

export const DashboardContextProvider = (props) => {
  const { children } = props;
  initialState.accountSelect = useSelector(state => state.sellerSelect);
  initialState.isVendor = (initialState.accountSelect.type === 2);
  const [reducerState, dispatch] = React.useReducer(reducer, initialState);
  const reducerActions = actions(dispatch);
  /* eslint-disable-next-line */
  const context = {
    state: { ...reducerState },
    actions: { ...reducerActions },
  };

  return (
    <DashboardContext.Provider value={context}>
      { children }
    </DashboardContext.Provider>
  );
};

export function useDashboardContext() {
  const context = React.useContext(DashboardContext);
  if (context === undefined) {
    throw new Error('useDashboardContext must be used within a DashboardContextProvider');
  }
  return context;
}

DashboardContextProvider.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};
