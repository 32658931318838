import React from 'react';
import { useSelector } from 'react-redux';
import PpcAndOrganicPerformance from './components/Page';
import VendorReportMessage from '../../../../shared/components/widgets/VendorReportMessage';

export default function PpcAndOrganicPerformancePage() {
  const sellerSelect = useSelector(store => store.sellerSelect);

  if (sellerSelect.type === 2) {
    return (
      <PpcAndOrganicPerformance />
    );
  }
  return (
    <VendorReportMessage />
  );
}
