import React from 'react';
import { Container, Row } from 'reactstrap';
import WalmartOnePageLayout from './components/WmOnePageLayout';

const WalmartOnePage = () => (
  <Container className="dashboard">
    <Row>
      <WalmartOnePageLayout />
    </Row>
  </Container>
);

export default WalmartOnePage;
