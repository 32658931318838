import { updateCalendarConstants } from '../../constants';

export function updateCalanderChange(currentState) {
  return {
    type: updateCalendarConstants.UPDATE_CALENDAR, currentState,
  };
}

export function updateCalanderDone(currentState) {
  return {
    type: updateCalendarConstants.UPDATE_CALENDAR_DONE, currentState,
  };
}


// eslint-disable-next-line
export const updateCalendarActions = {
  updateCalanderChange,
  updateCalanderDone,
};
