import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import InventoryReport from './components/InventoryReport';

export default function CampaignReportIndexPage() {
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">DSP Inventory Report</h3>
        </Col>
      </Row>
      <Row>
        <InventoryReport />
      </Row>
    </Container>
  );
}
