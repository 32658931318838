import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import AllCatalogMgr from './components/AllCatalogMgr';

export default function CatalogIndexPage() {
  const sellerSelect = useSelector(state => state.sellerSelect);
  const sellerConfig = {
    title: 'SKU Catalog Manager',
    endpoint: 'sellers/sku-catalog',
    uploadPath: 'replace-sku-master',
  }
  const vendorConfig = {
    title: 'ASIN Catalog Manager',
    endpoint: 'vendors/asin-catalog',
    uploadPath: 'replace-asin-master',
  }

  const accountConfig = (sellerSelect.type === 1) ? sellerConfig : vendorConfig;

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">{accountConfig.title}</h3>
        </Col>
        <Col md={12}>
          <p>
            Status Flags are change flags, not guarenteed to be the current state for all ADs with that ASIN/SKU. A tool that can be used to help manage Ads.  They are curretly only actionable with full catalog download and upload. <br />
            SP and SD Status can be manged indepently if AD Status is ON. AD Status of OFF is OFF for SP and SD by default.<br />
            On upload, the system will look for changes in the Status and compare the reqeust to the currently daily AD data to determie if a action is needed.  If a change is found, it will queue up the change accordling.  This means you can only do one ASIN/SKU update a day.
          </p>
        </Col>
      </Row>
      <Row>
        <AllCatalogMgr accountConfig={accountConfig} />
      </Row>
    </Container>
  );
}
