import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import config from '../../../../../config';
import TableBuilder from '../../../../../shared/components/table/TableBuilder';
import {
  formatFixedTwoPercent, currencyFormatter, formatDecimalToFixedTwoPercent,
} from '../../../../../shared/components/table/functions';
import { SellerSelect } from '../../../../../shared/prop-types/MainProps';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class PpcDataReportPage extends PureComponent {
  static propTypes = {
    sellerSelect: SellerSelect.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        {
          field: 'sku',
          headerName: 'SKU',
        },
        {
          field: 'asin',
          headerName: 'ASIN',
        },
        {
          field: 'brand',
          headerName: 'Brand',
        },
        {
          field: 'segment_one',
          headerName: 'Segment 1',
        },
        {
          field: 'segment_two',
          headerName: 'Segment 2',
          hide: true,
        },
        {
          field: 'segment_three',
          headerName: 'Segment 3',
          hide: true,
        },
        {
          field: 'segment_four',
          headerName: 'Segment 4',
          hide: true,
        },
        {
          field: 'segment_five',
          headerName: 'Segment 5',
          hide: true,
        },
        {
          field: 'number_of_campaigns',
          headerName: '# Of Campaigns',
          aggFunc: 'sum',
        },
        {
          field: 'impressions',
          headerName: 'Impressions',
          aggFunc: 'sum',
        },
        {
          field: 'clicks',
          headerName: 'Clicks',
          aggFunc: 'sum',
        },
        {
          field: 'spend',
          headerName: 'PPC Spend',
          valueFormatter: currencyFormatter,
          aggFunc: 'sum',
        },
        {
          field: 'sales',
          headerName: 'PPC Sales',
          valueFormatter: currencyFormatter,
          aggFunc: 'sum',
        },
        {
          field: 'orders',
          headerName: 'Orders',
          aggFunc: 'sum',
        },
        {
          field: 'units',
          headerName: 'Units',
          aggFunc: 'sum',
        },
        {
          field: 'CALCPacos',
          headerName: 'Total Spend %',
          valueFormatter: formatFixedTwoPercent,
          valueGetter: function calcValueGetter(params) {
            if (params && params.data && params.data.spend && params.data.sales && params.data.sales !== 0) {
              return params.data.spend / params.data.sales;
            }
            return 0;
          },
          headerTooltip: 'PPC ACoS',
        },
        {
          field: 'CALCCpc',
          headerName: 'CPC',
          valueFormatter: currencyFormatter,
          valueGetter: function calcValueGetter(params) {
            if (params && params.data && params.data.spend && params.data.clicks && params.data.clicks !== 0) {
              return params.data.spend / params.data.clicks;
            }
            return 0;
          },
        },
        {
          field: 'CR',
          headerName: 'CR',
          valueFormatter: formatDecimalToFixedTwoPercent,
          valueGetter: function calcValueGetter(params) {
            if (params && params.data && params.data.orders && params.data.clicks && params.data.clicks !== 0) {
              return params.data.orders / params.data.clicks;
            }
            return 0;
          },
        },
        {
          field: 'roas',
          headerName: 'PPC ROAS',
          valueFormatter: currencyFormatter,
          valueGetter: function calcValueGetter(params) {
            if (params && params.data && params.data.sales && params.data.spend && params.data.spend !== 0) {
              return params.data.sales / params.data.spend;
            }
            return 0;
          },
        },
        {
          field: 'CALCSpendPercentOfTotal',
          headerName: 'Spend % of total',
          valueFormatter: formatFixedTwoPercent,
          valueGetter: function calcValueGetter(params) {
            if (params && params.data && params.data.spend && params.data.total_spend && params.data.total_spend !== 0) {
              return (params.data.spend / params.data.total_spend) * 100;
            }
            return 0.00;
          },
        },
      ],
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        cellStyle: (params) => {
          if (params.value < 0.00) {
            return { color: 'red' };
          }
          return null;
        },
      },
      showDatePicker: true,
      showFooter: true,
      enableCharts: true,
      enableRangeSelection: true,
      apiQueryParams: '',
      parentControlledLoad: true,
    };
  }

  componentDidMount() {
    this.refreshData();
  }

  componentDidUpdate(prevState) {
    const { sellerSelect } = this.props;
    if (sellerSelect.value !== prevState.sellerSelect.value) {
      this.refreshData();
    }
  }

  refreshData = () => {
    const { sellerSelect } = this.props;

    const apiQueryParams = `sellerAccountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}&accountType=${sellerSelect.type}&impressionsFilter=1`;

    this.setState({
      apiQueryParams,
    });
  }


  render() {
    const {
      columnDefs, defaultColDef, showDatePicker, showFooter, enableCharts, enableRangeSelection, apiQueryParams, parentControlledLoad,
    } = this.state;

    const apiEndPoint = `${apiUrl}/customers/ppc-report`;

    return (
      <TableBuilder
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        apiEndPoint={apiEndPoint}
        showDatePicker={showDatePicker}
        showFooter={showFooter}
        enableCharts={enableCharts}
        enableRangeSelection={enableRangeSelection}
        apiQueryParams={apiQueryParams}
        parentControlledLoad={parentControlledLoad}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { sellerSelect } = state;

  return {
    sellerSelect,
  };
};

export default connect(mapStateToProps)(PpcDataReportPage);
