import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

/* eslint react/prop-types: 0 */
// eslint-disable-next-line
const PrivateRoute = ({ loggedIn, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      loggedIn
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )}
  />
);

const mapStateToProps = (state) => {
  const { loggedIn } = state.authentication;
  return {
    loggedIn,
  };
};

export default connect(mapStateToProps)(PrivateRoute);
