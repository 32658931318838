import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FilterSelect from '../../../../../shared/components/widgets/NewFilterSelect';
import {
  currencyFormatter, formatNumberDefaultToZero, formatDecimalToFullPercent, formatNumberOnePlace,
} from '../../../../../shared/components/table/functions';

export const MetricOptions = {
  IMPRESSIONS: {
    label: 'Impressions',
    value: 'impressions',
    formatter: formatNumberDefaultToZero,
  },
  CLICKS: {
    label: 'Clicks',
    value: 'clicks',
    formatter: formatNumberDefaultToZero,
  },
  COST: {
    label: 'Total Cost',
    value: 'total_cost',
    formatter: currencyFormatter,
  },
  SALES: {
    label: 'Total Sales',
    value: 'total_sales',
    formatter: currencyFormatter,
  },
  UNITS: {
    label: 'Total Units',
    value: 'total_units',
    formatter: formatNumberDefaultToZero,
  },
  PURCHASES: {
    label: 'Total Purchases',
    value: 'total_purchases',
    formatter: formatNumberDefaultToZero,
  },
  ROAS: {
    label: 'ROAS',
    value: 'roas',
    formatter: (params) => {
      if (params && params.value) {
        return formatNumberOnePlace(params.value);
      }
      return '';
    },
  },
  PACOS: {
    label: 'PACoS',
    value: 'pacos',
    formatter: formatDecimalToFullPercent,
  },
  ATC: {
    label: 'Total Add To Cart',
    value: 'total_add_to_cart',
    formatter: formatNumberDefaultToZero,
  },
  DPV: {
    label: 'Total Detail Page View',
    value: 'total_dpv',
    formatter: formatNumberDefaultToZero,
  },
  DPVV: {
    label: 'Total Detail Page View Views',
    value: 'total_dpv_views',
    formatter: formatNumberDefaultToZero,
  },
  DPVC: {
    label: 'Total Detail Page View Clicks',
    value: 'total_dpv_clicks',
    formatter: formatNumberDefaultToZero,
  },
  * [Symbol.iterator]() {
    yield MetricOptions.IMPRESSIONS;
    yield MetricOptions.CLICKS;
    yield MetricOptions.COST;
    yield MetricOptions.SALES;
    yield MetricOptions.UNITS;
    yield MetricOptions.PURCHASES;
    yield MetricOptions.ROAS;
    yield MetricOptions.PACOS;
    yield MetricOptions.ATC;
    yield MetricOptions.DPV;
    yield MetricOptions.DPVV;
    yield MetricOptions.DPVC;
  },
};

export default function MetricFilter({
  disable, accountId, value, setValue,
}) {
  // useEffect(() => {
  //   if (value) {
  //     setValue(value);
  //   }
  // }, [value]);

  useEffect(() => { // reset on account change
    if (accountId) {
      setValue(MetricOptions.IMPRESSIONS);
    }
  }, [accountId]);

  return (
    <FilterSelect
      label="Metric Option:"
      onChange={option => setValue(option)}
      name="metricOption"
      options={[...MetricOptions]}
      selected={value}
      disable={disable}
    />
  );
}

MetricFilter.defaultProps = {
  disable: false,
};

MetricFilter.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.oneOf([...MetricOptions]).isRequired,
  accountId: PropTypes.number.isRequired,
  disable: PropTypes.bool,
};
