import React, { PureComponent } from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import config from '../../../../config';
import TableBuilder from '../../../../shared/components/table/TableBuilder';
import LeftMiddleRightBox from '../../../../shared/components/widgets/LeftMiddleRightBox';
import {
  currencyFormatter, formatNumberDefaultToZero, formatDecimalToFixedTwoPercent, getExportPrefix,
} from '../../../../shared/components/table/functions';
import { imageFieldColumn, imageCellRendererComponent } from '../../../../shared/components/table/ImageCellRenderer';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

function numberValueParser(params) {
  return Number(params.newValue);
}

class VendorSaleSummaryTable extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        imageFieldColumn,
        { field: 'asin', headerName: 'ASIN' },
        { field: 'sku', headerName: 'SKU' },
        { field: 'parent_asin', headerName: 'Parent ASIN' },
        { field: 'product_title', headerName: 'Product Title' },
        { field: 'brand', headerName: 'Brand' },
        { field: 'category', headerName: 'Category' },
        { field: 'subcategory', headerName: 'Subcategory' },
        { field: 'segment_one', headerName: 'Segment 1' },
        { field: 'segment_two', headerName: 'Segment 2' },
        { field: 'segment_three', headerName: 'Segment 3' },
        { field: 'segment_four', headerName: 'Segment 4' },
        { field: 'segment_five', headerName: 'Segment 5' },
        {
          field: 'list_price', // Pull from Vendor Catalog
          headerName: 'List Price',
          valueFormatter: currencyFormatter,
        },
        { field: 'impressions', headerName: 'Impressions' }, // Product Ad
        {
          field: 'clicks', // Product Ad
          headerName: 'Clicks',
        },
        {
          field: 'spend', // Product Ad
          headerName: 'PPC Spend',
          valueFormatter: currencyFormatter,
        },
        {
          field: 'sales', // Product Ad
          headerName: 'PPC Sales',
          valueFormatter: currencyFormatter,
        },
        {
          field: 'ordered_revenue', // Product Ad
          headerName: 'Ordered Revenue',
          valueFormatter: currencyFormatter,
        },
        {
          field: 'units', // Product Ad
          headerName: 'PPC Units',
          valueFormatter: formatNumberDefaultToZero,
        },
        {
          field: 'CALCPpcShipppedCogs',
          headerName: 'PPC Shipped COGS',
          valueFormatter: currencyFormatter,
          valueGetter: function calcShippedCogsValueGetter(params) {
            if (params && params.data && params.data.sourcing_shipped_cogs && params.data.units && params.data.sourcing_shipped_units && params.data.sourcing_shipped_units !== 0) {
              return (params.data.sourcing_shipped_cogs / params.data.sourcing_shipped_units) * params.data.units;
            }
            return 0;
          },
        },
        {
          field: 'sourcing_shipped_cogs',
          headerName: 'Shipped COGS (src)',
          valueFormatter: currencyFormatter,
        },
        {
          field: 'sourcing_shipped_units',
          headerName: 'Shipped Units (Src)',
          valueFormatter: formatNumberDefaultToZero,
          valueParser: numberValueParser,
        },
        {
          field: 'shipped_units',
          headerName: 'Shipped Units (Mfg)',
          valueFormatter: formatNumberDefaultToZero,
          valueParser: numberValueParser,
        },
        {
          field: 'ordered_units',
          headerName: 'Ordered Units',
          valueFormatter: formatNumberDefaultToZero,
          valueParser: numberValueParser,
        },
        {
          field: 'lbb',
          headerName: 'LBB (Price)',
          valueFormatter: formatDecimalToFixedTwoPercent,
        },
        {
          field: 'CALCVendorPacos',
          headerName: 'PACoS',
          valueGetter: function calcPacosValueGetter(params) {
            if (params && params.data && params.data.spend && params.data.sales && params.data.sales !== 0) {
              return (params.data.spend / params.data.sales);
            }
            return 0;
          },
          valueFormatter: formatDecimalToFixedTwoPercent,
        },
        {
          field: 'CALCVendorTacos',
          headerName: 'TACoS',
          valueGetter: function calcTacosValueGetter(params) {
            if (params && params.data && params.data.spend && params.data.ordered_revenue && params.data.ordered_revenue !== 0) {
              return (params.data.spend / params.data.ordered_revenue);
            }
            return 0;
          },
          valueFormatter: formatDecimalToFixedTwoPercent,
        },
        {
          field: 'CALCVendorPpcRoas',
          headerName: 'PPC ROAS',
          valueGetter: function calcRoasValueGetter(params) {
            if (params && params.data && params.data.sales && params.data.spend && params.data.spend !== 0) {
              return (params.data.sales / params.data.spend);
            }
            return 0;
          },
          valueFormatter: currencyFormatter,
        },
        {
          field: 'CALCVendorRoas',
          headerName: 'Total ROAS',
          valueGetter: function calcRoasValueGetter(params) {
            if (params && params.data && params.data.ordered_revenue && params.data.spend && params.data.spend !== 0) {
              return (params.data.ordered_revenue / params.data.spend);
            }
            return 0;
          },
          valueFormatter: currencyFormatter,
        },
        {
          field: 'bacos',
          headerName: 'BACoS',
          valueFormatter: formatDecimalToFixedTwoPercent,
        },
      ],
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        cellStyle: (params) => {
          if (params.value < 0.00) {
            return { color: 'red' };
          }
          return null;
        },
      },
      frameworkComponents: {
        ...imageCellRendererComponent,
      },
      showDatePicker: true,
      showFooter: true,
      resultData: {},
      initalLoad: true,
      pdOptions: {
        showButton: true,
        columnName: 'asin',
      },
    };
  }

  getGroupRowAgg = (params) => {
    const result = {
      sales: 0,
      spend: 0,
      clicks: 0,
      impressions: 0,
      pacos: 0,
      sourcing_shipped_cogs: 0,
      sourcing_shipped_units: 0,
      shipped_units: 0,
      ordered_units: 0,
      CALCVendorPacos: 0,
      listPrice: 0,
      list_price: 0,
      CALCVendorTacos: 0,
      CALCVendorRoas: 0,
      CALCVendorPpcRoas: 0,
      CALCPpcShipppedCogs: 0,
      units: 0,
      ordered_revenue: 0,
    };
    let loading = true;
    let listPriceCount = 0;
    params.nodes.forEach((node) => {
      const data = node.group ? node.aggData : node.data;
      if (typeof data.sales === 'number') {
        result.sales += data.sales;
      }
      if (typeof data.spend === 'number') {
        result.spend += data.spend;
      }
      if (typeof data.clicks === 'number') {
        result.clicks += data.clicks;
      }
      if (typeof data.impressions === 'number') {
        result.impressions += data.impressions;
      }
      if (typeof data.sourcing_shipped_cogs === 'number') {
        result.sourcing_shipped_cogs += data.sourcing_shipped_cogs;
      }
      if (typeof data.sourcing_shipped_units === 'number') {
        result.sourcing_shipped_units += data.sourcing_shipped_units;
      }
      if (typeof data.shipped_units === 'number') {
        result.shipped_units += data.shipped_units;
      }
      if (typeof data.ordered_units === 'number') {
        result.ordered_units += data.ordered_units;
      }
      if (typeof data.list_price === 'number') {
        result.listPrice += data.list_price;
        listPriceCount += 1;
      }
      if (typeof data.units === 'number') {
        result.units += data.units;
      }
      if (typeof data.ordered_revenue === 'number') {
        result.ordered_revenue += data.ordered_revenue;
      }
      if (typeof data.units === 'number' && typeof data.sourcing_shipped_cogs === 'number' && typeof data.sourcing_shipped_units === 'number' && data.sourcing_shipped_units !== 0) {
        result.CALCPpcShipppedCogs += (data.sourcing_shipped_cogs / data.sourcing_shipped_units) * data.units;
      }
    });

    if (listPriceCount !== 0) {
      result.list_price = result.listPrice / listPriceCount;
    }

    if (result.sales !== 0) {
      result.pacos = result.spend / result.sales;
      result.CALCVendorPacos = result.pacos;
    }

    if (result.spend !== 0) {
      result.CALCVendorPpcRoas = result.sales / result.spend;
      result.CALCVendorRoas = result.ordered_revenue / result.spend;
    }

    if (result.ordered_revenue !== 0) {
      result.CALCVendorTacos = (result.spend / result.ordered_revenue);
    }

    // Fixes bug that left loading spinners running infinitely
    // We come through here a couple of times, once we have set some of the fields,
    // we should no longer consider the data loading
    loading = !Object.keys(result).some(item => (result[item] > 0));
    this.setState({
      resultData: result,
      initalLoad: loading,
    });

    return result;
  }

  render() {
    const {
      columnDefs, defaultColDef, onCellEditingStopped, showDatePicker, showFooter, resultData, initalLoad, pdOptions, frameworkComponents,
    } = this.state;
    const { sellerSelect } = this.props;

    const apiEndPoint = `${apiUrl}/vendors/sales-summary`;
    const exportPrefix = getExportPrefix('amz-sales-summary', sellerSelect);

    const pivotButtons = [
      { buttonName: 'ASIN', buttonAction: '' },
      { buttonName: 'Parent ASIN', buttonAction: 'parent_asin' },
      { buttonName: 'Brand', buttonAction: 'brand' },
      { buttonName: 'Category', buttonAction: 'category' },
      { buttonName: 'Subcategory', buttonAction: 'subcategory' },
      { buttonName: 'Segment 1', buttonAction: 'segment_one' },
      { buttonName: 'Segment 2', buttonAction: 'segment_two' },
      { buttonName: 'Segment 3', buttonAction: 'segment_three' },
      { buttonName: 'Segment 4', buttonAction: 'segment_four' },
      { buttonName: 'Segment 5', buttonAction: 'segment_five' },
    ];

    return (
      <Col>
        <Row>
          <LeftMiddleRightBox
            content={{
              title: 'PPC Spend',
              left_header: '',
              left_data: '',
              middle_header: '',
              middle_data: currencyFormatter({ value: resultData.spend }),
              right_header: '',
              right_data: '',
            }}
            loadingData={initalLoad}
            lgWidth={4}
          />
          <LeftMiddleRightBox
            content={{
              title: 'PPC ACoS',
              left_header: '',
              left_data: '',
              middle_header: '',
              middle_data: formatDecimalToFixedTwoPercent({ value: resultData.pacos }),
              right_header: '',
              right_data: '',
            }}
            loadingData={initalLoad}
            lgWidth={4}
          />
          <LeftMiddleRightBox
            content={{
              title: 'Total ACoS',
              left_header: '',
              left_data: '',
              middle_header: '',
              middle_data: formatDecimalToFixedTwoPercent({ value: resultData.CALCVendorTacos }),
              right_header: '',
              right_data: '',
            }}
            loadingData={initalLoad}
            lgWidth={4}
          />
        </Row>
        <Row>
          <LeftMiddleRightBox
            content={{
              title: 'PPC Sales',
              left_header: '',
              left_data: '',
              middle_header: '',
              middle_data: currencyFormatter({ value: resultData.sales }),
              right_header: '',
              right_data: '',
            }}
            loadingData={initalLoad}
            lgWidth={4}
          />
          <LeftMiddleRightBox
            content={{
              title: 'PPC ROAS',
              left_header: '',
              left_data: '',
              middle_header: '',
              middle_data: currencyFormatter({ value: resultData.CALCVendorPpcRoas }),
              right_header: '',
              right_data: '',
            }}
            loadingData={initalLoad}
            lgWidth={4}
          />
          <LeftMiddleRightBox
            content={{
              title: 'Total ROAS (TROAS)',
              left_header: '',
              left_data: '',
              middle_header: '',
              middle_data: currencyFormatter({ value: resultData.CALCVendorRoas }),
              right_header: '',
              right_data: '',
            }}
            loadingData={initalLoad}
            lgWidth={4}
          />
        </Row>
        <Row>
          <TableBuilder
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            apiEndPoint={apiEndPoint}
            onCellEditingStopped={onCellEditingStopped}
            showDatePicker={showDatePicker}
            showFooter={showFooter}
            getGroupRowAgg={this.getGroupRowAgg}
            pivotButtons={pivotButtons}
            productDetailOptions={pdOptions}
            frameworkComponents={frameworkComponents}
            rowHeight={75}
            autoSizeAll
            defaultStartDays={32}
            defaultEndDays={3}
            exportPrefix={exportPrefix}
          />
        </Row>
      </Col>
    );
  }
}

const mapStateToProps = (state) => {
  const { sellerSelect } = state;

  return {
    sellerSelect,
  };
};

export default connect(mapStateToProps)(VendorSaleSummaryTable);
