import React, { useState } from 'react';
// import { useSelector } from 'react-redux';
import {
  Container, Row, Col,
} from 'reactstrap';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Panel from '../../../shared/components/Panel';
import { SiteImage } from '../../../shared/components/domainSupport';
import PaymentStatus from './Plan/components/PaymentStatus';
import UpdatePaymentStatus from './Plan/components/UpdatePaymentStatus';
import { stripeKey } from './config';
import LoadingSpinner from '../../../shared/components/widgets/LoadingSpinner';

const stripePromise = loadStripe(stripeKey);

export default function AdRevUpgradeStatusPage({ action }) {
  const [checkingPayment] = useState(false);
  const [loading, setLoading] = useState(true);

  const headerText = action === 'paymentMethodUpdate' ? 'Payment Update Status' : 'Upgrade Status';
  const workingText = action === 'paymentMethodUpdate' ? 'Updating Payement Method....' : 'Unlocking Subscription....';

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Panel
            showButtons={false}
          >
            <Row>
              <div style={{
                display: 'flex', margin: 'auto', maxWidth: '1000px',
              }}
              >
                <SiteImage />
              </div>
            </Row>
            <Row>
              &nbsp;
            </Row>
            <Row>
              <Col>
                <div style={{ textAlign: 'center' }}>
                  <h3>{headerText}</h3>
                </div>
              </Col>
            </Row>
            <Row>
              &nbsp;
            </Row>
          </Panel>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Panel
            showButtons={false}
            parentRefresh={checkingPayment}
          >
            <Row>
              <Col md={12}>
                <div style={{ textAlign: 'center', height: '250px' }} id="startUpgrade">
                  {workingText}
                  <br />
                  <br />
                  <LoadingSpinner active={loading} />
                  <Elements stripe={stripePromise}>
                    {action === 'paymentMethodUpdate' ? <UpdatePaymentStatus setLoading={setLoading} /> : <PaymentStatus setLoading={setLoading} />}
                  </Elements>
                  <br />
                  <span className="lnr lnr-magic-wand" style={{ color: '#91C753', fontSize: '200px', verticalAlign: 'middle' }} />
                  <br />
                  <br />
                </div>
              </Col>
            </Row>
          </Panel>
        </Col>
      </Row>
    </Container>
  );
}

