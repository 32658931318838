import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import moment from 'moment';
import BudgetPacing from './components/BudgetPacing';

function useBudgetSettings() {
  let start = moment().startOf('month');
  let end = moment().endOf('month');
  let title = '';

  const now = moment();
  if (now.get('date') === 1) {
    const month = now.month() > 0 ? now.month() - 1 : 11;
    const year = now.month() > 0 ? now.year() : now.year() - 1;
    start = moment().month(month).year(year).startOf('month');
    end = moment().month(month).year(year).endOf('month');
  }

  title = ` - ${start.format('MMMM YYYY')}`;

  return {
    start,
    end,
    title,
  };
}

const BudgetPacingPage = () => {
  const { start, end, title } = useBudgetSettings();

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Budget Pacing{ title }</h3>
        </Col>
      </Row>
      <Row>
        <BudgetPacing startDate={start} endDate={end} />
      </Row>
    </Container>
  );
};

export default BudgetPacingPage;
