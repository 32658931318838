import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import Panel from '../Panel';
// import {
//   formatNumberDefaultToZero,
// } from '../table/functions';

// const getDelta = (inValue) => {
//   if (inValue > 0) {
//     return (<span style={{ color: '#03c03c' }}>{formatNumberDefaultToZero({ value: inValue })} {String.fromCharCode(8679)}</span>);
//   }
//   if (inValue < 0) {
//     return (<span style={{ color: '#d04960' }}>{formatNumberDefaultToZero({ value: inValue })} {String.fromCharCode(8681)}</span>);
//   }
//   return (<span>{formatNumberDefaultToZero({ value: inValue })} {String.fromCharCode(8691)}</span>);
// };


const MetricLine = ({
  content, loadingData, lgWidth, data,
}) => (
  <Panel lg={12} md={lgWidth} title={content.title} parentRefresh={loadingData}>
    <Table responsive className="table--bordered">
      <thead>
        <tr>
          <th>{content.metricHeader}</th>
          <th style={{ textAlign: 'center' }}>{content.metricRangeOne}</th>
          <th style={{ textAlign: 'center' }}>{content.metricPerformanceHeader}</th>
          <th style={{ textAlign: 'center' }}>{content.metricRangeTwo}</th>
        </tr>
      </thead>
      <tbody>
        {data && data.map(line => (
          <tr>
            <td>{line.metric}</td>
            <td style={{ textAlign: 'center' }}>{line.rangeOne}</td>
            <td style={{ textAlign: 'center' }}>{line.performance}</td>
            <td style={{ textAlign: 'center' }}>{line.rangeTwo}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  </Panel>
);

MetricLine.defaultProps = {
  lgWidth: 6,
};

MetricLine.propTypes = {
  // eslint-disable-next-line
  content: PropTypes.object.isRequired,
  // eslint-disable-next-line
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadingData: PropTypes.bool.isRequired,
  lgWidth: PropTypes.number,
};

export default MetricLine;
