import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ActivityManagementTable from './components/ActivityManagementTable';

const ActivityManagementTablePage = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Activity Manager</h3>
        <h3 className="page-subhead subhead">Activity Items By Date Descending</h3>
      </Col>
    </Row>
    <Row>
      <ActivityManagementTable />
    </Row>
  </Container>
);

export default ActivityManagementTablePage;
