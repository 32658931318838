import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const CustomerRoute = ({
  loggedIn, user, sellerSelect, component: Component, ...rest
}) => {
  const agencyData = JSON.parse(sellerSelect.agency || '{}');
  const agency = agencyData.code || 'limitless';
  const paidPlans = ['BASIC', 'GROWTH', 'PRO', 'FLEX'];
  const openUris = [
    '/customer/onboarding/ad-api/start',
    '/customer/onboarding/sp-api/start',
  ];

  return (
    <Route
      {...rest}
      render={props => (
        (loggedIn && (user.access === 'admin' || agency !== 'adrev' || openUris.includes(props.location?.pathname) || (paidPlans.includes((sellerSelect.plan || 'TRIAL').toUpperCase()) && (sellerSelect.planStatus || '').toLowerCase().startsWith('active'))))
          ? <Component {...props} />
          : <Redirect to={{ pathname: '/subscription/plan', state: { from: props.location } }} />
      )}
    />
  );
};

const mapStateToProps = (state) => {
  const { loggedIn, user } = state.authentication;
  const { sellerSelect } = state;
  return {
    loggedIn,
    user,
    sellerSelect,
  };
};

export default connect(mapStateToProps)(CustomerRoute);
