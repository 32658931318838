import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import { AccountSettingGroupShape } from './services';
import {
  fieldStyle,
  useInputText,
} from '../../../../../helpers';

export default function EditSettingGroup({
  item, toggleEdit, doSave,
}) {
  const { value: editKey, bind: bindKey, reset: resetKey } = useInputText(item.settingGroupKey || '');
  const { value: editValue, bind: bindValue, reset: resetValue } = useInputText(item.settingGroupValue || '');
  const { value: editAccessControl, bind: bindAccessControl, reset: resetAccessControl } = useInputText(item.settingGroupAccessControl || '');

  const doReset = () => {
    resetKey();
    resetValue();
    resetAccessControl();
  };

  const doCancel = (event) => {
    event.preventDefault();
    doReset();
    toggleEdit(item);
  };

  const performSave = event => doSave(event, {
    settingGroupId: item.settingGroupId,
    settingGroupKey: editKey,
    settingGroupValue: editValue,
    settingGroupAccessControl: editAccessControl,
  });

  return (
    <Fragment>
      <td style={fieldStyle}><Input type="text" name="settingGroupKey" placeholder="" {...bindKey} /></td>
      <td style={fieldStyle}><Input type="text" name="settingGroupValue" placeholder="" {...bindValue} /></td>
      <td style={fieldStyle}><Input type="text" name="settingGroupAccessControl" placeholder="" {...bindAccessControl} /></td>
      <td style={fieldStyle}>
        <button type="button" className="badge badge-success badge-btn" onClick={event => performSave(event)}>Save</button>
        <span>&nbsp;&nbsp;</span>
        <button type="button" className="badge badge-warning badge-btn" onClick={doReset}>Reset</button>
        <span>&nbsp;&nbsp;</span>
        <button type="button" className="badge badge-warning badge-btn" onClick={event => doCancel(event)}>Cancel</button>
      </td>
    </Fragment>
  );
}

EditSettingGroup.propTypes = {
  item: PropTypes.shape(AccountSettingGroupShape).isRequired,
  toggleEdit: PropTypes.func.isRequired,
  doSave: PropTypes.func.isRequired,
};
