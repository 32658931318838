import React from 'react';
import styled from 'styled-components';

const ToggleWrapOuter = styled.div`
display: flex;
align-items: center;

& > div {
  width: auto;
}
`;

const ToggleSlide = ({ name, setFunction, currentState, bgColor }) => {
  const togglePosition = !currentState ? 'left' : 'right';
  const ToggleLabel = styled.button`
    cursor: pointer;
    text-indent: -9999px;
    width: 40px;
    height: 20px;
    display: block;
    border-radius: 23px;
    position: relative;
    margin-bottom: 0;
    border: none;
    background-color: #899499;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      ${togglePosition}: 0;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      transition: 0.3s;
      box-shadow: 0 5px 10px rgba(153, 153, 153, 0.4);
      background-color: #ffffff;
    }
  `;

  const ToggleWrap = styled.div`
    justify-content: flex-start;
    display: inline-flex;
    width: 100%;
    padding: 4px 0;
    margin-bottom: 0;
    cursor: pointer;
    color: #646777;

    input {
      height: 0;
      width: 0;
      visibility: hidden;
      
      &:checked ~ ${ToggleLabel} {

        &:after {
          background: ${bgColor};
          // doesn't do RTL, it break toggles
          left: 100%;
          transform: translateX(-100%);
        }
      }
    }
  `;

  return (
    <>
      {name}: &nbsp;&nbsp;
      <ToggleWrap>
        <input name={name} type="checkbox" checked={currentState} onChange={setFunction} />
        <ToggleLabel
          type="button"
          onClick={setFunction}
        >
          Toggle {name}
        </ToggleLabel>
      </ToggleWrap>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </>
  );
}

export {
  ToggleWrapOuter,
  ToggleSlide,
};
