import { sellerSelectConstants } from '../../constants';
import { getLocalStorage, setLocalStorage } from '../../shared/storage';

const initialState = {
  value: 0,
  label: '',
  type: 0,
  currencySymbol: '$',
  currencyCode: 'USD',
  parentAccountId: 0,
  mpName: 'US',
  agency: '', // This is a object in a string, needs to be Parsed out if used.
  platform: '',
  subscriptionId: 0,
  plan: '',
  planStatus: '',
  externalCustomerId: '',
  externalAccountId: '',
  customerId: 0,
  refreshKey: 0,
  ...JSON.parse(getLocalStorage('sellerSelectOption')),
};

export default function sellerSelect(state = initialState, action) {
  const currentAccount = {
    value: action.sellerId,
    label: action.sellerName,
    type: action.sellerType,
    currencySymbol: action.currencySymbol,
    currencyCode: action.currencyCode,
    parentAccountId: action.parentAccountId,
    mpName: action.mpName,
    agency: action.agency,
    platform: action.platform,
    subscriptionId: action.subscriptionId,
    plan: action.plan,
    planStatus: action.planStatus,
    externalCustomerId: action.externalCustomerId,
    externalAccountId: action.externalAccountId,
    customerId: action.customerId,
    refreshKey: action.refreshKey,
  };
  switch (action.type) {
    case sellerSelectConstants.SELLER_SELECTED:
      setLocalStorage(
        'sellerSelectOption',
        JSON.stringify(currentAccount),
      );
      return currentAccount;
    case sellerSelectConstants.SELLER_SET_DEFAULT:
      return currentAccount;
    case sellerSelectConstants.SELLER_RESET:
      return {};
    default:
      return state;
  }
}
