import React, { PureComponent } from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  ComposedChart, Line, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend, CartesianGrid, ReferenceLine, LineChart,
} from 'recharts';
import config from '../../../../configWrapper';
import TableBuilder from '../../../../shared/components/table/TableBuilder';
import LeftMiddleRightBox from '../../../../shared/components/widgets/LeftMiddleRightBox';
import {
  currencyFormatter, formatDecimalToFixedTwoPercent, toDisplayDate,
} from '../../../../shared/components/table/functions';
import { SellerSelect } from '../../../../shared/prop-types/MainProps';
import Panel from '../../../../shared/components/Panel';

const apiUrl = config.serverUrl;

class BudgetPacingTable extends PureComponent {
  static propTypes = {
    sellerSelect: SellerSelect.isRequired,
    startDate: PropTypes.instanceOf(moment).isRequired,
    endDate: PropTypes.instanceOf(moment).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        {
          field: 'report_date',
          headerName: 'Date',
          valueFormatter: toDisplayDate,
        },
        {
          field: 'spend',
          headerName: 'Budget Spend',
          valueFormatter: currencyFormatter,
        },
        {
          field: 'percent_of_budget',
          headerName: '% of Total',
          valueFormatter: formatDecimalToFixedTwoPercent,
        },
        {
          field: 'sales',
          headerName: 'PPC Sales',
          valueFormatter: currencyFormatter,
        },
        {
          field: 'acos',
          headerName: 'PPC ACoS',
          valueFormatter: formatDecimalToFixedTwoPercent,
        },

      ],
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        cellStyle: (params) => {
          if (params.value < 0.00) {
            return { color: 'red' };
          }
          return null;
        },
      },
      showDatePicker: false,
      showFooter: false,
      totalData: {},
      initalLoad: true,
      autoSizeToFit: true,
      apiQueryParams: null,
      lineData: [],
      remainingDailyBudget: 0,
    };
  }

  componentDidMount() {
    this.refreshData();
  }

  componentDidUpdate(prevState) {
    const { sellerSelect } = this.props;
    if (sellerSelect.value !== prevState.sellerSelect.value) {
      this.refreshData();
    }
  }

  parentSetData = (data) => {
    const { startDate, endDate } = this.props;
    const lineData = [];
    const dailies = data.tableData;
    if (dailies.length > 0) {
      dailies.forEach((day) => {
        const dayData = {
          ...day,
          report_date: moment(day.report_date).format('Y MMM D'),
        };
        lineData.push(dayData);
      });
    }
    const daysOfResults = lineData.length;
    const daysThisMonth = startDate.daysInMonth();
    const daysRemaining = daysThisMonth - daysOfResults;
    let projectedDailySpend = 0;
    if (data.totalData.totalRemaining > 0 && daysRemaining > 0) {
      projectedDailySpend = (data.totalData.totalRemaining / daysRemaining);
    }

    // We pushed all the daily data we have into lineData, now fill in the remaining days with available budget
    for (let m = startDate.clone().add(daysOfResults, 'days'); m.isSameOrBefore(endDate); m.add(1, 'days')) {
      lineData.push({
        report_date: m.format('Y MMM D'),
        total_daily_projected_spend: projectedDailySpend,
        remainingBudget: data.tableData.remainingBudget,
      });
    }

    this.setState({
      totalData: data.totalData,
      lineData,
      initalLoad: false,
      remainingDailyBudget: projectedDailySpend,
    });
  }

  refreshData = () => {
    const { sellerSelect, startDate, endDate } = this.props;
    const apiQueryParams = `sellerAccountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}&startDate=${startDate.format('YYYYMMDD')}&endDate=${endDate.format('YYYYMMDD')}`;

    this.setState({
      apiQueryParams,
    });
  }

  render() {
    const {
      columnDefs, defaultColDef, showDatePicker, showFooter, totalData, initalLoad, autoSizeToFit, apiQueryParams, lineData, remainingDailyBudget,
    } = this.state;

    const { startDate, endDate } = this.props;
    const budgetSubtitle = startDate.format('MMMM');
    const burndownSubtitle = endDate.format('MMMM');
    const apiEndPoint = `${apiUrl}/vendors/budget-pacing`;
    const { totalSales, totalSpend } = totalData;
    const acos = totalSales > 0 ? totalSpend / totalSales : 0;

    return (
      <Col xs={12}>
        <Row>
          <LeftMiddleRightBox
            content={{
              title: 'Remaining Total Budget',
              left_header: '',
              left_data: '',
              middle_header: '',
              middle_data: currencyFormatter({ value: totalData.totalRemaining }),
              right_header: '',
              right_data: '',
            }}
            loadingData={initalLoad}
            lgWidth={4}
          />
          <LeftMiddleRightBox
            content={{
              title: 'Remaining Daily Budget',
              left_header: '',
              left_data: '',
              middle_header: '',
              middle_data: currencyFormatter({ value: remainingDailyBudget }),
              right_header: '',
              right_data: '',
            }}
            loadingData={initalLoad}
            lgWidth={4}
          />
          <LeftMiddleRightBox
            content={{
              title: 'PPC Sales',
              left_header: '',
              left_data: '',
              middle_header: '',
              middle_data: currencyFormatter({ value: totalData.totalSales }),
              right_header: '',
              right_data: '',
            }}
            loadingData={initalLoad}
            lgWidth={4}
          />
        </Row>
        <Row>
          <LeftMiddleRightBox
            content={{
              title: 'Monthly Budget',
              left_header: '',
              left_data: '',
              middle_header: '',
              middle_data: currencyFormatter({ value: totalData.monthly_target }),
              right_header: '',
              right_data: '',
            }}
            loadingData={initalLoad}
            lgWidth={4}
          />
          <LeftMiddleRightBox
            content={{
              title: 'Spend MTD',
              left_header: '',
              left_data: '',
              middle_header: '',
              middle_data: currencyFormatter({ value: totalData.totalSpend }),
              right_header: '',
              right_data: '',
            }}
            loadingData={initalLoad}
            lgWidth={4}
          />
          <LeftMiddleRightBox
            content={{
              title: 'PPC Acos',
              left_header: '',
              left_data: '',
              middle_header: '',
              middle_data: formatDecimalToFixedTwoPercent({ value: acos }),
              right_header: '',
              right_data: '',
            }}
            loadingData={initalLoad}
            lgWidth={4}
          />
        </Row>
        <Row>
          <Panel
            md={12}
            title="Daily Spend"
            subhead={`${budgetSubtitle}`}
            parentRefresh={initalLoad}
          >
            <ResponsiveContainer height={300} className="dashboard__area">
              <ComposedChart
                width={600}
                height={300}
                data={lineData}
                barSize={20}
                margin={{
                  top: 20, right: 20, bottom: 20, left: 20,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="report_date" tickLine={false} />
                <YAxis yAxisId="left" type="number" />
                <YAxis yAxisId="right" orientation="right" type="number" />
                <Tooltip formatter={value => currencyFormatter({ value })} />
                <Legend />
                <Bar dataKey="sp.spend" name="Sponsored Products" yAxisId="left" stackId="spend" fill="#d04960" />
                <Bar dataKey="sb.spend" name="Sponsored Brands" yAxisId="left" stackId="spend" fill="#03c03c" />
                <Bar dataKey="sd.spend" name="Sponsored Display" yAxisId="left" stackId="spend" fill="#fbb054" />
                <Bar dataKey="sbv.spend" name="Sponsored Brands Video" yAxisId="left" stackId="spend" fill="#36c9c9" />
                <Bar dataKey="total_daily_projected_spend" name="Remaining Daily Budget" yAxisId="left" stackId="b" fill="#9a9a9a" />
              </ComposedChart>
            </ResponsiveContainer>
          </Panel>
        </Row>
        <Row>
          <Panel
            md={12}
            title="Budget Burndown"
            subhead={`${burndownSubtitle}`}
            parentRefresh={initalLoad}
          >
            <ResponsiveContainer height={300} className="dashboard__area">
              <LineChart
                width={600}
                height={300}
                data={lineData}
                margin={{
                  top: 20, right: 20, bottom: 20, left: 20,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="report_date" tickLine={false} />
                <YAxis type="number" />
                <Tooltip formatter={value => currencyFormatter({ value })} />
                <Legend />
                <Line dot dataKey="remainingBudget" name="Remaining Budget" strokeWidth={2} />
                <ReferenceLine y={0} />
              </LineChart>
            </ResponsiveContainer>
          </Panel>
        </Row>
        <Row>
          <TableBuilder
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            apiEndPoint={apiEndPoint}
            showDatePicker={showDatePicker}
            showFooter={showFooter}
            autoSizeToFit={autoSizeToFit}
            parentSetData={this.parentSetData}
            apiQueryParams={apiQueryParams}
          />
        </Row>
      </Col>
    );
  }
}

const mapStateToProps = (state) => {
  const { sellerSelect } = state;

  return {
    sellerSelect,
  };
};

export default connect(mapStateToProps)(BudgetPacingTable);
