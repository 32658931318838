import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import config from '../../../config';
import { doFetch } from '../../../helpers';
import StreamsSettingsTable from './components/StreamsSettingsTable';
import StreamsTable from './components/StreamsTable';
import Notify from '../../../shared/components/widgets/Notify';
import CardPanel from '../../../shared/components/widgets/CardPanel';

const AccountTable = () => {
  const selectedSeller = useSelector(store => store.sellerSelect);
  const user = useSelector(store => store.authentication.user);
  const theme = useSelector(store => store.theme);
  const gridRef = useRef();
  const [accountSettings, setAccountSetttings] = useState([]);
  const [entityId, setEntityId] = useState('');
  const [subscriptions, setSubscriptions] = useState([]);
  const [listEntities, setListEntities] = useState([]);
  const [dataSetsCsv, setDataSetsCsv] = useState('');
  const [successRefresh, setSuccessRefresh] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const gridTheme = theme.className.endsWith('light') ? 'ag-theme-balham' : 'ag-theme-balham-dark';

  useEffect(() => {
    setEntityId('');
    setAccountSetttings([]);
    setSubscriptions([]);
    setListEntities([]);
    setLoading(true);
    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    const url = `${config.serverUrl}/accounts/list-sqs-subs?accountId=${selectedSeller.value}`;
    try {
      doFetch(url, requestOptions).then(data => {
        if (data.accountSettingEntity && data.accountSettingEntity.length === 1) {
          setEntityId(data.accountSettingEntity[0].setting_value);
        }

        setAccountSetttings(data.accountSetting || []);
        setSubscriptions(data.subscriptions || []);
        setListEntities(data.listEntityData || []);
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }, [selectedSeller.value]);

  useEffect(() => {
    if (successRefresh) {
      setEntityId('');
      setAccountSetttings([]);
      setSubscriptions([]);
      setListEntities([]);
      setLoading(true);
      const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
      const requestOptions = {
        method: 'GET',
        headers: headerWithAuth,
      };

      const url = `${config.serverUrl}/accounts/list-sqs-subs?accountId=${selectedSeller.value}`;
      try {
        doFetch(url, requestOptions).then(data => {
          if (data.accountSettingEntity && data.accountSettingEntity.length === 1) {
            setEntityId(data.accountSettingEntity[0].setting_value);
          }

          setAccountSetttings(data.accountSetting || []);
          setSubscriptions(data.subscriptions || []);
          setListEntities(data.listEntityData || []);
          setLoading(false);
        });
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
      setSuccessRefresh(false);
      setLoading(false);
    }
  }, [successRefresh]);

  const activateSqsSubscriptions = () => {
    setSubmit(true);
    Notify.show('warning', 'Initializing', 'Requesting SQS Subscriptions.');

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const postUrl = `${config.serverUrl}/accounts/activate-sqs-subs`;

    if (selectedSeller.value > 0) {
      const requestOptions = {
        method: 'POST',
        headers: headerWithAuth,
        body: JSON.stringify({
          accountId: selectedSeller.value.toString(),
          dataSetsCsv,
        }),
      };

      fetch(postUrl, requestOptions)
        .then((results) => {
          if (!results.ok) {
            throw Error(results.statusText);
          }
          return results.json();
        }).then((response) => {
          const datasetIds = response.map(obj => `\n\n ${obj.dataset_id.toString()} (${obj.request_status})`);
          Notify.show('primary', 'Results', `Requested SQS Subscriptions. ${datasetIds.toString()}`);
          setSuccessRefresh(true);
          setSubmit(false);
        }).catch((err) => {
          Notify.show('danger', 'Error', `Unable to reqeust SQS Subscriptions. Reason: ${err}`);
          setSubmit(false);
        });
    } else {
      Notify.show('danger', 'Error', 'Unable to reqeust SQS Subscriptions. Reason: account ID not found.');
      setSubmit(false);
    }
  }

  const archiveSqsSubscriptions = (subId) => {
    setSubmit(true);
    Notify.show('warning', 'Initializing', 'Archiving SQS Subscriptions.');

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const postUrl = `${config.serverUrl}/accounts/archive-sqs-subs`;

    if (selectedSeller.value > 0) {
      const requestOptions = {
        method: 'POST',
        headers: headerWithAuth,
        body: JSON.stringify({
          accountId: selectedSeller.value.toString(),
          subId,
        }),
      };

      fetch(postUrl, requestOptions)
        .then((results) => {
          if (!results.ok) {
            throw Error(results.statusText);
          }
          return results.json();
        }).then((response) => {
          Notify.show('primary', 'Results', `Archive SQS Subscriptions. ${JSON.stringify(response, null, 2)}`);
          setSuccessRefresh(true);
          setSubmit(false);
        }).catch((err) => {
          Notify.show('danger', 'Error', `Unable to archive SQS Subscriptions. Reason: ${err}`);
          setSubmit(false);
        });
    } else {
      Notify.show('danger', 'Error', 'Unable to archive SQS Subscriptions. Reason: account ID not found.');
      setSubmit(false);
    }
  }

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Account Stream Settings (SQS):</h3>
        </Col>
      </Row>
      <Row>
        <CardPanel
          showButtons={false}
          parentRefresh={loading}
        >
          <StreamsSettingsTable accountSettings={accountSettings} subscriptions={subscriptions} entityId={entityId} listEntities={listEntities} activateSqsSubscriptions={activateSqsSubscriptions} setDataSetsCsv={setDataSetsCsv} submit={submit} successRefresh={successRefresh} />
        </CardPanel>
      </Row>
      <Row>
        <CardPanel
          showButtons={false}
          parentRefresh={loading}
        >
          <StreamsTable subscriptions={subscriptions} gridRef={gridRef} gridTheme={gridTheme} archiveSqsSubscriptions={archiveSqsSubscriptions} />
        </CardPanel>
      </Row>
    </Container>
  );
}

export default AccountTable;
