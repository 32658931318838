

import React from 'react';
import {
  Row, Col, Table,
} from 'reactstrap';
import moment from 'moment';
import LoadingSpinner from '../../../../../shared/components/widgets/LoadingSpinner';
import { getPlanStatus } from '../../config';

const PlanView = ({
  flexPlan, setShowPlanEstimator, showPlanEstimator, setShowPriceTiers, currentPlan, currentPlanStatus, nextBillDate, sellerSelect, submitStartNew, setShowCancel, setShowReactivate, loadingPayment, selectedSubscriptionId,
}) => {
  const planStatusDtls = getPlanStatus(currentPlanStatus);
  const newPlanView = (
    <Row>
      <Col>
        <div style={{ textAlign: 'center' }}>
          <h1>Flex Plan: {flexPlan.costAmount} / Month + Tiered Pricing</h1>
          <p>Only pay for what you use.</p>
          <p>
            <button className="btn btn-primary" type="submit" onClick={() => setShowPlanEstimator(!showPlanEstimator)}>Show Plan Estimator</button>
            <button className="btn btn-primary" type="submit" onClick={() => setShowPriceTiers(true)}>Show Price Tiers</button>
          </p>
        </div>
      </Col>
    </Row>
  );

  const currentPlanView = (
    <Row>
      <Col>
        <div style={{ textAlign: 'center', margin: 'auto', maxWidth: '900px' }}>
          <Table responsive striped id="SubsInfo">
            <tbody>
              <tr>
                <td><h2>Plan</h2></td>
                <td><h2>{(currentPlan || 'Trial').toUpperCase()}</h2></td>
              </tr>
              <tr>
                <td><h2>Plan Status</h2></td>
                <td><h2>{planStatusDtls.cleanPlanStatus}</h2></td>
              </tr>
              <tr>
                <td><h2>{planStatusDtls.planBillingTitle}</h2></td>
                <td><h2>{planStatusDtls.showBillingDate ? moment(nextBillDate).format('YYYY-MM-DD') : 'NONE'}</h2></td>
              </tr>
            </tbody>
          </Table>
          <p>{planStatusDtls.planBillingMessage}</p>
          <br />
          <p>
            {sellerSelect.planStatus === 'cancelled' && (
              <button className="btn btn-primary" type="submit" onClick={() => submitStartNew(flexPlan.name)}>Start New Subscription</button>
            )}
            <button className="btn btn-primary" type="submit" onClick={() => setShowPlanEstimator(!showPlanEstimator)}>Show Plan Estimator</button>
            <button className="btn btn-primary" type="submit" onClick={() => setShowPriceTiers(true)}>Show Price Tiers</button>
            {(sellerSelect.plan || 'TRIAL').toUpperCase() !== 'TRIAL' && sellerSelect.planStatus !== 'activeCancelled' && sellerSelect.planStatus !== 'cancelled' && (
              <button className="btn btn-warning" type="submit" onClick={() => setShowCancel(true)}>Manage Subscription</button>
            )}
            {sellerSelect.planStatus === 'activeCancelled' && (
              <button className="btn btn-warning" type="submit" onClick={() => setShowReactivate(true)}>Manage Subscription</button>
            )}
          </p>
        </div>
      </Col>
    </Row>
  );

  const loadingSpinner = (
    <Row>
      <Col>
        <br />
        <br />
        <div style={{ textAlign: 'center', margin: 'auto', maxWidth: '900px' }}>
          <LoadingSpinner
            active={loadingPayment}
          />
        </div>
      </Col>
    </Row>
  )

  const loadedPlanView = (!selectedSubscriptionId && sellerSelect.planStatus !== '') ? currentPlanView : newPlanView;
  const planView = loadingPayment ? loadingSpinner : loadedPlanView;

  return planView;
};

export default PlanView;
