import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import CampaignReport from './components/CampaignReport';

export default function CampaignReportIndexPage() {
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">DSP Campaign Report</h3>
        </Col>
      </Row>
      <Row>
        <CampaignReport />
      </Row>
    </Container>
  );
}
