import React, { PureComponent } from 'react';
import {
  Container, Col, Row, Table,
} from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, // Line,
} from 'recharts';
import Panel from '../../../../../shared/components/Panel';
import DateRangePickerPanel from '../../../../../shared/components/widgets/DateRangePickerPanel';
import { SellerSelect, User, Theme } from '../../../../../shared/prop-types/MainProps';
import {
  formatNumberWhole, currencyFormatter, formatDecimalToFixedTwoPercent, formatNumberOnePlace,
} from '../../../../../shared/components/table/functions';
import style from '../../../../../shared/components/themeSupport';
import config from '../../../../../config';
import SalesMetricFilter, { MetricOptions } from '../../../../../shared/components/widgets/SalesMetricFilter';
import DataSetFilter, { DataSetsMulti } from '../../../../../shared/components/widgets/DataSetFilter';
import PortfolioFilter, { ALL_PORTFOLIOS } from '../../../../../shared/components/widgets/PortfolioFilter';
import DateViewFilter, { DateViewOptions } from '../../../../../shared/components/widgets/DateViewFilter';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class PpcOverTimeAccount extends PureComponent {
  static propTypes = {
    sellerSelect: SellerSelect.isRequired,
    user: User.isRequired,
    theme: Theme.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      reportStartDate: moment().subtract(34, 'days').format('YYYY-MM-DD'),
      reportEndDate: moment().subtract(4, 'days').format('YYYY-MM-DD'),
      initalLoad: false,
      accountTotalsWithSalesRank: [],
      metricOption: MetricOptions.SALES,
      dataOption: [DataSetsMulti.SP, DataSetsMulti.SB, DataSetsMulti.SBV, DataSetsMulti.SD_CPC, DataSetsMulti.SD_VCPM],
      dateViewOption: DateViewOptions.DAY,
      portfolioOption: ALL_PORTFOLIOS,
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevState) {
    const { sellerSelect } = this.props;
    if (sellerSelect.value !== prevState.sellerSelect.value) {
      this.getData();
    }
  }

  onDateChange = (startDate, endDate) => {
    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).format('YYYY-MM-DD');
    this.setState({
      reportStartDate: start,
      reportEndDate: end,
    }, this.getData);
  }

  getData = () => {
    const { sellerSelect, user } = this.props;
    const {
      reportStartDate, reportEndDate, dateViewOption, dataOption, portfolioOption,
    } = this.state;

    const dataOptionValues = dataOption ? dataOption.map(obj => obj.value).join(',') : '';

    const accountTotalsWithSalesRankUrl = `${apiUrl}/customers/account-over-time-totals?sellerAccountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}&startDate=${reportStartDate}&endDate=${reportEndDate}&accountType=${sellerSelect.type}&dataGroup=${dateViewOption.value}&dataSet=${dataOptionValues}&portfolioFilter=${portfolioOption.value}`;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    this.setState({
      initalLoad: true,
    });

    fetch(accountTotalsWithSalesRankUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        this.setState({
          initalLoad: false,
          accountTotalsWithSalesRank: data,
        });
      });
  };

  handleChange = (option, select) => {
    this.setState({
      [select.name]: option,
    }, () => {
      if (select.name !== 'metricOption') {
        const { initalLoad } = this.state;
        if (!initalLoad) {
          this.getData();
        }
      }
    });
  }

  graphFormatter = (itemType) => {
    let tickFormatter = number => formatNumberWhole(number);

    if ([MetricOptions.ACOS.value, MetricOptions.CVR.value, MetricOptions.CTR.value].includes(itemType)) {
      tickFormatter = percent => formatDecimalToFixedTwoPercent({ value: percent });
    }
    if ([MetricOptions.SALES.value, MetricOptions.SPEND.value, MetricOptions.CPC.value, MetricOptions.VCPM.value].includes(itemType)) {
      tickFormatter = dollar => currencyFormatter({ value: dollar });
    }
    if ([MetricOptions.ROAS.value].includes(itemType)) {
      tickFormatter = number => formatNumberOnePlace(number);
    }
    return tickFormatter;
  }

  render() {
    const {
      accountTotalsWithSalesRank, initalLoad, dataOption, reportStartDate, reportEndDate,
      dateViewOption, metricOption,
    } = this.state;

    const { theme } = this.props;

    const spDataKey = `datasets.sp.${metricOption.value}`;
    const sbDataKey = `datasets.sb.${metricOption.value}`;
    const sbvDataKey = `datasets.sbv.${metricOption.value}`;
    const sdDataCpcKey = `datasets.sd_cpc.${metricOption.value}`;
    const sdDataVcpmKey = `datasets.sd_vcpm.${metricOption.value}`;

    const showLeftAxis = !([MetricOptions.ACOS.value, MetricOptions.CVR.value, MetricOptions.ROAS.value, MetricOptions.CTR.value].includes(metricOption.value));

    let totalImpressions = 0;
    let totalClicks = 0;
    let totalOrders = 0;
    let totalSpend = 0;
    let totalSales = 0;
    let calcAcos = 0;
    let calcRoas = 0;
    let calcCvr = 0;
    let calcCtr = 0;
    let calcCpc = 0;
    let totalViewImpressions = 0;
    let totalViewSpend = 0;
    let calcVcpm = 0;

    if ((accountTotalsWithSalesRank || '').length > 0) {
      // calculate totals
      accountTotalsWithSalesRank.forEach((row) => {
        totalImpressions += row.impressions;
        totalClicks += row.clicks;
        totalOrders += row.orders;
        totalSpend += row.spend;
        totalSales += row.sales;
        totalViewImpressions += row.viewImpressions;
        totalViewSpend += row.viewSpend;
      });
      calcAcos = (totalSales > 0) ? (totalSpend / totalSales) : 0;
      calcRoas = (totalSpend > 0) ? (totalSales / totalSpend) : 0;
      calcCvr = (totalClicks > 0) ? (totalOrders / totalClicks) : 0;
      calcCtr = (totalImpressions > 0) ? (totalClicks / totalImpressions) : 0;
      calcCpc = (totalClicks > 0) ? (totalSpend / totalClicks) : 0;
      calcVcpm = (totalViewImpressions > 0) ? (totalViewSpend / (totalViewImpressions / 1000)) : 0;
    }

    return (
      <Container className="dashboard">
        <Col md={12}>
          <Row>
            <DateRangePickerPanel
              reportStartDate={reportStartDate}
              reportEndDate={reportEndDate}
              onDateChange={this.onDateChange}
            />
          </Row>
        </Col>
        <Panel
          lg={12}
          md={12}
          title="Filters"
          subhead="NOTE: Filters and View only use data in the date range selected.  If your date range doesn't match your view, you could be seeing partial data on the ends of your view."
          parentRefresh={initalLoad}
        >
          <Row>
            <Col lg={2}>
              <SalesMetricFilter
                initialValue={metricOption}
                onChange={value => this.handleChange(value, { name: 'metricOption' })}
                disable={initalLoad}
              />
            </Col>
            <Col lg={6}>
              <DataSetFilter
                initialValue={dataOption}
                onChange={value => this.handleChange(value, { name: 'dataOption' })}
                disable={initalLoad}
                multi
              />
            </Col>
            <Col lg={3}>
              <PortfolioFilter
                onChange={value => this.handleChange(value, { name: 'portfolioOption' })}
                disable={initalLoad}
              />
            </Col>
            <Col lg={1}>
              <DateViewFilter
                initialValue={dateViewOption}
                onChange={value => this.handleChange(value, { name: 'dateViewOption' })}
                disable={initalLoad}
              />
            </Col>
          </Row>
        </Panel>

        <Panel
          lg={12}
          md={12}
          title="Breakdown"
          subhead="Metrics by campaign type"
          parentRefresh={initalLoad}
        >
          <Row>
            <Col md={12} className="text-center">
              <h5>{metricOption.label}</h5>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ResponsiveContainer height={400} className="dashboard__area">
                <ComposedChart
                  width={600}
                  height={400}
                  data={accountTotalsWithSalesRank}
                  margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                  }}
                >
                  <CartesianGrid stroke={style(theme).colorText} />
                  <XAxis dataKey="rankDate" stroke={style(theme).colorText} />
                  {showLeftAxis && (
                    <YAxis yAxisId="left" tickFormatter={this.graphFormatter(metricOption.value)} stroke={style(theme).colorText} />
                  )}
                  {/* <YAxis yAxisId="right" orientation="right" tickFormatter={number => number} domain={[0, domainMax]} stroke={style(theme).colorText} /> */}
                  <Tooltip />
                  <Legend />
                  <Bar dataKey={spDataKey} name="Sponsored Products" formatter={this.graphFormatter(metricOption.value)} stackId="a" yAxisId="left" barSize={20} fill="#d04960" />
                  <Bar dataKey={sbDataKey} name="Sponsored Brands" formatter={this.graphFormatter(metricOption.value)} stackId="a" yAxisId="left" barSize={20} fill="#03c03c" />
                  <Bar dataKey={sdDataCpcKey} name="Sponsored Display - CPC" formatter={this.graphFormatter(metricOption.value)} stackId="a" yAxisId="left" barSize={20} fill="#fbb054" />
                  <Bar dataKey={sdDataVcpmKey} name="Sponsored Display - vCPM" formatter={this.graphFormatter(metricOption.value)} stackId="a" yAxisId="left" barSize={20} fill="#FF9966" />
                  <Bar dataKey={sbvDataKey} name="Sponsored Brands Video" formatter={this.graphFormatter(metricOption.value)} stackId="a" yAxisId="left" barSize={20} fill="#36c9c9" />
                  {/* <Line type="monotone" dataKey="salesRankAvg" name="Sales Rank Avg" yAxisId="right" stroke="#FF9966" /> */}
                </ComposedChart>
              </ResponsiveContainer>
            </Col>
          </Row>
        </Panel>

        <Panel
          lg={12}
          md={12}
          title=""
          subhead=""
          parentRefresh={initalLoad}
        >
          <div style={{
            marginLeft: '100px',
          }}
          >
            <Table responsive striped className="dashboard__table-orders" id="dataOverTime">
              <thead>
                <tr>
                  <th className="sticky-th" />
                  {accountTotalsWithSalesRank.map(item => (
                    <th>{item.rankDate}</th>
                  ))}
                  <th className="sticky-th-right">TOTAL</th>
                </tr>
              </thead>
              <tbody>
                {/* <tr>
                  <td>Average Sales Rank</td>
                  {accountTotalsWithSalesRank.map(item => (
                    <td>{formatNumberWhole(item.salesRankAvg)}</td>
                  ))}
                </tr> */}
                <tr>
                  <td className="sticky-td-color" style={{ marginTop: '1px' }}>Impressions</td>
                  {accountTotalsWithSalesRank.map(item => (
                    <td>{formatNumberWhole(item.impressions)}</td>
                  ))}
                  <td>{formatNumberWhole(totalImpressions)}</td>
                </tr>
                <tr>
                  <td className="sticky-td">Clicks</td>
                  {accountTotalsWithSalesRank.map(item => (
                    <td>{formatNumberWhole(item.clicks)}</td>
                  ))}
                  <td>{formatNumberWhole(totalClicks)}</td>
                </tr>
                <tr>
                  <td className="sticky-td-color">Orders</td>
                  {accountTotalsWithSalesRank.map(item => (
                    <td>{formatNumberWhole(item.orders)}</td>
                  ))}
                  <td>{formatNumberWhole(totalOrders)}</td>
                </tr>
                <tr>
                  <td className="sticky-td">Spend</td>
                  {accountTotalsWithSalesRank.map(item => (
                    <td>{currencyFormatter({ value: item.spend })}</td>
                  ))}
                  <td>{currencyFormatter({ value: totalSpend })}</td>
                </tr>
                <tr>
                  <td className="sticky-td-color">Sales</td>
                  {accountTotalsWithSalesRank.map(item => (
                    <td>{currencyFormatter({ value: item.sales })}</td>
                  ))}
                  <td>{currencyFormatter({ value: totalSales })}</td>
                </tr>
                <tr>
                  <td className="sticky-td">ACoS</td>
                  {accountTotalsWithSalesRank.map(item => (
                    <td>{formatDecimalToFixedTwoPercent({ value: item.acos })}</td>
                  ))}
                  <td>{formatDecimalToFixedTwoPercent({ value: calcAcos })}</td>
                </tr>
                <tr>
                  <td className="sticky-td-color">ROAS</td>
                  {accountTotalsWithSalesRank.map(item => (
                    <td>{item.roas}</td>
                  ))}
                  <td>{calcRoas.toFixed(1)}</td>
                </tr>
                <tr>
                  <td className="sticky-td">CVR</td>
                  {accountTotalsWithSalesRank.map(item => (
                    <td>{formatDecimalToFixedTwoPercent({ value: item.cvr })}</td>
                  ))}
                  <td>{formatDecimalToFixedTwoPercent({ value: calcCvr })}</td>
                </tr>
                <tr>
                  <td className="sticky-td-color">CTR</td>
                  {accountTotalsWithSalesRank.map(item => (
                    <td>{formatDecimalToFixedTwoPercent({ value: item.ctr })}</td>
                  ))}
                  <td>{formatDecimalToFixedTwoPercent({ value: calcCtr })}</td>
                </tr>
                <tr>
                  <td className="sticky-td">CPC</td>
                  {accountTotalsWithSalesRank.map(item => (
                    <td>{currencyFormatter({ value: item.cpc })}</td>
                  ))}
                  <td>{currencyFormatter({ value: calcCpc })}</td>
                </tr>
                <tr>
                  <td className="sticky-td-color">vCPM</td>
                  {accountTotalsWithSalesRank.map(item => (
                    <td>{currencyFormatter({ value: item.vcpm })}</td>
                  ))}
                  <td>{currencyFormatter({ value: calcVcpm })}</td>
                </tr>
                <tr>
                  <td className="sticky-td">View Impressions</td>
                  {accountTotalsWithSalesRank.map(item => (
                    <td>{formatNumberWhole(item.viewImpressions)}</td>
                  ))}
                  <td>{formatNumberWhole(totalViewImpressions)}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Panel>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { theme, sellerSelect } = state;
  const { user } = state.authentication;

  return {
    theme,
    sellerSelect,
    user,
  };
};

export default connect(mapStateToProps)(PpcOverTimeAccount);
