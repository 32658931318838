import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../Panel';
// import {
//   formatNumberDefaultToZero,
// } from '../table/functions';

// const getDelta = (inValue) => {
//   if (inValue > 0) {
//     return (<span style={{ color: '#03c03c' }}>{formatNumberDefaultToZero({ value: inValue })} {String.fromCharCode(8679)}</span>);
//   }
//   if (inValue < 0) {
//     return (<span style={{ color: '#d04960' }}>{formatNumberDefaultToZero({ value: inValue })} {String.fromCharCode(8681)}</span>);
//   }
//   return (<span>{formatNumberDefaultToZero({ value: inValue })} {String.fromCharCode(8691)}</span>);
// };


const DeltaChange = ({ content, loadingData, lgWidth }) => (
  <Panel md={12} lg={lgWidth} title={content.title} parentRefresh={loadingData}>
    <div className="dashboard__sales-report">
      <div style={{ textAlign: 'center' }}>
        <p className="dashboard__sales-report-title">{content.header}</p>
        <p className="dashboard__bypage-chart-number" style={{ whiteSpace: 'nowrap' }}>{content.data}</p>
        <p className="dashboard__bypage-report-now" style={{ whiteSpace: 'nowrap' }}>{content.delta}</p>
      </div>
    </div>
  </Panel>
);

DeltaChange.defaultProps = {
  lgWidth: 4,
};

DeltaChange.propTypes = {
  // eslint-disable-next-line
  content: PropTypes.object.isRequired,
  loadingData: PropTypes.bool.isRequired,
  lgWidth: PropTypes.number,
};

export default DeltaChange;
