import React from 'react';
import PropTypes from 'prop-types';
import AccountDataPointPanel from './AccountDataPointPanel';
import { currencyFormatter } from '../../../../shared/functions';

export default function Spend({
  data, loading, dateView, title, subtitle, account, dateFieldName,
}) {
  return (
    <AccountDataPointPanel
      data={data}
      title={title}
      subtitle={subtitle}
      dayField="spend"
      formatFunction={value => currencyFormatter({ value, account })}
      barColor="#c88ffa"
      loading={loading}
      dateView={dateView}
      dateFieldName={dateFieldName}
    />
  );
}

Spend.defaultProps = {
  data: null,
  title: 'PPC Spend',
  subtitle: 'Total for Date Range',
  dateFieldName: 'rankDate',
};

Spend.propTypes = {
  loading: PropTypes.bool.isRequired,
  dateView: PropTypes.instanceOf(Object).isRequired,
  account: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(Array),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  dateFieldName: PropTypes.string,
};
