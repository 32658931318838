/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import PpcBestsellers from './components/Page';
import PpcBestsellersGraph from '../../../../../shared/components/widgets/FullWidthFillGraph';
import PpcBestsellersButtons from '../../components/PpcBestsellersButtons';
import DateRangePickerPanel from '../../../../../shared/components/widgets/DateRangePickerPanel';
import config from '../../../../../config';
import { SellerSelect, User } from '../../../../../shared/prop-types/MainProps';
import SellerReportMessage from '../../../../../shared/components/widgets/SellerReportMessage';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class PpcBestsellersPage extends PureComponent {
  static propTypes = {
    sellerSelect: SellerSelect.isRequired,
    user: User.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      graphData: [],
      initalLoad: false,
      graphLoad: false,
      reportStartDate: moment().subtract(11, 'days').format('YYYY-MM-DD'),
      reportEndDate: moment().subtract(4, 'days').format('YYYY-MM-DD'),
      showingAll: false,
      pivotOn: 'SKU',
      checkedItem: new Set(),
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevState) {
    const { sellerSelect } = this.props;
    if (sellerSelect.value !== prevState.sellerSelect.value) {
      this.getData();
    }
  }

  handleCheckboxChange = (event) => {
    const { checkedItem } = this.state;
    const updateSet = new Set(checkedItem);
    if (updateSet.has(event.target.id)) {
      updateSet.delete(event.target.id);
    } else {
      updateSet.add(event.target.id);
    }

    this.setState({
      checkedItem: updateSet,
      graphLoad: true,
    }, () => {
      this.getGraphData();
    });
  }

  onDateChange = (startDate, endDate) => {
    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).format('YYYY-MM-DD');
    this.setState({
      reportStartDate: start,
      reportEndDate: end,
    }, this.getData);
  }

  toggleDisplay = () => {
    this.setState(prevState => ({
      showingAll: !prevState.showingAll,
    }));
  }

  setPivot = (pivotOn) => {
    const { checkedItem } = this.state;
    checkedItem.clear();
    this.setState({
      pivotOn,
      checkedItem,
    }, () => {
      this.getData();
    });
  }

  getGraphData = () => {
    const { sellerSelect, user } = this.props;
    const {
      reportStartDate, reportEndDate, pivotOn, checkedItem,
    } = this.state;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    // Use same requestOptions as above
    const bestsellersGraphUrl = `${apiUrl}/sellers/ppc-bestsellers-graph?sellerAccountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}&startDate=${reportStartDate}&endDate=${reportEndDate}&columnType=${pivotOn}&currencyCode=${(sellerSelect.currencyCode || 'USD')}&itemList=${Array.from(checkedItem).join(',')}`;
    fetch(bestsellersGraphUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((graphData) => {
        this.setState({
          graphLoad: false,
          graphData,
        });
      }).catch(() => {
        this.setState({
          graphLoad: false,
        });
      });
  }

  getData = () => {
    const { sellerSelect, user } = this.props;
    const {
      reportStartDate, reportEndDate, pivotOn, checkedItem,
    } = this.state;
    const start = moment(reportStartDate).format('YYYYMMDD');
    const end = moment(reportEndDate).format('YYYYMMDD');
    const bestsellersUrl = `${apiUrl}/sellers/ppc-bestsellers?sellerAccountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}&startDate=${start}&endDate=${end}&currencyCode=${(sellerSelect.currencyCode || 'USD')}&pivotType=${pivotOn}`;
    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
    checkedItem.clear();

    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    this.setState({
      data: [],
      graphData: [],
      initalLoad: true,
      graphLoad: true,
      checkedItem,
    });

    fetch(bestsellersUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        let lineCount = 5;
        if (data.length < 5) {
          lineCount = data.length;
        }

        for (let i = 0; i < lineCount; i += 1) {
          if (data[i][pivotOn.toLowerCase()]) {
            checkedItem.add(data[i][pivotOn.toLowerCase()]);
          }
        }

        this.setState({
          initalLoad: false,
          data,
          checkedItem,
        });

        this.getGraphData();
      }).catch(() => {
        this.setState({
          initalLoad: false,
        });
      });
  };

  render() {
    const {
      data, graphData, initalLoad, graphLoad, reportStartDate, reportEndDate, showingAll, pivotOn, checkedItem,
    } = this.state;

    const { sellerSelect } = this.props;

    if (sellerSelect.type === 1) {
      return (
        <Container className="dashboard">
          <Col md={12}>
            <Row>
              <DateRangePickerPanel
                reportStartDate={reportStartDate}
                reportEndDate={reportEndDate}
                onDateChange={this.onDateChange}
              />
            </Row>
          </Col>
          <Col md={12}>
            <Row>
              <PpcBestsellersGraph
                data={graphData}
                dataType="asin"
                title="Top 5 PPC Bestsellers"
                initalLoad={graphLoad}
                lineList={Array.from(checkedItem)}
                colorOptions={['#70bbfd', '#ffb153', '#f47b50', '#c88ffa', '#4ce1b6', '#66CCFF', '#FF9966', '#FF6666', '#9999CC', '#33CCCC']}
              />
            </Row>
          </Col>
          <Col md={12}>
            <Row>
              <PpcBestsellersButtons
                setPivot={this.setPivot}
                accountType={sellerSelect.type}
              />
            </Row>
          </Col>
          <Col md={12}>
            <Row>
              <PpcBestsellers
                newOrder={data}
                title="PPC Bestsellers"
                subhead="Top 10 Bestseller - Advertised Products"
                initalLoad={initalLoad}
                reportStartDate={reportStartDate}
                reportEndDate={reportEndDate}
                showingAll={showingAll}
                toggleDisplay={this.toggleDisplay}
                pivotOn={pivotOn}
                handleCheckboxChange={this.handleCheckboxChange}
                checkedItem={checkedItem}
                graphLoad={graphLoad}
              />
            </Row>
          </Col>
        </Container>
      );
    }
    return (
      <SellerReportMessage />
    );
  }
}

const mapStateToProps = (state) => {
  const { sellerSelect } = state;
  const { user } = state.authentication;

  return {
    sellerSelect,
    user,
  };
};

export default connect(mapStateToProps)(PpcBestsellersPage);
