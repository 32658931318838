import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from 'reactstrap';
import Panel from '../../../../../shared/components/Panel';

export default function AccountAccessPanel({
  initialLoad, getData, mapLine, apiData,
}) {
  return (
    <Panel lg={12} title="Account Access" getData={getData} parentRefresh={initialLoad}>
      <div>
        <Table responsive className="table--bordered">
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Active</th>
              <th>Logins Last 7 Days</th>
              <th>Access At</th>
            </tr>
          </thead>
          <tbody>
            {apiData && apiData.map(line => mapLine(line))}
          </tbody>
        </Table>
      </div>
    </Panel>
  );
}

AccountAccessPanel.propTypes = {
  initialLoad: PropTypes.bool.isRequired,
  getData: PropTypes.func.isRequired,
  mapLine: PropTypes.func.isRequired,
  apiData: PropTypes.instanceOf(Array).isRequired,
};
