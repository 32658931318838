import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import StoreOutlineIcon from 'mdi-react/StoreOutlineIcon';
import AlphaSCircleOutlineIcon from 'mdi-react/AlphaSCircleOutlineIcon';
import IdentifierIcon from 'mdi-react/IdentifierIcon';
import CheckCircleOutlineIcon from 'mdi-react/CheckCircleOutlineIcon';
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';
import Recaptcha from 'reaptcha';
import { Buffer } from 'buffer';
import { Input } from 'reactstrap';
import Alert from '../../../../shared/components/Alert';
import { serverUrl, jsonHeader, isProdEnv } from '../../../../config';
import { platform as PLATFORM } from '../../../../shared/components/domainSupport';
import LoadingSpinner from '../../../../shared/components/widgets/LoadingSpinner';
import { history } from '../../../../helpers';

const AuthorizeMpForm = () => {
  const selectedAccount = useSelector(store => store.sellerSelect);
  const { loggedIn, user } = useSelector(store => store.authentication);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recaptchaResponse, setRecaptchaResponse] = useState('');
  const [recaptchaIsLoaded, setRecaptchaIsLoaded] = useState(false);
  const [platform, setPlatform] = useState(PLATFORM);
  const [accountId, setAccountId] = useState(selectedAccount.value);
  const [localAlert, setLocalAlert] = useState({});
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [sellerId, setSellerId] = useState('');
  const [existingClientKey, setExistingClientKey] = useState('');
  const [onboardSuccess, setOnboardSuccsss] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const mpApiParams = urlParams.get('edata');
  // const adminParam = urlParams.get('admin');
  const isTestParam = urlParams.get('isTest')?.toLowerCase();

  if (loggedIn && window.location.pathname.startsWith('/onboarding/walmart/mp-api')) {
    history.push('/customer/onboarding/walmart/mp-api');
  } else if (mpApiParams) {
    try {
      const mpApiParamsDecoded = decodeURIComponent(mpApiParams);
      const mpApiParamsString = Buffer.from(mpApiParamsDecoded, 'base64').toString('utf-8');
      const mpApiParamsJson = JSON.parse(mpApiParamsString);
      useEffect(() => {
        setAccountId(mpApiParamsJson.accountId);
        setPlatform(mpApiParamsJson.platform);
      }, []);
    } catch (err) {
      return (
        <div className="form__form-group">
          <Alert className="INFO">You are required to be logged in or have a starting link to use the Walmart Marketplace API authorization process.</Alert>
          <br />
          Error Reference: Bad Link ({err})
        </div>
      );
    }
  } else if (!loggedIn) {
    return (
      <div className="form__form-group">
        <div style={{ textAlign: 'center', width: '100%' }}><CloseCircleOutlineIcon className="dashboard__x-icon" /></div>
        <br />
        <h3> You are required to be logged in or have a starting link to use the Walmart Marketplace API onboarding process.</h3>
      </div>
    );
  }

  // Run on load and see if Marketplace API has already been configured and/or if it is ready to be confiugred.  Requires seller id from AD onboard.
  useEffect(() => {
    setLocalAlert({});
    setLoading(true);
    setOnboardSuccsss(false);
    if (platform === 'adjuggernaut') {
      setPlatform('limitless'); // Temp workaround until all the APIs are deployed to be multi-platform.  AdRev is not live, so it's OK to onboard as-is.
    }

    const headerWithAuth = { ...jsonHeader, authorization: `JWT ${user?.jwtToken}` };
    // Add logic to check input values, make sure they are present and correct.
    const onboardOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    const onboardUrl = `${serverUrl}/walmart/onboard/mp-api?accountId=${selectedAccount.value || accountId}`;
    fetch(onboardUrl, onboardOptions)
      .then(async (results) => {
        if (!results.ok) {
          const text = await results.text();
          throw new Error(text);
        }
        return results.json();
      }).then((data) => {
        if (!isProdEnv) {
          console.info(data);
        }
        setSellerId(data.walmartSellerId);
        setExistingClientKey(data.walmartClientKey);
        setLoading(false);
      }).catch((error) => {
        try {
          const serverMsg = JSON.parse(JSON.parse(error.message).error).error;
          setLocalAlert({ color: 'danger', message: serverMsg });
        } catch {
          setLocalAlert({ color: 'danger', message: 'Unbale to check for exsiting configuration.' });
        }
        setLoading(false);
      });
  }, [selectedAccount.value, accountId]);

  const submitWalmartOnboardMpApiForm = () => {
    setSubmitted(true);
    setLocalAlert({});
    if (platform === 'adjuggernaut') {
      setPlatform('limitless'); // Temp workaround until all the APIs are deployed to be multi-platform.  AdRev is not live, so it's OK to onboard as-is.
    }
    if ((!recaptchaResponse && !loggedIn) || !clientId || !clientSecret) {
      setLocalAlert({ color: 'warning', message: 'Please fix the input issues below.' });
      setSubmitted(false);
    } else {
      const headerWithAuth = { ...jsonHeader, authorization: `JWT ${user?.jwtToken}` };
      // Add logic to check input values, make sure they are present and correct.
      const onboardOptions = {
        method: 'POST',
        headers: headerWithAuth,
        body: JSON.stringify({
          accountId,
          clientKey: Buffer.from(`${clientId}:${clientSecret}`).toString('base64'),
          sellerId,
          platform,
          recaptcha: recaptchaResponse,
        }),
      };

      const onboardUrl = `${serverUrl}/walmart/onboard/mp-api`;
      fetch(onboardUrl, onboardOptions)
        .then(async (results) => {
          if (!results.ok) {
            const text = await results.text();
            throw new Error(text);
          }
          return results.json();
        }).then((data) => {
          if (!isProdEnv) {
            console.info(data);
          }
          setOnboardSuccsss(true);
          setSubmitted(false);
        }).catch((error) => {
          try {
            const serverMsg = JSON.parse(JSON.parse(error.message).error).error;
            setLocalAlert({ color: 'danger', message: serverMsg });
          } catch {
            console.error('unable to parse');
            setLocalAlert({ color: 'danger', message: 'We are having issues processing your request, please check your data and try again.' });
          }
          setSubmitted(false);
        });
    }
  }

  return (
    <>
      {!onboardSuccess && sellerId !== '' && existingClientKey === '' && (
        <>
          <div className="form__form-group">
            {localAlert && localAlert.message && (
              <>
                <Alert color={localAlert.color}>{localAlert.message}</Alert>
              </>
            )}
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Seller ID</span>
            <div className="form__form-group-field">
              <div className="form__input-group-icon">
                <StoreOutlineIcon />
              </div>
              <Input
                autoFocus
                name="sellerId"
                component="input"
                type="text"
                value={sellerId}
                onChange={(e) => setSellerId(e.target.value)}
                disabled
              />
            </div>
            {localAlert.message && (!sellerId || sellerId === '') && (
              <div className="form__form-group-error">Client ID is required</div>
            )}
          </div>
          <br />
          <div className="form__form-group">
            <span className="form__form-group-label">Client ID</span>
            <div className="form__form-group-field">
              <div className="form__input-group-icon">
                <IdentifierIcon />
              </div>
              <Input
                autoFocus
                name="clientId"
                component="input"
                type="text"
                value={clientId}
                onChange={(e) => setClientId(e.target.value)}
              />
            </div>
            {localAlert.message && (!clientId || clientId === '') && (
              <div className="form__form-group-error">Client ID is required</div>
            )}
          </div>
          <br />
          <div className="form__form-group">
            <span className="form__form-group-label">Client Secret</span>
            <div className="form__form-group-field">
              <div className="form__input-group-icon">
                <AlphaSCircleOutlineIcon />
              </div>
              <Input
                autoFocus
                name="clientSecret"
                component="input"
                type="password"
                value={clientSecret}
                onChange={(e) => setClientSecret(e.target.value)}
              />
            </div>
            {localAlert.message && (!clientSecret || clientSecret === '') && (
              <div className="form__form-group-error">Client Secret is required</div>
            )}
          </div>
          <br />
          {!loggedIn && (
            <div className="form__form-group">
              <Recaptcha sitekey="6LdYQuAbAAAAAD-jRnBlWEzdtu7poJfrLQ72h_mZ" onVerify={(response) => setRecaptchaResponse(response)} onload={() => setRecaptchaIsLoaded(true)} />
            </div>
          )}
          <div style={{ textAlign: 'center', paddingBottom: '20px' }}>
            <LoadingSpinner
              active={submitted && !localAlert.message}
            />
          </div>
          {isTestParam === 'true' && (
            <div className="form__form-group">
              <p>************************************************************ YOU ARE IN TEST MODE ************************************************************</p>
            </div>
          )}
          {!submitted && !loading && (
            <div className="form__form-group">
              <button className="btn btn-primary account__btn" type="submit" disabled={recaptchaIsLoaded} onClick={() => submitWalmartOnboardMpApiForm()}>Authorize</button>
            </div>
          )}
        </>
      )}
      {!onboardSuccess && sellerId === '' && existingClientKey === '' && !loading && (
        <div className="form__form-group">
          <div style={{ textAlign: 'center', width: '100%' }}><CloseCircleOutlineIcon className="dashboard__x-icon" /></div>
          <br />
          <h3> This account has not ready for Walmart Marketplace API onboard.  Please work with your account manager to confirm the Advertising API has been onboarded and a Seller ID is configured correctly.</h3>
        </div>
      )}
      {loading && (
        <div style={{ textAlign: 'center', paddingBottom: '20px' }}>
          <LoadingSpinner active={loading} />
        </div>
      )}
      {(onboardSuccess || existingClientKey !== '') && (
        <div className="form__form-group">
          <div style={{ textAlign: 'center', width: '100%' }}><CheckCircleOutlineIcon className="dashboard__check-icon" /></div>
          <br />
          <h3> Congratulations, your Marketplace credentials have been confirmed and assigned to your account.</h3>
        </div>
      )}
    </>
  );
}

export default AuthorizeMpForm;
