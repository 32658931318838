import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Container, Col, Row } from 'reactstrap';
import moment from 'moment';
import BudgetTable from './BudgetTable';
import config from '../../../../config';
import ChartBuilder from '../../../../shared/components/chart/ChartBuilder';
import CardPanel from '../../../../shared/components/widgets/CardPanel';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

export default function BudgetGraph() {
  const sellerSelect = useSelector(store => store.sellerSelect);
  const user = useSelector(store => store.authentication.user);
  const theme = useSelector(store => store.theme);
  const [amzGraphData, setAmzGraphData] = useState([{ all: {}, internal: {} }]);
  const [amzAllGraphData, setAmzAllGraphData] = useState([{}]);
  const [amzAllGraphLoad, setAmzAllGraphLoad] = useState(false);
  const [amzAllSales, setAmzAllSales] = useState(0);
  const [amzAllSpend, setAmzAllSpend] = useState(0);
  const [amzAllById, setAmzAllById] = useState([]);
  const [amzInternalGraphData, setAmzInternalGraphData] = useState([{}]);
  const [amzInternalGraphLoad, setAmzInternalGraphLoad] = useState(false);
  const [amzInternalSales, setAmzInternalSales] = useState(0);
  const [amzInternalSpend, setAmzInternalSpend] = useState(0);
  const [amzInternalById, setAmzInternalById] = useState([]);

  const [wmGraphData, setWmGraphData] = useState([{}]);
  const [wmAllGraphData, setWmAllGraphData] = useState([{}]);
  const [wmAllGraphLoad, setWmAllGraphLoad] = useState(false);
  const [wmAllSales, setWmAllSales] = useState(0);
  const [wmAllSpend, setWmAllSpend] = useState(0);
  const [wmAllById, setWmAllById] = useState([]);

  const [wmInternalGraphData, setWmInternalGraphData] = useState([{}]);
  const [wmInternalGraphLoad, setWmInternalGraphLoad] = useState(false);
  const [wmInternalSales, setWmInternalSales] = useState(0);
  const [wmInternalSpend, setWmInternalSpend] = useState(0);
  const [wmInternalById, setWmInternalById] = useState([]);

  const [retailView, setRetailView] = useState('amazon');
  const [series, setSeries] = useState([
    {
      type: 'line',
      xKey: 'report_date',
      yKey: 'all.spend',
      yName: 'All Spend',
    },
    {
      type: 'line',
      xKey: 'report_date',
      yKey: 'all.sales',
      yName: 'All Sales',
    },
    {
      type: 'line',
      xKey: 'report_date',
      yKey: 'internal.spend',
      yName: 'Internal Spend',
    },
    {
      type: 'line',
      xKey: 'report_date',
      yKey: 'internal.sales',
      yName: 'Internal Sales',
    },
  ]);
  const [reportStartDate] = useState(moment().startOf('month').format('YYYYMMDD'));
  const [reportEndDate] = useState(moment().format('YYYYMMDD'));
  const [amzAllReportDays, setAmzAllReportDays] = useState(0);
  const [amzInternalReportDays, setAmzInternalReportDays] = useState(0);
  const [wmAllReportDays, setWmAllReportDays] = useState(0);
  const [wmInternalReportDays, setWmInternalReportDays] = useState(0);

  const getAmzData = useCallback(() => {
    setAmzAllGraphLoad(true);
    setAmzAllGraphData([{}]);
    setAmzAllSales(0);
    setAmzAllSpend(0);
    setAmzAllById(null);
    setAmzInternalGraphLoad(true);
    setAmzInternalGraphData([{}]);
    setAmzInternalSales(0);
    setAmzInternalSpend(0);
    setAmzInternalById(null);
    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    const allBudgetUrl = `${apiUrl}/customers/ppc-sales-spend?sellerAccountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}&startDate=${reportStartDate}&endDate=${reportEndDate}&accountType=${sellerSelect.type}`;
    fetch(allBudgetUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        const amzAllGraphDataSorted = data.by_date.sort((a, b) => {
          const checkPos = (b.report_date > a.report_date) ? -1 : 0;
          return (b.report_date < a.report_date) ? 1 : checkPos;
        });

        setAmzAllSales(data.totals.total_sales);
        setAmzAllSpend(data.totals.total_spend);
        setAmzAllById(data.by_id);
        setAmzAllReportDays(data.by_date.length);
        setAmzAllGraphLoad(false);
        setAmzAllGraphData(amzAllGraphDataSorted);
      }).catch(() => {
        setAmzAllGraphLoad(false);
      });

    const internalBudgetUrl = `${apiUrl}/customers/ppc-sales-spend?sellerAccountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}&startDate=${reportStartDate}&endDate=${reportEndDate}&accountType=${sellerSelect.type}&internal=true`;
    fetch(internalBudgetUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        const amzInternalGraphDataSorted = data.by_date.sort((a, b) => {
          const checkPos = (b.report_date > a.report_date) ? -1 : 0;
          return (b.report_date < a.report_date) ? 1 : checkPos;
        });

        setAmzInternalSales(data.totals.total_sales);
        setAmzInternalSpend(data.totals.total_spend);
        setAmzInternalById(data.by_id);
        setAmzInternalReportDays(data.by_date.length);
        setAmzInternalGraphLoad(false);
        setAmzInternalGraphData(amzInternalGraphDataSorted);
      }).catch(() => {
        setAmzInternalGraphLoad(false);
      });
  }, [sellerSelect.value]);

  const getWmData = useCallback(() => {
    setWmAllGraphLoad(true);
    setWmAllGraphData([{}]);
    setWmAllSales(0);
    setWmAllSpend(0);
    setWmAllById(null);
    setWmInternalGraphLoad(true);
    setWmInternalGraphData([{}]);
    setWmInternalSales(0);
    setWmInternalSpend(0);
    setWmInternalById(null);

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    const allStatsUrl = `${apiUrl}/walmart/query/ad-items-by-dates-breakdown?accountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}&startDate=${reportStartDate}&endDate=${reportEndDate}`;
    fetch(allStatsUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        const wmGraphDataSorted = data.by_date.sort((a, b) => {
          const checkPos = (b.report_date > a.report_date) ? -1 : 0;
          return (b.report_date < a.report_date) ? 1 : checkPos;
        });

        setWmAllSales(data.totals.total_sales);
        setWmAllSpend(data.totals.total_spend);
        setWmAllById(data.by_id);
        setWmAllReportDays(data.by_date.length);
        setWmAllGraphLoad(false);
        setWmAllGraphData(wmGraphDataSorted);
      }).catch(() => {
        setWmAllGraphLoad(false);
      });

    const internalStatsUrl = `${apiUrl}/walmart/query/ad-items-by-dates-breakdown?accountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}&startDate=${reportStartDate}&endDate=${reportEndDate}&internal=true`;
    fetch(internalStatsUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        const wmGraphDataSorted = data.by_date.sort((a, b) => {
          const checkPos = (b.report_date > a.report_date) ? -1 : 0;
          return (b.report_date < a.report_date) ? 1 : checkPos;
        });

        setWmInternalSales(data.totals.total_sales);
        setWmInternalSpend(data.totals.total_spend);
        setWmInternalById(data.by_id);
        setWmInternalReportDays(data.by_date.length);
        setWmInternalGraphLoad(false);
        setWmInternalGraphData(wmGraphDataSorted);
      }).catch(() => {
        setWmInternalGraphLoad(false);
      });
  }, [sellerSelect.value]);


  useEffect(() => {
    getAmzData();
    getWmData();
  }, [sellerSelect.value]);


  useEffect(() => {
    const combinedGraphData = [];
    amzAllGraphData.forEach((allRow => {
      const internalRow = amzInternalGraphData.find(row => row.report_date === allRow.report_date);
      combinedGraphData.push({ report_date: allRow.report_date, all: allRow, internal: internalRow });
    }))
    setAmzGraphData(combinedGraphData);
  }, [amzAllGraphData, amzInternalGraphData]);

  useEffect(() => {
    const combinedGraphData = [];
    wmAllGraphData.forEach((allRow => {
      const internalRow = wmInternalGraphData.find(row => row.report_date === allRow.report_date);
      combinedGraphData.push({ report_date: allRow.report_date, all: allRow, internal: internalRow });
    }));

    setWmGraphData(combinedGraphData);
  }, [wmAllGraphData, wmInternalGraphData]);

  const graphChange = (e) => {
    // Default is TOTAL
    const key = {
      label: '',
      spend: 'spend',
      sales: 'sales',
    }
    if (e.target.value === 'sp') {
      key.label = 'Sponsored Products ';
      key.spend = 'sp_spend';
      key.sales = 'sp_sales';
    }
    if (e.target.value === 'sd') {
      key.label = 'Sponsored Display ';
      key.spend = 'sd_spend';
      key.sales = 'sd_sales';
    }
    if (e.target.value === 'sb') {
      key.label = 'Sponsored Brands ';
      key.spend = 'sb_spend';
      key.sales = 'sb_sales';
    }
    if (e.target.value === 'sb') {
      key.label = 'Sponsored Brands Video ';
      key.spend = 'sbv_spend';
      key.sales = 'sbv_sales';
    }
    if (e.target.value === 'st') {
      key.label = 'Sponsored Telivision ';
      key.spend = 'st_spend';
      key.sales = 'st_sales';
    }
    setSeries([
      {
        type: 'line',
        xKey: 'report_date',
        yKey: `all.${key.spend}`,
        yName: `All ${key.label}Spend`,
      },
      {
        type: 'line',
        xKey: 'report_date',
        yKey: `all.${key.sales}`,
        yName: `All ${key.label}Sales`,
      },
      {
        type: 'line',
        xKey: 'report_date',
        yKey: `internal.${key.spend}`,
        yName: `Internal ${key.label}Spend`,
      },
      {
        type: 'line',
        xKey: 'report_date',
        yKey: `internal.${key.sales}`,
        yName: `Internal ${key.label}Sales`,
      },
    ])
  };

  const retailerChange = (e) => {
    graphChange(e);
    setRetailView(e.target.value);
  };

  const gridTheme = theme.className.endsWith('light') ? 'ag-theme-balham' : 'ag-theme-balham-dark';

  const chartOptions = {
    theme: theme.className.endsWith('light') ? 'ag-default' : 'ag-default-dark',
    data: retailView === 'amazon' ? amzGraphData : wmGraphData,
    series,
    title: {
      text: `${retailView.charAt(0).toUpperCase() + retailView.slice(1)} Budget Graph`,
    },
    // subtitle: {
    //   text: '2024',
    // },
  };

  return (
    <Container className="dashboard">
      <Col md={12}>
        <Row>
          <CardPanel
            showButtons={false}
          >
            <h4>
              <div style={{ width: '100%', display: 'table-cell' }}>
                Retailer: &nbsp;
                <select onChange={(e) => retailerChange(e)} className="select-options">
                  <option value="amazon">Amazon</option>
                  <option value="walmart">Walmart</option>
                </select>
              </div>
              {retailView === 'amazon' && (
                <div style={{
                  width: '100%', display: 'table-cell', justifyContent: 'flex-end', whiteSpace: 'nowrap',
                }}
                >
                  Amazon Graph Data: &nbsp;
                  <select onChange={(e) => graphChange(e)} className="select-options">
                    <option value="total">Total</option>
                    <option value="sp">Sponsored Product</option>
                    <option value="sd">Sponsored Display</option>
                    <option value="sb">Sponsored Brand</option>
                    <option value="sbv">Sponsored Brand Video</option>
                    <option value="st">Sponsored Telivision</option>
                  </select>
                </div>
              )}
            </h4>
          </CardPanel>
        </Row>
      </Col>
      {retailView === 'amazon' && (
        <>
          <Col md={12}>
            <Row>
              <CardPanel
                showButtons={false}
                parentRefresh={amzAllGraphLoad || amzInternalGraphLoad}
              >
                <ChartBuilder chartOptions={chartOptions} />
              </CardPanel>
            </Row>
          </Col>
          <Col md={12}>
            <Row>
              <BudgetTable
                title="Amazon Budget"
                allSpend={amzAllSpend}
                allSales={amzAllSales}
                allById={amzAllById}
                allByDate={amzAllGraphData}
                allGraphLoad={amzAllGraphLoad}
                internalSpend={amzInternalSpend}
                internalSales={amzInternalSales}
                internalById={amzInternalById}
                internalByDate={amzInternalGraphData}
                internalGraphLoad={amzInternalGraphLoad}
                retailer="Amazon"
                gridTheme={gridTheme}
                reportDays={{ allReportDays: amzAllReportDays, internalReportDays: amzInternalReportDays }}
              />
            </Row>
          </Col>
        </>
      )}
      {retailView === 'walmart' && (
        <>
          <br />
          <br />
          <Row>
            <CardPanel
              showButtons={false}
              parentRefresh={wmAllGraphLoad || wmInternalGraphLoad}
            >
              <ChartBuilder chartOptions={chartOptions} />
            </CardPanel>
          </Row>
          <Col md={12}>
            <Row>
              <BudgetTable
                title="Walmart Budget"
                allSpend={wmAllSpend}
                allSales={wmAllSales}
                allById={wmAllById}
                allByDate={wmAllGraphData}
                allGraphLoad={wmAllGraphLoad}
                internalSpend={wmInternalSpend}
                internalSales={wmInternalSales}
                internalById={wmInternalById}
                internalByDate={wmInternalGraphData}
                internalGraphLoad={wmInternalGraphLoad}
                retailer="Walmart"
                gridTheme={gridTheme}
                reportDays={{ allReportDays: wmAllReportDays, internalReportDays: wmInternalReportDays }}
              />
            </Row>
          </Col>
        </>
      )}
    </Container>
  );
}
