import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { AccountSettingGroupShape } from './services';
import { fieldStyle } from '../../../../../helpers';

export default function DisplaySettingGroup({
  item, toggleEdit, doDelete,
}) {
  return (
    <Fragment>
      <td style={fieldStyle}>{ item.settingGroupKey}</td>
      <td style={fieldStyle}>{ item.settingGroupValue}</td>
      <td style={fieldStyle}>{ item.settingGroupAccessControl}</td>
      <td style={fieldStyle}>
        {item.settingGroupKey !== '' && (
          <Fragment>
            <button type="button" className="badge badge-success badge-btn" onClick={() => toggleEdit(item)}>Edit</button>
            <span>&nbsp;&nbsp;</span>
            <button type="button" className="badge badge-danger badge-btn" onClick={event => doDelete(event, item)}>Delete</button>
          </Fragment>
        )}
      </td>
    </Fragment>
  );
}

DisplaySettingGroup.propTypes = {
  item: PropTypes.shape(AccountSettingGroupShape).isRequired,
  toggleEdit: PropTypes.func.isRequired,
  doDelete: PropTypes.func.isRequired,
};
