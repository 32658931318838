import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import GetStarted from './components/GetStarted';
import { SiteName } from '../../shared/components/domainSupport';

const Home = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Getting Started with {SiteName()}</h3>
      </Col>
    </Row>
    <Row>
      <GetStarted />
    </Row>
  </Container>
);

export default Home;
