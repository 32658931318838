import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Container, Row, Col,
} from 'reactstrap';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import config from '../../../../config';
import CardPanel from '../../../../shared/components/widgets/CardPanel';
import { SiteImage, getThemeName } from '../../../../shared/components/domainSupport';
import { sellerSelectActions } from '../../../../redux/actions';
import {
  planConfig, stripeKey,
} from '../config';
import PlanView from './components/PlanView';
import PlanEstimator from './components/PlanEstimator';
import CheckoutForm from './components/CheckoutForm';
import PriceTiersModal from './components/PriceTiersModal';
import CancelModal from './components/CancelModal';
import ReactivateModal from './components/ReactivateModal';
import { formatNumberTwoPlaces } from '../../../../shared/components/table/functions';

const stripePromise = loadStripe(stripeKey);
// const mode = new URLSearchParams(window.location.search).get('mode');

export default function UpgradePage({
  clientSecret, setClientSecret, selectedPlan, setSelectedPlan, selectedSubscriptionId, setSelectedSubscriptionId,
}) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.authentication.user);
  const sellerSelect = useSelector(state => state.sellerSelect);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPriceTiers, setShowPriceTiers] = useState(false);
  const [showPlanEstimator, setShowPlanEstimator] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [showReactivate, setShowReactivate] = useState(false);
  const [trialReady, setTrialReady] = useState(false);
  const [newPaymentMethodId, setNewPaymentMethodId] = useState(null);
  const [newPaymentMethodClientSecret, setNewPaymentMethodClientSecret] = useState(null);
  const [invalidCouponMessage, setInvalidCouponMessage] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [applyCouponCode, setApplyCouponCode] = useState(false);
  const [origDueToday, setOrigDueToday] = useState(0.00);
  const [dueToday, setDueToday] = useState(99.00);

  const currentPlan = sellerSelect.plan ? sellerSelect.plan.toUpperCase() : 'TRIAL';
  const currentPlanStatus = sellerSelect.planStatus ? sellerSelect.planStatus.toUpperCase() : 'NEW';
  const currentDate = new Date();
  const nextBillDate = currentDate > new Date(`${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-4`) ? new Date(`${currentDate.getFullYear()}-${currentDate.getMonth() + 2}-4`) : new Date(`${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-4`);

  // const { products, tagLine, basePlan } = planConfig;
  const { products, tagLine } = planConfig;
  const flexPlan = products.find(packageObj => packageObj.name === 'FLEX')

  useEffect(() => {
    const trialSettingsUrl = `${config.serverUrl}/onboarding/trial-settings?accountId=${sellerSelect.value}`;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    fetch(trialSettingsUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        const lockData = data.find(row => row.setting_key === 'lockData') || 'FALSE';
        setTrialReady(lockData.setting_value === 'TRUE')
      });
  }, []);

  const submitStartNew = (plan, newCouponCode = null) => {
    setLoadingPayment(true);
    setErrorMessage('');
    setInvalidCouponMessage('');
    const subscriptionUrl = `${config.serverUrl}/subscriptions`;

    const postData = {
      customerId: sellerSelect.customerId,
      plan,
      promotionCode: newCouponCode,
    }

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
    const requestOptions = {
      method: 'POST',
      headers: headerWithAuth,
      body: JSON.stringify(postData),
    };

    fetch(subscriptionUrl, requestOptions)
      .then(async (results) => {
        if (!results.ok) {
          let error = results.statusText;
          try {
            await results.json().then(result => {
              error = result.status;
            });
          } catch {
            // Error response not json
          }
          throw error;
        }
        return results.json();
      }).then((data) => {
        if (!newCouponCode) {
          setOrigDueToday(data.amountDue);
        }
        setClientSecret(data.clientSecret);
        setSelectedPlan(plan);
        setSelectedSubscriptionId(data.subscriptionId);
        setDueToday(data.amountDue);
        setLoadingPayment(false);
      }).catch((error) => {
        let reference = '';
        if (user.access === 'admin') {
          reference = `Reference: ${error}`;
        }
        if (error === 'invalid_promotion_code') {
          setInvalidCouponMessage('Invalid Coupon Code entered.');
        } else {
          setErrorMessage(`We are having trouble creating the payment form. ${reference}`);
        }
        setApplyCouponCode(false);
        setLoadingPayment(false);
      });
  }

  useEffect(() => {
    if ((currentPlan === 'TRIAL' && !selectedSubscriptionId && !clientSecret && !selectedSubscriptionId) || (currentPlan === 'FLEX' && currentPlanStatus === 'PENDING' && !selectedSubscriptionId)) {
      submitStartNew(flexPlan.name);
    }
  }, [currentPlan]);

  useEffect(() => {
    if (applyCouponCode) {
      submitStartNew(flexPlan.name, couponCode);
    }
  }, [applyCouponCode]);

  const submitChangePlanStatus = (newPlanStatus) => {
    setLoadingPayment(true);
    setErrorMessage('');
    setInvalidCouponMessage('');
    const subscriptionUrl = `${config.serverUrl}/subscriptions/${sellerSelect.subscriptionId}`;

    const postData = {
      planStatus: newPlanStatus,
    }

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
    const requestOptions = {
      method: 'POST',
      headers: headerWithAuth,
      body: JSON.stringify(postData),
    };

    if (sellerSelect.subscriptionId) {
      fetch(subscriptionUrl, requestOptions)
        .then((results) => {
          if (!results.ok) {
            throw Error(results.statusText);
          }
          return results.json();
        }).then(() => {
          dispatch(sellerSelectActions
            .sellerSelectChange(
              sellerSelect.value,
              sellerSelect.label,
              sellerSelect.type,
              sellerSelect.currencySymbol,
              sellerSelect.currencyCode,
              sellerSelect.parentAccountId,
              sellerSelect.mpName,
              sellerSelect.agency,
              sellerSelect.platform,
              sellerSelect.subscriptionId,
              sellerSelect.plan,
              newPlanStatus,
              sellerSelect.externalCustomerId,
              sellerSelect.externalAccountId,
              sellerSelect.customerId,
              Date.now(),
            ));
          setLoadingPayment(false);
        }).catch(() => {
          setErrorMessage('We were not able to update your subscription.');
          setLoadingPayment(false);
        });
    } else {
      setErrorMessage('We were not able to find your subscription.');
      setLoadingPayment(false);
    }
  }

  const startPaymentUpdate = () => {
    setLoadingPayment(true);
    setErrorMessage('');
    setInvalidCouponMessage('');
    const paymentMethodsUrl = `${config.serverUrl}/payment-methods`;

    const postData = {
      customerId: sellerSelect.customerId,
    }

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
    const requestOptions = {
      method: 'POST',
      headers: headerWithAuth,
      body: JSON.stringify(postData),
    };

    fetch(paymentMethodsUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((newPaymentMethod) => {
        setLoadingPayment(false);
        setSelectedSubscriptionId(sellerSelect.subscriptionId);
        setNewPaymentMethodId(newPaymentMethod.id);
        setNewPaymentMethodClientSecret(newPaymentMethod.clientSecret);
      }).catch(() => {
        setLoadingPayment(false);
        setErrorMessage('We were not able to update your subscription.');
      });
  }

  const updatePaymentoptions = {
    clientSecret: newPaymentMethodClientSecret,
    appearance: {
      theme: getThemeName().endsWith('light') ? 'stripe' : 'night',
    },
  };

  const options = {
    clientSecret,
    appearance: {
      theme: getThemeName().endsWith('light') ? 'stripe' : 'night',
    },
  };

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <CardPanel
            showButtons={false}
          >
            <Row>
              <div style={{
                display: 'flex', margin: 'auto', maxWidth: '1000px',
              }}
              >
                <SiteImage />
              </div>
            </Row>
            <Row>
              &nbsp;
            </Row>
            <Row>
              <Col>
                <div style={{ textAlign: 'center' }}>
                  <h2>My Plan</h2>
                  <p>{tagLine}</p>
                </div>
              </Col>
            </Row>
            <PlanView
              flexPlan={flexPlan}
              setShowPlanEstimator={setShowPlanEstimator}
              showPlanEstimator={showPlanEstimator}
              setShowPriceTiers={setShowPriceTiers}
              currentPlan={currentPlan}
              currentPlanStatus={currentPlanStatus}
              nextBillDate={nextBillDate}
              sellerSelect={sellerSelect}
              submitStartNew={submitStartNew}
              setShowCancel={setShowCancel}
              setShowReactivate={setShowReactivate}
              loadingPayment={loadingPayment}
              selectedSubscriptionId={selectedSubscriptionId}
            />
            <Row>
              {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
            </Row>
          </CardPanel>
        </Col>
      </Row>
      <Row>
        {currentPlan === 'TRIAL' && !trialReady && (
          <Col md={12}>
            <CardPanel
              showButtons={false}
            >
              <Row>
                <Col md={12}>
                  <div style={{ textAlign: 'center' }}>
                    <h2>Trial Settings are Required</h2>
                    <br />
                    <p>
                      Finish filling out your trial settings to be eligible for a plan.  Your trial settings can be found on the home page:
                      <br />
                      <br />
                      <Link className="btn btn-outline-primary account__btn" style={{ width: '500px' }} to={'/'}>Trial Settings Page</Link>
                    </p>
                  </div>
                </Col>
              </Row>
            </CardPanel>
          </Col>
        )}
      </Row>
      <Row>
        {newPaymentMethodId && newPaymentMethodClientSecret && (
          <Col md={12}>
            <CardPanel
              showButtons={false}
              parentRefresh={loadingPayment}
            >
              <Row>
                <Col md={12}>
                  <h2>Update Payment</h2>
                  <br />
                  <p>Please fill out the following payment information and hit submit to update your plans payment method.</p>
                  <br />
                  {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
                  <br />
                  <Elements stripe={stripePromise} options={updatePaymentoptions}>
                    <CheckoutForm selectedSubscriptionId={selectedSubscriptionId} newPaymentMethodId={newPaymentMethodId} />
                  </Elements>
                </Col>
              </Row>
            </CardPanel>
          </Col>
        )}
      </Row>
      <Row>
        {(currentPlan === 'TRIAL' || (currentPlan === 'FLEX' && (currentPlanStatus === 'PENDING' || currentPlanStatus === 'CANCELLED'))) && selectedSubscriptionId && trialReady && (
          <Col md={12}>
            <CardPanel
              showButtons={false}
              parentRefresh={loadingPayment}
            >
              <Row>
                <Col md={12}>
                  <h2>Checkout</h2>
                  <br />
                  <br />
                  <table>
                    <tr>
                      <td>
                        <h3>$99 {selectedPlan} Plan</h3>
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        <h3>${formatNumberTwoPlaces(origDueToday)}</h3>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <h6>Prorated for the first Month</h6>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h3>Subtotal</h3>
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        <h3>${formatNumberTwoPlaces(origDueToday)}</h3>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <h3>&nbsp;</h3>
                      </td>
                    </tr>
                    {!applyCouponCode && (
                      <tr>
                        <td colSpan="2">
                          <input
                            className="form__theme input_round"
                            style={{ borderWidth: '3px' }}
                            type="text"
                            id="couponCode"
                            name="couponCode"
                            value={couponCode}
                            onChange={e => setCouponCode(e.target.value?.toUpperCase())}
                          /> <button className="btn btn-primary" style={{ verticalAlign: 'top' }} type="submit" onClick={() => setApplyCouponCode(true)}>Apply Coupon Code</button>
                          {invalidCouponMessage && <><br /><div style={{ color: 'red' }}>{invalidCouponMessage}</div></>}
                        </td>
                      </tr>
                    )}
                    {applyCouponCode && (
                      <tr>
                        <td>
                          <h5>Coupon code {couponCode} has been applied</h5>
                        </td>
                        <td>
                          <h3 style={{ textAlign: 'right' }}>-${formatNumberTwoPlaces(origDueToday - dueToday)}</h3>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td colSpan="2">
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h3>Total due today</h3>
                      </td>
                      <td style={{ textAlign: 'right', width: '20%' }}>
                        <h3>${formatNumberTwoPlaces(dueToday)}</h3>
                      </td>
                    </tr>
                  </table>
                  <br />
                  <br />
                  <p>Please fill out the billing information and hit submit to start your plan.  Once complete, this plan will work across all the marketplaces you onboard.</p>
                  <br />
                  {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
                  <br />
                  <Elements stripe={stripePromise} options={options}>
                    <CheckoutForm selectedSubscriptionId={selectedSubscriptionId} />
                  </Elements>
                </Col>
              </Row>
            </CardPanel>
          </Col>
        )}
      </Row>
      {showPlanEstimator && (
        <Row>
          <Col md={12}>
            <CardPanel
              showButtons={false}
            >
              <Row>
                <Col>
                  <div style={{ textAlign: 'center' }}>
                    <h3>Plan Estimator</h3>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div style={{ margin: 'auto', maxWidth: '1400px' }}>
                    <PlanEstimator />
                  </div>
                </Col>
              </Row>
              <Row>
                &nbsp;
              </Row>
            </CardPanel>
          </Col>
        </Row>
      )}
      <PriceTiersModal
        showModal={showPriceTiers}
        toggleModal={() => setShowPriceTiers(!showPriceTiers)}
      />
      <CancelModal
        showModal={showCancel}
        toggleModal={() => setShowCancel(!showCancel)}
        nextBillDate={nextBillDate}
        cancelPlanFunction={submitChangePlanStatus}
        updatePaymentFunction={startPaymentUpdate}
      />
      <ReactivateModal
        showModal={showReactivate}
        toggleModal={() => setShowReactivate(!showReactivate)}
        nextBillDate={nextBillDate}
        reactivatePlanFunction={submitChangePlanStatus}
      />
    </Container>
  );
}
