import React from 'react';
import ChartBuilder from '../../../../shared/components/chart/ChartBuilder';

export default function SearchTrendsChart(props) {
  const { parentRefresh, chartData, chartHeight, chartTheme } = props;

  const renderTooltip = ({ datum, yKey }) => ({
    content: `${datum.reportDate} : ${Math.trunc(datum[yKey])}`,
  });

  const chartOptions = {
    theme: chartTheme,
    data: chartData,
    series: [
      { type: 'bar', xKey: 'reportDate', stackGroup: 'DAY', yKey: 'position1', yName: '1st Position', tooltip: { renderer: renderTooltip } },
      { type: 'bar', xKey: 'reportDate', stackGroup: 'DAY', yKey: 'position2', yName: '2nd Position', tooltip: { renderer: renderTooltip } },
      { type: 'bar', xKey: 'reportDate', stackGroup: 'DAY', yKey: 'position3', yName: '3rd Position', tooltip: { renderer: renderTooltip } },
    ],
    title: {
      text: 'Advertiser Items in Top 3 Positions',
    },
  };

  return (
    <ChartBuilder chartOptions={chartOptions} parentRefresh={parentRefresh} chartHeight={chartHeight} />
  )
}
