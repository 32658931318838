import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import config from '../../../../config';
import { SiteName } from '../../../../shared/components/domainSupport';
import LoadingSpinner from '../../../../shared/components/widgets/LoadingSpinner';


const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class OnbaordLinkAds extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      authed: false,
      errorMsg: '',
      customerOnboardingId: 0,
    };
  }

  componentDidMount() {
    // Get Amazon account info that we can link to
    const urlParams = new URLSearchParams(window.location.search);
    const paramMwsAuthToken = urlParams.get('mws_auth_token') || 'NA';
    const paramSellingPartnerId = urlParams.get('selling_partner_id');
    const paramCode = urlParams.get('spapi_oauth_code');
    const paramState = urlParams.get('state');

    if (paramCode && paramState && paramSellingPartnerId) {
      const authGrantUrl = `${apiUrl}/onboarding/auth-sp-api-complete?code=${paramCode}&state=${paramState}&site=${SiteName()}&mwsAuthToken=${paramMwsAuthToken}&sellingPartnerId=${paramSellingPartnerId}`;

      fetch(authGrantUrl)
        .then((results) => {
          if (!results.ok) {
            throw results;
          }
          return results.json();
        }).then(() => {
          this.setState({
            customerOnboardingId: paramState,
            loading: false,
            authed: true, // if this was a success, but there were no vendor profiles, we assume it was just a resync Ad account attempt (clean up later and verify that the Ad account is in our system)
          });
        }).catch((error) => {
          error.json().then((data) => {
            this.setState({
              errorMsg: `SP-API amazon_grant_failure ${data.statusMessage}`,
              loading: false,
            });
          }).catch(() => {
            this.setState({
              errorMsg: 'SP-API amazon_grant_failure: Generic Error',
              loading: false,
            });
          });
        });
    } else {
      this.setState({
        errorMsg: 'SP-API amazon_parameter_failure: Parameters missing or incorrect.',
        loading: false,
      });
    }
  }

  render() {
    const {
      customerOnboardingId, errorMsg, authed, loading,
    } = this.state;

    return (
      <Col md={12}>
        <Card>
          <Row>
            <Col md={5}>
              {!authed && loading && (
                <div className="card-body">
                  <p>Performing authorization for {SiteName()}.  This page will get updated when the sync is complete.</p>
                  <br />
                  <br />
                  <div style={{ textAlign: 'center', width: '100%' }}>
                    <LoadingSpinner
                      active={loading}
                    />
                  </div>
                </div>
              )}
              {!authed && !loading && (
                <CardBody>
                  <div className="card-body">
                    <h3>We are having trouble validating that you have Authorized our App.</h3>
                    <p>If you contine to get this error, pelase work with our representive to troubleshoot.</p>
                    <br />
                    <br />
                    <p>Thank You.</p>
                    <br />
                    <p>Reference ID: {customerOnboardingId}</p>
                    <p>{errorMsg}</p>
                  </div>
                </CardBody>
              )}
              {authed && (
                <CardBody>
                  <div className="card-body">
                    <h3>You have successfully authorised us to use the Selling Partner API.</h3>
                    <br />
                    <br />
                    <p>Thank You.</p>
                    <br />
                    <p>Reference ID: {customerOnboardingId}</p>
                  </div>
                </CardBody>
              )}
            </Col>
          </Row>
        </Card>
      </Col>
    );
  }
}

export default OnbaordLinkAds;
