import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import moment from 'moment';
import DateRangePicker from '../../../../../shared/components/widgets/DateRangePicker';
import GridTools from './GridTools';

const DatePickerInfoShape = {
  inputStart: PropTypes.instanceOf(Date),
  inputEnd: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
};

const GridInfoShape = {
  columnDefs: PropTypes.instanceOf(Array),
  defaultColDef: PropTypes.instanceOf(Object),
  dataSource: PropTypes.instanceOf(Object),
  paginationPageSize: PropTypes.number,
};

const PivotButtonShape = {
  buttonName: PropTypes.string,
  buttonAction: PropTypes.string,
};

export default function PpcReportGrid({
  gridInfo, datePickerInfo, pivotButtons, setPivotName, exportPrefix,
}) {
  const { theme } = useSelector(state => state);
  const [api, setApi] = useState(null);
  const [columnApi, setColumnApi] = useState(null);
  const {
    columnDefs,
    defaultColDef,
    // autoGroupColumnDef,
    dataSource,
    paginationPageSize,
  } = gridInfo;
  const {
    inputStart,
    inputEnd,
    onChange,
  } = datePickerInfo;

  const tableHeight = '75vh';
  let useTheme = 'ag-theme-balham';
  if (!theme.className.endsWith('light')) {
    useTheme = 'ag-theme-balham-dark';
  }

  function onGridReady(params) {
    setApi(params.api);
    setColumnApi(params.columnApi);
    params.api.setServerSideDatasource(dataSource);
  }

  function displayBy(value) {
    setPivotName(value);
  }

  function onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  const ShowButtons = ({ buttons }) => buttons.map(aButton => (
    <button key={aButton.buttonName} type="button" className="btn btn-primary" onClick={() => displayBy(aButton.buttonAction)}>{aButton.buttonName}</button>
  ));

  useEffect(() => {
    if (api && dataSource) {
      api.setServerSideDatasource(dataSource);
    }
  }, [api, dataSource]);

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <Row>
            <Col md={12}>
              <div className="topbar__wrapper">
                <div className="topbar__left">
                  <DateRangePicker
                    startDate={moment(inputStart).toDate()}
                    endDate={moment(inputEnd).toDate()}
                    onDateChange={onChange}
                  />
                </div>
                <div className="topbar__right">
                  <GridTools
                    options={{
                      gridApi: api,
                      gridColumnApi: columnApi,
                      startDate: inputStart,
                      endDate: inputEnd,
                      exportPrefix,
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
          {pivotButtons && (
            <Row>
              <Col md={12}>
                <ShowButtons
                  buttons={pivotButtons}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col md={12}>
              <div className={useTheme} style={{ height: tableHeight, width: '100%' }}>
                <AgGridReact
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  onGridReady={params => onGridReady(params)}
                  rowModelType="serverSide"
                  suppressServerSideInfiniteScroll={false}
                  editType="fullRow"
                  rowSelection="multiple"
                  sideBar={false}
                  paginationPageSize={paginationPageSize}
                  cacheBlockSize={paginationPageSize}
                  serverSideInfiniteScroll
                  suppressAggFuncInHeader
                  enableRangeSelection
                  pagination
                  pivotMode
                  enableCharts
                  onFirstDataRendered={onFirstDataRendered}
                />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
}

PpcReportGrid.propTypes = {
  gridInfo: PropTypes.shape(GridInfoShape).isRequired,
  datePickerInfo: PropTypes.shape(DatePickerInfoShape).isRequired,
  pivotButtons: PropTypes.arrayOf(PropTypes.shape(PivotButtonShape)).isRequired,
  setPivotName: PropTypes.func.isRequired,
};
