import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import FilterSelect from './NewFilterSelect';
import useFilterData from '../hooks/useFilterData';
import config from '../../../configWrapper';

export { DEFAULT_ALL_FILTER_VALUE as ALL_PORTFOLIOS } from '../hooks/useFilterData';
export default function PortfolioFilter({
  onChange, disable,
}) {
  const sellerSelect = useSelector(state => state.sellerSelect);
  const url = `${config.serverUrl}/customers/portfolios?sellerAccountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}`;
  const fields = {
    id: 'portfolioId',
    name: 'name',
  };
  const { data } = useFilterData(url, fields);
  const [selected, setSelected] = useState(data[0]);

  useEffect(() => {
    if (selected) {
      onChange(selected);
    }
  }, [selected]);

  return (
    <FilterSelect
      onChange={obj => setSelected(obj)}
      label="Portfolio Filter:"
      name="portfolioOption"
      options={data}
      selected={selected}
      disable={disable}
    />
  );
}

PortfolioFilter.defaultProps = {
  disable: false,
};

PortfolioFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  disable: PropTypes.bool,
};
