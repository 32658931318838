import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';

const SidebarLink = ({
  title, icon, newLink, betaLink, devLink, adminLink, route, onClick,
}) => (
  <NavLink
    to={route}
    onClick={onClick}
    activeClassName="sidebar__link-active"
  >
    <li className="sidebar__link">
      {icon ? <span className={`sidebar__link-icon lnr lnr-${icon}`} /> : ''}
      <p className="sidebar__link-title">
        {title}
        {adminLink ? <Badge className="sidebar__link-badge-admin"><span>ADMIN</span></Badge> : ''}
        {betaLink ? <Badge className="sidebar__link-badge"><span>BETA</span></Badge> : ''}
        {devLink ? <Badge className="sidebar__link-badge-dev"><span>DEV</span></Badge> : ''}
        {newLink ? <Badge className="sidebar__link-badge-new"><span>NEW</span></Badge> : ''}
      </p>
    </li>
  </NavLink>
);

SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  betaLink: PropTypes.bool,
  newLink: PropTypes.bool,
  devLink: PropTypes.bool,
  adminLink: PropTypes.bool,
  route: PropTypes.string,
  onClick: PropTypes.func,
};

SidebarLink.defaultProps = {
  icon: '',
  betaLink: false,
  newLink: false,
  devLink: false,
  adminLink: false,
  route: '/',
  onClick: () => {},
};

export default SidebarLink;
