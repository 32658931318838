import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { Button, ButtonToolbar } from 'reactstrap';
import renderSelectField from '../../../../shared/components/form/Select';
import config from '../../../../config'

const EditWmCatalogModalForm = ({
  changeShowEditModal, selectedData, forceRefresh,
}) => {
  const sellerSelect = useSelector(store => store.sellerSelect);
  const user = useSelector(store => store.authentication.user);
  const formValues = useSelector(state => getFormValues('BulkWmManageCatalog')(state));
  const [showError, setShowError] = useState('');

  const statusOptions = [
    { label: 'ON', value: 'ON' },
    { label: 'OFF', value: 'OFF' },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    const adStatus = formValues.adStatus.value;

    // Build update data, sp and sd are tied to ad_status
    const updateData = [
      {
        name: 'ad_status',
        value: adStatus,
      },
    ];

    // Build update IDs
    const updateItemIds = [];
    if (selectedData && Array.isArray(selectedData)) {
      selectedData.forEach(selectedRow => {
        if (selectedRow.item_id) {
          updateItemIds.push(selectedRow.item_id);
        }
      });
    }

    const putData = {
      updateModel: 'Freemium',
      updateData,
      updateItemIds,
    }

    const catalogsUrl = `${config.serverUrl}/walmart/catalogs/${sellerSelect.value}`;
    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
    const requestOptions = {
      method: 'PUT',
      headers: headerWithAuth,
      body: JSON.stringify(putData),
    };

    fetch(catalogsUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then(() => {
        changeShowEditModal();
        forceRefresh();
        // window.location.reload();
      }).catch((error) => {
        console.error(error);
        setShowError('Failed to update rows.');
      });
  };

  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="form__form-group">
        <br />
      </div>
      <h3>{selectedData.length} items selected for update.</h3>
      <div className="form__form-group">
        <br />
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">AD Status:</span>
        <div className="form__form-group-field">
          <Field
            name="adStatus"
            component={renderSelectField}
            type="text"
            options={statusOptions}
            value={formValues?.adStatus}
          />
        </div>
      </div>
      <div className="form__form-group">
        <br />
      </div>
      {showError && (
        <div className="form__form-group-error">&nbsp;&nbsp;&nbsp;Error: {showError}</div>
      )}
      <ButtonToolbar className="form__button-toolbar">
        <Button color="primary" type="submit">
          Update Selected Rows
        </Button>
        <Button type="button" onClick={changeShowEditModal}>Cancel</Button>
      </ButtonToolbar>
    </form>
  );
}

EditWmCatalogModalForm.propTypes = {
  changeShowEditModal: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'BulkWmManageCatalog',
  initialValues: {
    adStatus: { label: 'ON', value: 'ON' },
  },
})(EditWmCatalogModalForm);
