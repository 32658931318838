import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import AccountSettingPage from './components/AccountSettingPage';

const AccountSetting = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Account Setting</h3>
        <h3 className="page-subhead subhead">Use Account Selector to choose the setting you want to manage.</h3>
      </Col>
    </Row>
    <Row>
      <br />
    </Row>
    <Row>
      <AccountSettingPage />
    </Row>
  </Container>
);

export default AccountSetting;
