import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import SearchTrendsReport from './components/SearchTrendsReport';
import walmartDataProvider from './walmartDataProvider'

const WalmartSearchTrendsPage = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Walmart Search Trends</h3>
        <br />
        <span>Searches that contain an Item from the catalog in one or more of the three positions.</span>
      </Col>
    </Row>
    <Row>
      <br />
    </Row>
    <Row>
      <SearchTrendsReport dataProvider={walmartDataProvider} />
    </Row>
  </Container>
);

export default WalmartSearchTrendsPage;
