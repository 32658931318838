import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'react-scroll';
import classNames from 'classnames';
import { Row, Col, Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import CloseIcon from 'mdi-react/CloseIcon';
import moment from 'moment';

const CancelModal = ({
  toggleModal, showModal, nextBillDate, cancelPlanFunction, updatePaymentFunction,
}) => {
  const theme = useSelector(store => store.theme);
  const modalClasses = classNames({
    'todo__add-modal': true,
  }, theme.className, 'ltr-support');

  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      className={modalClasses}
    >
      <button className="panel__btn" type="button" onClick={() => toggleModal(false)}><CloseIcon /></button><br /><h2>Update My Payment Method</h2>
      <br />
      <br />
      <Row>
        <Col md={12} lg={12} xl={12}>
          <p>Complete this flow to update your payment method.</p>
          <br />
          <Button activeClass="active" className="btn btn-primary account__btn" type="submit" value="Update Payment" to="updatePayment" onClick={() => { updatePaymentFunction(); toggleModal(false); }} spy smooth offset={50} duration={500} />
          <br />
          <br />
        </Col>
      </Row>
      <br />
      <br />
      <h2>Cancel My Plan</h2>
      <br />
      <Row>
        <Col md={12} lg={12} xl={12}>
          <div style={{ textAlign: 'left' }}>
            <p>Please confirm, once your subscription has been cacneled:</p>
            <br />
            <ul>
              <li key="noManage">We will no longer manage ADs</li>
              <li key="noAccess">Access to reports will be removed</li>
              <li key="comeBack">Come back any time, restart by adding a subscription</li>
            </ul>
          </div>
          <br />
          <p>Your subscription will end {moment(nextBillDate).format('YYYY-MM-DD')}, at which time you will be billed for this months addons and your subscription will be cancelled.</p>
          <br />
          <Button activeClass="active" className="btn btn-primary account__btn" type="submit" value="Cancel Plan" to="cancelPlan" onClick={() => { cancelPlanFunction('activeCancelled'); toggleModal(false); }} spy smooth offset={50} duration={500} />
        </Col>
      </Row>
    </Modal>
  );
};

CancelModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default CancelModal;
