import { history } from './history';

export async function checkResponse(response) {
  if (response.status === 401) {
    history.push('/logout');
  }
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response.json();
}

// No exception handling here, wrap this in try/catch
export async function doFetch(url, options) {
  const response = await fetch(url, options);
  const data = await checkResponse(response);
  return data;
}
