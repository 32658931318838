import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { SiteName } from '../../../shared/components/domainSupport';

export function getCurrentDate(separator = '') {
  const newDate = new Date();
  const date = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();
  return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}`;
}

const currentDate = getCurrentDate('-');

const Welcome = () => (
  <Col md={12}>
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">Getting Started with {SiteName()}</h5>
          <h5 className="subhead">Todays Date: {currentDate}</h5>
          <span className="card__logo" />
        </div>
      </CardBody>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">Lets get started:</h5>
        </div>

        <p>In order to get started you need to link your MWS and Product Ads accounts so we can pull data into our reports.</p>
        <p>To do this, you will click and follow the steps on our onboarding link</p>
        <br />
        <p>Welcome to the Unfair Advantage!</p>
      </CardBody>
    </Card>
  </Col>
);

export default Welcome;
