import React from 'react';
import PropTypes from 'prop-types';
import CardPanel from '../../../../shared/components/widgets/CardPanel';
import GraphicDataPointCircle from './GraphicDataPointCircle';

function calculate(myData, field) {
  let total = 0;
  if (myData) {
    total = myData.reduce((sum, item) => {
      if (item[field]) {
        return sum + item[field];
      }
      return sum;
    }, 0);
  }
  return total / myData.length;
}

function average(data, numberOfDays, field) {
  if (Array.isArray(data) && data.length > 0 && field && numberOfDays) {
    return calculate(data.slice(data.length - numberOfDays), field);
  }
  return undefined;
}
export default function OverTimeDataPoint({
  data, dayField, title, subtitle, formatFunction, loading,
}) {
  const sevenDay = average(data, 7, dayField);
  const fourteenDay = average(data, 14, dayField);
  const thirtyDay = average(data, 30, dayField);

  return (
    <CardPanel
      title={title}
      subhead={subtitle}
      parentRefresh={loading}
    >
      {!loading && data && (
        <div className="dashboard__overTime">
          <GraphicDataPointCircle
            label="7-Day"
            value={formatFunction(sevenDay)}
            color="#f47b50"
          />
          <GraphicDataPointCircle
            label="14-Day"
            value={formatFunction(fourteenDay)}
            color="#c88ffa"
          />
          <GraphicDataPointCircle
            label="30-Day"
            value={formatFunction(thirtyDay)}
            color="#fbb054"
          />
        </div>
      )}
    </CardPanel>
  );
}

OverTimeDataPoint.defaultProps = {
  formatFunction: (data => data),
  loading: false,
  data: null,
  subtitle: 'Total for Date Range',
};

OverTimeDataPoint.propTypes = {
  dayField: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Array),
  formatFunction: PropTypes.func,
  loading: PropTypes.bool,
  subtitle: PropTypes.string,
};
