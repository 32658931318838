import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import AcctMgmtBudgetReport from './components/AcctMgmtBudgetReport';

const AcctMgmtBudgetPage = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Account Management Budget Control and Report</h3>
      </Col>
    </Row>
    <Row>
      <AcctMgmtBudgetReport />
    </Row>
  </Container>
);

export default AcctMgmtBudgetPage;
