/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../../../../shared/components/Panel';

const PpcBestsellersButons = ({ setPivot, accountType }) => {
  if (accountType === 2) {
    return (
      <Panel lg={12} md={12}>
        <button type="submit" className="btn btn-primary" onClick={() => setPivot('ASIN')}>ASIN</button>
        <button type="submit" className="btn btn-primary" onClick={() => setPivot('PARENT_ASIN')}>Parent ASIN</button>
        <button type="submit" className="btn btn-primary" onClick={() => setPivot('BRAND')}>Brand</button>
        <button type="submit" className="btn btn-primary" onClick={() => setPivot('CATEGORY')}>Category</button>
        <button type="submit" className="btn btn-primary" onClick={() => setPivot('SUBCATEGORY')}>Subcategory</button>
        <button type="submit" className="btn btn-primary" onClick={() => setPivot('SEGMENT_ONE')}>Segment 1</button>
        <button type="submit" className="btn btn-primary" onClick={() => setPivot('SEGMENT_TWO')}>Segment 2</button>
        <button type="submit" className="btn btn-primary" onClick={() => setPivot('SEGMENT_THREE')}>Segment 3</button>
        <button type="submit" className="btn btn-primary" onClick={() => setPivot('SEGMENT_FOUR')}>Segment 4</button>
        <button type="submit" className="btn btn-primary" onClick={() => setPivot('SEGMENT_FIVE')}>Segment 5</button>
      </Panel>
    );
  }
  return (
    <Panel lg={12} md={12}>
      <button type="submit" className="btn btn-primary" onClick={() => setPivot('SKU')}>SKU</button>
      <button type="submit" className="btn btn-primary" onClick={() => setPivot('BRAND')}>Brand</button>
      <button type="submit" className="btn btn-primary" onClick={() => setPivot('SEGMENT_ONE')}>Segment 1</button>
      <button type="submit" className="btn btn-primary" onClick={() => setPivot('SEGMENT_TWO')}>Segment 2</button>
      <button type="submit" className="btn btn-primary" onClick={() => setPivot('SEGMENT_THREE')}>Segment 3</button>
    </Panel>
  );
};

PpcBestsellersButons.propTypes = {
  setPivot: PropTypes.func.isRequired,
  accountType: PropTypes.number.isRequired,
};


export default PpcBestsellersButons;
