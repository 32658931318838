import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row } from 'reactstrap';
import EditMasterCatalogModal from './EditMasterCatalogModal';
import { imageFieldColumn, imageCellRendererComponent } from './ImageCellRenderer';
import config from '../../../../../config';
import TableBuilder from '../../../../../shared/components/table/TableBuilder';
import CardPanel from '../../../../../shared/components/widgets/CardPanel';
import { doFetch } from '../../../../../helpers';
import { getExportPrefix } from '../../../../../shared/components/table/functions';

function ItemCatalogTable() {
  const selectedSeller = useSelector(store => store.sellerSelect);
  const user = useSelector(store => store.authentication.user);
  const theme = useSelector(store => store.theme);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedData, setSelectedData] = useState(0);
  const [agGrid, setAgGrid] = useState(null);
  const exportPrefix = getExportPrefix('wm-catalog', selectedSeller);

  const columnDefs = [
    imageFieldColumn,
    {
      field: 'item_id',
      headerName: 'ID',
      cellRenderer: (params) => {
        if (params && params.data && params.data.item_page_url) {
          return <a href={params.data.item_page_url} target="_blank" rel="noopener noreferrer">{params.data.item_id}</a>;
        }
        return '';
      },
    },
    // {
    //   field: 'vendor_id',
    //   headerName: 'Vendor/Supplier ID',
    // },
    {
      field: 'sku',
      headerName: 'sku',
    },
    {
      field: 'product_name',
      headerName: 'Name',
    },
    {
      field: 'product_category',
      headerName: 'Product Category',
    },
    // {
    //   field: 'short_description',
    //   headerName: 'Short Desc',
    // },
    // {
    //   field: 'long_description',
    //   headerName: 'Long Desc',
    // },
    // {
    //   field: 'cost',
    //   headerName: 'cost',
    // },
    // {
    //   field: 'price',
    //   headerName: 'price',
    // },
    {
      field: 'currency',
      headerName: 'currency',
    },
    // {
    //   field: 'buy_box_item_price',
    //   headerName: 'Buy Box Item Price',
    // },
    // {
    //   field: 'buy_box_shipping_price',
    //   headerName: 'Buy Box Shipping Price',
    // },
    // {
    //   field: 'publish_status',
    //   headerName: 'Publish Status',
    // },
    // {
    //   field: 'lifecycle_status',
    //   headerName: 'Lifecycle Status',
    // },
    // {
    //   field: 'availability_status',
    //   headerName: 'Availability Status',
    // },
    // {
    //   field: 'ship_methods',
    //   headerName: 'Ship Methods',
    // },
    {
      field: 'wpid',
      headerName: 'WPID',
    },
    // {
    //   field: 'wm_number',
    //   headerName: 'WM#',
    // },
    {
      field: 'gtin',
      headerName: 'GTIN',
    },
    {
      field: 'upc',
      headerName: 'UPC',
    },
    {
      field: 'primary_image_url',
      headerName: 'Primary Image URL',
    },
    {
      field: 'shelf_name',
      headerName: 'Shelf Name',
    },
    {
      field: 'primary_cat_path',
      headerName: 'Primary Cat Path',
    },
    // {
    //   field: 'offer_start_date',
    //   headerName: 'offer_start_date',
    // },
    // {
    //   field: 'offer_end_date',
    //   headerName: 'Offer End Date',
    // },
    {
      field: 'item_creation_date',
      headerName: 'Item Creation Date',
    },
    // {
    //   field: 'item_last_updated',
    //   headerName: 'item_last_updated',
    // },
    {
      field: 'item_page_url',
      headerName: 'Item Page URL',
    },
    // {
    //   field: 'review_count',
    //   headerName: 'Review Count',
    // },
    // {
    //   field: 'average_rating',
    //   headerName: 'Average Rating',
    // },
    // {
    //   field: 'product_tax_code',
    //   headerName: 'Product Tax Code',
    // },
    // {
    //   field: 'shipping_weight',
    //   headerName: 'Shipping Weight',
    // },
    // {
    //   field: 'shipping_weight_unit',
    //   headerName: 'Shipping Weight Unit',
    // },
    // {
    //   field: 'status_change_reason',
    //   headerName: 'Status Change Reason',
    // },
    // {
    //   field: 'available_inventory_units',
    //   headerName: 'Available Inventory Units',
    // },
    // {
    //   field: 'parent_item_id',
    //   headerName: 'Parent Item ID',
    // },
    // {
    //   field: 'ad_status',
    //   headerName: 'AD Status',
    // },
    // {
    //   field: 'sp_status',
    //   headerName: 'SP Status',
    // },
    // {
    //   field: 'sb_status',
    //   headerName: 'SB Status',
    // },
    // {
    //   field: 'sd_status',
    //   headerName: 'SD Status',
    // },
    // {
    //   field: 'reporting_visibility',
    //   headerName: 'Reporting Visibility',
    // },
    {
      field: 'budget_group',
      headerName: 'Budget Group',
    },
    {
      field: 'segment_one',
      headerName: 'Segment 1',
    },
    {
      field: 'segment_two',
      headerName: 'Segment 2',
    },
    {
      field: 'segment_three',
      headerName: 'Segment 3',
    },
    {
      field: 'segment_four',
      headerName: 'Segment 4',
    },
    {
      field: 'segment_five',
      headerName: 'Segment 5',
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: false,
    resizable: true,
    cellStyle: (params) => {
      if (params && params.value < 0.00) {
        return { color: 'red' };
      }
      return null;
    },
  };

  const frameworkComponents = {
    ...imageCellRendererComponent,
  };
  const tableConfig = {
    showDatePicker: false,
    showFooter: false,
    parentControlledLoad: true,
    apiEndPoint: `${config.serverUrl}/walmart/catalogs`,
    paginationPageSize: 5000,
    cacheBlockSize: 5000,
  };

  const datasource = {
    getRows: async (params) => {
      const {
        request, success, fail,
      } = params;
      const { startRow, sortModel } = request;
      const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
      const requestOptions = {
        method: 'GET',
        headers: headerWithAuth,
      };

      let sort = '';
      if (sortModel.length > 0) {
        sort = `${sortModel[0].colId} ${sortModel[0].sort}`;
      }

      const offset = startRow || 0;

      const accountId = selectedSeller.parentAccountId > 0 ? selectedSeller.parentAccountId : selectedSeller.value;

      const url = `${tableConfig.apiEndPoint}/${accountId}?limit=${tableConfig.paginationPageSize}&offset=${offset}&sort=${sort}`;
      try {
        const data = await doFetch(url, requestOptions);
        success({
          rowData: data.results,
          rowCount: data.total,
        })
      } catch (error) {
        fail();
      }
    },
  };

  // function onFirstDataRendered(params) {
  //   params.api.sizeColumnsToFit();
  // }

  const parentAccess = (agGridPointer) => {
    setAgGrid(agGridPointer);
  }

  const forceRefresh = () => {
    agGrid.refreshData();
  }

  const changeShowEditModal = () => {
    setShowEditModal(!showEditModal);
  };

  const selectionChanged = (selectdData) => {
    setSelectedData(selectdData);
  };

  return (
    <Container className="todo-app">
      <Row>
        <CardPanel
          title={new Date().toISOString()}
          autorenewButton={false}
          closeButton={false}
        >
          <TableBuilder
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            apiEndPoint={tableConfig.apiEndPoint}
            showDatePicker={tableConfig.showDatePicker}
            showFooter={tableConfig.showFooter}
            frameworkComponents={frameworkComponents}
            rowHeight={75}
            // onFirstDataRendered={onFirstDataRendered}
            pagination
            rowModelType="serverSide"
            datasource={datasource}
            paginationPageSize={tableConfig.paginationPageSize}
            cacheBlockSize={tableConfig.cacheBlockSize}
            serverSideInfiniteScroll
            parentControlledLoad={tableConfig.parentControlledLoad}
            editSelectedRowsButton={false}
            openEditSelected={changeShowEditModal}
            parentSelectionChanged={selectionChanged}
            parentAccess={parentAccess}
            exportPrefix={exportPrefix}
          />
        </CardPanel>
        <EditMasterCatalogModal
          changeShowEditModal={changeShowEditModal}
          showEditModal={showEditModal}
          theme={theme}
          selectedData={selectedData}
          forceRefresh={forceRefresh}
        />
      </Row>
    </Container>
  );
}

export default ItemCatalogTable;
