import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import useFetch from '../../../../../shared/components/hooks/useFetch';
import config from '../../../../../config';

const UpdatePaymentStatus = ({ setLoading }) => {
  const sellerSelect = useSelector(state => state.sellerSelect);
  const match = useRouteMatch({
    path: '/subscription/:ecidsubidpmid/update',
    exact: false,
  });
  const params = (match && match.params ? match.params : '') || '';
  const delimitedData = params ? params.ecidsubidpmid.replace(sellerSelect.externalCustomerId, '') : '';
  const dataArray = delimitedData.split(':');
  const subscriptionId = dataArray[0];
  const paymentMethodId = dataArray[1];
  /*
    Data is broken out from this format {EAID}{SUBID}:{PMID}
    failure if you get a non-200 back
  */

  const fetchOptions = {
    requestOptions: {
      method: 'POST',
      body: JSON.stringify({
        paymentMethodId,
      }),
    },
  };
  const { loading, results } = useFetch(`${config.serverUrl}/subscriptions/${subscriptionId}`, fetchOptions);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (!results || results.length === 0) {
      return;
    }

    switch (results[0].payment_method_id.toString()) {
      case paymentMethodId:
        setLoading(false);
        setMessage('Success! Payment has been updated.');
        break;
      default:
        if (!loading) {
          setLoading(false);
          setMessage('We were unable to update your payment method, please try again.');
        }
        break;
    }
  }, [results]);

  return (<h2>{message}</h2>);
};

export default UpdatePaymentStatus;
