import { getPlatform } from './domainSupport';

const platfromObj = getPlatform();

const themeColors = {
  colorPrimary: platfromObj.colorPrimary,
  colorAccent: platfromObj.colorAccent,
  lightColorBackground: platfromObj.lightColorBackground,
  lightColorText: platfromObj.lightColorText,
  darkColorBackground: platfromObj.darkColorBackground,
  darkColorText: platfromObj.darkColorText,
  limitlessColorBackground: platfromObj.limitlessColorBackground,
  limitlessColorText: platfromObj.limitlessColorText,
};

export const DROPDOWN_COLORS = {
  lightColorBackground: platfromObj.lightColorBackground,
  lightColorText: platfromObj.lightColorText,
  darkColorBackground: platfromObj.darkColorBackgroundAlt,
  darkColorText: platfromObj.darkColorText,
  limitlessColorBackground: platfromObj.limitlessColorBackgroundAlt,
  limitlessColorText: platfromObj.limitlessColorText,
};

function style(theme, customValues = {}) {
  // const state = store.getState();
  const themeSettings = {
    colorPrimary: themeColors.colorPrimary,
    colorBackground: (customValues && customValues.lightColorBackground) || themeColors.lightColorBackground,
    colorText: (customValues && customValues.lightColorText) || themeColors.lightColorText,
    calendarDayColor: '#8B9898',
    areaOrange: '#f6936f',
    areaGray: '#a6a6a6',
    areaYellow: '#ffeb99',
    areaGreen: '#d2ff4d',
    lineYellow: '#ffdb4d',
    lineOrange: '#f47b50',
    lineGray: '#808080',
    lineGreen: '#ace600',
    chartThemeName: 'ag-default',
  };
  if (theme?.className.endsWith('dark')) {
    themeSettings.colorBackground = (customValues && customValues.darkColorBackground) || themeColors.darkColorBackground;
    themeSettings.colorText = (customValues && customValues.darkColorText) || themeColors.darkColorText;
    themeSettings.chartThemeName = 'ag-default-dark';
    themeSettings.calendarDayColor = '#FFFFFF';
  }
  if (theme?.className.endsWith('limitless')) {
    themeSettings.colorBackground = (customValues && customValues.limitlessColorBackground) || themeColors.limitlessColorBackground;
    themeSettings.colorText = (customValues && customValues.limitlessColorText) || themeColors.limitlessColorText;
    themeSettings.chartThemeName = 'ag-default-dark';
    themeSettings.calendarDayColor = '#FFFFFF';
  }

  return themeSettings;
}

export default style;
