import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from 'reactstrap';
import Panel from '../../../../../shared/components/Panel';

export default function MainSettingsPanel({
  initialLoad, getData, mapLine, onUpdate, apiData,
}) {
  return (
    <Panel lg={12} title="Main Account Settings" getData={getData} parentRefresh={initialLoad}>
      <div>
        <Table responsive className="table--bordered">
          <thead>
            <tr>
              <th>Key</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {apiData && apiData.map(line => mapLine(line))}
            <tr>
              <td><button type="button" className="badge badge-success badge-btn" onClick={() => onUpdate()}>Update Main Account Settings</button></td>
            </tr>
          </tbody>
        </Table>
      </div>
    </Panel>
  );
}

MainSettingsPanel.propTypes = {
  initialLoad: PropTypes.bool.isRequired,
  getData: PropTypes.func.isRequired,
  mapLine: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  apiData: PropTypes.instanceOf(Array).isRequired,
};
