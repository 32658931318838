import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FilterSelect from './NewFilterSelect';

export const MetricOptions = {
  IMPRESSIONS: {
    label: 'Impressions',
    value: 'impressions',
  },
  CLICKS: {
    label: 'Clicks',
    value: 'clicks',
  },
  ORDERS: {
    label: 'Orders',
    value: 'orders',
  },
  SPEND: {
    label: 'Spend',
    value: 'spend',
  },
  SALES: {
    label: 'Sales',
    value: 'sales',
  },
  ACOS: {
    label: 'ACoS',
    value: 'acos',
  },
  ROAS: {
    label: 'ROAS',
    value: 'roas',
  },
  CVR: {
    label: 'CVR',
    value: 'cvr',
  },
  CTR: {
    label: 'CTR',
    value: 'ctr',
  },
  CPC: {
    label: 'CPC',
    value: 'cpc',
  },
  VCPM: {
    label: 'vCPM',
    value: 'vcpm',
  },
  VIEW_IMPRESSIONS: {
    label: 'View Impressions',
    value: 'viewImpressions',
  },
  * [Symbol.iterator]() {
    yield MetricOptions.IMPRESSIONS;
    yield MetricOptions.CLICKS;
    yield MetricOptions.ORDERS;
    yield MetricOptions.SALES;
    yield MetricOptions.SPEND;
    yield MetricOptions.ACOS;
    yield MetricOptions.ROAS;
    yield MetricOptions.CVR;
    yield MetricOptions.CTR;
    yield MetricOptions.CPC;
    yield MetricOptions.VCPM;
    yield MetricOptions.VIEW_IMPRESSIONS;
  },
};

export default function SalesMetricFilter({
  initialValue, onChange, disable,
}) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (value) {
      onChange(value);
    }
  }, [value]);

  return (
    <FilterSelect
      label="Metric Option:"
      onChange={option => setValue(option)}
      name="metricOption"
      options={[...MetricOptions]}
      selected={value}
      disable={disable}
    />
  );
}

SalesMetricFilter.defaultProps = {
  initialValue: MetricOptions.SALES,
  disable: false,
};

SalesMetricFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialValue: PropTypes.oneOf([...MetricOptions]),
  disable: PropTypes.bool,
};
