import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import useFetch from '../../../../../shared/components/hooks/useFetch';
import config from '../../../../../config';
import { sellerSelectActions } from '../../../../../redux/actions';

const PaymentStatus = ({ setLoading }) => {
  const dispatch = useDispatch();
  const sellerSelect = useSelector(state => state.sellerSelect);
  const match = useRouteMatch({
    path: '/subscription/:ecidsubid/complete',
    exact: false,
  });
  const params = (match && match.params ? match.params : '') || '';
  const subscriptionId = params ? params.ecidsubid.replace(sellerSelect.externalCustomerId, '') : '';
  const { loading, results } = useFetch(`${config.serverUrl}/subscriptions/${subscriptionId}`);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (!results.plan_status) {
      return;
    }

    switch (results.plan_status) {
      case 'active':
        dispatch(sellerSelectActions
          .sellerSelectChange(
            sellerSelect.value,
            sellerSelect.label,
            sellerSelect.type,
            sellerSelect.currencySymbol,
            sellerSelect.currencyCode,
            sellerSelect.parentAccountId,
            sellerSelect.mpName,
            sellerSelect.agency,
            sellerSelect.platform,
            results.subscription_id,
            results.plan.toUpperCase(),
            results.plan_status.toUpperCase(),
            sellerSelect.externalCustomerId,
            sellerSelect.externalAccountId,
            sellerSelect.customerId,
            Date.now(),
          ));
        setLoading(false);
        setMessage('Success! Payment received.');
        break;
      default:
        if (!loading) {
          setLoading(false);
          setMessage('We were unable to process your payment, please try again.');
        }
        break;
    }
  }, [results]);

  return (<h2>{message}</h2>);
};

export default PaymentStatus;
