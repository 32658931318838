import React, { useEffect, useState } from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import Panel from '../../../../shared/components/Panel';
import { SiteImage, SiteName } from '../../../../shared/components/domainSupport';
import QuickStartItems from './components/QuickStartItems';
import OnboardTrialSteps from './components/OnboardTrialSteps';

export default function TrialDashboardPage({
  updatedProfile, linkedSpApi, quickAccountSetup, panelLoad,
}) {
  const [showIntro, setShowIntro] = useState(true);
  const siteName = SiteName();
  useEffect(() => {
    if (!showIntro) {
      introJs().setOptions({
        steps: [
          {
            title: 'Welcome to AdRev',
            intro: 'Let us walk you through what you need to complete to get your trial started.',
          },
          {
            title: 'Your Checklist with Status',
            element: '#checklist',
            intro: 'The status of each step will update you complete them.',
          },
          {
            title: 'Set Product Budget',
            element: '#itemBudget',
            intro: 'Set the daily ad spend budget that will be used for each product in the trial.',
          },
          {
            title: 'Set PPC ACoS Target',
            element: '#pacos',
            intro: 'Set the PPC ACoS target to be used for the trial.  To convert ROAS to ACoS, 1/ROAS = PPC ACoS.',
          },
          // {
          //   title: 'Set Products Process',
          //   element: '#productArea',
          //   intro: 'These boxes will help you search your Amazon catalog and add products.  Search results will displayed for available and eligibile product that can be added for trial consderation.  You can use multiple search to find additional products to add.',
          // },
          {
            title: 'Search and Add Products to your free trial',
            element: '#searchQuery',
            intro: 'Search your Amazon Catalog to find products for the trial. Search using ASIN, SKU, Title or Keywords.  Multiple searches are allowed.',
          },
          {
            title: 'Search Results',
            element: '#searchResults',
            intro: 'Available and eligibile product search results will be found here where you can add for trial consideration. Trouble finding products? Goto seller/vendor and get the ASIN or SKU to find the exact product.',
          },
          {
            title: 'Products Selected',
            element: '#useItemsDisplay',
            intro: 'These are your products you have selected for trial consideration.  You can add or remove up to 100 products.  AdRev will use the maximum number of products chosen above.',
          },
          // {
          //   title: 'Accept & Complete Trial Setup',
          //   element: '#readAndConfirm',
          //   intro: 'Agree to our terms.',
          // },
          {
            title: 'Done, trial will begin soon!',
            element: '#submit',
            intro: 'Your trial will begin in a few days, AdRev will send an email with more infomation when your trial is ready to launch.',
            position: 'middle',
          },
        ],
        keyboardNavigation: true,
        scrollToElement: true,
        exitOnEsc: true,
        showBullets: true,
      }).start();
      // introJs().setOptions({
      //   hints: [
      //     {
      //       hint: 'Ad Spend / Ad Sales',
      //       element: '#pacosHint',
      //       hintAnimation: false,
      //     },
      //   ],
      //   hintAnimation: false,
      // }).addHints();
    }
  }, [showIntro]);

  return (
    <Container className="dashboard">
      <Row>
        <Col xxl={4}>
          &nbsp;
        </Col>
        <Col xxl={4}>
          <Row>
            <Panel
              showButtons={false}
            >
              <Row>
                <SiteImage />
              </Row>
              <Row>
                &nbsp;
              </Row>
              <Row>
                <Col>
                  <span style={{ textAlign: 'center' }}>
                    <h2 id="welcome">Welcome to {siteName}!  Set up your trial below.</h2>
                  </span>
                </Col>
              </Row>
              <Row>
                &nbsp;
              </Row>
              <Row>
                &nbsp;
              </Row>
            </Panel>
          </Row>
          <Row>
            <Col>
              <Row>
                <OnboardTrialSteps updatedProfile={updatedProfile} linkedSpApi={linkedSpApi} quickAccountSetup={quickAccountSetup} panelLoad={panelLoad} />
              </Row>
              <Row>
                <QuickStartItems setShowIntro={setShowIntro} />
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xxl={4}>
          &nbsp;
        </Col>
      </Row>
    </Container>
  );
}
