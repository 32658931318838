import React from 'react';
import PropTypes from 'prop-types';
import AccountDataPointPanel from './AccountDataPointPanel';
import { formatNumberWhole } from '../../../../shared/components/table/functions';

export default function Clicks({
  data, loading, dateView, title, subtitle,
}) {
  return (
    <AccountDataPointPanel
      data={data}
      title={title}
      subtitle={subtitle}
      dayField="clicks"
      formatFunction={item => formatNumberWhole(item)}
      barColor="#70bbfd"
      loading={loading}
      dateView={dateView}
    />
  );
}

Clicks.defaultProps = {
  data: null,
  title: 'Clicks',
  subtitle: 'Total for Date Range',
};

Clicks.propTypes = {
  loading: PropTypes.bool.isRequired,
  dateView: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(Array),
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
