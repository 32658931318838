import React, { PureComponent } from 'react';
import { Table } from 'reactstrap';
import { connect } from 'react-redux';
import config from '../../../../config';
import Panel from '../../../../shared/components/Panel';
import { SellerSelect, User } from '../../../../shared/prop-types/MainProps';


class PromotionEvents extends PureComponent {
  static propTypes = {
    sellerSelect: SellerSelect.isRequired,
    user: User.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      apiData: [],
      initalLoad: false,
    };

    this.getColor = this.getColor.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevState) {
    const { sellerSelect } = this.props;
    if (sellerSelect.value !== prevState.sellerSelect.value) {
      this.getData();
    }
  }

  getData = () => {
    const { user, sellerSelect } = this.props;

    const apiEndPoint = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const getUrl = `${apiEndPoint}/events/calendar/seller?sellerAccountId=${sellerSelect.value.toString()}&parentAccountId=${sellerSelect.parentAccountId}`;

    if (sellerSelect.value > 0) {
      const requestOptions = {
        method: 'GET',
        headers: headerWithAuth,
      };
      this.setState({
        initalLoad: true,
      });
      // Call via API (would like to update to " await ")
      fetch(getUrl, requestOptions)
        .then((results) => {
          if (!results.ok) {
            throw Error(results.statusText);
          }
          return results.json();
        }).then((data) => {
          this.setState({
            initalLoad: false,
            apiData: data,
          });
          return data;
        }).catch(() => {
          this.setState({
            initalLoad: false,
          });
        });
    }
  };

  getColor = (input) => {
    let color;

    if (input && input.charAt(0) === '#') {
      return input;
    }

    switch (input) {
      case 'high':
        color = '#C3485F';
        break;

      case 'medium':
        color = '#4ce1b6';
        break;

      case 'normal':
        color = '#E87B50';
        break;

      case 'low':
        color = '#808080';
        break;

      default:
        color = '#000000';
        break;
    }
    return color;
  }

  render() {
    const { apiData, initalLoad } = this.state;

    return (
      <Panel lg={12} title="Seller Account Promotion Calendar" getData={this.getData} parentRefresh={initalLoad}>
        <Table responsive className="table--bordered">
          <thead>
            <tr>
              <th>#</th>
              <th>Event/Promotion Name</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Priority</th>
            </tr>
          </thead>
          <tbody>
            {apiData && apiData.map(line => (
              <tr>
                <td>{line.calendar_id}</td>
                <td>{line.title}</td>
                <td>{line.start.substring(0, line.start.indexOf('T'))}</td>
                <td>{line.end.substring(0, line.end.indexOf('T'))}</td>
                <td><div className="badge" style={{ backgroundColor: this.getColor(line.priority) }}>{line.priority}</div></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Panel>
    );
  }
}

const mapStateToProps = (state) => {
  const { sellerSelect } = state;
  const { user } = state.authentication;

  return {
    sellerSelect,
    user,
  };
};

export default connect(mapStateToProps)(PromotionEvents);
