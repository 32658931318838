/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import LeftMiddleRightBox from '../../../../shared/components/widgets/LeftMiddleRightBox';
import {
  currencyFormatter, formatDecimalToFixedTwoPercent,
} from '../../../../shared/components/table/functions';

const PpcBestsellersButons = ({
  totalPpcSpend, totalPpcSales, initalLoad,
}) => (
  <Row>
    <LeftMiddleRightBox
      content={{
        title: 'PPC Spend',
        left_header: '',
        left_data: '',
        middle_header: '',
        middle_data: currencyFormatter({ value: totalPpcSpend }),
        right_header: '',
        right_data: '',
      }}
      loadingData={initalLoad}
      lgWidth="4"
    />
    <LeftMiddleRightBox
      content={{
        title: 'PPC Sales',
        left_header: '',
        left_data: '',
        middle_header: '',
        middle_data: currencyFormatter({ value: totalPpcSales }),
        right_header: '',
        right_data: '',
      }}
      loadingData={initalLoad}
      lgWidth="4"
    />
    <LeftMiddleRightBox
      content={{
        title: 'PACoS',
        left_header: '',
        left_data: '',
        middle_header: '',
        middle_data: formatDecimalToFixedTwoPercent({ value: (totalPpcSpend / totalPpcSales) }),
        right_header: '',
        right_data: '',
      }}
      loadingData={initalLoad}
      lgWidth="4"
    />
  </Row>
);

PpcBestsellersButons.propTypes = {
  totalPpcSpend: PropTypes.number.isRequired,
  totalPpcSales: PropTypes.number.isRequired,
  initalLoad: PropTypes.func.isRequired,
};


export default PpcBestsellersButons;
