import React from 'react';
import {
  Container, Row,
} from 'reactstrap';
import JtsrAsinByPageMain from './components/JtsrAsinByPageMain';

const JtsrAsinByPagePage = () => (
  <Container className="dashboard">
    <Row>
      <JtsrAsinByPageMain />
    </Row>
  </Container>
);

export default JtsrAsinByPagePage;
