import PropTypes from 'prop-types';
import { withArray } from '../../../../shared/functions';

const calcTotal = (myData, placement, field) => {
  let total = 0;
  withArray(myData, () => {
    total = myData.reduce((sum, item) => {
      if (item.placements[placement][field]) {
        return sum + item.placements[placement][field];
      }
      return sum;
    }, 0);
  });
  return total;
};

export default function calcPpcTotalsByPlacement(data) {
  return withArray(data, () => {
    const result = [];
    const buyboxData = {
      name: 'buybox',
      sales: calcTotal(data, 'buybox', 'sales'),
      spend: calcTotal(data, 'buybox', 'spend'),
      displayName: 'Buy Box',
      color: '#d04960',
    };
    result.push(buyboxData);
    const carouselData = {
      name: 'carousel',
      sales: calcTotal(data, 'carousel', 'sales'),
      spend: calcTotal(data, 'carousel', 'spend'),
      displayName: 'Carousel',
      color: '#03c03c',
    };
    result.push(carouselData);
    const homepageData = {
      name: 'homepage',
      sales: calcTotal(data, 'homepage', 'sales'),
      spend: calcTotal(data, 'homepage', 'spend'),
      displayName: 'Homepage',
      color: '#36c9c9',
    };
    result.push(homepageData);
    const searchInGridData = {
      name: 'searchInGrid',
      sales: calcTotal(data, 'searchInGrid', 'sales'),
      spend: calcTotal(data, 'searchInGrid', 'spend'),
      displayName: 'Search In-grid',
      color: '#fbb054',
    };
    result.push(searchInGridData);
    const browseInGridData = {
      name: 'browseInGrid',
      sales: calcTotal(data, 'browseInGrid', 'sales'),
      spend: calcTotal(data, 'browseInGrid', 'spend'),
      displayName: 'Browse In-grid',
      color: '#c88ffa',
    };
    result.push(browseInGridData);
    const stockUpData = {
      name: 'stockUp',
      sales: calcTotal(data, 'stockUp', 'sales'),
      spend: calcTotal(data, 'stockUp', 'spend'),
      displayName: 'Stock-up',
      color: '#e85097',
    };
    result.push(stockUpData);
    const otherData = {
      name: 'other',
      sales: calcTotal(data, 'other', 'sales'),
      spend: calcTotal(data, 'other', 'spend'),
      displayName: 'Other',
      color: '#9a9a9a',
    };
    result.push(otherData);
    return result;
  }, () => []);
}

calcPpcTotalsByPlacement.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
};
