import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { formatNumberDefaultToZero } from '../../../../../shared/components/table/functions';
import ChartBuilder from '../../../../../shared/components/chart/ChartBuilder';
import topology from './UsTopology';
import CardPanel from '../../../../../shared/components/widgets/CardPanel';

const ReportChart = ({ loading, data, chartRange }) => {
  const theme = useSelector(store => store.theme);
  const chartOptions = {
    theme: theme.className.endsWith('light') ? 'ag-default' : 'ag-default-dark',
    title: {
      text: 'Orders in the United States',
    },
    data,
    topology,
    series: [
      {
        type: 'map-shape',
        idKey: 'name',
        colorKey: 'orders',
        labelKey: 'code',
        tooltip: {
          renderer: ({ datum, title }) => ({
            title,
            content: `${formatNumberDefaultToZero({ value: datum.orders })} orders`,
          }),
        },
      },
    ],
    gradientLegend: {
      enabled: true,
      scale: {
        interval: {
          minSpacing: 1,
          values: chartRange,
        },
        label: {
          fontSize: 9,
          formatter: ({ value }) => `${formatNumberDefaultToZero({ value })}`,
        },
      },
    },
  };

  return (
    <Container className="dashboard">
      <Col md={12}>
        <Row>
          <CardPanel
            autorenewButton={false}
            closeButton={false}
            parentRefresh={loading}
          >
            {data && data.length > 0 && (
              <ChartBuilder chartOptions={chartOptions} chartHeight="500px" />
            )}
          </CardPanel>
        </Row>
      </Col>
    </Container>
  );
}

export default ReportChart;
