import React from 'react';
import { useSelector } from 'react-redux';
import { Table, Col, Row } from 'reactstrap';
import useData from './useData';
import Panel from '../../../../../shared/components/Panel';

export default function AccountStatusWithContext() {
  const selectedSeller = useSelector(store => store.sellerSelect);
  const [loading, results, error] = useData(selectedSeller);
  const naResults = results && results.na ? results.na : [];
  const euResults = results && results.eu ? results.eu : [];
  const feResults = results && results.fe ? results.fe : [];


  const adApiContent = (status) => {
    let returnContent = <span><span className={`status__label-color status__label-color--${status}`} /> Active</span>;
    if (status === 'red') {
      returnContent = <a href="/onboarding/ad-api/start"><span className={`status__label-color status__label-color--${status}`} /> Authorize</a>;
    }
    if (status === 'yellow') {
      returnContent = <span><span className={`status__label-color status__label-color--${status}`} /> Inactive</span>;
    }
    if (status === 'black') {
      returnContent = <span><span className={`status__label-color status__label-color--${status}`} /> Unused</span>;
    }
    return (
      returnContent
    );
  };

  const spApiContent = (status, edata) => {
    let returnContent = <span><span className={`status__label-color status__label-color--${status}`} /> Active</span>;
    if (edata !== '' && status === 'red') {
      returnContent = <a href={`/onboarding/sp-api/start?edata=${edata}`}><span className={`status__label-color status__label-color--${status}`} /> Authorize</a>;
    }
    if (status === 'yellow') {
      returnContent = <span><span className={`status__label-color status__label-color--${status}`} /> Inactive</span>;
    }
    if (status === 'gray') {
      returnContent = <span><span className={`status__label-color status__label-color--${status}`} /> Not Ready</span>;
    }
    if (status === 'blue') {
      returnContent = <span><span className={`status__label-color status__label-color--${status}`} />  Configured</span>;
    }
    return (
      returnContent
    );
  };

  const wmApiContent = (status) => {
    let returnContent = <span><span className={`status__label-color status__label-color--${status}`} /> Active</span>;
    if (status === 'red') {
      returnContent = <span><span className={`status__label-color status__label-color--${status}`} /> Setup Needed</span>;
    }
    if (status === 'yellow') {
      returnContent = <span><span className={`status__label-color status__label-color--${status}`} /> Inactive</span>;
    }
    if (status === 'black') {
      returnContent = <span><span className={`status__label-color status__label-color--${status}`} /> Unused</span>;
    }
    return (
      returnContent
    );
  };

  const flagContent = (status) => {
    let returnContent = '';
    if (status === 0) {
      returnContent = 'OFF';
    }
    if (status === 1) {
      returnContent = 'ON';
    }
    return (
      returnContent
    );
  };

  return (
    <>
      {error}
      <Panel lg={12} title="Onboarding Links" parentRefresh={loading}>
        <a href="/onboarding/ad-api/start" className="btn btn-outline-primary">Authorize ADs API</a>
      </Panel>
      <Panel lg={12} title="Amazon Integration Status" parentRefresh={loading}>
        <h3 className="page-subhead subhead">
          &nbsp;&nbsp;<span className="status__label-color status__label-color--green" />=Configured and successful test.
          &nbsp;&nbsp;<span className="status__label-color status__label-color--blue" />=Configured but not tested.
          &nbsp;&nbsp;<span className="status__label-color status__label-color--yellow" />=Configured with failed test.
          &nbsp;&nbsp;<span className="status__label-color status__label-color--gray" />=Prerequisite needed before configuration.
          &nbsp;&nbsp;<span className="status__label-color status__label-color--red" />=Setup/Update required.
        </h3>
        <Table responsive striped className="dashboard__table-orders" id="dataOverTime">
          <thead>
            <tr>
              <th colSpan="4"><h3>Americas:</h3></th>
            </tr>
            <tr>
              <th key="flag">Flag</th>
              <th key="profileid">ProfileID</th>
              <th key="countrycode">Country Code</th>
              <th key="id">ID</th>
              <th key="aid">AID</th>
              <th key="cid">CID</th>
              <th key="adflag">AD Flag</th>
              <th key="spflag">SP Flag</th>
              <th key="mwsflag">MWS Flag</th>
              <th key="name">Name</th>
              <th key="adapistatus">AD API Status</th>
              <th key="spapistatus">SP API Status</th>
            </tr>
          </thead>
          <tbody>
            {naResults.length > 0 && naResults.map(acctObj => (
              <tr key={acctObj.profileId}>
                <td style={{ width: '100px' }}><img className="dashboard__table-flag" src={`${process.env.PUBLIC_URL}/img/flags/${acctObj.countryCode.toLowerCase()}.svg`} alt="flag" /></td>
                <td>{acctObj.profileId}</td>
                <td>{acctObj.countryCode}</td>
                <td>{acctObj.accountInfo.id}</td>
                <td>{acctObj.account_id}</td>
                <td>{acctObj.customer_id}</td>
                <td>{flagContent(acctObj.amz_ad_api_status)}</td>
                <td>{flagContent(acctObj.amz_sp_api_status)}</td>
                <td>{flagContent(acctObj.amz_mws_api_status)}</td>
                <td>{acctObj.accountInfo.name}</td>
                <td>{adApiContent(acctObj.active_ad_link)}</td>
                <td>{spApiContent(acctObj.active_sp_link, acctObj.e_data)}</td>
              </tr>
            ))}
          </tbody>
          <thead>
            <tr>
              <th colSpan="4"><br /><br /><h3>Europe:</h3></th>
            </tr>
            <tr>
              <th key="flag">Flag</th>
              <th key="profileid">ProfileID</th>
              <th key="countrycode">Country Code</th>
              <th key="id">ID</th>
              <th key="aid">AID</th>
              <th key="cid">CID</th>
              <th key="adflag">AD Flag</th>
              <th key="spflag">SP Flag</th>
              <th key="mwsflag">MWS Flag</th>
              <th key="name">Name</th>
              <th key="adapistatus">AD API Status</th>
              <th key="spapistatus">SP API Status</th>
            </tr>
          </thead>
          <tbody>
            {euResults.length > 0 && euResults.map(acctObj => (
              <tr key={acctObj.profileId}>
                <td style={{ width: '100px' }}><img className="dashboard__table-flag" src={`${process.env.PUBLIC_URL}/img/flags/${acctObj.countryCode.toLowerCase()}.svg`} alt="flag" /></td>
                <td>{acctObj.profileId}</td>
                <td>{acctObj.countryCode}</td>
                <td>{acctObj.accountInfo.id}</td>
                <td>{acctObj.account_id}</td>
                <td>{acctObj.customer_id}</td>
                <td>{flagContent(acctObj.amz_ad_api_status)}</td>
                <td>{flagContent(acctObj.amz_sp_api_status)}</td>
                <td>{flagContent(acctObj.amz_mws_api_status)}</td>
                <td>{acctObj.accountInfo.name}</td>
                <td>{adApiContent(acctObj.active_ad_link)}</td>
                <td>{spApiContent(acctObj.active_sp_link, acctObj.e_data)}</td>
              </tr>
            ))}
          </tbody>
          <thead>
            <tr>
              <th colSpan="4"><br /><br /><h3>Far East:</h3></th>
            </tr>
            <tr>
              <th key="flag">Flag</th>
              <th key="profileid">ProfileID</th>
              <th key="countrycode">Country Code</th>
              <th key="id">ID</th>
              <th key="aid">AID</th>
              <th key="cid">CID</th>
              <th key="adflag">AD Flag</th>
              <th key="spflag">SP Flag</th>
              <th key="mwsflag">MWS Flag</th>
              <th key="name">Name</th>
              <th key="adapistatus">AD API Status</th>
              <th key="spapistatus">SP API Status</th>
            </tr>
          </thead>
          <tbody>
            {feResults.length > 0 && feResults.map(acctObj => (
              <tr key={acctObj.profileId}>
                <td style={{ width: '100px' }}><img className="dashboard__table-flag" src={`${process.env.PUBLIC_URL}/img/flags/${acctObj.countryCode.toLowerCase()}.svg`} alt="flag" /></td>
                <td>{acctObj.profileId}</td>
                <td>{acctObj.countryCode}</td>
                <td>{acctObj.accountInfo.id}</td>
                <td>{acctObj.account_id}</td>
                <td>{acctObj.customer_id}</td>
                <td>{flagContent(acctObj.amz_ad_api_status)}</td>
                <td>{flagContent(acctObj.amz_sp_api_status)}</td>
                <td>{flagContent(acctObj.amz_mws_api_status)}</td>
                <td>{acctObj.accountInfo.name}</td>
                <td>{adApiContent(acctObj.active_ad_link)}</td>
                <td>{spApiContent(acctObj.active_sp_link, acctObj.e_data)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Panel>
      <Panel lg={12} title="Walmart Integration Status" parentRefresh={loading}>
        <h3 className="page-subhead subhead">
          &nbsp;&nbsp;<span className="status__label-color status__label-color--green" />=Configured and successful test.
          &nbsp;&nbsp;<span className="status__label-color status__label-color--blue" />=Configured but not tested.
          &nbsp;&nbsp;<span className="status__label-color status__label-color--yellow" />=Configured with failed test.
          &nbsp;&nbsp;<span className="status__label-color status__label-color--gray" />=Prerequisite needed before configuration.
          &nbsp;&nbsp;<span className="status__label-color status__label-color--red" />=Setup/Update required.
          &nbsp;&nbsp;<span className="status__label-color status__label-color--black" />=Not required/used/needed
        </h3>
        <Row>
          <Col md={6} lg={6} xl={6}>
            <table>
              <tr>
                <th style={{ padding: '5px' }}>&nbsp;</th>
                <th style={{ padding: '5px' }}>Status</th>
                <th style={{ padding: '5px' }}>Notes</th>
              </tr>
              <tr>
                <td style={{ padding: '5px' }}>Advertiser ID:</td>
                <td style={{ padding: '5px' }}>{wmApiContent(results?.wm?.advertiserIdStatus)}</td>
                <td style={{ padding: '5px' }}>{results?.wm?.advertiserId && (<span>ID: {results?.wm?.advertiserId} {results?.wm?.advertiserIdStatsCount && (<span>with {results?.wm?.advertiserIdStatsCount} campiagn stats</span>)}</span>)}</td>
              </tr>
              <tr>
                <td style={{ padding: '5px' }}>Seller ID:</td>
                <td style={{ padding: '5px' }}>{wmApiContent(results?.wm?.sellerIdStatus)}</td>
                <td style={{ padding: '5px' }}>{results?.wm?.sellerId && (<span>ID: {results?.wm?.sellerId}</span>)}</td>
              </tr>
              <tr>
                <td style={{ padding: '5px' }}>Marketplace Key:</td>
                <td style={{ padding: '5px' }}>{wmApiContent(results?.wm?.mpClientKeyStatus)}</td>
                <td style={{ padding: '5px' }}>
                  { results?.wm?.mpItmesCount && (
                    <span>{results?.wm?.mpItmesCount} items found in account</span>
                  )}&nbsp;
                </td>
              </tr>
              <tr>
                <td style={{ padding: '5px' }}>Supplier Key:</td>
                <td style={{ padding: '5px' }}>{wmApiContent(results?.wm?.suClientKeyStatus)}</td>
                <td style={{ padding: '5px' }}>&nbsp;</td>
              </tr>
            </table>
          </Col>
          <Col md={6} lg={6} xl={6}>
            <span>Three Steps (only one of steps 3 or step 4 required) to setup your Walmart Access and APIs:</span>
            <ol type="1">
              <li>Add User admaster@limiltess.tech in Advertising Console access with Write access.<br />
                &nbsp;&nbsp;Please follow the directions in Walmart Connect (https://advertisinghelp.walmart.com/s/guides?article=000009334)<br />
                &nbsp;&nbsp;*** see the &quot;Admin Management&quot; section with subsection &quot;Adding a user to your account&quot; *** that will have instructions to add admaster@limitless.tech as a user with write access.
              </li>
              <br />

              <li>Add Limitless as a API Partner, which will give us Walmart Connect API (AD-API) access.<br />
                &nbsp;&nbsp;After logging into  advertising.walmart.com do the following:<br />
                &nbsp;&nbsp;&nbsp;&nbsp;Click on &quot;Account&quot; in the upper right, and click &quot;Admin&quot; from the dropdown<br />
                &nbsp;&nbsp;&nbsp;&nbsp;Find &quot;API Partner - Advertiser level&quot; section and click &quot;Add Partner&quot;<br />
                &nbsp;&nbsp;&nbsp;&nbsp;Select &quot;Limitless&quot; from the partner name dropdown and &quot;Write Access&quot; from the access type dropdown.  Then Click &quot;Add Partner&quot;
              </li>
              <br />
              <li>(SELLER ONLY) Setup Marketplace API Client ID and Client Secret.  Email Client ID and Client Secret to help@limiltess.tech<br />
                &nbsp;&nbsp;Please follow the directions in Walmart Seller Help (https://sellerhelp.walmart.com/seller/s/guide?article=000011076&language=en_US) to generate API Keys for Limitless. Limitless needs to have &quot;Full Access&quot;<br />
              </li>
              <br />
              <li>(SUPPLIER ONLY) Setup Supplier API Client ID and Client Secret.  Email Client ID and Client Secret to help@limiltess.tech<br />
                &nbsp;&nbsp;Coming Soon<br />
              </li>
            </ol>
          </Col>
        </Row>
      </Panel>
    </>
  );
}
