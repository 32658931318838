import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  BarChart, Bar, Cell, ResponsiveContainer,
} from 'recharts';
import moment from 'moment';
import CardPanel from '../../../../shared/components/widgets/CardPanel';


const calcTotal = (myData, field) => {
  let total = 0;
  if (myData) {
    total = myData.reduce((sum, item) => {
      if (item[field]) {
        return sum + item[field];
      }
      return sum;
    }, 0);
  }
  return total;
};

const AccountDataPointPanel = ({
  totalFunction, data, dayField, title, subtitle, formatFunction, loading, barColor, dateView, dateFieldName,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [myData, setMyData] = useState(null);
  const [total, setTotal] = useState(0);
  const [date, setDate] = useState(subtitle);

  useEffect(() => {
    if (data && data.length > 0) {
      setMyData(data);
      setTotal(totalFunction(data, dayField));
    }
    return () => setMyData(null);
  }, [data, setMyData, dayField, totalFunction, setTotal]);

  function handleClick(daydata) {
    setActiveIndex(daydata);
    setTotal(daydata[dayField]);
    if (dateView.value !== 'day') {
      setDate(daydata.rankDate);
    } else {
      setDate(moment(daydata[dateFieldName]).format('YYYY-MM-DD'));
    }
  }

  function reloadData() {
    setActiveIndex(0);
    setMyData(data);
    setTotal(totalFunction(data, dayField));
    setDate(subtitle);
  }

  return (
    <CardPanel
      title={title}
      subhead={date}
      parentRefresh={loading}
      getData={reloadData}
    >
      {myData && (
        <div className="dashboard__sales-report">
          <div className="progress-wrap progress-wrap--small progress-wrap--pink">
            <p className="dashboard__bypage-chart-number">{ formatFunction(total) }</p>
          </div>
          <ResponsiveContainer height={50} width="50%" className="dashboard__chart-container">
            <BarChart data={myData}>
              <Bar dataKey={dayField} onClick={day => handleClick(day)}>
                {
                  myData.map((entry, index) => (
                    <Cell
                      cursor="pointer"
                      fill={myData[index][dateFieldName] === activeIndex[dateFieldName] ? '#4ce1b6' : barColor}
                      key={entry[dateFieldName]}
                    />
                  ))
                }
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </CardPanel>
  );
};

AccountDataPointPanel.defaultProps = {
  formatFunction: (data => data),
  barColor: 'gray',
  loading: false,
  data: null,
  totalFunction: ((data, dayField) => calcTotal(data, dayField)),
  subtitle: 'Total for Date Range',
  dateFieldName: 'rankDate',
};

AccountDataPointPanel.propTypes = {
  totalFunction: PropTypes.func,
  dayField: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  dateView: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(Array),
  formatFunction: PropTypes.func,
  barColor: PropTypes.string,
  loading: PropTypes.bool,
  subtitle: PropTypes.string,
  dateFieldName: PropTypes.string,
};

export default AccountDataPointPanel;
