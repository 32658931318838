import React from 'react';
import { Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import CloseIcon from 'mdi-react/CloseIcon';
import ChartBuilder from '../../../../../shared/components/chart/ChartBuilder';



const MultiLineSeriesModal = ({
  title, theme, data, showMultiLineSeriesModal, changeShowMultiLineSeriesModal, series,
}) => {
  const chartOptions = {
    data,
    series,
    title: {
      text: title,
    },
    // subtitle: {
    //   text: '2024',
    // },
  };

  return (
    <Modal
      isOpen={showMultiLineSeriesModal}
      toggle={changeShowMultiLineSeriesModal}
      className={theme.className}
      style={{ maxWidth: '900px', width: '100%' }}
    >
      <button className="panel__btn" type="button" onClick={() => changeShowMultiLineSeriesModal()}><CloseIcon /></button><h2>{title}</h2>
      <br />
      <br />
      <br />
      <div style={{ textAlign: 'left' }}>
        <ChartBuilder chartOptions={chartOptions} />
      </div>
    </Modal>
  );
};

MultiLineSeriesModal.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  changeShowMultiLineSeriesModal: PropTypes.func.isRequired,
  showMultiLineSeriesModal: PropTypes.bool,
};

MultiLineSeriesModal.defaultProps = {
  showMultiLineSeriesModal: false,
};

export default MultiLineSeriesModal;
