import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import WalmartPpcReport from './components/WalmartPpcReport';

export default function PpcReportPage() {
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Walmart PPC Report</h3>
        </Col>
      </Row>
      <Row>
        <WalmartPpcReport />
      </Row>
    </Container>
  );
}
