import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row, CardBody } from 'reactstrap';

const FreemiumIndex = () => (
  <Container className="dashboard">
    <Row>
      <Col md={10}>
        <CardBody>
          <h2>Limitless Free Ads Offer</h2>
        </CardBody>
      </Col>
    </Row>
    <Row>
      <br />
    </Row>
    <Row>
      <Col md={5}>
        <CardBody>
          <div className="account__wrapper">
            <h2>WALMART:</h2>
            <br />
            <p>
              <br />
              Limitless offers a free ad program.
              <br />
              <br />
              <br />
              <br />
              <div className="form__form-group">
                <Link className="btn btn-primary account__btn" style={{ color: '#FFFFFF' }} to="/free/walmart/step-one">Start Today</Link>
              </div>
            </p>
          </div>
        </CardBody>
      </Col>
    </Row>
    <Row>
      <br />
    </Row>
  </Container>
);

export default FreemiumIndex;
