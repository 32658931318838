import config from '../../../config';

function getChartData(user, accountId, startDate, endDate) {
  const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
  const requestOptions = {
    method: 'GET',
    headers: headerWithAuth,
  };

  const startParam = startDate.format('YYYY-MM-DD');
  const endParam = endDate.format('YYYY-MM-DD');
  const url = `${config.serverUrl}/accounts/walmart-search-trend-ranks?accountId=${accountId}&startDate=${startParam}&endDate=${endParam}`;

  return fetch(url, requestOptions);
}

const tableColumns = [
  {
    field: 'searchKeyword',
    headerName: 'Search Keyword',
  },
  {
    field: 'reportDate',
    headerName: 'Date',
  },
  {
    field: 'positionType',
    headerName: 'Position',
  },
  {
    field: 'keywordRank',
    headerName: 'Keyword Rank',
  },
  {
    field: 'itemId1',
    headerName: 'Item 1\nID',
  },
  {
    field: 'itemName1',
    headerName: 'Item 1\nName',
  },
  {
    field: 'itemBrand1',
    headerName: 'Item 1\nBrand',
  },
  {
    field: 'pctClickShareItem1',
    headerName: 'Item 1 % Click Share',
  },
  {
    field: 'pctConvShareItem1',
    headerName: 'Item 1 % Conv Share',
  },
  {
    field: 'itemId2',
    headerName: 'Item 2 ID',
  },
  {
    field: 'itemName2',
    headerName: 'Item 2 Name',
  },
  {
    field: 'itemBrand2',
    headerName: 'Item 2 Brand',
  },
  {
    field: 'pctClickShareItem2',
    headerName: 'Item 2 % Click Share',
  },
  {
    field: 'pctConvShareItem2',
    headerName: 'Item 2 % Conv Share',
  },
  {
    field: 'itemId3',
    headerName: 'Item 3 ID',
  },
  {
    field: 'itemName3',
    headerName: 'Item 3 Name',
  },
  {
    field: 'itemBrand3',
    headerName: 'Item 3 Brand',
  },
  {
    field: 'pctClickShareItem3',
    headerName: 'Item 3 % Click Share',
  },
  {
    field: 'pctConvShareItem3',
    headerName: 'Item 3 % Conv Share',
  },
];

function getTableColumns() {
  return tableColumns;
}

function getTableData(user, accountId, startDate, endDate) {
  const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
  const requestOptions = {
    method: 'GET',
    headers: headerWithAuth,
  };

  const startParam = startDate.format('YYYY-MM-DD');
  const endParam = endDate.format('YYYY-MM-DD');
  const url = `${config.serverUrl}/accounts/walmart-search-trends?accountId=${accountId}&startDate=${startParam}&endDate=${endParam}`;

  return fetch(url, requestOptions);
}

export default {
  getChartData,
  getTableColumns,
  getTableData,
}
