const agGridKey = 'Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-055246}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Limitless}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Dashboard}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Dashboard}_need_to_be_licensed___{Dashboard}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{19_August_2025}____[v3]_[0102]_MTc1NTU1ODAwMDAwMA==ae5324147defe310147ef7a10458c5d2';

const jsonHeader = { 'content-type': 'application/json' };
const gzipHeader = { 'accept-encoding': 'gzip', 'user-agent': 'gzip', 'content-type': 'application/json' };

const isProdEnv = process.env.NODE_ENV === 'production';

const serverProdUrl = 'https://server-dot-anine-backend.appspot.com';
const serverDevUrl = 'http://localhost:4000';
const serverUrl = isProdEnv ? serverProdUrl : serverDevUrl;

const uploadProdUrl = 'https://upload-dot-anine-backend.appspot.com';
const uploadDevUrl = 'http://localhost:5050';

const showNotification = ({
  notification, position, notificationLU, notificationRU, notificationTC,
}) => {
  switch (position) {
    case 'left-up':
      notificationLU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 0 },
        className: position,
      });
      break;
    case 'right-up':
      notificationRU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 'calc(100vw - 100%)' },
        className: position,
      });
      break;
    default:
      notificationTC.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 0 },
        className: position,
      });
      break;
  }
};

module.exports = {
  agGridKey,
  jsonHeader,
  gzipHeader,
  isProdEnv,
  serverProdUrl,
  serverDevUrl,
  serverUrl,
  uploadProdUrl,
  uploadDevUrl,
  showNotification,
};
