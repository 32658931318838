import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../Panel';

const PercentOf = ({
  content, loadingData, lgWidth, color,
}) => (
  <Panel md={12} lg={lgWidth} title={content.title} parentRefresh={loadingData} color={color}>
    <div className="dashboard__sales-report">
      <div className="progress-wrap progress-wrap--small">
        <p className="dashboard__sales-report-title">{content.left_header}</p>
        <p className="dashboard__bypage-chart-number">{content.left_data}</p>
      </div>
      <div className="progress-wrap progress-wrap--small progress-wrap--pink">
        <p className="dashboard__sales-report-title">{content.middle_header}</p>
        <p className="dashboard__bypage-chart-number" style={{ whiteSpace: 'nowrap' }}>{content.middle_data}</p>
      </div>
      <div className="progress-wrap progress-wrap--small progress-wrap--pink">
        <p className="dashboard__sales-report-title">{content.right_header}</p>
        <p className="dashboard__bypage-chart-number">{content.right_data}</p>
      </div>
    </div>
  </Panel>
);

PercentOf.defaultProps = {
  lgWidth: 6,
  color: '',
};

PercentOf.propTypes = {
  // eslint-disable-next-line
  content: PropTypes.object.isRequired,
  loadingData: PropTypes.bool.isRequired,
  lgWidth: PropTypes.number,
  color: PropTypes.string,
};

export default PercentOf;
