import React from 'react';
import {
  Container, Row,
} from 'reactstrap';
import JtsrAsinMain from './components/JtsrAsinMain';

const JtsrAsinPage = () => (
  <Container className="dashboard">
    <Row>
      <JtsrAsinMain />
    </Row>
  </Container>
);

export default JtsrAsinPage;
