import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import OverTimeDataPoint from './OverTimeDataPoint';
import { numberTwoPlaces } from '../../../../shared/components/table/functions';

export default function AverageSalesRank({
  data, loading, dateView, lastDay,
}) {
  const subhead = `As of ${lastDay.format('YYYY-MM-DD')}`;
  return (
    <OverTimeDataPoint
      data={data}
      title="Average Sales Rank"
      dayField="rank"
      formatFunction={item => numberTwoPlaces({ value: item })}
      barColor="#c88ffa"
      loading={loading}
      dateView={dateView}
      subtitle={subhead}
      key={lastDay}
    />
  );
}

AverageSalesRank.defaultProps = {
  data: null,
  lastDay: moment().subtract(4, 'days'),
};

AverageSalesRank.propTypes = {
  loading: PropTypes.bool.isRequired,
  dateView: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(Array),
  lastDay: PropTypes.instanceOf(moment),
};
