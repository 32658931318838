import React from 'react';
import Panel from '../../../../../shared/components/Panel';

export default function OnboardingTrialSteps({
  updatedProfile, linkedSpApi, quickAccountSetup, panelLoad,
}) {
  return (
    <Panel
      lg={12}
      md={12}
      title="Onboarding Next Steps"
      subhead="Steps to get your Trial going."
      parentRefresh={panelLoad}
      showButtons={false}
    >
      <div id="checklist">
        <p>Here is a checklist of the steps needed to start your 7 day free Trial:</p>
        <hr />
        <p>{(updatedProfile && (<span className="lnr lnr-checkmark-circle" style={{ color: '#91C753', fontSize: '40px', verticalAlign: 'middle' }} />)) || (<span className="lnr lnr-circle-minus" style={{ color: '#6146FB', fontSize: '40px', verticalAlign: 'middle' }} />)}&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ verticalAlign: 'middle' }}>Update your <a href="/account/profile">profile</a> - Change password and update your first and last name.</span></p>
        <hr />
        <p>{(linkedSpApi && (<span className="lnr lnr-checkmark-circle" style={{ color: '#91C753', fontSize: '40px', verticalAlign: 'middle' }} />)) || (<span className="lnr lnr-circle-minus" style={{ color: '#6146FB', fontSize: '40px', verticalAlign: 'middle' }} />)}&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ verticalAlign: 'middle' }}>Link Amazon&#39;s <a href="/customer/onboarding/sp-api/start">SP-API</a></span> (Required for below steps)</p>
        <hr />
        <p>{(quickAccountSetup && (<span className="lnr lnr-checkmark-circle" style={{ color: '#91C753', fontSize: '40px', verticalAlign: 'middle' }} />)) || (<span className="lnr lnr-circle-minus" style={{ color: '#6146FB', fontSize: '40px', verticalAlign: 'middle' }} />)}&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ verticalAlign: 'middle' }}>Select your budget</span></p>
        <hr />
        <p>{(quickAccountSetup && (<span className="lnr lnr-checkmark-circle" style={{ color: '#91C753', fontSize: '40px', verticalAlign: 'middle' }} />)) || (<span className="lnr lnr-circle-minus" style={{ color: '#6146FB', fontSize: '40px', verticalAlign: 'middle' }} />)}&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ verticalAlign: 'middle' }}>Select your PPC ACoS</span></p>
        <hr />
        <p>{(quickAccountSetup && (<span className="lnr lnr-checkmark-circle" style={{ color: '#91C753', fontSize: '40px', verticalAlign: 'middle' }} />)) || (<span className="lnr lnr-circle-minus" style={{ color: '#6146FB', fontSize: '40px', verticalAlign: 'middle' }} />)}&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ verticalAlign: 'middle' }}>Input 1-100 products and select how many you want to use in the trial</span></p>
      </div>
      <hr />
    </Panel>
  );
}
