import { useFetchReturnsArray as useFetch } from './useFetch';
import config from '../../../configWrapper';

function formatUrl(account) {
  if (account && account.value) {
    const url = `${config.serverUrl}/vendors/asin-counts?accountId=${account.value}&parentAccountId=${account.parentAccountId}`;
    return url;
  }
  return undefined;
}

export default function useAsinCounts(account) {
  return useFetch(formatUrl(account));
}
