import useFetch from './useFetch';
import config from '../../../configWrapper';

function formatUrl() {
  const url = `${config.serverUrl}/onboarding/encrypt-auth-api-start`;
  return url;
}

function requestOptions(accountId) {
  return {
    requestOptions: {
      method: 'POST',
      body: JSON.stringify({
        accountId: accountId.toString(),
      }),
    },
  };
}

export default function useOnboardingEdata(accountId) {
  // Use the object form of useFetch so we grab the results and ignore loading/errors
  const { results } = useFetch(formatUrl(), requestOptions(accountId));
  return results;
}
