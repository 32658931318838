// eslint-disable-next-line
export const userConstants = {
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  REFRESH_LOGIN_REQUEST: 'USERS_REFRESH_LOGIN_REQUEST',
  REFRESH_LOGIN_SUCCESS: 'USERS_REFRESH_LOGIN_SUCCESS',
  REFRESH_LOGIN_FAILURE: 'USERS_REFRESH_LOGIN_FAILURE',

  PROFILE_UPDATE_REQUEST: 'USERS_PROFILE_REQUEST',
  PROFILE_UPDATE_SUCCESS: 'USERS_PROFILE_SUCCESS',
  PROFILE_UPDATE_FAILURE: 'USERS_PROFILE_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',

  DELETE_REQUEST: 'USERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'USERS_DELETE_FAILURE',

  FORGOTPASSWORD_REQUEST: 'USERS_FORGOTPASSWORD_REQUEST',
  FORGOTPASSWORD_SUCCESS: 'USERS_FORGOTPASSWORD_SUCCESS',
  FORGOTPASSWORD_FAILURE: 'USERS_FORGOTPASSWORD_FAILURE',

  AUTHORIZE_API_REQUEST: 'AUTHORIZE_API_REQUEST',
  AUTHORIZE_API_SUCCESS: 'AUTHORIZE_API_SUCCESS',
  AUTHORIZE_API_FAILURE: 'AUTHORIZE_API_FAILURE',

};
