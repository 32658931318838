import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Row } from 'reactstrap';
import config from '../../../../config';
import TableBuilder from '../../../../shared/components/table/TableBuilder';
import { currencyFormatter, currencyFormatterWhole, formatDecimalToFixedTwoPercent, getExportPrefix } from '../../../../shared/components/table/functions';

export default function BudgetStatsTable({ retailerConfig }) {
  const sellerSelect = useSelector(store => store.sellerSelect);
  const columnDefs = [
    { field: 'budget_group', headerName: 'Budget Group (BG)' },
    { field: 'subcategory', headerName: 'Subcategory (SC)' },
    { field: 'brand', headerName: 'Brand (B)' },
    { field: 'bg_mo_budget', headerName: 'BG Monthly Budget', valueFormatter: currencyFormatterWhole },
    { field: 'bg_mo_target_pacos', headerName: 'BG Target PACoS', valueFormatter: formatDecimalToFixedTwoPercent },
    { field: 'bg_mo_target_roas', headerName: 'BG Target ROAS', valueFormatter: currencyFormatter },
    { field: 'bgscb_noof_target_count', headerName: 'BGSCB Active noof Target Count' },
    { field: 'bgscb_new_noof_targets_low', headerName: 'BGSCB Est New noof Target Count Low' },
    { field: 'bgscb_new_30d_forecast_spend_low', headerName: 'BGSCB Est New 30 Day Spend Low', valueFormatter: currencyFormatterWhole },
    { field: 'bgscb_new_30d_forecast_sales_low', headerName: 'BGSCB Est New 30 Day Sales Low', valueFormatter: currencyFormatterWhole },
    { field: 'pacos_low', headerName: 'BGSCB Est New 30 Day PACoS Low', valueFormatter: formatDecimalToFixedTwoPercent },
    { field: 'roas_low', headerName: 'BGSCB Est New 30 Day ROAS Low', valueFormatter: currencyFormatter },
    { field: 'bgscb_new_noof_targets_medium', headerName: 'BGSCB Est New noof Target Count Medium' },
    { field: 'bgscb_new_30d_forecast_spend_medium', headerName: 'BGSCB Est New 30 Day Spend Medium', valueFormatter: currencyFormatterWhole },
    { field: 'bgscb_new_30d_forecast_sales_medium', headerName: 'BGSCB Est New 30 Day Sales Medium', valueFormatter: currencyFormatterWhole },
    { field: 'pacos_medium', headerName: 'BGSCB Est New 30 Day PACoS Medium', valueFormatter: formatDecimalToFixedTwoPercent },
    { field: 'roas_medium', headerName: 'BGSCB Est New 30 Day ROAS Medium', valueFormatter: currencyFormatter },
    { field: 'bgscb_new_noof_targets_high', headerName: 'BGSCB Est New noof Target Count High' },
    { field: 'bgscb_new_30d_forecast_spend_high', headerName: 'BGSCB Est New 30 Day Spend High', valueFormatter: currencyFormatterWhole },
    { field: 'bgscb_new_30d_forecast_sales_high', headerName: 'BGSCB Est New 30 Day Sales High', valueFormatter: currencyFormatterWhole },
    { field: 'pacos_high', headerName: 'BGSCB Est New 30 Day PACoS High', valueFormatter: formatDecimalToFixedTwoPercent },
    { field: 'roas_high', headerName: 'BGSCB Est New 30 Day ROAS High', valueFormatter: currencyFormatter },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };

  const showExportOptions = true;
  const apiEndPoint = `${config.serverUrl}/accounts/budget-forecast?retailer=${retailerConfig.retailerKey}`;

  const exportPrefix = getExportPrefix(`${retailerConfig.retailerPrefix}-budget-forecast`, sellerSelect);

  return (
    <Container>
      <Row>
        <TableBuilder
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          apiEndPoint={apiEndPoint}
          showDatePicker={false}
          showExportOptions={showExportOptions}
          exportPrefix={exportPrefix}
        />
      </Row>
    </Container>
  );
}
