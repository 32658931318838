import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import config from '../../../config';
import TrialDashboardPage from './Dashboard/Trial';
import PaidDashboardPage from './Dashboard/Paid'


export default function SubscriptionDashboard() {
  const selectedSeller = useSelector(store => store.sellerSelect);
  const user = useSelector(store => store.authentication.user);
  const [updatedProfile, setUpdatedProfile] = useState(false);
  const [linkedSpApi, setLinkedSpApi] = useState(false);
  const [quickAccountSetup, setQuickAccountSetup] = useState(false);
  const [panelLoad, setPanelLoad] = useState(true);

  useEffect(() => {
    const trialSetupChecksUrl = `${config.serverUrl}/onboarding/trial-setup-check?accountId=${selectedSeller.value}`;
    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    setPanelLoad(true);
    setUpdatedProfile(false);
    setLinkedSpApi(false);
    setQuickAccountSetup(false);

    fetch(trialSetupChecksUrl, requestOptions)
      .then(response => response.json())
      .then(data => {
        setUpdatedProfile(data.updatedProfile);
        setLinkedSpApi(data.linkedSpApi);
        setQuickAccountSetup(data.quickAccountSetup);
        setPanelLoad(false);
      })
  }, [selectedSeller])

  if ((!selectedSeller.plan || selectedSeller.plan.toUpperCase() === 'TRIAL')) {
    return (
      <TrialDashboardPage updatedProfile={updatedProfile} linkedSpApi={linkedSpApi} quickAccountSetup={quickAccountSetup} panelLoad={panelLoad} />
    );
  }
  return (
    <PaidDashboardPage linkedSpApi={linkedSpApi} panelLoad={panelLoad} />
  );
}
