import React from 'react';
import { Link } from 'react-router-dom';
import {
  Container, Row, Col,
} from 'reactstrap';
import CardPanel from '../../../shared/components/widgets/CardPanel';
import { SiteImage, SiteName } from '../../../shared/components/domainSupport';

function SystemDashboardPage() {
  const siteName = SiteName();
  return (
    <Container className="dashboard">
      <Row>
        <Col>
          <CardPanel
            showButtons={false}
          >
            <Row>
              <SiteImage />
            </Row>
            <Row>
              &nbsp;
            </Row>
            <Row>
              <Col>
                <h2>ADMIN ACCESS:</h2>
              </Col>
            </Row>
            <Row>
              &nbsp;
            </Row>
            <Row>
              &nbsp;
            </Row>
            <Row>
              <Col>
                <p>This computer system is the property of {siteName}. It is for authorized use only. By using this system, all users acknowledge notice of, and agree to comply with, {siteName}&apos;s Acceptable Use Policy (AUP). Unauthorized or improper use of this system may result in administrative disciplinary action, civil charges/criminal penalties, and/or other sanctions as set forth in {siteName}&apos;s AUP. By continuing to use this system you indicate your awareness of and consent to these terms and conditions of use.</p>
              </Col>
            </Row>
            <Row>
              &nbsp;
            </Row>
            <Row>
              <Col>
                <p>LOG OFF IMMEDIATELY if you do not agree to the conditions stated in this warning.</p>
              </Col>
            </Row>
            <Row>
              &nbsp;
            </Row>
            <Row>
              <Col md={2}>
                <p>System Links:</p>
                <br />
                <Link className="btn btn-outline-primary account__btn" to="/status">System Status</Link>
              </Col>
            </Row>
            <Row>
              &nbsp;
            </Row>
            <Row>
              <Col>
                <p>Thank You,<br /> Your {siteName} Team</p>
              </Col>
            </Row>
          </CardPanel>
        </Col>
      </Row>
    </Container>
  );
}

export default function SellerDashboard() {
  return (
    <SystemDashboardPage />
  );
}
