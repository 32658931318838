import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import style from '../themeSupport';

export default function LoadingSpinner({
  active, wrap, customStyle,
}) {
  const theme = useSelector(state => state.theme);

  const flexCenter = {
    display: 'flex',
    alignItems: 'center',
  };

  function renderSpinner() {
    return (
      <svg className="load__icon" style={{ customStyle }}>
        <path fill={style(theme).colorPrimary} x="10px" y="10px" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
      </svg>
    );
  }

  function renderWithWrapper() {
    return (
      <div className="load__icon-wrap" style={flexCenter}>
        {renderSpinner()}
      </div>
    );
  }

  if (active) {
    if (wrap) {
      return renderWithWrapper();
    }
    return renderSpinner();
  }
  return null;
}

LoadingSpinner.defaultProps = {
  active: false,
  wrap: false,
  customStyle: {},
};

LoadingSpinner.propTypes = {
  active: PropTypes.bool,
  wrap: PropTypes.bool,
  customStyle: PropTypes.instanceOf(Object),
};
