import React from 'react';
import {
  Container, Row, Table,
} from 'reactstrap';
import config from '../../../../config';
import Panel from '../../../../shared/components/Panel';
import TableBuilder from '../../../../shared/components/table/TableBuilder';
import { getExportPrefix } from '../../../../shared/components/table/functions';

export default function BudgetStatsTable(props) {
  const columnDefs = [
    {
      field: 'account_id', headerName: 'Account ID', description: 'Customers account ID', example: '444',
    },
    {
      field: 'name', headerName: 'Name', description: 'Customer Name', example: 'CHARBS STORE',
    },
    {
      field: 'agency', headerName: 'Agency', description: 'Agency', example: 'limitless',
    },
    {
      field: 'status', headerName: 'Status', description: 'Customer Status', example: 'active',
    },
    {
      field: 'budget_group', headerName: 'Budget Group', description: 'Budget Group', example: 'Sponsored Brand',
    },
    {
      field: 'mtdty', headerName: 'mtdty', description: 'Number of days from the first of the month to yesterday', example: '4',
    },
    {
      field: 'impressions_3dty', headerName: 'impressions_3dty', description: '3 Day Impressions', example: '7,762,454',
    },
    {
      field: 'impressions_7dty', headerName: 'impressions_7dty', description: '7 Day Impressions', example: '16,295,748',
    },
    {
      field: 'impressions_30dty', headerName: 'impressions_30dty', description: '30 Day Impressions', example: '63,544,630',
    },
    {
      field: 'impressions_mtdty', headerName: 'impressions_mtdty', description: 'MTD Impressions', example: '9,010,768',
    },
    {
      field: 'impressions_avd3d', headerName: 'impressions_avd3d', description: 'AVG 3 Day Impressions', example: '2,587,485',
    },
    {
      field: 'impressions_avd7d', headerName: 'impressions_avd7d', description: 'AVG 7 Day Impressions', example: '2,327,964',
    },
    {
      field: 'impressions_avd30d', headerName: 'impressions_avd30d', description: 'AVG 30 Day Impressions', example: '2,252,692',
    },
    {
      field: 'impressions_avdMtd', headerName: 'impressions_avdMtd', description: 'AVG Month to Yesterday Day Impressions', example: '2,252,692',
    },
    {
      field: 'clicks_3dty', headerName: 'clicks_3dty', description: '3 Day Clicks', example: '21,581',
    },
    {
      field: 'clicks_7dty', headerName: 'clicks_7dty', description: '7 Day Clicks', example: '46,658',
    },
    {
      field: 'clicks_30dty', headerName: 'clicks_30dty', description: '30 Day Clicks', example: '191,067',
    },
    {
      field: 'clicks_mtdty', headerName: 'clicks_mtdty', description: 'MTD Clicks', example: '24,115',
    },
    {
      field: 'clicks_avd3d', headerName: 'clicks_avd3d', description: 'AVG 3 Day Clicks', example: '7,194',
    },
    {
      field: 'clicks_avd7d', headerName: 'clicks_avd7d', description: 'AVG 7 Day Clicks', example: '6,665',
    },
    {
      field: 'clicks_avd30d', headerName: 'clicks_avd30d', description: 'AVG 30 Day Clicks', example: '6,369',
    },
    {
      field: 'clicks_avdMtd', headerName: 'clicks_avdMtd', description: 'AVG MTD Clicks', example: '6,029',
    },
    {
      field: 'spend_3dty', headerName: 'spend_3dty', description: '3 Day Spend', example: '32,392',
    },
    {
      field: 'spend_7dty', headerName: 'spend_7dty', description: '7 Day Spend', example: '68,462',
    },
    {
      field: 'spend_30dty', headerName: 'spend_30dty', description: '30 Day Spend', example: '296,419',
    },
    {
      field: 'spend_mtdty', headerName: 'spend_mtdty', description: 'MTD Spend', example: '36,280',
    },
    {
      field: 'spend_avd3d', headerName: 'spend_avd3d', description: 'AVG 3 Day Spend', example: '10,797',
    },
    {
      field: 'spend_avd7d', headerName: 'spend_avd7d', description: 'AVG 7 Day Spend', example: '9,780',
    },
    {
      field: 'spend_avd30d', headerName: 'spend_avd30d', description: 'AVG 30 Day Spend', example: '9,881',
    },
    {
      field: 'spend_avdMtd', headerName: 'spend_avdMtd', description: 'AVG MTD Spend', example: '9,070',
    },
    {
      field: 'sales_3dty', headerName: 'sales_3dty', description: '3 Day Sales', example: '111,874',
    },
    {
      field: 'sales_7dty', headerName: 'sales_7dty', description: '7 Day Sales', example: '258,831',
    },
    {
      field: 'sales_30dty', headerName: 'sales_30dty', description: '30 Day Sales', example: '1,168,560',
    },
    {
      field: 'sales_mtdty', headerName: 'sales_mtdty', description: 'MTD Sales', example: '125,015',
    },
    {
      field: 'sales_avd3d', headerName: 'sales_avd3d', description: 'AVG 3 Day Sales', example: '5237,2916',
    },
    {
      field: 'sales_avd7d', headerName: 'sales_avd7d', description: 'AVG 7 Day Sales', example: '36,976',
    },
    {
      field: 'sales_avd30d', headerName: 'sales_avd30d', description: 'AVG 30 Day Sales', example: '38,952',
    },
    {
      field: 'sales_avdMtd', headerName: 'sales_avdMtd', description: 'AVG MTD Sales', example: '31,254',
    },
    {
      field: 'units_30dty', headerName: 'units_30dty', description: '30 Day Units', example: '34,714',
    },
    {
      field: 'orders_30ty', headerName: 'orders_30ty', description: '30 Day Orders', example: '27,457',
    },
    {
      field: 'number_of_campaigns_30dty', headerName: 'number_of_campaigns_30dty', description: 'Number of Campaigns 30 Days', example: '330,325',
    },
    {
      field: 'units_avd30d', headerName: 'units_avd30d', description: 'AVG Dialy 30 Day Units', example: '1,157',
    },
    {
      field: 'orders_avd30d', headerName: 'orders_avd30d', description: 'AVG 30 Day Orders', example: '915',
    },
    {
      field: 'number_of_campaigns_avd30d', headerName: 'number_of_campaigns_avd30d', description: 'AVG Number of Campaigns 30 Days', example: '11,011',
    },
    {
      field: 'mgt_spend_total_bg_min', headerName: 'mgt_spend_total_bg_min', description: 'Budget Group Total Monthly Budget', example: '300,000',
    },
    {
      field: 'mgt_spend_total_ba_min', headerName: 'mgt_spend_total_ba_min', description: 'Budget Total Customer Monthly Budget', example: '433,000',
    },
    {
      field: 'mo_bg_pacos', headerName: 'mo_bg_pacos', description: 'Target PACOS', example: '26.00%',
    },
    {
      field: 'count_of_d_products', headerName: 'count_of_d_products', description: 'NOOF ASINs in Budget Group', example: '2134',
    },
    {
      field: 'limitless_budget', headerName: 'limitless_budget', description: 'Limitless Budget - Yes = 1, No = 0', example: '0',
    },
    {
      field: 'bg_sales_fcst_total', headerName: 'bg_sales_fcst_total', description: 'Sales Target = Budget Group - Spend / PACOS Target', example: '1,153,846',
    },
    {
      field: 'bg_sales_pacing_total', headerName: 'bg_sales_pacing_total', description: 'Sales Pacing Fcst - Pacing Spend / Pacing PACOS', example: '937,611',
    },
    {
      field: 'bg_sales_fcst_total_pacing_total_ou', headerName: 'bg_sales_fcst_total_pacing_total_ou', description: 'Sales (Over) Under Target', example: '216,235',
    },
    {
      field: 'bg_pacos_mtdty', headerName: 'bg_pacos_mtdty', description: 'MTD Actual PACOS = MTD Spend/MTD Sales', example: '29.02%',
    },
    {
      field: 'bg_pacos_bg_target_bg_mtdty_ou', headerName: 'bg_pacos_bg_target_bg_mtdty_ou', description: 'Actual PACOS (Greater)/Less Target', example: '-3.02%',
    },
    {
      field: 'bg_pacos_bg_target_bg_mtdty_ou_ratio', headerName: 'bg_pacos_bg_target_bg_mtdty_ou_ratio', description: 'Percent PACOS (Greater)/Less Target', example: '-11.62%',
    },
    {
      field: 'bg_pacing_spend_bg_target_bg_mtdty_fcst_eom_ou', headerName: 'bg_pacing_spend_bg_target_bg_mtdty_fcst_eom_ou', description: 'Actual Spend Less(Greater) Budget', example: '27,897',
    },
    {
      field: 'bg_pacing_bg_spend_mtdty_eom_total', headerName: 'bg_pacing_bg_spend_mtdty_eom_total', description: 'Pacing Projected Spend', example: '272,103',
    },
    {
      field: 'bg_pacing_spend_rom_total', headerName: 'bg_pacing_spend_rom_total', description: 'Rest of Month Spend = Budget - MTD Actual', example: '263,720',
    },
    {
      field: 'bg_pacing_spend_bg_mtdty_bg_fcstty_total_ratio', headerName: 'bg_pacing_spend_bg_mtdty_bg_fcstty_total_ratio', description: 'Percent of Pacing Spend versus Budget', example: '90.70%',
    },
    {
      field: 'bg_pacing_spend_bg_mtdty_bg_fcstty_daily_ratio', headerName: 'bg_pacing_spend_bg_mtdty_bg_fcstty_daily_ratio', description: 'Daily Percent of Pacing Spend versus Budget', example: '90.70%',
    },
    {
      field: 'bg_pacing_spend_bg_mtdty_bg_fcstty_daily_minus_one_ratio', headerName: 'bg_pacing_spend_bg_mtdty_bg_fcstty_daily_minus_one_ratio', description: '% Over(Under) Budget  Not Spent', example: '-9.30%',
    },
    {
      field: 'bg_spend_bg_target_daily', headerName: 'bg_spend_bg_target_daily', description: 'Daily Budgeted Spend', example: '10,000',
    },
    {
      field: 'bg_pacing_spend_mtdty_daily', headerName: 'bg_pacing_spend_mtdty_daily', description: 'Daily Pacing Spend', example: '9,070',
    },
    {
      field: 'bg_pacing_spend_bg_target_bg_mtdty_daily_uo', headerName: 'bg_pacing_spend_bg_target_bg_mtdty_daily_uo', description: 'Daily Over/Under Pacing Versus Budget', example: '930',
    },
    {
      field: 'bg_pacing_spend_bg_target_bg_mtdty_daily_uo_ratio', headerName: 'bg_pacing_spend_bg_target_bg_mtdty_daily_uo_ratio', description: 'Daily Over/Under Pacing Versus Budget Ratio', example: '9.30%',
    },
    {
      field: 'bg_pacing_spend_rom_daily', headerName: 'bg_pacing_spend_rom_daily', description: 'Daily Rest of Month Spend', example: '10,143',
    },
    {
      field: 'bg_pacing_spend_rom_daily_bg_daily_ratio', headerName: 'bg_pacing_spend_rom_daily_bg_daily_ratio', description: 'Daily Over/Uner Rest of Month Spend Ratio', example: '101.43%',
    },
    {
      field: 'client_tier', headerName: 'Customer Tier', description: 'Customer Tier', example: 'A',
    },
    {
      field: 'the_date', headerName: 'Date', description: 'YYYYMMDD', example: '20230405',
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };

  const showDatePicker = true;
  const singleDatePicker = true;
  const showExportOptions = true;
  const defaultStartDays = 0;
  const apiEndPoint = `${config.serverUrl}/accounts/budget-group-stats`;

  const { sellerSelect } = props;
  const exportPrefix = getExportPrefix('budget-stats', sellerSelect);

  const columnDefinitionLine = line => (
    <tr key={line.field}>
      <td>{line.headerName}</td>
      <td>{line.description}</td>
      <td>{line.example}</td>
    </tr>
  );

  return (
    <Container>
      <Row>
        <TableBuilder
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          apiEndPoint={apiEndPoint}
          showDatePicker={showDatePicker}
          singleDatePicker={singleDatePicker}
          showExportOptions={showExportOptions}
          defaultStartDays={defaultStartDays}
          exportPrefix={exportPrefix}
        />
      </Row>
      <Row>
        <br />
      </Row>
      <Row>
        <Panel lg={12} title="Column Definitions">
          <div>
            <Table responsive className="table--bordered">
              <thead>
                <tr>
                  <th>Header Name</th>
                  <th>Description</th>
                  <th>Example</th>
                </tr>
              </thead>
              <tbody>
                {columnDefs && columnDefs.map(line => columnDefinitionLine(line))}
              </tbody>
            </Table>
          </div>
        </Panel>
      </Row>
    </Container>
  );
}
