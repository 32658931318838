import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import { SiteName } from '../../../shared/components/domainSupport';

const OAuthPage = () => {
  const supportText = SiteName() === 'Limitless' ? <h4>For onboarding support, please contact <a href="mailto:adteam@limitless.tech">adteam@limitless.tech</a></h4> : '';
  const urlParams = new URLSearchParams(window.location.search);
  const paramCode = urlParams.get('code');
  const paramScope = urlParams.get('scope');

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">API Onboarding and Maintance</h3>
        </Col>
      </Row>
      <Row>
        <br />
      </Row>
      <Row>
        <Col md={6}>
          <div className="account__wrapper">
            <h4>This flow can be used to initiate onboarding or to renew your authorization on an existing account.  We will verfiy the information and either assign it to a existing account or create a new account. </h4>
            <br />
            <p>Code: {paramCode}</p>
            <p>Scope: {paramScope}</p>
            <br />
            {supportText}
            <br />
            <h4>Thank You</h4>
          </div>
        </Col>
      </Row>
      <Row>
        <br />
      </Row>
    </Container>
  );
};

export default OAuthPage;
