import React from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import SettingsDataPage from './components/SettingsDataPage';

export default function AccountSettingsManagement() {
  return (
    <Container>
      <Row>
        <Col md={4}>
          <h3 className="page-title">Account Settings Data</h3>
          <h3 className="page-subhead subhead">Configure global account settings groups and access permissions.</h3>
        </Col>
        <Col md={4}>
          <p>
            <br /><b>Admin Only Access</b> - <span>{JSON.stringify({ scope: 'admin' })}</span>
            <br /><b>Role Based Access</b> - <span>{JSON.stringify({ scope: 'user', roleList: ['superRole', 'adminAccountRole'] })}</span>
            <br /><b>Note</b> - Role based access is granted to all users having one or more of the roles specified.
            <br />
            <br />
          </p>
        </Col>
      </Row>
      <Row>
        <SettingsDataPage />
      </Row>
    </Container>
  );
}
