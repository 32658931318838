import React from 'react';
import PropTypes from 'prop-types';
import AccountDataPointPanel from './AccountDataPointPanel';
import { formatDecimalToFixedTwoPercent } from '../../../../shared/components/table/functions';

export default function PPCAcos({
  data, loading, dateView, dateFieldName,
}) {
  const sales = (myData) => {
    let total = 0;
    if (myData) {
      total = myData.reduce((sum, item) => {
        if (item.sales) {
          return sum + item.sales;
        }
        return sum;
      }, 0);
    }
    return total;
  };
  const spend = (myData) => {
    let total = 0;
    if (myData) {
      total = myData.reduce((sum, item) => {
        if (item.spend) {
          return sum + item.spend;
        }
        return sum;
      }, 0);
    }
    return total;
  };
  return (
    <AccountDataPointPanel
      totalFunction={() => spend(data) / sales(data)}
      data={data}
      title="PPC ACoS"
      dayField="acos"
      formatFunction={item => formatDecimalToFixedTwoPercent({ value: item })}
      barColor="#fbb054"
      loading={loading}
      dateView={dateView}
      dateFieldName={dateFieldName}
    />
  );
}

PPCAcos.defaultProps = {
  data: null,
  dateFieldName: 'rankDate',
};

PPCAcos.propTypes = {
  loading: PropTypes.bool.isRequired,
  dateView: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(Array),
  dateFieldName: PropTypes.string,
};
