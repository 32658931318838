import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row, CardBody } from 'reactstrap';

const WalmartOnboardStepOne = ({ adProgram }) => {
  const programTag = adProgram === 'FREE' ? ' Free' : '';
  const pathTag = adProgram === 'FREE' ? '/free' : '/onboarding';

  return (
    <Container className="dashboard">
      <Row>
        <Col xl={10}>
          <CardBody>
            <h2>Setting Up Walmart{programTag} Ads: Step 1 of 2</h2>
          </CardBody>
        </Col>
      </Row>
      <Row>
        <br />
      </Row>
      <Row>
        <Col xl={5}>
          <CardBody>
            <div className="account__wrapper" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
              <h2>FIRST STEP:</h2>
              <br />
              <p style={{ fontSize: '20px' }}>
                <br />
                Add Limitless as an API partner.
                <br />
                <br />
                Login to your Walmart Advertising account <a href="https://advertising.walmart.com/view/admin" target="_blank" rel="noreferrer">https://advertising.walmart.com/view/admin</a>.  You must have admin access to add Limitless as a partner and complete the onboarding.
                <ul>
                  <li>Scroll to the “API Partner - Advertiser level” section and click “Add Partner”</li>
                  <li>Select “Limitless” from the partner name dropdown and “Write Access” from the access type dropdown. Then Click “Add Partner”</li>
                </ul>
                <br />
                <br />
                Copy the Advertiser ID in the Advertising section and save it to use on the next step
                <br />
                <br />
                <br />
                <br />
                <div className="form__form-group">
                  <Link className="btn btn-primary account__btn" style={{ color: '#FFFFFF' }} to={`${pathTag}/walmart/step-two`}>I have added Limitless as a partner.  Click here for step two.</Link>
                </div>
              </p>
            </div>
          </CardBody>
        </Col>
        <Col xl={5}>
          <CardBody>
            <div className="account__wrapper">
              <h4>Admin Managment Example</h4>
              <br />
              <img src={`${process.env.PUBLIC_URL}/img/onboarding/Walmart/walmart_account_id_and_partner_onboard_example.png`} alt="Select Admin from the proflie dropdown and Navagate to Add Partner" />
            </div>
          </CardBody>
        </Col>
      </Row>
      <Row>
        <br />
      </Row>
    </Container>
  )
};

export default WalmartOnboardStepOne;
