import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FiCameraOff } from 'react-icons/fi';
import TableModal from './modals/TableModal';
import Config from '../../../../config';
import CardInsight from '../../../../shared/components/widgets/CardInsight';
import { countryOptionsArray, countryOptionDefault } from '../../../Onboarding/amazon/components/OnboardingConfig';

export default function InsightAlertCard({
  loading, title, cardData,
}) {
  const sellerSelect = useSelector(state => state.sellerSelect);
  const theme = useSelector(state => state.theme);
  const [showAlertModal, setShowAlertModal] = useState(false);

  const changeShowAlertModal = () => {
    setShowAlertModal(!showAlertModal);
  };

  const columnDefs = [
    {
      field: 'statusDate.value',
      headerName: 'statusDate',
    },
    {
      field: 'productId',
      headerName: 'ASIN or SKU',
    },
    {
      field: 'eligibilityStatus',
      headerName: 'Eligibility Status',
    },
  ];

  const headerNameFunc = (key) => {
    if (key.startsWith('eligibilityStatus_')) {
      return moment(key.replace('eligibilityStatus_', '').replaceAll('_', '-')).format('MM/DD/YYYY')
    }
    return key.toUpperCase();
  };

  const cellStyle = (params) => {
    if (params.value === 'INELIGIBLE') {
      return { backgroundColor: '#f4cccc' };
    }
    if (params.value === 'ELIGIBLE') {
      return { backgroundColor: '#d9ead3' };
    }
    return null;
  }

  const buildDynamicColumns = (colDefs, row) => {
    const keys = Object.keys(row);
    const iconStyle = { width: 20, height: 20, color: 'var(--secondary)' };
    keys.forEach(key => {
      if (key === 'imageUrl') {
        colDefs.push({
          field: 'img',
          headerName: 'IMAGE',
          minWidth: 60,
          width: 60,
          cellClass: 'ag-image-cell',
          cellRenderer: (params) => (
            <>
              {params && params.data.imageUrl && (
                <div style={{ maxWidth: '45' }}><img src={params.data.imageUrl} width={45} height={45} title="Product Image" alt={params.data.sku} loading="lazy" /></div>
              )}
              {params && !params.data.imageUrl && (
                <FiCameraOff className="ag-no-image-icon" style={iconStyle} alt="Image not available" />
              )}
              {!params.data && (
                null
              )}
            </>
          ),
        });
      } else if (key === 'asin') {
        colDefs.push({
          field: 'asin',
          headerName: 'ASIN',
          cellRenderer: (params) => {
            if (params && params.data) {
              const getMarketPlace = (name) => {
                let result = countryOptionDefault.marketplace;
                countryOptionsArray.forEach((country) => {
                  if (name === country.value) {
                    result = country.marketplace;
                  }
                });
                return result;
              };
              const { mpName } = sellerSelect;
              const marketplace = getMarketPlace(mpName);
              const mkplUrl = `https://${marketplace}/dp/${params.data.asin}`;
              return <a href={mkplUrl} target="_blank" rel="noopener noreferrer">{params.data.asin}</a>;
            }
            return '';
          },
        });
      } else if (key === 'sku') {
        colDefs.push({
          field: 'sku',
          headerName: 'SKU',
        });
      } else if (key === 'ineligibilityCodes') {
        colDefs.push({
          field: 'ineligibilityCodes',
          headerName: 'Ineligibility Codes',
        })
      } else {
        colDefs.push({ field: key, headerName: headerNameFunc(key), cellStyle });
      }
    });
    return colDefs;
  }

  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };

  const apiEndPoint = `${Config.serverUrl}/${cardData.dtlsUrl}?productList=${cardData.productList}&dateList=${cardData.dateList}&firstDate=${cardData.firstDate}`;

  return (
    <>
      <CardInsight
        title={title}
        parentRefresh={loading}
        insightClass="alert"
        icon="warning"
        countName={cardData.countName}
        countValue={cardData.countValue}
      >
        <div>
          <div><b>Description:</b></div>
          <div>{cardData.recommendation}</div>
        </div>
        <br />
        <br />
        <div><button type="button" className="btn btn-primary badge-btn" onClick={() => changeShowAlertModal()}>View Details</button></div>
        <br />
        <div className={'insight_bottom-blank'}>
          &nbsp;
        </div>
        <br />
        <div>
          <span><b>Date Range:</b> {moment(cardData.firstDate).format('MM/DD/YYYY')} - {moment(cardData.lastDate).format('MM/DD/YYYY')}</span>
        </div>
      </CardInsight>
      <TableModal
        title={title}
        theme={theme}
        showTableModal={showAlertModal}
        changeShowTableModal={changeShowAlertModal}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        apiEndPoint={apiEndPoint}
        setColumnsFromObject={{ enabled: true, buildDynamicColumns }}
      />
    </>
  );
}

// InsightAdCard.defaultProps = {
//   data: null,
// };

InsightAlertCard.propTypes = {
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};
